import React, {useState, useEffect} from 'react'
import LeftContainerForLogin from '../login/loginLeftContainer'
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import BCLogo from '../../../assets/images/BRAINCommons-Platform.svg'
import ShadowImage from '../../../assets/images/shadow.png'
import Classes from './index.module.css'
import {navigate} from '@reach/router'
import {Auth} from 'aws-amplify'
import InfoText from '../../controls/info-text'
import get from 'lodash/get'
import {getNewsArticles, sendGAEvents, updateGAEventsPageTitleOnPageChange} from '../../../api/generic-api'
import queryString from 'query-string'
import trim from 'lodash/trim'

const ConfirmCongitoSignUp = props => {
    const [code, setCode] = useState("");
    const [authError, setAuthError] = useState("")
    const [loginLoader, setLoginLoader] = useState(false)
    const [newsArticles, setNewsArticles] = useState(null)

    const onChangeCode = event => {
        setAuthError("")
        setCode(event.target.value);
    }
    useEffect(() => {
        updateGAEventsPageTitleOnPageChange('verify_email');
    }, [])
    useEffect(() => {
        getNewsArticlesData();
    }, []);
    useEffect(() => {
        if (props.verifyMFA) {
            if (!props.signInLoader) setLoginLoader(false)
        }
    }, [props.signInLoader])

    const getNewsArticlesData = async () => {
        try {
            const response = await getNewsArticles();
            setNewsArticles(response)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSignUpUserVerification = async () => {
        const email = props?.location?.state?.userEmailId || "";
        try {
            setLoginLoader(true);
            if (!code) {
                setAuthError("Please enter a valid code.");
                setLoginLoader(false);
                return;
            }
            const response = await Auth.confirmSignUp(email, code);
            if (response && props.password) {
                await handleLogin(email, props.password['text']);
            } else {
                navigate("/login")
            }
            setLoginLoader(false)
        } catch (error) {
            if (error?.message && error.message.includes("Current status is CONFIRMED")) {
                await handleLogin(email, props.password['text']);
            } else {
                setAuthError(error.message)
                setLoginLoader(false)
            }
        }
    }
    const handleVerify = async () => {
        const search = window.location.search;
        if (props?.verifyMFA || false) {
            handleChallenge();
        } else {
            handleSignUpUserVerification();
        }
    }
    const handleChallenge = async () => {
        try {
            setLoginLoader(true);
            const user = props.mfaUserChallengeObj;
            let codeToSend = trim(code);
            if (user.challengeName === 'CUSTOM_CHALLENGE') {
                // to send the answer of the custom challenge
                await Auth.sendCustomChallengeAnswer(user, codeToSend)
                    .then(user => {
                        if (!(user?.challengeParam || false)) {
                            props.handleLogin(user);
                        } else {
                            setLoginLoader(false);
                            setAuthError('Please enter valid code.')
                        }
                    })
                    .catch(err => {
                        setAuthError(err.message + ' Please login again.');
                        console.log(err);
                        setLoginLoader(false);
                    })
            } else {
                console.log(user);
            }
        } catch (error) {
            setAuthError(error.message);
            setLoginLoader(false);
            console.log(error);
        }
    }
    const handleOnKeyDown = event => {
        if (event.keyCode == 13) {
            handleVerify();
        }
      }

    const handleLogin = async (email, password) => {
        try {
            if (email && password) {
                const response = await Auth.signIn({username: email, password: password})
                if (response) {
                    const idToken = get(response, 'signInUserSession.idToken.jwtToken', '');
                    sessionStorage.setItem('cognito_id_token', idToken);
                    await props.updateUserLogin(response)
                    sendGAEvents('verify_email_account', 'account_creation', 'verify email', {page_title: 'verify_email'});
                    navigate("/explore")
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    const resendSignUpCode = async () => {
        const search = window.location.search;
        const values = queryString.parse(search);
        await Auth.resendSignUp(values.email.replace(' ', '+'));
        props.updateSnackBar("New code sent successfully", "Success");
    }

    const resendMFACode = async () => {
        try {
            const user = props.mfaUserChallengeObj;
            await Auth.sendCustomChallengeAnswer(user, 'dummy ans', {'resend_code': 'True'})
                .then((user) => {
                    props.updateSnackBar("New MFA code sent successfully", "Success");
                    console.log(user)
                })
                .catch((error) => {
                    props.updateSnackBar(error.message + ' Please login again.', "error");
                    console.log(error)
                });
        } catch (error) {
            props.updateSnackBar("Unable to send code", "error");
            console.log(error);
        }
    }
    const onResendButtonClick = async () => {
        try {
            if (props.verifyMFA) {
                resendMFACode();
            } else {
                resendSignUpCode();
            }
        } catch (e) {
            props.updateSnackBar(e.message, "Error")
        }
    }
    const onCancelButtonClick = () => {
        if (props?.verifyMFA || false) {
            props.setVerifyMFA(false);
            props.setSignInLoader(false);
        }
        navigate('/login');
    }

    return (
        <Grid container>
            {<LeftContainerForLogin newsArticles={newsArticles}/>}
            <Grid container alignItems='center' justifyContent='center' direction='column' item xs={6}
                  className={Classes.rightContainer}>
                {<div className={Classes.bcLogoContainer}>
                    <img alt='braincommons log' className={Classes.bcLogo} src={BCLogo}/>
                    <img alt='shadow' className={Classes.shadowImage} src={ShadowImage}/>
                </div>}
                <Grid item xs={7}>
                    <Grid container spacing={2} direction='column'>
                        <p className={Classes.forgotPassTitle}>Verify email account</p>
                        <React.Fragment>
                            {
                                authError &&
                                <InfoText errorMessage={authError} type="Error"/>
                            }
                            <label>Code</label>
                            <OutlinedInput
                                onChange={onChangeCode}
                                value={code}
                                onKeyDown={handleOnKeyDown}
                                placeholder='Enter your code'/>
                            <p className={`no-outlined-button ${Classes.resendButton}`}
                               onClick={() => onResendButtonClick()}>Resend Code</p>
                            <button
                                className={`solid-button ${Classes.sendResetNotButton}`}
                                disabled={loginLoader}
                                onClick={() => handleVerify()}>Verify
                            </button>
                            <p className={`no-outlined-button ${Classes.cancelButton}`}
                               onClick={onCancelButtonClick}>Cancel</p>
                        </React.Fragment>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ConfirmCongitoSignUp;