import React, { useState, useEffect } from 'react'
import classes from './index.module.css'
import Tabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid'
import { isEmpty } from 'lodash';
import PendingApprovalCard from './pending-approval-card';
import { v4 } from 'uuid'
import moment from 'moment'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FormControl, Select, InputBase, MenuItem, Button} from '@mui/material';
import { Autocomplete } from '@mui/material';
import OwnershipTransferApprovals from './OwnershipTransferApprovals'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TablePagination from '../../commons/table-pagination/TablePagination'
import SearchIcon from '@mui/icons-material/Search';
import PendingApprovalRow from './pending-approval-row/PendingApprovalRow';
import {LightTooltip} from "../community-left-menu/CommunityLeftMenu";
import WorkspaceCartApprovals from '../workspace-cart-approvals/WorkspaceCartApprovals';
import {StyledTableCell} from "../../pages/white-list/WhiteList";
import CommunityEmptyState from "../community-empty-state";
import imgClose from "../../../assets/images/close.png";
import { getOrganizationList } from '../../../api/profile-api'

const useStyles = makeStyles(theme => ({
  flexContainer: {
    borderBottom: '2px solid #637BBD'
  },
  endAdornment: {
      top: 'calc(50% - 10px)',
      right: 6,
  },
  paper: {
    width: '100%',
    backgroundColor: 'transparent'
  }
}))

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    color: '#031235',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: "Raleway",
    height: 41,
    boxSizing: 'border-box',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    }
  },
  formControl: {
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10
  },
  
}))(InputBase);


const PendingApprovals = React.forwardRef((props, ref) => {
  const { workspaceAccessRequest = [], datasetRequests = [], filteredDcaRequest = [], workspaceOwnerships =[], workspaceCarts = [], filteredDifRequest = [],
    zone3Requests = [], getAllPendingApprovalWorkspaceCartsRequests = () => {},
    getWorkspaceAccessRequest = () => { },
    updateSnackBar, currentTab, setCurrentTab, setDashboardTitle
  } = props;

  const [tabValue, setTabValue] = useState('');
  const [selectedRequestData, setSelectedRequestData] = useState([]);
  const [unicUsers, setUnicUsers] = useState([])
  const [unicStatuses, setUnicStatuses] = useState([])
  const [selectedStatus, setSelectedStatus] = useState('Show All')
  const [isOpenedSelectList, setIsOpenedSelectList] = useState(false)
  const [selectedUser, setSelectedUser] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false)
  const [organizationsList, setOrganizationsList] = useState([])

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
      setPage(0);
  }, [selectedStatus, selectedUser])
  const setPageHandler = number => {
      setPage(number);
  }

  const styledClasses = useStyles();
  const Tab = withStyles(theme => ({
    root: {
      minWidth: 50,
      padding: '14px 6px',
      marginRight: 10,
      border: '2px solid #637BBD',
      color: '#637BBD',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      opacity: 1,
      borderBottom: 'none'
    },
    flexContainer: {
      border: '2px solid #637BBD'
    },
    selected: {
      color: "#000000",
      borderBottom: '2px solid #fafafa'

    }
  }))(MuiTab);

  const StyledAutocomplete = withStyles(() => ({
    root: {
      '& .MuiInputBase-root': {
        margin: 0,
        border: '1px solid #DEDEDE',
        borderBottom: 'none',
        padding: 15,
        '& .MuiInputBase-input': {
          border: 'none',
          borderBottom: '1px solid #DEDEDE',
          borderRadius: '0px',
          outline: 'none',
          boxShadow: 'none',
          borderColor: '#DEDEDE'
        }
      },
      '& .MuiSvgIcon-root': {
        position: 'absolute',
        right: 20
      },
      '&::-webkit-scrollbar': {
        width: '7px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#fff',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#626262',
        borderRadius: '5px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#494949', 
      },
    },
    listbox: {
      border: '1px solid #DEDEDE',
      borderTop: 'none',
      ["@media (max-width:1366px)"]: {
        maxHeight: 250,
      },
      '&::-webkit-scrollbar': {
        width: '7px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#fff',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#626262',
        borderRadius: '5px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#494949', 
      }
    },
    paper: {
      margin: 0
    }
  }))(Autocomplete);

  const getOrganizations = async () => {
    try {
      const res = await getOrganizationList();
      const orgResList = res?.organizations || []
      setOrganizationsList(orgResList);
    } catch (error) {
      setOrganizationsList([]);
      console.log('error in getting organization list')
    }
  }

  useEffect(() => {
    if(tabValue === 'workspace' && isEmpty(organizationsList)){
      getOrganizations()
    }
  }, [tabValue])

  useEffect(() => {
    setSelectedRequestData(workspaceAccessRequest)
  }, [workspaceAccessRequest])

  useEffect(() => {
    switch (tabValue) {
      case 'dca': {
        setSelectedRequestData(filteredDcaRequest);
        break;
      }
      case 'dif': {
        setSelectedRequestData(filteredDifRequest);
        break;
      }
      case 'zone2': {
        setSelectedRequestData(datasetRequests);
        break;
      }
      case 'zone3': {
        setSelectedRequestData(zone3Requests);
        break;
      }
      case 'workspace': {
        setSelectedRequestData(workspaceAccessRequest);
        break;
      }
      case 'workspaceOwnership': {
        //MOCK it here
        setSelectedRequestData([]);
        break;
      }
      case 'workspaceCarts': {
        //MOCK it here
        setSelectedRequestData([]);
        break;
      }
      default: setSelectedRequestData([]);
    }

  }, [tabValue, filteredDcaRequest, filteredDifRequest, datasetRequests, zone3Requests, workspaceAccessRequest])

  const users = []
  useEffect(() => {
    selectedRequestData.map(data => {
      if ((data?.first_name && data?.last_name) && !users.includes(`${data.first_name} ${data.last_name}`)) {
        users.push(`${data.first_name} ${data.last_name}`)
      }
    })

    setUnicUsers(users)
  }, [selectedRequestData])

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (props.userDetails?.resourceActions?.dashboard?.list_advance_compute_requests) {
      setTabValue('workspace');
    } else {
      setTabValue('dca');
    }
  }, [props.userDetails])

  const dataArr = [workspaceAccessRequest?.length || 0, filteredDcaRequest?.length || 0, filteredDifRequest?.length || 0, datasetRequests?.length || 0, zone3Requests?.length || 0]

  const getUnicStatuses = () => {
    let statusSet = new Set()
    if (selectedRequestData.length > 0) {
      selectedRequestData.forEach(item => {
        if (item?.request_status) {
          statusSet.add(item?.request_status.toLowerCase())
        } else if (item?.requestStatus) {
          statusSet.add(item?.requestStatus.toLowerCase())
        } else if (item?.status) {
          statusSet.add(item?.status.toLowerCase())
        }
      })
    }
    if(statusSet.size > 1){
      statusSet = new Set(['Show All', ...statusSet]);
    }

    setSelectedStatus([...statusSet][0])
    setUnicStatuses([...statusSet])
  }

  useEffect(() => {
    getUnicStatuses()
  }, [selectedRequestData])

  const compareStatuses = (item) => {
    if(selectedStatus === 'Show All'){
      return true
    }

    if (item?.request_status?.toLowerCase()) {
      return item?.request_status?.toLowerCase() === selectedStatus?.toLowerCase()
    } else if (item?.requestStatus?.toLowerCase()) {
      return item?.requestStatus?.toLowerCase() === selectedStatus?.toLowerCase()
    } else if (item?.status?.toLowerCase()) {
      return item?.status?.toLowerCase() === selectedStatus?.toLowerCase()
    }
  }
  const compareNames = (item) => {
    if (selectedUser && item?.first_name && item?.last_name) {
      return `${item?.first_name} ${item?.last_name}` === selectedUser
    } else if(!selectedUser) {
      return item
    }
  }

  const maxCount = selectedRequestData.filter(compareStatuses).filter(compareNames)?.length ? Math.ceil(selectedRequestData.filter(compareStatuses).filter(compareNames).length / 20) : 0;

  const Menu = props => {
    return (
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 4,
          position: 'absolute',
          zIndex: 2,
          width: '100%'
        }}
        {...props}
      />
    );
  };
  const Blanket = props => (
    <div
      style={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: 'fixed',
        zIndex: 1,
      }}
      {...props}
    />
  );
  const Dropdown = ({ children, isOpen, target, onClose }) => (
    <div style={{ position: 'relative', minWidth: '300px', marginLeft: '10px' }}>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
  );

  const [sortedSelectedRequestData, setSortedSelectedRequestData] = useState([])

  useEffect(() => {
    let sorted = selectedRequestData.sort((a, b) => {
      const firstDate = a.lastUpdatedDate || a.request_submitted_time || a.updated_timestamp || a.approved_at || a.requestCompletionDate
      const secondDate = b.lastUpdatedDate || b.request_submitted_time || b.updated_timestamp || b.approved_at || b.requestCompletionDate 
      
        return moment(secondDate).diff(moment(firstDate))
    })

    setSortedSelectedRequestData(sorted)
  }, [selectedRequestData])

  return (
    <div className={classes.mainContainer} ref={ref}>
      <Grid container>
        {currentTab === null &&
          <>
            <Grid item xs={8}>
              <p className={classes.title}>Agreements & Requests</p>
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {currentTab === null && (
                <div className={classes.zoneTilesDetailsButton}>
                  <button
                    id="cvb-adminDashboard-zone1ViewDatasetButton"
                    className={classes.btn}
                    onClick={() => {
                      setCurrentTab('agreements-and-requests');
                      setDashboardTitle('Agreements & Requests');
                    }}>
                    Review
                  </button>
                </div>
              )}
            </Grid>
          </>}
      </Grid>
      <Grid container >
        {currentTab === null && <Grid container spacing={3}>
          <Grid item xs={3}>
            <PendingApprovalCard
              backgroundColor='#d8ecf5'
              topBorderColor='#72b7e5'
              title={`Advanced Compute Requests`}
              value={workspaceAccessRequest?.length || 0}
            />
          </Grid>
          <Grid item xs={3}>
            <PendingApprovalCard
              backgroundColor='#d8ecf5'
              topBorderColor='#72b7e5'
              title="Data Contribution Agreements"
              value={filteredDcaRequest?.length || 0}
            />
          </Grid>
          <Grid item xs={3}>
            <PendingApprovalCard
              backgroundColor='#d8ecf5'
              topBorderColor='#72b7e5'
              title="Data Inventory Form"
              value={filteredDifRequest?.length || 0}
            />
          </Grid>
          <Grid item xs={3}>
            <PendingApprovalCard
              backgroundColor='#d8ecf5'
              topBorderColor='#72b7e5'
              title="Zone 2 Requests"
              value={datasetRequests?.length || 0}
            />
          </Grid>
          <Grid item xs={3}>
            <PendingApprovalCard
              backgroundColor='#d8ecf5'
              topBorderColor='#72b7e5'
              title="Zone 3 Requests"
              value={zone3Requests?.length || 0}
            />
          </Grid>
          <Grid item xs={3}>
            <PendingApprovalCard
              backgroundColor='#d8ecf5'
              topBorderColor='#72b7e5'
              title="Workspace Ownership Transfer Requests"
              value={workspaceOwnerships?.length || 0}
            />
          </Grid>
          <Grid item xs={3}>
            <PendingApprovalCard
              backgroundColor='#d8ecf5'
              topBorderColor='#72b7e5'
              title="Workspace Files Download Requests"
              value={workspaceCarts?.length || 0}
            />
          </Grid>


        </Grid>}

        {currentTab === 'agreements-and-requests' &&
          <Grid item xs={12} className={classes.pendingTextWrapper}>
            <Paper square className={styledClasses.paper}>
              <Tabs
                value={tabValue}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "rgb(250 250 250)"
                  }
                }}
                onChange={handleChange}
                classes={{ flexContainer: styledClasses.flexContainer }}
              >
                {
                  props.userDetails?.resourceActions?.dashboard?.list_advance_compute_requests ?
                    <Tab value={`workspace`} id='workspaceRequestsTab' label={`Advanced Compute Requests`} /> : null
                }
                {
                  props.userDetails?.resourceActions?.dashboard?.list_data_contribution_agreement ?
                    <Tab value={`dca`} id='dcaRequestsTab' label={`Data Contribution Agreement`} /> : null
                }
                {
                  props.userDetails?.resourceActions?.dashboard?.list_data_inventory_form ?
                    <Tab value={`dif`} id='difRequestsTab' label={`Data Inventory Form`} /> : null
                }
                {
                  props.userDetails?.resourceActions?.dashboard?.list_zone2_access_requests ?
                    <Tab value={`zone2`} id='zone2RequestsTab' label={`Zone 2`} /> : null
                }

                {
                  props.userDetails?.resourceActions?.dashboard?.list_zone3_access_requests ?
                    <Tab value={`zone3`} id='zone3RequestsTab' label={`Zone 3`} /> : null
                }
                {
                  props.userDetails?.resourceActions?.dashboard?.list_zone3_access_requests ?
                    <Tab value={`workspaceOwnership`} id='workspaceOwnership' label={`Workspace Ownership`} /> : null
                }

                {
                  props.userDetails?.resourceActions?.dashboard?.view_workspace_files_download ?
                    <Tab value={`workspaceCarts`} id='workspaceCarts' label={`Workspace Files Download`} /> : null
                }

              </Tabs>
            </Paper>
          </Grid>
        }

      </Grid>
      {currentTab === 'agreements-and-requests' &&
        <>
          {unicStatuses.length > 0 && (tabValue !== "workspaceOwnership") &&
            <div className={classes.selectWrapper} style={{width: '230px'}}>
              <FormControl style={{ margin: '15px 0'}}>
                <span>Status</span>
                <Select
                  defaultValue={selectedStatus}
                  value={selectedStatus}
                  onOpen={() => setIsOpenedSelectList(true)}
                  onClose={() => setTimeout(() => {
                    setIsOpenedSelectList(false)
                  }, 500)}
                  input={<BootstrapInput />}
                  IconComponent={KeyboardArrowDownIcon}
                  onChange={(e) => { setSelectedStatus(e.target.value) }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                  className={classes.select}>
                  {unicStatuses.map((item, i) => {
                    const content = item?.split('_').join(' ');
                    return (
                        <MenuItem key={i} value={item}>
                          {(!isOpenedSelectList && item === selectedStatus && content.length >= 12) ? (
                              <LightTooltip placement="right" arrow title={content}>
                                <p className={classes.popoverFieldSelectItem}>{content}</p>
                              </LightTooltip>
                          ) : <p className={classes.popoverFieldSelectItem}>{content}</p>}
                        </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </div>
          }
          {unicUsers.length > 0 &&
            <div className={classes.selectWrapper} style={{width: '100%', maxWidth: '450px'}}>
              <FormControl style={{margin: 0}}>
                <span>Submitted By</span>

                <Dropdown
                  isOpen={isOpen}
                  className={classes.dropdown}
                  onClose={() => {toggleOpen()}}
                  target={
                    <Button
                      endIcon={!selectedUser ? <KeyboardArrowDownIcon style={{transform: !isOpen ? 'rotate(0deg)' : 'rotate(180deg)'}} /> : <img className={classes.closeIcon} src={imgClose} onClick={(e) => {
                        e.stopPropagation()
                        setSelectedUser("")
                    }}/>}
                      onClick={() => {toggleOpen()}}
                      isSelected={isOpen}
                      className={classes.button}
                      disableRipple
                      disableFocusRipple
                      disableElevation
                      fullWidth
                    >
                      {selectedUser ? `${selectedUser}` : 'Select'}
                    </Button>
                  }
                >
                  <StyledAutocomplete
                      open={isOpen}
                      className={classes.autocomplete}
                      forcePopupIcon={false}
                      options={unicUsers.sort((a,b) => a.localeCompare(b))}
                      getOptionLabel={(option) => option}
                      disableCloseOnSelect={false}
                      filterSelectedOptions={true}
                      renderTags={() => null}
                      onChange={(e) => { setSelectedUser(e.target.innerText); setIsOpen(false) }}
                      renderInput={(params) => {
                        const { InputLabelProps, InputProps, ...rest } = params;
                        InputProps.endAdornment = <SearchIcon/>
                        return <BootstrapInput 
                        placeholder='Search'
                        endAdornment={<SearchIcon/>}
                        {...InputProps}  
                        {...rest} 
                        />
                      }}
                    >
                  </StyledAutocomplete>
                </Dropdown>
                </FormControl>

      
            </div>
          }
          {
            tabValue === "workspaceOwnership" ?
              <OwnershipTransferApprovals
                BootstrapInput={BootstrapInput}
                workspaceOwnershipRequests={workspaceOwnerships}
              /> : tabValue === "workspaceCarts" ?
              <WorkspaceCartApprovals
                BootstrapInput={BootstrapInput}
                workspaceCartRequests={workspaceCarts}
                updateSnackBar={updateSnackBar}
                getAllPendingApprovalWorkspaceCartsRequests={getAllPendingApprovalWorkspaceCartsRequests}
                userDetails={props?.userDetails}
              /> :
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headCell} width="32px" />
                    <TableCell className={classes.headCell} width="20%" style={{ borderLeft: 'none' }}>Submitted By</TableCell>
                    <TableCell className={classes.headCell} width="25%">Type</TableCell>
                    <TableCell className={classes.headCell} width="15%">Submission Date</TableCell>
                    <TableCell className={classes.headCell} width="10%">Approval Status</TableCell>
                    <TableCell className={classes.headCell} width="25%" style={{ borderLeft: 'none' }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(sortedSelectedRequestData.length > 0 && sortedSelectedRequestData.filter(compareStatuses).filter(compareNames).length > 0) ?
                      sortedSelectedRequestData.filter(compareStatuses).filter(compareNames).map((user, i) => {
                        return <PendingApprovalRow
                            key={v4()}
                            updateSnackBar={updateSnackBar}
                            userDetails={props?.userDetails}
                            currentData={user}
                            tabValue={tabValue}
                            className={classes.row} 
                            getWorkspaceAccessRequest={getWorkspaceAccessRequest}
                            organizationsList={organizationsList}
                            />
                      }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      :  (
                          <TableRow key={v4()}>
                            <StyledTableCell colSpan={6} component="th" scope="row">
                              <CommunityEmptyState text="search results" />
                            </StyledTableCell>
                          </TableRow>
                      )}
                </TableBody>
              </Table>
          }
          {(maxCount > 1 && sortedSelectedRequestData.length > rowsPerPage) && <div className={classes.paginationContainer}>
                <div className={classes.tablePaginationInfo}>Showing {rowsPerPage * page + 1}-{sortedSelectedRequestData.filter(compareStatuses).filter(compareNames).length < (rowsPerPage * page) + rowsPerPage ? sortedSelectedRequestData.filter(compareStatuses).filter(compareNames).length : (rowsPerPage * page) + rowsPerPage} of {sortedSelectedRequestData.filter(compareStatuses).filter(compareNames).length}</div>
                <div className={classes.tablePagination}>
                  <TablePagination
                      maxCount={maxCount}
                      page={page}
                      setPage={setPageHandler}
                  />
              </div>
            </div>}
        </>
      }
    </div>
  )
})


PendingApprovals.displayName = 'PendingApprovals'

export default PendingApprovals;