import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  TextField,
  Box,
  Checkbox,
  Chip,
  createFilterOptions,
  FormControlLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMemo } from 'react';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { CustomSlider } from './CustomSlider';
import { CustomAutocomplete, FilterSelect } from './CustomAutocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { vars } from '../../../../../assets/variables';

const {
  primaryColor,
  boxShadowColor,
  outlinedInputBorderColor,
  inputLabelColor,
} = vars;

const useStyles = makeStyles((theme) => ({
  tag: {
    height: 20,
    maxWidth: '150px !important',
    position: 'relative',
    zIndex: 0,
    borderRadius: '4px',
    backgroundColor: boxShadowColor,
    fontSize: '0.875rem',
    padding: '0rem 0.125rem',

    '& .MuiChip-label': {
      color: primaryColor,
    },
    '& .MuiChip-deleteIcon': {
      color: primaryColor,
      width: '1.0825rem',
      height: '1.0825rem',
      zIndex: 3,
    },
    '&:after': {
      content: '""',
      right: 10,
      top: 6,
      height: 12,
      width: 12,
      position: 'absolute',
      zIndex: 1,
    },
  },
  input: {
    border: `1px solid ${outlinedInputBorderColor}`,
    background: 'white',
    paddingRight: theme.spacing(1),
    borderRadius: theme.spacing(1),
    '& .MuiFormControlLabel-label.MuiTypography-root': {
      fontSize: '0.875rem',
      fontWeight: 400,
      color: inputLabelColor,
    },
  },
}));

const minDistance = 10;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function sortArray(list, sortOrder) {
  return list.sort((a, b) => {
    const aValue = a.value;
    const bValue = b.value;
    return sortOrder.indexOf(bValue) - sortOrder.indexOf(aValue);
  });
}

function stripStr(str) {
  return str?.replaceAll('/', ' ').replaceAll('_', ' ');
}

const ProjectFilter = ({ filters, title, filterKey, handleFilterChange }) => {
  const [sliderValues, setSliderValues] = React.useState(
    () => getDefaultSliderValue() ?? [20, 70]
  );
  const filter = createFilterOptions();

  const filterArr = useMemo(
    () => (filters ? Object.entries(filters) : []),
    [filters]
  );
  const filterMap = useMemo(
    () =>
      filterArr.map(([key, value]) => ({
        label: stripStr(key),
        value,
        id: key,
      })),
    [filterArr]
  );
  const defaultValue = useMemo(
    () => filterMap.filter((f) => !!f.value),
    [filterMap]
  );

  const [value, setValue] = React.useState(() => defaultValue ?? []);
  const allSelected = filterMap.length === value.length;

  const debounceSliderValues = useCallback(
    debounce((value) => {
      handleFilterChange(filterKey, '_', value);
    }, 800),
    [filterKey]
  );

  const handleSliderChange = useCallback((event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setSliderValues([clamped, clamped + minDistance]);
        debounceSliderValues([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setSliderValues([clamped - minDistance, clamped]);
        debounceSliderValues([clamped - minDistance, clamped]);
      }
    } else {
      setSliderValues(newValue);
      debounceSliderValues(newValue);
    }
  }, []);

  function getDefaultSliderValue() {
    if (filters && filters?.min !== undefined) {
      return [filters?.range?.min ?? 20, filters?.range.max ?? 60];
    } else {
      return [40, 80];
    }
  }

  useEffect(() => {
    if (filters && filters?.min !== undefined) {
      setSliderValues([filters?.range?.min, filters?.range?.max]);
    }
  }, [filters]);

  useEffect(() => {
    setValue(defaultValue);
  }, [filters]);

  const renderFilters = useMemo(() => {
    if (filters && filters?.min !== undefined) {
      return (
        <>
          <FilterSelect
            size="small"
            label={title}
            labelId="project-filter-label"
            placeholder={title}
            style={{ width: 144, maxWidth: 172 }}
          >
            <Box px={2}>
              <CustomSlider
                getAriaLabel={() => 'Minimum distance shift'}
                value={sliderValues}
                valueLabelDisplay="on"
                onChange={handleSliderChange}
                disableSwap
                min={filters?.min ? filters?.min : 0}
                max={filters?.max ? filters?.max : 100}
                step={1}
              />
            </Box>
          </FilterSelect>
        </>
      );
    }

    function filterOptions(options, params) {
      const selectedValueKeys = value.length ? value.map((v) => v.value) : [];
      const orderedOptions = sortArray(options, selectedValueKeys);
      const mappedOrderedOptions = orderedOptions.map((option) => ({
        ...option,
      }));
      const filtered = filter(mappedOrderedOptions, {
        ...params,
      });

      return [
        { label: 'Select All', value: 'select-all', id: 'select-all' },
        ...filtered,
      ];
    }

    function inputRenderer(params) {
      return (
        <TextField
          {...params}
          label={title}
          size="small"
          placeholder="Select"
          variant="filled"
        />
      );
    }

    const optionRenderer = (props, option, { selected }) => {
      const selectAllProps =
        option.value === 'select-all' // To control the state of 'select-all' checkbox
          ? { checked: allSelected }
          : {};

      async function onOptionClick() {
        if (option.value === 'select-all') {
          if (!allSelected) {
            //select all
            const options = filterMap.map((option) => ({
              ...option,
              value: true,
            }));

            // update value state
            setValue(filterMap);

            const optionsObj = options.reduce((prev, cur) => {
              if (cur.id !== undefined) {
                prev[cur.id] = cur.value;
              }
              return prev;
            }, {});

            // handle select each option filter change
            handleFilterChange(filterKey, option.id, optionsObj);
          } else {
            //deselect all
            const options = filterMap.map((option) => ({
              ...option,
              value: false,
            }));

            const optionsObj = options.reduce((prev, cur) => {
              if (cur.id !== undefined) {
                prev[cur.id] = cur.value;
              }
              return prev;
            }, {});

            // clear options if all is selected
            setValue([]);

            // handle deselect each option filter change
            handleFilterChange(filterKey, option.id, optionsObj);
          }
        } else {
          if (selected) {
            setValue((value) => value.filter((v) => v.id !== option.id));
          } else {
            setValue((value) => [...value, { ...option, value: true }]);
          }
          handleFilterChange(filterKey, option.id);
        }
      }

      return (
        <li {...props} key={option.label} onClick={onOptionClick}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            size="small"
            {...selectAllProps}
            style={{
              padding: 0,
              marginRight: 6,
            }}
          />
          <div
            style={{
              fontSize: '0.875rem',
              lineHeight: '1.25rem',
              textTransform: 'capitalize',
            }}
          >
            {option.label}
          </div>
        </li>
      );
    };

    if (filters && filterArr) {
      return (
        <>
          <CustomAutocomplete
            multiple
            id="project-filter-tags"
            limitTags={0}
            size="small"
            options={filterMap}
            value={value}
            popupIcon={<KeyboardArrowDownRoundedIcon />}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => null);
            }}

            renderOption={optionRenderer}
            renderInput={inputRenderer}
            filterOptions={filterOptions}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event) => {
              event.preventDefault();
            }}
            // filterSelectedOptions
            disableClearable
          />
        </>
      );
    }

    return (
      <Box paddingX={2} paddingY={1}>
        <Typography>No Filter data</Typography>
      </Box>
    );
  }, [filters, filterArr, sliderValues, handleFilterChange]);

  return <>{renderFilters}</>;
};

ProjectFilter.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  filters: PropTypes.object,
  filterKey: PropTypes.string,
  updateCohortFilers: PropTypes.func,
};

export default ProjectFilter;

export const CustomCheckbox = ({ label, ...props }) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      classes={{ root: classes.input }}
      {...{ label }}
      control={<Checkbox {...props} />}
    />
  );
};

const CustomChip = (props) => {
  const classes = useStyles();

  return <Chip className={classes.tag} {...props} />;
};
