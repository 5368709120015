import React, { useEffect, useState } from 'react'
import {env} from '../../../env'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { MY_PROFILE_URLS } from "../../../constants/urls";
import { getCurrProgressColor } from "../../../utils/styles-helpers";
import Classes from './index.module.css'
import DefaultProfile from '../../../assets/images/user/default-profile-image.png'
import { navigate } from '@reach/router'
import EditIcon from '../../../assets/images/editPenIcon.png'
import EnabledTickIcon from '../../../assets/images/enabled_icon.png'
import LockIcon from '../../../assets/icons/Lock-Dark.svg'
import EditIconDark from '../../../assets/icons/EditDark.svg'
import HelpIcon from '../../../assets/icons/HelpSGrey.svg'
import DeactivateIcon from '../../../assets/icons/deactivate.svg';
import makeStyles from '@mui/styles/makeStyles';
import EmailIcon from '../../../assets/images/EmailIcon.png'
import MobileIcon from '../../../assets/images/MobileIcon.png'
import z1 from '../../../assets/images/latest-images/bc-zone1.png'
import z2 from '../../../assets/images/latest-images/bc-zone2.png'
import z3 from '../../../assets/images/latest-images/bc-zone3.png'
import cross from '../../../assets/images/cross.svg'
import InstitutePinIcon from '../../../assets/images/instituteIcon.png'
import { Auth } from 'aws-amplify'
import SectorIcon from '../../../assets/icons/sector_icon.svg'
import CertificatePreview from '../../controls/certificate-preview'
import Modal from '../../commons/modal'
import get from 'lodash/get'
import difference from 'lodash/difference'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import findIndex from 'lodash/findIndex'
import { getProfilePicture as getProfilePictureApi, 
    getPendingZone3RequestData, getSubmittedDUP, createDataRequest, getDataRequestStatus, getUserDeactivationState, getDeactivationCode } from '../../../api/profile-api'
import { getWorkspaceRequests } from '../../../api/workspace-api/workspace-api'
import moment from 'moment'
import { INSTITUTE_APPROVAL_PENDING_TEXT, BC_BECOME_A_USER_LINK, ZONE2ACCESSS_STATUS, COMMUNITY_OPT_OUT_MESSAGE, COMMUNITY_OPT_IN_MESSAGE, COMMUNITY_OPT_OUT_BUTTON_TEXT, COMMUNITY_OPT_IN_BUTTON_TEXT } from '../../../constants/strings'
import capitalize from 'lodash/capitalize'
import CustomProgress from '../../commons/custom-progress';
import useScrollToTop from '../../commons/scroll-top';
import SectionHeader from '../../commons/section-header'
import { getCommunityStatus, updateCommunityOptInStatus } from '../../../api/onboarding-api';
import Tooltip from '@mui/material/Tooltip'
import { sendGAEvents } from '../../../api/generic-api';
import MultiSelect from '../../commons/multi-select'
import CautionIcon from '../../../assets/images/caution.svg';
import ErrorText from '../../controls/error-text';
import ProfilePic from '../../../assets/images/user/default-profile-image.png'

const useStyles = makeStyles(theme => ({
    paper: {
        height: 'auto',
    },
}))

const MyProfile = props => {
    const customClasses = useStyles();
    const [userDetails, setUserDetails] = useState({})
    const [certificatePreviewModal, setCertificatePreviewModal] = useState(false);
    const [selectedCertificateName, setSelectedCertificateName] = useState('');
    const [dataAccess, setDataAccess] = useState(null)
    const [latestWorkspaceRequest, setLatestWorkspaceRequest] = useState({});
    const [isFederatedSignIn, setIsFederatedSignIn] = useState(undefined)
    const [isInCommonsUser, setIsInCommonsUser] = useState(false)
    const [communityStatus, setCommunityStatus] = useState({});
    const [strokeColor, setStrokeColor] = useState('');
    const [zone3Pending, setZone3Pending] = useState('');
    const [requestAccessModal, setRequestAccessModal] = useState(false);
    const [requestZone2AccessModal, setRequestZone2AccessModal] = useState(false);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [DUPRequest, setDUPRequest] = useState(false);
    const [zone3ButtonDisabled, setZone3ButtonDisabled] = useState(false);
    const [noProjectsSelected, setNoProjectsSelected] = useState(null);
    const [isCommunityPrivate, setIsCommunityPrivate] = useState(null);
    const [optOutModal, setOptOutModal] = useState(false);
    const [optOutModalTitle, setOptOutModalTitle] = useState(null);
    const [optOutPositiveButtonText, setOptOutPositiveButtonText] = useState(null)
    const [isWorkspaceRequestLoading, setIsWorkspaceRequestLoading] = useState(false);
    const [canUserDeactivate, setCanUserDeactivate] = useState(false);
    const [deactivateModal, setDeactivateModal] = useState(false);
    const [eligibilityResponse, setEligibilityResponse] = useState({});
    const [workspaceDeactivationType, setWorkspaceDeactivationType] = useState('');
    const [isWorkspaceFormSubmitted, setIsWorkspaceFormSubmitted] = useState(false);

    const closeCertificateReviewModal = () => {
        setCertificatePreviewModal(false);
    }

    const getAllWorkspaceRequest = async () => {
        try {
            setIsWorkspaceRequestLoading(true);
            const requests = await getWorkspaceRequests();
            setIsWorkspaceRequestLoading(false);
            if (!isEmpty(requests)) {
                let dateArr = requests.map(request => moment(request.request_submitted_time, 'YYYY-MM-DD HH:mm:ss.SSSSSS'));
                const maxDate = moment.max(dateArr);
                const index = findIndex(requests, request => moment(request['request_submitted_time']).isSame(maxDate));
                const currentRequest = requests[index];
                setLatestWorkspaceRequest(currentRequest);
            }
        } catch (error) {
            setIsWorkspaceRequestLoading(false);
            console.log('error in getting workspace request', error);
        }
    }

    const closeRequestAccessModal = () => {
        setRequestAccessModal(false);
    }

    const closeRequestZone2AccessModal = () => {
        setRequestZone2AccessModal(false);
    }

    const createZoneRequest = async (type='') => {
        try {
            let body = {
                dup_request_id: DUPRequest.requestId
            };
            setZone3ButtonDisabled(true);
            let requestType = 'zone2';
            if(type === 'Zone 3'){
                body.projects = selectedProjects
                requestType = 'zone3';
            }
            const requests = await createDataRequest(requestType,body);
            if(type === 'Zone 3'){
                let remainingProjects = difference(zone3Pending.projects, selectedProjects);
                let zone3LatestData = {
                    "is_user_allow_to_request_zone3_access": remainingProjects?.length ? true : false,
                    "projects": remainingProjects
                }
                setZone3Pending(zone3LatestData);
                setSelectedProjects([]);
                closeRequestAccessModal();
            }else{
                getPolicyData();
                closeRequestZone2AccessModal();
            }
            props.updateSnackBar(`Request for ${type ? type : 'Zone 2'} Access completed.`, "Success");

        }catch (error) {
            const errorMessage = error?.response?.data?.error ? error?.response?.data?.error : `Unable to request ${type ? type : 'Zone 2'} Access`;
            props.updateSnackBar(`${errorMessage}`, "Error")
        } finally {
            setZone3ButtonDisabled(false);
        }
    }

    const requestZoneAccessModal = () => {
        sendGAEvents('request_zone3_access', 'zone3_access', 'request zone 3 access');
        if(selectedProjects?.length){
            setNoProjectsSelected(false);
            if(!DUPRequest?.isDupSubmitted){
                navigate('/my-profile/request-access', { state: { from: 'myProfile', type: 'Zone 3', projects: selectedProjects || [] } })
            }else {
                createZoneRequest('Zone 3')
            }
        }else{
            setNoProjectsSelected(true);
        }
    }

    const requestZone2Access = () => {
        createZoneRequest();
    }

    const getPendingZone3Request = async () => {
        try {
            const requests = await getPendingZone3RequestData();
            setZone3Pending(requests);
        }catch (error) {
            console.log(error);
        }
    }

    const getSubmittedDUPRequest = async () => {
        try {
            const requests = await getSubmittedDUP(props?.userDetails?.['username']);
            if(requests?.isDupSubmitted){
                setDUPRequest(requests);
            }
            
        }catch (error) {
            console.log(error);
        }
    }

    const requestZoneAccess = (zone) => {
        switch (zone) {
            case 'zone2': {
                sendGAEvents('request_zone2_access', 'zone2_access', 'request zone 2 access');
                break
            }
            case 'zone3': {
                sendGAEvents('request_zone3_access', 'zone3_access', 'request zone 3 access');
                break
            }
            default: break;
        }
        
        if(zone === 'zone3'){
            setRequestAccessModal(true);
        }
        else if(!DUPRequest?.isDupSubmitted && zone === 'zone2'){
            navigate('/my-profile/request-access', { state: { from: 'myProfile', type: "Zone 2" } })
        }else {
            setRequestZone2AccessModal(true);
        }
        
    }

    const handleProjectChange = (selectedValues) => {
        setNoProjectsSelected(false);
        setSelectedProjects(selectedValues);
    }

    const REQUEST_ACCESS_MODAL_PROPS = {
        modalTitle: "Select Zone 3 Project(s)",
        positiveButtonText: 'Request Access',
        negativeButtonText: "Cancel",
        positiveButtonAction: requestZoneAccessModal,
        negativeButtonAction: closeRequestAccessModal,
        popupButtonDisable: zone3ButtonDisabled
    }

    const REQUEST_ZONE2_ACCESS_MODAL_PROPS = {
        modalTitle: "Request Zone 2 Access",
        positiveButtonText: 'Request Access',
        negativeButtonText: "Cancel",
        positiveButtonAction: requestZone2Access,
        negativeButtonAction: closeRequestZone2AccessModal,
        popupButtonDisable: zone3ButtonDisabled
    }
    useEffect(() => {
        getCommunityStatusDetails();
        getProfilePicture();
        getAllWorkspaceRequest();
        getSignInProvider();
        getPendingZone3Request();
    }, []);

    const getUserDeactivationStateDetails = async () => {
        try {
            const response = await getUserDeactivationState();            
            setCanUserDeactivate(response?.user_deactivation_eligibility);
            setEligibilityResponse(response);
            setDeactivateModal(true);
        } catch (error) {
            console.log('error fetching deactivation status')
        }
    }

    const getDeactivationCodeForUser = async (deleteWorkspace=false) => {
        try {
            const response = await getDeactivationCode();  
            navigate('/my-profile/deactivate',{state:{deleteWorkspace:deleteWorkspace,workspaces:eligibilityResponse?.workspaces || []}})          
        } catch (error) {
            console.log('error in getting deactivation code')
        }
    }

    const getCommunityStatusDetails = async () => {
        try {
            const response = await getCommunityStatus();
            const strokeColorValue = getCurrProgressColor(response?.percent_complete);
            
            setCommunityStatus(response);
            setStrokeColor(strokeColorValue);
        } catch (error) {
            console.log('error fetching community details')
        }
    }

    useScrollToTop();

    const getProfilePicture = async () => {
        try {
            const response = await getProfilePictureApi();
            props.updateProfilePicture(response);
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getSubmittedDUPRequest();
        if (props.userDetails?.permissions?.user_roles?.length) {
            props.userDetails.permissions.user_roles.map((role) => {
                if (role?.is_primary_role) {
                    props.userDetails.user_role = role?.role_label;
                    props.userDetails.user_role_name = role?.role_name;
                    props.userDetails.user_role_expiry_date = role?.role_expiry_date;
                }
            });
        }
        setUserDetails(props.userDetails || {})

    }, [props.userDetails]);

    useEffect(() => {
        if (props.isUserPrivate) {
            setIsCommunityPrivate(true);
        } else {
            setIsCommunityPrivate(false);
            getCommunityStatusDetails();
        }
    }, [props.isUserPrivate]);

    const getSignInProvider = async () => {
        try {
            const user = await Auth.currentSession();
            const identities = user?.idToken?.payload?.identities || null;
            if (identities?.length > 0 && (identities?.[0]?.providerName == "InCommons")) {
                setIsInCommonsUser(true)
            }
            if (identities?.length > 0 && (identities?.[0]?.providerType === "Google" ||
                identities?.[0]?.providerType === "OIDC")) {
                setIsFederatedSignIn(true);
                return;
            }

            setIsFederatedSignIn(false);
        }
        catch (error) {
            console.log(error)
            setIsFederatedSignIn(false)
        }
    }

    const CERTIFICATE_VIEW_MODAL_PROPS = {
        modalTitle: selectedCertificateName,
    }

    useEffect(() => {
        getPolicyData()
    }, [])

    const getPolicyData = async () => {
        try {
            const response = await getDataRequestStatus('zone2');
            setDataAccess(response[0]);
            
        }
        catch (error) {
            setDataAccess('');
            console.log(error)
        }
    }
    const onRequestAdvanceComputeClick = () => {
        navigate('/my-profile/request-advance-compute');
    }

    const onSocialClick = (link) => {
        if (link) {
            window.open(link, "_blank")
        }
    }

    const goToCVB = () => {
        window.open(BC_BECOME_A_USER_LINK, '_blank');
    }
    const LearnMoreSection = () => {
        return (
            <Grid xs={12}>
                <div className={Classes.alertBlock}>
                    <div>
                        <p className={Classes.noteText} id='cvb-cvb-myProfile-learnMoreButtonPopupText'>If you already have access to Zone 2 or Zone 3 data and you would like to use managed desktop computing services to analyze data in the cloud, you can request advanced compute privileges.</p>
                    </div>
                </div>
            </Grid>
        )
    }
    const requestAdvanceCompute = () => {
        const requestText = get(latestWorkspaceRequest, 'request_status', '');
        const zone2Request = get(dataAccess, 'request_status', '');
        
        if ((props.userDetails?.permissions?.zone2_access || props.userDetails?.permissions?.zone3_access || zone2Request === ZONE2ACCESSS_STATUS['APPROVED'])) {
            return (
                <div>
                    <LearnMoreSection />
                    <Grid container>
                        <Grid xs={4}>
                            <button onClick={goToCVB} id='cvb-cvb-myProfile-learnMoreButton'
                                className={`transparent-button medium-size-button solid-button ${Classes.learnMoreButton}`}>
                                Learn More </button></Grid>
                        <Grid xs={8}>
                            <button onClick={onRequestAdvanceComputeClick} id='cvb-cvb-myProfile-requestAdvancedComputeButton'
                                className={`solid-button ${Classes.requestAdvancedComputeButton}`}>
                                Request Advanced Compute</button> </Grid></Grid></div>
            )
        }
        else if ((requestText === 'COMPLETED' || requestText === 'SUBMITTED' || requestText == INSTITUTE_APPROVAL_PENDING_TEXT) && !props.userDetails?.permissions?.workspace_access) {
            return (
                <div>
                    <LearnMoreSection />
                    <Grid container>
                        <Grid xs={4}>
                            <button onClick={goToCVB}
                                id='cvb-cvb-myProfile-learnMoreButton'
                                className={`transparent-button medium-size-button solid-button ${Classes.learnMoreButton}`}>
                                Learn More </button></Grid>
                        <Grid xs={8}>
                            <button disabled onClick={onRequestAdvanceComputeClick}
                                id='cvb-cvb-myProfile-requestAdvancedComputeButton'
                                className={`solid-button ${Classes.disabledRequestAccessButton}`}>
                                Pending Advanced Compute</button></Grid></Grid> </div>
            )
        } else if (props.userDetails?.permissions?.workspace_access || requestText === "APPROVED") {
            return (
                <>
                </>
            )
        }
        else {
            return (
                <div>
                    <LearnMoreSection />
                    <Grid container>
                        <Grid xs={4}>
                            <button onClick={goToCVB}
                                id='cvb-cvb-myProfile-learnMoreButton'
                                className={`transparent-button small-size-button solid-button ${Classes.learnMoreButton}`}>
                                Learn More </button></Grid>
                        <Grid xs={8}>
                            <button disabled
                                id='cvb-cvb-myProfile-requestAdvancedComputeButton'
                                className={`solid-button ${Classes.disabledRequestAccessButton}`}>
                                Request Advanced Compute </button></Grid></Grid>
                </div>
            )

        }
    }

    const Data2Access = (buttonCheck = false) => {
        const zone2Request = get(dataAccess, 'request_status', '');
        //zone 2 request will be empty in case of zone 2 request not found for user
        if(isEmpty(zone2Request)){
            return (
                <p id='cvb-cvb-myProfile-zone2NotAuthorizedText' className={Classes.rightContainerSubHeaders}>
                    <span><img id='cvb-cvb-myProfile-zone2NotAuthorizedImage' alt='success icon' src={cross} className={Classes.enabledCrossIconImage} /></span>
                    Not Authorized</p>
            )
        }
        if (buttonCheck) {
            if (zone2Request === ZONE2ACCESSS_STATUS['COMPLETED']
                || zone2Request === 'Pending For Certification Approval' || zone2Request === 'PENDING_FOR_ADMIN_APPROVAL' || zone2Request === 'PENDING_FOR_CERTIFICATION_APPROVAL' || zone2Request === 'Pending' || zone2Request === "ADDITIONAL_INFORMATION_REQUESTED" || props.userDetails?.permissions?.zone2_access ) {
                return false;
            } else if(zone2Request === ZONE2ACCESSS_STATUS['APPROVED'] || zone2Request === "REJECTED" ){
                return true;
            }
        }
        if (props.userDetails?.permissions?.zone2_access || zone2Request === ZONE2ACCESSS_STATUS['APPROVED']) {
            return (
                <p className={Classes.rightContainerSubHeaders} id='cvb-cvb-myProfile-zone2AuthorizedText'>
                    <span><img id='cvb-cvb-myProfile-zone2AuthorizedImage' alt='success icon' src={EnabledTickIcon} className={Classes.enabledTickIconImage} /></span>
                Authorized</p>
            )
        }
        else {
            if (zone2Request === ZONE2ACCESSS_STATUS['COMPLETED'] || zone2Request === "ADDITIONAL_INFORMATION_REQUESTED" 
                || zone2Request === 'Pending For Certification Approval' || zone2Request === 'PENDING_FOR_ADMIN_APPROVAL' || zone2Request === 'PENDING_FOR_CERTIFICATION_APPROVAL' || zone2Request === 'Pending') {
                return (
                    <p id='cvb-cvb-myProfile-zone2PendingText' className={Classes.rightContainerSubHeaders}>
                        <span><img id='cvb-cvb-myProfile-zone2PendingImage' alt='success icon' src={cross} className={Classes.enabledCrossIconImage} /></span>
                    Pending</p>
                )
            }
            else if(zone2Request === "REJECTED"){
                return <p id='cvb-cvb-myProfile-zone2NotAuthorizedText' className={Classes.rightContainerSubHeaders}>
                <span><img id='cvb-cvb-myProfile-zone2NotAuthorizedImage' alt='success icon' src={cross} className={Classes.enabledCrossIconImage} /></span>
                Rejected</p>
            }
            else {
                return (
                    <p id='cvb-cvb-myProfile-zone2NotAuthorizedText' className={Classes.rightContainerSubHeaders}>
                        <span><img id='cvb-cvb-myProfile-zone2NotAuthorizedImage' alt='success icon' src={cross} className={Classes.enabledCrossIconImage} /></span>
                        Not Authorized</p>
                )
            }
            
        }
        
    }
    const profilePictureSource = (props.proPic ? props.proPic : DefaultProfile)
    let userSubDetailsArr = [];
    const userName = (capitalize(userDetails?.["first_name"]) + ' ' + capitalize(userDetails?.["last_name"]))
    const user_designation = ((userDetails?.['designation']?.charAt(0)?.toUpperCase() + userDetails?.['designation']?.slice(1)) || "")
    !isEmpty(user_designation) && userSubDetailsArr.push(user_designation);
    const usersOrganization = get(userDetails, 'organization', '');
    !isEmpty(usersOrganization) && userSubDetailsArr.push(usersOrganization);
    const instituteCountry = get(userDetails, 'institution_details.country', '');
    !isEmpty(instituteCountry) && userSubDetailsArr.push(instituteCountry);
    const usersSubDetails = userSubDetailsArr.join(', ');


    let sectionHeaderButtons = [];
    sectionHeaderButtons = (isFederatedSignIn || isInCommonsUser) ? [
        {
            buttonText: "Edit Profile",
            buttonImageSource: EditIcon,
            handleOnClick: () => { navigate('/edit-profile/edit-about-me') }
        },
        {
            buttonText: "Deactivate My Account",
            buttonImageSource: DeactivateIcon,
            handleOnClick: () => { getUserDeactivationStateDetails(); }
        }
    ] : [
            {
                buttonText: "Change Password",
                buttonImageSource: LockIcon,
                handleOnClick: () => { navigate('/change-password') }
            },
            {
                buttonText: "Edit Profile",
                buttonImageSource: EditIconDark,
                handleOnClick: () => { navigate('/edit-profile/edit-about-me') }
            },
            {
                buttonText: "Manage Notification",
                buttonImageSource: HelpIcon,
                handleOnClick: () => { navigate('/change-notification') }
            },
            {
                buttonText: "Deactivate My Account",
                buttonImageSource: DeactivateIcon,
                handleOnClick: () => { getUserDeactivationStateDetails(); }
            }
        ]

    const onDeactivateConfirm = () => {
        setIsWorkspaceFormSubmitted(true);
        let deleteWorkspace = false;
        if(eligibilityResponse?.reason === 'LAST_BC_MASTER_USER'){
            navigate('/users')
            return
        }
        if (eligibilityResponse?.reason === 'WORKSPACE_EXISTS'){
            if(isEmpty(workspaceDeactivationType)){
                return
            }
            if (workspaceDeactivationType === 'keepAccount'){
                navigate('/explore/tools/workspace/ownership-change')
                return
            } else if(workspaceDeactivationType === 'deleteWorkspace'){
                deleteWorkspace = true;
            }
        }  
        getDeactivationCodeForUser(deleteWorkspace);
    }
    const closeDeactivateModal = () => {
        setDeactivateModal(false);
    }

    const DEACTIVATE_MODAL_PROPS = {
        modalTitle: eligibilityResponse?.reason === 'WORKSPACE_EXISTS' ? 'Transfer Workspace ownership to deactivate your account' :  'Deactivate your account',
        positiveButtonText: eligibilityResponse?.reason === 'WORKSPACE_EXISTS' ? 'Proceed' : eligibilityResponse?.reason === 'LAST_PA_USER' ? null : eligibilityResponse?.reason === 'LAST_BC_MASTER_USER' ? 'Assign BRAINCommons Manager Role' : 'Deactivate My Account',
        negativeButtonText: eligibilityResponse?.reason === 'LAST_PA_USER' ? null :  eligibilityResponse?.reason === 'LAST_BC_MASTER_USER' ? "Cancel" : "Never Mind, keep my account",
        positiveButtonAction: onDeactivateConfirm,
        negativeButtonAction: closeDeactivateModal,
        imageSrc: CautionIcon
    }
    const sectionHeaderProps = {
        title: "My Profile",
        type: 'myProfileButton',
        defaultActionButtons: sectionHeaderButtons
    }


    const onOptOutClick = () => {
        setOptOutModal(true);
    }
    const onOptOutConfirm = () => {
        setUserOptOutCommunityStatus();
    }
    const closeOptOutModal = () => {
        setOptOutModal(false);
    }

    useEffect(() => {
        const title = isCommunityPrivate ? COMMUNITY_OPT_IN_MESSAGE : COMMUNITY_OPT_OUT_MESSAGE;
        const buttonText = isCommunityPrivate ? COMMUNITY_OPT_IN_BUTTON_TEXT
         : COMMUNITY_OPT_OUT_BUTTON_TEXT;
        setOptOutPositiveButtonText(buttonText)
        setOptOutModalTitle(title)
    },[isCommunityPrivate])
    
    const OPT_OUT_MODAL_PROPS = {
        modalTitle: optOutModalTitle,
        positiveButtonText: optOutPositiveButtonText,
        negativeButtonText: "Cancel",
        positiveButtonAction: onOptOutConfirm,
        negativeButtonAction: closeOptOutModal,
        imageSrc: CautionIcon
    }
    const setUserOptOutCommunityStatus = async () => {
        try{
            const body = {
                "private": !isCommunityPrivate
            }
            const details = await updateCommunityOptInStatus(body);
            props.updateSnackBar(`You have successfully opted ${isCommunityPrivate ? 'in to become part of' : 'out of joining'} the BRAINCommons Communities.`, "Success");
            setOptOutModal(false);
            props.updateUserIncognitoStatus(!isCommunityPrivate);
        } catch (error) {
            props.updateSnackBar("Something went wrong. Please try again later.", "Error");
            console.log(error)
        }
        

    }

    const onWorkspaceDeactivationSelection = (type) => {
		setWorkspaceDeactivationType(type)
	}

    const usersEducation = userDetails?.custom_attributes?.highestEducation || ''


    return (
        <Grid container className={Classes.mainContainer} direction="column">
            <Modal
                open={requestAccessModal}
                handleClose={closeRequestAccessModal}
                dialogProps={REQUEST_ACCESS_MODAL_PROPS}
                disableTitleDivider={true}
            >

                <div style={{height:'350px'}}>
                
                    <label>Project<sup> *</sup></label>
                    <MultiSelect
                        searchable
                        values={zone3Pending?.projects || []}
                        handleFilterStateChange={handleProjectChange}
                        selectedValues={selectedProjects}
                        hideApplyFooter={true}
                        filterCategory={"projects"}
                        placeholder="Select" />
                    {
                        noProjectsSelected ?
                        <div style={{color: 'red', marginTop: '10px'}}>Please select a project first</div> : null
                    }
                    

                </div>
                
            </Modal>

            <Modal
                open={requestZone2AccessModal}
                handleClose={closeRequestZone2AccessModal}
                dialogProps={REQUEST_ZONE2_ACCESS_MODAL_PROPS}
                disableTitleDivider={true}
            >
                
            </Modal>

            <Modal
                open={deactivateModal}
                handleClose={closeDeactivateModal}
                dialogProps={DEACTIVATE_MODAL_PROPS}
                enableImage={true}
                disableBottomHorizontalDivider={true}
            >
                <div className={Classes.deactivateBody}>
                    {
                        (() => {
                            if (eligibilityResponse?.reason === 'LAST_PA_USER')
                                return <p>Your account can’t be deactivated as there are no additional Project Administrators for your organization. Please contact Customer Support at <a href="mailto:support@braincommons.org">support@braincommons.org</a> to nominate another individual as Project Administrator for your organization.<br /> 
                                <br />
                                You can try again deactivating your account once additional Project Administrators for your organization are created.</p>
                            if (eligibilityResponse?.reason === 'LAST_BC_MASTER_USER')
                                return <p>Your account can’t be deactivated as there are no additional BRAINCommons Manager Master. Please assign additional users to the role of BC Manager Master from the User Management page and then try deactivating your account again.</p>
                            if ((canUserDeactivate && eligibilityResponse?.reason === 'OTHER_PA_USER_PRESENT') || 
                            (eligibilityResponse?.reason === 'WORKSPACE_EXISTS' && userDetails?.user_role === 'Project Administrator' ))
                                return <div>We are sorry to see you go. After you confirm, your account will be deactivated. To reactivate your account, you can contact customer support at <a href="mailto:support@braincommons.org">support@braincommons.org</a> to request for it to be reactivated. 
                                    <div className={Classes.deactivateBodyText}> There are additional Project Administrators for your organization. Following users will continue being Project Administrators for your organization:</div>
                                    <div className={Classes.userContainer}>
                                        {
                                            eligibilityResponse?.other_pa_users?.length ?
                                            map(eligibilityResponse.other_pa_users, (user) => {
                                                return (
                                                    <div className={Classes.userBox}>
                                                        <img id='cvb-topNavBar-profilePic' src={user?.avatar_image_url || ProfilePic} alt="Profile Pic" />
                                                        <Tooltip title={user?.name}>
                                                            <div className={Classes.additionalUserName}>{user?.name}</div>
                                                        </Tooltip>
                                                    </div>
                                                )
                                            }) : null
                                        }
                                    </div>
                                    {   
                                        
                                        eligibilityResponse?.workspaces?.length &&
                                        <>
                                        <div className={Classes.deactivateBodyText}>Before you leave...</div>
                                        <ul className={Classes.orderedListContainer}>
                                            <li className={Classes.deactivateBodyText}>Your Workspace will be deleted and you, as well as any users that you have shared the Workspace or any Notebooks with, will lose access to them.</li>
                                            <li className={Classes.deactivateBodyText}>If you decide to reactivate you account, you will have to request a new Workspace but you will lose any data that you had previously stored in the current one.</li>
                                            <li className={Classes.deactivateBodyText}>Any remaining credit in you account will be refunded using the same payment method that was used to add credit.</li>
                                            <li className={Classes.deactivateBodyText}>If you would like the Workspace to be maintained, you must pass its ownership to someone else.</li>
                                        </ul>
                                        <div className={Classes.deactivateBodyText} style={{fontWeight:'bold'}}>To proceed:</div>
                                        <div  className={`${Classes.radioCheckContainer} ${Classes.deactivateBodyText}`}>
                                            <label className={Classes.radioContainer} id="cvb-myProfile-deleteWorkspace">Delete workspace & deactivate account</label>
                                            <input id='deleteWorkspace' type="radio" name="deleteWorkspace"
                                                onChange={() => onWorkspaceDeactivationSelection('deleteWorkspace')} />
                                            <span className={Classes.checkmark}></span>
                                        </div>
                                        <div  className={Classes.radioCheckContainer}>
                                            <label className={Classes.radioContainer} id="cvb-myProfile-keepAccount">Keep my account for now until I transfer my Workspace to a new owner</label>
                                            <input id='keepAccount' type="radio" name="deleteWorkspace"
                                                onChange={() => onWorkspaceDeactivationSelection('keepAccount')} />
                                            <span className={Classes.checkmark}></span>
                                        </div>
                                        <div>
                                        {
                                            eligibilityResponse?.workspaces?.length &&   isWorkspaceFormSubmitted && isEmpty(workspaceDeactivationType) &&
                                            <ErrorText errorMessage="Please Select a Preference" />  
                                        }
                                        </div> </>
                                    } 
                                </div>

                            if ((canUserDeactivate && eligibilityResponse?.reason === 'OTHER_BC_MASTER_PRESENT') || 
                            (eligibilityResponse?.reason === 'WORKSPACE_EXISTS' && userDetails?.user_role_name === 'Master' ))
                                return <div>We are sorry to see you go. After you confirm, your account will be deactivated. To reactivate your account, you can contact customer support at <a href="mailto:support@braincommons.org">support@braincommons.org</a> to request for it to be reactivated. 
                                    <div className={Classes.deactivateBodyText}> There are additional BRAINCommons Manager Master. Following users will continue being BRAINCommons Manager Master:</div>
                                    <div className={Classes.userContainer}>
                                        {
                                            eligibilityResponse?.other_bc_master_users?.length ?
                                            map(eligibilityResponse.other_bc_master_users, (user) => {
                                                return (
                                                    <div className={Classes.userBox}>
                                                        <img id='cvb-topNavBar-profilePic' src={user?.avatar_image_url || ProfilePic} alt="Profile Pic" />
                                                        <Tooltip title={user?.name}>
                                                            <div className={Classes.additionalUserName}>{user?.name}</div>
                                                        </Tooltip>
                                                    </div>
                                                )
                                            }) : null
                                        }
                                    </div>
                                    {   
                                        
                                        eligibilityResponse?.workspaces?.length &&
                                        <>
                                        <div className={Classes.deactivateBodyText}>Before you leave...</div>
                                        <ul className={Classes.orderedListContainer}>
                                            <li className={Classes.deactivateBodyText}>Your Workspace will be deleted and you, as well as any users that you have shared the Workspace or any Notebooks with, will lose access to them.</li>
                                            <li className={Classes.deactivateBodyText}>If you decide to reactivate you account, you will have to request a new Workspace but you will lose any data that you had previously stored in the current one.</li>
                                            <li className={Classes.deactivateBodyText}>Any remaining credit in you account will be refunded using the same payment method that was used to add credit.</li>
                                            <li className={Classes.deactivateBodyText}>If you would like the Workspace to be maintained, you must pass its ownership to someone else.</li>
                                        </ul>
                                        <div className={Classes.deactivateBodyText} style={{fontWeight:'bold'}}>To proceed:</div>
                                        <div  className={`${Classes.radioCheckContainer} ${Classes.deactivateBodyText}`}>
                                            <label className={Classes.radioContainer} id="cvb-myProfile-deleteWorkspace">Delete workspace & deactivate account</label>
                                            <input id='deleteWorkspace' type="radio" name="deleteWorkspace"
                                                onChange={() => onWorkspaceDeactivationSelection('deleteWorkspace')} />
                                            <span className={Classes.checkmark}></span>
                                        </div>
                                        <div  className={Classes.radioCheckContainer}>
                                            <label className={Classes.radioContainer} id="cvb-myProfile-keepAccount">Keep my account for now until I transfer my Workspace to a new owner</label>
                                            <input id='keepAccount' type="radio" name="deleteWorkspace"
                                                onChange={() => onWorkspaceDeactivationSelection('keepAccount')} />
                                            <span className={Classes.checkmark}></span>
                                        </div>
                                        <div>
                                        {
                                            eligibilityResponse?.workspaces?.length &&   isWorkspaceFormSubmitted && isEmpty(workspaceDeactivationType) &&
                                            <ErrorText errorMessage="Please Select a Preference" />  
                                        }
                                        </div> </>
                                    } 
                                </div>
                            if (eligibilityResponse?.reason === 'WORKSPACE_EXISTS')
                                return <div>We're sorry to see you go. After you confirm, your account will be deactivated. 
                                    To reactivate your account, you can contact customer<br /> support at <a href="mailto:support@braincommons.org">support@braincommons.org</a> to request for it to be reactivated.<br />
                                    <div className={Classes.deactivateBodyText}>Before you leave...</div>
                                    <ul className={Classes.orderedListContainer}>
                                        <li className={Classes.deactivateBodyText}>Your Workspace will be deleted and you, as well as any users that you have shared the Workspace or any Notebooks with, will lose access to them.</li>
                                        <li className={Classes.deactivateBodyText}>If you decide to reactivate you account, you will have to request a new Workspace but you will lose any data that you had previously stored in the current one.</li>
                                        <li className={Classes.deactivateBodyText}>Any remaining credit in you account will be refunded using the same payment method that was used to add credit.</li>
                                        <li className={Classes.deactivateBodyText}>If you would like the Workspace to be maintained, you must pass its ownership to someone else.</li>
                                    </ul>
                                    <div className={Classes.deactivateBodyText} style={{fontWeight:'bold'}}>To proceed:</div>
                                    <div  className={`${Classes.radioCheckContainer} ${Classes.deactivateBodyText}`}>
                                        <label className={Classes.radioContainer} id="cvb-myProfile-deleteWorkspace">Delete workspace & deactivate account</label>
                                        <input id='deleteWorkspace' type="radio" name="deleteWorkspace"
                                            onChange={() => onWorkspaceDeactivationSelection('deleteWorkspace')} />
                                        <span className={Classes.checkmark}></span>
                                    </div>
                                    <div  className={Classes.radioCheckContainer}>
                                        <label className={Classes.radioContainer} id="cvb-myProfile-keepAccount">Keep my account for now until I transfer my Workspace to a new owner</label>
                                        <input id='keepAccount' type="radio" name="deleteWorkspace"
                                            onChange={() => onWorkspaceDeactivationSelection('keepAccount')} />
                                        <span className={Classes.checkmark}></span>
                                    </div>
                                    {
                                        isWorkspaceFormSubmitted && isEmpty(workspaceDeactivationType) &&
                                        <ErrorText errorMessage="Please Select a Preference" />
                                    }</div>


                            if (canUserDeactivate)
                                return <p>We are sorry to see you go. After you confirm, your account will be deactivated. To reactivate your account, you can contact customer< br/> support at <a href="mailto:support@braincommons.org">support@braincommons.org</a> to request for it to be reactivated.
                                </p>
                            else 
                                return <p></p>
                        })()
                    }
                </div>
            </Modal>

            <Modal
                open={optOutModal}
                handleClose={closeOptOutModal}
                dialogProps={OPT_OUT_MODAL_PROPS}
                enableImage={isCommunityPrivate ? false : true}
            >
            </Modal>
            <SectionHeader
                id='cvb-cvb-myProfile-sectionHeader'
                open={props.open}
                sectionHeaderProps={sectionHeaderProps}
                onBackButtonClick={() => navigate('/my-profile')}
            />
            <Modal
                open={certificatePreviewModal}
                handleClose={closeCertificateReviewModal}
                dialogProps={CERTIFICATE_VIEW_MODAL_PROPS}
                disableTitleDivider={true}
            >
                <CertificatePreview selectedCertificateName={selectedCertificateName} />
            </Modal>
        
            <div className={Classes.rightLeftContainerWrapper}>
                <Grid item lg={3} md={3} xs={3} className={Classes.leftContainer}>
                    <Paper square xs={3} elevation={0} className={Classes.leftContainerPaper}>
                        <Grid container alignItems='center' direction='column' className={Classes.userBasicInfoContainer}>
                            <Grid item xs={6} className={Classes.profilePicContainer}>
                                <img src={profilePictureSource} className={Classes.profilePicImage} alt='default profile' id='cvb-cvb-myProfile-defaultProfileImage' />
                                <div className={Classes.profilePicShadow}>
                                </div>
                            </Grid>
                            <Tooltip title={userName} id='cvb-cvb-myProfile-usernameTooltip'>
                                <span className={Classes.userName} id='cvb-cvb-myProfile-username'>{userName}</span>
                            </Tooltip>
                            {!isEmpty(usersEducation) && 
                                <Tooltip title={usersEducation} id='cvb-cvb-myProfile-userDegreeTooltip'>
                                    <p className={Classes.education} id='cvb-cvb-myProfile-usersDegree'>{usersEducation}</p>
                                </Tooltip>
                            }
                            <p className={Classes.designation} id='cvb-cvb-myProfile-usersDesignationDetails'>{usersSubDetails}</p>

                            <Grid item xs={12} className={Classes.mediaIconsContainer}>
                                <div id='cvb-cvb-myProfile-orchidLink' onClick={() => onSocialClick(userDetails?.custom_attributes?.orchid)} className={`${Classes.idBefore} ${userDetails?.custom_attributes?.orchid ? Classes.idColor : ""}`}> </div>
                                <div id='cvb-myProfile-googleLink' onClick={() => onSocialClick(userDetails?.custom_attributes?.google)} className={`${Classes.googleBefore} ${userDetails?.custom_attributes?.google ? Classes.googleColor : ""}`}> </div>
                                <div id='cvb-myProfile-linkedinLink' onClick={() => onSocialClick(userDetails?.custom_attributes?.linkedin)} className={`${Classes.linkedinBefore} ${userDetails?.custom_attributes?.linkedin ? Classes.linkedinColor : ""}`}> </div>
                                <div id='cvb-myProfile-twitterLink' onClick={() => onSocialClick(userDetails?.custom_attributes?.twitter)} className={`${Classes.twitterBefore} ${userDetails?.custom_attributes?.twitter ? Classes.twitterColor : ""}`}> </div>
                                <div id='cvb-myProfile-githubLink' onClick={() => onSocialClick(userDetails?.custom_attributes?.github)} className={`${Classes.gitBefore} ${userDetails?.custom_attributes?.github ? Classes.githubColor : ""}`}> </div>
                                <div onClick={() => onSocialClick(userDetails?.custom_attributes?.personal)} className={`${Classes.internetBefore} ${userDetails?.custom_attributes?.personal ? Classes.internetColor : ""}`}> </div>
                            </Grid>
                            <div className={Classes.usersExtraInfoContainer}>
                                <div className={[Classes.emailBorder, Classes.phoneBorder].join(' ')}>
                                    <div className={Classes.usersExtraInfo}>
                                        <img src={EmailIcon} alt='email icon' />
                                        <p id='cvb-myProfile-userEmail' className={`${Classes.rightContainerInfoText} ${Classes.add5TopMargin}`}>
                                            {userDetails?.['email']}
                                        </p>
                                    </div>
                                    {
                                        !isEmpty(userDetails?.['phone_number']) ?
                                            <div className={Classes.usersExtraInfo}>
                                                <img src={MobileIcon} alt='mobile icon' />
                                                <p id='cvb-myProfile-userPhoneNumber' className={`${Classes.rightContainerInfoText} ${Classes.add5TopMargin}`}>
                                                    {userDetails?.['phone_number']}
                                                </p>
                                            </div> : null
                                    }
                                </div>
                                {

                                    !isEmpty(userDetails?.institution_details) && !Object.values(userDetails?.institution_details).every(o => o === null) ?
                                        <div className={Classes.instituteDetails}>
                                            <p className={Classes.rightContainerHeaders}>Institute</p>
                                            <div className={Classes.instituteContent}>
                                                <img src={InstitutePinIcon} alt='institute' />
                                                <div className={Classes.instituteInfoContainer}>
                                                    <p id='cvb-myProfile-userInstituteName' className={Classes.rightContainerSubHeaders}>{userDetails?.institution_details?.['institution_name']}</p>
                                                    <p id='cvb-myProfile-userInstituteFullAddress' className={`${Classes.rightContainerInfoText} ${Classes.add5TopMargin}`}>
                                                        {userDetails?.institution_details?.['address'] ? userDetails?.institution_details?.['address'] + ", " : ""}
                                                        {userDetails?.institution_details?.['city'] ? userDetails?.institution_details?.['city'] + ", " : ""}
                                                        {userDetails?.institution_details?.['state'] ? userDetails?.institution_details?.['state'] : ""}
                                                    </p>
                                                </div>
                                            </div>
                                            {
                                                isInCommonsUser &&
                                                <div className={Classes.instituteContentInCommons}>
                                                    <div className={Classes.instituteContent}>
                                                        <img src={SectorIcon} alt='institute' />
                                                        <div className={Classes.instituteInfoContainer}>
                                                            <p className={Classes.rightContainerSubHeaders}>Department:</p>
                                                            <p id='cvb-myProfile-usersDepartment' className={`${Classes.rightContainerInfoText} ${Classes.add5TopMargin}`}>
                                                                {userDetails?.institution_details?.['department'] || ""}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className={Classes.instituteContent}>
                                                        <img src={SectorIcon} alt='institute' />
                                                        <div className={Classes.instituteInfoContainer}>
                                                            <p className={Classes.rightContainerSubHeaders}>Sector:</p>
                                                            <p id='cvb-myProfile-usersSector' className={`${Classes.rightContainerInfoText} ${Classes.add5TopMargin}`}>
                                                                {userDetails?.institution_details?.['sector'] || ""}

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                        </div> : null
                                }
                            </div>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item lg={9} md={9} xs={9} className={Classes.rightContentContainer}>
                    <Grid container lg={12} md={12} direction='column'>
                        {env.REACT_APP_COMMUNITY_HIDDEN !== 'true' && <Paper square elevation={0} className={`${Classes.leftContainerPaper} ${Classes.communitySection} ${Classes.badgeHeadContainer}`}>
                            <Grid container>
                                <Grid lg={8} md={8} item className={Classes.joinBCContainer}>
                                    <p className={Classes.rightContainerHeaders}>Join the BRAINCommons Community</p>
                                    <p className={Classes.subtitleProfile} >
                                        Develop an extended network of partnerships across the BRAINCommons to empower an exchange of knowledge and skills that will 
                                        accelerate important discoveries and deepen our understanding of the brain.
                                        <div>
                                        {
                                            !isCommunityPrivate && <button id='cvb-myProfile-optOutButton' className={`solid-button ${Classes.communityPreferenceButton}`}
                                            onClick={() => { onOptOutClick() }}>
                                            Change my BRAINCommons Community preferences 
                                            </button>
                                        }</div>
                                    </p>

                                    {
                                        isCommunityPrivate &&
                                        
                                        <div className={Classes.communityMessageBox}>
                                            <Grid className={Classes.communityBoxTitle} item lg={12} md={12} xs={12}>
                                                <img src={CautionIcon} />
                                                <p className={Classes.communityText}>You have not joined the BRAINCommons Community. You can join at any time by changing your preferences and answering a few simple questions.</p>
                                            </Grid>
                                                <Grid className={Classes.communityBoxButton} item lg={12} md={12} xs={12}>
                                                    <button id='cvb-myProfile-optOutButton' className={`solid-button ${Classes.communityPreferenceButton}`}
                                                        onClick={() => { onOptOutClick() }}>
                                                        Change my BRAINCommons Community preferences
                                                                </button>
                                                </Grid>
                                        </div>
                                        
                                    }
                                </Grid>
                                {
                                    !isCommunityPrivate &&
                                    <Grid item lg={4} md={4} className={Classes.progressGridContainer}>
                                        <div className={Classes.progress}>
                                            <CustomProgress id='cvb-myProfile-communityQuestionsProgress' trailStrokeColor="#DBEAF0" strokeColor={strokeColor} percentage={communityStatus?.percent_complete} innerText="Answered" />
                                            <p className={Classes.answerQues}>Please answer the following questions so we can connect you to other users.</p>
                                            <button id='cvb-myProfile-answerNowButton' className={`transparent-button small-size-button ${Classes.answerButton}`} onClick={() => { navigate(MY_PROFILE_URLS.TELL_US_MORE_WHO_ARE_YOU) }}>{communityStatus?.percent_complete === 100 ? "Change answers" : "Answer Now"}</button>
                                        </div>
                                    </Grid>
                                }
                            </Grid>

                        </Paper>}

                        <Paper square elevation={0} className={Classes.leftContainerBioPaper}>
                            <Grid item className={Classes.bioContainer}>
                                <p className={Classes.rightContainerHeaders}>My Background & Interests</p>
                                <p id='cvb-myProfile-professionalBioText' className={Classes.rightContainerInfoText}>{userDetails?.['professional_bio'] || ""}
                                </p>
                            </Grid>
                        </Paper>

                        <Grid item xs={12} className={Classes.roleAndZonesContainer} >
                            <Grid container spacing={4}>
                                <Grid item xs={5}>
                                    <Paper square elevation={0} className={`${Classes.leftContainerPaper} ${customClasses.paper} `}>
                                        <Grid item xs={12} className={Classes.myRoleContainer}>

                                            <Grid container direction='column'>
                                                <p className={Classes.rightContainerHeaders}>My Role in the BRAINCommons</p>

                                                <div id='cvb-myProfile-userRoleText' className={Classes.userRoleHead}>{userDetails?.user_role}</div>

                                            </Grid>
                                            <Grid container xs={12}>
                                                {
                                                    (!isWorkspaceRequestLoading && dataAccess !== null) ?
                                                    requestAdvanceCompute()
                                                    : null

                                                }</Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item xs={7}>
                                    <Paper square elevation={0}>
                                        <Grid item className={Classes.zoneContainer}>
                                            <Grid container spacing={2} justifyContent='space-between'
                                                className={`${Classes.zoneInfoContainer}`} direction='row'>
                                                <Grid xs={3} className={Classes.zonesAccessInfoContainer} item>
                                                    <img className={Classes.zoneImageIcon} src={z1} id='cvb-myProfile-zone1AccessLogoImage' alt='public access logo' />
                                                </Grid>
                                                <Grid xs={9} item className={Classes.zoneSection}>
                                                    <Grid container spacing={1} direction='column'>
                                                        <Grid item xs={12}>
                                                            <p className={`${Classes.rightContainerHeadersZone} ${Classes.add5MarginBottom}`}>Zone 1: Public Access</p>
                                                        </Grid>
                                                        <p className={Classes.rightContainerSubHeaders}>
                                                            <span><img alt='success icon' src={EnabledTickIcon} id='cvb-myProfile-zone1AuthorizedImage' className={Classes.enabledTickIconImage} /></span>
                                                                    Authorized</p>
                                                        <a id='cvb-myProfile-zone1ViewDetailsLink' href={BC_BECOME_A_USER_LINK} rel="noopener noreferrer" target="_blank" className={Classes.viewDetails}>View Details</a>

                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Paper>

                                    <Paper square elevation={0} className={Classes.zoneHead}>
                                        <Grid item className={Classes.zoneContainer}>
                                            <Grid container spacing={2} justifyContent='space-between'
                                                className={`${Classes.zoneInfoContainer}`} direction='row'>
                                                <Grid xs={3} className={Classes.zonesAccessInfoContainer} item>
                                                    <img className={Classes.zoneImageIcon} src={z2} id='cvb-myProfile-zone2AccessLogoImage' alt='public access logo' />
                                                </Grid>
                                                <Grid xs={6} item className={Classes.zoneSection}>
                                                    <Grid container spacing={1} direction='column'>
                                                        <Grid item xs={12}>
                                                            <p className={`${Classes.rightContainerHeadersZone} ${Classes.add5MarginBottom}`}>Zone 2: Controlled Access</p>
                                                        </Grid>
                                                        {
                                                            dataAccess !== null && Data2Access()
                                                        }
                                                        <a id='cvb-myProfile-zone2ViewDetailsLink' href={BC_BECOME_A_USER_LINK} rel="noopener noreferrer" target="_blank" className={Classes.viewDetails}>View Details</a>
                                                    </Grid>
                                                </Grid>
                                                {<Grid item xs={3}>
                                                    {
                                                        ( isEmpty(dataAccess) || !props.userDetails?.permissions?.zone2_access && Data2Access(true) && dataAccess !== null) ?
                                                            <button id='cvb-myProfile-zone2RequestAccessButton' className={`solid-button ${Classes.requestAccessButton}`}
                                                                onClick={() => { requestZoneAccess('zone2') }}>
                                                                Request Access
                                                                </button> : null
                                                    }
                                                </Grid>}

                                            </Grid>
                                        </Grid>
                                    </Paper>

                                    <Paper square elevation={0} className={Classes.zoneHead}>
                                        <Grid item className={Classes.zoneContainer}>
                                            <Grid container spacing={2} justifyContent='space-between'
                                                className={`${Classes.zoneInfoContainer}`} direction='row'>
                                                <Grid xs={3} className={Classes.zonesAccessInfoContainer} item>
                                                    <img className={Classes.zoneImageIcon} id='cvb-myProfile-zone3PublicAccessLogo' src={z3} alt='public access logo' />
                                                </Grid>
                                                <Grid xs={6} item className={Classes.zoneSection}>
                                                    <Grid container spacing={1} direction='column'>
                                                        <Grid item xs={12}>
                                                            <p className={`${Classes.rightContainerHeadersZone} ${Classes.add5MarginBottom}`}>Zone 3: Restricted Access</p>
                                                        </Grid>
                                                        {
                                                            props.userDetails?.permissions?.zone3_access ?

                                                                <p className={Classes.rightContainerSubHeaders} id='cvb-myProfile-zone3AuthorizedText'>
                                                                    <span><img id='cvb-myProfile-zone3AuthorizedImage' alt='success icon' src={EnabledTickIcon} className={Classes.enabledTickIconImage} /></span>
                                                                    Authorized</p> :

                                                                <p className={Classes.rightContainerSubHeaders} id='cvb-myProfile-zone3NotAuthorizedText'>
                                                                    <span><img id='cvb-myProfile-zone3NotAuthorizedImage' alt='success icon' src={cross} className={Classes.enabledCrossIconImage} /></span>
                                                                    Not Authorized</p>

                                                        }


                                                        <a id='cvb-myProfile-zone3ViewDetailsLink' href={BC_BECOME_A_USER_LINK} rel="noopener noreferrer" target="_blank" className={Classes.viewDetails}>View Details</a>

                                                    </Grid>
                                                </Grid>

                                                {
                                                    <Grid item xs={3}>
                                                        {
                                                            zone3Pending?.is_user_allow_to_request_zone3_access && props.userDetails?.resourceActions?.explore?.explore ?
                                                                <button id='cvb-myProfile-zone3RequestAccessButton' className={`solid-button ${Classes.requestAccessButton}`}
                                                                onClick={() => { requestZoneAccess('zone3') }}>
                                                                Request Access
                                                                </button> : null 
                                                        }
                                                    </Grid>
                                                }

                                            </Grid>
                                        </Grid>
                                    </Paper>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>

            </div>
        </Grid>
    );
}
export default MyProfile;
