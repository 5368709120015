import React, { useState, useEffect } from 'react'
import Classes from './index.module.css';
import Grid from '@mui/material/Grid';
import DropDown from '../../commons/dropdown'
import WorkspaceFileCard from '../workspace-file-card';
import map from 'lodash/map'
import { v4 } from 'uuid'
import DeleteBinIcon from '../../../assets/images/deleteBinIcon.png'
import Checkbox from '@mui/material/Checkbox'
import CardViewIcon from '../../../assets/images/Cards.S_D.png'
import CardActiveViewIcon from '../../../assets/images/CardsActive.png'
import ListViewIcon from '../../../assets/images/ListView.png'
import ListActiveViewIcon from '../../../assets/images/Listactive.png'
import ShareIcon from '../../../assets/images/Share.S-dark.png'
import refreshIcon from '../../../assets/icons/refresh-icon.svg';
import get from 'lodash/get'
import WorkspaceFileListView from '../workspace-file-list-view';
import { OutlinedInput } from '@mui/material';
import { getAWSConfigObject } from '../../../utils/aws-sdk-helper'
import DropZone from 'react-dropzone'
import isEmpty from 'lodash/isEmpty';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '../../../assets/icons/search-icon.svg';
import LinearProgress from '@mui/material/LinearProgress';
import BackButtonArrow from '../../../assets/icons/left_arrow.png'
import AWS from 'aws-sdk';
import Modal from '../../commons/modal';
import AddUsersToShareAll from '../add-users-to-share-all';
import {
    getWorkspaceStatus, getWorkspaceDetails, getUsersForNotebookShare,createWorkspaceCart, updateWorkspaceCart,
    getAllWorkspaceCarts
} from '../../../api/workspace-api/workspace-api';
import LoaderImg from '../../../assets/images/loader.gif'
import filter from 'lodash/filter'
import { cloneDeep } from 'lodash';
import TablePagination from '../../commons/table-pagination'
import {
    WORKSPACE_STATUS_STARTED,
    WORKSPACE_STATUS_STARTING,
    WORKSPACE_STATUS_STOPPING,
} from '../../../constants/strings';
import { navigate } from '@reach/router'
import Store from '../../../redux/store';
import { setAllWorkspacesCarts } from '../../../redux/actions/workspaceActions'
import CartIconWhite from '../../../assets/images/shoppingCartWhiteIcon.svg';
const WorkspaceFileDirectory = props => {
    const [filesView, setFilesView] = useState('cards');
    const [filesSelected, setFilesSelected] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [selectedWorkspaceFilesMetadata, setSelectedWorkspaceFilesMetadata] = useState('');
    const [searchText, setSearchText] = useState('');
    const [searchInputVal, setSearchInputVal] = useState('');
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [progressBar, setProgressBar] =  useState(false);
    const [progress, setProgress] = useState(10);
    const [itemsLength, setItemsLength] = useState(18);
    const [folderWorkSpace , setFolderWorkSpace] = useState('');
    const [folderDirectory, setFolderDirectory] = useState('');
    const [progressBarTwo, setProgressBarTwo] = useState(false);
    const [folderControlData, setFolderControlData] = useState(false);
    const [breadcrumbCopy, setBreadcrumbCopy] = useState('');
    const [emptyFolder, setEmptyFolder] = useState('');
    const [uploadClass, setUploadClass] = useState(false);
    const [searchedData, setSearchedData] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [openShareWorkspaceModal, setOpenShareWorkspaceModal] = useState(false);
    const [workspaceDetails, setWorkspaceDetails] = useState('');
    const [sharedUsersList, setSharedUsersList] = useState([]);
    const [sharedUserIds, setSharedUserIds] = useState([]);
    const [shareAllFilePath, setShareAllFilePath] = useState('');
    const [directoryLoader, setDirectoryLoader] = useState(false);
    const [ bucketId, setBucketId] = useState(''); 
    const { closeFileDirectory } = props;
    const [workspaceStatus, setWorkspaceStatus] = useState(get(props, 'location.state.status', ''))
    const workspaceId = get(props, 'id', '');
    const bucketIdFromParams =  get(props, 'location.state.bucketId', '');
    const [piOwner, setPiOwner] =  useState(get(props, 'location.state.piOwner', ''));
    const workspaceStatusBackground = workspaceStatus === WORKSPACE_STATUS_STARTED || workspaceStatus === WORKSPACE_STATUS_STARTING || workspaceStatus === WORKSPACE_STATUS_STOPPING
        ? Classes.activeWorkspaceBackground
        : Classes.stoppedWorkspaceBackground;

    const [usersForShare, setUsersForShare] = useState([])
    const [openCartExists, setOpenCartExists] = useState(false)
    const [cartData, setCartData] = useState({})

    const getUsersForShare = async () => {
        try {
            const users = await getUsersForNotebookShare();
            if (users?.users) {
                setUsersForShare(users.users)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getUsersForShare();
    }, [])

    useEffect(() => {
        let currentWorkspaceCart = []
        if(props.allWorkspacesCarts?.workspace_carts){
            for(let workspace in props.allWorkspacesCarts.workspace_carts){
                if(workspace === workspaceId){
                    currentWorkspaceCart = props.allWorkspacesCarts.workspace_carts[workspace]
                }
            }
            if(currentWorkspaceCart?.length){
                for(let order of currentWorkspaceCart){
                    if(order?.status?.toLowerCase() === "open"){
                        setOpenCartExists(true)
                        setCartData(order)
                    }
                }
            }
        }
    }, [props.allWorkspacesCarts])

    const addFileToCart = async (fileName) => {
        try{
            let response = "";
            let body = {
                "workspace_id": workspaceId,
                "action":"ADD",
                "files": [
                    {
                        "name":fileName
                    }
                ]
            }
            if(openCartExists){
                response = await updateWorkspaceCart(body, cartData?.order_id);
            }else{
                response = await createWorkspaceCart(body);
            }
            const allWorkspaceCarts = await getAllWorkspaceCarts();
            if(!isEmpty(allWorkspaceCarts)){
                Store.dispatch(setAllWorkspacesCarts(allWorkspaceCarts))
            }
            props.updateSnackBar(`File ${fileName} added to cart successfully`, "Success")
        } catch (error){
            console.log(error)
            props.updateSnackBar(error?.message || "Something went wrong, please try again later", "Error")
        }
        
    }

    const addMultipleFilesToCart = async () => {
        try{
            let body = {
                "workspace_id": workspaceId,
                "action":"ADD",
                "files": []
            }
            let response = "";
            for (let key in filesSelected) {
                if (filesSelected[key]) {
                    body.files.push({
                        "name": key
                    })
                }
            }
            if(openCartExists){
                response = await updateWorkspaceCart(body, cartData?.order_id);
            }else{
                response = await createWorkspaceCart(body);
            }
            const allWorkspaceCarts = await getAllWorkspaceCarts();
            if(!isEmpty(allWorkspaceCarts)){
                Store.dispatch(setAllWorkspacesCarts(allWorkspaceCarts))
            }
            props.updateSnackBar(`Files added to cart successfully`, "Success")
        }catch (error){
            console.log(error)
            props.updateSnackBar(error?.message || "Something went wrong, please try again later", "Error")
        }
    }

    useEffect(() => {
        let tempBucketId = '';
        if(isEmpty(bucketIdFromParams)) {
            tempBucketId = localStorage.getItem('currentBucketId');
        }
        else{
            tempBucketId = bucketIdFromParams;
        }
        setBucketId(tempBucketId);
    }, [bucketIdFromParams])

    useEffect(() => {
        if(isEmpty(workspaceStatus)){
            getCurrentWorkspaceStatus()
        }
    }, [workspaceStatus])


    const getCurrentWorkspaceStatus = async () => {
        try {
            if (!isEmpty(workspaceId)) {
                const res = await getWorkspaceStatus(workspaceId);
                const workspaceDetailsResponse = await getWorkspaceDetails(workspaceId);
                const status = get(res, 'status', '');
                setWorkspaceStatus(status);
                setPiOwner(workspaceDetailsResponse?.pi_owner)
            }
        } catch (error) {
            console.log('error in getting status');
            setWorkspaceStatus('');
        }
    }

    
    useEffect(() => {
        getSelectedWorkspaceDirectoryMetadata();
    }, [searchText, bucketId])

    let folderPath="";
    let uploadFileBoolean=true;
    const maxCount = selectedWorkspaceFilesMetadata.length ? Math.ceil(selectedWorkspaceFilesMetadata.length / itemsLength) : 0

    const sliceInitialIndex = pageNumber == 0 ? 0 : pageNumber * itemsLength;
    const sliceEndIndex = (pageNumber + 1) * itemsLength;

    const displayData = selectedWorkspaceFilesMetadata && selectedWorkspaceFilesMetadata.length > 0 && selectedWorkspaceFilesMetadata.slice(sliceInitialIndex, sliceEndIndex) || []

    if(folderControlData !== true){
            displayData.unshift(...folderWorkSpace);
    }
    let sortedActivities = displayData.sort((a, b) => b.LastModified - a.LastModified)

    const getSelectedWorkspaceDirectoryMetadata = async () => {
        let marker;
        try {
            let res = await getAWSConfigObject(workspaceId);
            let params =""
            setBreadcrumbCopy('');
            setSearchedData('');
            setSearchInputVal('');
            setErrorMessage('');
            setDirectoryLoader(true);
            if (res) {
                res.get(function (err, response) {
                    if (!err) {
                        setErrorMessage('');
                        let s3 = new AWS.S3({
                            credentials: res,
                            region: 'us-east-1',
                        })
                        if(folderDirectory !== ""){
                            params = {
                                Bucket: bucketId,
                                Prefix: searchText,
                                Delimiter: '/',
                            };
                        }else{
                            params = {
                                Bucket: bucketId,
                                Prefix: folderDirectory,
                                Delimiter: '/',
                            };
                        }
                        s3.listObjectsV2(params, function (err, response) {

                            if (err) {
                                if(bucketId!==""){
                                    setErrorMessage('Error in accessing the bucket')
                                }
                                setSelectedWorkspaceFilesMetadata([]);
                                setFolderWorkSpace([]);
                               
                            }else{
                                setSelectedWorkspaceFilesMetadata(response.Contents);
                                setDirectoryLoader(false);
                                setErrorMessage('');
                                const resultsAddedKey = response.CommonPrefixes.map((el) => {
                                    var o = Object.assign({}, el);
                                    o.folderActive = true;
                                    return o;
                                  })
                                  setPageNumber(0);
                                  setFolderControlData(false)
                                  setFolderWorkSpace(resultsAddedKey);
                                  setFolderDirectory('');
                            }
                            setProgressBar(false);
                            setProgressBarTwo(false);
                        })
                    } else {
                        setErrorMessage('Error in accessing bucket');
                        setDirectoryLoader(false);
                    }
                });
            }
        } catch (error) {
            setErrorMessage('Error in accessing bucket');
            setSelectedWorkspaceFilesMetadata([]);
            setFolderWorkSpace([]);
            setDirectoryLoader(false)
        }
    }

    const folderDirectoryApi = async(folderName) =>{
        try{
            if(folderName !== ""){
                setFolderDirectory(folderName);
                setProgressBarTwo(true);
                setSearchedData('');
                setSearchInputVal('');
                folderPath=folderName;
                setShareAllFilePath(folderName);

                let splitPath = folderPath.split('/')

                var checkEmpty = (element) => element === '';
                var i = splitPath.findIndex(checkEmpty)
                splitPath.splice(i, splitPath.length);
                if(splitPath.length === 1){
                    folderName = splitPath[0]+'/'
                }
                setBreadcrumbCopy(splitPath);
                let res = await getAWSConfigObject(workspaceId);
                if (res) {
                    setErrorMessage('');
                        res.get(function (err, response) {
                            if(!err){
                                let s3 = new AWS.S3({
                                    credentials: res,
                                    region: 'us-east-1',
                                })
                                const params = {
                                    Bucket: bucketId,
                                    Prefix: folderName,
                                    Delimiter:'/'
                                };
                                s3.listObjectsV2(params, function (err, response) {
                                    if(!err){
                                        const resultsAddedKey = response.CommonPrefixes !== undefined && response.CommonPrefixes !== null? response.CommonPrefixes.map((el) => {
                                            var o = Object.assign({}, el);
                                            o.folderActive = true;
                                            return o;
                                          }) :null
                                          if(resultsAddedKey === null){
                                            setFolderWorkSpace('');
                                          }else{
                                            setFolderWorkSpace(resultsAddedKey);
                                          }
                                          let content = response.Contents;
                                            let removeFolderCopy;
                                            Object.keys(content).forEach((key, i)=>{
                                                let contentKey = content[i]['Key'];
                                                let splitted = contentKey.split('/')
                                                content[i]['Key'] = splitted[splitted.length-1]
                                                return removeFolderCopy= content;
                                            });
                                            let removedEmpty = filter(removeFolderCopy, function (el) {
                                                return el.Key != "";
                                            });
                                          setSelectedWorkspaceFilesMetadata(removedEmpty);
                                          setFolderWorkSpace(resultsAddedKey);
                                    }

                                    setProgressBarTwo(false);
                                })
                            }

                        })
                    }
                }
        }
        catch(error){
            setErrorMessage('Error in accessing the bucket');
        }

    }

    const breadcrumbApi = async(filePath, filePathArray) =>{
        let resultsAddedKey;
        setSearchedData('');
        setSearchInputVal('');
        const findIndeVal = (element) => element === filePath;
        let indexVal = filePathArray.findIndex(findIndeVal);
            filePathArray.splice(indexVal +1, filePathArray.length);
            setBreadcrumbCopy(filePathArray);

            setFolderDirectory(filePathArray+'/');
        try{
            let res = await getAWSConfigObject(workspaceId);
            setProgressBar(true);
            setProgressBarTwo(true);
            if (res) {
                setErrorMessage('');
                    res.get(function (err, response) {
                        let s3 = new AWS.S3({
                            credentials: res,
                            region: 'us-east-1',
                        })
                        const params = {
                            Bucket: bucketId,
                            Delimiter:'/',
                            Prefix: filePath+'/',
                        };
                    s3.listObjectsV2(params, function (err, response) {

                        if(response.CommonPrefixes.length > 0 && !isEmpty(response.CommonPrefixes)){
                            resultsAddedKey = response.CommonPrefixes.map((el) => {
                            var o = Object.assign({}, el);
                            o.folderActive = true;
                            return o;
                        })
                    }
                        let content = response.Contents;
                        let removeFolderCopy;
                        Object.keys(content).forEach((key, i)=>{
                            let contentKey = content[i]['Key'];
                            let splitted = contentKey.split('/')
                            content[i]['Key'] = splitted[splitted.length-1]
                            return removeFolderCopy= content;
                        });
                        let removedEmpty = removeFolderCopy.filter(function (el) {
                            return el.Key != "";
                          });

                        setSelectedWorkspaceFilesMetadata(removedEmpty);
                        setFolderWorkSpace(resultsAddedKey);
                    })
                    setProgressBar(false);
                    setProgressBarTwo(false);

                })
            }
            setProgressBar(false);
        }catch(error){
            setErrorMessage('Error in accessing the bucket');
            setProgressBar(false);
        }
    }

    const onCardIconClick = () => {
        setFilesView('cards');
    }
    const onListIconClick = () => {
        setFilesView('list');
    }
    const onFileSelected = (id, checked) => {
        const files = { ...filesSelected, [id]: checked };
        let checkBoxesBooleanVal=[];

        for(var key in files){
            checkBoxesBooleanVal.push(files[key]);
        }
        const cloneSelectedFilesShare = []
        setFilesSelected(files);
        cloneSelectedFilesShare.push(id);
    }

    const onSelectAllClick = (checked) => {
        const files = {};
        map(selectedWorkspaceFilesMetadata, fileData => {
            if (checked) {
                setSelectAll(true);
                files[fileData.Key] = true;
            } else {
                setSelectAll(false);
                files[fileData.Key] = false;
            }
        });
        setFilesSelected(files);
    }

    const onDeleteFile = async (filename) => {
        try {
            if (!isEmpty(filename)) {
                let res = await getAWSConfigObject(workspaceId);
                let fileFolderPath = folderDirectory+filename
                if (res) {
                    setErrorMessage('');
                    res.get(function (err, response) {
                        if (!err) {
                            let s3 = new AWS.S3({
                                credentials: res,
                                region: 'us-east-1',
                            })
                            var params = {
                                Bucket: bucketId,
                                Key: fileFolderPath
                            };

                            s3.deleteObject(params, function (err, data) {
                                if (err) console.log(err, err.stack);
                                else {
                                    folderDirectoryApi(folderDirectory);
                                    deleteSelectedFileKeys();
                                    props.updateSnackBar(`Successfully Deleted Files ${filename}`, "Success")
                                }
                            });
                        } else {
                            setErrorMessage('Error in accessing bucket');
                        }
                    });
                }
            }
        } catch (error) {
            setErrorMessage('Error in accessing the bucket');
        }
    }
    const onOpenDeleteModal = () => {
        setOpenDeleteModal(true)
    }
    const closeDeleteModal = () =>{
        setOpenDeleteModal(false)
    }
    const deleteSelectedFileKeys = () => {
        const fileSelectedClone = cloneDeep(filesSelected);
        for(let key in fileSelectedClone){
            if(fileSelectedClone[key]){
                delete fileSelectedClone[key];
            }
        }
        setFilesSelected(fileSelectedClone);
    }
    const onDeleteMultipleFile = async () => {
        try {
            let selectedObjectList = [];
            for (let key in filesSelected) {
                if (filesSelected[key]) {
                    selectedObjectList.push({ 'Key': folderDirectory+key });
                }
            }
            if (!isEmpty(selectedObjectList)) {
                let res = await getAWSConfigObject(workspaceId);
                if (res) {
                    setErrorMessage('');
                    res.get(function (err, response) {
                        if (!err) {
                            let s3 = new AWS.S3({
                                credentials: res,
                                region: 'us-east-1',
                            })
                            var params = {
                                Bucket: bucketId
                            };
                            params.Delete = {Objects:selectedObjectList};

                            s3.deleteObjects(params, function (err, data) {
                                if (err) console.log(err, err.stack);
                                else {
                                    folderDirectoryApi(folderDirectory);
                                    setSelectAll(false);
                                    setBreadcrumbCopy('');
                                    deleteSelectedFileKeys();
                                    props.updateSnackBar(`Successfully Deleted Files`, "Success");
                                }
                            });
                        } else {
                            setErrorMessage('Error in accessing bucket');
                        }
                    });
                }
            }
            setOpenDeleteModal(false)
        } catch (error) {
            setErrorMessage('Error in accessing bucket');
        }
    }
    let count = 0;
    const downloadURL = (url) => {
        let hiddenIFrameID = 'hiddenDownloader' + count++;
        let iframe = document.createElement('iframe');
        iframe.id = hiddenIFrameID;
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        iframe.src = url;
    }
    const onDownloadClick = async (fileName) => {
        try {
            let res = await getAWSConfigObject(workspaceId);
            if (res) {
                setErrorMessage('');
                res.get(function (err, response) {
                    if (!err) {
                        let s3 = new AWS.S3({
                            credentials: res,
                            region: 'us-east-1',
                        })
                        var params = {
                            Bucket: bucketId,
                            Key: folderDirectory+fileName,
                            ResponseContentDisposition: `attachment; filename=${fileName}`
                        };
                        var url = s3.getSignedUrl('getObject', params);
                        downloadURL(url);
                        props.updateSnackBar(`Successfully downloaded`, "Success")
                    } else {
                        setErrorMessage('Error in accessing bucket');
                    }
                });
            }
        } catch (error) {
            setErrorMessage('Error in download');
        }

    }
    const reloadComponent =() =>{
        if(breadcrumbCopy === ""){
            getSelectedWorkspaceDirectoryMetadata();
        }else{
            folderDirectoryApi(folderDirectory);
        }
        setProgressBarTwo(true);
    }
    const onImageDrop = async (files, folderDirectory, uploadFileBoolean) => {
        try {
            if(!isEmpty(bucketId)){
            let fileContent = files[0];
            if(folderDirectory.indexOf('upload') === -1 || folderDirectory === ""){
                folderDirectory = 'upload/'
            }
            if(breadcrumbCopy === ""){
                setUploadClass(true);
            }
            let fileFolderPath = folderDirectory + fileContent['name'];
            let res = await getAWSConfigObject(workspaceId);
            setProgressBar(true);
            if (res) {
                setErrorMessage('');
                res.get(function (err, response) {
                    if (!err) {
                        let s3 = new AWS.S3({
                            credentials: res,
                            region: 'us-east-1',
                        })
                        const params = {
                            Bucket: bucketId,
                            Key: fileFolderPath,
                            Body: fileContent,
                            Delimiter:'/',
                            ACL: 'bucket-owner-full-control',
                            ContentType: fileContent['type']
                        };

                        s3.upload(params, function (err, data) {
                            if (err) {
                                folderDirectory ="";
                              return setErrorMessage("Error in Upload");
                            }

                            folderDirectoryApi(folderDirectory, uploadFileBoolean, fileContent['name'])
                            setProgressBar(false);
                            setTimeout(()=> { setUploadClass(false) }, 1500);
                            setErrorMessage('');
                            props.updateSnackBar(`Successfully File Uploaded "${fileContent.name}"`, "Success");

                        });
                        setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 90));
                    } else {
                        setErrorMessage('Upload file error');
                        props.updateSnackBar("Unable to upload file", "Error");
                    }
                });
            }
        }
        } catch (error) {
            setProgressBar(false);
            setErrorMessage('Upload file error');
        }
    }

    const onFilterValueChange = (value) => {
        setItemsLength(value)
    }
    const onSearchChange = (text) => {
        let val = text.toLowerCase();
        sortedActivities = sortedActivities.filter(v => v['Key']? v['Key'].toLowerCase().includes(val): v['Prefix'].toLowerCase().includes(val));
        if(text === ""){
            setSearchedData('')
        }else{
            setSearchedData(sortedActivities);
            sortedActivities = searchedData;
        }
        setSearchInputVal(text);
    }
    const onShareWorkspaceSelect = () => {
        setOpenShareWorkspaceModal(true);
    }
    const onShareWorkspaceModal = () => {

    }
    const closeShareWorkspaceModal = () => {
        setOpenShareWorkspaceModal(false);
    }
    const checkSelectedFiles = () =>{
        let isFileSelected = false;
        for(let key in filesSelected){
            if(filesSelected[key]){
                isFileSelected = true;
            }
        }
        return isFileSelected;
    }
    const SHARE_WORKSPACE_MODAL_PROPS = {
        modalTitle: "Share Notebooks",
        positiveButtonAction: onShareWorkspaceModal,
        negativeButtonAction: closeShareWorkspaceModal
    }
    const DELETE_MODAL_PROPS = {
        modalTitle: "Are you sure you want to delete this notebooks",
        positiveButtonText: "Delete Notebooks",
        negativeButtonText: "Cancel",
        positiveButtonAction: onDeleteMultipleFile,
        negativeButtonAction: closeDeleteModal
    }
    if(searchedData !==""){
        sortedActivities = searchedData;
    }

    const setTablePage = (number) =>{
        setPageNumber(number)
    }

    const goBack = () => {
        navigate('/explore/tools/workspace')
    }
    return (
        <div className={Classes.container}>
             <Modal
                open={openDeleteModal}
                handleClose={closeDeleteModal}
                dialogProps={DELETE_MODAL_PROPS}
                disableTitleDivider
                >
            </Modal>

            <Modal
                open={openShareWorkspaceModal}
                handleClose={closeShareWorkspaceModal}
                dialogProps={SHARE_WORKSPACE_MODAL_PROPS}
                disableBottomHorizontalDivider
            >
                <AddUsersToShareAll
                    workspaceDetails={workspaceDetails}
                    workspaceId={workspaceId}
                    shareAllFilePath={shareAllFilePath}
                    sharedUsersList={sharedUsersList}
                    filesSelected={filesSelected}
                    isWorkspaceShare
                    id={get(props, 'eventData.workspace_id', '')}
                    sharedUserIds={sharedUserIds}
                    usersForShare={usersForShare}
                    updateSnackBar={props.updateSnackBar}
                />
            </Modal>

            {closeFileDirectory !==true ?

            <div className={Classes.childContainer}>
                <div>
                    <p id='cvb-adminProfile-backButton' onClick={goBack} className={Classes.backButton}>
                        <span><img alt='back button arrow' src={BackButtonArrow} /></span>
                        <p id='cvb-adminProfile-backButtonText' className={Classes.backButtonText}>Back To Workspaces</p>
                    </p>
                </div>
                {
                    workspaceStatus &&
                    <div style={{fontSize:'32px'}}>
                        {workspaceId}
                        <p id='cvb-workspaceFileDirectory-type-text' className={`${Classes.workspaceStatus} ${workspaceStatusBackground}`} >{workspaceStatus}</p>
                    </div>
                }
                <div className={Classes.headerDiv}>
                    <p id='cvb-workspaceFileDirectory-fileDirectory-label' className={Classes.fileDirectoryText}>File Directory</p>
                    <div className={Classes.headerOptions}>
                        <div className={Classes.headerOptionsIconContainer}>
                            {filesView === 'list'
                                ? <img id='cvb-workspaceFileDirectory-listViewImage' onClick={onListIconClick} src={ListActiveViewIcon} alt='List view' />
                                : <img id='cvb-workspaceFileDirectory-listViewImage' onClick={onListIconClick} src={ListViewIcon} alt='List view' />
                            }
                        </div>
                        <div className={Classes.headerOptionsIconContainer}>
                            {filesView === 'cards'
                                ? <img id='cvb-workspaceFileDirectory-cardViewImage' onClick={onCardIconClick} src={CardActiveViewIcon} alt='card view' />
                                : <img id='cvb-workspaceFileDirectory-cardViewImage' onClick={onCardIconClick} src={CardViewIcon} alt='card view' />
                            }
                        </div>
                        <div className={Classes.headerOptionsIconContainer}>
                            <DropZone
                                onDrop={(acceptedFiles) => onImageDrop(acceptedFiles, folderDirectory, uploadFileBoolean)}
                                multiple={false}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className='upload-image-div'>
                                            <button id='cvb-workspaceFileDirectory-uploadFileButton' className={`medium-size-button solid-button ${Classes.uploadButton}`}>Upload File</button>
                                        </div>
                                    </div>
                                )}
                            </DropZone>
                        </div>
                    </div>
                </div>
                <div className={Classes.filterContainer}>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={7}>
                            <div className={Classes.filterDropdownContainer}>
                                <div className={Classes.dropdownContainer}>

                                    <DropDown
                                        values={['10', '25', '50', '100']}
                                        handleFilterStateChange={onFilterValueChange}
                                        selectedFilter={itemsLength}
                                    />
                                </div>
                                <p id='cvb-workspaceFileDirectory-showingCount' className={Classes.showingText}>Showing {displayData.length - folderWorkSpace.length}</p>
                            </div>
                            <div className={Classes.breadCrum}>
                            <ul className={Classes.breadcromNavi}>
                                <li id='cvb-workspaceFileDirectory-home' onClick={(e) => getSelectedWorkspaceDirectoryMetadata()}> Home </li>

                                { breadcrumbCopy.length > 0 ?
                                  breadcrumbCopy.map((value, i) => { return <li key={v4()}>
                                      {i === breadcrumbCopy.length-1
                                  ? <span className={Classes.breadCrumDisable}>{`> ${value} `}</span>
                                    : <span onClick={(e) => breadcrumbApi(value, breadcrumbCopy)}>{` > ${value}`}</span> }</li> })
                                    :null }
                        </ul></div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={5}>
                            <div className={Classes.searchFileDropdownContainer}>
                                <OutlinedInput
                                    fullWidth
                                    value={searchInputVal}
                                    onChange={(e) => onSearchChange(e.target.value)}
                                    placeholder='Search'
                                    id='cvb-workspaceFileDirectory-fileSearchInput'
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" size="large">
                                                <img alt='visibility On' src={SearchIcon} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <div className={Classes.filesViewContainer}>
                    <div className={Classes.filesViewHeaderContainer}>
                        <Checkbox
                            checked={selectAll}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            onChange={(e) => onSelectAllClick(e.target.checked)}
                            id='cvb-workspaceFileDirectory-selectAllFile-checkbox'
                        />
                        <label className={Classes.selectAllLabel}>Select All</label>
                        <div className={Classes.fileOperationOptions}>
                        {
                            folderDirectory.includes('upload/') ? 
                            checkSelectedFiles() ?
                            <div id='cvb-workspaceFileDirectory-openDeleteModal-button' onClick={onOpenDeleteModal} className={Classes.fileOptionsIconContainer}>
                                <img src={DeleteBinIcon} alt='delete' />
                            </div>
                            :<div id='cvb-workspaceFileDirectory-openDeleteModal-disabled' className={Classes.fileOptionsIconContainerDisabled}>
                                <img src={DeleteBinIcon} alt='delete' />
                            </div> : null
                        }
                        {
                            !folderDirectory?.includes("upload/") && folderDirectory ?
                            <> 
                            {checkSelectedFiles() ?
                                <div id='cvb-workspaceFileDirectory-openShareModal-button' className={Classes.fileOptionsIconContainer}>
                                    <img src={ShareIcon}  onClick={onShareWorkspaceSelect} alt='share' />
                                </div>
                                :<div id='cvb-workspaceFileDirectory-openShareModal-disabled' className={Classes.fileOptionsIconContainerDisabled}>
                                    <img src={ShareIcon} alt='share' />
                                </div>
                            }
                            {checkSelectedFiles() ?
                                <div id='cvb-workspaceFileDirectory-openShareModal-button' className={`${Classes.fileOptionsIconContainer} ${Classes.cartIconContainer}`}>
                                    <img src={CartIconWhite}  onClick={addMultipleFilesToCart} alt='addToCart' className={Classes.cartIconImage} />
                                </div>
                                :<div id='cvb-workspaceFileDirectory-openShareModal-disabled' className={`${Classes.fileOptionsIconContainerDisabled} ${Classes.cartIconContainer}`}>
                                    <img src={CartIconWhite} className={Classes.cartIconImage} alt='addToCart' />
                                </div>
                            }
                            {/* {checkSelectedFiles() ?
                                <div className={Classes.fileOptionsIconContainer}>
                                    <img id='cvb-workspaceFileDirectory-download-button' onClick={onDownloadMultipleFiles} src={DownloadIcon} alt='download' />
                                </div>
                                :<div className={Classes.fileOptionsIconContainerDisabled}>
                                    <img id='cvb-workspaceFileDirectory-openShareModal-disabled' src={DownloadIcon} alt='download' />
                                </div> */
                            } </> : null
                        }
                            <div className={Classes.fileOptionsIconContainer}>
                                <img id='cvb-workspaceFileDirectory-refresh-button' className={Classes.changeColor} onClick={reloadComponent} src={refreshIcon} alt='download' />
                            </div>
                        </div>
                    </div>
                    <div className={Classes.filesMainContainer}>
                   {errorMessage !=="" ? <div> {errorMessage}</div> :null}
                   {progressBarTwo !== false ? <LinearProgress /> : null}
                     {filesView === 'cards' && <Grid container direction='row' spacing={2}>
                            {map(sortedActivities, (fileData, i) => {
                                const fileId = get(fileData, 'Key', '');
                                const isChecked = get(filesSelected, `${fileId}`, false);

                                return <WorkspaceFileCard
                                    key={v4()}
                                    fileData={fileData}
                                    onFileSelected={onFileSelected}
                                    isChecked={isChecked}
                                    onDownloadClick={onDownloadClick}
                                    onDeleteFile={onDeleteFile}
                                    workspaceId={workspaceId}
                                    index={i}
                                    addFileToCart={addFileToCart}
                                    progressBar={progressBar}
                                    progress={progress}
                                    folderDirectoryApi={folderDirectoryApi}
                                    breadcrumbCopy = {breadcrumbCopy}
                                    uploadClass = {uploadClass}
                                    usersForShare={usersForShare}
                                    folderDirectory={folderDirectory}
                                    piOwner={piOwner}
                                    />
                            })}
                            { (directoryLoader || selectedWorkspaceFilesMetadata === '') ?  
                                <div className={Classes.loaderWrapper}>
                                   {isEmpty(errorMessage) && <img id='cvb-workspaceFileDirectory-loader' src={LoaderImg} alt='loader'/>}
                                </div>
                                : ((isEmpty(sortedActivities) && isEmpty(errorMessage) && (selectedWorkspaceFilesMetadata !== '')) && <p id='cvb-workspaceFileDirectory-noResultFound'>No results found</p>)  
                            }
                        </Grid>
                        }
                        {filesView === 'list' &&
                            <div className={Classes.tableViewContainer}>
                                <WorkspaceFileListView
                                    fileData={sortedActivities}
                                    filesSelected={filesSelected}
                                    onFileSelected={onFileSelected}
                                    onSelectAllClick={onSelectAllClick}
                                    onDownloadClick={onDownloadClick}
                                    onDeleteFile={onDeleteFile}
                                    workspaceId={workspaceId}
                                    progressBar={progressBar}
                                    progress={progress}
                                    folderDirectoryApi ={folderDirectoryApi}
                                    emptyFolder={emptyFolder}
                                    usersForShare={usersForShare}
                                    folderDirectory={folderDirectory}
                                />
                            </div>
                        }
                    </div>

                </div>
                <div className={Classes.tablePagination}>
                    {
                            breadcrumbCopy &&
                                <TablePagination
                                maxCount={maxCount}
                                page={pageNumber}
                                setPage={setTablePage}
                            />
                           
                        
                    }
                    
                </div>
            </div>
            :null}
        </div>
    );
}

export default WorkspaceFileDirectory
