import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { Amplify } from 'aws-amplify';
import config from './config';
import initCornerstone from './initCornerstone';

//
initCornerstone();
Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        authenticationFlowType: 'CUSTOM_AUTH',		
	},
	oauth: {
		domain: config.oauth.domain,
		scope: config.oauth.scope,
		redirectSignIn: config.oauth.redirectSignIn,
		redirectSignOut: config.oauth.redirectSignOut,
		responseType: config.oauth.responseType,
	},
	federationTarget: config.oauth.federationTarget,
});
const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);
