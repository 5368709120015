import React, { useState, useMemo } from 'react'
import Grid from '@mui/material/Grid';
import Classes from './index.module.css';
import MuiPaper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import ProfileImage from '../../../assets/images/user/default-profile-image.png'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get'
import { SIGNUP_ERROR_TEXTS } from '../../../constants/strings';
import { VALID_EMAIL_REGEX } from '../../../constants/regex';
import ErrorText from '../error-text';
import { trimStringToTenChar } from '../../../utils/stringFormatter';
import Tooltip from '@mui/material/Tooltip'
import CloseIcon from '../../../assets/icons/close-small.svg'
import findIndex from 'lodash/findIndex'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const Paper = withStyles(theme => ({
    root: {
        height: '100%',
        boxShadow: '0px 3px 6px #00000029',
        border: '1px dashed #637BBD',
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        display: 'flex',
        width: 140,
        padding: '0px 5px',
        backgroundColor: '#FFFFFF'
    },
}))(MuiPaper);

const AddUsersToShare = props => {
    const [userText, setUserText] = useState({ text: '', isValid: false, isSubmit: false, invalidText: SIGNUP_ERROR_TEXTS.EMAIL, isAlreadyPresent: false });
    const { isWorkspaceShare = false } = props;
    const shareTitle = get(props, 'id', '');
    const { sharedUsersList = [] } = props;
    const { onAddUserClick = () => { } } = props;
    const { unshareForSelectedUser = () => { } } = props;
    const { errorMessage = '' } = props;
    const onUserNameChange = (val) => {
        try {
            setUserText({ ...userText, text: val, isSubmit: false });
        } catch (error) {
            console.log('error in setting text', error)
        }
    }

    const validateUserText = () => {
        let re = VALID_EMAIL_REGEX;
        re.test(userText['text']) ? setUserText({ ...userText, isSubmit: true, isValid: true })
            : setUserText({ ...userText, isSubmit: true, isValid: false });
        return re.test(userText['text']);
    }
    const checkForAlreadyPresentUser = () => {
        const isEmailValid = validateUserText();
        const isPresent = findIndex(sharedUsersList, (user) => user['email_id'] === userText['text']) > -1;
        if (isPresent) {
            setUserText({ ...userText, isValid: isEmailValid, isSubmit: true, isAlreadyPresent: true })
        } else {
            setUserText({ ...userText, isValid: isEmailValid, isSubmit: true, isAlreadyPresent: false })
        }
        return isPresent;
    }
    const onAddUserClickToShare = () => {
        try {
            const isValid = validateUserText();
            const isUserAlreadyPresent = checkForAlreadyPresentUser();
            if (isValid && !isUserAlreadyPresent) {
                setUserText({ text: '', isValid: false, isSubmit: false, invalidText: SIGNUP_ERROR_TEXTS.EMAIL });
                //call add user API
                const userData = { "sharedWith": userText['text'] };
                onAddUserClick(userData);
            }
        } catch (error) {
            console.log('enable to add users', error);
        }
    }
    const unshareWorkspace = (email) => {
        unshareForSelectedUser(email);
    }

    const userResults = useMemo(() => {
        const searchText = userText['text'] || "";
        return props.usersForShare?.filter(item => item.toLowerCase().includes(searchText.toLowerCase()))
    }, [props.usersForShare, userText['text']])

    const isAddUserDisabled = () => {
        return !props.usersForShare?.find((item) => item.toLowerCase() === userText['text']?.toLowerCase())
    }

    const accessText = isWorkspaceShare ? 'Users can access this workspace' : 'Users can access this notebook';
    return (
        <React.Fragment>
            <div className={Classes.container}>
                <div className={Classes.titleContainer}>
                    <p id='cvb-addUsersToShare-shareTitle' className={Classes.shareModalTitle}>{shareTitle}</p>
                </div>
                <div className={Classes.basicInfoContainer}>
                    {isWorkspaceShare && <div className={`${Classes.infoColumn} ${Classes.idColumn}`}>
                        <p id='cvb-addUsersToShare-id-label' className={Classes.columnTitle}>ID:</p>
                        <Tooltip id='cvb-addUsersToShare-id-tooltip' title={get(props, 'id', '')} arrow>
                            <p id='cvb-addUsersToShare-id-text' className={Classes.columnSubTitle}>{trimStringToTenChar(get(props, 'id', ''))}
                            </p>
                        </Tooltip>
                    </div>}
                    <div className={`${Classes.infoColumn} ${Classes.typeColumn}`}>
                        <p id='cvb-addUsersToShare-workspaceType-label' className={Classes.columnTitle}>Type:</p>
                        <p id='cvb-addUsersToShare-workspaceType-text' className={Classes.columnSubTitle}>{get(props, 'workspaceDetails.type', '')}</p>
                    </div>
                   {!isWorkspaceShare && <div className={`${Classes.infoColumn} ${Classes.sizeColumn}`}>
                        <p id='cvb-addUsersToShare-workspaceSize-label' className={Classes.columnTitle}>Size:</p>
                        <p id='cvb-addUsersToShare-workspaceDetails-text' className={Classes.columnSubTitle}>{get(props, 'workspaceDetails.size', '')}</p>
                    </div>}
                </div>
                <div className={Classes.userAccessInfoContainer}>
                    <p id='cvb-addUsersToShare-shareUserListCount' className={Classes.userNumber}>{`${sharedUsersList.length}`.padStart(2, '0')}</p>
                    <p id='cvb-addUsersToShare-accessText' className={Classes.accessDetail}>{accessText}</p>
                </div>
                <div className={Classes.addUserContainer}>
                    <div className={Classes.userTextInputContainer}>
                        <label id='cvb-addUsersToShare-user-label' htmlFor='userName'>User</label>
                        <div className={Classes.textInputButtonContainer}>
                            <div className={Classes.inputDropdownContainer}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={userResults}
                                    getOptionLabel={(option) => option}
                                    onChange={(e, newValue) => onUserNameChange(newValue)}
                                    onInputChange={(e, val) => { onUserNameChange(val) }}
                                    value={userText['text']}
                                    style={{ width: 400 }}
                                    renderInput={(params) => <TextField {...params}
                                        variant="outlined"
                                        label=""
                                        margin="dense"
                                        placeholder='user'
                                        onChange={e => onUserNameChange(e.target.value)} />}
                                />
                                {(userText['isSubmit'] && !userText['isValid']) &&
                                    <div id='cvb-addUsersToShare-errorText' className={Classes.errorTextWrapper}> <ErrorText errorMessage={SIGNUP_ERROR_TEXTS.EMAIL} /></div>
                                }
                                {(userText['isSubmit'] && userText['isValid']) && userText['isAlreadyPresent'] &&
                                    <div id='cvb-addUsersToShare-errorText' className={Classes.errorTextWrapper}> <ErrorText errorMessage={'User is already added.'} /></div>
                                }
                                {!isEmpty(errorMessage) &&
                                    <div id='cvb-addUsersToShare-errorText' className={Classes.errorTextWrapper}> <ErrorText errorMessage={errorMessage} /></div>
                                }
                            </div>
                            <button id='cvb-addUsersToShare-addToShare' onClick={onAddUserClickToShare} disabled={isAddUserDisabled()} className={`medium-size-button ${Classes.addUserButton}`}>Add user</button>
                        </div>
                    </div>
                    <div className={Classes.userCardsContainer}>
                        <Grid container direction='row' spacing={2}>
                            {map(sharedUsersList, (item) => {
                                const userEmail = get(item, 'email_id', '');
                                return (<Grid item className={Classes.userDetailsCard}>
                                    <Paper className={Classes.cardPaperCustom}>
                                        <img id={`cvb-addUsersToShare-unshare-closeIcon`} src={CloseIcon} alt='close icon' className={Classes.closeIconStyle} onClick={() => unshareWorkspace(userEmail)} />
                                        <img id='cvb-addUsersToShare-profileImage' alt='profile' className={Classes.userProfileImage} src={ProfileImage} />
                                        <p id='cvb-addUsersToShare-userEmail' className={Classes.userName}>{userEmail}</p>
                                    </Paper>
                                </Grid>)
                            })
                            }
                        </Grid>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddUsersToShare;