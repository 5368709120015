import React, { useState, useEffect } from 'react'
import {env} from '../../../env'
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import clsx from 'clsx';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import SideBarLogo from '../../../assets/images/BRAINCommons-Platform.svg'
import SideBarSmallLogo from '../../../assets/images/BC.svg'
import ImageLeftArrow from '../../../assets/icons/left.svg'
import ImageRightArrow from '../../../assets/icons/right.svg'
import ImgUpArrow from '../../../assets/icons/up-arrow.svg'
import ImgDownArrow from '../../../assets/icons/down-arrow.svg'
import CustomClass from './index.module.css'
import { SideBarOptions, COMMUNITY_MENU_ITEM } from '../../../constants/side-nav-bar-elements'
import { navigate } from '@reach/router'
import { v4 } from 'uuid'
import { useDispatch } from 'react-redux';
import { setOpenedTab } from '../../../redux/actions/adminDashboardActions';

export const drawerWidth = 240;
export const drawerWidthClosed = 72;

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overflow: 'visible',
    },
    drawerOpen: {
        width: drawerWidth,
        overflow: 'visible',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflow: 'visible',
        width: drawerWidthClosed,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(0, 2),
        ...theme.mixins.toolbar,
        marginTop: 20,
        overflow: "visible"

    }
}));

const SideNavBar = props => {

    const { open, toggleDrawer } = props;
    const { userDetails, isUserPrivate } = props;
    const [collapsedTabs, setCollapsedTabs] = useState([]);
    const classes = useStyles();
    const [optionsSource, setOptionsSource] = useState([]);
    const dispatch = useDispatch();

    const Drawer = withStyles(theme => ({
        paper: {
            position: open ? 'sticky' : 'fixed',
            height: open ? '100vh' : '100%'
        }
    }))(MuiDrawer);

    const getSelectedIndex = () => {
        const pathName = window.location.pathname;
        switch (true) {
            case pathName.includes("/my-stuff"): return "myStuffPath";
            case pathName.includes('/dashboard'): return "dashboardPath";
            case pathName.includes("/users"): return "usersPath";
            case pathName.includes("/admin/user-profile"): return "usersPath";
            case pathName.includes("/my-uploads"): return "myUploadsPath";
            case pathName.includes("/invoice"): return "invoicePath";
            case pathName.includes("/pricing"): return "pricingPath";
            case pathName.includes("/"): return "explorePath";
            default: return 0;
        }
    }

    const getInnerSelectedIndex = () => {
        const pathName = window.location.pathname;
        if ((props.userDetails?.resourceActions?.data_upload_history && pathName.includes("my-uploads")) &&
            (!props.userDetails?.resourceActions?.structured_data && !props.userDetails?.resourceActions?.unstructured_data)) {
            return 0;
        }

        switch (true) {
            case pathName.includes("explore"): {
                const pathElement = pathName.split("/")[2];
                switch (pathElement) {
                    case "tools": case "community": return 2;
                    case "cohorts": return 0;
                    case "data-model": return 1;
                    case "data": return 3;
                    case "project-gallery": return 4;
                    default: return -1;
                }
            }
            case pathName.includes("/analysis/analyze-data") || pathName.includes("structured-data/upload-data") || pathName.includes("project-gallery") || pathName.includes("/users/invited-users"): return 0;
            case pathName.includes("history"): return 2;
            case pathName.includes("unstructured-data") || pathName.includes("/explore/data"): return 1;
            case pathName.includes("tools"): return 3;

            case pathName.includes("/my-profile"): return -2;
            default: return -1;
        }
    }
    const selectedIndex = getSelectedIndex();
    const innerSelectedIndex = getInnerSelectedIndex()


    useEffect(() => {
        let options = [];
        SideBarOptions.map((sideBar) => {
            if (sideBar.resourceName === "my_uploads") {
                sideBar.innerElements = sideBar.innerElements.filter(
                    (upload) => {
                        return (
                            upload.resourceName in
                            props.userDetails?.resourceActions
                        );
                    }
                );
                if (sideBar.innerElements?.length) {
                    options.push(sideBar);
                    sideBar.navigateRoute =
                        sideBar.innerElements[0].navigateRoute;
                }
            }
            else if (sideBar.resourceName === "users" && props.userDetails?.resourceActions?.users) {
                sideBar.innerElements = sideBar.innerElements.filter(
                    (upload) => {
                        return ('view_invited_users' in props.userDetails?.resourceActions?.users);
                    }
                );
                options.push(sideBar);
            }
            else if (
                sideBar.resourceName === "home" ||
                sideBar.resourceName in props.userDetails?.resourceActions
            ) {
                if (sideBar.resourceName === "explore") {
                    let currentMenuItem = env.REACT_APP_COMMUNITY_HIDDEN !== 'true' ? {
                        ...sideBar,
                        innerElements: [
                            ...sideBar.innerElements,
                            COMMUNITY_MENU_ITEM,
                        ],
                    } : {
                        ...sideBar,
                        innerElements: [
                            ...sideBar.innerElements,
                        ],
                    };
                    if (!(props.userDetails?.resourceActions?.explore_data?.explore_data)) {

                        const updatedInnerElements = currentMenuItem?.innerElements.filter(ele => ele.title !== 'Data');
                        currentMenuItem = {
                            ...currentMenuItem,
                            innerElements: updatedInnerElements
                        }
                    }

                    options.push(currentMenuItem);
                } else {
                    options.push(sideBar);
                }
            }
        });
        setOptionsSource(options);
    }, [userDetails, isUserPrivate]);

    const collapseChild = (index) => {
        const cloneList = collapsedTabs;
        cloneList.push(index);
        setCollapsedTabs(cloneList)
    }

    const collapseAllTabs = () => {
        getInnerSelectedIndex()
        const cloneList = collapsedTabs;
        optionsSource.map((item, i) => {
            if (!cloneList.includes(i)) {
                cloneList.push(i)
            }
        })
        setCollapsedTabs(cloneList)
    }

    useEffect(() => {
        collapseAllTabs()
    }, [props.profileClick])


    const expandChild = index => {
        let newList = collapsedTabs.filter(item => item !== index);
        setCollapsedTabs(newList)
    }

    return [
        <Drawer
            key={v4()}
            variant="permanent"
            className={clsx(classes.drawer,
                {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
            classes={{
                paper: clsx(
                    CustomClass.sideMenuBackgroundImage, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <div className={classes.toolbar}>
                <img className={CustomClass.SideBarLogo} src={open ? SideBarLogo: SideBarSmallLogo} alt="Sidebar-Logo" />
            </div>
            <List>
                <div className={CustomClass.sidebarContainerMenu}>
                    {
                        optionsSource.map((item, index) => {
                            // if (item.title === "My Uploads" && props.isSubmitter === false) {
                            //     return null
                            // }
                            return <div key={v4()}>
                                <ListItem key={v4()} className={clsx(CustomClass.SideMenuItems, {
                                    [CustomClass.selectedListItem]: item.id === selectedIndex
                                })} button id={`cvb-sideNavBar-${item.id}-button`} onClick={() => { navigate(item.navigateRoute) }}>
                                    <ListItemIcon><img src={item.imageSource} className={CustomClass.NavIcon} alt={"icon-" + item.title} /> </ListItemIcon>
                                    <ListItemText primary={item.title} className={clsx(CustomClass.sideBarNavTitle, {
                                        [CustomClass.hideItem]: !open
                                    })} />
                                    {
                                        (item.innerElements && item.innerElements.length > 0) &&
                                        <React.Fragment>
                                            {selectedIndex === item.id && !collapsedTabs.includes(index) ?
                                                <div id={`cvb-sideNavBar-innerItem-${item.id}-collapseButton`} onClick={() => collapseChild(index)}><img src={ImgUpArrow} alt="Up arrow" /></div>
                                                : <div id={`cvb-sideNavBar-innerItem-${item.id}-expandButton`} onClick={() => expandChild(index)}><img src={ImgDownArrow} alt="down arrow" /></div>}
                                        </React.Fragment>
                                    }
                                </ListItem>
                                {
                                    selectedIndex === item.id && item.innerElements && open && !collapsedTabs.includes(index) &&
                                    < ul className={CustomClass.innerMenuItems} key={v4()}>
                                        {
                                            item.innerElements.map((innerItem, innerIndex) => {
                                                return (
                                                    <li
                                                        key={v4()}
                                                        id={`cvb-sideNavBar-innerElements-${item.title}`}
                                                        className={innerIndex === innerSelectedIndex ? CustomClass.selectedInnerMenuItem : ""}
                                                        onClick={() => {
                                                            if (innerItem.title === 'Community' && isUserPrivate) {
                                                                navigate('/my-profile')
                                                            } else if (item.title === 'Dashboard') {
                                                                dispatch(setOpenedTab(item.innerElements[innerIndex]))
                                                            } else {
                                                                navigate(innerItem?.navigateRoute)
                                                            }
                                                        }}>
                                                        <span style={{ whiteSpace: 'pre-wrap' }}>{innerItem.title}</span>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                }
                            </div>
                        })}
                </div>
            </List>
            <button key={v4()} onClick={toggleDrawer} className={clsx({
                [`${CustomClass.openIcon} ${CustomClass.openCloseIcon} click-outside-ignore`]: !open,
                [`${CustomClass.closeIcon} ${CustomClass.openCloseIcon} click-outside-ignore`]: open
            })}>
                {open ? <img id='cvb-sideNavbar-leftArrowImage' src={ImageLeftArrow} alt="left arrow" /> : <img id='cvb-sideNavbar-rightArrowImage' src={ImageRightArrow} alt="left arrow" />}
            </button>
        </Drawer >,


    ]
}

export default SideNavBar
