import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import CustomLoader from '../custom-loader';
import DropDown from '../dropdown';
import { Grid } from '@mui/material';
import ErrorText from '../../controls/error-text';
import { isEmpty, map } from 'lodash';
import CustomModal from '../modal';
import OutlinedInput from "@mui/material/OutlinedInput";

const useStyles = makeStyles(() => ({
  manifestInput: { marginTop: '1em', height: '150px' },
}));

const ExportWorkspaceModal = ({
  open,
  loading,
  handleClose,
  modalProps,
  allWorkspaces,
  defaultSelectedWorkspace,
  setDefaultSelectedWorkspace,
  updateSelectedWorkspace,
  updateManifestFileName,
  failedWorkspaceValidation
}) => {
  const classes = useStyles();
  const [workspaceValues, setWorkspaceValues] = React.useState([]);
  const [manifestFileName, setManifestFileName] = React.useState('');

  const onWorkspaceOptionChange = (value) => {
    setDefaultSelectedWorkspace(value);
    if (updateSelectedWorkspace) updateSelectedWorkspace(value);
  };

  const onManifestFileNameChange = (event) => {
    const manifestFileName = event.target.value;
    setManifestFileName(manifestFileName)
    updateManifestFileName(manifestFileName);
  }

  React.useEffect(() => {
      setManifestFileName('');
      updateManifestFileName(manifestFileName);
  }, [open])

  React.useEffect(() => {
    const workspacesIds = map(
      allWorkspaces,
      (workspace) => workspace['workspace_id']
    );
    if (workspacesIds?.length) {
      setWorkspaceValues(workspacesIds);
      if (workspacesIds?.length === 1) {
        onWorkspaceOptionChange(workspacesIds[0]);
      }
    }
  }, [allWorkspaces]);

  return (
    <CustomModal open={open} handleClose={handleClose} dialogProps={modalProps}>
      <div>
        {workspaceValues?.length > 1 ? (
            <Grid item xs={6}>
              <label htmlFor='workspace' id="cvb-mySavedSearch-fileNameLabel">Workspace<sup> *</sup></label>
              <DropDown
                  values={workspaceValues}
                  handleFilterStateChange={onWorkspaceOptionChange}
                  selectedFilter={defaultSelectedWorkspace}
                  placeholder={'Select workspace'}
                  name='workspace'
              />
              {(failedWorkspaceValidation && isEmpty(defaultSelectedWorkspace)) && (
                  <div
                      className={classes.errorTextDiv}
                      id="cvb-mySavedSearch-workspaceError"
                  >
                    <ErrorText errorMessage="Please select workspace"/>
                  </div>
              )}
            </Grid>
        ) : (
          <p id="cvb-mySavedSearch-workspaceDescription">
            {' '}
            You are exporting Cohort to{' '}
            <span className={classes.workspaceName}>
              <b>{workspaceValues[0]}</b>
            </span>{' '}
            Workspace.
          </p>
        )}
        <Grid item xs={6} className={classes.manifestInput}>
          <label htmlFor='manifestFileName' id="cvb-mySavedSearch-fileNameLabel">Manifest File Name<sup> *</sup></label>
          <OutlinedInput
              type="text"
              value={manifestFileName}
              fullWidth
              inputProps={{maxLength: 256}}
              id='cvb-mySavedSearch-fileNameInput'
              name='manifestFileName'
              onChange={onManifestFileNameChange}
              placeholder='Manifest File Name'
          />

          {(failedWorkspaceValidation && isEmpty(manifestFileName)) && (
            <ErrorText errorMessage="Enter Manifest File Name" />
          )}

          {loading && (
              <CustomLoader styleProp={{marginTop: '25px'}}/>
          )}
        </Grid>
        </div>
    </CustomModal>
);
};

export default ExportWorkspaceModal;

ExportWorkspaceModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
};
