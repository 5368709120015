import { connect } from 'react-redux'
import { saveCohortWithoutID } from '../../../api/analysis-api'
import {
    getExploreDataTotalCount, updateExcludeFromExploration } from "../../../redux/actions/exploreDataActions"
import {
    addNewAnalysis, setSelectedAnalysis,
    removeAnalysis, saveAnalysisCohort
} from '../../../redux/actions/analysisDataActions'
import { updateSnackBar } from '../../../redux/actions/globalActions'

import Explore from './Explore.jsx'

const mapStateToProps = state => {
    return {
        analysisAttributes: state.analyzeData.analysisAttributes,
        selectedAnalysis: state.analyzeData.selectedAnalysis,
        analysisCounter: state.analyzeData.analysisCounter,
        excludeFromExploration: state.exploreData.excludeFromExploration,
        totalCount: state.exploreData.totalCount,
        userDetails: state.user.userDetails
    }
}
const mapDispatchToProps = dispatch => {
    return {
        addNewAnalysis: (value) => dispatch(addNewAnalysis(value)),
        setSelectedAnalysis: (value) => dispatch(setSelectedAnalysis(value)),
        removeAnalysis: (value) => dispatch(removeAnalysis(value)),
        saveAnalysisCohort: (value) => dispatch(saveAnalysisCohort(value)),
        saveCohortWithoutID: async (body) => await saveCohortWithoutID(body),
        getExploreDataTotalCount: async (exploreFilters, ExcludeFromExploration, processedFilterValue) => {
            await dispatch(getExploreDataTotalCount(exploreFilters, ExcludeFromExploration, processedFilterValue))
        },
        updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type)),
        updateExcludeFromExploration: async value => dispatch(updateExcludeFromExploration(value)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Explore)