import React, { useEffect, useState } from 'react'
import Classes from './index.module.css';
import isEmpty from 'lodash/isEmpty'
import Grid from '@mui/material/Grid'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip'
import Paper from '@mui/material/Paper';
import { getStudyDownloadPresignedURL } from '../../../api/study-api'
import lowerCase from 'lodash/lowerCase'
import moment from 'moment'
import LoaderImg from '../../../assets/images/loader.gif'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import Collapse from '@mui/material/Collapse';
import { navigate } from '@reach/router';

const WorkspaceFileDownloadOrders = props => {
    const [isRequestsLoading, setIsRequestsLoading] = useState(false);
    const [workspaceFileDownloadData, setWorkspaceFileDownloadData] = useState([])
    useEffect(() => {
        getWorkspaceFileDownloads();
    }, [props.allWorkspacesCarts])

    const getWorkspaceFileDownloads = () => {
        try {
            //API CALL GET
            let fileDownloadData = [];
            const res = props.allWorkspacesCarts?.workspace_carts;
            for (let key in res) {
                fileDownloadData.push({
                    title: key,
                    expand: true,
                    expandedData: res[key]
                })
            }
            setWorkspaceFileDownloadData(fileDownloadData);
        } catch (e) {
            console.log('error', e)
        }
    }

    let count = 1;
    const downloadURL = (url) => {
        let hiddenIFrameID = 'hiddenDownloader' + count++;
        let iframe = document.createElement('iframe');
        iframe.id = hiddenIFrameID;
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        iframe.src = url;
    }

    const useRowStyles = makeStyles({
        root: {
            '& > *': {
                borderBottom: 'unset',
            },
        },
    });
    const onOrderDetailsClick = (workspaceFileData, fileData) => {
        props.updateSelectedWorkspaceFileData(workspaceFileData);
        navigate('/workspace-file-download-request/' + workspaceFileData?.title+'/'+fileData?.order_id)
    }
   
    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);
        const classes = useRowStyles();

        return (
            <React.Fragment>

                <TableRow className={classes.root}>
                    <TableCell>
                        {
                            row?.expand ?
                                <IconButton id='cvb-workspaceFileDownload-expandRowButton' aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton> : null
                        }

                    </TableCell>
                    <TableCell className={[Classes.organizationCell, Classes.userTableCell].join(" ")} component="th" scope="row">
                        <Tooltip id='cvb-workspaceFileDownload-tableRow-typeTitleValue' title={row?.title || ''}>
                            <span id='cvb-workspaceFileDownload-tableRow-typeValue' className={Classes.typeText}>{row?.title}</span>

                        </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell component="th" scope="row"></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, background: '#f9f9f9' }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>

                            <Table id='cvb-workspaceFileDownload-table' size="small" aria-label="purchases">
                                <TableHead style={{ display: 'none' }}>
                                    <TableRow>
                                        <TableCell id='cvb-myStuff-tableHead-searchColumn' width="5%" className={Classes.searchColumn} ></TableCell>
                                        <TableCell id='cvb-myStuff-tableHead-typeColumn' width="20%" className={Classes.searchColumn} >Type</TableCell>
                                        <TableCell id='cvb-myStuff-tableHead-subStatusColumn' width="16%" className={Classes.searchColumn}  align="left">Submission Status</TableCell>
                                        <TableCell id='cvb-myStuff-tableHead-subDateColumn' width="16%"className={Classes.searchColumn}  align="left">Submission Date</TableCell>
                                        <TableCell id='cvb-myStuff-tableHead-approvalStatusColumn' width="15%" className={Classes.searchColumn}  align="left">Approval Status</TableCell>
                                        <TableCell id='cvb-myStuff-tableHead-expiryDateColumn' width="25%" className={Classes.searchColumn}  align="left">Expiry Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        row?.expand ?

                                            row.expandedData.map((expandedRow, i) => {
                                                let statusStyle = '';
                                                switch (lowerCase(expandedRow?.status)) {
                                                    case 'open': statusStyle = Classes.draftText; break;
                                                    case 'submitted for approval': statusStyle = Classes.draftText; break;
                                                    case 'rejected': statusStyle = Classes.pendingText; break;
                                                    case 'approved': statusStyle = Classes.submitText; break;
                                                    case 'ready for download': statusStyle = Classes.submitText; break;
                                                    case 'delivered': statusStyle = Classes.deliveredBoxText; break;
                                                    case 'scheduled for delivery': statusStyle = Classes.draftText; break;
                                                    default: break;
                                                }
                                                const statusLabel = `${expandedRow?.status}`.replaceAll('_',' ');
                                                return (
                                                    <TableRow key={expandedRow.id}>
                                                        <TableCell className={`${Classes.organizationCell} ${Classes.userTableCell} ${Classes.iconsColumn}`} style={{visibility: 'hidden'}} component="th" scope="row">{'aa'}</TableCell>
                                                        <TableCell className={[Classes.typeCell, Classes.userTableCell].join(" ")} id='cvb-workspaceFileDownload-tableRow-expandedRow-type' component="th" scope="row">
                                                            {expandedRow?.order_id}
                                                        </TableCell>
                                                        <TableCell className={`${Classes.organizationCell} ${Classes.submissionDate} ${Classes.userTableCell}`} id='cvb-workspaceFileDownload-tableRow-expandedRow-type' component="th" scope="row">
                                                            {expandedRow?.created_at ? (moment.utc(expandedRow?.created_at)?.local().format('MM/DD/YYYY') + ' at ' + moment.utc(expandedRow?.created_at)?.local().format('hh:mm A')) : ''}
                                                        </TableCell>
                                                        <TableCell className={`${Classes.organizationCell} ${Classes.updatedDate} ${Classes.userTableCell}`} id='cvb-workspaceFileDownload-tableRow-expandedRow-type' component="th" scope="row">
                                                            {expandedRow?.updated_at ? (moment.utc(expandedRow?.updated_at)?.local().format('MM/DD/YYYY') + ' at ' + moment.utc(expandedRow?.updated_at)?.local().format('hh:mm A')) : ''}
                                                        </TableCell>
                                                        <TableCell className={[Classes.statusColumn, Classes.userTableCell].join(" ")} id='cvb-workspaceFileDownload-tableRow-expandedRow-type' component="th" scope="row">
                                                            <span id='cvb-workspaceFileDownload-studyDownload-includeUnstructuredFiles' className={`${Classes.statusText} ${statusStyle}`}>
                                                                {statusLabel}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell className={[ Classes.userTableCell].join(" ")} component="th" scope="row">
                                                            {<span className={Classes.actionLink} onClick={() => props.onOrderDetailsClick(row, expandedRow)}>
                                                                {expandedRow?.status === 'READY_FOR_DOWNLOAD' ? 'Proceed To Download' : 'Order Details'}
                                                            </span>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }) : null
                                    }
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        )
    }

    return (
        <>
            <div className={Classes.container}>
                <div className={Classes.headerContainer} id='cvb-workspaceFileDownload-studyDownload-blockContainer'>
                    <p className={Classes.sectionHeaders} id='cvb-workspaceFileDownload-studyDownload-headingText'>My WorkSpace File Download Orders</p>
                </div>
                {!isEmpty(workspaceFileDownloadData) ? <Grid container lg={9} item xs={12} md={12} spacing={4} className={Classes.authorizationContainer} >
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={Classes.searchColumn} width="5%"></TableCell>

                                    <TableCell id='cvb-workspaceFileDownload-tableHeaderStatus' className={Classes.searchColumn} width="20%%" align="left">Order ID</TableCell>
                                    <TableCell id='cvb-workspaceFileDownload-tableHeaderDate' className={Classes.searchColumn} width="20%" align="left">Created At</TableCell>
                                    <TableCell id='cvb-workspaceFileDownload-tableHeaderApprovalStatus' className={Classes.searchColumn} width="20%" align="left">Updated At</TableCell>
                                    <TableCell id='cvb-workspaceFileDownload-tableHeaderExpiryDate' className={Classes.searchColumn}  width="20%%" align="left">Status</TableCell>
                                    <TableCell id='cvb-workspaceFileDownload-tableHeaderType' className={Classes.typeColumn} width="15%">Actions</TableCell>
                                </TableRow>

                            </TableHead>
                            <TableBody>
                                {workspaceFileDownloadData.map((row) => (
                                    <Row key={row.id} row={row} onOrderDetailsClick={onOrderDetailsClick} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                    : isRequestsLoading ?
                        <div id="cvb-workspaceFileDownload-studyDownload-inputChips" className={` ${Classes.overlayLoader}`}>
                            <img src={LoaderImg} alt="loader" />
                        </div>
                        :
                        <div id="cvb-workspaceFileDownload-studyDownload-noDownloadRequest">You have no workspace file download requests.</div>
                }
            </div>
        </>
    )
}

export default WorkspaceFileDownloadOrders;