import React, { useEffect } from 'react'
import moment from 'moment';
import { Auth } from 'aws-amplify'

const SESSION_TIMEOUT_MINUTES = 15;

function SessionTimeoutCheckerHook() {
    
    const handleSessionTimeout = () => {
        const lastTokenAccessedTime = localStorage.getItem("lastTokenAccessedTime")
        if (lastTokenAccessedTime) {
            const time = moment(lastTokenAccessedTime).toDate().getTime();
            const time30minsAgo = moment().subtract(SESSION_TIMEOUT_MINUTES, 'minutes').toDate().getTime();
            if (time < time30minsAgo) {
                logout();
            }
        }
    }

    const logout = async () => {
        try {
            await Auth.signOut().catch(e => { })
            // Retrieve the 'columnsData' value before clearing local storage
            const cohortsColumns = localStorage.getItem('myCohortsColumns');
            const projectsColumns = localStorage.getItem('availableProjectsColumns');
            localStorage.clear();
            localStorage.setItem('myCohortsColumns', cohortsColumns);
            localStorage.setItem('availableProjectsColumns', projectsColumns);
            sessionStorage.clear();
            window.location.href = "/"
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => handleSessionTimeout(), [])

    return;
}

export default SessionTimeoutCheckerHook;
