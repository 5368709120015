import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classes from "./index.module.css"
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DownArrow from '../../../assets/icons/W-DropDown.XS.svg'
import UpArrow from '../../../assets/icons/up-arrow.svg'
import SearchIcon from '../../../assets/icons/search-icon.svg';
import CloseSmall from '../../../assets/images/close.png';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { v4 } from 'uuid'
import isEmpty from 'lodash/isEmpty'
const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
    }
}));

function DropDown(props) {
    const {
        searchable = false,
        customClass = "",
        values = [],
        handleFilterStateChange,
        selectedFilter,
        selectedValues = [],
        parseText = false,
        disable,
        filterCategory,
        placeholder = 'Select',
        isCountryCodeDropdown,
        customMoreFilterWrapper = null,
        isClearable = false,
        labelAliasing,
        error
    } = props

    const materialClasses = useStyles();
    const [open, setOpen] = React.useState(false);
    const [searchText, setSearchText] = useState("")
    const [currentElementTarget, setCurrentElementTarget] = useState('');
    useEffect(() => {
        if (open && (props?.scrollToSelectedDropdown || false)) {
            props.scrollToSelectedDropdown(currentElementTarget);
        }
    }, [open, currentElementTarget])
    const handleClick = (e) => {
        setOpen(prev => !prev);
        if (props?.scrollToSelectedDropdown || false) {
            setCurrentElementTarget(e.currentTarget)
        }
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const renderIcon = () => {
        if (open) {
            return <img id='cvb-dropDown-upArrowIcon' className={classes.dropdownIcon} src={UpArrow} alt="Up Arrow" />
        } else {
            return <img id='cvb-dropDown-downArrowIcon' className={classes.dropdownIcon} src={DownArrow} alt="downarrow" />
        }

    }

    const filteredValues = values.filter(item => item?.toString()?.toLowerCase()?.includes(searchText.toLowerCase())) || [];
    const filterCountryCodes = values.filter(item => item?.name?.toString()?.toLowerCase()?.includes(searchText.toLowerCase())) || [];
    const isValueSelected = value => {
        if (Array.isArray(selectedValues)) {
            return selectedValues.indexOf(value) >= 0
        }
        else {
            return selectedValues === value;
        }

    }

    const handleKeyDown = (event, type, item, filterCategory) => {
        if ((event.key === 'Tab' || event.key === 'Enter') && !open) {
            handleClick();
            event.preventDefault();
        }
        if (type === 'menuItem' && event.key === 'Enter') {
            handleFilterStateChange(item, filterCategory);
            handleClick();
        }
    }
    const onClearInput = (e) => {
        handleFilterStateChange('', filterCategory);
        e.stopPropagation();
    }
    const filterPopoverWrapper = isCountryCodeDropdown ? `${classes.moreFiltersWrapper} ${classes.countryPopover}` : classes.moreFiltersWrapper
    const formatItem = inputString => {

        if (labelAliasing?.[inputString?.toLowerCase()]) {
            return labelAliasing[inputString?.toLowerCase()]
        }
        if (!inputString || typeof inputString != "string") {
            return inputString;
        }
        const removedUnderscores = inputString?.split("_").join(" ");
        const trimmedString = removedUnderscores.trim()
        return trimmedString.charAt(0).toUpperCase() + trimmedString.slice(1)
    }


    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={`${materialClasses.wrapper} ${classes.outline}`} tabIndex={0} onKeyDown={handleKeyDown}>
                <div id='cvb-dropDown-dropDownTitleContainer-button' className={`${classes.dropdownTitle} ${customClass} ${disable ? classes.disabled : ''} ${error ? classes.error : ''} `} onClick={handleClick}  >
                    <span id='cvb-dropDown-dropDownTitle-heading' className={classes.selectedFilterInput}>{!isEmpty(selectedFilter) ? (parseText ? formatItem(selectedFilter) : selectedFilter) : <span className={classes.placeHolderDropDownText}>{placeholder}</span>}<span>{renderIcon()}</span></span>
                    {isClearable && <span className={classes.clearIconWrapper}>
                        <img id='cvb-dropDown-clearInputImage-button' alt='clear' onClick={onClearInput} className={classes.searchDropdownIcon} src={CloseSmall} />
                    </span>}
                </div>
                {open && !disable ? (
                    <div className={customMoreFilterWrapper || filterPopoverWrapper}>
                        {
                            searchable &&
                            <div className={classes.searchDropdown}>
                                <OutlinedInput fullWidth type='text'
                                    value={searchText}
                                    placeholder='Search'
                                    onChange={e => setSearchText(e.target.value)}
                                    className='signup-inputs-large' name='password'
                                    disabled={disable}
                                    id='cvb-dropDown-searchInput'
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" size="large">
                                                <img alt='visibility On' className={classes.searchDropdownIcon} src={SearchIcon} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </div>
                        }
                        <ul className={classes.moreFiltersPopover}>
                            {
                                !isCountryCodeDropdown && filteredValues.map((item, index) => {
                                    return <li key={v4()} className={classes.dropdownOptionItem} tabIndex={0}
                                        id={`menu-item-${index}`}
                                        onKeyDown={(e) => { handleKeyDown(e, 'menuItem', item, filterCategory); }}
                                        onClick={() => { handleFilterStateChange(item, filterCategory); setOpen(false) }}>
                                        {isValueSelected(item) && <span>*</span>}{parseText ? formatItem(item) : formatItem(item)}
                                    </li>
                                })
                            }
                            {
                                isCountryCodeDropdown && filterCountryCodes.map((item, index) => {
                                    return <li key={v4()} className={classes.dropdownOptionItem} tabIndex={0}
                                        id={`menu-item-${index}`}
                                        onKeyDown={(e) => { handleKeyDown(e, 'menuItem', item, filterCategory); }}
                                        onClick={() => { handleFilterStateChange(item, filterCategory); setOpen(false) }}>
                                        {isValueSelected(item?.name) && <span>*</span>}{`+${item?.callingCodes[0]} ${item?.name}`}
                                    </li>
                                })
                            }
                        </ul>

                    </div>
                ) : null}
            </div>
        </ClickAwayListener>
    );
}

export default DropDown;
