import React, { useEffect, useState, useRef } from 'react'
import Classes from './index.module.css'
import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Grid'
import classes from './index.module.css';
import { COUNTRY_LIST } from '../../../constants/strings'
import ErrorText from '../error-text'
import { VALID_EMAIL_REGEX } from '../../../constants/regex'
import moment from 'moment';
import capitalize from 'lodash/capitalize'
import SignatureCanvas from 'react-signature-canvas'
import DropDown from '../../commons/dropdown';
import CommonDatePicker from '../../commons/common-date-picker';
import isEmpty from 'lodash/isEmpty';
import {  getOrganizationList } from '../../../api/profile-api'

export default function InstitutionalCertification(props) {
    const {
        institution,
        setInstitution,
        signatureEmail,
        setSignatureEmail,
        streetAddressOfInst,
        setStreetAddressOfInst,
        cityOfInst,
        setCityOfInst,
        stateOfInst,
        setStateOfInst,
        zipCodeOfInst,
        setZipCodeOfInst,
        countryOfInst,
        setCountryOfInst,
        certificateName,
        setCertificateName,
        adminName,
        setAdminName,
        isFormSubmitted,
        currentZone,
    } = props;
    const sigRef = useRef();
    const clearSignature = () => {
        sigRef.current.clear();
    }

    const [userDetails, setUserDetails] = useState({});
    useEffect(() => {
        setUserDetails(props.userDetails);
        getOrganizations();
        setAdminName(capitalize(props.userDetails?.["first_name"]) + " " + capitalize(props.userDetails?.["last_name"]))
    }, [props.userDetails])
    

    const userName = (capitalize(userDetails?.["first_name"]) + " " + capitalize(userDetails?.["last_name"]));

    const isFieldValid = field => !!props?.[field];

    const isEmailValid = email => {
        let reg = VALID_EMAIL_REGEX;
        return reg.test(email);
    }

    const getOrganizations = async () => {
        try {
            const res = await getOrganizationList();
            const orgResList = res?.organizations || [];
            if(orgResList?.length){
                for(let organization of orgResList){
                    if(organization.id === props.userDetails?.organization_id && organization?.name === "Other"){
                        if(isEmpty(institution) && isEmpty(cityOfInst) && isEmpty(stateOfInst) && isEmpty(zipCodeOfInst)
                        && isEmpty(countryOfInst) && isEmpty(streetAddressOfInst)){
                            setStreetAddressOfInst(props.userDetails?.institution_details?.streetAddress)
                            setCityOfInst(props.userDetails?.institution_details?.city)
                            setStateOfInst(props.userDetails?.institution_details?.state)
                            setZipCodeOfInst(props.userDetails?.institution_details?.zipCode)
                            setCountryOfInst(props.userDetails?.institution_details?.country)
                            setInstitution(props.userDetails?.organization)
                        }
                    }
                }
            }
        } catch (error) {
            console.log('error in getting organization list')
        }
    }

    const renderErrorText = (field, name, isEmail) => {
        if (!isFormSubmitted) {
            return null;
        }
        if (isFormSubmitted && !isFieldValid(field)) {
            return <ErrorText errorMessage={`Enter a valid ${name} `} />
        }
        if (isEmail && !isEmailValid(props?.[field])) {
            return <ErrorText errorMessage={`Enter a valid ${name} `} />
        }
        return null;
    }
    const currentDate = moment(new Date()).format('MM-DD-YYYY');
    return (
        <div className={Classes.mainAgreementContainer}>
            <div className={Classes.impTitleHeaderContainer}>
                <p className={Classes.alertTitle}>Important </p>
                <p>Please complete the Institutional Certification and submit it to your Authorizing Official (AO) at your institution or organization. It will need to be approved by the AO at your institution or organization in order for you to obtain access to {currentZone} data. </p>
                <p>Authorizing Official: This means an executive, officer or other individual who is authorized to sign contracts on behalf of your organization or company. You can find out who that is by contacting the office of your legal counsel or chief executive. </p>
            </div>
            <Grid container direction='column'>
                <h3 className={Classes.agreementHeadingText}>Institutional Certification</h3>
                <p className={Classes.certificationDateText}>
                    Date:
                    <span className={Classes.dateValue}>{currentDate}</span>
                </p>
                <p className={Classes.aoHeaderText}>Authorizing Official</p>
                <Grid item xs={7}>
                    <Grid container direction='row' spacing={2}>
                        <Grid item xs={6}>
                            <label htmlFor='streetAddress'>Name of the Institution<sup> *</sup></label>
                            <OutlinedInput
                                fullWidth
                                value={institution}
                                error={(isFormSubmitted && !institution)}
                                onChange={e => setInstitution(e.target.value)}
                                placeholder='Enter Institution Name'
                                id='institutionName'
                            />
                            {renderErrorText("institution", "Institution name")}
                        </Grid>
                        <Grid item xs={6}>
                            <label htmlFor='streetAddress'>Email of Authorizing Official at the Institution<sup> *</sup></label>
                            <OutlinedInput
                                fullWidth
                                error={(isFormSubmitted && renderErrorText("signatureEmail", "signature email", true))}
                                value={signatureEmail}
                                onChange={e => setSignatureEmail(e.target.value)}
                                placeholder='Enter email' />
                            {renderErrorText("signatureEmail", "signature email", true)}
                        </Grid>
                    </Grid>
                </Grid>
                <p className={Classes.contactFormTitles}>Institution Address</p>
                <Grid item xs={7}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <label htmlFor='country'>Country<sup> *</sup></label>
                                    <DropDown
                                        id='country'
                                        values={COUNTRY_LIST}
                                        error={(isFormSubmitted && renderErrorText("countryOfInst", "Country"))}
                                        handleFilterStateChange={setCountryOfInst}
                                        selectedFilter={countryOfInst}
                                        placeholder={'Select Country'}
                                        searchable
                                        isClearable
                                    />
                                    {renderErrorText("countryOfInst", "Country")}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <label htmlFor='streetAddress'>Street Address<sup> *</sup></label>
                            <OutlinedInput
                                fullWidth
                                value={streetAddressOfInst}
                                error={(isFormSubmitted && !streetAddressOfInst)}
                                onChange={e => setStreetAddressOfInst(e.target.value)}
                                placeholder='Enter street address'
                                name='streetAddress' />
                            {renderErrorText("streetAddressOfInst", "Street Address")}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <label htmlFor='city'>City<sup> *</sup></label>
                                    <OutlinedInput
                                        fullWidth
                                        value={cityOfInst}
                                        error={(isFormSubmitted && !cityOfInst)}
                                        onChange={e => setCityOfInst(e.target.value)}
                                        placeholder='Enter city'
                                        name='city'
                                        id='instituteCity'
                                    />
                                    {renderErrorText("cityOfInst", "City")}
                                </Grid>
                                <Grid item xs={6}>
                                    <label htmlFor='state'>State<sup> *</sup></label>
                                    <OutlinedInput
                                        fullWidth
                                        value={stateOfInst}
                                        error={(isFormSubmitted && !stateOfInst)}
                                        onChange={e => setStateOfInst(e.target.value)}
                                        placeholder='Enter state'
                                        name='state' />
                                    {renderErrorText("stateOfInst", "State")}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <label htmlFor='zipCode'>Zip Code<sup> *</sup></label>
                                    <OutlinedInput
                                        fullWidth
                                        value={zipCodeOfInst}
                                        error={(isFormSubmitted && !zipCodeOfInst)}
                                        onChange={e => setZipCodeOfInst(e.target.value)}
                                        placeholder='Enter zip code'
                                        name='zipCode' />
                                    {renderErrorText("zipCodeOfInst", "Zip Code")}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <div className={classes.institutionalAgreementPara}>
                <p>Re: Institutional Certification of
                <div className={`${Classes.textFields} ${Classes.certificateFieldContainer}`}>
                        <OutlinedInput
                            error={(isFormSubmitted && !certificateName)}
                            value={certificateName}
                            onChange={e => setCertificateName(e.target.value)}
                            placeholder=' Enter Institution Name*' />
                        <p className={Classes.certificateNameError}>{renderErrorText("certificateName", 'Institution name')}</p>
                    </div>
                        Regarding User

                    <div className={`${Classes.textFields} ${Classes.certificateFieldContainer}`}>
                        <OutlinedInput
                            value={adminName}
                            onChange={e => setAdminName(e.target.value)}
                            disabled
                            placeholder='Enter name*'
                            id='adminName'
                        />
                        {renderErrorText("adminName", 'admin name')}

                    </div>
                </p>
                
            </div>

            <div className={classes.institutionalAgreementLetter}>
                <p className={Classes.acknowledgementPara}>
                    <span className={Classes.textFields}>
                        <OutlinedInput
                            onChange={(e) => setInstitution(e.target.value)}
                            placeholder=' Enter Institution Name'
                            value={institution} />
                    </span>
                hereby acknowledges that
                <span className={Classes.textFieldInstitution}>
                        <OutlinedInput
                            disabled
                            value={userName} />
                    </span>
                 is currently a facility member and active researcher at the Institution.
                Institution is aware of the requirements and obligations of the User as described in the Data Use Agreement and it
                shall ensure that the User complies with all such requirements and obligations. By signing below,
                 I certify on behalf of Institution,
                that I reviewed the certification and am authorized by Institution to sign on behalf of the Institution.
                </p>
            </div>
            <div className={Classes.signPolicyContainer}>
                <div className={Classes.overlayDiv}>
                    <p className={Classes.toBeSignedByText}>To be signed by institute</p>
                </div>
                <div className={Classes.enableBlur}>
                    <p className={Classes.signPolicyText}>
                        <label>Sign Agreement<sup> *</sup></label>
                    </p>
                    <div>
                        <p className={Classes.signPolicySubText}>Sign your name on the trackpad and press any key when finished</p>
                        <div className={Classes.signHereBox} >
                            <SignatureCanvas penColor='black'
                                ref={sigRef}
                                canvasProps={{ width: 400, height: 150, className: 'sigCanvas' }} />
                        </div>
                        <p onClick={clearSignature} className={Classes.clearText}>Clear</p>
                        <div className={Classes.signatureNameInputContainer}>
                            <label >Name</label>
                            <OutlinedInput
                                value={''}
                                onChange={(e) => { }}
                                placeholder={'Enter signatory name'}
                                fullWidth
                                id='signatoryName'
                            />
                        </div>
                        <div className={Classes.signatureNameInputContainer}>
                            <label >Title</label>
                            <OutlinedInput
                                value={''}
                                onChange={(e) => { }}
                                placeholder={'Enter signatory title'}
                                fullWidth
                                id='signatoryTitle'
                            />
                        </div>
                        <div className={Classes.signatureNameInputContainer}>
                            <label >Date</label>
                            <CommonDatePicker
                                onCalenderDateSelected={()=>{}}
                                date={null}
                                id='cvb-institutionalCertification-date'
                                canClearInput
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
