import React, { useState, useEffect } from 'react'
import DIFIndividualDatasetTabs from '../dif-individual-dataset-tabs'
import DIFDatasetForm from '../dif-dataset-form';
import Classes from './index.module.css'
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map'
import get from 'lodash/get'
const DIFIndividualDataset = props => {
    const {
        difDatasetActiveIndex,
        individualDatasets,
        isFormSubmitted,
        currentRequestId
    } = props;
    const [ isAddMoreClick, setIsAddMoreClick] = useState(false);
    const [ inputBlurs, setInputBlur ] = useState({});
    const onAddMoreClick = () => {
        setIsAddMoreClick(true);
    }
    useEffect(() => {
        document.getElementById('difSubmitButton').addEventListener('click', () => {
            let blurIndexes = {};
            map(individualDatasets, (dataset, index) => {
                const isCuratedDate = (get(dataset, 'do_you_need_brain_commons_curation_services', '') === 'no') &&
                                (get(dataset, 'is_your_data_curated', '') === 'yes');

                const curatedDateRequired = isCuratedDate ? true : false;
                blurIndexes = {
                    ...blurIndexes, 
                    'email':{ 
                        ...blurIndexes['email'], 
                        [index] : true
                    },
                    'curatedDate':{
                        ...blurIndexes['curatedDate'],
                        [index]: curatedDateRequired
                    }
                }
            })
            setInputBlur(blurIndexes);
        });

        return document.getElementById('difSubmitButton').removeEventListener('click', ()=>{}, true);
    }, [individualDatasets]);

    return (
        <div className={Classes.container} id="cvb-DIFIndividualDataset-mainContainer">
            <p className={Classes.formTitle} id="cvb-DIFIndividualDataset-headingText">Individual Datasets</p>
            <div>
                <DIFIndividualDatasetTabs onAddMoreClick={onAddMoreClick}/>
            </div>
                {!isEmpty(individualDatasets) && 
                    <DIFDatasetForm inputBlurs={inputBlurs} isAddMoreClick={isAddMoreClick} isFormSubmitted={isFormSubmitted} currentRequestId={currentRequestId} datasetData={individualDatasets[difDatasetActiveIndex]}/>
                }
        </div>
    )
}

DIFIndividualDataset.defaultProps = {
    difDatasetActiveIndex: 0,
    individualDatasets: []
}
export default DIFIndividualDataset;
