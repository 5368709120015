import React from 'react'
import classes from './index.module.css';
import Grid from '@mui/material/Grid'
import ImageWorkspace from '../../../assets/icons/024-book-1.svg';
import {formatBytes} from '../../../utils/file-size-formatter';

const WorkspaceFile = ({file}) => {

    return (
        <>
            <Grid item xs={12} lg={12}>
                <div className={classes.cardFilesContainer}>
                    <div container className={classes.cardContentContainer}>
                        <div className={classes.workspaceImageContainer}>
                            <img src={ImageWorkspace} className={classes.WorkspaceImage} alt='Workspace' />
                        </div>
                        <div className={classes.cardContent}>
                            <p className={classes.curiousText}>{file?.name}</p>
                            <p className={`${classes.curiousText} ${classes.fileType}`}>{file?.type}</p>
                        </div>
                        <div className={classes.cardContent}>
                            <p className={classes.curiousText}>{formatBytes(file?.size)}</p>
                        </div>
                    </div>
                </div>
            </Grid>
        </>
    )
}

export default WorkspaceFile;