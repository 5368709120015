import React, {useState, useEffect} from 'react'
import {
    Grid,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    FormControl,
    Select,
    MenuItem,
    Box,
    OutlinedInput,
    InputAdornment,
    FormControlLabel,
    Switch,
    FormGroup
} from '@mui/material'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';

import down from '../../../assets/icons/Down.svg';

import withStyles from '@mui/styles/withStyles';
import {v4} from 'uuid'
import {
    createUserInvite,
    deleteInvite,
    getAllInvites,
    getSelfSignUpStatus,
    updateSelfSignUpStatus
} from '../../../api/registration-api';

import BlockPreloader from '../../commons/block-preloader'
import WhiteListInviteModal from './white-list-invite-modal/WhiteListInviteModal';
import WhiteListDeletionModal from './white-list-deletion-modal';
import deleteIcon from '../../../assets/icons/delete-basket.svg';
import imgClose from "../../../assets/images/close.png";

import classes from './index.module.css'
import { Pagination } from '@mui/material';
import {LightTooltip} from "../../controls/community-left-menu/CommunityLeftMenu";

import {getWindowDimensions} from "../../../utils/hooks";

import CommunityEmptyState from '../../controls/community-empty-state';

export const StyledTableCell = withStyles(() => ({
    head: {
        fontSize: 16,
        backgroundColor: '#ECEFF6',
        color: '#586477',
        borderColor: '#ECEFF6'
    },
    body: {
        fontSize: 16,
    },
}))(TableCell);

const StyledPagination = withStyles(() => ({
	root: {
		marginLeft: 'auto'
	},
    ul: {
        "& .MuiPaginationItem-root": {
            color: "#A9AEB5"
        },
        "& .Mui-selected": {
            backgroundColor: "#637BBD",
            color: '#fff'
        }
    }
}))(Pagination);

const StyledSwitch = withStyles(() => ({
    switchBase: {
        top: '0px',
        left: '2px'
    }
}))(Switch);

const WhiteList = (props) => {
    const {updateSnackBar} = props
    const [openInvite, setOpenInvite] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [currentInvite, setCurrentInvite] = useState()
    const [userToInvite, setUserToInvite] = useState({})
    const [sortDirectionTopToBottom, setSortDirectionTopToBottom] = useState(false)
    const [sortBy, setSortBy] = useState('date')
    const [allInvites, setAllInvites] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchField, setSearchField] = useState("")
    const [maxCount, setMaxCount] = useState(0)
    const [selfSignUp, setSelfSignUp] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchInvites = async () => {
        const invites = await getAllInvites()
        setAllInvites(invites)
        setIsLoading(false)
    }

    const defaultMaxCount = 0 // allInvites?.length ? Math.ceil(allInvites.length / 10) : 0

    const filteredInvites = allInvites.filter(invite => {
        return (
            invite?.first_name.toLowerCase().includes(searchField.toLowerCase()) || invite?.last_name.toLowerCase().includes(searchField.toLowerCase()) || invite?.email_id.toLowerCase().includes(searchField.toLowerCase())
        )
    }) || []

    const calculateMaxCount = (rows) => {
        let count = filteredInvites?.length ? Math.ceil(filteredInvites.length / rows) : 0;
        setRowsPerPage(rows)
        setMaxCount(count)
    }

    const handleDeleteModal = (invite) => {
        setCurrentInvite(invite)
        setOpenDelete(true)
    }

    const handleDelete = async (user) => {
        const res = await deleteInvite(user?.email_id);
		if (res.status === 200) {
			setOpenDelete(false);
			updateSnackBar(
				`User ${user?.first_name} ${user?.last_name} is successfully deleted`,
				'success',
				{ vertical: 'bottom', horizontal: 'right' }
			);
			setIsLoading(true);
			fetchInvites();
		} else {
			updateSnackBar(`An error occured, please try again later.`, 'error', {
				vertical: 'bottom',
				horizontal: 'right',
			});
		}
    }

    const inviteUser = async payload => {
		const res = await createUserInvite(payload);

        
		switch (res.status) {
			case 200:
				setOpenInvite(false);
				updateSnackBar(
					`User ${payload?.users[0]?.first_name} ${payload?.users[0]?.last_name} is successfully added`,
					'success',
					{ vertical: 'bottom', horizontal: 'right' }
				);
				setIsLoading(true);
                setTimeout(() => {
                    fetchInvites()
                }, 1500)
                break;
			case 400:
				updateSnackBar(`${res.data.error}`, 'error', {
					vertical: 'bottom',
					horizontal: 'right',
				});
                break;
			default:
				updateSnackBar(`An error occured, please try again later.`, 'error', {
					vertical: 'bottom',
					horizontal: 'right',
				});
                break;
			}

	}

    const sorting = (category, direction) => {
        let sortedUsers
        switch (category) {
            case 'date':
                sortedUsers = allInvites.sort((a, b) => (new Date(a.invitation_send_date) > new Date(b.invitation_send_date)) ? 1 : -1)
                break
            case 'name':
                sortedUsers = allInvites.sort((a, b) => (a.first_name.toLowerCase() < b.first_name.toLowerCase()) ? 1 : -1)
                break
            case 'status':
                sortedUsers = allInvites.sort((a, b) => (a.account_status > b.account_status) ? -1 : 1)
        }

        if (!direction) {
            setAllInvites([...sortedUsers].reverse())
        } else {
            setAllInvites([...sortedUsers])
        }
    }

    const processInviteStatus = (status) => {
        switch (status) {
            case 'INVITATION_SEND':
                return <span className={`${classes.status} ${classes.statusCreated}`}>created</span>
            case 'ACCOUNT_CREATED':
                return <span className={`${classes.status} ${classes.statusRegistered}`}>registered</span>
            case 'ACCOUNT_EXPIRED':
                return <span className={`${classes.status} ${classes.statusExpired}`}>expired</span>
        }
    }

    useEffect(() => {
        if (allInvites.length > 0) {
            sorting(sortBy, sortDirectionTopToBottom)
        }
    }, [sortBy, sortDirectionTopToBottom, isLoading])

    const handleSSU = async (param) => {
        const res = await updateSelfSignUpStatus(param)
        if (res.message === 'SSM parameter updated successfully') {
            setSelfSignUp(param)
        }
    }

    const getSSU = async () => {
        const res = await getSelfSignUpStatus()
        setSelfSignUp(res?.sign_up_allowed)
    }

    useEffect(() => {
        setIsLoading(true)
        fetchInvites()
        getSSU()
    }, [])

    useEffect(() => {
        setMaxCount(defaultMaxCount)
    }, [defaultMaxCount])

    useEffect(() => {
        if (filteredInvites.length > 0) {
            calculateMaxCount(rowsPerPage)
            if (searchField) {
                setPage(0)
            }
        }
    }, [filteredInvites])

	useEffect(() => {
		if (!openInvite) {
			setUserToInvite({});
		}
	}, [openInvite]);

	useEffect(() => {
		if (userToInvite && Object.keys(userToInvite).length > 0) {
			inviteUser(userToInvite);
		}
	}, [userToInvite]);

    return (
        <div className={classes.innerContainer}>
            <Grid container className={classes.searchBackground}>
                <Grid container xs={12} lg={12} md={12} className={classes.sectionHeaderContainer}>
                    <div className={classes.gridTitle}><h2>Invited Users</h2></div>
                    <button className={`solid-button ${classes.inviteBtn}`} onClick={() => {
                        setOpenInvite(true)
                    }}>Invite User
                    </button>
                </Grid>
            </Grid>
            <div className={classes.backdrop}>
                <div className={classes.tableWrapper}>
                    {isLoading ? <BlockPreloader/> : <>
                        <Box style={{marginBottom: '15px', display: 'flex', alignItems: 'center', gap: '15px'}}>
                            <FormControl variant="standard" style={{minWidth: '160px'}}>
                                <Select
                                    className={classes.select}
                                    defaultValue={sortBy}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                    value={sortBy}
                                    input={<OutlinedInput/>}
                                    onChange={(e) => {
                                        setSortBy(e.target.value)
                                    }}
                                    IconComponent={(props) => <KeyboardArrowDownIcon
                                        classes={{label: 'selectIcon'}} {...props}/>}
                                >
                                    <MenuItem value='date'><p className={classes.popoverFieldSelectItem}>Sort by
                                        Date</p></MenuItem>
                                    <MenuItem value='name'><p className={classes.popoverFieldSelectItem}>Sort by
                                        Name</p></MenuItem>
                                    <MenuItem value='status'><p className={classes.popoverFieldSelectItem}>Sort by
                                        Status</p></MenuItem>
                                </Select>
                            </FormControl>
                            <img
                                className={`${classes.sortDirectionIcon} ${sortDirectionTopToBottom && classes.sortDirectionIconTurned}`}
                                onClick={() => {
                                    setSortDirectionTopToBottom(!sortDirectionTopToBottom)
                                }} src={down} alt=""/>
                            <p className={classes.paginationSubtitle}>Showing {rowsPerPage * page + 1}-{filteredInvites.length < (rowsPerPage * page) + rowsPerPage ? filteredInvites.length : (rowsPerPage * page) + rowsPerPage} of {filteredInvites.length} users</p>
                            <FormGroup row>
                                <FormControlLabel
                                    style={{marginLeft: '20px', marginBottom: 0}}
                                    control={
                                        <StyledSwitch
                                            checked={selfSignUp}
                                            onChange={() => {
                                                handleSSU(!selfSignUp);
                                            }}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Self Sign Up"
                                />
                            </FormGroup>
                            <OutlinedInput
                                style={{marginLeft: 'auto', width: 220}}
                                variant="outlined"
                                value={searchField}
                                onChange={(e) => {
                                    setSearchField(e.target.value)
                                }}
                                placeholder="Search"
                                endAdornment={
                                    <InputAdornment position="end">
                                        {searchField.length ? <img className={classes.closeIcon} src={imgClose} onClick={() => {
                                            setSearchField("")
                                        }}/> : <SearchIcon/>}
                                    </InputAdornment>
                                }
                            />

                        </Box>
                        <TableContainer component={Paper} className={classes.table}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>First Name</StyledTableCell>
                                        <StyledTableCell>Last Name</StyledTableCell>
                                        <StyledTableCell>User Email</StyledTableCell>
                                        <StyledTableCell>Invitation Date</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                        <StyledTableCell width="32px"></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredInvites.length > 0 && filteredInvites.map((invite) => (
                                        <TableRow key={v4()} className={classes.personRow}>
                                            <StyledTableCell component="th" scope="row">
                                                {invite?.first_name.length > 15 ? (
                                                    <LightTooltip arrow placement="top-start" title={invite?.first_name}>
                                                        <span className={classes.toolTipSpan}>{invite?.first_name}</span>
                                                    </LightTooltip>
                                                ) : <>{invite?.first_name}</>}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {invite?.last_name.length > 15 ? (
                                                    <LightTooltip arrow placement="top-start" title={invite?.last_name}>
                                                        <span className={classes.toolTipSpan}>{invite?.last_name}</span>
                                                    </LightTooltip>
                                                ) : <>{invite?.last_name}</>}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {(windowDimensions.width >= 1600 ? invite?.email_id.length > 25 : invite?.email_id.length > 20) ? (
                                                    <LightTooltip arrow placement="top-start" title={invite?.email_id}>
                                                        <span className={classes.toolTipSpan}>{invite?.email_id}</span>
                                                    </LightTooltip>
                                                ) : <>{invite?.email_id}</>}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <span className={classes.toolTipSpan}>{invite?.invitation_send_date?.split(' ')[0]}</span>
                                            </StyledTableCell>
                                            <StyledTableCell>{processInviteStatus(invite?.account_status)}</StyledTableCell>
                                            <StyledTableCell>
                                                {(invite?.account_status !== 'ACCOUNT_CREATED') && (
                                                    <img className={classes.deleteIcon} src={deleteIcon} alt="Delete Icon" onClick={() => {
                                                        handleDeleteModal(invite)
                                                    }}/>
                                                )}
                                            </StyledTableCell>
                                        </TableRow>
                                    )).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
									{filteredInvites.length === 0 && (
											<TableRow key={v4()}>
												<StyledTableCell colSpan={6} component="th" scope="row">
													<CommunityEmptyState text="search results" />
												</StyledTableCell>
											</TableRow>
										)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes.tablePagination}>
                            {filteredInvites.length > 0 && <p className={classes.tablePaginationTotal}>Total {filteredInvites.length} items</p>}
                            {(maxCount > 1 && filteredInvites.length > 0) && <StyledPagination boundaryCount={3} page={page + 1} count={maxCount} shape="rounded" onChange={(e,p) => {setPage(p - 1)}}/>}
                            <p className={classes.tablePaginationPerPage}>Show per Page:
                                <div className={classes.rowsPerPageContainer}>
                                    {[10, 25, 50, 100].map(num => {
                                        return <button key={v4()}
                                                       className={`${classes.rowsPerPageBtn} ${rowsPerPage === num && classes.rowsPerPageBtnActive}`}
                                                       onClick={() => {
                                                           calculateMaxCount(num);
                                                           setPage(0)
                                                       }}>{num}</button>
                                    })}
                                </div>
                            </p>
                        </div>
                    </>}
                </div>
            </div>
            <WhiteListInviteModal openInvite={openInvite} setOpenInvite={setOpenInvite}
                                  setUserToInvite={setUserToInvite}/>
            <WhiteListDeletionModal openDelete={openDelete} setOpenDelete={setOpenDelete} currentInvite={currentInvite}
                                    handleDelete={handleDelete}/>
        </div>
    )
}

export default WhiteList
