import React,  {useRef}  from 'react'
import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Grid'
import Classes from './index.module.css';
import SignatureCanvas from 'react-signature-canvas'
import CommonDatePicker from '../../commons/common-date-picker';

const ViewInstitutionCertificateForm = (props) => {
    const sigRef = useRef();
    const clearSignature = () => {
        sigRef.current.clear();
    }
    const { certificationApproverDetails } = props || {}

    return (
        <>
            <Grid container direction='column'>
            <p className={Classes.aoDefinitionText}>Authorizing Official: This means an executive, officer or other individual who is authorized to sign contracts on behalf of your organization or company. You can find out who that is by contacting the office of your legal counsel or chief executive. </p>
                <Grid item xs={7}>
                    <Grid container direction='row' spacing={2}>
                        <Grid item xs={6}>
                            <label htmlFor='streetAddress'>Name of the Institution</label>
                            <OutlinedInput
                                fullWidth
                                disabled
                                value={certificationApproverDetails?.nameOfTheInstitution || ''}
                                placeholder='Enter Institution Name'
                                id='cvb-viewInstCertForm-institutionName'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <label htmlFor='streetAddress'>Email of Authorizing Official at the Institution</label>
                            <OutlinedInput
                                disabled
                                fullWidth
                                value={certificationApproverDetails?.emailOfsignatorAtIntitution || ''}
                                placeholder='Enter email' 
                                id='cvb-viewInstCertForm-email'
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <p className={Classes.contactFormTitles}>Institution Address</p>
                <Grid item xs={7}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <label htmlFor='country'>Country</label>
                                    <OutlinedInput
                                        disabled
                                        fullWidth
                                        value={certificationApproverDetails?.addressOfInstitution?.country || ''}
                                        placeholder='Enter email' 
                                        id='cvb-viewInstCertForm-country'
                                        />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <label htmlFor='streetAddress'>Street Address</label>
                            <OutlinedInput
                                fullWidth
                                disabled
                                value={certificationApproverDetails?.addressOfInstitution?.streetName || ''}
                                placeholder='Enter street address'
                                name='streetAddress' 
                                id='cvb-viewInstCertForm-streetAddress'
                                />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <label htmlFor='city'>City</label>
                                    <OutlinedInput
                                        fullWidth
                                        value={certificationApproverDetails?.addressOfInstitution?.city || ''}
                                        disabled
                                        placeholder='Enter city'
                                        name='city'
                                        id='cvb-viewInstCertForm-city'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <label htmlFor='state'>State</label>
                                    <OutlinedInput
                                        fullWidth
                                        value={certificationApproverDetails?.addressOfInstitution?.state || ''}
                                        disabled
                                        placeholder='Enter state'
                                        name='state' 
                                        id='cvb-viewInstCertForm-state'
                                        />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <label htmlFor='zipCode'>Zip Code</label>
                                    <OutlinedInput
                                        fullWidth
                                        disabled
                                        value={certificationApproverDetails?.addressOfInstitution?.zipCode || ''}
                                        placeholder='Enter zip code'
                                        name='zipCode' 
                                        id='cvb-viewInstCertForm-zip'
                                        />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={Classes.institutionalAgreementPara}>
                    <p>Re: Institutional Certification of
                <div className={`${Classes.textFields} ${Classes.certificateFieldContainer}`}>
                            <OutlinedInput
                                value={props?.certificationName || ''}
                                disabled
                                placeholder=' Enter Institution Name' 
                                id='cvb-viewInstCertForm-institutionNameInput'
                                />
                        </div>
                        Regarding User
                </p>
                </div>
                <div className={Classes.institutionalAgreementLetter}>
                    <p className={Classes.textFields}>
                        <OutlinedInput
                            value={props?.adminName || ''}
                            disabled
                            placeholder='Enter name'
                            id='cvb-viewInstCertForm-adminNameInput'
                        />
                    </p>
                    <p className={Classes.acknowledgementPara}>
                        <span className={Classes.textFields}>
                            <OutlinedInput
                                disabled
                                placeholder=' Enter Institution Name'
                                value={certificationApproverDetails?.nameOfTheInstitution || ''} 
                                id='cvb-viewInstCertForm-nameOfinstitutionInput'
                                />
                        </span>
                hereby acknowledges that
                <span className={Classes.textFieldInstitution}>
                            <OutlinedInput
                                disabled
                                value={props?.researcherName || ''} 
                                id='cvb-viewInstCertForm-researcherName'
                                />
                        </span>
                 is currently a facility member and active researcher at the Institution.
                Institution is aware of the requirements and obligations of the User as described in the Data Use Agreement and it
                shall ensure that the User complies with all such requirements and obligations. By signing below,
                 I certify on behalf of Institution,
                that I reviewed the certification and am authorized by Institution to sign on behalf of the Institution.
                </p>
                </div>
                <div className={Classes.signPolicyContainer}>
                <div className={Classes.overlayDiv}>
                    <p className={Classes.toBeSignedByText}>To be signed by institute</p>
                </div>
                <div className={Classes.enableBlur}>
                        <p className={Classes.signPolicyText}>
                            <label>Sign Agreement<sup> *</sup></label>
                        </p>
                    <div>
                        <p className={Classes.signPolicySubText}>Sign your name on the trackpad and press any key when finished</p>
                        <div className={Classes.signHereBox} >
                            <SignatureCanvas penColor='black'
                                ref={sigRef}
                                canvasProps={{ width: 400, height: 150, className: 'sigCanvas' }} />
                        </div>
                        <p id='cvb-viewInstCertForm-clearSignButton' onClick={clearSignature} className={Classes.clearText}>Clear</p>
                        <div className={Classes.signatureNameInputContainer}>
                            <label>Name</label>
                            <OutlinedInput
                                value={''}
                                onChange={(e) => { }}
                                placeholder={'Enter signatory name'}
                                fullWidth
                                id='cvb-viewInstCertForm-signatoryNameText'
                            />
                        </div>
                        <div className={Classes.signatureNameInputContainer}>
                            <label>Title</label>
                            <OutlinedInput
                                value={''}
                                onChange={(e) => { }}
                                placeholder={'Enter signatory title'}
                                fullWidth
                                id='cvb-viewInstCertForm-signatoryTitleText'
                            />
                        </div>
                        <div className={Classes.signatureNameInputContainer}>
                            <label>Date</label>
                                <CommonDatePicker
                                    onCalenderDateSelected={(date) =>{ }}
                                    date={null}
                                    id='cvb-viewInstCertification'
                                    canClearInput
                                />
                        </div>
                    </div>
                </div>
            </div>
            </Grid>

        </>
    )
}

export default ViewInstitutionCertificateForm
