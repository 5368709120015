import { SET_DCA_STAGE, SET_DCA_AGREEMENT_DATE, SET_DCA_CLIENT_NAME, SET_DCA_CONTRIBUTOR_NAME,
    SET_CONTRIBUTOR_SIGNING_NAME, SET_CONTRIBUTOR_SIGNING_BY_TEXT, SET_CONTRIBUTOR_SIGNING_TITLE, 
    SET_CONTRIBUTOR_SIGNING_DATE, SET_DCA_REVIEW_DATE, SET_DCA_REVIEW_APPROVE_TEXT, SET_DCA_REVIEW_BY_TEXT,
    SET_DCA_REVIEWER_NAME, SET_DCA_REVIEWER_TITLE, SET_DCA_REVIEWER_SIGN_DATE, SET_DCA_ALL_FORM_FIELDS, RESET_DCA_FORM,
    SET_CURRENT_DCA_FORM_STAGE, SET_DCA_SIGNATURE, SET_ACKNOWLEDGMENT_SIGNATURE, SET_INSTITUTE_SIGNATURE
} from '../action-types'
import moment from 'moment'
import get from 'lodash/get'

const initialState = {
    dcaStage: 1,
    dcaAgreementDate: null,
    dcaClientName: '',
    dcaContributorName: '',
    dataContributorAgreementSignature: null,
    contributorSigningByText: '',
    contributorSigningName: '',
    contributorSigningTitle: '',
    contributorSigningDate: null,
    currentDCAFormStage: '',
    acknowledgmentSignature: '',
    instituteSignature: ''
}

const dcaReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DCA_STAGE: {
            return {
                ...state,
                dcaStage: action.payload
            }
        }
        case SET_DCA_AGREEMENT_DATE: {
            return {
                ...state,
                dcaAgreementDate: action.payload
            }
        }
        case SET_DCA_CLIENT_NAME: {
            return {
                ...state,
                dcaClientName: action.payload
            }
        }
        case SET_DCA_CONTRIBUTOR_NAME: {
            return {
                ...state,
                dcaContributorName: action.payload
            }
        }
        case SET_CONTRIBUTOR_SIGNING_BY_TEXT: {
            return {
                ...state,
                contributorSigningByText: action.payload
            }
        }
        case SET_CONTRIBUTOR_SIGNING_NAME: {
            return {
                ...state,
                contributorSigningName: action.payload
            }
        }
        case SET_CONTRIBUTOR_SIGNING_TITLE: {
            return {
                ...state,
                contributorSigningTitle: action.payload
            }
        }
        case SET_CONTRIBUTOR_SIGNING_DATE: {
            return {
                ...state,
                contributorSigningDate: action.payload
            }
        }
        case SET_DCA_REVIEW_DATE: {
            
            return {
                ...state,
                dcaReviewDate: action.payload
            }
        }
        case SET_DCA_REVIEW_APPROVE_TEXT: {
            return {
                ...state,
                dcaReviewApproveText: action.payload
            }
        }
        case SET_DCA_REVIEW_BY_TEXT: {
            return {
                ...state,
                dcaReviewByText: action.payload
            }
        }
        case SET_DCA_REVIEWER_NAME: {
            return {
                ...state,
                dcaReviewerName: action.payload
            }
        }
        case SET_DCA_REVIEWER_TITLE: {
            return {
                ...state,
                dcaReviewerTitle: action.payload
            }
        }
        case SET_DCA_REVIEWER_SIGN_DATE: {
            return {
                ...state,
                dcaReviewerSignDate: action.payload
            }
        }
        case SET_DCA_SIGNATURE: {
            return {
                ...state,
                dataContributorAgreementSignature: action.payload
            }
        }
        case SET_DCA_ALL_FORM_FIELDS: {
            const formField = action.payload;
            
            const effectiveDate = get(formField, 'effectiveDate', null) === null ? null : moment.utc(get(formField, 'effectiveDate', null)).toDate();
            const dataContributorDate = get(formField, 'dataContributorDate', null) === null ? null : moment.utc(get(formField, 'dataContributorDate', null)).toDate();
            const data = {
                dcaAgreementDate: effectiveDate,
                dataContributorAgreementSignature: get(formField, 'dataContributorAgreementSignature', ''),
                dcaClientName: get(formField, 'partyName', ''),
                dcaContributorName: get(formField, 'partyDescription', ''),
                contributorSigningName: get(formField, 'requesterName', ''),
                contributorSigningTitle:  get(formField, 'requesterTitle', ''),
                contributorSigningDate: dataContributorDate,
                acknowledgmentSignature: get(formField, 'acknowledgementSignature', ''),
                instituteSignature: get(formField, 'institutionalCertificationSignature', '')
            }
            return {
                ...state,
               ...data,
            }
        }
        case RESET_DCA_FORM : {
            return {
                ...state,
                dcaStage: 1,
                dcaAgreementDate: null,
                dcaClientName: '',
                dcaContributorName: '',
                contributorSigningByText: '',
                contributorSigningName: '',
                contributorSigningTitle: '',
                contributorSigningDate: null,
                currentDCAFormStage: '',
                dataContributorAgreementSignature: '',
                acknowledgmentSignature: '',
                instituteSignature: ''
            }
        }
        case SET_CURRENT_DCA_FORM_STAGE : {
            return {
                ...state,
                currentDCAFormStage: action.payload
            }
        }
        case SET_ACKNOWLEDGMENT_SIGNATURE : {
            return {
                ...state,
                acknowledgmentSignature: action.payload
            }
        }
        case SET_INSTITUTE_SIGNATURE: {
            return {
                ...state,
                instituteSignature: action.payload
            } 
        }
        default: return state
    }
}


export default dcaReducer;