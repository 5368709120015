import React from 'react'
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import Classes from './index.module.css'
import { COUNTRY_LIST, DIF_FORM_COMPLETED_STATUS } from '../../../constants/strings'
import isEmpty from 'lodash/isEmpty'
import ErrorText from '../error-text';
import DropDown from '../../commons/dropdown'

const DIFInstitution = props => {
    const {
        difInstitutionInstitutionName,
        difInstitutionStreetAddress,
        difInstitutionCountry,
        difInstitutionState,
        difInstitutionCity,
        difInstitutionMunicipality,
        difInstitutionZipCode,
        isFormSubmitted,
        currentDIFFormStage,
        setDifInstitutionInstitutionName,
        setDifInstitutionStreetAddress,
        setDifInstitutionCountry,
        setDifInstitutionState,
        setDifInstitutionCity,
        setDifInstitutionMunicipality,
        setDifInstitutionZipCode
    } = props;
    const onInstituteNameChange = event => {
        setDifInstitutionInstitutionName(event.target.value);
    }
    const onStreetAddressChange = event => {
        setDifInstitutionStreetAddress(event.target.value);
    }
    const onCountryChange = value => {
        setDifInstitutionCountry(value);
    }
    const onStateChange = event => {
        setDifInstitutionState(event.target.value);
    }
    const onCityChange = event => {
        setDifInstitutionCity(event.target.value);
    }
    const onMunicipalityChange = event => {
        setDifInstitutionMunicipality(event.target.value);
    }
    const onZipCodeChange = event => {
        setDifInstitutionZipCode(event.target.value);
    }

    const disableInput = currentDIFFormStage === DIF_FORM_COMPLETED_STATUS;
    return (
        <div className={Classes.container} id="cvb-DIFFormStep1-mainContainer">
            <Grid container direction='row' className={Classes.inputContainers} spacing={3}>
                <Grid item xs={12}>
                    <p className={`${Classes.formTitle}`} id="cvb-DIFFormStep1-instituteHeading">Institution</p>
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='institutionName' id="cvb-DIFFormStep1-instituteLabel">Institution Name<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Please enter the formal name of the institution"
                        value={difInstitutionInstitutionName}
                        onChange={onInstituteNameChange}
                        fullWidth
                        id='cvb-DIFFormStep1-institutionName'
                        name='institutionName'
                        disabled={disableInput}
                    />
                    {
                        isFormSubmitted && isEmpty(difInstitutionInstitutionName) &&
                        <ErrorText errorMessage="Enter institution name" />
                    }
                </Grid>
                <Grid item xs={12}>
                    <p className={`${Classes.formTitle} ${Classes.addMarginTop}`} id="cvb-DIFFormStep1-addressLabel">Institution Address</p>
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='country' id="cvb-DIFFormStep1-countryLabel">Country<sup> *</sup></label>
                    <DropDown
                        id="cvb-DIFFormStep1-instituteCountry"
                        name='instituteCountry'
                        values={COUNTRY_LIST}
                        handleFilterStateChange={onCountryChange}
                        selectedFilter={difInstitutionCountry}
                        placeholder={'Select Country'}
                        disable={disableInput}
                        searchable
                        isClearable
                    />
                    {
                        isFormSubmitted && isEmpty(difInstitutionCountry) &&
                        <ErrorText errorMessage="Select country" />
                    }
                </Grid>
                <Grid item xs={10} className={Classes.fieldCont}>
                    <label htmlFor='streetAddress' id="cvb-DIFFormStep1-streetAddressLabel">Street Address<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter street address "
                        value={difInstitutionStreetAddress}
                        onChange={onStreetAddressChange}
                        fullWidth
                        id="cvb-DIFFormStep1-streetAddressField"
                        name='streetAddress'
                        disabled={disableInput}
                    />
                    {
                        isFormSubmitted && isEmpty(difInstitutionStreetAddress) &&
                        <ErrorText errorMessage="Enter street address" />
                    }
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='state' id="cvb-DIFFormStep1-stateLabel">State<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter state"
                        value={difInstitutionState}
                        onChange={onStateChange}
                        fullWidth
                        id="cvb-DIFFormStep1-stateField"
                        name='state'
                        disabled={disableInput}
                    />
                    {
                        isFormSubmitted && isEmpty(difInstitutionState) &&
                        <ErrorText errorMessage="Enter state" />
                    }
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='City' id="cvb-DIFFormStep1-cityLabel">City<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter city"
                        value={difInstitutionCity}
                        onChange={onCityChange}
                        fullWidth
                        id="cvb-DIFFormStep1-cityField"
                        name='City'
                        disabled={disableInput}
                    />
                    {
                        isFormSubmitted && isEmpty(difInstitutionCity) &&
                        <ErrorText errorMessage="Enter city" />
                    }
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='municipality' id="cvb-DIFFormStep1-municipalityLabel">Municipality</label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter municipality"
                        value={difInstitutionMunicipality}
                        onChange={onMunicipalityChange}
                        fullWidth
                        id="cvb-DIFFormStep1-municipalityField"
                        name='municipality'
                        disabled={disableInput}
                    />
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='zipCode' id="cvb-DIFFormStep1-zipLabel">Zip Code<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter zip code"
                        value={difInstitutionZipCode}
                        onChange={onZipCodeChange}
                        fullWidth
                        id="cvb-DIFFormStep1-zipCodeField"
                        name='zipCode'
                        disabled={disableInput}
                    />
                    {
                        isFormSubmitted && isEmpty(difInstitutionZipCode) &&
                        <ErrorText errorMessage="Enter zip code" />
                    }
                </Grid>
            </Grid>
        </div >
    )
}

DIFInstitution.defaultProps = {
    difInstitutionInstitutionName: '',
    difInstitutionStreetAddress: '',
    difInstitutionCountry: '',
    difInstitutionState: '',
    difInstitutionCity: '',
    difInstitutionMunicipality: '',
    difInstitutionZipCode: '',
    isFormSubmitted: '',
    currentDIFFormStage: '',
    setDifInstitutionInstitutionName: () => { },
    setDifInstitutionStreetAddress: () => { },
    setDifInstitutionCountry: () => { },
    setDifInstitutionState: () => { },
    setDifInstitutionCity: () => { },
    setDifInstitutionMunicipality: () => { },
    setDifInstitutionZipCode: () => { }
}

export default DIFInstitution;
