import React, { useEffect } from 'react'
import Classes from './index.module.css';
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import SignatureCanvas from 'react-signature-canvas'
import capitalize from 'lodash/capitalize'
import { DCA_FORM_COMPLETED_TEXT } from '../../../constants/strings'
import ErrorText from '../../controls/error-text'
import isEmpty from 'lodash/isEmpty'
import CommonDatePicker from '../../commons/common-date-picker';
const InstitutionalCertificationStage = (props) => {
    const {
        adminName,
        setInstitutionalCertAdminName,
        instituteCertRef,
        ppiInstituteName,
        ppiInstituteStreetAddress,
        ppiInstituteCity,
        ppiInstituteState,
        ppiInstituteZipCode,
        ppiInstituteCountry,
        currentDCAFormStage,
        setInstituteSignature,
        isFormSubmitted,
        instituteSignature,
        isDashboard,
        contributorSigningName,
        userDetails
    } = props;
    const disableTextInput =  (currentDCAFormStage === DCA_FORM_COMPLETED_TEXT);
    const createInstCertSign = async (data) => await instituteCertRef?.current?.fromDataURL(data);
    const clearInstituteSign = async () => await instituteCertRef.current.clear();
    useEffect(() => {
        
        if(!isEmpty(instituteSignature)){
            createInstCertSign(instituteSignature);
        }else{
            clearInstituteSign();
            setInstituteSignature(null);
        }
    }, []);

    const onSignatureEnd = async () => {
        const instituteSign = instituteCertRef?.current?.toDataURL();
        setInstituteSignature(instituteSign);
    }
    
    const clearSignature = async () => {
        if (!disableTextInput) {
            await clearInstituteSign();
             setInstituteSignature(null);
        }
    }
    const onAdminNameChange = (event) => {
        setInstitutionalCertAdminName(event.target.value)
    }
    const userName = !isDashboard ? (capitalize(userDetails?.["first_name"]) + " " + capitalize(userDetails?.["last_name"])) : contributorSigningName;
    const instituteAddr =  `${ppiInstituteStreetAddress}, ${ppiInstituteCity}, ${ppiInstituteState}, 
                            ${ppiInstituteZipCode}, ${ppiInstituteCountry}`;
    return (
        <div className={Classes.container}>
            <p className={Classes.formTitle}>Institutional Certification</p>
            <Grid item xs={10}>
                <Grid container className={Classes.detailsContainer}>
                    <Grid item lg={3} md={6} xs={12}>
                        <div className={Classes.detailsInfo}>
                            <p className={Classes.title}>Name</p>
    <p className={Classes.subTitle}>{userName}</p>
                            <div className={Classes.verticalDivider}></div>
                        </div>

                    </Grid>
                    <Grid item lg={3} md={6} xs={12}>
                        <div className={Classes.detailsInfo}>
                            <p className={Classes.title}>Name of Institution</p>
                            <p className={Classes.subTitle}>{ppiInstituteName}</p>
                            <div className={Classes.verticalDivider}></div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={6} xs={12}>
                        <div className={Classes.detailsInfo}>
                            <p className={Classes.title}>Address of Institution</p>
    <p className={Classes.subTitle}>{instituteAddr}</p>
                        </div>
                    </Grid>
                </Grid>
                <p className={Classes.impInfoText}>Re: Institutional Certification of {ppiInstituteName} to Accompany
                Submission of Contributed Data to Cohen Veterans Bioscience, Inc. for the BRAINCommons Project</p>
                <Grid item xs={4} className={Classes.nameInputContainer}>
                    <label htmlFor="name"> Dear<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter name"
                        value={adminName}
                        onChange={onAdminNameChange}
                        fullWidth
                        id='adminName'
                        disabled={disableTextInput}
                    />
                    {isFormSubmitted && isEmpty(adminName) &&
                        <ErrorText errorMessage="Enter a valid name" />
                    }
                </Grid>
                <p className={Classes.submissionText}>
                    The submission of data to Cohen Veterans Bioscience, Inc. (“CVB”) is being made with institutional
                    approval from {ppiInstituteName} (“Institution”). Institution hereby assures that the submission
                    of Contributed Data (as defined in the BRAINCommons Data Contributor Agreement (the “Agreement”))
                    meets the following expectations, in accordance with the terms of the Agreement:
                </p>
                <ul className={Classes.ulList}>
                    <li>No Prohibited Data will be provided by Contributor, or any of its Representatives, without the prior written
                    consent of the BC Manager.
                 </li>
                    <li>
                        Contributor has obtained all necessary consents and approvals,
                        including any necessary Institutional Review Board (“IRB”) or Privacy Board approval, and shall
                        otherwise comply with all Applicable Laws and other restrictions: (i) to transmit any Contributed
                        Data to the Platform or to the BC Manager; or (ii) to authorize the BC Manager to provide Users with
                        access to and use of such Contributed Data as set forth in Section 2.2 of the Agreement; and (iii)
                        to authorize the BC Manager to exercise its rights and perform its obligations under the Agreement.
                 </li>
                    <li>
                        Contributor has obtained all consents and waivers necessary to transmit to the Platform or to the BC Manager
                        any Contributed Data and to grant the rights granted to the BC Manager under the Agreement. • Contributor’s
                        contribution of Contributed Data to the Platform does not infringe any third party rights, does not violate
                        Contributor contractual obligations to third parties, and complies with all Applicable Laws. Contributor has
                        full legal authority to contribute the Contributed Data to the Platform. Please note that all capitalized
                        terms used, but not defined, above refer to terms defined in the Agreement. By signing below, I certify on
                        behalf of Institution, that, I have reviewed the requirements in this certification and am authorized by
                        Institution to agree that the submission meets them. Sincerely,
                 </li>
                </ul>
            </Grid>
            <p className={`${Classes.submissionText} ${Classes.sincerelyText}`}>Sincerely,</p>
            <div className={Classes.signPolicyContainer}>
                <div className={Classes.overlayDiv}>
                    <p className={Classes.toBeSignedByText}>To be signed by institute</p>
                </div>
                <Grid item xs={12} className={Classes.enableBlur}>
                    <p className={Classes.signPolicyText}>
                        <label>Sign Agreement<sup> *</sup></label>
                    </p>
                    <p className={Classes.signPolicySubText}>Sign your name on the trackpad and press any key when finished</p>
                    <div className={Classes.signHereBox} id='instituteSignatureBox'>
                        <p className={Classes.clickHeretoBegin}>Click here to begin</p>
                        <SignatureCanvas penColor='black'
                            ref={instituteCertRef}
                            canvasProps={{ width: 400, height: 150, className: 'instituteCertCanvas' }}
                            onEnd={onSignatureEnd}
                        />
                        <p onClick={clearSignature} className={Classes.clearText}>Clear</p>

                    </div>
                    <div className={Classes.userDetails}>
                        <p>Name of Institution: <span>{ppiInstituteName}</span></p>
                        <div className={Classes.signatureNameInputContainer}>
                            <label>Name</label>
                            <OutlinedInput
                                value={''}
                                onChange={(e) => { }}
                                placeholder={'Enter signatory name'}
                                fullWidth
                                id='signatoryName'
                            />
                        </div>
                        <div className={Classes.signatureNameInputContainer}>
                            <label>Title</label>
                            <OutlinedInput
                                value={''}
                                onChange={(e) => { }}
                                placeholder={'Enter signatory title'}
                                fullWidth
                                id='signatoryTitle'
                            />
                        </div>
                        <div className={Classes.signatureNameInputContainer}>
                            <label>Date</label>
                            <CommonDatePicker
                                onCalenderDateSelected={() => { }}
                                date={null}
                                id='cvb-institutionalCertification-date'
                                canClearInput
                            />
                        </div>
                    </div>
                </Grid>
            </div>
        </div>
    )
}


InstitutionalCertificationStage.defaultProps = {
    adminName: '',
    instituteCertRef: null,
    ppiInstituteName: '',
    ppiInstituteStreetAddress: '',
    ppiInstituteCity: '',
    ppiInstituteState: '',
    ppiInstituteZipCode: '',
    ppiInstituteCountry: '',
    currentDCAFormStage: '',
    isDashboard: false,
    contributorSigningName: '',
    contributorSigningTitle: '',
    setInstitutionalCertAdminName: () => {},
    setInstituteSignature: () => {}
}
export default InstitutionalCertificationStage;
