import MyStuff from './MyStuff'
import { connect } from 'react-redux'
import { getUserCohorts, resetMyStuffStore } from '../../../redux/actions/myStuffActions'
const mapStateToProps = state => {
    return {
        userCohorts: state.myStuff.userCohorts,
        lastEvaluatedKey: state.myStuff.lastEvaluatedKey,
        userDetails: state.user.userDetails,
        allWorkspaces: state.workspace.allWorkspaces
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUserCohorts: async (limit, lastExecutedKey) => await dispatch(getUserCohorts(limit, lastExecutedKey)),
        resetMyStuffStore: async () => dispatch(resetMyStuffStore())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyStuff);