export default {
    "custom_attributes": {
        "github": "",
        "google": "",
        "highestEducation": "PhD",
        "linkedin": "",
        "onboarding_check": "true",
        "orchid": "",
        "other_designation": "Developer",
        "personal": "",
        "privacy_policy": "accepted",
        "privacy_policy_date": "2022-03-30 16:37:24",
        "scientificBackground": "",
        "tnc": "accepted",
        "tnc_date": "2022-03-30 16:37:16",
        "twitter": ""
    },
    "designation": "Developer",
    "email": "filippo@metacell.us",
    "first_name": "Filippo",
    "idp": "Google",
    "institution_details": null,
    "last_name": "Ledda",
    "organization": "Cohen Veterans Bioscience",
    "organization_id": 1,
    "phone_number": "",
    "professional_bio": "",
    "status": "ENABLED",
    "tags": null,
    "updated_at": "2022-03-30 16:38:20.625866",
    "username": "acbc0a04-7ae4-4d0f-833e-757480590726",
    "permissions": {
        "privileges": [
            {
                "expiry_date": "2099-12-31",
                "label": "Zone1 Read Access"
            },
            {
                "expiry_date": "2099-12-31",
                "label": "Zone1 Read Storage Access"
            },
            {
                "expiry_date": "2099-12-31",
                "label": "Download API Keys"
            },
            {
                "expiry_date": "2099-12-31",
                "label": "Copy API Keys to Workspace"
            },
            {
                "expiry_date": "2099-12-31",
                "label": "Explore Data"
            },
            {
                "expiry_date": "2099-12-31",
                "label": "Explore Data Model"
            },
            {
                "expiry_date": "2099-12-31",
                "label": "Explore Data Study Gallery"
            },
            {
                "expiry_date": "2099-12-31",
                "label": "Explore Data Query"
            },
            {
                "expiry_date": "2099-12-31",
                "label": "Explore Tools"
            },
            {
                "expiry_date": "2099-12-31",
                "label": "Explore Community"
            },
            {
                "expiry_date": "2099-12-31",
                "label": "Request Zone2 Access"
            },
            {
                "expiry_date": "2099-12-31",
                "label": "Community Interaction"
            },
            {
                "expiry_date": "2099-12-31",
                "label": "Copy Cohort to Workspace"
            },
            {
                "expiry_date": "2099-12-31",
                "label": "Request Zone3 Access"
            }
        ],
        "resources": {
            "object_data_list": [],
            "portal_ui": [
                {
                    "actions": {
                        "copy_api_keys_to_workspace": true,
                        "copy_cohort_to_workspace": true,
                        "download_api_keys": true,
                        "export_saved_search_to_workspace": true,
                        "list_cohorts": true,
                        "list_saved_search": true
                    },
                    "path": "/my-stuff",
                    "resource_label": "My Stuff",
                    "resource_name": "my_stuff"
                },
                {
                    "actions": {
                        "explore": true
                    },
                    "path": "/explore",
                    "resource_label": "Explore",
                    "resource_name": "explore"
                },
                {
                    "actions": {
                        "explore_data_model": true
                    },
                    "path": "/explore/data-model",
                    "resource_label": "Explore Data Model",
                    "resource_name": "explore_data_model"
                },
                {
                    "actions": {
                        "explore_data_study_gallery": true
                    },
                    "path": "/explore/project-gallery",
                    "resource_label": "Explore Data",
                    "resource_name": "explore_data_study_gallery"
                },
                {
                    "actions": {
                        "explore_data_query": true
                    },
                    "path": "/explore/query",
                    "resource_label": "Explore Data Query",
                    "resource_name": "explore_data_query"
                },
                {
                    "actions": {
                        "explore_tools": true
                    },
                    "path": "/explore/tools",
                    "resource_label": "Explore Data",
                    "resource_name": "explore_tools"
                },
                {
                    "actions": {
                        "explore_community": true
                    },
                    "path": "/explore/community",
                    "resource_label": "Explore Data",
                    "resource_name": "explore_community"
                },
                {
                    "actions": {
                        "community_interaction": true,
                        "request_zone2_access": true,
                        "request_zone3_access": true
                    },
                    "path": "/my-profile",
                    "resource_label": "My Profile",
                    "resource_name": "my_profile"
                }
            ],
            "programs": [],
            "projects": [
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/CVB11/projects/simulate11",
                    "resource_label": "/programs/CVB11/projects/simulate11",
                    "resource_name": "CVB11-simulate11"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/CVB21/projects/simulate21",
                    "resource_label": "/programs/CVB21/projects/simulate21",
                    "resource_name": "CVB21-simulate21"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/CVB/projects/simulate",
                    "resource_label": "/programs/CVB/projects/simulate",
                    "resource_name": "CVB-simulate"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/CVB31/projects/simulate31",
                    "resource_label": "/programs/CVB31/projects/simulate31",
                    "resource_name": "CVB31-simulate31"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/CVB41/projects/simulate41",
                    "resource_label": "/programs/CVB41/projects/simulate41",
                    "resource_name": "CVB41-simulate41"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/CVB71/projects/simulate71",
                    "resource_label": "/programs/CVB71/projects/simulate71",
                    "resource_name": "CVB71-simulate71"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/CVB81/projects/simulate81",
                    "resource_label": "/programs/CVB81/projects/simulate81",
                    "resource_name": "CVB81-simulate81"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/CVB91/projects/simulate91",
                    "resource_label": "/programs/CVB91/projects/simulate91",
                    "resource_name": "dummy-CVB91-simulate91"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/CVB91/projects/simulate91",
                    "resource_label": "/programs/CVB91/projects/simulate91",
                    "resource_name": "CVB91-simulate91"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/CVB51/projects/simulate51",
                    "resource_label": "/programs/CVB51/projects/simulate51",
                    "resource_name": "CVB51-simulate51"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/CVB61/projects/simulate61",
                    "resource_label": "/programs/CVB61/projects/simulate61",
                    "resource_name": "dummy-CVB61-simulate61"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/CVB61/projects/simulate61",
                    "resource_label": "/programs/CVB61/projects/simulate61",
                    "resource_name": "CVB61-simulate61"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/CVB101/projects/simulate101",
                    "resource_label": "/programs/CVB101/projects/simulate101",
                    "resource_name": "CVB101-simulate101"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/CVB1/projects/simulate1",
                    "resource_label": "/programs/CVB1/projects/simulate1",
                    "resource_name": "CVB1-simulate1"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/cvb/projects/simulator71",
                    "resource_label": "/programs/cvb/projects/simulator71",
                    "resource_name": "cvb-simulator71"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/cvb/projects/simulator72",
                    "resource_label": "/programs/cvb/projects/simulator72",
                    "resource_name": "cvb-simulator72"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/cvb/projects/simulator73",
                    "resource_label": "/programs/cvb/projects/simulator73",
                    "resource_name": "cvb-simulator73"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/cvb/projects/simulator74",
                    "resource_label": "/programs/cvb/projects/simulator74",
                    "resource_name": "cvb-simulator74"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/cvb/projects/simulator75",
                    "resource_label": "/programs/cvb/projects/simulator75",
                    "resource_name": "cvb-simulator75"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/cvb/projects/simulate71",
                    "resource_label": "/programs/cvb/projects/simulate71",
                    "resource_name": "cvb-simulate71"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/cvb/projects/simulate76",
                    "resource_label": "/programs/cvb/projects/simulate76",
                    "resource_name": "cvb-simulate76"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/cvb/projects/simulate77",
                    "resource_label": "/programs/cvb/projects/simulate77",
                    "resource_name": "cvb-simulate77"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/cvb/projects/simulate78",
                    "resource_label": "/programs/cvb/projects/simulate78",
                    "resource_name": "cvb-simulate78"
                },
                {
                    "actions": {
                        "read": true,
                        "read-storage": true
                    },
                    "path": "/programs/mjff/projects/BEAT-PD",
                    "resource_label": "/programs/mjff/projects/BEAT-PD",
                    "resource_name": "mjff-BEAT-PD"
                }
            ],
            "workspaces": []
        },
        "user_roles": [
            {
                "is_primary_role": true,
                "role_expiry_date": "2099-12-31",
                "role_label": "General User",
                "role_name": "General User"
            }
        ],
        "workspace_access": false,
        "zone1_access": true,
        "zone2_access": false,
        "zone3_access": false
    },
    "resourceActions": {
        "my_stuff": {
            "copy_api_keys_to_workspace": true,
            "copy_cohort_to_workspace": true,
            "download_api_keys": true,
            "export_saved_search_to_workspace": true,
            "list_cohorts": true,
            "list_saved_search": true
        },
        "explore": {
            "explore": true
        },
        "explore_data": {
            "explore_data": true
        },
        "explore_data_model": {
            "explore_data_model": true
        },
        "explore_data_study_gallery": {
            "explore_data_study_gallery": true
        },
        "explore_data_query": {
            "explore_data_query": true
        },
        "explore_tools": {
            "explore_tools": true
        },
        "explore_community": {
            "explore_community": true
        },
        "my_profile": {
            "community_interaction": true,
            "request_zone2_access": true,
            "request_zone3_access": true
        }
    }
}