import React, { useEffect, useState, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FormControl, Select, MenuItem, Paper, TableContainer } from '@mui/material';
import { Autocomplete } from '@mui/material';
import OwnershipTransferRequestDocumentModel from '../authorisation-certificates/OwnershipTransferRequestDocumentModel'
import classes from './index.module.css'
import moment from 'moment'
import CustomLoader from "../../commons/custom-loader";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {LightTooltip} from "../community-left-menu/CommunityLeftMenu";

const OwnershipTransferApprovals = ({ BootstrapInput, workspaceOwnershipRequests }) => {
  const [workspaceOwnerships, setWorkspaceOwnerships] = useState([]);
  const [showOwnershipTransferRequestDocument, setShowOwnershipTransferRequestDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [selectedInitiatedBy, setSelectedInitiatedBy] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState('Show All')
  const [isOpenedSelectList, setIsOpenedSelectList] = useState(false)

  useEffect(() => {
    setWorkspaceOwnerships(workspaceOwnershipRequests)
  }, [workspaceOwnershipRequests]);


  const renderStatus = status => {
    if (status?.toLowerCase() === "accepted") {
      return <span className={classes.submitText}>{status || "-"}</span>
    }
    if (status?.toLowerCase() === "rejected") {
      return <span className={classes.rejectedText}>{status || "-"}</span>
    }
    if (status?.toLowerCase() === "submitted") {
      return <span className={classes.submittedText}>{status || "-"}</span>
    }
    if (status?.toLowerCase() === "completed") {
      return <span className={classes.submitText}>{status || "-"}</span>
    }
    return <span>{status || "-"}</span>
  }

  const filteredRecords = useMemo(() => {
    if (!selectedInitiatedBy && !selectedStatus) {
      return workspaceOwnerships
    }
    return workspaceOwnerships.filter(({ initiatedBy, status }) => {
      if (selectedInitiatedBy && selectedStatus) {
        return selectedInitiatedBy?.toLowerCase()?.includes(initiatedBy?.toLowerCase()) && (selectedStatus === 'Show All' || selectedStatus.toLowerCase() === status.toLowerCase());
      }
      if (selectedInitiatedBy) {
        return selectedInitiatedBy?.toLowerCase()?.includes(initiatedBy?.toLowerCase());
      }
      if (selectedStatus) {
        return selectedStatus.toLowerCase() === status.toLowerCase() || selectedStatus === 'Show All';
      }
    })
  }, [workspaceOwnerships, selectedStatus, selectedInitiatedBy])

  const allInitiatedUsers = useMemo(() => {
    const users = workspaceOwnerships.map(({ initiatedBy }) => initiatedBy);
    return [...new Set(users)]
  }, [workspaceOwnerships])

  if (isLoading) {
    return <div>
      <CustomLoader componentLoader />
    </div>
  }

  return <div>
    <OwnershipTransferRequestDocumentModel
      showOwnershipTransferRequestDocument={showOwnershipTransferRequestDocument}
      setShowOwnershipTransferRequestDocument={setShowOwnershipTransferRequestDocument}
    />
    <div className={classes.selectWrapper} style={{width: '230px'}}>
              <FormControl style={{ margin: '15px 0'}}>
                <span>Status</span>
                <Select
                  defaultValue={selectedStatus}
                  value={selectedStatus}
                  onOpen={() => setIsOpenedSelectList(true)}
                  onClose={() => setTimeout(() => {
                    setIsOpenedSelectList(false)
                  }, 500)}
                  input={<BootstrapInput />}
                  IconComponent={KeyboardArrowDownIcon}
                  onChange={(e) => { setSelectedStatus(e.target.value) }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                  className={classes.select}>
                  {['Show All', 'Accepted', 'Rejected', 'Submitted', 'Completed'].map((item, i) => {
                    const content = item?.split('_').join(' ');
                    return (
                        <MenuItem key={i} value={item}>
                          {(!isOpenedSelectList && item === selectedStatus && content.length >= 12) ? (
                              <LightTooltip placement="right" arrow title={content}>
                                <p className={classes.popoverFieldSelectItem}>{content}</p>
                              </LightTooltip>
                          ) : <p className={classes.popoverFieldSelectItem}>{content}</p>}
                        </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </div>
    <div className={classes.selectWrapper}>

      <FormControl >
        <span>Initiated By</span>
        <Autocomplete
          options={allInitiatedUsers}
          getOptionLabel={(option) => option}
          onChange={(e) => { setSelectedInitiatedBy(e.target.innerText) }}
          renderInput={(params) => {
            const { InputLabelProps, InputProps, ...rest } = params;
            return <BootstrapInput style={{ paddingRight: '0px' }} {...params.InputProps} {...rest} />
          }}
        >
        </Autocomplete>
      </FormControl>
    </div>
    <TableContainer component={Paper} className={classes.table}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="20%" style={{ borderLeft: 'none' }}>Initiated By</TableCell>
            <TableCell width="25%">Workspace Name</TableCell>
            <TableCell width="15%">Workspace Owner</TableCell>
            <TableCell width="10%">Nominated User</TableCell>
            <TableCell width="15%">Submission Date</TableCell>
            <TableCell width="10%">Status</TableCell>
            <TableCell width="15%" style={{ borderLeft: 'none' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {
            filteredRecords?.map((item) => {
            
                const dayStr = moment(item.submitted_at).format('MM/DD/YYYY');
                const timeStr = moment(item.submitted_at).format('hh:mm A');
            
              return <TableRow key={item.request_id}>
                <TableCell width="20%" style={{ borderLeft: 'none' }}>{item?.initiated_by?.first_name + ' ' + item?.initiated_by?.last_name}</TableCell>
                <TableCell width="25%">{item?.workspace_id || "-"}</TableCell>
                <TableCell width="15%">{item?.initiatedBy}</TableCell>
                <TableCell width="10%">{item?.nominatedUser}</TableCell>
                <TableCell width="10%">{dayStr} at {timeStr}</TableCell>
                <TableCell width="10%" >{renderStatus(item?.status)}</TableCell>
                <TableCell width="25%" style={{ borderLeft: 'none' }}>
                  <button className={classes.btnView} onClick={() => setShowOwnershipTransferRequestDocument(item)}>View</button>
                </TableCell>
              </TableRow>
            })
          }
        </TableBody>
      </Table>
    </TableContainer>

  </div >
}

export default OwnershipTransferApprovals;