import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import classes from "./index.module.css";
import ImgClose from '../../../assets/icons/close-small.svg'
import { v4 } from 'uuid'
import MuiToolTip from '@mui/material/Tooltip'

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
    },
    div: {
        
    },
}));

export default function FiltersPopover(props) {

    const {
        title = "More",
        category = "",
        appliedFilters = {},
        onChangeFilterItemState = () => { }
    } = props;

    const { histogram = [] } = appliedFilters

    const materialClasses = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(prev => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={materialClasses.wrapper}>
                <div className={classes.filterDetailsMore}>
                    <span id='cvb-filtersPopover-title' className={classes.filterDetailsRemoveIcon} onClick={handleClick}>{title}</span>
                </div>
                {open ? (
                    <div className={classes.moreFiltersWrapper}>
                        <ul className={classes.moreFiltersPopover}>
                            <li className={classes.popOverCategory}>{category}</li>
                            {
                                histogram?.length > 0 &&
                                histogram.map(item => {
                                    if (Array.isArray(item.key) && item?.key?.length > 1){
                                        item.key = item.key.join(" - ")
                                    }
                                    return <li id={`cvb-filtersPopover-filterItemKey-${item.key}`} key={v4()}>
                                        {
                                            item.key?.length > 18 ?
                                            <MuiToolTip title={item.key}>
                                                <span className={classes.tooltipFilterPopover}>{item.key}</span>
                                            </MuiToolTip> : <span className={classes.tooltipFilterPopover}>{item.key}</span>
                                        }
                                        <span
                                            className={classes.innerRemoveBtn}
                                            id={`cvb-filtersPopover-removeButton-${item.key}`}
                                            onClick={() => { onChangeFilterItemState(null, category, item.key) }}
                                        >
                                            <img id={`cvb-filtersPopover-closeImage-${item.key}`} src={ImgClose} alt="Close" />
                                        </span>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                ) : null}
            </div>
        </ClickAwayListener>
    );
}

