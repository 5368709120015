import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Classes from './index.module.css'
import OutlinedInput from '@mui/material/OutlinedInput'
import DropDown from '../../commons/dropdown'
import { DISEASE_AREA, IMAGING_OPTIONS, GENOMIC_OPTION, PROTEOMIC_OPTIONS, WHOLE_GENOME_SEQUENCING, DIF_DATA_DIC_DOCUMENT, DIF_DATA_DIC_RELATION_DOCUMENT, METABOLOMIC_OPTIONS, WEARABLE_OPTIONS, DERIVED_SENSOR_DATA_OPTIONS, TISSUE_SAMPLE_OPTIONS, MODAL_SYSTEM_OPTIONS, DATA_STANDARDS_OPTIONS, DIF_FORM_COMPLETED_STATUS } from '../../../constants/strings'
import map from 'lodash/map'
import get from 'lodash/get'
import DropZone from 'react-dropzone';
import DocumentIcon from '../../../assets/images/Doc.M.png';
import { getDIFConsentDownloadUrl , getConsentDocUploadURL } from '../../../api/dif-form-api'
import isEmpty from 'lodash/isEmpty'
import { uploadFileToS3 } from '../../../utils/aws-sdk-helper'
import { validateEmailAddress } from '../../../utils/input-validations'
import ErrorText from '../error-text'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import MenuItem from '@mui/material/MenuItem'
import isDate from 'lodash/isDate'
import { deleteDCADIFFile } from '../../../api/dca-form-api'
import Modal from '../../commons/modal'
import CommonDatePicker from '../../commons/common-date-picker'

const DIFDatasetForm = props => {
    const {
        updateDIDatasetName,
        updateDIOwnerType,
        updateDIOwnerName,
        updateDIOwnerTitle,
        updateDIOwnerAffiliation,
        updateDIOwnerEmail,
        updateDIAbstract,
        updateDIDiseaseArea,
        updateDICuratedToStandard,
        updateDIStandard,
        updateDIRestrictDataAccess,
        updateDIAllowDataDownload,
        updateDINumberOfSample,
        updateDIEstimatedSize,
        updateDITimeSeriesChange,
        updateDIDatasetComplete,
        updateDIUploadCompletedDate,
        updateDISubjectSpaceStudy,
        updateDISubjectSpaceCaptureInfo,
        updateDIBioSpecimenStudy,
        updateDIBioSpecimenCaptureInfo,
        updateCanYouProvideDataDict,
        updateOtherImagingText,
        updateOtherGenomicText,
        updateOtherProteomicText,
        updateOtherWholeGenomeText,
        updateImagingOption,
        updateGenomicOption,
        updateProteomicOption,
        updateWholeGenomeOption,
        updateOtherTypeOfDataText,
        updateIsClinical,
        setDataDictDocuments,
        setDataDictRelationshipDocuments,
        updateDIDatasetMoreDataAdded,
        updateModalSystem,
        updateModalSystemOtherText,
        updateDiseaseFeatureText,
        updateTypeOfDataDiseaseArea,
        updateOtherDiseaseAreaText,
        updateMetabolomicOption,
        updateBehavioralTaskText,
        updatePhysiologicalOutcomeText,
        updateTissueSample,
        updateBehavioralAssessment,
        updateWearableSensors,
        updateDerivedSensorsData,
        currentRequestId,
        datasetData,
        isFormSubmitted,
        currentDIFFormStage,
        updateDoYouNeedCurationService,
        updateIsDataCurated,
        updateCurationDate,
        updateCuratorName,
        updateCuratorEmail,
        updateCuratorInstitution,
        updateOtherMetabolomicText,
        updateWearableSensorsOtherText,
        updateTissueSampleOtherText,
        updateDerivedSensorDataOtherText,
        removeDataDictDocuments,
        inputBlurs,
    } = props;

    const [ dataDictRelationshipDocumentDetails, setDataDictRelationshipDocumentDetails ] = useState({});
    const [ dataDictDocumentDetails, setDataDictDocumentDetails ] = useState({});
    const [ dataDictUploadPercentage, setDataDictUploadPercentage ] = useState(0);
    const [ dataDictRelationshipUploadPercentage, setDataDictRelationshipUploadPercentage ] = useState(0);
    const [ difFileToDelete, setDifFileToDelete ] = useState({});
    const [ deleteFileModal, setDeleteFileModal ] = useState(false);
    const id = props.difDatasetActiveIndex;
    const disableInput = currentDIFFormStage === DIF_FORM_COMPLETED_STATUS;

    const onDatasetNameChange = (event) => {
        updateDIDatasetName(event.target.value, id);
    }
    const ownDataOwnerTypeChange = (value) => {
        updateDIOwnerType(value, id);
    }
    const onOwnerNameChange = (event) => {
        updateDIOwnerName(event.target.value, id);
    }
    const onOwnerTitleChange = (event) => {
        updateDIOwnerTitle(event.target.value, id);
    }
    const onOwnerAffiliationChange = (event) => {
        updateDIOwnerAffiliation(event.target.value, id);
    }
    const onOwnerEmailChange = (event) => {
        updateDIOwnerEmail(event.target.value, id);
    }
    const onAbstractChange = (event) => {
        updateDIAbstract(event.target.value, id);
    }
    const onDiseaseAreaSelect = (value) => {
        updateDIDiseaseArea(value, id);
    }
    const onDataCuratedToStandardChange = (value) => {
        if(value === 'no'){
            updateDIStandard('', id);
        }
        updateDICuratedToStandard(value, id);
    }
    const onDatasetStandardsChange = (event) => {
        updateDIStandard(event.target.value, id);
    }
    const onRestrictDataAccessChange = (value) => {
        updateDIRestrictDataAccess(value, id);
    }
    const onAllowDataDownloadChange = (value) => {
        updateDIAllowDataDownload(value, id);
    }
    const onNumberOfSamplesChange = (event) => {
        updateDINumberOfSample(event.target.value, id);
    }
    const onEstimatedSizeChange = (event) => {
        updateDIEstimatedSize(event.target.value, id);
    }
    const onDataTimeSeriesChange = (value) => {
        updateDITimeSeriesChange(value, id);
    }
    const onDatasetCompleteChange = (value) => {
        if(value === 'yes'){
            updateDIDatasetMoreDataAdded('', id);
        }
        updateDIDatasetComplete(value, id);
    }
    const onDataUploadCompletedDateChange = (date) => {
        updateDIUploadCompletedDate(date, id);
    }
    const onSubjectSpaceStudyChange = (value) => {
        if(value === 'no'){
            updateDISubjectSpaceCaptureInfo('', id);
        }
        updateDISubjectSpaceStudy(value, id);
    }
    const onSubjectSpaceCaptureInfoChange = (value) => {
        updateDISubjectSpaceCaptureInfo(value, id);
    }
    const onBioSpecimenStudyChange = (value) => {
        if(value === 'no'){
            updateDIBioSpecimenCaptureInfo('', id);
        }
        updateDIBioSpecimenStudy(value, id);
    }
    const onBioSpaceCaptureInfoChange = (value) => {
        updateDIBioSpecimenCaptureInfo(value, id);
    }
    const onCanYouProvideDataDictChange = (value) => {
        updateCanYouProvideDataDict(value, id);
    }
    const onOtherImagingTextChange = (event) => {
        updateOtherImagingText(event.target.value, id);
    }
    const onOtherGenomicTextChange = (event) => {
        updateOtherGenomicText(event.target.value, id);
    }
    const onOtherProteomicTextChange = (event) => {
        updateOtherProteomicText(event.target.value, id);
    }
    const onOtherWholeGenomeTextChange = (event) => {
        updateOtherWholeGenomeText(event.target.value, id);
    }
    const onImagingOptionChange = (value) => {
        updateImagingOption(value, id);
    }
    const onGenomicOptionChange = (value) => {
        updateGenomicOption(value, id);
    }
    const onProteomicOptionChange = (value) => {
        updateProteomicOption(value, id);
    }
    const onWholeGenomeOptionChange = (value) => {
        updateWholeGenomeOption(value, id);
    }
    const onOtherTypeOfDataChange = (event) => {
        updateOtherTypeOfDataText(event.target.value, id);
    }
    const onIsClinicalChange = (value) =>{
        updateIsClinical(value, id);
    }
    const onModalSystemChange = (value) => {
        updateModalSystem(value, id);
    }
    const onOtherModalSystemTextChange = (event) => {
        updateModalSystemOtherText(event.target.value, id);
    }
    const onDiseaseFeatureChange = (event) => {
        updateDiseaseFeatureText(event.target.value, id);
    }
    const onTypeOfDataDiseaseAreaChange = (value) => {
        updateTypeOfDataDiseaseArea(value, id);
    }
    const onOtherDiseaseAreaTextChange = (event) => {
        updateOtherDiseaseAreaText(event.target.value, id);
    }
    const onMetabolomicChange = (value) => {
        updateMetabolomicOption(value, id);
    }
    const onBehavioralTaskChange = (event) => {
        updateBehavioralTaskText(event.target.value, id);
    }
    const onPhysiologicalOutcomeChange = (event) => {
        updatePhysiologicalOutcomeText(event.target.value, id);
    }
    const onTissueSampleChange = (value) => {
        updateTissueSample(value, id);
    }
    const onBehavioralAssessmentChange = (event) => {
        updateBehavioralAssessment(event.target.value, id);
    }
    const onWearableSensorsChange = (value) => {
        updateWearableSensors(value, id);
    } 
    const onDerivedSensorsChange = (value) => {
        updateDerivedSensorsData(value, id);
    } 
    const onOtherMetabolomicChange = event => {
        updateOtherMetabolomicText(event.target.value, id);
    }
    const onWearableSensorsOtherChange = event => {
        updateWearableSensorsOtherText(event.target.value, id);
    }
    const onTissueSampleOtherChange = event => {
        updateTissueSampleOtherText(event.target.value, id);
    }
    const onDerivedSensorDataOtherChange = event => {
        updateDerivedSensorDataOtherText(event.target.value, id);
    }
    const onDataDictDocumentDrop = async (file) => {
        try {
            setDataDictDocumentDetails(file);
            const preSignedData = await getConsentDocUploadURL(currentRequestId);
            let config = {
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setDataDictUploadPercentage(percentCompleted);
                }
            };
            const res = await uploadFileToS3(file[0], preSignedData, config);
            if (res == 204 || res == 202 || res == 200) {
                setDataDictUploadPercentage(0);
                props.updateSnackBar('Document uploaded successfully.', 'success');
                setDataDictDocuments(`${currentRequestId}/${file[0].name}`, id);
                setDataDictDocumentDetails({});
            }
        } catch (error) {
            console.log('error in uploading', error);
            props.updateSnackBar('Document uploaded failed.', 'error');
        }
    }

    const onDataDictRelationshipDocumentDrop = async (file) => {
        try {
            setDataDictRelationshipDocumentDetails(file);
            const dataDicDoc = `${DIF_DATA_DIC_RELATION_DOCUMENT}`;
            const preSignedData = await getConsentDocUploadURL(currentRequestId);
            let config = {
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setDataDictRelationshipUploadPercentage(percentCompleted);
                }
            };

            const res = await uploadFileToS3(file[0], preSignedData, config);
            if (res == 204 || res == 202 || res == 200) {
                setDataDictRelationshipUploadPercentage(0);
                props.updateSnackBar('Document uploaded successfully.', 'success');
                setDataDictRelationshipDocuments(`${currentRequestId}/${file[0].name}`, id);
            }
        } catch (error) {
            console.log('error in uploading', error);
            props.updateSnackBar('Document uploaded failed.', 'error');
        }
    }
    const onDownloadClick = async (key) => {
        try {
            const url = await getDIFConsentDownloadUrl(key);
            if (!isEmpty(url)) {
                window.open(url, "_blank")
            }
        } catch (error) {
            console.log('error in getting download url', error);
        }
    }

    const onWillDataContinueToUploadChange = (value) => {
        updateDIDatasetMoreDataAdded(value, id);
    }
    const onDoYouNeedCurationService = (value) => {
        updateDoYouNeedCurationService(value, id);
    }
    const onYourDataCuratedChange = (value) => {
        updateIsDataCurated(value, id);
    }
    const onCuratorNameChange = (event) => {
        updateCuratorName(event.target.value, id);
    }
    const onCuratorEmailChange = (event) => {
        updateCuratorEmail(event.target.value, id);
    }
    const onCuratorInstituteChange = (event) => {
        updateCuratorInstitution(event.target.value, id);
    }
    const onDataCurationDateChange = (date) => {
        updateCurationDate(date, id);
    }
    const onDeleteDataDictDocument = (fileKey, index) => {
            setDifFileToDelete({
                "type": "dif",
                "key": fileKey,
                isDataDict: true,
                index: index
            })
            setDeleteFileModal(true);
    }
    const onDeleteRelationshipDoc = (fileKey) => {
        setDifFileToDelete({
            "type": "dif",
            "key": fileKey,
            isDataDict: false,
            index: 0
        })
        setDeleteFileModal(true);
    } 
    const onDeleteDocConfirm = async () => {
        try{
            setDeleteFileModal(false);
            const body = {
                "type": "dif",
                "key": difFileToDelete?.key || '',
            }
            const res = await deleteDCADIFFile(body);
            if(difFileToDelete?.isDataDict){

                removeDataDictDocuments(difFileToDelete?.index, id);
            }else{

                setDataDictRelationshipDocuments('', id);
                setDataDictRelationshipDocumentDetails({});
            }
            setDifFileToDelete({});
            props.updateSnackBar('File deleted successfully', 'success');
        }catch(error){
            setDeleteFileModal(false);
            console.log('error in deleting file', error);
            props.updateSnackBar('Error in deleting file', 'error');
        }
    }
    const closeDeleteFileModal = () => {
        setDeleteFileModal(true);
    }
    const isClinical = get(datasetData, 'type_of_data', '') === 'clinical';
    const isPreclinical = get(datasetData, 'type_of_data', '') === 'preclinical';
    const typeOfData =  get(datasetData, 'type_of_data', '');
    const datasetStandards = isEmpty(get(datasetData, 'dataset_name_and_standards', [])) ? [] : get(datasetData, 'dataset_name_and_standards', []);

    const FILE_DELETE = {
        modalTitle: 'Are you sure you want to delete this file?',
        positiveButtonText: 'Delete',
        negativeButtonText: "Cancel",
        positiveButtonAction: onDeleteDocConfirm,
        negativeButtonAction: closeDeleteFileModal
    }
    const dataDicRelationDocFileName = datasetData['data_dictionaries_relationship_document'];
    const originalDataDicRelFileName = !isEmpty(dataDicRelationDocFileName) ? dataDicRelationDocFileName?.substring(dataDicRelationDocFileName.indexOf('/')+1) || '': '';
    return (
        <div className={Classes.container} id="cvb-DIFDatasetForm-mainContainer">
            <Modal
                open={deleteFileModal}
                handleClose={closeDeleteFileModal}
                dialogProps={FILE_DELETE}
                disableTitleDivider={true}
            />
            <Grid container direction='row' spacing={3}>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='datasetName' id="cvb-DIFDatasetForm-dataSetNameLabel">Data Set Name <sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter data set name"
                        value={get(datasetData, 'dataset_name', '')}
                        fullWidth
                        id={`datasetName`+id}
                        name='datasetName'
                        onChange={onDatasetNameChange}
                        disabled={disableInput}
                    />
                    {
                        isFormSubmitted && isEmpty(get(datasetData, 'dataset_name', '')) &&
                        <ErrorText errorMessage="Enter dataset name" />
                    }
                </Grid>
                <Grid item xs={12} >
                    <label id="cvb-DIFDatasetForm-dataOwnerLabel">Who owns this data?</label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-dataOwnerIndividual">Individual</label>
                            <input id='dataOwner1' type="radio" name="dataOwner"
                                disabled={disableInput}
                                checked={get(datasetData, 'data_owner_type', '') === 'individual'} onChange={() => ownDataOwnerTypeChange('individual')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-dataOwnerInstitution">Institution</label>
                            <input id='dataOwner2' type="radio" name="dataOwner"
                                disabled={disableInput}
                                checked={get(datasetData, 'data_owner_type', '') === 'institution'} onChange={() => ownDataOwnerTypeChange('institution')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='ownerName' id="cvb-DIFDatasetForm-ownerNameLabel">Owner Name</label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter owner name"
                        value={get(datasetData, 'owner_name', '')}
                        fullWidth
                        disabled={disableInput}
                        id='ownerName'
                        name='ownerName'
                        onChange={onOwnerNameChange}
                    />
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='ownerTitle' id="cvb-DIFDatasetForm-ownerTitleLabel">Owner Title</label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter owner title"
                        value={get(datasetData, 'owner_title', '')}
                        fullWidth
                        disabled={disableInput}
                        id='ownerTitle'
                        name='ownerTitle'
                        onChange={onOwnerTitleChange}
                    />
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='ownerAffiliation' id="cvb-DIFDatasetForm-ownerInstitutionLabel">Owner Institution </label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter owner institution  "
                        value={get(datasetData, 'owner_affiliation', '')}
                        fullWidth
                        disabled={disableInput}
                        id='ownerAffiliation'
                        name='ownerAffiliation'
                        onChange={onOwnerAffiliationChange}
                    />
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='ownerEmail' id="cvb-DIFDatasetForm-ownerEmailLabel">Owner Email</label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter email"
                        value={get(datasetData, 'owner_email', '')}
                        fullWidth
                        disabled={disableInput}
                        id={`ownerEmail${id}`}
                        name={`ownerEmail${id}`}
                        onChange={onOwnerEmailChange}
                    />
                    {
                        ( inputBlurs?.['email']?.[id] && !isEmpty(get(datasetData, 'owner_email', '')) && !validateEmailAddress(get(datasetData, 'owner_email', '')) ) &&
                        <ErrorText errorMessage="Enter valid email" />
                    }
                </Grid>
                <Grid item xs={10}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={10}>
                    <label htmlFor='abstract' id="cvb-DIFDatasetForm-abstractDescriptionLabel">Abstract/Description</label>
                    <textarea
                        value={get(datasetData, 'abstract', '')}
                        onChange={onAbstractChange}
                        className={Classes.textAreaClass}
                        placeholder='Enter abstract/description'
                        rows={4}
                        id='abstract'
                        name='abstract'
                        disabled={disableInput}
                    ></textarea>
                </Grid>
                <Grid item xs={10}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont} id="cvb-DIFDatasetForm-diseaseAreaDropDownContainer">
                    <label htmlFor='institutionName' id="cvb-DIFDatasetForm-diseaseAreaLabel">Disease Area</label>
                    <DropDown
                        values={DISEASE_AREA}
                        handleFilterStateChange={onDiseaseAreaSelect}
                        selectedFilter={get(datasetData, 'disease_area', '')}
                        placeholder={'Enter disease name'}
                        disable={disableInput}
                    />
                </Grid>
                <Grid item xs={10}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={12}>
                    <p className={Classes.formSubTitle} id="cvb-DIFDatasetForm-typeDataLabel">Type Of Data</p>
                </Grid>
                <Grid item xs={12} >
                    <label id="cvb-DIFDatasetForm-isClinicalLabel">Is this data Clinical or Preclinical?</label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer}>Clinical</label>
                            <input id='isClinical1' type="radio" name="isClinical"
                                disabled={disableInput}
                                checked={get(datasetData, 'type_of_data', '') === 'clinical'} 
                                onChange={() => onIsClinicalChange('clinical')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-preClinicalLabel">Preclinical</label>
                            <input id='isClinical2' type="radio" name="isClinical" 
                                disabled={disableInput}
                                checked={get(datasetData, 'type_of_data', '') === 'preclinical'}
                                onChange={() => onIsClinicalChange('preclinical')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                </Grid>
               { isPreclinical &&
               <React.Fragment> 
               <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='modalSystem' id="cvb-DIFDatasetForm-modelSystemLabel">Model System</label>
                    <DropDown
                        values={MODAL_SYSTEM_OPTIONS}
                        handleFilterStateChange={onModalSystemChange}
                        selectedFilter={get(datasetData,`[${typeOfData}][modalSystem]`, '')}
                        placeholder={'Please specify'}
                        name='modalSystem'
                        id='modalSystem'
                        disable={disableInput}
                    />
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    {get(datasetData,`[${typeOfData}][modalSystem]`, '') === 'Other' &&
                        <React.Fragment>
                            <label htmlFor='modalSystemText' id="cvb-DIFDatasetForm-otherLabel">Other</label>
                            <OutlinedInput
                                type="text"
                                placeholder="Please specify"
                                value={get(datasetData,`[${typeOfData}][modalSystem_other]`, '')}
                                fullWidth
                                id='modalSystemText'
                                name='modalSystemText'
                                onChange={onOtherModalSystemTextChange}
                                disabled={disableInput}
                            />
                        </React.Fragment>
                    }
                </Grid>
                </React.Fragment>}
               {isPreclinical && 
                    <Grid item xs={12} className={Classes.fieldCont}>
                        <Grid container spacing={3}>
                            <Grid item xs={5}>
                                <label htmlFor='diseaseFeature' id="cvb-DIFDatasetForm-diseaseFeatureLabel">Disease Feature</label>
                                <OutlinedInput
                                    type="text"
                                    id="cvb-DIFDatasetForm-diseaseFeatureField"
                                    placeholder="Please specify"
                                    value={get(datasetData,`[${typeOfData}][diseaseFeature]`, '')}
                                    fullWidth
                                    onChange={onDiseaseFeatureChange}
                                    disabled={disableInput}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {(isClinical || isPreclinical) && <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='imaging' id="cvb-DIFDatasetForm-imagingLabel">Imaging</label>
                    <DropDown
                        values={IMAGING_OPTIONS}
                        handleFilterStateChange={onImagingOptionChange}
                        selectedFilter={get(datasetData,`[${typeOfData}][imaging]`, '')}
                        placeholder={'Please specify'}
                        name='imaging'
                        id='imaging'
                        disable={disableInput}
                    />
                </Grid>
                }
               {(isClinical || isPreclinical) && <Grid item xs={5} className={Classes.fieldCont}>
                    {get(datasetData, `[${typeOfData}][imaging]`, '') === 'Other' &&
                        <React.Fragment>
                            <label htmlFor='otherImaging' id="cvb-DIFDatasetForm-otherImagingLabel">Other</label>
                            <OutlinedInput
                                type="text"
                                placeholder="Please specify"
                                value={get(datasetData,`[${typeOfData}][imaging_other]`, '')}
                                fullWidth
                                id='otherImaging'
                                name='otherImaging'
                                onChange={onOtherImagingTextChange}
                                disabled={disableInput}
                            />
                        </React.Fragment>
                    }
                </Grid>}
                {(isClinical || isPreclinical) && <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='genomic' id="cvb-DIFDatasetForm-genomicLabel">Genomic</label>
                    <DropDown
                        values={GENOMIC_OPTION}
                        handleFilterStateChange={onGenomicOptionChange}
                        selectedFilter={get(datasetData,`[${typeOfData}][genomic]`, '')}
                        placeholder={'Please specify'}
                        name='genomic'
                        id='genomic'
                        disable={disableInput}
                    />
                </Grid>}
                {(isClinical || isPreclinical) && <Grid item xs={5} className={Classes.fieldCont}>
                    {get(datasetData,`[${typeOfData}][genomic]`, '') === 'Other' &&
                        <React.Fragment>
                            <label htmlFor='otherGenomic' id="cvb-DIFDatasetForm-otherGenomicLabel">Other</label>
                            <OutlinedInput
                                type="text"
                                placeholder="Please specify"
                                value={get(datasetData,`[${typeOfData}][genomic_other]`, '')}
                                fullWidth
                                id='otherGenomic'
                                name='otherGenomic'
                                onChange={onOtherGenomicTextChange}
                                disabled={disableInput}
                            />
                        </React.Fragment>
                    }
                </Grid>}
               {(isClinical || isPreclinical) &&  <Grid item xs={5} className={Classes.fieldCont} id="cvb-DIFDatasetForm-proteomicDropdown">
                    <label htmlFor='institutionName' id="cvb-DIFDatasetForm-proteomicLabel">Proteomic</label>
                    <DropDown
                        values={PROTEOMIC_OPTIONS}
                        handleFilterStateChange={onProteomicOptionChange}
                        selectedFilter={get(datasetData,`[${typeOfData}][proteomic]`, '')}
                        placeholder={'Please specify'}
                        disable={disableInput}
                    />
                </Grid>}
                {(isClinical || isPreclinical) && <Grid item xs={5} className={Classes.fieldCont}>
                    {get(datasetData,`[${typeOfData}][proteomic]`, '') === 'Other' &&
                        <React.Fragment>
                            <label htmlFor='otherProteomic' id="cvb-DIFDatasetForm-otherProteomicLabel">Other</label>
                            <OutlinedInput
                                type="text"
                                placeholder="Please specify"
                                value={get(datasetData,`[${typeOfData}][proteomic_other]`, '')}
                                fullWidth
                                disabled={disableInput}
                                id='otherProteomic'
                                name='otherProteomic'
                                onChange={onOtherProteomicTextChange}
                            />
                        </React.Fragment>
                    }
                </Grid>}
                {(isClinical || isPreclinical) && <Grid item xs={5} className={Classes.fieldCont} id="cvb-DIFDatasetForm-transcriptomicDropdown">
                    <label htmlFor='transcriptomic' id="cvb-DIFDatasetForm-transcriptomicLabel">Transcriptomic</label>
                    <DropDown
                        values={WHOLE_GENOME_SEQUENCING}
                        handleFilterStateChange={onWholeGenomeOptionChange}
                        selectedFilter={get(datasetData,`[${typeOfData}][transcriptomic]`, '')}
                        placeholder={'Please specify'}
                        disable={disableInput}
                    />
                </Grid>}
               {(isClinical || isPreclinical) &&  <Grid item xs={5} className={Classes.fieldCont}>
                    {get(datasetData,`[${typeOfData}][transcriptomic]`, '') === 'Other' &&
                        <React.Fragment>
                            <label htmlFor='otherWholeGenome' id="cvb-DIFDatasetForm-otherWholeGenomeLabel">Other</label>
                            <OutlinedInput
                                type="text"
                                placeholder="Please specify"
                                value={get(datasetData,`[${typeOfData}][transcriptomic_other]`, '')}
                                fullWidth
                                disabled={disableInput}
                                id='otherWholeGenome'
                                name='otherWholeGenome'
                                onChange={onOtherWholeGenomeTextChange}
                            />
                        </React.Fragment>
                    }
                </Grid>}
                {(isClinical || isPreclinical) && <Grid item xs={5} className={Classes.fieldCont} id="cvb-DIFDatasetForm-diseaseAreaDropdown">
                    <label htmlFor='diseaseArea' id="cvb-DIFDatasetForm-diseaseAreaLabel">Disease Area</label>
                    <DropDown
                        values={DISEASE_AREA}
                        handleFilterStateChange={onTypeOfDataDiseaseAreaChange}
                        selectedFilter={get(datasetData,`[${typeOfData}][disease_area]`, '')}
                        placeholder={'Please specify'}
                        disable={disableInput}
                    />
                </Grid>}
                {(isClinical || isPreclinical) && <Grid item xs={5} className={Classes.fieldCont}>
                    {get(datasetData,`[${typeOfData}][disease_area]`, '') === 'Other' &&
                        <React.Fragment>
                            <label htmlFor='otherDiseaseArea' id="cvb-DIFDatasetForm-otherDiseaseAreaLabel">Other</label>
                            <OutlinedInput
                                type="text"
                                placeholder="Please specify"
                                value={get(datasetData,`[${typeOfData}][disease_area_other]`, '')}
                                fullWidth
                                disabled={disableInput}
                                id='otherDiseaseArea'
                                name='otherDiseaseArea'
                                onChange={onOtherDiseaseAreaTextChange}
                            />
                        </React.Fragment>
                    }
                </Grid>}
                <Grid item xs={12}>
                    <Grid container direction='row' spacing={3}>
                       {(isClinical || isPreclinical) &&  <Grid item xs={5} className={Classes.fieldCont} id="cvb-DIFDatasetForm-metabolomicDropdown">
                            <label htmlFor='metabolomic' id="cvb-DIFDatasetForm-metabolomicLabel">Metabolomic</label>
                            <DropDown
                                values={METABOLOMIC_OPTIONS}
                                handleFilterStateChange={onMetabolomicChange}
                                selectedFilter={get(datasetData,`[${typeOfData}][metabolomic]`, '')}
                                placeholder={'Please specify'}
                                disable={disableInput}
                            />
                        </Grid>
                        }
                        {(isClinical || isPreclinical) && <Grid item xs={5} className={Classes.fieldCont}>
                            {get(datasetData, `[${typeOfData}][metabolomic]`, '') === 'Other' && 
                                <React.Fragment>
                                    <label htmlFor='otherMetabolomic' id="cvb-DIFDatasetForm-otherMetabolomicLabel">Other</label>
                                    <OutlinedInput
                                        type="text"
                                        placeholder="Please specify"
                                        value={get(datasetData, `[${typeOfData}][metabolomic_other]`, '')}
                                        fullWidth
                                        disabled={disableInput}
                                        id='otherMetabolomic'
                                        name='otherMetabolomic'
                                        onChange={onOtherMetabolomicChange}
                                    />
                                </React.Fragment>
                            }
                        </Grid>}
                        { isPreclinical &&
                            <Grid item xs={5} className={Classes.fieldCont}>
                                <label htmlFor='behavioralTask' id="cvb-DIFDatasetForm-behavioralTaskLabel">Behavioral Task</label>
                                <OutlinedInput
                                    type="text"
                                    placeholder="Please specify"
                                    value={get(datasetData,`[${typeOfData}][behavioralTask]`, '')}
                                    fullWidth
                                    onChange={onBehavioralTaskChange}
                                    disabled={disableInput}
                                    id="cvb-DIFDatasetForm-behavioralTaskField"
                                />
                            </Grid>}
                        {isPreclinical &&
                            <Grid item xs={12} >
                                <Grid container spacing={3}>
                                    <Grid item xs={5} className={Classes.fieldCont}>
                                        <label htmlFor='physiologicalOutcome' id="cvb-DIFDatasetForm-physiologicalOutcomeLabel">Physiologic Outcome</label>
                                        <OutlinedInput
                                            type="text"
                                            placeholder="Please specify"
                                            value={get(datasetData, `[${typeOfData}][physiologicalOutcome]`, '')}
                                            fullWidth
                                            onChange={onPhysiologicalOutcomeChange}
                                            disabled={disableInput}
                                            id="cvb-DIFDatasetForm-physiologicalOutcomeField"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        { isClinical &&
                            <Grid item xs={10} className={Classes.fieldCont}>
                                <label htmlFor='behavioralAssessment' id="cvb-DIFDatasetForm-behavioralAssessmentLabel">Behavioral/Cognitive Assessments</label>
                                <textarea
                                    type="text"
                                    placeholder="Please specify"
                                    value={get(datasetData,`[${typeOfData}][behavioral_assessment]`, '')}
                                    rows={4}
                                    onChange={onBehavioralAssessmentChange}
                                    disabled={disableInput}
                                    className={Classes.textAreaClass}
                                    id="cvb-DIFDatasetForm-behavioralAssessmentField"
                                />
                            </Grid>}
                        {isClinical &&
                            <Grid item xs={12} >
                                <Grid container spacing={3}>
                                    <Grid item xs={5} className={Classes.fieldCont} id="cvb-DIFDatasetForm-wearableSensorsDropdown">
                                        <label htmlFor='wearableSensors' id="cvb-DIFDatasetForm-wearableSensorsLabel">Wearable Sensors</label>
                                        <DropDown
                                            values={WEARABLE_OPTIONS}
                                            handleFilterStateChange={onWearableSensorsChange}
                                            selectedFilter={get(datasetData, `[${typeOfData}][wearable_sensors]`, '')}
                                            placeholder={'Please specify'}
                                            disable={disableInput}
                                        />
                                    </Grid>
                                    {get(datasetData, `[${typeOfData}][wearable_sensors]`, '') === 'Other'
                                    && <Grid item xs={5} className={Classes.fieldCont}>
                                        <label htmlFor='wearableSensorsOther' id="cvb-DIFDatasetForm-wearableSensorsOtherLabel">Other</label>
                                        <OutlinedInput
                                            type="text"
                                            placeholder="Please specify"
                                            value={get(datasetData, `[${typeOfData}][wearable_sensors_other]`, '')}
                                            fullWidth
                                            onChange={onWearableSensorsOtherChange}
                                            disabled={disableInput}
                                            id="cvb-DIFDatasetForm-wearableSensorsOtherField"
                                        />
                                    </Grid>}
                                </Grid>
                                </Grid>
                           }
                        {(isClinical || isPreclinical) &&
                            <Grid item xs={12} >
                                <Grid container spacing={3}>
                                    <Grid item xs={5} className={Classes.fieldCont} id="cvb-DIFDatasetForm-tissueSampleDropdown">
                                        <label htmlFor='tissueSample' id="cvb-DIFDatasetForm-tissueSampleLabel">Tissue Sample</label>
                                        <DropDown
                                            values={TISSUE_SAMPLE_OPTIONS}
                                            handleFilterStateChange={onTissueSampleChange}
                                            selectedFilter={get(datasetData, `[${typeOfData}][tissueSample]`, '')}
                                            placeholder={'Please specify'}
                                            disable={disableInput}
                                        />
                                    </Grid>
                                    {get(datasetData, `[${typeOfData}][tissueSample]`, '') === 'Other'
                                    && <Grid item xs={5} className={Classes.fieldCont}>
                                        <label htmlFor='tissueSampleOther' id="cvb-DIFDatasetForm-tissueSampleOtherLabel">Other</label>
                                        <OutlinedInput
                                            type="text"
                                            placeholder="Please specify"
                                            value={get(datasetData, `[${typeOfData}][tissueSample_other]`, '')}
                                            fullWidth
                                            onChange={onTissueSampleOtherChange}
                                            disabled={disableInput}
                                            id="cvb-DIFDatasetForm-tissueSampleOtherField"
                                        />
                                    </Grid>}
                                </Grid>
                            </Grid>}
                        {isClinical &&
                            <Grid item xs={12} >
                                <Grid container spacing={3}>
                                    <Grid item xs={5} className={Classes.fieldCont}>
                                        <label htmlFor='derivedSensorData' id="cvb-DIFDatasetForm-derivedSensorDataLabel">Derived Sensor Data</label>
                                        <DropDown
                                            values={DERIVED_SENSOR_DATA_OPTIONS}
                                            handleFilterStateChange={onDerivedSensorsChange}
                                            selectedFilter={get(datasetData, `[${typeOfData}][derived_sensor_data]`, '')}
                                            placeholder={'Please specify'}
                                            disable={disableInput}
                                            id="cvb-DIFDatasetForm-derivedSensorDataField"
                                        />
                                    </Grid>
                                    {get(datasetData, `[${typeOfData}][derived_sensor_data]`, '') === 'Other'
                                    && <Grid item xs={5} className={Classes.fieldCont}>
                                        <label htmlFor='derivedSensorDataOther' id="cvb-DIFDatasetForm-derivedSensorDataOtherLabel">Other</label>
                                        <OutlinedInput
                                            type="text"
                                            placeholder="Please specify"
                                            value={get(datasetData, `[${typeOfData}][derived_sensor_data_other]`, '')}
                                            fullWidth
                                            onChange={onDerivedSensorDataOtherChange}
                                            disabled={disableInput}
                                            id="cvb-DIFDatasetForm-derivedSensorDataOtherField"
                                        />
                                    </Grid>}
                                </Grid>
                            </Grid>
                        }
                        {(isClinical || isPreclinical) && <Grid item xs={5} className={Classes.fieldCont}>
                            <label htmlFor='other' id="cvb-DIFDatasetForm-otherClinicalLabel">Other</label>
                            <OutlinedInput
                                type="text"
                                placeholder="Please specify"
                                value={get(datasetData,`[${typeOfData}][other]`, '')}
                                fullWidth
                                onChange={onOtherTypeOfDataChange}
                                disabled={disableInput}
                                id="cvb-DIFDatasetForm-otherClinicalField"
                            />
                        </Grid>}

                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={12} >
                <label id="cvb-DIFDatasetForm-curatedStandardLabel">Please provide any standards followed</label>
                <Grid container direction="row">
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-curatedStandardYesLabel">Yes</label>
                            <input id='curatedStandard1' type="radio" name="curatedStandard"
                                disabled={disableInput}
                                checked={get(datasetData, 'is_data_curated_to_any_standard', '') === 'yes'}
                                onChange={() => onDataCuratedToStandardChange('yes')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-curatedStandardNoLabel">No</label>
                            <input id='curatedStandard2' type="radio" name="curatedStandard"
                                disabled={disableInput}
                                checked={get(datasetData, 'is_data_curated_to_any_standard', '') === 'no'}
                                onChange={() => onDataCuratedToStandardChange('no')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                </Grid>
                {(get(datasetData, 'is_data_curated_to_any_standard', '') === 'yes') &&
                    <Grid item xs={12}>
                        <label htmlFor='institutionName' id="cvb-DIFDatasetForm-curatedStandardDatasetLabel">Please provide the dataset name and any standards followed</label>
                        <Grid container>
                            <Grid item xs={5}>
                                <Select
                                    labelId="datastandard"
                                    id="datastandard"
                                    fullWidth
                                    multiple
                                    displayEmpty
                                    value={datasetStandards}
                                    onChange={onDatasetStandardsChange}
                                    input={<OutlinedInput />}
                                    disabled={disableInput}
                                    renderValue={(selected) => {
                                        if(selected.length === 0) {
                                            return 'Please specify'
                                        }
                                        return selected.join(', ');
                                    }
                                    }
                                >
                                {DATA_STANDARDS_OPTIONS.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox color='primary' checked={datasetStandards.indexOf(name) > -1} />
                                        <p className={Classes.dataStandardOptions}>{name}</p>
                                    </MenuItem>
                                ))}
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                    }
                <Grid item xs={10}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={12} >
                    <label id="cvb-DIFDatasetForm-restrictAccessLabel">Do you want to restrict access to your data?</label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-restrictAccessYesLabel">Yes</label>
                            <input id='restrictAccess1' type="radio" name="restrictAccess"
                                disabled={disableInput}
                                checked={get(datasetData, 'restrict_data_access', '') === 'yes'}
                                onChange={() => onRestrictDataAccessChange('yes')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-restrictAccessNoLabel">No</label>
                            <input id='restrictAccess2' type="radio" name="restrictAccess"
                                disabled={disableInput}
                                checked={get(datasetData, 'restrict_data_access', '') === 'no'}
                                onChange={() => onRestrictDataAccessChange('no')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <label id="cvb-DIFDatasetForm-allowDownloadLabel">Would you like the data to be downloaded by others?</label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-allowDownloadYesLabel">Yes</label>
                            <input id='allowDownload1' type="radio" name="allowDownload"
                                disabled={disableInput}
                                checked={get(datasetData, 'allow_data_download_by_others', '') === 'yes'}
                                onChange={() => onAllowDataDownloadChange('yes')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-allowDownloadNoLabel">No</label>
                            <input id='allowDownload2' type="radio" name="allowDownload"
                                disabled={disableInput}
                                checked={get(datasetData, 'allow_data_download_by_others', '') === 'no'}
                                onChange={() => onAllowDataDownloadChange('no')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor={`noOfSamples${id}`} id="cvb-DIFDatasetForm-noOfSamplesLabel">Approximate Number of Samples/Subjects In Dataset</label>
                    <OutlinedInput
                        type="text"
                        placeholder="Please specify"
                        value={get(datasetData, 'number_of_samples_in_dataset', '')}
                        fullWidth
                        disabled={disableInput}
                        id={`noOfSamples${id}`}
                        name={`noOfSamples${id}`}
                        onChange={onNumberOfSamplesChange}
                    />
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor={`estimatedSize${id}`} id="cvb-DIFDatasetForm-estimatedSizeLabel">Estimated Total Size of Data (Terabytes)</label>
                    <OutlinedInput
                        type="text"
                        placeholder="Please specify"
                        value={get(datasetData, 'data_size', '')}
                        fullWidth
                        disabled={disableInput}
                        id={`estimatedSize${id}`}
                        name={`estimatedSize${id}`}
                        onChange={onEstimatedSizeChange}
                    />
                </Grid>
                <Grid item xs={12} >
                    <label id="cvb-DIFDatasetForm-timeSeriesLabel">Is this data longitudinal/time series?</label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-timeSeriesYesLabel">Yes</label>
                            <input id='timeSeries1' type="radio" name="timeSeries"
                                disabled={disableInput}
                                checked={get(datasetData, 'is_data_longitudinal_or_time_series', '') === 'yes'}
                                onChange={() => onDataTimeSeriesChange('yes')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-timeSeriesNoLabel">No</label>
                            <input id='timeSeries2' type="radio" name="timeSeries"
                                disabled={disableInput}
                                checked={get(datasetData, 'is_data_longitudinal_or_time_series', '') === 'no'}
                                onChange={() => onDataTimeSeriesChange('no')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <label id="cvb-DIFDatasetForm-dataSetCompleteLabel">Is this dataset complete?</label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-dataSetCompleteYesLabel">Yes</label>
                            <input id='dataSetComplete1' type="radio" name="dataSetComplete"
                                disabled={disableInput}
                                checked={get(datasetData, 'is_data_set_complete', '') === 'yes'}
                                onChange={() => onDatasetCompleteChange('yes')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-dataSetCompleteNoLabel">No</label>
                            <input id='dataSetComplete2' type="radio" name="dataSetComplete"
                                disabled={disableInput}
                                checked={get(datasetData, 'is_data_set_complete', '') === 'no'}
                                onChange={() => onDatasetCompleteChange('no')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                </Grid>
                {get(datasetData, 'is_data_set_complete', '') === 'no' && <Grid item xs={12} >
                    <label id="cvb-DIFDatasetForm-willDataContinueLabel">will data continue to be uploaded?</label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-willDataContinueYesLabel">Yes</label>
                            <input id='willDataContinue1' type="radio" name="willDataContinue"
                                disabled={disableInput}
                                checked={get(datasetData, 'will_more_data_added', '') === 'yes'}
                                onChange={() => onWillDataContinueToUploadChange('yes')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-willDataContinueNoLabel">No</label>
                            <input id='willDataContinue2' type="radio" name="willDataContinue"
                                disabled={disableInput}
                                checked={get(datasetData, 'will_more_data_added', '') === 'no'}
                                onChange={() => onWillDataContinueToUploadChange('no')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                </Grid>}
                <Grid item xs={5} id="cvb-DIFDatasetForm-completedDataContainer">
                    <label id="cvb-DIFDatasetForm-completedDataLabel">Data upload Completed by</label>
                    <CommonDatePicker
                        onCalenderDateSelected={onDataUploadCompletedDateChange}
                        date={get(datasetData, 'data_upload_completed_date', null)}
                        disabled={disableInput}
                        id='cvb-dif-dataUpload-dateInput'
                        canClearInput
                    />
                </Grid>
                <Grid item xs={12} >
                    <label id="cvb-DIFDatasetForm-canYouProvideDataDictLabel">Can you provide the data dictionary(s)?</label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-canYouProvideDataDictYesLabel">Yes</label>
                            <input id='canYouProvideDataDict1' type="radio" name="canYouProvideDataDict"
                                disabled={disableInput}
                                checked={get(datasetData, 'can_you_provide_data_dict', '') === 'yes'}
                                onChange={() => onCanYouProvideDataDictChange('yes')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-canYouProvideDataDictNoLabel">No</label>
                            <input id='canYouProvideDataDict2' type="radio" name="canYouProvideDataDict"
                                disabled={disableInput}
                                checked={get(datasetData, 'can_you_provide_data_dict', '') === 'no'}
                                onChange={() => onCanYouProvideDataDictChange('no')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                </Grid>
                {get(datasetData, 'can_you_provide_data_dict', '') === 'yes' &&
                    <Grid item xs={11}>
                        <Grid container spacing={4} direction='row'>
                            <Grid item xs={6} id="cvb-DIFDatasetForm-uploadDataDictContainer">
                                <label id="cvb-DIFDatasetForm-uploadDataDictLabel">Upload Data Dictionaries</label>
                                <div className={Classes.dropZoneButtonContainer}>
                                <DropZone
                                    onDrop={(acceptedFiles) => onDataDictDocumentDrop(acceptedFiles)}
                                    multiple={false}
                                    disabled={disableInput}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <div className={Classes.uploadImageDiv}>
                                                <img src={DocumentIcon} alt='document' />
                                                <p className={Classes.chooseFileText}>
                                                    Drag and drop or
                                                                <span>
                                                        <button className={`${Classes.chooseFileButton}`}>
                                                            Select files
                                                                    </button>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </DropZone>
                            </div>
                            <progress className={Classes.progressBar} value={dataDictUploadPercentage} max={100}/>
                                <p className={Classes.fileNameAndSize}>{get(dataDictDocumentDetails,'[0].name', '')}
                                    <span>{!isEmpty(dataDictDocumentDetails) ? `${get(dataDictDocumentDetails,'[0].size', '')}'kb'` : ''} </span>
                                </p>
                                {!isEmpty(datasetData['data_dictionaries']) &&
                                map(datasetData['data_dictionaries'], (dataDictionary, index)=>{
                                    const originalFileName = !isEmpty(dataDictionary) ? dataDictionary?.substring(dataDictionary.indexOf('/')+1) || '': '';
                                   return <div key={`${dataDictionary}`} className={Classes.downloadButtonWrapper}>
                                       <p className={Classes.fileNameText}>{originalFileName}</p>
                                       <button id="cvb-DIFDatasetForm-uploadDataDictDownloadButton" className={`solid-button medium-size-button ${Classes.actionButtons}`}
                                    onClick={()=>onDownloadClick(dataDictionary)}>
                                       Download
                                   </button>
                                  {!disableInput && <button id="cvb-DIFDatasetForm-uploadDataDictDeleteButton" className={`solid-button medium-size-button ${Classes.actionButtons} ${Classes.deleteButton}`}
                                    onClick={()=>onDeleteDataDictDocument(dataDictionary, index)}>
                                       Delete
                                   </button>}
                                       </div>
                                })
                                }
                            </Grid>
                            <Grid item xs={6} id="cvb-DIFDatasetForm-uploadDocumentDictDownloadContainer">
                                <label id="cvb-DIFDatasetForm-uploadDocumentDictDownloadLabel">Upload Document Describing The Relation Between Data Dictionaries</label>
                                <div className={Classes.dropZoneButtonContainer}>
                                <DropZone
                                    onDrop={(acceptedFiles) => onDataDictRelationshipDocumentDrop(acceptedFiles)}
                                    multiple={false}
                                    disabled={disableInput}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <div className={Classes.uploadImageDiv}>
                                                <img src={DocumentIcon} alt='document' />
                                                <p className={Classes.chooseFileText}>
                                                    Drag and drop or
                                                                <span>
                                                        <button className={`${Classes.chooseFileButton}`}>
                                                            Select files
                                                                    </button>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </DropZone>
                            </div>
                            <progress className={Classes.progressBar} value={dataDictRelationshipUploadPercentage} max={100}/>
                                <p className={Classes.fileNameAndSize}>{!isEmpty(dataDictRelationshipDocumentDetails) ? get(dataDictRelationshipDocumentDetails,'[0].name', '')
                                    : originalDataDicRelFileName
                                    }
                                    <span>{!isEmpty(dataDictRelationshipDocumentDetails) ? `${get(dataDictRelationshipDocumentDetails,'[0].size', '')}'kb'` : ''} </span>
                                </p>
                                {!isEmpty(datasetData['data_dictionaries_relationship_document']) && 
                                <div className={Classes.downloadButtonWrapper}>
                                 <button className={`solid-button medium-size-button ${Classes.actionButtons}`}
                                 onClick={()=>onDownloadClick(datasetData['data_dictionaries_relationship_document'])} id="cvb-DIFDatasetForm-uploadDocumentDictDownloadButton">
                                    Download
                                </button>
                                {!disableInput && <button className={`solid-button medium-size-button ${Classes.actionButtons} ${Classes.deleteButton}`}
                                 onClick={()=>onDeleteRelationshipDoc(datasetData['data_dictionaries_relationship_document'])} id="cvb-DIFDatasetForm-uploadDocumentDictDeleteButton">
                                    Delete
                                </button>}
                                </div>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item xs={10}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={12}>
                    <p className={Classes.formSubDetailsText} id="cvb-DIFDatasetForm-subjectDetailText">BRAINCommons provides a space to capture Deidentified Barcode, Id, or other identifier for the patient or subject in the cases where
                    multiple institutions are using the same patient or subject for their respective studies
                    </p>
                </Grid>
                <Grid item xs={12} >
                    <label id="cvb-DIFDatasetForm-subjectSpaceStudyLabel">Do subjects in this study belong to another study?</label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-subjectSpaceStudyYesLabel">Yes</label>
                            <input id='subjectSpaceStudy1' type="radio" name="subjectSpaceStudy"
                                disabled={disableInput}
                                checked={get(datasetData, 'subject_space.subject_belong_to_another_study', '') === 'yes'}
                                onChange={() => onSubjectSpaceStudyChange('yes')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-subjectSpaceStudyNoLabel">No</label>
                            <input id='subjectSpaceStudy2' type="radio" name="subjectSpaceStudy"
                                checked={get(datasetData, 'subject_space.subject_belong_to_another_study', '') === 'no'}
                                disabled={disableInput}
                                onChange={() => onSubjectSpaceStudyChange('no')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                </Grid>
                {get(datasetData, 'subject_space.subject_belong_to_another_study', '') === 'yes' &&
                <Grid item xs={12} >
                    <label id="cvb-DIFDatasetForm-subjectSpaceCaptureInfoLabel">Do you want to capture this information in BRAINCommons?</label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-subjectSpaceCaptureInfoYesLabel">Yes</label>
                            <input id='subjectSpaceCaptureInfo1' type="radio" name="subjectSpaceCaptureInfo"
                                disabled={disableInput}
                                checked={get(datasetData, 'subject_space.do_you_want_to_capture_this_information', '') === 'yes'}
                                onChange={() => onSubjectSpaceCaptureInfoChange('yes')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-subjectSpaceCaptureInfoNoLabel">No</label>
                            <input id='subjectSpaceCaptureInfo2' type="radio" name="subjectSpaceCaptureInfo"
                                disabled={disableInput}
                                checked={get(datasetData, 'subject_space.do_you_want_to_capture_this_information', '') === 'no'}
                                onChange={() => onSubjectSpaceCaptureInfoChange('no')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                </Grid>}
                <Grid item xs={10}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={12}>
                    <p className={Classes.formSubDetailsText} id="cvb-DIFDatasetForm-biospecimenStudyMultipleLabel">Similar to subject, BRAINCommons also provides a space to capture a Deidentified Identifier where the same Biospecimen is
                    used by multiple institutions
                    </p>
                </Grid>
                <Grid item xs={12} >
                    <label id="cvb-DIFDatasetForm-biospecimenStudyLabel">Do the samples taken in this study also belong to another study?</label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-biospecimenStudyYesLabel">Yes</label>
                            <input id='biospecimenStudy1' type="radio" name="biospecimenStudy"
                                disabled={disableInput}
                                checked={get(datasetData, 'biospecimen_space.samples_belong_to_another_study', '') === 'yes'}
                                onChange={() => onBioSpecimenStudyChange('yes')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-biospecimenStudyNoLabel">No</label>
                            <input id='biospecimenStudy2' type="radio" name="biospecimenStudy"
                                disabled={disableInput}
                                checked={get(datasetData, 'biospecimen_space.samples_belong_to_another_study', '') === 'no'}
                                onChange={() => onBioSpecimenStudyChange('no')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                </Grid>
                {get(datasetData, 'biospecimen_space.samples_belong_to_another_study', '') === 'yes' &&
                <Grid item xs={12} >
                    <label id="cvb-DIFDatasetForm-biospecimenCaptureLabel">Do you want to capture this information in BRAINCommons?</label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-biospecimenCaptureYesLabel">Yes</label>
                            <input id='biospecimenCapture1' type="radio" name="biospecimenCapture"
                                disabled={disableInput}
                                checked={get(datasetData, 'biospecimen_space.do_you_want_to_capture_this_information', '') === 'yes'}
                                onChange={() => onBioSpaceCaptureInfoChange('yes')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-biospecimenCaptureNoLabel">No</label>
                            <input id='biospecimenCapture2' type="radio" name="biospecimenCapture"
                                disabled={disableInput}
                                checked={get(datasetData, 'biospecimen_space.do_you_want_to_capture_this_information', '') === 'no'}
                                onChange={() => onBioSpaceCaptureInfoChange('no')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                </Grid>}
                <Grid item xs={10}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={12} >
                    <label id="cvb-DIFDatasetForm-doYouNeedCurationServiceLabel">Do you need BRAINCommons Curation services for this dataset?</label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-doYouNeedCurationServiceYesLabel">Yes</label>
                            <input id='doYouNeedCurationService1' type="radio" name="doYouNeedCurationService"
                                disabled={disableInput}
                                checked={get(datasetData, 'do_you_need_brain_commons_curation_services', '') === 'yes'}
                                onChange={() => onDoYouNeedCurationService('yes')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-doYouNeedCurationServiceNoLabel">No</label>
                            <input id='doYouNeedCurationService2' type="radio" name="doYouNeedCurationService"
                                disabled={disableInput}
                                checked={get(datasetData, 'do_you_need_brain_commons_curation_services', '') === 'no'}
                                onChange={() => onDoYouNeedCurationService('no')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                </Grid>
                {get(datasetData, 'do_you_need_brain_commons_curation_services', '') === 'no' &&
                    <Grid item xs={12} >
                        <label id="cvb-DIFDatasetForm-isDataCuratedLabel">Is your data already curated?</label>
                        <Grid container>
                            <Grid item xs={2} className={Classes.radioCheckContainer}>
                                <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-isDataCuratedYesLabel">Yes</label>
                                <input id='isDataCurated1' type="radio" name="isDataCurated"
                                    disabled={disableInput}
                                    checked={get(datasetData, 'is_your_data_curated', '') === 'yes'}
                                    onChange={() => onYourDataCuratedChange('yes')} />
                                <span className={Classes.checkmark}></span>
                            </Grid>
                            <Grid item xs={2} className={Classes.radioCheckContainer}>
                                <label className={Classes.radioContainer} id="cvb-DIFDatasetForm-isDataCuratedNoLabel">No</label>
                                <input id='isDataCurated2' type="radio" name="isDataCurated"
                                    disabled={disableInput}
                                    checked={get(datasetData, 'is_your_data_curated', '') === 'no'}
                                    onChange={() => onYourDataCuratedChange('no')} />
                                <span className={Classes.checkmark}></span>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {(get(datasetData, 'do_you_need_brain_commons_curation_services', '') === 'no' &&
                 get(datasetData, 'is_your_data_curated', '') === 'yes') &&
                    <Grid item xs={12} >
                        <Grid container spacing={3}>
                            <Grid item xs={5} className={Classes.fieldCont}>
                                <label htmlFor='curatorName' id="cvb-DIFDatasetForm-curatorNameLabel">Curator Name</label>
                                <OutlinedInput
                                    type="text"
                                    placeholder="Enter curator name"
                                    value={get(datasetData, 'curator.name', '')}
                                    fullWidth
                                    disabled={disableInput}
                                    id='curatorName'
                                    name='curatorName'
                                    onChange={onCuratorNameChange}
                                />
                            </Grid>
                            <Grid item xs={5} className={Classes.fieldCont}>
                                <label htmlFor='curatorEmail' id="cvb-DIFDatasetForm-curatorNameEmail">Curator Email</label>
                                <OutlinedInput
                                    type="text"
                                    placeholder="Enter curator email"
                                    value={get(datasetData, 'curator.email', '')}
                                    fullWidth
                                    disabled={disableInput}
                                    id={`curatorEmail${id}`}
                                    name={`curatorEmail${id}`}
                                    onChange={onCuratorEmailChange}
                                />
                            {
                                 ( inputBlurs?.['email']?.[id] && !isEmpty(get(datasetData, 'curator.email', '')) && !validateEmailAddress(get(datasetData, 'curator.email', '')) ) &&
                                <ErrorText errorMessage="Enter valid email" />
                            }
                            </Grid>
                            <Grid item xs={5} className={Classes.fieldCont}>
                                <label htmlFor='curatorInstitution' id="cvb-DIFDatasetForm-curatorInstitution">Curator Institution</label>
                                <OutlinedInput
                                    type="text"
                                    placeholder="Enter curator institution"
                                    value={get(datasetData, 'curator.institution', '')}
                                    fullWidth
                                    disabled={disableInput}
                                    id='curatorInstitution'
                                    name='curatorInstitution'
                                    onChange={onCuratorInstituteChange}
                                />
                            </Grid>
                        <Grid item xs={5} className={Classes.fieldCont} id="cvb-DIFDatasetForm-curatorDateContainer">
                            <label htmlFor='curationDate' id={`curatorDate${id}`} >Curation Date <sup> *</sup></label>
                            <CommonDatePicker
                                onCalenderDateSelected={onDataCurationDateChange}
                                date={get(datasetData, 'curation_date', null)}
                                disabled={disableInput}
                                maxDate={new Date()}
                                id='cvb-dif-curator-dateInput'
                                showValidationError={(get(datasetData, 'do_you_need_brain_commons_curation_services', '') === 'no' &&
                                    get(datasetData, 'is_your_data_curated', '') === 'yes') &&
                                    inputBlurs?.['curatedDate']?.[id] && !isDate(get(datasetData, 'curation_date', ''))}
                                errorMessage='Please select curation date'
                                canClearInput
                            />
                        </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </div>
    )
}


DIFDatasetForm.defaultProps = {
    updateDIDatasetName: () => { },
    updateDIOwnerType: () => { },
    updateDIOwnerName: () => { },
    updateDIOwnerTitle: () => { },
    updateDIOwnerAffiliation: () => { },
    updateDIOwnerEmail: () => { },
    updateDIAbstract: () => { },
    updateDIAgencyName: () => { },
    updateDIAgencyNumber: () => { },
    updateDIDiseaseArea: () => { },
    updateDICuratedToStandard: () => { },
    updateDIStandard: () => { },
    updateDIRestrictDataAccess: () => { },
    updateDIAllowDataDownload: () => { },
    updateDINumberOfSample: () => { },
    updateDIEstimatedSize: () => { },
    updateDITimeSeriesChange: () => { },
    updateDIDatasetComplete: () => { },
    updateDIUploadCompletedDate: () => { },
    updateDISubjectSpaceStudy: () => { },
    updateDISubjectSpaceCaptureInfo: () => { },
    updateDIBioSpecimenStudy: () => { },
    updateDIBioSpecimenCaptureInfo: () => { },
    updateCanYouProvideDataDict: () => { },
    updateOtherImagingText: () => { },
    updateOtherPreclinicalText: () => { },
    updateOtherGenomicText: () => { },
    updateOtherProteomicText: () => { },
    updateOtherWholeGenomeText: () => { },
    updateImagingOption: () => { },
    updatePreclinicalOption: () => { },
    updateGenomicOption: () => { },
    updateProteomicOption: () => { },
    updateWholeGenomeOption: () => { },
    updateOtherTypeOfDataText: () => { },
    updateIsClinical: () => { },
    updateIsPublicationsPresentChange: () => { },
    addDIFPublications: () => { },
    updatePublicationName: () => { },
    updatePublicationUrl: () => { },
    removeDIFPublications : () => { },
    updateSnackBar: () => { },
    setDataDictDocuments: () => { },
    setDataDictRelationshipDocuments: () => { },
    updateDIDatasetMoreDataAdded: () => { },
    updateModalSystem: () => { },
    updateModalSystemOtherText: () => { },
    updateDiseaseFeatureText: () => { },
    updateTypeOfDataDiseaseArea: () => { },
    updateOtherDiseaseAreaText: () => { },
    updateMetabolomicOption: () => { },
    updateBehavioralTaskText: () => { },
    updatePhysiologicalOutcomeText: () => { },
    updateTissueSample: () => { },
    updateBehavioralAssessment: () => { },
    updateWearableSensors: () => { },
    updateDerivedSensorsData: () => { },
    updateDoYouNeedCurationService: () => { },
    updateIsDataCurated: () => { },
    updateCurationDate: () => { },
    updateCuratorName: () => { },
    updateCuratorEmail: () => { },
    updateCuratorInstitution: () => { },
    updateOtherMetabolomicText: () => { },
    updateWearableSensorsOtherText: () => { },
    updateTissueSampleOtherText: () => { },
    updateDerivedSensorDataOtherText: () => { },
    removeDataDictDocuments: () => { },
    datasetData: {},
    difDatasetActiveIndex: 0,
    currentRequestId: '',
    isAddMoreClick: false,
    currentDIFFormStage: ''
}

export default DIFDatasetForm;
