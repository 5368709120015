/**
 *  External Imports
 */
import { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@mui/material/colors';
import { colors } from './generatePalette';

/**
 *  Internal Imports
 */

/**
 *  Hooks
 */
function useHover() {
  const [isHovered, setIsHovered] = useState(false);

  const eventHandlers = {
    onMouseOver: () => setIsHovered(true),
    onMouseEnter: () => setIsHovered(true),
    onMouseLeave: () => setIsHovered(false),
  };

  return [isHovered, eventHandlers];
}

function useResize(ref, isFullscreenView) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleResize = () => {
    if (!ref?.current) {
      return;
    }

    const currentParams = ref.current.getBoundingClientRect();

    setWidth(currentParams.width);
    setHeight(currentParams.height);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  useEffect(() => {
    handleResize();
  }, [isFullscreenView]);

  useEffect(() => {
    handleResize();
  }, []);

  return { width, height };
}

function useClientRect() {
  const [clientRect, setClientRect] = useState(null);

  const ref = useCallback((elm) => {
    if (elm === null) {
      return;
    }

    setClientRect(elm.getBoundingClientRect());
  }, []);

  return [clientRect, ref];
}

/**
 * @function useConnectionsUsersList
 *
 * @param {function(object[]):void} initUsers Action
 * @param {function(object[]):void} loadMoreUsers Action
 * @param {function({cancelToken: CancelToken | undefined?, defaultDataSize: Number, page: string}): Promise<object>} getUsersMethod Method to request data
 *
 * @return {{loadMoreData: Promise<object>, isSortLoaded: Boolean, isLoaded: Boolean, isFullyLoaded: Boolean, setSortBy: function(object):void}} Return load more method and loading status
 */
function useConnectionsUsersList(initUsers, loadMoreUsers, getUsersMethod) {
  const initDataCancelTokenSrc = axios.CancelToken.source();

  const [isFullyLoaded, setIsFullyLoaded] = useState(true);
  const [sortBy, setSort] = useState(false);
  const [orderBy, setOrder] = useState('asc');
  const [nextDataPage, setNextDataPage] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSortLoaded, setIsSortLoaded] = useState(true);

  const requestData = async (
    isLoadMore = false,
    sort = 'name',
    order = 'asc'
  ) => {
    const basicRequestParams = {
      cancelToken: initDataCancelTokenSrc.token,
      sortBy: sort ? sort : sortBy,
      orderBy: order ? order : orderBy,
    };

    const currentRequestParams = isLoadMore
      ? { ...basicRequestParams, page: nextDataPage }
      : basicRequestParams;

    const { isCanceled, data, nextPage } = await getUsersMethod(
      currentRequestParams
    );

    if (!isCanceled) {
      isLoadMore ? loadMoreUsers(data) : initUsers(data);
    }

    setNextDataPage(nextPage);
    setIsFullyLoaded(Boolean(!nextPage));

    if (sort) {
      setSort(sort);
    }
    if (order) {
      setOrder(order);
    }
  };

  const initData = async () => {
    await requestData();
    setIsLoaded(true);
  };

  const loadMoreData = async () => {
    await requestData(true);
  };

  const setSortBy = async ({ sort, order }) => {
    setIsSortLoaded(false);
    await requestData(false, sort, order);
    setIsSortLoaded(true);
  };

  useEffect(() => {
    initData();

    return () => initDataCancelTokenSrc.cancel();
  }, []);

  return { loadMoreData, isLoaded, isFullyLoaded, setSortBy, isSortLoaded };
}

/**
 * @function useUserListFilter
 *
 * @param {string} searchValue search term
 * @param {object[]} rawUserList user list to filter
 *
 * @return {{filteredUserList: object[]}} Return filtered user list
 */

function useUserListFilter(searchValue, rawUserList) {
  const [filteredUserList, setFilteredUserList] = useState([]);

  const filterUserList = () => {
    if (!rawUserList || !rawUserList.length) {
      return [];
    }
    const searchTerms = searchValue.trim().replace(/\s\s+/g, ' ').split(' ');

    const currentFilteredUserList = rawUserList.reduce((acc, user) => {
      const compareKeysList = user.name.toLowerCase().split(' ');
      for (let index = 0; index < searchTerms.length; index++) {
        compareKeysList.forEach((compareKey) => {
          let counter = 0;
          if (compareKey.includes(searchTerms[index].toLowerCase())) {
            counter = counter + 1;
            if (acc.has(user.name)) {
              const existingMatch = acc.get(user.name);
              acc.set(user.name, {
                value: user,
                match: existingMatch.match + counter,
              });
            } else {
              acc.set(user.name, { value: user, match: counter });
            }
          }
        });
      }
      return acc;
    }, new Map());

    const searchResult = [...currentFilteredUserList.values()]
      .sort((a, b) => b.match - a.match)
      .map((user) => user.value);

    setFilteredUserList(searchResult);
  };

  useEffect(() => {
    if (!searchValue || (searchValue && searchValue.length < 3)) {
      setFilteredUserList(rawUserList);
      return;
    }
    filterUserList();
  }, [searchValue, rawUserList]);

  return { filteredUserList };
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

/**
 * @param  {} variant='default' | 'hex'
 *  @return {colors array}

 */
function useColors(variant = 'default') {
  if (variant == 'hex') {
    return colors;
  }

  return [
    amber,
    blue,
    blueGrey,
    brown,
    cyan,
    deepOrange,
    deepPurple,
    green,
    grey,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow,
  ];
}

/**
 *  Exports
 */
export {
  useHover,
  useClientRect,
  useConnectionsUsersList,
  useUserListFilter,
  useResize,
  getWindowDimensions,
  useWindowDimensions,
  useColors,
};
