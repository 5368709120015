/**
 *  External Imports
 */
import React, { useEffect, useRef, useMemo } from "react";
import {
    arrayOf,
    func,
    number,
    shape,
    string,
    bool,
    instanceOf,
} from "prop-types";
import { Graph } from "@vx/network";
import {
    forceLink,
    forceManyBody,
    forceSimulation,
    forceCollide,
    forceRadial,
} from "d3-force";

/**
 *  Internal Imports
 */
import CommunityConstellationGraphNode from "../community-constellation-graph-node";
import CommunityConstellationGraphLink from "../community-constellation-graph-link";
import CommunityConstellationGraphMask from "../community-constellation-graph-mask";
import { useFormGraphData } from "./CommunityConstellationGraph.helper";
import {
    CONSTELLATION_GRAPH_NODE_TYPE,
    GRAPH_NODE_DEFAULT_RADIUS,
    DEFAULT_GRAPH_LINK_DISTANCE,
    DEFAULT_TOOLTIP_HEIGHT
} from "../../../../constants/constellation";
import {useWindowDimensions} from "../../../../utils/hooks";

const CommunityConstellationGraph = (props) => {
    const {
        parentWidth,
        parentHeight,
        currentUser,
        userLabels,
        userList,
        parentTop,
        parentLeft,
        isSelectionEnabled,
        graphUsersType,
    } = props;

    const { constellationTooltipParams, graphLabelsMask } = props;
    const {
        setTooltipParams,
        setLabelNodeParams,
        updateLabelNodeParams,
        updateConstellationGraphReady,
        resetNodeParams
    } = props;
    const forceRef = useRef();
    const windowDimensions = useWindowDimensions();
    const isSmallWindowDimensions = (windowDimensions.width < 1440 && windowDimensions.height < 800);


    const { graphNodes, graphLinks } = useFormGraphData(
        parentWidth,
        parentHeight,
        currentUser,
        userLabels,
        userList,
        isSelectionEnabled,
        graphUsersType
        );

    if(graphUsersType === 'recommendedUser'){
       // removes links between recommended users & current user
        graphLinks.map((link, i) => {
            if((link.source.nodeType === 1 && link.target.nodeType === 2) || link.source.nodeType === 2){
                graphLinks.splice(i, 1)
            }
        })

        // moves recommended users little bit closer to current user
        graphNodes.map(node => {
            if(node.graphUsersType === 'recommendedUser'){
                node.distance = node.distance - 10
            }
        })
    }

    useEffect(() => {
        updateConstellationGraphReady(false);
        setLabelNodeParams(null);
        resetNodeParams();

        const updateGraphLabelsMaskPosition = () => {
            graphNodes.forEach((node) => {
                if (node.nodeType === CONSTELLATION_GRAPH_NODE_TYPE.LABEL) {
                    if (graphLabelsMask.has(node.metadata.value)) {
                        updateLabelNodeParams({
                            ...graphLabelsMask.get(node.metadata.value),
                            x: node.x,
                            y: node.y,
                        });
                    }
                }
            });
        };

        const updateGraphTooltipPosition = () => {
            const tooltipParent = graphNodes.find(
                (node) =>
                    (node.nodeType ===
                        CONSTELLATION_GRAPH_NODE_TYPE.COMMON_USER &&
                        node.metadata?.recommendationId &&
                        node.metadata.recommendationId ===
                            constellationTooltipParams.userId) ||
                    (node.metadata.connectionId &&
                        node.metadata.connectionId ===
                            constellationTooltipParams.userId) ||
                    node.metadata.id === constellationTooltipParams.userId
            );

            if (tooltipParent) {
                setTooltipParams({
                    ...constellationTooltipParams,
                    x: tooltipParent.x,
                    y: tooltipParent.y,
                    userStatus: tooltipParent.metadata.status,
                    isOpposite: tooltipParent.y < DEFAULT_TOOLTIP_HEIGHT
                });
            } else {
                setTooltipParams(null);
            }
        };
        if (graphNodes.length !== 0 && graphLinks.length !== 0) {
            forceRef.current = forceSimulation(graphNodes)
                .force(
                    "charge",
                    forceManyBody().strength(
                        (Math.min(parentWidth, parentHeight) / 2) * -2
                    )
                )
                .force(
                    "collide",
                    forceCollide()
                        .radius((d) => {
                            if (d.isLabelChild || d.size) {
                                const currentRadius = d.size * 3;
                                return currentRadius <
                                    DEFAULT_GRAPH_LINK_DISTANCE
                                    ? DEFAULT_GRAPH_LINK_DISTANCE
                                    : currentRadius;
                            }
                            return isSmallWindowDimensions ? GRAPH_NODE_DEFAULT_RADIUS / 1.3 : GRAPH_NODE_DEFAULT_RADIUS;
                        })
                        .iterations(1.1)
                )
                .force(
                    "link",
                    forceLink(graphLinks).distance((d) => {
                        if (d.isLabelChild) {
                            const currentDistance =
                                d.distance > d.parentDistance
                                    ? d.distance - d.parentDistance
                                    : d.distance < d.parentDistance
                                    ? d.parentDistance - d.distance
                                    : d.parentDistance === d.distance
                                    ? DEFAULT_GRAPH_LINK_DISTANCE
                                    : d.distance;
                            return (
                                (currentDistance + d.source.size) *
                                (Math.min(parentWidth, parentHeight) / 2 / (isSmallWindowDimensions ? 200 : 100))
                            );
                        }
                        return (
                            d.distance *
                            (Math.min(parentWidth, parentHeight) / 2 / 150)
                        );
                    })
                )
                .force(
                    "r",
                    forceRadial(
                        (d) => {
                            return (
                                d.distance *
                                (Math.min(parentWidth, parentHeight) / 2 / (isSmallWindowDimensions ? 200 : 100))
                            );
                        },
                        parentWidth / 2,
                        parentHeight / 2
                    ).strength(1)
                );
            forceRef.current.alphaMin(0.1);

            forceRef.current.on("tick", () => {
                if (graphLabelsMask.size !== 0) {
                    updateGraphLabelsMaskPosition();
                }
                if (
                    constellationTooltipParams &&
                    constellationTooltipParams.userId !== -1
                ) {
                    updateGraphTooltipPosition();
                }
            });

            forceRef.current.on("end", () => {
                updateConstellationGraphReady(true);
            });
        }

        return () => {
            if(forceRef.current){
                forceRef.current.stop();
            }
        };
    }, [parentWidth, parentHeight, graphNodes, graphLinks]);

    useEffect(() => {
        setTooltipParams(null);
        setLabelNodeParams(null);
        resetNodeParams();
    }, [userList]);

    const graphNode = useMemo(() => {
        const onTooltipToggle = (params) => {
            setTooltipParams(params);
        };

        const onLabelHover = (params) => {
            setLabelNodeParams(params);
        };
        const node = (props) => (
            <CommunityConstellationGraphNode
                {...props}
                onTooltipToggle={onTooltipToggle}
                onLabelHover={onLabelHover}
            />
        );

        return node;
    }, []);

    const graphLink = useMemo(() => {
        const link = (props) => (
            <CommunityConstellationGraphLink
                {...props}
                graphLabelsMask={graphLabelsMask}
            />
        );

        return link;
    }, [graphLabelsMask]);

    return (
        <g>
            <Graph
                data-testid="constellationNetwork"
                top={parentTop}
                left={parentLeft}
                graph={{ nodes: graphNodes, links: graphLinks }}
                linkComponent={graphLink}
                nodeComponent={graphNode}
            />
            <CommunityConstellationGraphMask />
        </g>
    );
};

CommunityConstellationGraph.propTypes = {
    parentWidth: number.isRequired,
    parentHeight: number.isRequired,
    isSelectionEnabled: bool.isRequired,
    graphUsersType: string.isRequired,
    currentUser: shape({
        userIcon: string.isRequired,
        current: bool.isRequired,
        designation: string.isRequired,
        firstName: string.isRequired,
        fullName: string.isRequired,
        id: number.isRequired,
        isUserUnsubscribedFromPendingNotificationEmail: bool.isRequired,
        lastName: string.isRequired,
        organization: string.isRequired,
        userSub: string.isRequired,
        labelsAnswer: arrayOf(string).isRequired,
        labelsKeyword: arrayOf(string).isRequired,
        recommendationId: number,
        status: string,
    }),
    userLabels: arrayOf(
        shape({
            isKeyword: bool.isRequired,
            value: string.isRequired,
        })
    ),
    userList: arrayOf(
        shape({
            userIcon: string.isRequired,
            current: bool.isRequired,
            designation: string.isRequired,
            firstName: string.isRequired,
            fullName: string.isRequired,
            id: number.isRequired,
            isUserUnsubscribedFromPendingNotificationEmail: bool.isRequired,
            lastName: string.isRequired,
            organization: string.isRequired,
            userSub: string.isRequired,
            connectionLinks: arrayOf(string).isRequired,
            weight: number.isRequired,
            email: string.isRequired,
            recommendationId: number,
            connectionId: number,
            status: string,
        })
    ),

    constellationTooltipParams: shape({
        x: number.isRequired,
        y: number.isRequired,
        userId: number.isRequired,
        userIcon: string.isRequired,
        userName: string.isRequired,
        userOrganization: string.isRequired,
        userConnections: arrayOf(string),
        userStatus: string,
        nodeSize: number.isRequired,
        userType: string.isRequired,
        userEmail: string.isRequired,
        isIncomingConnection: bool,
        userWeight: number.isRequired,
        isOpposite: bool.isRequired
    }).isRequired,

    graphLabelsMask: instanceOf(Map).isRequired,

    setTooltipParams: func.isRequired,
    setLabelNodeParams: func.isRequired,
    updateConstellationGraphReady: func.isRequired,
};

export default CommunityConstellationGraph;
