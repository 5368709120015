/**
 *  External Imports
 */
import React from "react";
import {
    func,
    number,
    shape,
    string,
    bool,
    instanceOf,
} from "prop-types";

/**
 *  Internal Imports
 */

import styles from "./index.module.css";

const CommunityConstellationGraphMask = (props) => {
    const { graphLabelsMask, graphLabelsMaskHovered, constellationGraphReady, nodesParams } = props;
    const { selectLabel, setLabelNodeParams } = props;

    const onLabelSelect = (labelParams) => {
        if (!labelParams.isSelectable || !constellationGraphReady) {
            return;
        }
        selectLabel(labelParams);
    };

    const onHoverUpdate = (params) => {
        if (!constellationGraphReady) {
            return;
        }

        setLabelNodeParams(params);
    };

    return (
        <>
            {constellationGraphReady && Array.from(nodesParams.values())?.map(node => (
                <use
                    key={node.id}
                    onClick={() => node.onClick()}
                    xlinkHref={`#node_${node.id}`}
                    className={styles.selectedLabel}
                    style={{
                        transform: `translateX(${node.x}px) translateY(${node.y}px)`
                    }}/>
            ))}
            {graphLabelsMask.size !== 0 || graphLabelsMaskHovered && (
            <>
                {graphLabelsMask.size !== 0 &&
                Array.from(graphLabelsMask.values()).map((maskItem) => (
                    <use
                        key={maskItem.id}
                        onClick={() => onLabelSelect(maskItem)}
                        onMouseEnter={() => onHoverUpdate(maskItem)}
                        xlinkHref={`#${maskItem.id}`}
                        className={styles.selectedLabel}
                        style={{
                            transform: `translateX(${maskItem.x}px) translateY(${maskItem.y}px)`,
                        }}
                    />
                ))}
                {graphLabelsMaskHovered && (
                    <use
                        onClick={() => onLabelSelect(graphLabelsMaskHovered)}
                        onMouseLeave={() => onHoverUpdate(null)}
                        xlinkHref={`#${graphLabelsMaskHovered.id}`}
                        className={
                            graphLabelsMask.has(graphLabelsMaskHovered.value) ||
                            !graphLabelsMaskHovered.isSelectable
                                ? graphLabelsMaskHovered.isKeyword
                                    ? `${styles.hoveredKeyword}`
                                    : `${styles.selectedLabel}`
                                : `${styles.hoveredLabel}`
                        }
                        style={{
                            transform: `translateX(${graphLabelsMaskHovered.x}px) translateY(${graphLabelsMaskHovered.y}px)`,
                        }}
                    />
                )}
            </>
            )}
        </>
    )
};

CommunityConstellationGraphMask.propTypes = {
    selectLabel: func,
    graphLabelsMask: instanceOf(Map),
    constellationGraphReady: bool.isRequired,
    graphLabelsMaskHovered: shape({
        id: string.isRequired,
        x: number.isRequired,
        y: number.isRequired,
        isSelectable: bool.isRequired,
        value: string.isRequired,
        isKeyword: bool.isRequired
    }),
};

export default CommunityConstellationGraphMask;
