import React, { useState, useEffect } from "react";
import MuiStepper from "@mui/material/Stepper";
import StepConnector from "@mui/material/StepConnector";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import MuiStepLabel from "@mui/material/StepLabel";
import MuiStep from "@mui/material/Step";
import findIndex from "lodash/findIndex";
import Grid from "@mui/material/Grid";
import searchBenefits from "../../../assets/icons/search_benefit.png";
import respectBenefits from "../../../assets/icons/respect_benefit.png";
import userBenefits from "../../../assets/icons/user_benefit.png";
import Footer from "../../commons/footer";
import TopNavBar from "../../controls/top-nav-bar";
import { navigate } from "@reach/router";
import Classes from "./index.module.css";
import WhoAreYou from "../../controls/tell-us-more-controls/WhoAreYou";
import MoreAboutYou from "../../controls/tell-us-more-controls/MoreAboutYou";
import {
    ONBOARD_USER_DATA,
} from "../../../constants/strings";
import { getOnboardUserProfiles } from "../../../constants/questionaries-data";
import {
    getAllQuestionaries,
    updateQuestionaries,
    updateCommunityOptInStatus,
} from "../../../api/onboarding-api";
import isEmpty from "lodash/isEmpty";
import Questionaries from "../../controls/tell-us-more-controls/Questionaries";
import map from "lodash/map";
import Modal from "../../commons/modal";
import MuiDialog from "@mui/material/Dialog";
import ImgClose from "../../../assets/images/close.png";
import IconButton from "@mui/material/IconButton";
import leftArrow from "../../../assets/icons/left.svg";
import useScrollToTop from "../../commons/scroll-top";
import { scrollToGivenTop } from "../../../utils/input-validations";
import { Auth } from "aws-amplify";
import CustomLoader from "../../commons/custom-loader";
import { find } from "lodash";
import CommunityWelcome from "../../controls/tell-us-more-controls/CommunityWelcome";
import AcademicWhiteIcon from "../../../assets/icons/academic-white.svg";
import ExpertWhiteIcon from "../../../assets/icons/expert-white.svg";
import ArrowWhite from "../../../assets/icons/arrow-community-white.svg";
import { BC_COMMUNITY_INFO_URL } from "../../../constants/urls";

const StepLabel = withStyles({
    iconContainer: {
        paddingRight: 0,
    },
    horizontal: {
        padding: 0,
    },
    label: {
        textTransform: "uppercase",
        fontSize: "14px !important",
        fontFamily: "Raleway",
        fontWeight: "700 !important",
        marginLeft: "16px !important",
        color: "rgb(204, 204, 204)",
    },
    active: {
        color: "rgb(204, 204, 204) !important",
    },
    complete: {
        color: "#031235",
    },
})(MuiStepLabel);

const Step = withStyles({
    horizontal: {
        padding: 0,
    },
})(MuiStep);

const Stepper = withStyles({
    root: {
        backgroundColor: "inherit",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
})(MuiStepper);

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const Dialog = withStyles((theme) => ({
    container: {
        backgroundColor: "rgba(0, 0, 0, 0.38)",
        backdropFilter: "blur(2px)",
        zIndex: 999,
    },
    paper: {
        borderRadius: 10,
    },
}))(MuiDialog);

const ColorlibConnector = withStyles({
    root: {
        maxWidth: 50,
    },
    active: {
        "& $line": {
            backgroundColor: "#23116C",
        },
    },
    completed: {
        "& $line": {
            backgroundColor: "#23116C",
        },
    },
    line: {
        height: 3,
        width: "100%",
        border: 0,
        backgroundColor: "#eaeaf0",
        borderRadius: 1,
        padding: 0,
    },
})(StepConnector);

const TellUsMore = (props) => {
    const classes = useStyles();

    const [whoAreYou, setWhoAreYou] = useState({});
    const [onboardingData, setOnboardingData] = useState({});
    const [moreAboutYou, setMoreAboutYou] = useState({
        countyCode: {
            callingCodes: ["1"],
            name: "",
        },
        usersAffiliation: [""],
        publications: [{ publicationName: "", publicationURL: "" }],
        contactPrivacy: "Viewable by all",
        projectDescriptionPrivacy: "Viewable by all",
        contact: "",
    });
    const [questionaries, setQuestionaries] = useState([]);
    const [formChanged, setFormChanged] = useState(false);
    const [activeStep, setActiveStep] = React.useState(-1);
    const [steps, setSteps] = useState([]);
    const [showLearnMoreDialog, setShowLearnMoreDialog] = useState(false);
    const [doItLaterModal, setDoItLaterModal] = useState(false);
    const [customAnswerInput, setCustomAnswerInput] = useState("");
    const [customAnswerObj, setCustomAnswersObj] = useState({});
    const [isInCommonsUser, setIsInCommonsUser] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isFormUpdateInProgress, setIsFormUpdateInProgress] = useState(false);
    const { updateSnackBar = () => {} } = props;
    const { updateUserIncognitoStatus } = props;
    const selectedUserPersona =
        find(whoAreYou?.userType, (user) => user.isChecked)?.selectedPersona ||
        "";

    const [isCommunityPrivate, setIsCommunityPrivate] = useState(null);
    const [isCommunityPrivateOnUpdate, setIsCommunityPrivateOnUpdate] = useState(false);

    useEffect(() => {
        getCurrentLoginType();
    }, []);

    useEffect(() => {
        setIsCommunityPrivate(props.isUserPrivate);
    }, [props.isUserPrivate]);

    const getCurrentLoginType = async () => {
        try {
            const user = await Auth.currentSession();
            const identities = user?.idToken?.payload?.identities || null;
            if (
                identities?.length > 0 &&
                identities?.[0]?.providerName == "InCommons"
            ) {
                setIsInCommonsUser(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleValueChange = (step, field, value) => {
        setFormChanged(true);
        switch (step) {
            case 1: {
                const currentValueClone = { ...whoAreYou };
                currentValueClone[field] = value;
                setWhoAreYou(currentValueClone);
                return;
            }
            case 2: {
                let currentValueClone = { ...moreAboutYou };
                currentValueClone[field] = value;
                if (field === "tagList" || field === "tabsInputText") {
                    if (field === "tagList") {
                        currentValueClone = {
                            ...currentValueClone,
                            tabsInputText: "",
                            tagsInputError: false,
                        };
                    }
                    currentValueClone = {
                        ...currentValueClone,
                        tagsInputError: false,
                    };
                }
                setMoreAboutYou(currentValueClone);
                return;
            }
            case 3: {
                const currentValueClone = { ...questionaries };
                let selectedPersona = "";
                const personaIndex = findIndex(
                    whoAreYou?.userType,
                    (user) => user.isChecked
                );
                if (personaIndex > -1) {
                    selectedPersona =
                        whoAreYou?.userType[personaIndex]?.selectedPersona;
                }
                const quesIndex = findIndex(
                    questionaries[selectedPersona],
                    (ques) => ques?.question?.id === value?.questionId
                );
                if (quesIndex > -1) {
                    let tempAns =
                        questionaries[selectedPersona][quesIndex]?.answer;
                    if (!tempAns.includes(value?.answerText)) {
                        if (!value?.isMultiChoice) {
                            tempAns[0] = value?.answerText;
                        } else {
                            tempAns.push(value?.answerText);
                        }
                    } else {
                        const ansIndex = findIndex(
                            tempAns,
                            (ans) => ans === value?.answerText
                        );
                        if (ansIndex > -1) {
                            tempAns.splice(ansIndex, 1);
                        }
                    }
                    questionaries[selectedPersona] = {
                        ...questionaries[selectedPersona],
                        answer: tempAns,
                    };
                    setQuestionaries(currentValueClone);
                }
                return;
            }
            default:
                return;
        }
    };
    useEffect(() => {
        getQuestionaires();
    }, []);

    const getQuestionaires = async () => {
        try {
            const res = await getAllQuestionaries();

            if (!res?.selectedPersona) {
                res.selectedPersona = res?.personas[0] || "AcademicScientist";
            }

            for (let k in res?.questionnaire) {
                res.questionnaire[k].forEach((question) => {
                    question.optionHash = question.options.reduce(
                        (acc, item) => {
                            acc[item.text] = true;
                            return acc;
                        },
                        {}
                    );
                });
            }
            setQuestionaries(res?.questionnaire);
            setOnboardingData(res);
            // console.log(res)
        } catch (error) {
            console.error("error in loading questionaires");
        } finally {
            setLoading(false);
        }
    };

    const updateQuestionare = async (onboardingDetails, lastStep = false) => {
        try {
            setIsFormUpdateInProgress(true);
            await updateQuestionaries(onboardingDetails);
            setIsFormUpdateInProgress(false);
            if (lastStep) {
                updateSnackBar(
                    "You have successfully answered questions to become part of the community.",
                    "Success"
                );
                if (isInCommonsUser && !props.editProfile) {
                    navigate("/?show-dialog=true");
                } else if (props.editProfile && !isInCommonsUser) {
                    //navigate(`/edit-profile/edit-contact-details`)
                } else {
                    navigate(`/my-profile`);
                }
            } else {
                updateSnackBar("Form updated successfully.", "Success");
            }
        } catch (error) {
            setIsFormUpdateInProgress(false);
            updateSnackBar("Unable to update the form.", "Error");
            console.error("error in updating questionaires");
        }
    };

    useEffect(() => {
        const index = findIndex(whoAreYou?.userType, (user) => user.isChecked);
        if (index > -1) {
            const quesSteps =
                questionaries?.[whoAreYou?.userType[index]?.selectedPersona] ||
                [];
            if (!isEmpty(quesSteps)) {
                let allSteps = ["who-are-you"];
                if(index === 0){
                    allSteps.push("more-about-you")
                }
                map(quesSteps, (ques) => allSteps.push(ques?.question?.id));

                setSteps(allSteps);
            }
        }
    }, [questionaries, moreAboutYou, activeStep, whoAreYou]);
    useScrollToTop();
    useEffect(() => {
        const getUSerStatus = getOnboardUserProfiles();
        const index = findIndex(
            ONBOARD_USER_DATA,
            (userData) =>
                userData?.selectedPersona === getUSerStatus?.selectedPersona
        );
        let data = ONBOARD_USER_DATA;

        data.map((type) => {
            if (type?.selectedPersona === onboardingData?.selectedPersona) {
                type.isChecked = true;
            } else {
                type.isChecked = false;
            }
        });

        let whoAreYou = {
            userType: data,
        };

        setWhoAreYou(whoAreYou);

        let selectedPersona = onboardingData?.selectedPersona || "";
        if (!isEmpty(selectedPersona)) {

            let publications = [];

            if (onboardingData?.profiles[selectedPersona]?.keyPublications) {
                onboardingData.profiles[selectedPersona].keyPublications.map(
                    (publication) => {
                        publications.push({
                            publicationName: publication?.name,
                            publicationURL: publication?.url,
                        });
                    }
                );
            } else {
                publications.push({
                    publicationName: "",
                    publicationURL: "",
                });
            }
            let moreAboutYouData = {
                rndProjectDescription:
                    onboardingData?.profiles[selectedPersona]
                        ?.projectDescription?.value || "",
                publications: publications,
                tagList:
                    onboardingData?.profiles[selectedPersona]?.keywords || [],
                contactPrivacy: onboardingData?.profiles[selectedPersona]?.phone
                    ?.isPrivate
                    ? "Private"
                    : "Viewable by all",
                projectDescriptionPrivacy: onboardingData?.profiles[
                    selectedPersona
                ]?.projectDescription?.isPrivate
                    ? "Private"
                    : "Viewable by all"
            };
            setMoreAboutYou(moreAboutYouData);
        }
    }, [onboardingData]);


    useEffect(() => {
        let selectedPersona = onboardingData?.selectedPersona || "";
        if(!isEmpty(selectedPersona)){
            setMoreAboutYou({
                ...moreAboutYou,
                tagList: onboardingData?.profiles[selectedUserPersona]?.keywords,
            })
        }

    }, [selectedUserPersona])

    useEffect(() => {
        const currentStep = getCurrentStepFromURLParams();
        setCurrentStep(currentStep);
    }, [props?.location || "", steps]);

    const CustomStepIcon = (props) => {
        const bgClass =
            props.index > activeStep
                ? Classes.incompleteSteps
                : Classes.currentStep;
        const index = findIndex(steps, (step) => step === props.label) + 1;
        return (
            <div className={`${Classes.stepIconContainer} ${bgClass}`}>
                <span>{index}</span>
            </div>
        );
    };

    const getCurrentStepFromURLParams = () => {
        switch (props?.id) {
            case "community-welcome":
                return -1;
            case "who-are-you":
                return 0;
            case "more-about-you":
                return 1;
            case "question": {
                const hashValue = props?.location?.hash;
                const currentQuestionStep = findIndex(steps, (step) =>
                    hashValue?.includes(step)
                );
                return currentQuestionStep;
            }
            default:
                return 0;
        }
    };
    const getCurrentComponent = () => {
        let selectedPersona = "";
        const personaIndex = findIndex(
            whoAreYou?.userType,
            (user) => user.isChecked
        );
        if (personaIndex > -1) {
            selectedPersona =
                whoAreYou?.userType[personaIndex]?.selectedPersona;
        }
        if (activeStep === -1) {
            return (
                <CommunityWelcome
                    isStatusLoading={isCommunityPrivateOnUpdate}
                    isCommunitySelected={isCommunityPrivate}
                    changeCommunitySelection={updateUserCommunityStatus}
                />
            );
        }
        if (activeStep === 0) {
            return (
                <WhoAreYou
                    step={1}
                    handleValueChange={handleValueChange}
                    whoAreYou={whoAreYou}
                />
            );
        }
        if (activeStep === 1 && personaIndex === 0) {
            return (
                <MoreAboutYou
                    step={2}
                    handleValueChange={handleValueChange}
                    selectedUserPersona={selectedUserPersona}
                    moreAboutYou={moreAboutYou}
                />
            );
        }
        if (
            activeStep >= 2 &&
            activeStep < steps.length &&
            !isEmpty(questionaries) || (activeStep >= 1 && personaIndex === 1 && activeStep < steps.length && !isEmpty(questionaries))
        ) {
            const ques = questionaries?.[selectedPersona]?.[activeStep - (personaIndex === 1 ? 1 : 2)];

            return (
                <Questionaries
                    step={personaIndex === 1 ? 2 : 3}
                    handleValueChange={handleValueChange}
                    setCustomAnswerInput={setCustomAnswerInput}
                    setFormChanged={setFormChanged}
                    customAnswerInput={customAnswerInput}
                    selectedPersona={selectedPersona}
                    questionaries={ques}
                    setCustomAnswersObj={setCustomAnswersObj}
                    customAnswerObj={customAnswerObj}
                    moreAboutYou={moreAboutYou}
                    onboardingData={onboardingData}
                    getQues={getQuestionaires}
                    currentStep={getCurrentStepFromURLParams}
                    selectedUserPersona={selectedUserPersona}
                />
            );
        }
    };


    const navigateUponNextPerviousClick = (step) => {
		const personaIndex = findIndex(
			whoAreYou?.userType,
			(user) => user.isChecked
		);
        if (step === -1) {
            if (props?.myProfile) {
                navigate("/my-profile/tell-us-more/community-welcome");
                return;
            } else if (props?.editProfile) {
                navigate("/edit-profile/tell-us-more/community-welcome");
                return;
            } else {
                navigate("/tell-us-more/community-welcome");
                return;
            }
        }
        if (step === 0) {
            if (props?.myProfile) {
                navigate("/my-profile/tell-us-more/who-are-you");
                return;
            } else if (props?.editProfile) {
                navigate("/edit-profile/tell-us-more/who-are-you");
                return;
            } else {
                navigate("/tell-us-more/who-are-you");
                return;
            }
        }
        if (step === 1 && personaIndex === 0) {
            if (props?.myProfile) {
                navigate("/my-profile/tell-us-more/more-about-you");
                return;
            } else if (props?.editProfile) {
                navigate("/edit-profile/tell-us-more/more-about-you");
                return;
            } else {
                navigate("/tell-us-more/more-about-you");
                return;
            }
        }
        if ((step >= 2 && step < steps.length && personaIndex === 0) || (step >= 1 && step < steps.length && personaIndex === 1))  {
            let selectedPersona = "";
            if (personaIndex > -1) {
                selectedPersona =
                    whoAreYou?.userType[personaIndex]?.selectedPersona;
                const quesSteps = questionaries?.[selectedPersona];

                if (!isEmpty(quesSteps)) {
                    const quesId = quesSteps[step - (personaIndex === 1 ? 1 : 2)]?.question?.id;
                    if (props?.myProfile) {
                        navigate(`/my-profile/tell-us-more/question#${quesId}`);
                        return;
                    } else if (props?.editProfile) {
                        navigate(
                            `/edit-profile/tell-us-more/question#${quesId}`
                        );
                        return;
                    } else {
                        navigate(`/tell-us-more/question#${quesId}`);
                        return;
                    }
                }
            }
        }
    };
    const setCurrentStep = (step) => {
        const isUsersPersona =
            findIndex(whoAreYou?.userType, (persona) => persona?.isChecked) >
            -1;
        const selectedPersonaIndex = findIndex(
            whoAreYou?.userType,
            (persona) => persona?.isChecked
        );
        let selectedPersona = "";
        if (selectedPersonaIndex > -1) {
            selectedPersona =
                whoAreYou?.userType[selectedPersonaIndex]?.selectedPersona;
        }
        const ques = questionaries?.[selectedPersona]?.[step - (selectedPersonaIndex === 1 ? 1 : 2)];
        if (ques) {
            var ip = [];
            map(ques?.answer, (t) => {
                if (!ques.optionHash[t]) {
                    ip.push(t);
                }
            });
            setCustomAnswerInput(ip.join(","));
            setCustomAnswersObj({
                ...customAnswerObj,
                [ques?.question?.id]: ip.join(","),
            });
        }
        setActiveStep(step);
    };
    const handlePrevious = () => {
        const tellUsMoreElement = document.getElementById(`tellUsMoreId`);
        scrollToGivenTop(tellUsMoreElement);
        if (!props?.myProfile && activeStep === -1) {
            navigate(`/onboarding`);
        } else if (props?.myProfile && activeStep === -1) {
            navigate(`/my-profile`);
        } else {
            setCurrentStep(activeStep - 1);
            navigateUponNextPerviousClick(activeStep - 1);
        }
    };

    const validateFormFields = () => {
        const validTagsText = isEmpty(moreAboutYou?.tabsInputText || "");
        if (!validTagsText) {
            setMoreAboutYou({ ...moreAboutYou, tagsInputError: true });
        }
        return validTagsText;
    };

    const createOnboardingUsersMappedData = () => {
        const isUsersPersona =
            findIndex(whoAreYou?.userType, (persona) => persona?.isChecked) >
            -1;
        const selectedPersonaIndex = findIndex(
            whoAreYou?.userType,
            (persona) => persona?.isChecked
        );
        let selectedPersona = "";
        if (selectedPersonaIndex > -1) {
            selectedPersona =
                whoAreYou?.userType[selectedPersonaIndex]?.selectedPersona;
        }

        let answers = {};
        let publications = [];
        let affiliations = [];
        if (moreAboutYou?.publications?.length) {
            moreAboutYou.publications.map((publication) => {
                if (
                    publication?.publicationName ||
                    publication?.publicationURL
                ) {
                    publications.push({
                        name: publication?.publicationName || "",
                        url: publication?.publicationURL || "",
                    });
                }
            });
            let updatedPublications = moreAboutYou.publications.filter(
                (publication) => {
                    return (
                        publication?.publicationName ||
                        publication?.publicationURL
                    );
                }
            );
            if (updatedPublications?.length === 0) {
                updatedPublications.push({
                    name: "",
                    url: "",
                });
            }
            handleValueChange(2, `publications`, updatedPublications);
        }

        if (moreAboutYou?.usersAffiliation?.length) {
            moreAboutYou.usersAffiliation.map((affiliation) => {
                if (affiliation) {
                    affiliations.push(affiliation);
                }
            });
            let updatedAffiliations = moreAboutYou.usersAffiliation.filter(
                (affiliation) => {
                    return affiliation;
                }
            );
            if (updatedAffiliations?.length === 0) {
                updatedAffiliations.push("");
            }
            handleValueChange(2, `usersAffiliation`, updatedAffiliations);
        }

        let profiles = {};
        profiles[whoAreYou?.userType[selectedPersonaIndex]?.selectedPersona] = {
            affiliations: affiliations,
            location: {
                country: moreAboutYou?.country || "",
                city: moreAboutYou?.city || "",
                state: moreAboutYou?.state || "",
            },
            phone: {
                value:
                    moreAboutYou?.countyCode?.callingCodes[0] +
                        "-" +
                        moreAboutYou?.contact || "",
                isPrivate:
                    moreAboutYou?.contactPrivacy === "Private" ? true : false,
            },
            keyPublications: publications,
            projectDescription: {
                value: moreAboutYou?.rndProjectDescription || "",
                isPrivate:
                    moreAboutYou?.projectDescriptionPrivacy === "Private"
                        ? true
                        : false,
            },
            keywords: moreAboutYou?.tagList || [],
        };
        map(questionaries[selectedPersona], (question) => {
            let answerArray = [];
            map(question.answer, (t) => {
                if (!question.optionHash[t]) {
                    const isCustomAnsEmpty = !isEmpty(
                        customAnswerObj[question?.question?.id]
                    );
                    if (isCustomAnsEmpty) {
                        answerArray.push(t);
                    }
                } else {
                    answerArray.push(t);
                }
            });
            answers[question["question"]["id"]] = answerArray;
            let ques = questionaries?.[selectedPersona]?.[activeStep - (selectedPersonaIndex === 1 ? 1 : 2 )];
            if (
                question?.question?.id === ques?.question?.id &&
                !isEmpty(customAnswerObj[ques?.question?.id])
            ) {
                const newAnswers =
                    customAnswerObj[ques?.question?.id].split(",");
                newAnswers.forEach((newAnswer) => {
                    if (
                        !answers[question["question"]["id"]].includes(newAnswer)
                    ) {
                        answers[question["question"]["id"]].push(newAnswer);
                    }
                });
            }
        });
        let currentQuestClone = { ...questionaries };
        const currentQuesAnswers =
            answers[
                currentQuestClone[selectedPersona][activeStep - (selectedPersonaIndex === 1 ? 1 : 2 )]?.question?.id
            ];
        const selPersonaQuesArr = currentQuestClone[selectedPersona];
        selPersonaQuesArr[activeStep - (selectedPersonaIndex === 1 ? 1 : 2 )] = {
            ...selPersonaQuesArr[activeStep - (selectedPersonaIndex === 1 ? 1 : 2 )],
            answer: currentQuesAnswers,
        };
        currentQuestClone = {
            ...questionaries,
            [selectedPersona]: selPersonaQuesArr,
        };
        setQuestionaries(currentQuestClone);

        return {
            profiles: profiles,
            answers: answers,
            selectedPersona: selectedPersona,
        };
    };

    const handleNext = async () => {
        const isUsersPersona =
            findIndex(whoAreYou?.userType, (persona) => persona?.isChecked) >
            -1;

        const checkForUserSelected =
            props.id === "who-are-you" ? isUsersPersona : true;
        let lastStep = steps?.length === activeStep + 1 ? true : false;
        if (checkForUserSelected) {
            if (validateFormFields()) {
                if (formChanged) {
                    const onboardingDetails = createOnboardingUsersMappedData();
                    setFormChanged(false);
                    await updateQuestionare(onboardingDetails, lastStep);
                    navigate(`/my-profile`);
                } else {
                    if (lastStep) {
                        const onboardingDetails =
                            createOnboardingUsersMappedData();
                        await updateQuestionare(onboardingDetails, lastStep);
                        navigate(`/my-profile`);
                    }
                }
                const tellUsMoreElement =
                    document.getElementById(`tellUsMoreId`);
                scrollToGivenTop(tellUsMoreElement);
                if (steps?.length !== activeStep + 1) {
                    setCurrentStep(activeStep + 1);
                    navigateUponNextPerviousClick(activeStep + 1);
                }
            }
        }
    };
    const onLearnMoreClick = () => {
        activeStep === -1
            ? window.open(BC_COMMUNITY_INFO_URL, "_blank")
            : setShowLearnMoreDialog(true);
    };
    const onDoItLaterClick = () => {
        setDoItLaterModal(true);
    };
    const onDoItLaterConfirm = () => {
        setDoItLaterModal(false);
        props?.myProfile
            ? navigate("/my-profile")
            : isInCommonsUser
            ? navigate("/?show-dialog=true")
            : navigate("/");
    };
    const closeDoItLaterModal = () => {
        setDoItLaterModal(false);
    };
    const DO_IT_LATER_MODAL_PROPS = {
        modalTitle:
            "Are you sure you want to answer community questions later?",
        positiveButtonText: "Do It Later",
        negativeButtonText: "Answer Now",
        positiveButtonAction: onDoItLaterConfirm,
        negativeButtonAction: closeDoItLaterModal,
    };

    const updateUserCommunityStatus = async () => {
        setIsCommunityPrivateOnUpdate(true);
        setIsCommunityPrivate(!isCommunityPrivate);
        try {
            const body = {
                private: !isCommunityPrivate,
            };
            const details = await updateCommunityOptInStatus(body);
            const message = `You have successfully opted ${
                !isCommunityPrivate ? "out of joining" : "in to become part of"
            } the BRAINCommons Communities.`;
            updateSnackBar(message, "Success");
            updateUserIncognitoStatus(!isCommunityPrivate);
        } catch (error) {
            updateSnackBar(
                "Something went wrong. Please try again later.",
                "Error"
            );
            setIsCommunityPrivate(props.isUserPrivate);
            console.log(error);
        } finally {
            setIsCommunityPrivateOnUpdate(false);
        }
    };

    return (
        <div className={classes.root}>
            <Modal
                open={doItLaterModal}
                handleClose={closeDoItLaterModal}
                dialogProps={DO_IT_LATER_MODAL_PROPS}>
                <p>
                    If you would prefer to answer these questions later, you can
                    always access them from your My Profile page.
                </p>
            </Modal>

            <div
                className={
                    !props.editProfile ? Classes.onBoardHomeMainContainer : null
                }>
                {!props.myProfile && !props.editProfile && (
                    <TopNavBar showDataPortalLogo />
                )}
                <Grid container spacing={2} id="tellUsMoreId">
                    <Grid lg={12} md={6} xs={12}>
                        <div
                            className={`${
                                props.editProfile
                                    ? Classes.headingsContainerEditProfile
                                    : ""
                            }   ${
                                !props.editProfile
                                    ? Classes.headingsContainer
                                    : ""
                            } ${
                                !props?.myProfile && !props.editProfile
                                    ? Classes.headingsContainerOnboard
                                    : ""
                            }`}>
                            <div>
                                <p
                                    id="cvb-community-tellUsMore-joinUsHeading"
                                    className={`${Classes.helloTitleText} ${
                                        props.editProfile
                                            ? Classes.helloTitleTextEditProfile
                                            : ""
                                    }`}>
                                    {activeStep === -1
                                        ? "Welcome to the BRAINCommons (BC) Community!"
                                        : "Join the BRAINCommons (BC) GOT-IT Community."}
                                </p>
                                <p
                                    id="cvb-community-tellUsMore-collaborateDesc"
                                    className={`${Classes.welcomeText} ${
                                        props.editProfile
                                            ? Classes.welcomeTextEditProfile
                                            : ""
                                    }`}>
                                    {activeStep === -1
                                        ? "Coordinate and increase the exchange of ideas with other users of the BRAINCommons"
                                        : "Collaborate and increase the exchange between Industry and Academia."}
                                </p>
                            </div>
                            <button
                                id="cvb-community-tellUsMore-learnMoreButton"
                                className={`${Classes.learnMoreButton} medium-size-button transparent-button`}
                                onClick={() => onLearnMoreClick()}>
                                Learn More
                            </button>
                        </div>
                    </Grid>
                    <Grid
                        lg={4}
                        md={6}
                        xs={12}
                        className={`${
                            !props.editProfile
                                ? Classes.backroundImageContainer
                                : ""
                        }`}>
                    </Grid>
                </Grid>

                <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={showLearnMoreDialog}>
                    <Grid container className={Classes.communityModalContainer}>
                        <Grid
                            item
                            xs={4}
                            lg={4}
                            md={4}
                            className={Classes.leftContainerImage}>
                            <div
                                className={
                                    Classes.communityModalLeftBlockHeading
                                }>
                                <h2 id="cvb-community-tellUsMore-becomePartHeading">
                                    Become part of the BC GOT-IT Community
                                </h2>
                            </div>
                            <div
                                className={
                                    Classes.communityModalLeftBlockContent
                                }>
                                <div
                                    className={
                                        Classes.communityModalLeftBlocks
                                    }>
                                    <div
                                        className={
                                            Classes.communityModalLeftBlocksImage
                                        }>
                                        <img
                                            id="cvb-community-tellUsMore-academicIcon"
                                            src={AcademicWhiteIcon}
                                            alt=""
                                        />
                                    </div>
                                    <p
                                        id="cvb-community-tellUsMore-academicHeading"
                                        className={
                                            Classes.communityModalLeftContentText
                                        }>
                                        Academic Scientist
                                    </p>
                                </div>
                                <div
                                    className={
                                        Classes.communityModalLeftBlocks
                                    }>
                                    <div
                                        className={
                                            Classes.communityModalLeftBlocksArrow
                                        }>
                                        <img
                                            id="cvb-community-tellUsMore-arrowIcon"
                                            src={ArrowWhite}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div
                                    className={
                                        Classes.communityModalLeftBlocks
                                    }>
                                    <div
                                        className={
                                            Classes.communityModalLeftBlocksImage
                                        }>
                                        <img
                                            id="cvb-community-tellUsMore-industrialIcon"
                                            src={ExpertWhiteIcon}
                                            alt=""
                                        />
                                    </div>
                                    <p
                                        id="cvb-community-tellUsMore-industrialExpertHeading"
                                        className={
                                            Classes.communityModalLeftContentText
                                        }>
                                        Industrial Expert / Mentor
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            lg={8}
                            md={8}
                            className={Classes.rightContainerContent}>
                            <IconButton
                                id="cvb-community-tellUsMore-closeIconButton"
                                aria-label="close"
                                className={Classes.closeButton}
                                onClick={() => setShowLearnMoreDialog(false)}
                                size="large">
                                <img
                                    className={Classes.closeIcon}
                                    src={ImgClose}
                                    alt="close"
                                />
                            </IconButton>
                            <div className={Classes.communityIntroductionBlock}>
                                <h2>The BC GOT-IT Community</h2>
                                <p>
                                    One of the key factors for the success of
                                    translational projects is the timely
                                    integration of industry experienced and
                                    qualified experts in order to complete the
                                    drug discovery competence of academic
                                    research teams. Newly identified drug
                                    targets often originate from academic
                                    research, with the biopharma industry
                                    typically leading drug discovery and
                                    development. As a consequence, academia and
                                    industry need to interact at some point and
                                    close the gap between basic research and
                                    drug discovery to translate research on new
                                    targets into clinically effective products.{" "}
                                </p>
                                <p>
                                    Thus, through this online platform,
                                    scientists from academia will be empowered
                                    by experts from the pharmaceutical industry
                                    to select and prioritize molecular drug
                                    targets most likely to succeed based on
                                    industrial target validation and assessment
                                    standards
                                </p>
                            </div>
                            <hr />
                            <Grid
                                container
                                className={Classes.communityBenefits}>
                                <Grid
                                    item
                                    xs={12}
                                    lg={12}
                                    md={12}
                                    className={
                                        Classes.communityBenefitsHeading
                                    }>
                                    <h2>Benefits</h2>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    lg={4}
                                    md={4}
                                    className={Classes.communityBlockBenefits}>
                                    <div
                                        className={
                                            Classes.communityBlockBenefitsImage
                                        }>
                                        <img
                                            src={searchBenefits}
                                            alt="user benefits"
                                        />
                                    </div>
                                    <p>
                                        Helps academic scientists to recruit
                                        drug discovery experts to receive timely
                                        and well-informed advice
                                    </p>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    lg={4}
                                    md={4}
                                    className={Classes.communityBlockBenefits}>
                                    <div
                                        className={
                                            Classes.communityBlockBenefitsImage
                                        }>
                                        <img
                                            src={respectBenefits}
                                            alt="user benefits"
                                        />
                                    </div>
                                    <p>
                                        Develop a more extended network of
                                        partnerships and genuine collaborations
                                    </p>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    lg={4}
                                    md={4}
                                    className={Classes.communityBlockBenefits}>
                                    <div
                                        className={
                                            Classes.communityBlockBenefitsImage
                                        }>
                                        <img
                                            src={userBenefits}
                                            alt="user benefits"
                                        />
                                    </div>
                                    <p>
                                        Increase the exchange between Industry
                                        and Academia
                                    </p>
                                </Grid>
                            </Grid>
                            <hr />
                        </Grid>
                    </Grid>
                </Dialog>

                {loading ? (
                    <CustomLoader componentLoader styleProp={{ height: 350 }} />
                ) : (
                    <>
                        {activeStep >= 0 && (
                            <Stepper
                                activeStep={activeStep}
                                connector={<ColorlibConnector />}>
                                {steps.map((label, index) => {
                                    return (
                                        <Step
                                            id={`cvb-community-tellUsMore-steps-${label}`}
                                            key={label}
                                            >
                                            <StepLabel
                                                StepIconComponent={() => (
                                                    <CustomStepIcon
                                                        label={label}
                                                        index={index}
                                                    />
                                                )}></StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        )}
                        <div
                            className={
                                props.editProfile
                                    ? Classes.contentMainWrapperEditProfile
                                    : Classes.contentMainWrapper
                            }>
                            {getCurrentComponent()}
                        </div>{" "}
                    </>
                )}
            </div>
            {!loading ? (
                <div
                    className={`${
                        props.editProfile
                            ? Classes.communityFooterEditProfile
                            : ""
                    }  ${Classes.communityFooter}`}>
                    <div
                        className={` ${
                            props.editProfile
                                ? Classes.buttonWrapperClassEditProfile
                                : ""
                        }   ${Classes.buttonWrapperClass}`}>
                        {!props.editProfile ? (
                            <button
                                id="cvb-community-tellUsMore-doItLaterHeading"
                                onClick={onDoItLaterClick}
                                className={`no-outlined-button small-size-button ${Classes.doItLaterButton}`}>
                                Do It Later
                            </button>
                        ) : null}

                        {!(
                            props.myProfile && props.id === "community-welcome"
                        ) &&
                            !(
                                props.editProfile &&
                                props.id === "community-welcome"
                            ) && (
                                <button
                                    id="cvb-community-tellUsMore-previousButton"
                                    className={`transparent-button medium-size-button ${Classes.cancelButton}`}
                                    onClick={handlePrevious}>
                                    <img src={leftArrow} alt="left" />
                                    Previous
                                </button>
                            )}
                        <button
                            disabled={
                                isFormUpdateInProgress || isCommunityPrivate
                            }
                            id="cvb-community-tellUsMore-nextButton"
                            type="submit"
                            className={`medium-size-button solid-button ${Classes.nextButton}`}
                            onClick={handleNext}>
                            {activeStep + 1 === steps?.length
                                ? "Finish"
                                : "Next"}
                        </button>
                    </div>
                </div>
            ) : null}
            {!props?.myProfile && !props?.editProfile && <Footer />}
        </div>
    );
};

export default TellUsMore;
