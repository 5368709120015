import { SET_USER_DETAILS } from '../action-types';
import { setBannerCookie } from '../actions/homeActions';

import userMock from '../../mock-data/userMock';

export default store => next => action => {
    console.log(action);

    switch (action.type) {
        case SET_USER_DETAILS:
            if (!action.payload.email) {
                action.payload = userMock;
                next(setBannerCookie(false));
                break;
            }
    }


    next(action);
}