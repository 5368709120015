import ApolloClient from 'apollo-boost';
import {env} from '../../env'
import { BASE_URL } from '../../constants/google-login-urls'
import { getCognitoIdToken } from '../utilityMethods';
import {
  EXPLORE_FILTERS_GRAPHQL,
  EXPLORE_DATA_COUNT,
  AGE_DISTRIBUTION_GRAPH,
  SUN_BURST_CHART,
  GET_OBJECT_IDS
} from '../graphql-query-helpers/graphql'
import { formFilterData } from '../../utils/explore-data-plotly-helper'
import isEmpty from "lodash/isEmpty";
import map from 'lodash/map'
import { gql } from 'apollo-boost'

export const constructFilterQuery = (appliedFilters) => {
  let queryFilter = {
    "AND": []
  }
  let hiddenProjects = `${env.REACT_APP_PROJECT_HIDDEN}`.split(',');
  let hiddenProjectFilters = [];
  map(hiddenProjects, (project) => hiddenProjectFilters.push({ "!=": { "project_id": project.trim() } }));

  if (!isEmpty(appliedFilters)) {
    const filterKeys = Object.keys(appliedFilters)
    for (let key of filterKeys) {
      if (Array.isArray(appliedFilters[key])) {
        queryFilter.AND.push(
          {
            "gte": {
              [key]: appliedFilters[key][0]
            }
          },
          {
            "lte": {
              [key]: appliedFilters[key][1]
            }
          }
        )
      } else {
        const selectedValues = Object.keys(appliedFilters[key]);
        if (selectedValues.length > 0) {
          queryFilter.AND.push({
            "IN": { [key]: selectedValues }
          })
        }
      }

    }
  }
  queryFilter = {
    "AND": [
      ...queryFilter["AND"],
      ...hiddenProjectFilters
    ]
  }
  return queryFilter
}

export const getAnalyseChartData = async (query, filters) => {
  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/guppy/aggregate`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: "omit"
        },
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
    }
  })

  const response = await apolloClient.query({
    query,
    variables: {
      "nestedAggFields": { "termsFields": ["age_at_onset"] },
      "rangeStep": 10,
      "filter": constructFilterQuery(filters),
    }
  });
  return response?.data || new Error("Unable to get explore data filters")
}

export const getExploreDataFiltersApi = async (filterValues) => {
  let queryVariables = formFilterData(filterValues)
  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/guppy/aggregate`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: "omit"
        },
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
    }
  })

  let finalFilterValues = JSON.parse(JSON.stringify(queryVariables?.filter))
  const response = await apolloClient.query({
    query: EXPLORE_FILTERS_GRAPHQL,
    variables: {
      "nestedAggFields": { "termsFields": ["age_at_onset"] },
      "rangeStep": 10,
      "filter": finalFilterValues,

    }
  });
  return response?.data || new Error("Unable to get explore data filters")
}

export const getExploreDataHierarchyFiltersApi = async (filterValues) => {
  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/guppy/aggregate`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: "omit"
        },
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
    }
  })
  const response = await apolloClient.query({
    query: EXPLORE_FILTERS_GRAPHQL,
    variables: {
      "pdTermsField": { "termsFields": ["meddra_pt"] },
      "nestedAggFields": { "termsFields": ["age_at_enrollment"] },
      "rangeStep": 10,
      "filter": filterValues,
    }
  });
  return response?.data || new Error("Unable to get explore data filters")
}

export const getObjectIdsApi = async (filterValues) => {
  const token = await getCognitoIdToken();

  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/guppy/aggregate`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: "omit"
        },
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
    }
  })
  const response = await apolloClient.query({
    query: GET_OBJECT_IDS,
    variables: {
      "filter": filterValues,
    }
  });
  return response?.data?._aggregation || new Error("Unable to get explore data filters")
}

export const getSunBurstChartAPI = async (filterValues) => {
  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/guppy/aggregate`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: "omit"
        },
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
    }
  })
  const response = await apolloClient.query({
    query: SUN_BURST_CHART,
    variables: {
      "pdTermsField": { "termsFields": ["meddra_pt"] },
      "filter": filterValues,
    }
  });
  return response?.data || new Error("Unable to get explore data filters")
}


export const getAgeDistributionDataApi = async (filterValues) => {
  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/guppy/aggregate`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: "omit"
        },
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
    }
  })

  const response = await apolloClient.query({
    query: AGE_DISTRIBUTION_GRAPH,
    variables: {
      "nestedAggFields": { "termsFields": ["age_at_enrollment"] },
      "filter": filterValues,
    }
  });
  return response?.data || new Error("Unable to get explore data filters")
}




export const getExploreDataTotalCountApi = async (filterValues, excludeFromExploration, processedFilterValues) => {
  let queryVariables = processedFilterValues || formFilterData(filterValues, excludeFromExploration)
  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/guppy/aggregate`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: "omit"
        },
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
    }
  })
  const response = await apolloClient.query({ query: EXPLORE_DATA_COUNT, variables: queryVariables });
  return response?.data || new Error("Unable to get explore data total count")
}

export async function getCohortAggregatesData(cohort, fields) {

  const queryFields = fields.filter((field) => field.enabled && field.queryVariable !== "subjects").reduce((currentValue, field) => {
    if (field.type === 'slider') {
      return `${currentValue} ${field.queryVariable} {histogram {min, max, count}}`
    } else {
      return `${currentValue} ${field.queryVariable} {histogram {key, count}}`
    }
  }, '')

  // Avoid making a query with no fields, which leads to an GraphQL error.
  if(queryFields === '') return null;

  const query = gql`query GetCohortAggregates ($filter: JSON) {_aggregation {case(filter: $filter) {${queryFields}}}}`
  let cohort_query = cohort.cohort_query
  if(typeof cohort_query !== "string") {
    cohort_query = JSON.stringify(cohort_query)
  }
  const filter = JSON.parse(cohort_query)
  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/guppy/aggregate`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: "omit"
        },
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
    }
  })
  const response = await apolloClient.query({ query: query, variables: { "filter": filter } });
  return response || new Error("Unable to get explore data files count")
}

export async function getCohortFiltersValues(queryColumnAttributes, fieldName) {

  const queryFields = queryColumnAttributes.filter((field) => field.queryVariable === fieldName).reduce((currentValue, field) => {
    if (field.type === 'slider') {
      return `${currentValue} ${field.queryVariable} {histogram {min, max, count}}`
    } else {
      return `${currentValue} ${field.queryVariable} {histogram {key, count}}`
    }
  }, '')

  const query = gql`query GetCohortAggregates {_aggregation {case {${queryFields}}}}`

  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/guppy/aggregate`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: "omit"
        },
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
    }
  })
  const response = await apolloClient.query({ query: query });
  return response || new Error(`Unable to get Cohort filter values for field ${fieldName}`)
}

export async function getCohortStatisticsData(cohort, fields) {

  const selectedFields = fields.filter(field => field !== "")

  if(selectedFields.length === 0) return new Error("Invalid field selection for Cohort statistics data.")

  const fieldQuery =  `${selectedFields[0]} {histogram {key, count, termsFields { field, terms { key, count}}}}`

  const query = gql`query GetCohortStatisticsData ($filter: JSON, $nestedAggFields: JSON) {_aggregation {case(filter: $filter, nestedAggFields: $nestedAggFields) {${fieldQuery}}}}`
  const filter = cohort.cohort_query

  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/guppy/aggregate`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: "omit"
        },
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
    }
  })

  const response = await apolloClient.query({ query: query, variables: { "filter": filter, "nestedAggFields": { "termsFields":  [selectedFields.pop()] }, } });
  return response || new Error("Unable to get cohort statistics data.")
}