import React, { useState, useEffect } from 'react'
import Classes from './index.module.css'
import Grid from '@mui/material/Grid'
import tick from '../../../assets/icons/tick.svg'
import disabled from '../../../assets/icons/disabled.svg'
import classes from '../../commons/pending-approvals-stepper/index.module.css'
import CompletedStepPNG from '../../../assets/images/completedStep.png'
import { getWorkspaceRequests } from '../../../api/workspace-api/workspace-api'
import { getUserDocumentURLForDownload, getDataRequestStatus, getSubmittedDUP } from '../../../api/profile-api'
import get from 'lodash/get'
import * as moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import ViewDataForm from '../../controls/view-data-forms'
import TermsOfUse from '../../commons/terms-of-use/'
import PrivacyPolicy from '../../commons/privacy-policy/'
import Modal from '../../commons/modal'
import { navigate } from '@reach/router';
import ViewBlueIcon from '../../../assets/icons/view.svg'
import DownloadBlueIcon from '../../../assets/icons/download.svg'
import { getCertificates, getCertificateUrl } from '../../../api/certificate-api'
import ViewInstitutionCertificateForm from '../../controls/view-institution-certificate-form'
import { v4 } from 'uuid'
import { filter, orderBy } from 'lodash';
import { getDownloadURLForServiceAgreement } from '../../../api/admin/admin-dashboard';
import { getServiceAgreementDetails } from '../../../api/workspace-api/workspace-api';
import RequestAdvancedComputeDetailsView from '../../commons/request-advanced-compute-details-view';
const UserPermissions = (props) => {
    const [zone2DataAccess, setZone2DataAccess] = useState(null)
    const [zone3Requests, setZone3DataAccess] = useState([])
    const [workspaceRequests, setWorkspaceRequests] = useState([]);
    const [selectedWorkspaceRequest, setSelectedWorkspaceRequest] = useState({});
    const [openTermsOfUseModal, setOpenTermsOfUseModal] = useState(false);
    const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState(false);
    const [openDataAccessModal, setOpenDataAccessModal] = useState(false);
    const [openServiceAgreementModal, setOpenServiceAgreementModal] = useState(false);
    const [privacyPolicyDate, setPrivacyPolicyDate] = useState('')
    const [privacyPolicyTime, setPrivacyPolicyTime] = useState('')
    const [viewInstitutionCertificateForm, setViewInstitutionCertificateForm] = useState(false);
    const [currentZone2Data, setCurrentZone2Data] = useState({})

    const getDateTimeObject = (response) => {
        let dayStr = '';
        let timeStr = '';
        let requestSubmittedDate = get(response, 'requestedDate', '');
        if (!requestSubmittedDate) {
            requestSubmittedDate = get(response, 'request_submitted_time', '')
        }
        const reqDateMomentObj = moment(requestSubmittedDate);
        if (moment.isMoment(reqDateMomentObj)) {
            dayStr = reqDateMomentObj.format('MM/DD/YYYY');
            timeStr = reqDateMomentObj.format('hh:mm A');
        }
        response.dayStr = dayStr;
        response.timeStr = timeStr;
        return response;
    }

    useEffect(() => {
        if (props?.data?.custom_attributes?.privacy_policy_date) {
            setPrivacyPolicyDate(moment(props?.data?.custom_attributes?.privacy_policy_date)?.format('MM/DD/YYYY'));
            setPrivacyPolicyTime(moment(props?.data?.custom_attributes?.privacy_policy_date)?.format('hh:mm A'));
        }
    }, [props.data])

    const getAllWorkspaceRequest = async () => {
        try {
            let requests = await getWorkspaceRequests(props.data.id);
            if (!isEmpty(requests)) {
                requests = requests.map(request => getDateTimeObject(request));
                setWorkspaceRequests(requests);
            }
        } catch (error) {
            console.log('error in getting workspace request', error);
            setWorkspaceRequests([]);
        }
    }

    const getZoneRequest = async (type) => {
        try {
            let response = await getDataRequestStatus(type, props.data.username);
            if (!isEmpty(response)) {
                let approvedRequests = [];
                let requestOtherThanApproved = [];
                let latestApprovedRequest = {};
                let allRequests = [];
                response = getDateTimeObject(response);
                if (type === "zone2") {
                    approvedRequests = filter(response, (data) => data.request_status === 'APPROVED');
                    requestOtherThanApproved = filter(response, (data) => data.request_status !== 'APPROVED');
                    if(approvedRequests.length > 1){
                        latestApprovedRequest =  orderBy(approvedRequests, [(data)=> new Date(data.request_submitted_time)], ['desc'])[0];
                    }else if(approvedRequests.length === 1 ){
                        latestApprovedRequest = approvedRequests[0];
                    }else{
                        latestApprovedRequest = {}
                    }
                    if(!isEmpty(latestApprovedRequest)){
                        allRequests.push(latestApprovedRequest);
                    }else{
                        allRequests = [ ...requestOtherThanApproved];
                    }
                    setZone2DataAccess(allRequests)
                } else {
                    setZone3DataAccess(response?.user_zone3_access_requests)
                }
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllWorkspaceRequest();
        getZoneRequest('zone2');
        getZoneRequest('zone3');
    }, []);

    const toggleTermOfUseModal = () => {
        setOpenTermsOfUseModal(false);
    }
    const onOpenTermsOfUseModalClick = () => {
        setOpenTermsOfUseModal(true);
    }

    const TERM_OF_USE_MODAL = {
        modalTitle: "Terms Of Use",
        modalContent: "",

    }

    const togglePrivacyPolicyModal = () => {
        setOpenPrivacyPolicyModal(false);
    }
    const onOpenPrivacyPolicyModalClick = () => {
        setOpenPrivacyPolicyModal(true);
    }

    const PRIVACY_POLICY_MODAL = {
        modalTitle: "Privacy Notice",
        modalContent: "",

    }

    const toggleDataAccessModal = () => {
        setOpenDataAccessModal(false);
    }
    const onOpenDataAccessModalClick = async (zone2Data) => {
        try{
            const res = await getSubmittedDUP(zone2Data?.user_sub)
            setCurrentZone2Data(res);
            setOpenDataAccessModal(true);
        }catch(e){
            console.log(e)
        }
    }

    const DATA_ACCESS_MODAL = {
        modalTitle: "Data Use Agreement",
        modalContent: "",
    }


    const toggleServiceAgreementModal = () => {
        setOpenServiceAgreementModal(false);
    }
    const onOpenServiceAgreementModalClick = async (requestData) => {
        try {
            let workspaceResponse = await getServiceAgreementDetails(requestData?.user_sub, requestData?.request_id);
            setSelectedWorkspaceRequest(workspaceResponse);
            setOpenServiceAgreementModal(true);
        } catch (error) {
            setSelectedWorkspaceRequest({});
            console.log(error)
        }
    }
    const onDocumentDownload = async (type) => {
        try {
            const res = await getUserDocumentURLForDownload(props.id, type);
            window.location.href = res?.document_url;
        } catch (error) {
            console.log('error');
        }
    }
    let count = 0;
    const downloadURL = (url) => {
        let hiddenIFrameID = 'hiddenDownloader' + count++;
        let iframe = document.createElement('iframe');
        iframe.id = hiddenIFrameID;
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        iframe.src = url;
    }
    const onDownloadCertificates = async () => {
        try {
            const userSub = props.data.id;
            const res = await getCertificates(userSub);
            if (isEmpty(res?.certificate_list)) {
                props.updateSnackBar('No certificates to download', 'error');
            } else {
                map(res?.certificate_list, async (certificate) => {
                    const certificateName = certificate?.certificate_name;
                    const certUrlRes = await getCertificateUrl(certificateName, props.data.id);
                    downloadURL(certUrlRes?.url);
                })
            }
        } catch (error) {
            props.updateSnackBar('Error in download certificates', 'error');
        }
    }


    const SERVICE_AGREEMENT_MODAL = {
        modalTitle: "Advanced Compute Request",
        modalContent: "",

    }

    const goBack = () => {
        if (props?.location?.state?.adminDashboard) {
            navigate(`/admin/user-profile/${props.data.id}#certificates`, { state: { adminDashboard: true } })
        } else {
            navigate(`/admin/user-profile/${props.data.id}#certificates`, { state: { search: true, searchString: props?.location?.state?.searchString } })
        }
    }

    const getInstitutionCertificateData = async (zone2Data) => {
        try{
            const res = await getSubmittedDUP(zone2Data?.user_sub)
            setCurrentZone2Data(res);
            setViewInstitutionCertificateForm(true);
        }catch(e){
            console.log(e)
        }
      
    }

    const onDownloadServiceAgreement = async (requestData) => {
        try {
            const userSub = get(requestData, 'user_sub', '');
            const requestId = get(requestData, 'request_id', '');
            const fileName = get(requestData, 'service_agreement_file_name', '');
            const res = await getDownloadURLForServiceAgreement(userSub, requestId, fileName);
            downloadURL(res);

        } catch (error) {
            props.updateSnackBar('Error in downloading Advanced Compute Request', 'error');
        }
    }


    return (
        <div>
            <TermsOfUse
                open={openTermsOfUseModal}
                handleClose={toggleTermOfUseModal}
                dialogProps={TERM_OF_USE_MODAL}
                submitted='true'
                data={props.data}
                policyDate={privacyPolicyDate}
                policyTime={privacyPolicyTime}
            />
            <PrivacyPolicy
                open={openPrivacyPolicyModal}
                handleClose={togglePrivacyPolicyModal}
                dialogProps={PRIVACY_POLICY_MODAL}
                submitted='true'
                data={props.data}
                policyDate={privacyPolicyDate}
                policyTime={privacyPolicyTime}
            />
            <Modal open={openDataAccessModal} handleClose={toggleDataAccessModal} dialogProps={DATA_ACCESS_MODAL} disableTitleDivider disableBottomHorizontalDivider >
                <div className={[Classes.contentStartText, Classes.showHeader].join(' ')}>
                    <p>
                        <span id='cvb-adminProfile-UserPermissions-dup-submittedOnTime' className={Classes.submitDetails} ><p className={Classes.submitText}>Submitted</p> on {getDateTimeObject(currentZone2Data)?.dayStr} at {getDateTimeObject(currentZone2Data)?.timeStr}</span>
                        <p id='cvb-adminProfile-UserPermissions-dup-researcherName' className={Classes.submitName}>By {currentZone2Data?.researcherName}</p>
                    </p>

                </div>
                <ViewDataForm
                    data={currentZone2Data}
                />
            </Modal>

            <Modal open={viewInstitutionCertificateForm}
                handleClose={() => setViewInstitutionCertificateForm(false)}
                dialogProps={{ modalTitle: "Institution Certificate" }} disableTitleDivider disableBottomHorizontalDivider >
                <div className={[classes.contentStartText, classes.showHeader].join(' ')}>
                    <p>
                        <span id='cvb-adminProfile-UserPermissions-instituteCert-submittedOnTime' className={classes.submitDetails} ><p className={classes.submitText}>Submitted</p> on {getDateTimeObject(currentZone2Data)?.dayStr} at {getDateTimeObject(currentZone2Data)?.timeStr}</span>
                        <p id='cvb-adminProfile-UserPermissions-instituteCert-researcherName' className={classes.submitName}>By {currentZone2Data?.researcherName}</p>
                    </p>

                </div>
                <ViewInstitutionCertificateForm {...currentZone2Data} />
            </Modal>

            <RequestAdvancedComputeDetailsView
                open={openServiceAgreementModal}
                handleClose={toggleServiceAgreementModal}
                dialogProps={SERVICE_AGREEMENT_MODAL}
                workspaceDetails={selectedWorkspaceRequest}
            >
            </RequestAdvancedComputeDetailsView>



            <Grid xs={12} lg={12}>

                <div className={Classes.aboutTitleContainer}>
                    <div id='cvb-adminProfile-UserPermissions-zoneAccessHeading' className={Classes.aboutTabTitle}>Zone Access</div>
                    <div id='cvb-adminProfile-UserPermissions-workspaceHeading' className={[Classes.aboutTabTitle, Classes.aboutTitleRight].join(' ')}>Workspace</div>
                </div>
                <div className={Classes.accessBoxes}>
                    <div className={Classes.accessBox}>
                        <div id='cvb-adminProfile-UserPermissions-zone1Text' className={Classes.accessBoxTitle}> ZONE 1</div>
                        <div id='cvb-adminProfile-UserPermissions-publicAccessText' className={Classes.accessBoxSubtitle}>Public Access</div>
                        <div className={Classes.accessStatus}>
                            <img id='cvb-adminProfile-UserPermissions-zone1AccessIcon' className={Classes.accessIcon} src={props.data?.permissions?.zone1_access ? tick : disabled} />
                            <span id='cvb-adminProfile-UserPermissions-zone1AccessIconText' className={Classes.accessIconText} >{props.data?.permissions?.zone1_access ? 'Authorized' : 'Unauthorized'}</span>
                        </div>
                    </div>
                    <div className={Classes.accessBox}>
                        <div id='cvb-adminProfile-UserPermissions-zone2Text' className={Classes.accessBoxTitle}>ZONE 2</div>
                        <div id='cvb-adminProfile-UserPermissions-zone2AccessText' className={Classes.accessBoxSubtitle}>Controlled Access</div>
                        <div className={Classes.accessStatus}>
                            <img id='cvb-adminProfile-UserPermissions-zone2AccessIcon' className={Classes.accessIcon} src={props.data?.permissions?.zone2_access ? tick : disabled} />
                            <span id='cvb-adminProfile-UserPermissions-zone2AccessIconText' className={Classes.accessIconText}>{props.data?.permissions?.zone2_access ? 'Authorized' : 'Unauthorized'}</span>
                        </div>
                    </div>
                    <div className={Classes.accessBox}>
                        <div id='cvb-adminProfile-UserPermissions-zone3Text' className={Classes.accessBoxTitle}>ZONE 3</div>
                        <div id='cvb-adminProfile-UserPermissions-zone3AccessText' className={Classes.accessBoxSubtitle}>Restricted Access</div>
                        <div className={Classes.accessStatus}>
                            <img id='cvb-adminProfile-UserPermissions-zone3AccessIcon' className={Classes.accessIcon} src={props.data?.permissions?.zone3_access ? tick : disabled} />
                            <span id='cvb-adminProfile-UserPermissions-zone3AccessIconText' className={Classes.accessIconText}>{props.data?.permissions?.zone3_access ? 'Authorized' : 'Unauthorized'}</span>
                        </div>
                    </div>
                    <div className={[Classes.accessBox, Classes.workspaceBox].join(' ')}>
                        <div id='cvb-adminProfile-UserPermissions-advanceComputeText' className={Classes.accessBoxTitle}>Advanced Compute</div>
                        <div className={Classes.accessStatus}>
                            <img id='cvb-adminProfile-UserPermissions-advanceComputeAccessIcon' className={Classes.accessIcon} src={props.data?.permissions?.workspace_access ? tick : disabled} />
                            <span id='cvb-adminProfile-UserPermissions-advanceComputeIconText' className={Classes.accessIconText}>{props.data?.permissions?.workspace_access ? 'Authorized' : 'Unauthorized'}</span>
                        </div>
                    </div>
                </div>

                <div className={[classes.timeLine, Classes.timelineSection].join(' ')}>
                    <Grid container>
                        <Grid item xs={12}>
                            <h2 id='cvb-adminProfile-UserPermissions-docSubmissionStatusHeading' className={classes.timeLineHeader}>Document submission status</h2>
                        </Grid>
                    </Grid>
                    <div className={classes.timeLineBlock}>
                        {
                            map(workspaceRequests, (workspaceRequest) => {
                                return (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className={classes.timeLineBlockUserTimeline}>
                                                <div className={classes.timeLineBlockUserTimelineHeader}>
                                                    <div className={classes.timeLineBlockUserTimelineLeft}>
                                                        <h4 id='cvb-adminProfile-UserPermissions-requestAdvanceComputeHeading'>Requested Advanced Compute</h4>
                                                        <p id='cvb-adminProfile-UserPermissions-workspaceRequestTimestamp'>
                                                            Submitted on {workspaceRequest?.dayStr} at {workspaceRequest?.timeStr}
                                                            {
                                                                workspaceRequest?.expiry_date ?

                                                                    <span> | <span style={{ color: 'red', fontWeight: 'bold' }}>Expiry Date: {workspaceRequest.expiry_date}</span></span> : null
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className={classes.timeLineBlockUserTimelineRight}>
                                                        <ul>
                                                            <li>
                                                                <p id='cvb-adminProfile-UserPermissions-workspaceRequestStatusText' className={classes.pendingApproval}>
                                                                    {workspaceRequest?.request_status}
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className={classes.timeLineBlockUserTimelines}>
                                                    <div className={classes.formStepperHorizontal}>
                                                        <div className={classes.formStep}>
                                                            <div className={classes.formStepCircle}>
                                                                <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                                                            </div>
                                                            <div id='cvb-adminProfile-serviceAgreement-heading' className={classes.formStepTitle}>Proposal</div>
                                                            <div className={classes.formStepOptional}>
                                                                <p id='cvb-adminProfile-serviceAgreement-submittedText' className={classes.submitText}>Submitted</p>
                                                            </div>
                                                            <div className={classes.viewButtonWrapper}>
                                                                <div className={classes.viewDownloadIconSA}>
                                                                    <img id='cvb-adminProfile-viewServiceAgreementModal-button' alt='view' onClick={() => onOpenServiceAgreementModalClick(workspaceRequest)} src={ViewBlueIcon} />
                                                                </div>
                                                            </div>
                                                            <div className={classes.formStepBarLeft}></div>
                                                            <div className={classes.formStepBarRight}></div>
                                                        </div>
                                                        {
                                                            workspaceRequest?.service_agreement_file_name ?
                                                                <div className={classes.formStep}>
                                                                    <div className={classes.formStepCircle}>
                                                                        <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                                                                    </div>
                                                                    <div id='cvb-adminProfile-certificates-heading' className={classes.formStepTitle}>Advanced Compute Request</div>
                                                                    <div className={classes.formStepOptional}>
                                                                        <p id='cvb-adminProfile-certificatesSubmitted-text' className={classes.submitText}>Submitted</p>
                                                                    </div>
                                                                    <div className={classes.viewButtonWrapper}>
                                                                        <div className={classes.viewDownloadIconSA}>
                                                                            <img id='cvb-adminProfile-downloadCertificates-button' alt='download' onClick={() => onDownloadServiceAgreement(workspaceRequest)} src={DownloadBlueIcon} />
                                                                        </div>
                                                                    </div>
                                                                    <div className={classes.formStepBarLeft}></div>
                                                                    <div className={classes.formStepBarRight}></div>
                                                                </div> : null}
                                                    </div>

                                                </div>
                                            </div>

                                        </Grid>
                                    </Grid>)
                            })}
                        {
                            !isEmpty(zone2DataAccess) ?

                                map(zone2DataAccess, (request) => {
                                    let dayStr = '';
                                    let timeStr = '';
                                    const requestSubmittedDate = get(request, 'request_submitted_time', '');
                                    const reqDateMomentObj = moment(requestSubmittedDate);

                                    if (moment.isMoment(reqDateMomentObj)) {
                                        dayStr = reqDateMomentObj.format('MM/DD/YYYY');
                                        timeStr = reqDateMomentObj.format('hh:mm A');
                                    }

                                    return (
                                        <Grid container className={classes.agreementTimeline}>
                                            <Grid item xs={12}>
                                                <div className={classes.timeLineBlockUserTimeline}>
                                                    <div className={classes.timeLineBlockUserTimelineHeader}>
                                                        <div className={classes.timeLineBlockUserTimelineLeft}>
                                                            <h4 id='cvb-adminProfile-UserPermissions-reqZone2AccessHeading'>Requested Zone 2 Access</h4>
                                                            <p id='cvb-adminProfile-UserPermissions-reqZone2AccessSubmitted'>
                                                                Submitted on {dayStr} at {timeStr}
                                                                {
                                                                    request?.expiry_date ?

                                                                        <span> | <span style={{ color: 'red', fontWeight: 'bold' }}>Expiry Date: {request.expiry_date}</span></span> : null
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className={classes.timeLineBlockUserTimelineRight}>
                                                            <ul>
                                                                <li className={classes.workspaceAccess}>Zone 2 Access</li>
                                                                {
                                                                    request?.request_status?.toLowerCase() === 'approved' ?
                                                                        <li className={classes.unAuthorizedAccess}>
                                                                            <img id='cvb-adminProfile-UserPermissions-reqZone2AccessAuthorizeIcon' alt='authorize' className={Classes.accessIconTimeline} src={tick} />
                                                                            Authorized
                                                                        </li>
                                                                        :
                                                                        <li className={classes.unAuthorizedAccess}>
                                                                            <img id='cvb-adminProfile-UserPermissions-reqZone2AccessUnauthorizeIcon' alt='unauthorize' className={Classes.accessIconTimeline} src={disabled} />
                                                                            Unauthorized
                                                                        </li>
                                                                }
                                                                <li>
                                                                    <p id='cvb-adminProfile-UserPermissions-reqZone2RequestStatus' className={classes.pendingApproval}>
                                                                        {request?.request_status}
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className={classes.timeLineBlockUserTimelines} >
                                                        <div className={classes.formStepperHorizontal}>
                                                            <div className={classes.formStep}>
                                                                <div className={classes.formStepCircle}>
                                                                    <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                                                                </div>
                                                                <div id='cvb-adminProfile-UserPermissions-signedDUPHeading' className={classes.formStepTitle}>Signed Data Use Agreement</div>
                                                                <div className={classes.formStepOptional}>
                                                                    <p id='cvb-adminProfile-UserPermissions-signedDUPSubmittedText' className={classes.submitText}>Submitted</p>
                                                                </div>
                                                                <div className={classes.viewButtonWrapper}>
                                                                    <div className={classes.viewDownloadIcon}>
                                                                        <img id='cvb-adminProfile-UserPermissions-viewDUPModalImage' alt='view' onClick={()=>onOpenDataAccessModalClick(request)} src={ViewBlueIcon} />
                                                                        <img id='cvb-adminProfile-UserPermissions-downloadDUPModalImage' alt='download' onClick={() => onDocumentDownload('data_use_policy')} src={DownloadBlueIcon} />
                                                                    </div>
                                                                </div>
                                                                <React.Fragment> <div className={classes.formStepBarLeft}></div>
                                                                    <div className={classes.formStepBarRight}></div>
                                                                </React.Fragment>
                                                            </div>
                                                            <div className={classes.formStep}>
                                                                <div className={classes.formStepCircle}>
                                                                    <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                                                                </div>
                                                                <div id='cvb-adminProfile-UserPermissions-instCertHeading' className={classes.formStepTitle}>Institutional Certificate</div>
                                                                <div className={classes.formStepOptional}>
                                                                    <p id='cvb-adminProfile-UserPermissions-instCertSubmittedText' className={classes.submitText}>Submitted</p>
                                                                </div>
                                                                <div className={classes.viewButtonWrapper}>
                                                                    <div className={classes.viewDownloadIcon}>
                                                                        <img id='cvb-adminProfile-UserPermissions-viewInstCert' alt='view' onClick={() => getInstitutionCertificateData(request)} src={ViewBlueIcon} />
                                                                        <img id='cvb-adminProfile-UserPermissions-downloadInstCert' alt='download' onClick={() => onDocumentDownload('data_use_policy')} src={DownloadBlueIcon} />
                                                                    </div>
                                                                </div>
                                                                <div className={classes.formStepBarLeft}></div>
                                                                <div className={classes.formStepBarRight}></div>
                                                            </div>
                                                            <div className={classes.formStep}>
                                                                <div className={classes.formStepCircle}>
                                                                    <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                                                                </div>
                                                                <div className={classes.formStepTitle}>Certificates</div>
                                                                <div className={classes.formStepOptional}>
                                                                    <p className={classes.submitText}>Submitted</p>
                                                                </div>
                                                                <div className={classes.viewButtonWrapper}>
                                                                    <div className={classes.viewDownloadIcon}>
                                                                        <img id='cvb-adminProfile-UserPermissions-viewCertImage' alt='view' onClick={goBack} src={ViewBlueIcon} />
                                                                        <img id='cvb-adminProfile-UserPermissions-downloadCertImage' alt='download' onClick={() => onDownloadCertificates()} src={DownloadBlueIcon} />
                                                                    </div>
                                                                </div>
                                                                <div className={classes.formStepBarLeft}></div>
                                                                <div className={classes.formStepBarRight}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Grid>
                                        </Grid>
                                    )
                                })

                                : null
                        }

                        {
                            zone3Requests?.length ?
                                map(zone3Requests, (requestData) => {
                                    let dayStr = '';
                                    let timeStr = '';

                                    const requestSubmittedDate = get(requestData, 'request_submitted_time', '');
                                    const reqDateMomentObj = moment(requestSubmittedDate);

                                    if (moment.isMoment(reqDateMomentObj)) {
                                        dayStr = reqDateMomentObj.format('MM/DD/YYYY');
                                        timeStr = reqDateMomentObj.format('hh:mm A');
                                    }


                                    const requestStatus = get(requestData, 'request_status', '');
                                    const requestStatusLabel = requestStatus.replaceAll("_", " ");
                                    const projectId = get(requestData, 'project_id', '');
                                    return <Grid container key={v4()} className={classes.requestContainer}>

                                        <Grid item xs={12}>
                                            <div className={classes.timeLineBlockUserTimeline}>
                                                <div className={classes.timeLineBlockUserTimelineHeader}>
                                                    <div className={classes.timeLineBlockUserTimelineLeft}>
                                                        <h4 id='cvb-adminDashboard-zone3Requests-reqZone3Access-heading'>Requested Zone 3 Access <span style={{ textTransform: 'none' }}>for {projectId}</span></h4>
                                                        <p id='cvb-adminDashboard-zone3Requests-submittedTime'>
                                                            Submitted on {dayStr} at {timeStr}

                                                            {
                                                                requestData?.expiry_date ?

                                                                    <span> | <span style={{ color: 'red', fontWeight: 'bold' }}>Expiry Date: {requestData.expiry_date}</span></span> : null
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className={classes.timeLineBlockUserTimelineRight}>
                                                        <ul>
                                                            <li className={classes.workspaceAccess}>Zone 3 Access</li>
                                                            {
                                                                requestData?.request_status?.toLowerCase() === 'approved' ?
                                                                    <li className={classes.unAuthorizedAccess}>
                                                                        <img id='cvb-adminProfile-UserPermissions-reqZone3AccessAuthorizeIcon' alt='authorize' className={Classes.accessIconTimeline} src={tick} />
                                                                        Authorized
                                                                    </li>
                                                                    :
                                                                    <li className={classes.unAuthorizedAccess}>
                                                                        <img id='cvb-adminProfile-UserPermissions-reqZone3AccessUnauthorizeIcon' alt='unauthorize' className={Classes.accessIconTimeline} src={disabled} />
                                                                        Unauthorized
                                                                    </li>
                                                            }
                                                            <li>
                                                                <p id='cvb-adminProfile-UserPermissions-reqZone3RequestStatus' className={classes.pendingApproval}>
                                                                    {requestStatusLabel}
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </div>
                                                <div className={classes.timeLineBlockUserTimelines}>
                                                    <div className={classes.formStepperHorizontal}>
                                                        <div className={classes.formStep}>
                                                            <div className={classes.formStepCircle}>
                                                                <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                                                            </div>
                                                            <div id='cvb-adminDashboard-dup-heading' className={classes.formStepTitle}>Data Use Agreement</div>
                                                            <div className={classes.formStepOptional}>
                                                                <p id='cvb-adminDashboard-dup-submittedText' className={classes.submitText}>Submitted</p>
                                                            </div>
                                                            <div className={classes.viewButtonWrapper}>
                                                                <div className={classes.viewDownloadIcon}>
                                                                    <img id='cvb-adminProfile-UserPermissions-viewDUPModalImage' alt='view' onClick={onOpenDataAccessModalClick} src={ViewBlueIcon} />
                                                                    <img id='cvb-adminProfile-UserPermissions-downloadDUPModalImage' alt='download' onClick={() => onDocumentDownload('data_use_policy')} src={DownloadBlueIcon} />
                                                                </div>
                                                            </div>
                                                            <React.Fragment>
                                                                <div className={classes.formStepBarLeft}></div>
                                                                <div className={classes.formStepBarRight}></div>
                                                            </React.Fragment>

                                                        </div>
                                                        <div className={classes.formStep}>
                                                            <div className={classes.formStepCircle}>
                                                                <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                                                            </div>
                                                            <div id='cvb-adminDashboard-instCert-heading' className={classes.formStepTitle}>Institutional Certificates</div>
                                                            <div className={classes.formStepOptional}>
                                                                <p id='cvb-adminDashboard-instCert-submittedText' className={classes.submitText}>Submitted</p>
                                                            </div>
                                                            <div className={classes.viewButtonWrapper}>
                                                                <div className={classes.viewDownloadIcon}>
                                                                    <img id='cvb-adminDashboard-viewInstCert-button' alt='view' onClick={() => getInstitutionCertificateData(requestData)} src={ViewBlueIcon} />
                                                                    <img id='cvb-adminDashboard-downloadInstCert-button' alt='download' onClick={() => onDocumentDownload('data_use_policy')} src={DownloadBlueIcon} />
                                                                </div>
                                                            </div>
                                                            <div className={classes.formStepBarLeft}></div>
                                                            <div className={classes.formStepBarRight}></div>
                                                        </div>
                                                        <div className={classes.formStep}>
                                                            <div className={classes.formStepCircle}>
                                                                <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                                                            </div>
                                                            <div id='cvb-adminDashboard-certificates-heading' className={classes.formStepTitle}>Certificates</div>
                                                            <div className={classes.formStepOptional}>
                                                                <p id='cvb-adminDashboard-certificatesSubmitted-text' className={classes.submitText}>Submitted</p>
                                                            </div>
                                                            <div className={classes.viewButtonWrapper}>
                                                                <div className={classes.viewDownloadIcon}>
                                                                    <img id='cvb-adminDashboard-viewCertificatesButton' alt='view' onClick={goBack} src={ViewBlueIcon} />
                                                                    <img id='cvb-adminDashboard-downloadCertificates-button' alt='download' onClick={() => onDownloadCertificates()} src={DownloadBlueIcon} />
                                                                </div>
                                                            </div>
                                                            <div className={classes.formStepBarLeft}></div>
                                                            <div className={classes.formStepBarRight}></div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </Grid>
                                    </Grid>
                                })
                                : null
                        }

                        <Grid container className={classes.agreementTimeline}>
                            <Grid item xs={12}>
                                <div className={classes.timeLineBlockUserTimeline}>
                                    <div className={classes.timeLineBlockUserTimelineHeader}>
                                        <div className={classes.timeLineBlockUserTimelineLeft}>
                                            <h4 id='cvb-adminProfile-UserPermissions-signedTOUAndPPHeading'>Signed Terms Of Use & Privacy Notice</h4>
                                            <p id='cvb-adminProfile-UserPermissions-signedTOUAndPPSubmittedText'> Submitted on {privacyPolicyDate} {privacyPolicyTime ? `at ${privacyPolicyTime}` : null}</p>
                                        </div>


                                        <div className={classes.timeLineBlockUserTimelineRight}>
                                            <ul>
                                                <li id='cvb-adminProfile-UserPermissions-zone1AccessHeading' className={classes.workspaceAccess}>Zone 1 Access</li>

                                                <li className={classes.unAuthorizedAccess}>
                                                    <img id='cvb-adminProfile-UserPermissions-zone1AuthorizedIcon' alt='auth' className={Classes.accessIconTimeline} src={tick} />
                                                    Authorized
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={classes.timeLineBlockUserTimelines} >
                                        <div className={classes.formStepperHorizontal}>
                                            <div className={classes.formStep}>
                                                <div className={classes.formStepCircle}>
                                                    <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                                                </div>
                                                <div id='cvb-adminProfile-UserPermissions-signedTOUHeading' className={classes.formStepTitle}>Signed Terms Of Use</div>
                                                <div className={classes.formStepOptional}>
                                                    <p id='cvb-adminProfile-UserPermissions-signedTOUSubmittedText' className={classes.submitText}>Submitted</p>
                                                </div>
                                                <div className={classes.viewButtonWrapper}>
                                                    <div className={classes.viewDownloadIcon}>
                                                        <img id='cvb-adminProfile-UserPermissions-viewTOUIcon' alt='view' onClick={onOpenTermsOfUseModalClick} src={ViewBlueIcon} />
                                                        <img id='cvb-adminProfile-UserPermissions-downloadTOUIcon' alt='download' onClick={() => onDocumentDownload('terms_of_use')} src={DownloadBlueIcon} />
                                                    </div>
                                                </div>
                                                <div className={classes.formStepBarLeft}></div>
                                                <div className={classes.formStepBarRight}></div>
                                            </div>
                                            <div className={classes.formStep}>
                                                <div className={classes.formStepCircle}>
                                                    <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                                                </div>
                                                <div id='cvb-adminProfile-UserPermissions-signedPPHeading' className={classes.formStepTitle}>Signed Privacy Notice</div>
                                                <div className={classes.formStepOptional}>
                                                    <p id='cvb-adminProfile-UserPermissions-signedPPSubmittedText' className={classes.submitText}>Submitted</p>
                                                </div>
                                                <div className={classes.viewButtonWrapper}>
                                                    <div className={classes.viewDownloadIcon}>
                                                        <img id='cvb-adminProfile-UserPermissions-viewPPIcon' alt='view' onClick={onOpenPrivacyPolicyModalClick} src={ViewBlueIcon} />
                                                        <img id='cvb-adminProfile-UserPermissions-downloadPPIcon' alt='download' onClick={() => onDocumentDownload('privacy_notice')} src={DownloadBlueIcon} />
                                                    </div>
                                                </div>
                                                <div className={classes.formStepBarLeft}></div>
                                                <div className={classes.formStepBarRight}></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Grid></div>
    )
}

export default UserPermissions
