import { useMemo } from "react";

import {
    CONSTELLATION_GRAPH_NODE_TYPE,
    GRAPH_LABEL_STATIC_DISTANCE,
    DEFAULT_GRAPH_LINK_DISTANCE
} from "../../../../constants/constellation";

const PARENT_NODE_RATIO = 10;
const TABS_PLACEHOLDER_SIZE = 80;
const COMMON_USER_MAX_IMAGE_SIZE = 80;

function getParentNodeSize(parentWidth, parentHeight) {
    return (
        (Math.min(parentWidth / 2, parentHeight / 2) / 100) * PARENT_NODE_RATIO
    );
}

function getConnectionSize(weight) {
    const radius = 2;
    const currentSize = (COMMON_USER_MAX_IMAGE_SIZE * weight) / radius;
    return currentSize < 10 ? 10 : currentSize;
}

function getNodeParams(index, nodeType, size, data) {
    return {
        id: index + 1,
        nodeType: nodeType,
        size: size,
        metadata: data,
    };
}

function getConnectionDistance(weight) {
    return (1 - weight) * 100;
}

function getDirectConnectionDistance(weight) {
    const maxRadialRatio = 0.02;
    return weight !== 0
        ? (1 - weight) * 100
        : (1 - weight - maxRadialRatio) * 100;
}

function getLabelWeight(children) {
    const getChildrenMediumWeight = children.reduce((acc, child) => {
        acc = acc + child.weight;
        return acc;
    }, 0);
    return getChildrenMediumWeight / children.length;
}

function getTabPlaceholderNode(index, parentWidth) {
    return {
        ...getNodeParams(index, CONSTELLATION_GRAPH_NODE_TYPE.TABS, TABS_PLACEHOLDER_SIZE, {}),
        fx: parentWidth / 2,
        fy: 0,
        x: parentWidth / 2,
        y: 0,
        isLabelChild: false,
        distance: 0,
    };
}

function formGraphUserConnections(connectionsList) {
    const labelConnectionsMap = new Map();
    const directConnectionsSet = new Set();
    if (connectionsList.length !== 0) {
        connectionsList.forEach((recommendedUser) => {
            if (recommendedUser.connectionLinks.length !== 0) {
                recommendedUser.connectionLinks.forEach((option) => {
                    if (labelConnectionsMap.has(option)) {
                        labelConnectionsMap.set(option, [
                            ...labelConnectionsMap.get(option),
                            recommendedUser,
                        ]);
                    } else {
                        labelConnectionsMap.set(option, [recommendedUser]);
                    }
                });
            } else {
                directConnectionsSet.add({
                    nodeType: CONSTELLATION_GRAPH_NODE_TYPE.COMMON_USER,
                    data: recommendedUser,
                    children: [],
                    weight: recommendedUser.weight,
                });
            }
        });
    }

    return { labelConnectionsMap, directConnectionsSet };
}

function  createGraphDataTree(mainNode, labelsList, connectionsList) {
    const { labelConnectionsMap, directConnectionsSet } =
        formGraphUserConnections(connectionsList);

    const directConnectionsList =
        directConnectionsSet.size !== 0 ? [...directConnectionsSet] : [];

    const labelConnectionsList =
        labelsList.length !== 0
            ? labelsList.reduce((acc, labelItem) => {
                  const basicLabelNode = {
                      nodeType: CONSTELLATION_GRAPH_NODE_TYPE.LABEL,
                      data: labelItem,
                      children: [],
                  };
                  if (
                      labelConnectionsMap.size !== 0 &&
                      labelConnectionsMap.has(labelItem.value)
                  ) {
                      const labelConnections = labelConnectionsMap.get(
                          labelItem.value
                      );
                      labelConnections.forEach((connection) => {
                          basicLabelNode.children.push({
                              nodeType:
                                  CONSTELLATION_GRAPH_NODE_TYPE.COMMON_USER,
                              data: connection,
                              children: [],
                              weight: connection.weight !== 1 ? connection.weight : connection.weight - DEFAULT_GRAPH_LINK_DISTANCE / 100,
                          });
                      });
                  }
                  const labelWeight = basicLabelNode.children.length
                      ? getLabelWeight(basicLabelNode.children)
                      : GRAPH_LABEL_STATIC_DISTANCE;

                  acc.push({ ...basicLabelNode, weight: labelWeight });
                  return acc;
              }, [])
            : [];

    return {
        nodeType: CONSTELLATION_GRAPH_NODE_TYPE.CURRENT_USER,
        data: mainNode,
        children: [...directConnectionsList, ...labelConnectionsList].sort(
            (a, b) => {
                return b.children.length - a.children.length;
            }
        ),
    };
}

function setGraphData(
    dataTree,
    parentWidth,
    parentHeight,
    isSelectionEnabled,
    graphUsersType
) {
    const linkedNodeMap = new Map();
    const nodes = [];
    const links = [];

    const parentNodeSize = getParentNodeSize(parentWidth, parentHeight);

    nodes.push({
        ...getNodeParams(0, dataTree.nodeType, parentNodeSize, dataTree.data),
        fx: parentWidth / 2,
        fy: parentHeight / 2,
        x: parentWidth / 2,
        y: parentHeight / 2,
        isLabelChild: false,
        distance: 0,
        size: parentNodeSize
    });

    if (dataTree.children.length === 0) {
        links.push({
            source: nodes[0],
            target: nodes[0],
        });
    } else {
        let counter = 0;
        dataTree.children.forEach((firstLevelItem) => {
            const directConnectionDistance = getDirectConnectionDistance(
                firstLevelItem.weight
            );
            counter = nodes.length;
            nodes.push({
                ...getNodeParams(
                    counter,
                    firstLevelItem.nodeType,
                    firstLevelItem.nodeType ===
                        CONSTELLATION_GRAPH_NODE_TYPE.LABEL
                        ? 0
                        : getConnectionSize(firstLevelItem.weight),
                    firstLevelItem.data
                ),
                isSelectable: isSelectionEnabled,
                distance: directConnectionDistance,
                graphUsersType: graphUsersType,
                isLabelChild: false,
            });
            links.push({
                source: nodes[0],
                target: nodes[nodes.length - 1],
                isSelectable: isSelectionEnabled,
                distance: directConnectionDistance,
                isLabelChild: false,
                parentDistance: directConnectionDistance,
            });
            counter++;
            if (firstLevelItem.children.length !== 0) {
                firstLevelItem.children.forEach((secondLevelItem) => {
                    const connectionDistance = getConnectionDistance(
                        secondLevelItem.data.weight
                    );
                    if (!linkedNodeMap.has(secondLevelItem.data.id)) {
                        nodes.push({
                            ...getNodeParams(
                                nodes.length,
                                secondLevelItem.nodeType,
                                secondLevelItem.nodeType ===
                                    CONSTELLATION_GRAPH_NODE_TYPE.LABEL
                                    ? 0
                                    : getConnectionSize(secondLevelItem.weight),
                                secondLevelItem.data
                            ),
                            isSelectable: isSelectionEnabled,
                            distance: connectionDistance,
                            graphUsersType: graphUsersType,
                            isLabelChild: true,
                            parentDistance: directConnectionDistance,
                        });
                        links.push({
                            source: nodes[nodes.length - 1],
                            target: nodes[counter - 1],
                            isSelectable: isSelectionEnabled,
                            distance: connectionDistance,
                            isLabelChild: true,
                            parentDistance: directConnectionDistance,
                        });
                        linkedNodeMap.set(secondLevelItem.data.id, {
                            source: nodes[nodes.length - 1],
                        });
                    } else {
                        const source = linkedNodeMap.get(
                            secondLevelItem.data.id
                        );
                        links.push({
                            ...source,
                            isSelectable: isSelectionEnabled,
                            target: nodes[counter - 1],
                            distance: connectionDistance,
                            isLabelChild: true,
                            parentDistance: directConnectionDistance,
                        });
                    }
                });
            }
        });
    }

    nodes.push(getTabPlaceholderNode(nodes.length, parentWidth));

    return { graphNodes: nodes, graphLinks: links };
}

/**
 * @function useFormGraphData
 *
 * @param {number} parentWidth parent container width
 * @param {number} parentHeight parent container height
 * @param {object} currentUser main user on graph
 * @param {object[]} graphLabels array of graph labels
 * @param {object[]} userList array of user connections
 *
 * @return { {graphNodes: object[], graphLinks: object[]} }
 */

const useFormGraphData = (
    parentWidth,
    parentHeight,
    currentUser,
    graphLabels,
    userList,
    isSelectionEnabled,
    graphUsersType
) => {
    const connectionsDataTree = useMemo(() => {
        if (currentUser) {
            return createGraphDataTree(currentUser, graphLabels, userList);
        }

        return null;
    }, [currentUser, graphLabels, userList]);

    return useMemo(() => {
        if (connectionsDataTree) {
            return setGraphData(
                connectionsDataTree,
                parentWidth,
                parentHeight,
                isSelectionEnabled,
                graphUsersType
            );
        }

        return { graphNodes: [], graphLinks: [] };
    }, [
        connectionsDataTree,
        parentWidth,
        parentHeight,
        isSelectionEnabled,
        graphUsersType,
    ]);
};

export { useFormGraphData };
