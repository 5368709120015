import React, { useState } from 'react';
import Classes from './index.module.css'
import { v4 } from 'uuid'
import clsx from 'clsx'
import BackButtonIcon from '../../../assets/icons/left_arrow.png'
import isEmpty from 'lodash/isEmpty'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DotIcon from "../../../assets/icons/MoreOptionsVerticalWhite.svg"
import DotIconDark from "../../../assets/icons/MoreOptionsVertical.S-dark.svg"
import APIKeys from '../../controls/api-keys';
import { Button, Stack, Typography } from "@mui/material";

const ExploreDataHeader = ({ open, sectionHeaderProps, backButtonText = '', onBackButtonClick, isOwnedWorkspacePresent = false }) => {
    const {
        title = "",
        pageDefinition = "",
        defaultActionButtons = [],
        specialActionButtons = [],
        type = "",
        breadcrumbs = null,
    } = sectionHeaderProps || {}

    const [popOverState, setPopOverState] = useState(false)
    const addTopClass = !isEmpty(backButtonText) ? Classes.addTopToSectionHeader : '';
    const PopoverActiveToggle = popOverState ? Classes.popIconActive : Classes.popIcon
    const ToggleIcon = popOverState ? DotIconDark : DotIcon
    const pageDefinitionClass = title === 'Data Model' || title === 'Explore Tools' ? Classes.respPageDefinition : '';

    return (
        <React.Fragment>
            {!isEmpty(backButtonText) &&
                <div className={Classes.backButtonContainer}>
                    <img id='cvb-sectionHeader-backButton-image' src={BackButtonIcon} alt='back button' />
                    <p id='cvb-sectionHeader-backButton-text' className={Classes.backButtonText} onClick={onBackButtonClick}>{backButtonText}</p>
                </div>
            }
            <div className={clsx(Classes.headerContainer, addTopClass, {
                [Classes.headerContainerOpen]: open,
                [Classes.headerContainerClose]: !open,
            })}>
                <div className={Classes.leftBlock}>
                    {breadcrumbs && breadcrumbs}
                    {title || pageDefinition ? 
                        <div className={Classes.headerTitleContainer}>
                            <h1 id='cvb-sectionHeader-title'>{title}</h1> 
                            {!isEmpty(pageDefinition) ? <p className={pageDefinitionClass} id='cvb-sectionHeader-pageDefinition'>{pageDefinition}</p>: null}
                        </div> 
                    : null}

                    {
                        specialActionButtons.length > 0 &&
                        specialActionButtons.map(item => {
                            const { buttonText = "" } = item || {};
                            const { handleOnClick = () => { } } = item;
                            return <button
                                key={v4()}
                                className={`${Classes.viewAllSavedButton}`}
                                onClick={handleOnClick}
                                id={`cvb-sectionHeader-buttons-${buttonText}`}
                            >
                                {buttonText}
                            </button>
                        })
                    }

                </div>
                <div className={Classes.rightBlock}>
                    {
                        (type !== 'myProfileButton' && type !== 'newCohortButtons' && type !== 'cohortExplorer' && defaultActionButtons.length > 0) &&
                        <ul className={Classes.topMenuItems}>
                            {
                                defaultActionButtons.map(item => {
                                    const {
                                        buttonText = "",
                                        buttonImageSource,
                                        customComponent,
                                        handleOnClick = () => { },
                                        isDisabled
                                    } = item || {}
                                    return customComponent ? customComponent : <li key={v4()} className={item?.customClass || "section-items"}
                                        style={isDisabled ? { opacity: 0.5 } : {}}
                                        id={`cvb-sectionHeader-buttons-${buttonText}`}
                                        onClick={isDisabled ? () => { } : handleOnClick}>
                                        <img src={buttonImageSource} alt={buttonText} />
                                        <p>{buttonText}</p>
                                    </li>
                                })
                            }
                        </ul>
                    }
                    {
                        (title === 'Workspace' && isOwnedWorkspacePresent) ?
                            <APIKeys></APIKeys> : null
                    }
                    {
                        (type === 'myProfileButton' && defaultActionButtons.length > 0) &&
                        <div>
                            {
                                popOverState ?
                                    <ClickAwayListener onClickAway={() => setPopOverState(false)}>
                                        <div className={Classes.popOverCard} id='cvb-myProfileMenu-popoverContainer'>
                                            {defaultActionButtons.map(item => {
                                                return <div className={Classes.PopOverContent} id={`cvb-sectionHeader-popOver-${item?.buttonText}`}
                                                    key={v4()} onClick={item?.handleOnClick} >
                                                    <img className={Classes.popIconImage} src={item?.buttonImageSource} alt={item?.buttonText} />
                                                    <p>{item?.buttonText}</p>
                                                </div>

                                            })}
                                        </div>
                                    </ClickAwayListener> : null

                            }
                            <p id='cvb-myProfileMenu-contentToggleButton'
                                onClick={() => setPopOverState(true)} className={PopoverActiveToggle}>
                                <img src={ToggleIcon} alt="dot icon" />
                            </p>
                        </div>

                    }
                    {
                        (type === 'newCohortButtons' || type === 'cohortExplorer' && defaultActionButtons.length > 0) &&
                        <Stack direction='row' alignItems='center' spacing={1.5}>
                                {defaultActionButtons.map(item => {
                                    return (
                                      <Stack direction='row' alignItems='center' spacing={1.5}>
                                        {item.description && <Typography fontSize={14} fontWeight={600}>{item.description}</Typography>}
                                        <Button variant={item?.variant} disabled={item?.disabled} color="inherit" onClick={item?.handleOnClick}>
                                            {item?.imageSrc && <img src={item?.imageSrc} alt="" />}
                                            {item?.buttonText}
                                        </Button>
                                      </Stack>
                                    )
                                })}
                        </Stack>
                    }
                </div>
            </ div >
        </React.Fragment>
    )
}

const areEqual = (prevProps, nextProps) => {
    return prevProps.open === nextProps.open &&
        prevProps.sectionHeaderProps === nextProps.sectionHeaderProps
}

const memorizedComponent = React.memo(ExploreDataHeader, areEqual)
export default memorizedComponent;
