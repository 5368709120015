import React, {useEffect, useState} from 'react';
import * as moment from 'moment';
import { v4 } from 'uuid';

import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';

import ReviewKeyword from '../review-keyword';

import classes from './index.module.css';

import KeywordsDetailCard from '../../commons/keyword-detail-card/KeywordDetailCard';

import hourglass from '../../../assets/icons/hourglass.png'
import rejected from '../../../assets/icons/rejected.png'
import { FormControlLabel } from '@mui/material';





const ReviewApproveKeywords = React.forwardRef((props, ref) => {
	const {
		setTabValueKeyWord,
		tabValueKeyWord,
		keywordsData,
		approveOrRejectKeywords,
		onToggleAllKeywords,
		onEditKeyword,
		onToggleKeyword,
        currentTab,
        setCurrentTab,
        setDashboardTitle,
        setKeywordsData
	} = props;
    const [isChecked, setIsChecked] = useState(false)

    const useStyles = makeStyles(theme => ({
        flexContainer: {
            borderBottom: '2px solid #637BBD',
            background: '#fafafa'
        }
    }))
    
    const Tab = withStyles(theme => ({
        root: {
            minWidth: 50,
            padding: '14px 6px',
            marginRight: 10,
            border: '2px solid #637BBD',
            color: '#637BBD',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            opacity: 1,
            borderBottom: 'none'
        },
        flexContainer: {
            border: '2px solid #637BBD'
        },
        selected: {
            color: "#000000",
            borderBottom: '2px solid #fafafa'
    
        }
    }))(MuiTab);
    const styledClasses = useStyles();

	const handleChangeKeyWords = (event, newValue) => {
		setTabValueKeyWord(newValue);
	};

    useEffect(() => {
        let keywords = []
        if(keywordsData?.pending?.length > 0 && tabValueKeyWord === 0){
            keywords = keywordsData?.pending.map(item => {
                if(item?.isSelected){
                    item.isSelected = false
                }
            })
            setKeywordsData({pending: keywords, ...keywordsData})
        }

        if(keywordsData?.rejected?.length > 0 && tabValueKeyWord === 1){
            keywords = keywordsData?.rejected.map(item => {
                if(item?.isSelected){
                    item.isSelected = false
                }
            })
            setKeywordsData({rejected: keywords, ...keywordsData})
        }


    }, [tabValueKeyWord, currentTab])

    useEffect(() => {
        let isAllPendingChecked;
        let isAllRejectedChecked;

        if(isChecked && keywordsData?.pending?.length > 0){
            keywordsData.pending.map(item => {
                if(!item?.isSelected){
                    setIsChecked(false)
                }
            })
        }

        if(isChecked && keywordsData?.rejected?.length > 0){
            keywordsData.rejected.map(item => {
                if(!item?.isSelected){
                    setIsChecked(false)
                }
            })
            
        }

        if(keywordsData?.pending?.length > 0  && tabValueKeyWord === 0){
            isAllPendingChecked = keywordsData?.pending.every(item => item.isSelected === true)
        } else if(keywordsData?.rejected?.length > 0  && tabValueKeyWord === 1) {
            isAllRejectedChecked = keywordsData?.rejected.every(item => item.isSelected === true)
        }

        if(isAllPendingChecked || isAllRejectedChecked){
            setIsChecked(true)
        }
    }, [keywordsData])
    
	return (
		<div className={classes.mainContainer} ref={ref}>
			{currentTab === null && 
            <Grid container>
				<Grid item xs={8}>
					<p className={classes.title}>Review & Approve Keywords</p>
				</Grid>
				<Grid item xs={4} style={{display: 'flex', justifyContent: 'flex-end'}}>
					{currentTab === null && (
						<div className={classes.zoneTilesDetailsButton}>
							<button
								id="cvb-adminDashboard-zone1ViewDatasetButton"
								className={classes.btn}
								onClick={() => {
									setCurrentTab('review-keywords');
									setDashboardTitle('Review & Approve Keywords');
								}}>
								Review Keywords
							</button>
						</div>
					)}
				</Grid>
			</Grid>}
			<div style={{ position: 'relative' }}>
				{currentTab === null && 
                <Grid container justifyContent="center" spacing={2}>
					<Grid
						item
						lg={6}
						md={6}
						xs={6}
						className={classes.cardWrapper}>
						<KeywordsDetailCard
                            value={keywordsData?.pending?.length || 0}
                            title="Pending Approvals"
                            backgroundColor='#FCF1E0'
                            topBorderColor='#F8B64C'
                            cardIcon={hourglass}
                        />
					</Grid>
					<Grid
						item
						lg={6}
						md={6}
						xs={6}
						className={classes.cardWrapper}>
						<KeywordsDetailCard
                            value={keywordsData?.rejected?.length || 0}
                            title="Rejected Keywords"
                            backgroundColor='#f5d8d8'
                            topBorderColor='#ff0000'
                            cardIcon={rejected}
                        />
					</Grid>
				</Grid>}
                                
				{currentTab === 'review-keywords' && <div className={classes.timeLine}>
                {
                           
                                <div className={classes.timeLine}>
                                    <Grid container>
                                        <Grid item xs={12} className={classes.pendingTextWrapper}>
                                            <h2 id='cvb-adminDashboard-pendingApprovals-heading'
                                                className={classes.timeLineHeader}>
                                                Review & Approve Keywords</h2>

                                        </Grid>
                                        <Grid item xs={12} className={classes.pendingTextWrapper}>
                                            <Paper square className={styledClasses.paper}>
                                                <Tabs
                                                    value={tabValueKeyWord}
                                                    TabIndicatorProps={{
                                                        style: {
                                                            backgroundColor: "rgb(250 250 250)"
                                                        }
                                                    }}
                                                    onChange={handleChangeKeyWords}
                                                    classes={{ flexContainer: styledClasses.flexContainer }}
                                                >

                                                    <Tab id='pendingApprovalTab' label={`Pending Approval (${keywordsData?.pending?.length || 0})`} />
                                                    <Tab id='rejectedKeywordsTab' label={`Rejected (${keywordsData?.rejected?.length || 0})`} />
                                                </Tabs>
                                            </Paper>
                                        </Grid>
                                        
                                        {
                                            tabValueKeyWord === 0 && keywordsData?.pending?.length ?
                                                <>
                                                    <Grid item xs={12}>
                                                        <div className={classes.reviewKeyWordsContainer} >
                                                            <div className={classes.reviewKeyWordsLeftContainer}>
                                                                <FormControlLabel
                                                                checked={isChecked}
                                                                    control={<Checkbox
                                                                        className='checkbox'
                                                                        color="primary"
                                                                        id='cvb-filterCategory-toggleFilterSelectionCheckbox'
                                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                        onChange={(e) => { onToggleAllKeywords(e, 'pending'); }}
                                                                    />}/>
                                                                <span className={classes.KeyWordsSelectAll}>Select All</span>

                                                            </div>
                                                            <div className={classes.reviewKeyWordsRightContainer}>
                                                                <button onClick={() => approveOrRejectKeywords('reject', 'pending')} id='cvb-reviewKeyWord-rejectButton' className={classes.keyWordRejectButton}>Reject</button>
                                                                <button onClick={() => approveOrRejectKeywords('approve', 'pending')} id='cvb-reviewKeyWord-ApproveButton' className={`solid-button ${classes.keyWordApproveButton}`}>Approve</button>

                                                            </div>
                                                        </div>
                                                    </Grid>



                                                    <Grid item xs={12}>
                                                        <div className={classes.keyWordsListView}>
                                                            <ul>
                                                                {
                                                                    keywordsData?.pending.length > 0 && 
                                                                        keywordsData?.pending?.map(x => {

                                                                            let dayStr = '';
                                                                            let timeStr = '';
                                                                            const reqDateMomentObj = moment.unix(x?.create_time);
    
                                                                            if (moment.isMoment(reqDateMomentObj)) {
                                                                                dayStr = reqDateMomentObj.format('MM/DD/YYYY');
                                                                                timeStr = reqDateMomentObj.format('hh:mm A');
                                                                            }
    
                                                                            return <ReviewKeyword
                                                                                key={v4()}
                                                                                user={x?.create_user?.full_name}
                                                                                time={`${dayStr} at ${timeStr}`}
                                                                                label={x?.keyword}
                                                                                type="pending"
                                                                                isSelected={x?.isSelected}
                                                                                onEditKeyword={onEditKeyword}
                                                                                onToggleKeyword={onToggleKeyword}
                                                                            />
                                                                        })
                                                                    
                                                                }
                                                            </ul>
                                                        </div>
                                                    </Grid> </> : tabValueKeyWord === 0 ? <span style={{ marginTop: '15px', marginBottom: '15px' }}>No keywords found</span> : null
                                        }

                                        {
                                            tabValueKeyWord === 1 && keywordsData?.rejected?.length ?
                                                <>
                                                    <Grid item xs={12}>
                                                        <div className={classes.reviewKeyWordsContainer}>
                                                            <div className={classes.reviewKeyWordsLeftContainer}>
                                                            <FormControlLabel
                                                                checked={isChecked}
                                                                    control={<Checkbox
                                                                        className='checkbox'
                                                                        color="primary"
                                                                        id='cvb-filterCategory-toggleFilterSelectionCheckbox'
                                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                        onChange={(e) => { onToggleAllKeywords(e, 'rejected'); }}
                                                                    />}/>
                                                                <span className={classes.KeyWordsSelectAll}>Select All</span>

                                                            </div>
                                                            <div className={classes.reviewKeyWordsRightContainer}>
                                                                <button onClick={() => approveOrRejectKeywords('approve', 'rejected')} id='cvb-reviewKeyWord-ApproveButton' className={`solid-button ${classes.keyWordApproveButton}`}>Approve</button>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>

                                                        <div className={classes.keyWordsListView}>
                                                            <ul>
                                                                {
                                                                    keywordsData?.rejected?.map(x => {
                                                                        let dayStr = '';
                                                                        let timeStr = '';
                                                                        const reqDateMomentObj = moment.unix(x?.create_time);

                                                                        if (moment.isMoment(reqDateMomentObj)) {
                                                                            dayStr = reqDateMomentObj.format('MM/DD/YYYY');
                                                                            timeStr = reqDateMomentObj.format('hh:mm A');
                                                                        }
                                                                        return <ReviewKeyword
                                                                            key={v4()}
                                                                            user={x?.create_user?.full_name}
                                                                            time={`${dayStr} at ${timeStr}`}
                                                                            type="rejected"
                                                                            label={x?.keyword}
                                                                            isSelected={x?.isSelected}
                                                                            onEditKeyword={onEditKeyword}
                                                                            onToggleKeyword={onToggleKeyword}
                                                                        />
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>

                                                    </Grid> </> : tabValueKeyWord === 1 ? <span style={{ marginTop: '15px', marginBottom: '15px' }}>No keywords found</span> : null
                                        }


                                    </Grid>
                                </div>
                        }
				</div>}
			</div>
		</div>
	);
})

ReviewApproveKeywords.displayName = ReviewApproveKeywords

export default ReviewApproveKeywords;
