import React from 'react';
import BiotechIcon from '@mui/icons-material/Biotech';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import MedicationIcon from '@mui/icons-material/Medication';
import DatasetIcon from '@mui/icons-material/Dataset';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

export const schema = [
  {
    title: 'Project',
    icon: <DatasetIcon />,
    props: [
      {
        title: 'Project ID',
        queryVariable: 'project_id',
      },
    ],
    subcategory: [],
  },
  {
    title: 'Person Characteristics',
    icon: <PersonSearchIcon />,
    props: [
      {
        title: 'Age at Enrollment',
        queryVariable: 'age_at_enrollment',
        type: 'slider',
      },
      {
        title: 'Ethnicity',
        queryVariable: 'ethnicity',
      },
      {
        title: 'Gender',
        queryVariable: 'gender',
      },
      {
        title: 'Handedness',
        queryVariable: 'handedness',
      },
      {
        title: 'Race',
        queryVariable: 'race',
      },
      {
        title: 'Years of Education',
        queryVariable: 'years_of_education',
        type: 'slider',
      },
    ],
    subcategory: [],
  },
  {
    title: 'Conditions',
    icon: <AssignmentIcon />,
    props: [
      {
        title: 'Primary Diagnosis',
        queryVariable: 'primary_diagnosis',
      },
      {
        title: 'Neurological Condition',
        queryVariable: 'neurological_condition',
      },
      {
        title: 'Psychological Condition',
        queryVariable: 'psychological_condition',
      },
      {
        title: 'Other Condition',
        queryVariable: 'comorbidity',
      },
    ],
    subcategory: [],
  },
  {
    title: 'Observation',
    icon: <MedicalServicesIcon />,
    props: [
      {
        title: 'Adverse Event',
        queryVariable: 'ae_name',
      },
      {
        title: 'Assessments',
        queryVariable: 'assessments',
      },
    ],
    subcategory: [],
  },
  {
    title: 'Medications',
    icon: <MedicationIcon />,
    key: 'drug',
    props: [
      {
        title: 'Drug',
        queryVariable: 'medication_name',
      },
    ],
    subcategory: [],
  },
  {
    title: 'Measurements',
    icon: <BiotechIcon />,
    props: [
      {
        title: 'Measurement',
        queryVariable: 'measurement',
      },
    ],
    subcategory: [],
  },
  {
    title: 'Imaging',
    icon: <PhotoCamera />,
    props: [
      {
        title: 'Imaging Modality',
        queryVariable: 'imaging_modality',
      },
    ],
    subcategory: [],
  },
];




export const allSchemaDict = {};


schema.forEach((category) => {
  category.props.forEach((prop) => {
    allSchemaDict[prop.queryVariable] = prop;
  });
});


export const allSchema = Object.values(allSchemaDict);