import React, { useState, useEffect } from 'react'
import SectionHeader from '../../commons/section-header'
import { navigate } from '@reach/router'
import classes from './index.module.css'
import Modal from '../../commons/modal'
import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Grid'
import DropDown from '../../commons/dropdown';
import InputAdornment from '@mui/material/InputAdornment';
import moment from 'moment';
import { getUserTransactions, getUserBalance, addUserCredit, updateCreditAmount } from '../../../api/account-api'
import { COUNTRY_LIST } from '../../../constants/strings'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ErrorText from '../../controls/error-text'
import { validateEmailAddress } from '../../../utils/input-validations'
import { filter, isEmpty } from 'lodash'
import { useLocation } from "@reach/router"
import TransactionTable from '../../controls/transaction-table'

const sectionHeaderProps = {
    title: "My Account",
    defaultActionButtons: []
}

const MyAccount = (props) => {
    const [OpenAddCreditsModal, setOpenAddCreditsModal] = useState(false);
    const [openAdjustCreditsModal, setOpenAdjustCreditsModal] = useState(false);
    const [adjustCreditAmount, setAdjustCreditAmount] = useState('');
    const [adjustReasonText, setAdjustReasonText] = useState('');
    const [amountValue, setAmountValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [name, setNameValue] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [country, setCountry] = useState('');
    const [sendRequestButtonClick, setSendRequestButtonClick] = useState(false);
    const [transactionData, setTransactionData] = useState([]);
    const [userAccountBalance, setUserAccountBalance] = useState('');
    const userSub = props?.currentUserDetails?.attributes?.sub
    const isStreetAddressValid = () => !!streetAddress;
    const isCityValid = () => !!city;
    const isStateValid = () => !!state;
    const isZipCodeValid = () => !!zipCode;
    const isCountryValid = () => !!country;
    const isNameValid = () => !!name;
    const isAmountValid = () => !!Number(amountValue);
    const isAdjustAmountValid = () => !!Number(adjustCreditAmount);
    const isAdjustReasonTextValid = () => !!adjustReasonText;

    const { search } = useLocation();


    const getQueryParam = (param) => {
        try {
          const urlParams = new URLSearchParams(search);
          return urlParams?.get(param)
        }
        catch (error) {
          return undefined;
        }
      }
    
      const queryUserName = getQueryParam('username');

    const getCurrentUserAccountBalance = async () => {
        try {
            if (!isEmpty(userSub)  && !queryUserName) {
                const res = await getUserBalance(userSub);
                setUserAccountBalance(res?.account_balance)
            }else{
                if(!isEmpty(queryUserName)){
                    const res = await getUserBalance(queryUserName);
                    setUserAccountBalance(res?.account_balance)
                }
            }
        } catch (e) {
            setUserAccountBalance('-')
            console.log(e);
        }
    }

    const getAllTransactionsData = async () => {
        try {
            if (!isEmpty(userSub) && !queryUserName ) {
                //API CALL 
                const res = await getUserTransactions(userSub);
                setTransactionData(res);
            }else{
                if(!isEmpty(queryUserName)){
                    const res = await getUserTransactions(queryUserName);
                    setTransactionData(res);
                }
            }
        } catch (e) {
            setTransactionData([]);
            console.log(e);
        }
    
    }
    
    useEffect(() => {
        //API CALL TO GET ALL TRANSACTIONS
        getAllTransactionsData();
    }, [userSub, queryUserName])

    useEffect(() => {
        getCurrentUserAccountBalance();
    }, [ userSub, queryUserName])

    const onAddCreditsButtonClick = () => {
        setOpenAddCreditsModal(true);
        setSendRequestButtonClick(false);
    }
    const resetAddCreditsField = () =>{
        setAmountValue('');
        setNameValue('');
        setEmailValue('');
        setCountry('');
        setStreetAddress('');
        setZipCode('');
        setCity('');
        setState('')
    }
    const onSendRequestClick = async () => {
        try {//API CALL TO SEND REQUEST
            setSendRequestButtonClick(true);
            if (isStreetAddressValid() && isCityValid() && isStateValid() && isCountryValid() && isZipCodeValid() && isNameValid() && isAmountValid()
                && validateEmailAddress(emailValue)
            ) {
                const body = {
                    "amount": amountValue,
                    "entity_id": queryUserName ? queryUserName : userSub,
                    "invoice_type": 'CREDIT',
                    "entity_type": "USER_ACCOUNT",
                    "billing_information": {
                        "name": name,
                        "email": emailValue,
                        "zip_code": zipCode,
                        "street_address": streetAddress,
                        "country": country,
                        "state": state,
                        "city": city,
                    }
                }
                //API CALL
                const res = await addUserCredit(body);
                if (res) {
                    props.updateSnackBar(res?.message || "Request sent successfully", 'success')
                    resetAddCreditsField();
                    closeAddCreditModal();
                }
            }
        } catch (e) {
            props.updateSnackBar(e?.response?.data?.message || "Error is sending requesting", 'error')
            console.log(e)
        }
    }
    const resetAdjustCreditForm = ()=>{
        setAdjustCreditAmount('');
        setAdjustReasonText('');
    }
    const onSendAdjustRequestClick = async () =>{
        try{
            setSendRequestButtonClick(true);
            if (isAdjustAmountValid() && isAdjustReasonTextValid()){
                const body = {
                        "amount": adjustCreditAmount,
                        "entity_id": queryUserName,
                        "invoice_type": "ADJUSTMENT",
                        "entity_type": "USER_ACCOUNT",
                        "comments": adjustReasonText
                    }
                const res = await updateCreditAmount(body);
                if(res){
                    props.updateSnackBar(res?.message || "Request sent successfully", 'success')
                    resetAdjustCreditForm();
                    closeAdjustCreditModal();
                    getAllTransactionsData();
                    getCurrentUserAccountBalance();
                }else{
                    props.updateSnackBar(res?.message || "Error is sending adjust credit request", 'error')

                }
            }
        }catch(e){
            props.updateSnackBar(e?.response?.data?.message || "Error is sending adjust credit request", 'error')
            console.log(e)
        }
    }
    const closeAddCreditModal = () => {
        setOpenAddCreditsModal(false);
        setSendRequestButtonClick(false);
    }
    const onAmountValueChange = (e) => {
        setAmountValue(e.target.value);
    }
    const onEmailChange = e => {
        setEmailValue(e.target.value);
    }
    const onNameChange = e => {
        setNameValue(e.target.value);
    }
    const closeAdjustCreditModal = ()=>{
        setSendRequestButtonClick(false);
        setOpenAdjustCreditsModal(false);
    }
    const onAdjustAmountValueChange = (e)=>{
        setAdjustCreditAmount(e.target.value);
    }
    const onAdjustReasonChange = (e)=>{
        setAdjustReasonText(e.target.value);
    }
    const onAdjustCreditButtonClick = () =>{
        setOpenAdjustCreditsModal(true);
    }
    const filterTransactionDataOnDate = (startDate, endDate) =>{
        const filteredTransactionData = filter(transactionData?.transactions, (data)=> moment(data?.date).isBetween(moment(startDate), moment(endDate)));
       return filteredTransactionData;
    }

    const ADD_CREDITS_MODAL = {
        modalTitle: 'Request Invoice to add credit to account balance',
        modalContent: "",
        positiveButtonText: 'Send Request',
        negativeButtonText: "Cancel",
        positiveButtonAction: onSendRequestClick,
        negativeButtonAction: closeAddCreditModal
    }
    const ADJUST_CREDITS_MODAL = {
        modalTitle: 'Adjust Account Balance',
        modalContent: "",
        positiveButtonText: 'Update Account Balance',
        negativeButtonText: "Cancel",
        positiveButtonAction: onSendAdjustRequestClick,
        negativeButtonAction: closeAdjustCreditModal
    }

    return (
        <React.Fragment>
             <Modal
              disableTitleDivider
                open={openAdjustCreditsModal}
                handleClose={closeAdjustCreditModal}
                dialogProps={ADJUST_CREDITS_MODAL} >
                <Grid item xs={6} className={classes.adjustModalField}>
                    <label className={classes.adjustModalField}>Amount<sup> *</sup></label>
                    <OutlinedInput
                        fullWidth
                        value={adjustCreditAmount}
                        onChange={onAdjustAmountValueChange}
                        placeholder='Enter amount'
                        id='amount'
                        type='number'
                        startAdornment={(
                            <InputAdornment position="start">
                                $
                            </InputAdornment>
                        )}
                    />
                    {
                        sendRequestButtonClick && !isAdjustAmountValid() &&
                        <ErrorText errorMessage="Enter valid amount" />
                    }
                </Grid>
                <Grid item xs={12}>
                        <label htmlFor='adjustmentReason' className={classes.adjustModalField}>Reason Of Adjustment<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            value={adjustReasonText}
                            onChange={onAdjustReasonChange}
                            placeholder='Enter reason'
                            name='adjustmentReason'
                            id='adjustmentReason'
                        />
                        {
                            sendRequestButtonClick && !isAdjustReasonTextValid() &&
                            <ErrorText errorMessage="Enter reason" />
                        }
                    </Grid>
            </Modal>
            <Modal
                open={OpenAddCreditsModal}
                handleClose={closeAddCreditModal}
                dialogProps={ADD_CREDITS_MODAL} >
                <Grid item xs={6} >
                    <label>Amount<sup> *</sup></label>
                    <OutlinedInput
                        fullWidth
                        value={amountValue}
                        onChange={onAmountValueChange}
                        placeholder='Enter amount'
                        id='amount'
                        type='number'
                        startAdornment={(
                            <InputAdornment position="start">
                                $
                            </InputAdornment>
                        )}
                    />
                    {
                        sendRequestButtonClick && !isAmountValid() &&
                        <ErrorText errorMessage="Enter valid amount" />
                    }
                </Grid>
                <h2 className={classes.billingInfoHeader}>Billing Information</h2>
                <Grid container xs={8} spacing={4}>
                    <Grid item xs={6}  >
                        <label>Name<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            onChange={onNameChange}
                            value={name}
                            placeholder='Username'
                            id='username'
                        />
                        {
                            sendRequestButtonClick && !isNameValid() &&
                            <ErrorText errorMessage="Enter valid username" />
                        }
                    </Grid>
                    <Grid item xs={6} >
                        <label>Email<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            onChange={onEmailChange}
                            value={emailValue}
                            placeholder='Email'
                            id='email'
                        />
                        {
                            sendRequestButtonClick && !validateEmailAddress(emailValue) &&
                            <ErrorText errorMessage="Enter valid email address" />
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={4} xs={8} className={classes.instituteDetailsContainer}>
                    <Grid item xs={6}>
                        <label htmlFor='country'>Country<sup> *</sup></label>
                        <DropDown
                            id='country'
                            values={COUNTRY_LIST}
                            handleFilterStateChange={setCountry}
                            selectedFilter={country}
                            placeholder={'Select Country'}
                            searchable
                            isClearable
                        />
                        {
                            sendRequestButtonClick && !isCountryValid() &&
                            <ErrorText errorMessage="Select country" />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <label htmlFor='streetAddress'>Street Address<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            value={streetAddress}
                            onChange={e => { setStreetAddress(e.target.value); }}
                            placeholder='Enter street address'
                            name='streetAddress'
                            id='streetAddress'
                        />
                        {
                            sendRequestButtonClick && !isStreetAddressValid() &&
                            <ErrorText errorMessage="Enter valid street address" />
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <label htmlFor='city'>City<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            value={city}
                            onChange={e => { setCity(e.target.value); }}
                            placeholder='Enter city'
                            name='city'
                            id='city'
                        />
                        {
                            sendRequestButtonClick && !isCityValid() &&
                            <ErrorText errorMessage="Enter valid city" />
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <label htmlFor='state'>State <sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            value={state}
                            onChange={e => { setState(e.target.value); }}
                            placeholder='Enter state'
                            name='state'
                            id='state'
                        />
                        {
                            sendRequestButtonClick && !isStateValid() &&
                            <ErrorText errorMessage="Enter valid state" />
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <label htmlFor='zipCode'>Zip Code<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            value={zipCode}
                            onChange={e => { setZipCode(e.target.value); }}
                            placeholder='Enter zip code'
                            name='zipCode'
                            id='zip'
                        />
                        {
                            sendRequestButtonClick && !isZipCodeValid() &&
                            <ErrorText errorMessage="Enter valid zip code" />
                        }
                    </Grid>

                </Grid>
            </Modal>
            <SectionHeader
                id='cvb-cvb-myAccount-sectionHeader'
                open={props.open}
                sectionHeaderProps={sectionHeaderProps}
                onBackButtonClick={() => navigate('/my-profile')}
            />



            <div className={classes.container}>
                <div className={classes.cardContainer}>
                    <div className={classes.cardItem}>
                        <div className={classes.cardContent}>
                            <h4>My Account Balance</h4>
                            <p>You will need to have credit in your account in order to be able to download Project files.</p>
                            <h5>$ {userAccountBalance}</h5>
                            <div className={classes.cardItemActions}>
                                <button className={`solid-button medium-size-button ${classes.addCreditButton}`} onClick={onAddCreditsButtonClick}>Add Credits</button>
                                {
                                    queryUserName &&
                                    <button className={`solid-button medium-size-button ${classes.addCreditButton}`} onClick={onAdjustCreditButtonClick}>Adjust Credits</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.transactionTableContainer}>
                    <Grid item lg={12} md={12} sm={12} className={classes.contentBlock}>
                        <div className={classes.sectionLeftContainer}>
                        </div>
                        <div className={classes.contentMainContentArea}>
                           { !isEmpty(transactionData) && <TransactionTable 
                            transactionData={transactionData}
                            filterTransactionDataOnDate={filterTransactionDataOnDate}
                            queryUserName={queryUserName}
                            />
                            }
                        </div>
                    </Grid>
                </div>
            </div>
        </React.Fragment>

    )
}

export default MyAccount;