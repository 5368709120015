import React, { useEffect, useState } from 'react';
import { Box, Divider, Button, Link } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AccordionDownIcon from '@mui/icons-material/ArrowDropDown';
import makeStyles from '@mui/styles/makeStyles';
import LinkIcon from '@mui/icons-material/Link';

const useStyles = makeStyles((theme) => ({
  studyAccordion: {
    backgroundColor: theme.palette.grey[50],
    borderTop: 'none',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    '&::before': {
      display: 'none',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      marginLeft: -24,
    },
    '& .MuiAccordionDetails-root': {
      fontSize: '0.88rem',
    },
    '& a': {
      color: '#4D80C6',
    },
    '& .MuiButton-sizeSmall': {
      padding: '0 6px',
    },
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '1em',
      marginRight: '0.5em',
    },
  },
  accordionSummary: {
    flexDirection: 'row-reverse',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  study: {
    fontSize: '0.875rem',
    '& .MuiButton-sizeSmall': {
      padding: '0 6px',
    },
    '& a': {
      color: '#4D80C6',
    },
  },
  highlight: {
    backgroundColor: theme.palette.grey[50],
    borderTop: 'none',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
}));

const useKeyValueStyles = makeStyles((theme) => ({
  inlineWrapper: {
    display: 'flex',
    marginBottom: '1em',
    alignItems: 'center',
    '& .MuiTypography-root': {
      width: '50%',
    },
    '& .title': {
      paddingRight: '1em',
    },
    '& .value': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  blockWrapper: {
    marginBottom: '1em',
    '& .MuiTypography-root': {
      marginBottom: '0.3em',
    },
  },
}));

const KeyValue = ({ title, value, displayFn, inline }) => {
  const styles = useKeyValueStyles();
  return (
    <Box
      mt={1}
      mb={1}
      className={inline ? styles.inlineWrapper : styles.blockWrapper}
    >
      <Typography className="title" variant="h4">
        {title}
      </Typography>
      <Typography className="value" variant={inline ? 'span' : 'div'}>
        {value === undefined || value == null || value == 'None'
          ? 'N/A'
          : displayFn
          ? displayFn(value)
          : value}
      </Typography>
    </Box>
  );
};

function displayFullDate(dateString) {
  const d = new Date(dateString);
  return d.toLocaleString();
}

function displayInnerHtml(htmlString) {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

function displayButtonHtml(htmlString, Icon) {
  return (
    <Button variant="outlined" size="small">
      <LinkIcon fontSize="small" />
      <div dangerouslySetInnerHTML={{ __html: htmlString }} />
    </Button>
  );
}

function displayStringOrArray(stringOrArray) {
  return Array.isArray(stringOrArray)
    ? stringOrArray.join(', ')
    : stringOrArray;
}

const displayLink = (url) => <Link href={url}>{url}</Link>;

const displayDataArray = (dataArray) =>
  dataArray.map((data) =>
    Object.keys(data).map((key) => (
      <Typography component="p">
        {key}: {displayStringOrArray(data[key])}
      </Typography>
    ))
  );

const displayPublications = (dataArray) =>
  dataArray.map((data) => (
    <KeyValue
      title={data.publication_name}
      value={data.publication_url}
      inline={true}
      displayFn={displayLink}
    />
  ));

export const CohortInfoStudies = ({ studies, participants }) => {
  const styles = useStyles();

  function getParticipantsForStudy(projectId) {
    const item = participants?.project_id?.histogram?.find(obj => obj.key === projectId);
    return item ? item.count : null;
  }
  const [expandedIndex, setExpandedIndex] = useState(false);

  const handleChange = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? false : index));
  };

  useEffect(() => {
    setExpandedIndex(studies.length === 1 ? 0 : false);
  }, [studies]);

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >

      {studies.length ? studies.map((study, index) => (
        <Accordion key={study.projectId} className={styles.studyAccordion} expanded={expandedIndex === index} onChange={() => handleChange(index)}>
          <AccordionSummary
            expandIcon={<AccordionDownIcon />}
            className={styles.accordionSummary}
          >
            <Typography variant="h4">{study.studyName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <KeyValue
              title="Project ID"
              value={study.projectId}
              inline={true}
            />
            <Divider className={styles.divider} />
            <KeyValue
              title="Number of Participants"
              value={getParticipantsForStudy(study.projectId)}
              inline={true}
            />
            <KeyValue
              title="Restrictions"
              value={
                study.usageRestrictions ||
                'No restrictions to all authorised users'
              }
              inline={true}
            />
            <Divider className={styles.divider} />
            <KeyValue
              title="Created on"
              value={study.createdAt}
              displayFn={displayFullDate}
              inline={true}
            />
            <KeyValue
              title="Last updated on"
              value={study.updatedAt}
              displayFn={displayFullDate}
              inline={true}
            />
            <Divider className={styles.divider} />
            <KeyValue
              title="Location of Study"
              value={study.locationOfStudy}
              inline={true}
            />
            <KeyValue
              title="Duration of study"
              value={study.durationOfStudyInMonths}
              inline={true}
            />

            <Divider className={styles.divider} />
            <KeyValue
              title="Objective"
              value={study.objectiveOfStudy}
              displayFn={displayInnerHtml}
            />
            <KeyValue
              title="Description"
              value={study.descriptionOfStudy}
              displayFn={displayInnerHtml}
            />
            <KeyValue
              title="Data Description"
              value={study.descriptionOfData}
              displayFn={displayInnerHtml}
            />
            <Divider className={styles.divider} />
            <KeyValue
              title="Study design"
              value={study.studyDesign}
              inline={true}
            />
            <KeyValue
              title="Data Type"
              value={study.dataType}
              displayFn={displayDataArray}
            />
            <Divider className={styles.divider} />
            <KeyValue
              title="Acknowledgement Text"
              value={study.acknowledgeText}
              displayFn={displayInnerHtml}
            />
            <KeyValue
              title="Data Repository of Record"
              value={study.dataRepositoryOfRecord}
              displayFn={displayInnerHtml}
            />
            <Divider className={styles.divider} />
            <KeyValue
              title="Additional Resource"
              value={study.additionalResource}
              displayFn={displayButtonHtml}
              inline={true}
            />
            <Divider className={styles.divider} />
            <KeyValue
              title="Publications"
              value={study.publications}
              displayFn={displayPublications}
            />
          </AccordionDetails>
        </Accordion>
      )) : <Typography variant="body1">No data for this Cohort.</Typography>}
    </Box>
  );
};

export default CohortInfoStudies;

export function StudyInfo({ study }) {
  const styles = useStyles();

  return (
    <Box className={styles.study}>
      <Box className={styles.highlight}>
        <KeyValue title="Project ID" value={study.projectId} inline={true} />
        <Divider className={styles.divider} />
        <KeyValue
          title="Number of Participants"
          value={study.case_count}
          inline={true}
        />
        <KeyValue
          title="Restrictions"
          value={
            study.usageRestrictions || 'No restrictions to all authorised users'
          }
          inline={true}
        />
        <Divider className={styles.divider} />
        <KeyValue
          title="Created on"
          value={study.createdAt}
          displayFn={displayFullDate}
          inline={true}
        />
        <KeyValue
          title="Last updated on"
          value={study.updatedAt}
          displayFn={displayFullDate}
          inline={true}
        />
        <Divider className={styles.divider} />
        <KeyValue
          title="Location of Study"
          value={study.locationOfStudy}
          inline={true}
        />
        <KeyValue
          title="Duration of study"
          value={study.durationOfStudyInMonths}
          inline={true}
        />
      </Box>
      <Box px={2.5}>
        <KeyValue
          title="Objective"
          value={study.objectiveOfStudy}
          displayFn={displayInnerHtml}
        />
        <KeyValue
          title="Description"
          value={study.descriptionOfStudy}
          displayFn={displayInnerHtml}
        />
        <KeyValue
          title="Data Description"
          value={study.descriptionOfData}
          displayFn={displayInnerHtml}
        />
        <Divider className={styles.divider} />
        <KeyValue
          title="Study design"
          value={study.studyDesign}
          inline={true}
        />
        <KeyValue
          title="Data Type"
          value={study.dataType}
          displayFn={displayDataArray}
          inline={true}
        />
        <Divider className={styles.divider} />
        <KeyValue
          title="Acknowledgement Text"
          value={study.acknowledgeText}
          displayFn={displayInnerHtml}
        />
        <KeyValue
          title="Data Repository of Record"
          value={study.dataRepositoryOfRecord}
          displayFn={displayInnerHtml}
        />
        <Divider className={styles.divider} />
        <KeyValue
          title="Additional Resource"
          value={study.additionalResource}
          displayFn={displayButtonHtml}
          inline={true}
        />
        <Divider className={styles.divider} />
        <KeyValue
          title="Publications"
          value={study.publications}
          displayFn={displayPublications}
        />
      </Box>
    </Box>
  );
}
