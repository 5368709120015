import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import classes from './index.module.css'
import SectionHeader from '../../commons/section-header'
import strokesBG from '../../../assets/images/strokes-bg.png'
import deactivationIcon from '../../../assets/images/deactivation-lock.svg';
import OutlinedInput from '@mui/material/OutlinedInput';
import {validateDeactivationCode, deactivateUserAccount} from '../../../api/profile-api';
import { Auth } from 'aws-amplify';
import ErrorText from '../../controls/error-text';
import { navigate } from '@reach/router'

const Deactivate = props => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [deactivationCode, setDeactivationCode] = useState('')
    const [isFormUpdating, setIsFormUpdating] = useState(false);
    
    window.scrollTo(0, 0);
    
    const sectionHeaderProps = {
        title: ""
    }

    const cancelDeactivation = () => {
        navigate('/my-profile')
    }

    const onChangeCode = (event) => {
        setErrorMessage('');
        setDeactivationCode(event.target.value)
    }

    const logout = async () => {
        try {
            await Auth.signOut().catch(e => { })
            const cohortsColumns = localStorage.getItem('myCohortsColumns');
            const projectsColumns = localStorage.getItem('availableProjectsColumns');
            localStorage.clear();
            localStorage.setItem('myCohortsColumns', cohortsColumns);
            localStorage.setItem('availableProjectsColumns', projectsColumns);
            sessionStorage.clear();
            if(props?.location?.state?.deleteWorkspace){
                window.location.href = '/deactivation-success?workspace=true';
            }else{
                window.location.href = '/deactivation-success';
            }
        } catch (error) {
            console.log(error)
        }
    }

    const deactivateAccount = async () => {
        try{
            setIsFormUpdating(true)
            setIsFormSubmitted(true)
            if(deactivationCode){
                const body = {
                    "deactivation_code":deactivationCode
                };
                const deactivation_details = {
                    "deactivate_workspace": props?.location?.state?.deleteWorkspace ? true : false,
                    "workspace_ids": props?.location?.state?.deleteWorkspace ? props?.location?.state?.workspaces : []
                }
                const response = await validateDeactivationCode(body);
                if(!response?.deactivation_code_verified){
                    setErrorMessage('This code didn’t work. Check the code and try again.');
                    return
                }else{
                    const userResponse = await deactivateUserAccount(deactivation_details);
                    if(userResponse?.user_deactivation){
                        logout();
                    }else{
                        setErrorMessage(userResponse?.message ? userResponse.message : 'Something went wrong, please try again.');
                        return
                    }
                }
                
            }else{
                setErrorMessage('Please enter Deactivation Code')
            }
        }catch(error){
            let errorMessage = "Something went wrong, Please try again.";
            if (error?.response?.data?.message) {
                errorMessage = error.response.data.message
            }
            console.log(error);
            setErrorMessage(errorMessage)
        }finally{
            setIsFormUpdating(false)
        }
    }

    


    return (
        <Grid container className={classes.mainContainer} direction="column">
            <SectionHeader
                id='cvb-cvb-myProfile-sectionHeader'
                sectionHeaderProps={sectionHeaderProps}
            />
            <div className={classes.innerContainer}>
                <Grid container className={classes.searchBackground}>
                            
                    <Grid item xs={8} lg={5} md={6} className={classes.backroundImageContainer}>
                        <img className={classes.strokesImage} src={strokesBG} alt='strokes background' />
                    </Grid>
                    <div className={classes.deactivateBox}>
                        
                        <Grid container>
                            <Grid lg={2}>
                                <img src={deactivationIcon}  />
                            </Grid>
                        
                    
                            <Grid lg={10}>
                                <div className={classes.deactivateTitle}>Enter code to deactivate your account</div>
                                <div className={classes.deactivateSubTitle}>A verification code has been sent to your registered email <span style={{fontWeight:'bold'}}>{props?.userDetails?.email}</span>.</div>

                                <OutlinedInput
                                    type="text"
                                    value={deactivationCode}
                                    fullWidth
                                    onChange={onChangeCode}
                                    error={(isFormSubmitted && errorMessage)} 
                                    id='cvb-requestAdvancedComputeDetailsView-projectTitle'
                                    name='projectTitle'
                                />
                                {
                                    isFormSubmitted && errorMessage &&
                                    <ErrorText errorMessage={errorMessage} />
                                }
                            </Grid>
                        </Grid>
                        
                        <div className={classes.buttonContainer}>
                            <button onClick={cancelDeactivation}
                                id='cvb-modal-negativeActionButton'
                                className={`transparent-button medium-size-button solid-button ${classes.cancelDeactivateButton}`} >
                                Cancel
                            </button>
                        
                            <button onClick={() => deactivateAccount()} 
                                id='cvb-modal-positiveActionButton'
                                className={`solid-button ${classes.deactivateButton}`}
                                disabled={isFormUpdating}>
                                Verify
                            </button>
                        </div>
        
                    </div>
                </Grid>
            </div>   

            
            
        </Grid>
    )
}
export default Deactivate;
