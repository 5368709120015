import React, { useState, useEffect } from 'react'
import { Switch } from '@mui/material'
import withStyles from '@mui/styles/withStyles';
import { Auth } from 'aws-amplify'
import { getUserProfile, updateUserProfile } from '../../../api/profile-api'
import Grid from '@mui/material/Grid';
import classes from './index.module.css';
import { navigate } from '@reach/router';
import BackButtonArrow from '../../../assets/icons/left_arrow.png'
import CustomLoader from '../../commons/custom-loader'

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        top: '-2px'
    },
    switchBase: {
        left: '2px !important',
        color: theme.palette.grey[500],
        '& + $track': {
            borderColor: '#9e9e9e',
        },
        '&$checked': {
            transform: 'translateX(12px)',
            color: '#412391',
            '& + $track': {
                opacity: 1,
                backgroundColor: '#fff',
                borderColor: '#412391',
            },
        },
    },
    thumb: {
        marginTop: 2,
        left: 2,
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid #412391`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: '#fff',
    },
    checked: {},
}))(Switch);

const ChangeNotification = (props) => {
    const [userDetails, setUserDetails] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const getData = async () => {
        try {
            setIsLoading(true)
            const userValues = await Auth.currentUserInfo();
            const userInfo = await getUserProfile(userValues?.attributes?.sub);
            setUserDetails(userInfo?.user_details)
            setIsLoading(false)
        }
        catch (error) {
            console.log(error)
            setIsLoading(false)

        }
    }

    useEffect(() => {
        getData()
    }, [])

    const handleChange = async () => {
        try {
            const userValues = await Auth.currentUserInfo();
            const body = { ...userDetails }
            body.custom_attributes.is_user_unsubscribed_from_pending_notification_email = (body?.custom_attributes?.is_user_unsubscribed_from_pending_notification_email === "False") ? "True" : "False"
            await updateUserProfile(userValues?.attributes?.sub, body);
            props.updateSnackBar("Notification preference updated", 'success');
            getData()
        }
        catch (error) {
            props.updateSnackBar("Error in updating the profile", 'error');
        }
    }

    return (
        <div className={classes.innerContainer} id='cvb-notificationPreference-mainContainer'>
            <Grid container className={classes.searchBackground}>
                <Grid item xs={12} lg={12} md={12}>
                    <Grid item xs={4} lg={4} md={4}>
                        <p id='cvb-notificationPreference-backButton'
                            onClick={() => navigate('/my-profile')} className={classes.backButton}>
                            <span><img alt='back button arrow' src={BackButtonArrow} /></span>
                            <p className={classes.backButtonText}>Back</p>
                        </p>
                        <div className={classes.sectionHeaderContainer}>
                            <div className={classes.gridTitle}><h2 id='cvb-notificationPreference-headingText'>Manage Notifications</h2></div>
                            <div className={classes.subHeadingContent}>
                                <h3>Email Notifications</h3>
                                <p>Decide whether or not you want to receive pending notifications</p>
                            </div>
                        </div>

                    </Grid>
                    <Grid item xs={4} lg={4} md={4}>
                        <div className={classes.toggleContainer} id='cvb-notificationPreference-toggleContainer'>
                            <div className={classes.leftContainer}>
                                <p>Pending Notifications</p>
                            </div>
                            <div className={classes.rightContainer}>
                                <ul>
                                    <li><p>On</p></li>
                                    <li className={classes.toggleButton} id='cvb-notificationPreference-toggleButton'>
                                        {
                                            isLoading ?
                                                <CustomLoader styleProp={{ height: '20px', width: '20px', position: 'relative' }} /> :
                                                <AntSwitch
                                                    checked={userDetails?.custom_attributes?.is_user_unsubscribed_from_pending_notification_email != "True"}
                                                    onChange={handleChange} />

                                        }

                                    </li>
                                </ul>


                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default ChangeNotification
