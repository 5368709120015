import React, { useState } from 'react'
import Modal from '../modal'
import PrivacyPolicy from '../privacy-policy/PrivacyPolicy'

const PrivacyNotice = (props) => {
    const {open,  handleClose, dialogProps} = props;
    const [openPrivacyNoticeModal, setOpenPrivacyNoticeModal] = useState(false);
    const PRIVACY_NOTICE_MODAL = {
        modalTitle: "Privacy Notice",
        modalContent: ""
    }
    const togglePrivacyNoticeModal = () =>{
        setOpenPrivacyNoticeModal(false);
    }
    return (
        <>
         <PrivacyPolicy
            open={openPrivacyNoticeModal}
            handleClose={togglePrivacyNoticeModal}
            dialogProps={PRIVACY_NOTICE_MODAL} />
        <Modal open={open} handleClose={handleClose} dialogProps={dialogProps} disableTitleDivider disableBottomHorizontalDivider containerClass="private-policy-content">


            <div>
                <p>
                    <b><span>BRAINCommons&trade; GLOBAL PRIVACY POLICY </span></b>
                </p>
                <p>Version 2.0</p>
                <p>Last updated: 12/16/2021</p>
                <p><b>CONTENTS</b></p>

                <p>
                    <ol>

                        <li>Introduction</li>
                        <li>Terms used</li>
                        <li>What Personal Data we collect from you </li>
                        <li>Purposes for Processing Personal Data</li>
                        <li>Children and Personal Data</li>
                        <li>Legal basis for Processing </li>
                        <li>Website and BC Platform</li>
                        <li>Cookies</li>
                        <li>Data Security</li>
                        <li>Retention of Personal Data</li>
                        <li>Contact Us </li>
                    </ol>
                </p>
                <p>To read our <b>BRAINCommons Global Privacy Notice Summary</b>, please click <a href="#"  >here</a> </p>
                <p>To read the full <b>BRAINCommons Global Privacy Notice</b>, please click <a href="#" onClick={() => setOpenPrivacyNoticeModal(true) } >here</a> </p>
                <p>
                    <b>
                        <span>INTRODUCTION</span>
                    </b>
                </p>
                <p>Privacy, data protection and security are of paramount importance to the BRAINCommons&trade;. When you use the BRAINCommons (BC) Platform, you trust us to protect your Personal Data and to create a safe and secure environment for the Processing of De-identified Personal Data for Educational Purposes and Research Purposes only.</p>
                <p>We are fully committed to keeping that trust and this BRAINCommons Global Privacy Policy sets out how we achieve this by describing our global practices that align with our core values an organization operated not for profit. </p>
                <p>Our mission is to fast-track the development of diagnostic tests and personalized therapeutics for the millions of veterans and civilians who suffer the devastating effects of trauma related and other brain disorders. This is achieved by harnessing the power of biotechnology (including neuroimaging, omics and biosensors) in combination with high-performance computing and data analytics to understand the underlying mechanisms of disease and discover new and novel ways to improve brain health.</p>
                <p>The BRAINCommons Platform has been built to with the intent to comply with relevant global privacy, data protection, and security laws and regulations with respect to protection of the rights, freedoms and interests of everyone that uses, contributes and shares Personal Data, Protected Health Information (PHI) and De-identified Personal Data in furtherance of our mission. </p>
                <p>This BRAINCommons Global Privacy Policy applies to all data Processing activities we conduct including information received, both online and off-line, any electronic, written or oral communications, information you provide and that is shared with third party service providers and other parties that we collaborate with.</p>
                <p>For simplicity, throughout this BRAINCommons Global Privacy Policy, ‘we’, ‘us’ and ‘our’ means the BC Manager.</p>

                <p>
                    <b>
                        <span>TERMS USED</span>
                    </b>
                </p>

                <p>
                    <b>
                        <i>
                            <span>“Advanced Compute Privileges”</span>
                        </i>
                    </b>
                    <span> are privileges granted to designated Users of the BC Platform that meet specified requirements and 
                        have submitted the required documentation and have institutional certification approved for requested 
                        compute services. These Users have the ability to download data to workspaces and access to applications 
                        that support interoperability and tool development.
                    </span>
                </p>


                <p>
                    <b>
                        <i>
                            <span>“BC Executive Director”</span>
                        </i>
                    </b>
                    <span> is the individual representative of the BC Manager with senior responsibility and authority for the management of the BRAINCommons Platform.
                    </span>
                </p>

                <p>
                    <b>
                        <i>
                            <span>“BC Manager”</span>
                        </i>
                    </b>
                    <span> is the manager institution of the BRAINCommons with responsibility for the management of the BRAINCommons Platform. The BC Manager may be BRAINCommons LLC or its designee.
                    </span>
                </p>


                <p>
                    <b>
                        <i>
                            <span>“BRAINCommons”</span>
                        </i>
                    </b> or <b><i>“BC“</i></b>
                    <span> is a knowledge commons that collocates or integrates data, storage and computing infrastructure with services, tools, interfaces and applications for managing, analyzing, and sharing data to create an interoperable resource for the brain and other research communities that enables and encourages data sharing, collaboration and data-driven discovery.
                    </span>
                </p>

                <p>
                    <b>
                        <i>
                            <span>“BRAINCommons Data Contributor Agreement”</span>
                        </i>
                    </b>
                    <span> is an agreement between BRAINCommons LLC and the data contributor that provides terms and conditions for contribution of De-identified Personal Data to the BC Platform. Pursuant to the BRAINCommons Data Contributor Agreement, the data contributor agrees to contribute certain datasets that consist of de-identified pre-clinical and/or bio-medical data (including but not limited to clinical, genomic, imaging, or biosensor data) to the BC Platform and permits the BC Manager to grant Users certain access rights based on the applicable contributor’s designated zone rights (i.e. Zone 1, Zone 2 or Zone 3), to access and use contributed data via the BC Platform subject to applicable law, the BRAINCommons Terms of Use and if applicable, the BRAINCommons Data Use Agreement and any organizational or dataset-specific use agreement.
                    </span>
                </p>


                <p>
                    <b>
                        <i>
                            <span>“BRAINCommons Data Use Agreement”</span>
                        </i>
                    </b>
                    <span> is an agreement that sets out requirements and standard operating procedures for access, use and sharing of various datasets from research into brain disorders and diseases within the BC Platform Zone 2 or Zone 3.
                    </span>
                </p>


                <p>
                    <b>
                        <i>
                            <span>“BRAINCommons Platform”</span>
                        </i>
                    </b> or <b><i>“BC Platform“</i></b>
                    <span> is the software (and other technology)-enabled platform for managing, analyzing and sharing de-identified bio-medical data, which is provided or made available in connection with the BRAINCommons.</span>
                </p>


                <p>
                    <b>
                        <i>
                            <span>“BRAINCommons Terms of Use”</span>
                        </i>
                    </b>
                    <span> are a set of terms that regulates the use of the BC Platform by all Users, including access levels, any services or tools made available through the BC Platform as well as the use and sharing of any content and data residing on the BC Platform.</span>
                </p>

                <p>
                    <b>
                        <i>
                            <span>“Controlled Access”</span>
                        </i>
                    </b>
                    <span> means a data access model whereby Users that are qualified researchers are granted access to several datasets in a controlled environment in the BC Platform. Controlled Access allows for use of some visualization and basic data exploration tools with the associated datasets and interaction with the community. Users granted with Controlled Access can also request Advanced Compute Privileges. See also <b>“Zone 2”</b>. </span>
                </p>


                <p>
                    <b>
                        <i>
                            <span>“Data”</span>
                        </i>
                    </b>
                    <span> is any information, results, raw or processed data, including De-Identified Personal Data, that may be stored, uploaded or shared on the BC.</span>
                </p>


                <p>
                    <b>
                        <i>
                            <span>“Data Breach”</span>
                        </i>
                    </b>
                    <span> means a security incident that affects the confidentiality, integrity or availability of data, including accidental or unlawful destruction, loss, alteration, unauthorized disclosure or access to data.</span>
                </p>


                <p>
                    <b>
                        <i>
                            <span>“Data Controller”</span>
                        </i>
                    </b>
                    <span> means the BC Manager, that makes decisions as to the purposes and means for Processing De-identified Personal Data contributed under the BRAINCommons Data Contributor Agreement and for Processing any Personal Data of Users accessing the BRAINCommons Platform.</span>
                </p>


                <p>
                    <b>
                        <i>
                            <span>“Data Subject”</span>
                        </i>
                    </b>
                    <span> means the individual whose data has been or will be collected, generated, held, used or shared (in other words, Processed) on the BC Platform.</span>
                </p>


                <p>
                    <b>
                        <i>
                            <span>“De-identified Personal Data”</span>
                        </i>
                    </b> or 
                    <b>
                        <i>
                            <span> “Pseudonymized Data“</span>
                        </i>
                    </b>
                    <span> means personal identifiers are replaced with nonidentifying references or Encryption Keys so that anyone working with the data is unable to identify the Data Subject without the Encryption Key.</span>
                </p>


                <p>
                    <b>
                        <i>
                            <span>“Educational Purposes”</span>
                        </i>
                    </b>
                    <span> means a purpose that relates directly to learning, teaching, training, and professional development of the De-identified Personal Data.  This includes non-profit and commercial activities that achieves this purpose. </span>
                </p>

                <p>
                    <b>
                        <i>
                            <span>“Encryption”</span>
                        </i>
                    </b>
                    <span> means re-ordering bits of data to make it unintelligible and therefore unusable to an unauthorized person while still enabling an authorized user to use the data after the reverse process of decryption with an Encryption Key.</span>
                </p>

                <p>
                    <b>
                        <i>
                            <span>“Encryption Key”</span>
                        </i>
                    </b>
                    <span> means a piece of data that an Encryption algorithm uses to determine exactly how to unscramble De-identified Personal Data or Pseudonymized Data. Such Encryption Keys are not provided to the BC Manager, or otherwise housed within the BC.</span>
                </p>

                <p>
                    <b>
                        <i>
                            <span>“Personally Identifiable Information (PII)”</span>
                        </i>
                    </b> or 
                    <b>
                        <i>
                            <span>“Personal Data”</span>
                        </i>
                    </b>
                    <span> typically includes personal contact details such as name, title, address, telephone numbers, place of employment/institution where the individual is based, email address and area of expertise, activity/medical research and interests.</span>
                </p>

                <p>
                    <b>
                        <i>
                            <span>“Privacy, Data Protection and Security Training”</span>
                        </i>
                    </b>
                    <span> is the free online training offered to Users by the BRAINCommons relating to privacy, data protection and security. </span>
                </p>

                <p>
                    <b>
                        <i>
                            <span>“Process”, “Processing” </span>
                        </i>
                    </b> or 
                    <b>
                        <i>
                            <span> “Processed”</span>
                        </i>
                    </b>
                    <span> means any operation or set of operations that is performed on data or on sets of data, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.</span>
                </p>

                <p>
                    <b>
                        <i>
                            <span>“Protected Health Information”</span>
                        </i>
                    </b>
                    <span> includes all individually identifiable health information, including demographic data, medical histories, test results, insurance information, and other information used to identify a patient or provide healthcare services or healthcare coverage.</span>
                </p>

                <p>
                    <b>
                        <i>
                            <span>“Public Access”</span>
                        </i>
                    </b>
                    <span> means a data access model whereby all Users have access to public datasets in the BC Platform. Public Access allows for use of some visualization and basic data exploration tools with the associated datasets and interaction with the community. See also <b>“Zone 1”</b>.</span>
                </p>

                <p>
                    <b>
                        <i>
                            <span>“Research Purposes”</span>
                        </i>
                    </b>
                    <span> means activities undertaken for the advancement of knowledge for the brain and other research community that enable and encourage data sharing, collaboration and data-driven discovery. It includes analysis on data to conduct a systematic investigation, including research development, testing, or evaluation, which is designed to develop or contribute to generalizable knowledge or education. </span>
                </p>

                <p>
                    <b>
                        <i>
                            <span>“Restricted Access”</span>
                        </i>
                    </b>
                    <span> means a data access model whereby designated Users are granted access to one dataset in a controlled environment in the BC Platform. Restricted Access allows for use of some visualization and basic data exploration tools with the designated dataset and interaction with the community.  See also <b>“Zone 3”</b>. </span>
                </p>

                <p>
                    <b>
                        <i>
                            <span>“Users”</span>
                        </i>
                    </b>
                    <span> means (1) registered users, including the general public, (2) authorized users who are qualified researchers, and (3) designated users designated by Data Contributor for use of a specific dataset, tool and/or application, in each case, accessing the BC Platform for Education Purposes or Research Purposes.</span>
                </p>

                <p>
                    <b>
                        <i>
                            <span>“Zone 1”</span>
                        </i>
                    </b>
                    <span> is the level of ‘Public Access’ and provides Users access to Data that is subject to the BRAINCommons Terms of Use, BRAINCommons Global Privacy Notice and this BRAINCommons Global Privacy Policy.</span>
                </p>


                <p>
                    <b>
                        <i>
                            <span>“Zone 2”</span>
                        </i>
                    </b>
                    <span> is the level of ‘Controlled Access’ and provides more limited access to Data to Users who are verified and meet the controlled user requirements as determined by the Data Access Committee (or, as applicable, via another approval process implemented by the BC Manager). Each such authorized User must enter into a BRAINCommons Data Use Agreement and if applicable, an organizational or Dataset-specific Use Agreement as part of his or her Zone Access Request.</span>
                </p>


                <p>
                    <b>
                        <i>
                            <span>“Zone 3”</span>
                        </i>
                    </b>
                    <span> is the level of ‘Restricted Access’ and provides access to specific datasets or tools to User(s) that are (a) specifically authorized by the Data Contributor or (b) approved through the Data Access Committee (or, as applicable, via another approval process implemented by the BC Manager). Prior to being granted access to Zone 3 Data, each such authorized User must enter into a BRAINCommons Data Use Agreement and if applicable, an organizational or Dataset-specific Use Agreement.</span>
                </p>

                <p>
                    <b>
                        <i>
                            <span>“Zone Access Request”</span>
                        </i>
                    </b>
                    <span> is the request that a User may make in order to request for access to Data that are designated as Zone 2 or Zone 3.</span>
                </p>

                <p>
                    If you have questions about data protection and privacy or you want more information about the BC Platform and marketing permissions, please contact us at <a href="mailto:notice@braincommons.org">notice@braincommons.org</a>. All notices received will be triaged for appropriate action and response.
                </p>


                <p>
                    <b>
                        <span>WHAT PERSONAL DATA WE COLLECT FROM YOU</span>
                    </b>
                </p>

                <p>We’ll collect information through our website and BC Platform that can identify you, such as your name, address, phone number, email, company/organization name and position. The BC Manager may use this information to respond to your requests for information and services. The BRAINCommons may also collect information that will usually not allow us to directly identify you, such as information gathered through “cookies”. For purposes of authentication, monitoring, completeness or accuracy, we may receive information about you from other sources, including third parties you have authorized to share information with us, and we may add or relate such information to your account. </p>
                <p>Access to your Personal Data and the equipment Processing your Personal Data is restricted to our staff and third parties working under our strict instructions. Unless provided for under this BRAINCommons Global Privacy Policy, we will not trade, sell, release, share or transfer your Personal Data for use by any third party without consent or in a form other than what was disclosed to you at the time the Personal Data was collected, unless permitted or required by law.</p>
                <p>We comply with data protection, privacy and security laws that are applicable when we Process your Personal Data and ensure that you can exercise your rights with respect to personal information collected by us but not limited to the right of access and correction, to withdraw consent at any time, object to data Processing, request data deletion, restrict aspects of data Processing, and request transmission of Personal Data in a common digital format provided this is technically possible of being received by a third party.</p>
                <p>For more detailed information on Personal Data we collect from you and how we protect this data, please refer to our BRAINCommons Global Privacy Notice.</p>


                <p>
                    <b>
                        <span>PURPOSES FOR PROCESSING PERSONAL DATA</span>
                    </b>
                </p>

                <p>We Process the minimum of Personal Data to provide you with access to the BC Platform and our services as well as to comply with statutory and regulatory obligations. Typically, the purposes for Processing Personal Data are: </p>
                <ul>
                    <li>For participation in clinical studies, registries and trials; managing and validating the recruitment and participation of individuals to studies, trials and other operations; analyzing demographic data; offering special activities, trials, events via our website and carrying out other research and educational activities.</li>
                    <li>To provide you access to the BC Platform and services, tools, apps, social media, and management of your online account.</li>
                    <li>To allow us to identify or authenticate you; provide or verify your credentials including via passwords, password hints, security information and questions, government-issued ID, healthcare professional number, driver’s license data, and passport data.</li>
                    <li>To improve and develop the BC Platform and services; identify usage trends and develop new and novel services; understand how you interact with our services; track and respond to safety concerns; determine the effectiveness of our communications and conduct surveys.</li>
                    <li>To personalize your experience when using the BC Platform and our services; ensure that our services are presented in the way that best suits you; understand your professional and personal interests in our content and services or other content and adapt our content to your needs and preferences.</li>
                    <li>To allow us to communicate with you; respond to your requests or inquiries; provide support for the BC Platform and services; provide you with important information, administrative information, required notices, and promotional materials; send you news and information about our services; organize and manage professional seminars, symposiums and events, including your participation to such events.</li>
                    <li>To offer donations, sponsorship, and other types of support.</li>
                    <li>To respond to legal requests from regulators, administrative and judicial authorities, in accordance with applicable laws; comply with a subpoena, required registration, or legal process.</li>
                    <li>To protect our rights and legitimate interests; protect the health, safety, and security of our staff; carry out internal audits, asset management, system, privacy, security, and other business controls; manage business administration (finance and accounting, fraud monitoring and prevention); maintain the security of our services and operations; protect our rights, privacy, safety or property; to allow us to pursue available remedies or limit the damages that we may incur as necessary; to protect ourselves against possible fraudulent actions.</li>
                </ul> 

                <p>For more detailed information on Personal Data we collect from you and how we protect this data, please refer to our Global Privacy Notice.</p>


                <p>
                    <b>
                        <span>CHILDREN AND PERSONAL DATA</span>
                    </b>
                </p>

                <p>Our BRAINCommons website is not intended for children under 18 years of age. We don’t knowingly collect Personal Data from children under 18 years-old through our BRAINCommons website. If you are under 18 years-old, do not access or use our website unless authorized and under the supervision of a parent or guardian.</p>
                <p>There may be restricted circumstances where we may collect Personal Data about children with the consent of the parent/guardian for Research Purposes and Educational Purposes only. We don’t solicit Personal Data or market to children. If a parent/guardian becomes aware that their child has provided us with Personal Data, they should contact us at <a href="mailto:notice@braincommons.org">notice@braincommons.org</a> and we will delete such Personal Data from our database in accordance with applicable legal requirements.</p>


                <p>
                    <b>
                        <span>LEGAL BASIS FOR PROCESSING </span>
                    </b>
                </p>

                <p>We Process your Personal Data on the following legal basis:</p>

                <ul>
                    <li>Prior express consent: where you’ve clearly expressed your approval of us Processing your Personal Data or Protected Health Information (PHI). In practice, this will generally mean that we’ll ask you to complete an online “opt-in” form or to follow any relevant procedure to allow you to be fully informed and then either clearly accept or reject the Personal Data Processing envisaged.</li>
                    <li>Where there’s a contractual relationship between us: in such a situation, the Processing of your Personal Data is generally necessary to the execution or performance of the contract; this means that if you don’t wish us to Process your Personal Data in that context we may or will be obliged to refuse to enter into such contract with you or will not be able to provide the services covered in this contract.</li>
                    <li>Where we have a legitimate interest to Process your Personal Data in accordance with applicable data protection and privacy laws: in such a situation, we shall always balance your fundamental rights and interests against our legitimate interests in determining that your rights are not overridden and that such Processing of Personal Data is appropriate, transparent, accountable, and lawful.</li>
                </ul>


                <p>
                    <b>
                        <span>WEBSITE AND BC PLATFORM</span>
                    </b>
                </p>

                <p>The primary purpose of the BRAINCommons website is for general communications with all Users. The primary purpose for the BC Platform is to provide and share De-identified Personal Data and information for the benefit of general users, qualified researchers, designated users, authorizing officials, submitters of data, project administrators, other users and employees and staff of the BC Manager. We want you to feel secure when visiting the BRAINCommons website and using the BC Platform and are committed to protecting your privacy when you do so.</p>
                <p>For more detailed information on Personal Data we collect from you and how we protect this data, please refer to our Global Privacy Notice.</p>

                <p>
                    <b>
                        <span>Links to Third-Party websites and Social Media</span>
                    </b>
                </p>

                <p>The BC Platform does have links to third-party websites and social media platforms. Please note that we are not responsible for the privacy and security policies of these websites and platforms or the content that is presented within these websites and platforms. Please keep this in mind when signing up to access these third-party websites and platforms whether through the BC Platform or otherwise as we don’t control what happens on these websites and platforms. We strongly recommend that you carefully check the privacy and security policies of these websites and platforms you’re visiting to ensure that you fully understand how the operator of that website and platform will collect your Personal Data and how this will be used and protected.</p>



                <p>
                    <b>
                        <span>Social Networks and Third-Party Plug-ins</span>
                    </b>
                </p>

                <p>The BC Platform may also include User-to-User communication functionality, such as discussion forums.  If you choose to use any of these features, any information you disclose could be accessed and further disclosed by other Users.</p>

                <p>The following social networks and third-party plug-ins are available from the BC Platform: </p>
                <ol>
                    <li>LinkedIn <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a></li>
                    <li>Twitter <a target="_blank" rel="noreferrer" href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a></li>
                    <li>Google Scholar <a target="_blank" rel="noreferrer" href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></li>
                    <li>User’s personal website(s)</li>
                    <li>GitHub <a target="_blank" rel="noreferrer" href="https://docs.github.com/en/github/site-policy/global-privacy-practices">https://docs.github.com/en/github/site-policy/global-privacy-practices</a></li>
                    <li>ORCID <a target="_blank" rel="noreferrer" href="https://orcid.org/privacy-policy">https://orcid.org/privacy-policy</a></li>
                </ol>

                <p>
                    <b>
                        <span>COOKIES</span>
                    </b>
                </p>

                <p>We analyse your IP and browser information to determine how we can improve the website and BC Platform experience for you.</p>
                <p>For more information, please see our Global Cookie Policy.</p>


                <p>
                    <b>
                        <span>DATA SECURITY</span>
                    </b>
                </p>

                <p>We take data security extremely seriously on the BRAINCommons website and the BC Platform. We comply with health sector industry-standard practices and technologies to safeguard your Personal Data. We also employ several different security techniques to protect your Personal Data from unauthorized access by users inside and outside the BC.</p>
                <p>The web servers for the BC are located in a locked, secure environment, and computer systems are maintained in accordance with industry standards to secure information. You should be aware, however, that "perfect security" does not exist on the web, and third parties may unlawfully intercept or access transmissions or private communications.</p>
                <p>Consistent with regulations of the United States and European Union, we take appropriate technical and organisational/administrative precautions to keep your Personal Data and privacy secure against security breaches. However, there is no guarantee that your Personal Data is completely safe from breach of our computer systems and servers. In the event of a Data Breach, we will do everything to mitigate the risk to your Personal Data and security, as well as reduce the risk of further harm, damage or distress and comply with our Data Controller obligations in accordance with applicable data protection laws and regulations in the US, European Union and other countries.</p>

                <p>
                    <b>
                        <span>RETENTION OF PERSONAL DATA </span>
                    </b>
                </p>

                <p>As a medical research corporation, we retain Personal Data, De-identified Personal Data as well as details of our employees, partners, affiliates and third-party suppliers. Given the nature of our medical research, we are required by laws and regulations relevant in the jurisdictions in which we operate to retain records for several purposes that include:</p>
                <ul>
                    <li>complying with laws in the territories in which we operate;</li>
                    <li>complying with Government regulations and standards;</li>
                    <li>enabling us to assess the quality of our medical research and services; and</li>
                    <li>ensuring numerous records are created as an effective part of managing our operations.</li>
                </ul>

                <p>Certain records must be maintained for continued use or as specified by applicable laws or contract requirements in the territories in which we operate. Other records have expired their usefulness and need to be destroyed.</p>
                
                <p>These are the 5 key objectives in our records retention policy:</p>

                <ol>
                    <li>Facilitating retrieval of Personal Data when necessary to provide access to our website and BC Platform and our services; </li>
                    <li>Ensuring that certain records are retained in accordance with laws and regulations (including any applicable statute of limitation) within the countries we operate and this is kept under constant review; </li>
                    <li>Preserving documents involved in pending, threatened or anticipated judicial or regulatory proceedings or investigations (both criminal and civil); </li>
                    <li>Providing documentation and evidence in support of services rendered; and</li>
                    <li>Providing the basis for the routine destruction of documents (virtual and physical) in order to save storage space and costs as well as to eliminate unnecessary inventories of documents outside of any contractual, legal and regulatory obligations.</li>
                </ol>

                <p>We achieve these records retention objectives by applying the following principles:</p>

                <ul>
                    <li>We adopt reasonable policies and procedures in records retention.</li>
                    <li>We keep our document retention policy (DRP) under constant review to ensure that it’s realistic, practical and appropriate for our needs and requirements.</li>
                    <li>We incorporate procedures to avoid the unnecessary retention of all electronic information (principle of data minimization).</li>
                    <li>We take a comprehensive approach to the data lifecycle management for the Processing of all types of Personal Data, Protected Health Information (PHI) and De-identified Personal Data.</li>
                </ul>

                <p>
                    <b>
                        <span>CONTACT US </span>
                    </b>
                </p>

                <p>We hope this BRAINCommons Global Privacy Policy and related documents have been helpful in explaining your rights as well as setting out in detail our approach to privacy, data protection, and security on a global basis.</p>
                <p>If you’ve any questions that haven’t been covered, please</p>
                <p>Email us at <a href="mailto:notice@braincommons.org">notice@braincommons.org</a>.</p>
                <p>Or write to us at: </p>
                <p>BRAINCommons LLC</p>
                <p>535 8<sup>th</sup> Ave, 12<sup>th</sup> Floor, New York, NY 10018, USA.</p>
                <p>Attn: Global Privacy Advisor </p>
                <p>All notices received will be triaged for appropriate action and response. Our BC Executive Director will be pleased to help you.</p>


            </div>





        </Modal> </>
    )
}

export default PrivacyNotice;
