import React, { useState, useEffect } from 'react'
import SectionHeader from '../../commons/section-header'
import classes from './index.module.css'
import { Grid } from '@mui/material'
import { navigate } from '@reach/router';
import DropDown from '../../commons/dropdown';
import ImageWorkspace from '../../../assets/icons/024-book-1.svg';
import DocIcon from '../../../assets/images/doc_icon.svg'
import {
    submitWorkspaceCartForApproval, getAllWorkspaceCarts, updateWorkspaceCart
} from '../../../api/workspace-api/workspace-api';
import Store from '../../../redux/store';
import { setAllWorkspacesCarts } from '../../../redux/actions/workspaceActions'
import isEmpty from 'lodash/isEmpty';
import CartEmpty from '../../../assets/images/cartEmpty.svg';
import { v4 } from 'uuid';
import DeleteIcon from '../../../assets/images/deleteIcon.svg';
import {formatBytes} from '../../../utils/file-size-formatter';
const CartList = (props) => {
    const [isDeleteLoading, setIsDeleteLoading ] = useState(false);
    const [isLoading, setIsLoading ] = useState(true);
    const [activeCarts, setActiveCarts] = useState([])
    const [workspaceValues, setWorkspaceValues] = useState([]);
    const [selectedWorkspace, setSelectedWorkspace] = useState('')
    const [selectedCart, setSelectedCart] = useState({})
    const [fileSize, setFileSize] = useState('');
    const [submitWorkspaceCartForApprovalInProgress, setSubmitWorkspaceCartForApprovalInProgress] = useState(false);
    const sectionHeaderProps = {
        title: "My Cart"
    }

    useEffect(() => {
        let workspaceCarts = []
        let workspaces = []
        if(props.allWorkspacesCarts?.workspace_carts){
            for(let workspace in props.allWorkspacesCarts?.workspace_carts){
                if(props.allWorkspacesCarts.workspace_carts[workspace]?.length){
                    for(let order of props.allWorkspacesCarts.workspace_carts[workspace]){
                        if(order?.status?.toLowerCase() === "open"){
                            order["workspace_id"] = workspace
                            workspaceCarts.push(order)
                            workspaces.push(workspace)
                        }
                    }
                }
            }
            setActiveCarts(workspaceCarts)
            setWorkspaceValues(workspaces)
            setIsLoading(false)
        }
    }, [props.allWorkspacesCarts])

    useEffect(() => {
        if(props.id){
            onWorkspaceOptionChange(props.id)
        }else{
            setSelectedCart(activeCarts[0])
            setSelectedWorkspace(workspaceValues[0])
        }
    },[activeCarts])

    useEffect(() => {
        let size = 0;
        if(selectedCart?.files?.length){
            for(let workspace of selectedCart.files){
                size = size + workspace?.size
            }
        }
        setFileSize(size);
    },[selectedCart])

    const onWorkspaceOptionChange = async (value) => {
        for(let activeCart of activeCarts){
            if(activeCart.workspace_id === value){
                setSelectedCart(activeCart)
            }
        }
        setSelectedWorkspace(value);
    }

    const submitCartForApproval = async () => {
        try{
            setSubmitWorkspaceCartForApprovalInProgress(true)
            const response = await submitWorkspaceCartForApproval(selectedCart?.order_id);
            const allWorkspaceCarts = await getAllWorkspaceCarts();
            if(!isEmpty(allWorkspaceCarts)){
                Store.dispatch(setAllWorkspacesCarts(allWorkspaceCarts))
            }
            props.updateSnackBar("Cart submitted for approval successfully", "Success")
            navigate('/cart/success')
        }catch(error){
            console.log(error)
        }finally{
            setSubmitWorkspaceCartForApprovalInProgress(false)
        }
    }

    const removeFile = async (file) => {
        try{
            const body = {
                "workspace_id": selectedWorkspace,
                "action":"REMOVE",
                "files": [
                    {
                        "name": file.name,
                        "s3_version": file.s3_version,
                        "type": file.type,
                        "size": file.size
                    }
                ]
            }
            const response = await updateWorkspaceCart(body, selectedCart?.order_id);
            const allWorkspaceCarts = await getAllWorkspaceCarts();
            if(!isEmpty(allWorkspaceCarts)){
                Store.dispatch(setAllWorkspacesCarts(allWorkspaceCarts))
            }
            props.updateSnackBar("File removed successfully", "Success")
        }catch(error){
            props.updateSnackBar("Cannot delete file", "Error")
            console.log(error)
        }
    }

    const removeAllFiles = async () => {
        try{
            setIsDeleteLoading(true);
            const body = {
                "workspace_id": selectedWorkspace,
                "action":"REMOVE_ALL"
            }
            const response = await updateWorkspaceCart(body, selectedCart?.order_id);
            const allWorkspaceCarts = await getAllWorkspaceCarts();
            if(!isEmpty(allWorkspaceCarts)){
                Store.dispatch(setAllWorkspacesCarts(allWorkspaceCarts))
            }
            props.updateSnackBar("Files removed successfully", "Success")
        }catch(error){
            props.updateSnackBar("Cannot delete files", "Error")
            console.log(error)
        }finally{
            setIsDeleteLoading(false)
        }
    }

    const goToWorkspace = () => {
        navigate('/explore/tools/workspace')
    }

    return (
        <div className={!selectedCart?.files?.length ? classes.emptyCartContainer : ''}>
            <SectionHeader
                open={props.open}
                sectionHeaderProps={sectionHeaderProps}
            />
            <Grid container xs={12} className={classes.container}>
                <Grid item lg={12} style={{display:'flex'}}>
                    <div className={classes.title} >{activeCarts?.length} Active Carts</div>
                    
                    <div style={{width:'200px'}}>
                    <DropDown
                        values={workspaceValues}
                        handleFilterStateChange={onWorkspaceOptionChange}
                        selectedFilter={selectedWorkspace}
                        placeholder={'Select a workspace'}
                        customClass={classes.workspaceDropdown}
                    /></div>

                </Grid>
                {
                    selectedCart?.files?.length ?
                <Grid container lg={6} className={classes.subSection}>

                    <Grid item lg={8}>
                        <div className={classes.subtitle}>
                            {
                                selectedCart?.files?.length
                            } Files added | {formatBytes(fileSize)}
                        </div>
                    </Grid>
                    <Grid item lg={4}>
                        <button onClick={removeAllFiles}
                            id='cvb-cartList-removeAll'
                            disabled={isDeleteLoading}
                            className={`medium-size-button ${classes.removeAllButton}`} >
                            <img src={DeleteIcon} /> Remove All
                        </button>
                    </Grid>
                
                    <Grid item xs={11}>
                        {
                            selectedCart?.files?.map((file) => {
                                return <div key={v4()} className={classes.cardContainer}>
                                <div container className={classes.cardContentContainer}>
                                    <div className={classes.workspaceImageContainer}>
                                        <img src={ImageWorkspace} className={classes.WorkspaceImage} alt='Workspace' />
                                    </div>
                                    <div className={classes.cardContent}>
                                        <p id={`cvb-cartList-fileName`} className={classes.curiousText}>
                                            {file?.name} <br />
                                            <div className={classes.fileType}>{file?.type}</div>
                                        </p>
                                        
                                    </div>
                                    <div className={classes.cardContent}>{formatBytes(file?.size)}</div>
                                    <div onClick={() =>  {removeFile(file)}} className={classes.deleteIconContainer}>
                                        <img src={DeleteIcon} />
                                    </div>
                                </div>
                            </div>
                            })
                        }
                        
                    </Grid>
                </Grid> : null
                }
                {
                    !selectedCart?.files?.length && !isLoading ?
                <Grid item xs={12}>
                    <div id="cvb-cartSuccess-box" className={classes.emptyCartBox}>
                        
                        <Grid container>
                            <Grid lg={12}>
                                <img src={CartEmpty}  />
                            </Grid>
                        
                    
                            <Grid lg={12}>
                                <div id="cvb-cartSuccess-title" className={classes.successTitle}>
                                Your cart is empty!
                                    
                                </div>
        
                                <button onClick={goToWorkspace} className={`medium-size-button ${classes.addUserButton}`}>View WorkSpace to Add Files</button>
                            </Grid>
                        </Grid>
        
                    </div>
                
                </Grid>: null }
                
                {
                    selectedCart?.files?.length && !isLoading ?
                <Grid item lg={6} className={classes.rightSection}>
                    <div className={classes.rightBox}>
                        <img src={DocIcon} className={classes.docIcon} />
                        <div className={classes.content}>
                        Before being able to download your files, your cart must be approved by the BRAINCommons team to ensure that it does not contain confidential, sensitive or other files that should not be downloaded.<br /><br /> After submitting an order for approval to BRAINCommons, you can no longer make any changes. You can track the approval status from ‘My Stuff’.
                        </div>

                        <button disabled={submitWorkspaceCartForApprovalInProgress} onClick={submitCartForApproval} className={`medium-size-button ${classes.addUserButton}`}>Submit for Approval</button>
                    </div>
                </Grid> : null
                }           
            </Grid>
        </div>
    )
}

export default CartList;
