import React from 'react';
import Classes from './index.module.css';
import Grid from '@mui/material/Grid'
import DropDown from '../../commons/dropdown';

const AnalyzeAttributesSelectionCard = props => {

  const {
    updateField = () => { },
    xAxisValues = [],
    yAxisValues = [],
    currentState = {},
  } = props;

  const onXAxisChange = value => {
    updateField("xAxis", value, currentState);
  }

  const onYAxisChange = value => {
    updateField("yAxis", value, currentState);
  }

  const getAxisAliasing = (axisValues) => {
    let aliasing = {}
    for(let item of axisValues){
      aliasing[item.value] = item.label
    };
    return aliasing;
  }
  const selectedXValue = currentState?.xAxis?.toString().charAt(0).toUpperCase() + currentState?.xAxis?.toString().slice(1);
  const selectedYValue = currentState?.yAxis?.toString().charAt(0).toUpperCase() + currentState?.yAxis?.toString().slice(1);

  return (
    <div className={Classes.analyzeAttributeContainer}>
      <p className={Classes.cardTitles}>What variables would you like to analyze from the cohort?</p>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <label id='cvb-analyzeAttributesSelection-xAxis-label' htmlFor='xAxis'>X Axis</label>
          <DropDown
            values={xAxisValues.map(({ value }) => value)}
            handleFilterStateChange={onXAxisChange}
            selectedFilter={selectedXValue}
            placeholder={'Select'}
            labelAliasing={getAxisAliasing(xAxisValues)}
            parseText={true}
          />
        </Grid>
        <Grid item xs={12}>
          <label id='cvb-analyzeAttributesSelection-yAxis-label' htmlFor='yAxis'>Y Axis</label>
          <DropDown
            values={yAxisValues.map(({ value }) => value)}
            handleFilterStateChange={onYAxisChange}
            selectedFilter={selectedYValue}
            placeholder={'Select'}
            labelAliasing={getAxisAliasing(yAxisValues)}
            parseText
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default AnalyzeAttributesSelectionCard