/**
 *  External Imports
 */
import React, { useState, useRef, useEffect } from "react";
import { number, shape, string, arrayOf, func, bool } from "prop-types";
import ReactDOM from "react-dom";
import useOnclickOutside from "react-cool-onclickoutside";

import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip'

/**
 *  Internal Imports
 */
import {
    communityAPI,
    COMMUNITY_CONNECTION_STATUSES,
} from "../../../../api/community-api";
import { CONSTELLATION_USER_TYPE } from "../../../../constants/constellation";
import {
    COMMUNITY_URLS,
    COMMUNITY_CONNECTIONS_PARENT_ROUTE,
} from "../../../../constants/urls";
import deleteIcon from '../../../../assets/icons/delete-basket.svg';
import iconTooltip from "../../../../assets/icons/icon-info-h.svg";
import styles from "./index.module.css";

const TOOLTIP_WIDTH = 256;
const TOOLTIP_OFFSET = 16;

const CommunityConstellationGraphTooltip = (props) => {
    const { constellationTooltipParams, constellationTooltipOpen } = props;
    const {
        onConnectDialogOpen,
        onRecommendedConnectUser,
        onDeclineRecommendedUser,
        setTooltipParams,
        onMessageChatRedirect,
        onAcceptDialogOpen,
        onAcceptIncomingUser,
        onDeclineIncomingUser,
        onDeleteConnectedUser,
        onDeleteDialogOpen
    } = props;

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: "#FCF8C8",
            color: "#222222",
            boxShadow: "-1px 1px 106px -61px rgba(74,73,74,1);",
            fontSize: 14,
            fontWeight: "medium",
            marginRight: '3px',
            fontFamily: "Raleway",
            borderRadius: 0,
            padding: 15,
            textAlign: "left"
        },
        arrow: {
            color: "#FCF8C8"
        },
        customWidth: {
            maxWidth: 200,
        },
    }))(Tooltip);

    const { currentScreenWidth } = props;

    const [currentTooltipHeight, setCurrentTooltipHeight] = useState(0);

    const tooltipNode = useRef(null);

    const tooltipWrap = useOnclickOutside(
        () => {
            setTooltipParams(null);
        },
        { ignoreClass: "click-outside-ignore" }
    );

    const onItemConnectBtnClick = () => {
        const dialogConnectData = {
            userIcon: constellationTooltipParams.userIcon,
            organization: constellationTooltipParams.userOrganization,
            reason: "",
            name: constellationTooltipParams.userName,
            id: constellationTooltipParams.userId,
            onConnectUser: onRecommendedConnectUser,
            connectMethod: communityAPI.postRecommendationByID,
        };
        onConnectDialogOpen(dialogConnectData);
    };

    const onItemDeclineBtnClick = async (
        onDeclineUserMethod,
        declineMethod
    ) => {
        const requestParams = {
            id: constellationTooltipParams.userId,
            actionStatus: COMMUNITY_CONNECTION_STATUSES.DECLINE,
        };
        const { status } = await declineMethod(requestParams);

        // Checking a response status
        if (status === 200) {
            onDeclineUserMethod(constellationTooltipParams.userId);
        }
    };

    const onItemDeleteBtnClick = () => {
        const dialogDeleteData = {
            id: constellationTooltipParams.connectionId,
            userIcon: constellationTooltipParams.userIcon,
            name: constellationTooltipParams.userName,
            organization: constellationTooltipParams.userOrganization,
            deleteMethod: communityAPI.deleteAcceptedConnection,
            onDeleteUser: onDeleteConnectedUser
        }

        onDeleteDialogOpen(dialogDeleteData);
    }

    const navigateToExactChat = (id) => () => {
        setTooltipParams(null);
        onMessageChatRedirect(
            `${COMMUNITY_CONNECTIONS_PARENT_ROUTE}${COMMUNITY_URLS.MESSAGES}`,
            { userChatId: id }
        );
    };

    const onUserAcceptBtnClick = () => {
        const dialogSuccessData = {
            id: constellationTooltipParams.userId,
            userIcon: constellationTooltipParams.userIcon,
            name: constellationTooltipParams.userName,
            email: constellationTooltipParams.userEmail,
            connectMethod: communityAPI.postConnectionStatusByID,
            onSuccessEvent: onAcceptIncomingUser,
        };

        onAcceptDialogOpen(dialogSuccessData);
    };

    const getUserWeight = (weight) => {
        return Math.round(weight * 100) / 100;
    };

    useEffect(() => {
        if (tooltipNode?.current && constellationTooltipParams.userId !== -1) {
            setCurrentTooltipHeight(tooltipNode?.current.getBoundingClientRect().height);
        }
    }, [constellationTooltipParams]);

    return constellationTooltipOpen
        ? ReactDOM.createPortal(
              <div ref={tooltipWrap} data-testid="constellationTooltip">
                  <div
                      ref={tooltipNode}
                      className={
                        constellationTooltipParams.isOpposite
                              ? styles.oppositeTooltip
                              : styles.tooltip
                      }
                      style={{
                          transform: `translateX(${
                              document.body.getBoundingClientRect().width -
                              currentScreenWidth +
                              constellationTooltipParams.x -
                              TOOLTIP_WIDTH / 2
                          }px) translateY(${
                            constellationTooltipParams.isOpposite
                                  ? constellationTooltipParams.y +
                                    constellationTooltipParams.nodeSize +
                                    TOOLTIP_OFFSET
                                  : constellationTooltipParams.y -
                                    currentTooltipHeight -
                                    constellationTooltipParams.nodeSize -
                                    TOOLTIP_OFFSET
                          }px)`,
                      }}>
                      <div className={styles.tooltipContent}>
                          <img data-testid="constellationTooltipIcon"
                              className={styles.tooltipImg}
                              src={constellationTooltipParams.userIcon}
                              alt="User Icon"
                          />
                          <div className={styles.tooltipInfo}>
                              <p className={styles.tooltipText} data-testid="constellationTooltipUserName">
                                  {constellationTooltipParams.userName}
                              </p>
                              <p className={styles.tooltipHint} data-testid="constellationTooltipUserOrganization">
                                  {constellationTooltipParams.userOrganization}
                              </p>
                              <div className={styles.tooltipWeight}>
                                  <span className={styles.tooltipWeightText}>
                                      Weight:{" "}
                                      {getUserWeight(
                                          constellationTooltipParams.userWeight *
                                              100
                                      )}
                                  </span>
                                  <LightTooltip placement="right" arrow title="Weight of recommendation is counted as percentage of matches to the total number of parameters for comparison.">
                                        <img className={styles.userTooltipIcon} src={iconTooltip} alt="info icon" />
                                    </LightTooltip>
                              </div>
                          </div>
                      </div>
                      {constellationTooltipParams.userConnections &&
                          constellationTooltipParams.userConnections.length !==
                              0 && (
                              <div className={styles.tooltipContent}>
                                  <div className={styles.tooltipInfo}>
                                      <p className={styles.tooltipTitle}>
                                          Matches
                                      </p>
                                      <ul className={styles.tooltipList}>
                                          {constellationTooltipParams.userConnections.map(
                                              (connection) => (
                                                  <li data-testid="constellationTooltipUserMatches" 
                                                      className={
                                                          styles.tooltipListItem
                                                      }
                                                      key={connection}>
                                                      {connection}
                                                  </li>
                                              )
                                          )}
                                      </ul>
                                  </div>
                              </div>
                          )}
                      <div className={styles.tooltipControls}>
                          {constellationTooltipParams.userType ===
                              CONSTELLATION_USER_TYPE.RECOMMENDED && (
                              <>
                                  {!constellationTooltipParams.userStatus &&
                                      (constellationTooltipParams.isIncomingConnection ? (
                                          <>
                                              <button data-testid="constellationTooltipDeclineIncomingBtn"
                                                  className={styles.declineBtn}
                                                  onClick={() =>
                                                      onItemDeclineBtnClick(
                                                          onDeclineIncomingUser,
                                                          communityAPI.postConnectionStatusByID
                                                      )
                                                  }>
                                                  Decline
                                              </button>
                                              <button data-testid="constellationTooltipAcceptBtn"
                                                  className={styles.connectBtn}
                                                  onClick={
                                                      onUserAcceptBtnClick
                                                  }>
                                                  Accept
                                              </button>
                                          </>
                                      ) : (
                                          <>
                                              <button data-testid="constellationTooltipDeclineBtn" 
                                                  className={styles.declineBtn}
                                                  onClick={() =>
                                                      onItemDeclineBtnClick(
                                                          onDeclineRecommendedUser,
                                                          communityAPI.postRecommendationByID
                                                      )
                                                  }>
                                                  Ignore for now
                                              </button>
                                              <button data-testid="constellationTooltipConnectBtn" 
                                                  className={styles.connectBtn}
                                                  onClick={
                                                      onItemConnectBtnClick
                                                  }>
                                                  Connect
                                              </button>
                                          </>
                                      ))}
                                  {constellationTooltipParams.userStatus && (
                                      <p className={styles.tooltipStatus} data-testid="constellationTooltipStatus">
                                          {
                                              constellationTooltipParams.userStatus
                                          }
                                      </p>
                                  )}
                              </>
                          )}
                          {constellationTooltipParams.userType ===
                              CONSTELLATION_USER_TYPE.CONNECTED && (
                                <>
                                <button 
                                    data-testid="constellationTooltipMessageBtn"
                                    className={styles.messageBtn}
                                    onClick={navigateToExactChat(
                                      constellationTooltipParams.userId
                                    )}>
                                  Message
                                </button>
                                 <button
                                    data-testid="constellationTooltipDeleteBtn"
                                    className={styles.deleteBtn}
                                    onClick={onItemDeleteBtnClick}>
                                    <img src={deleteIcon} alt="Delete basket"/>
                                </button>
                              </>
                          )}
                        </div>
                    </div>
                </div>,
              document.body
          )
        : null;
};

CommunityConstellationGraphTooltip.propTypes = {
    constellationTooltipParams: shape({
        x: number.isRequired,
        y: number.isRequired,
        userId: number.isRequired,
        userIcon: string.isRequired,
        userName: string.isRequired,
        userOrganization: string.isRequired,
        userConnections: arrayOf(string),
        userStatus: string,
        userWeight: number.isRequired,
        nodeSize: number.isRequired,
        userType: string.isRequired,
        userEmail: string,
        isIncomingConnection: bool,
        isOpposite: bool.isRequired
    }),

    currentScreenWidth: number.isRequired,
    constellationTooltipOpen: bool.isRequired,

    onDeclineRecommendedUser: func.isRequired,
    onRecommendedConnectUser: func.isRequired,
    onConnectDialogOpen: func.isRequired,
    onAcceptDialogOpen: func.isRequired,
    onDeclineIncomingUser: func.isRequired,
    toggleTooltipOpen: func.isRequired,
    onMessageChatRedirect: func.isRequired,
    onAcceptIncomingUser: func.isRequired
};

export default CommunityConstellationGraphTooltip;
