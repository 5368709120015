import React, { useState, useMemo, useEffect } from 'react';
import { navigate } from "@reach/router"
import Grid from '@mui/material/Grid'
import classes from './index.module.css'
import BackButtonIcon from '../../../assets/icons/left_arrow.png'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '../../../assets/icons/search-icon.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SuccessIcon from '../../../assets/icons/success.svg';
import disabledIcon from '../../../assets/icons/disabled.svg';
import TotalRecordsIcon from '../../../assets/icons/agenda.svg';
import Checkbox from '@mui/material/Checkbox'
import TablePagination from '../../commons/table-pagination'
import { v4 } from 'uuid'

function ViewStructuredDataStatus(props) {
    let { affectedRecords, failureRecords: failureRecordsProps = [], isUnStructuredData = false, clearMapping = () => { } } = props

    const [searchText, setSearchText] = useState("")
    const [pageNumber, setPageNumber] = useState(0)
    const [isAllSelected, setIsAllSelected] = useState(false)
    const [failureRecords, setFailureRecords] = useState([])

    useEffect(() => {
        const records = failureRecordsProps.map(item => {
            return {...item, isUnchecked: true}
        })
        setFailureRecords(records)
    }, [failureRecordsProps]);

    const filteredFailureRecords = useMemo(() => {
        setPageNumber(0)
        if (failureRecords?.length > 0) {
            return failureRecords.filter(item => item?.id?.toLowerCase()?.includes(searchText.toLowerCase()))
        }
        return []
    }, [failureRecords, searchText])

    const recordsPerPage = useMemo(() => {
        if (filteredFailureRecords?.length) {
            let showingRecords = []
            for (let i = (pageNumber * 20); i < (pageNumber + 1) * 20; i++) {
                if (filteredFailureRecords[i])
                    showingRecords.push(filteredFailureRecords[i])
            }
            return showingRecords;
        }
        return []
    }, [filteredFailureRecords, pageNumber])

    const maxCount = filteredFailureRecords.length ? Math.ceil(filteredFailureRecords.length / 20) : 0

    const toggleCheck = checkedItem => {
        const cloneData = [...failureRecords]
        const foundElement = cloneData.find(item => item.id === checkedItem.id);
        foundElement.isUnchecked = !foundElement.isUnchecked;
        setFailureRecords(cloneData);
        const foundUncheckedElement = cloneData.find(item => item.isUnchecked);
        setIsAllSelected(!foundUncheckedElement)
    }

    const markAllChecked = () => {
        setIsAllSelected(!isAllSelected);
        const cloneData = failureRecords.map(item => {
            return { ...item, isUnchecked: isAllSelected }
        })
        setFailureRecords(cloneData)
    }

    const downloadCSVFromJson = (filename, arrayOfJson) => {
        // convert JSON to CSV
        const replacer = (key, value) => value === null ? '' : value
        const header = Object.keys(arrayOfJson[0])
        let csv = arrayOfJson.map(row => header.map(fieldName =>
            JSON.stringify(row[fieldName], replacer)).join(','))
        csv.unshift(header.join(','))
        csv = csv.join('\r\n')

        // Create link and download
        var link = document.createElement('a');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const exportRecords = () => {
        const checkedItems = failureRecords.map(item => {
            if (!item.isUnchecked) {
                return {
                    id: item.id,
                    uploadStatus: item.isSuccess ? 'passed' : "failed",
                    reason: item.errors?.[0]?.message || (item.errorMessage || '')
                }
            }
        }).filter(item => item)
        downloadCSVFromJson(new Date() + ".csv", checkedItems)
    }

    const handleBackClick = () => {
        if (isUnStructuredData) {
            clearMapping();
        }
        else {
            navigate("/my-uploads/structured-data/upload-data")
        }
    }

    const setTablePage = number => {
        setPageNumber(number)
    }
    return (
        <div className={classes.innerContainer}>
            <Grid container className={classes.searchBackground}>
                <Grid item xs={12} lg={12} md={12}>
                    <div className={classes.sectionHeaderContainer}>
                        <p onClick={() => { handleBackClick() }} className={classes.backButton} id='cvb-viewStructuredDataStatus-backButton'>
                            <img src={BackButtonIcon} alt="black button" /> Back</p>
                        <div className={classes.gridTitle}><h2 id='cvb-viewStructuredDataStatus-uploadStructuredHeading'>Upload Structured Data</h2></div>
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={8} lg={8} md={8}>
                    <div className={classes.uploadStatsContainer}>
                        <div className={classes.gridTitleSuccess}><h2 id='cvb-viewStructuredDataStatus-uploadSuccessfulHeading'>Upload Successful</h2></div>
                        <Grid container spacing={3} >
                            <Grid item xs={4} lg={4} md={4} className={classes.uploadStatsItem}>
                                <div className={classes.totalRecords}>
                                    <p id='cvb-viewStructuredDataStatus-totalRecordsHeading'><img src={TotalRecordsIcon} alt="Success Icon" className={classes.uploadStatsItemIcon} /> Total Records</p>
                                    <h3 id='cvb-viewStructuredDataStatus-totalRecordsCount'>{affectedRecords?.totalEntries || 0}</h3>
                                </div>
                            </Grid>
                            <Grid item xs={4} lg={4} md={4} className={classes.uploadStatsItem}>
                                <div className={classes.successfulRecords}>
                                    <p id='cvb-viewStructuredDataStatus-totalSuccessfulHeading'> <img src={SuccessIcon} alt="Success Icon" className={classes.uploadStatsItemIcon} /> Successful</p>
                                    <h3 id='cvb-viewStructuredDataStatus-totalSuccessfulCount'>{affectedRecords?.successfulEntries || 0}</h3>
                                </div>
                            </Grid>
                            <Grid item xs={4} lg={4} md={4} className={classes.uploadStatsItem}>
                                <div className={classes.failureRecords}>
                                    <p id='cvb-viewStructuredDataStatus-totalFailedHeading'> <img src={disabledIcon} alt="Success Icon" className={classes.uploadStatsItemIcon} /> Failed</p>
                                    <h3 id='cvb-viewStructuredDataStatus-totalFailedCount'>{affectedRecords?.failedEntries || 0}</h3>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            {
                failureRecords?.length > 0 &&
                <Grid container >
                    <Grid item xs={8} lg={8} md={8} className={classes.uploadTableContainer}>
                        <div className={classes.uploadTableContainerInner}>
                            <h4 className={classes.tableHeaderMain} id='cvb-viewStructuredDataStatus-filesHeadingTable'>Files</h4>
                            <div className={classes.sectionTableHeader}>
                                <div className={classes.sectionLeftContainer}>
                                    <p className={classes.recordsStats} id='cvb-viewStructuredDataStatus-showingText'>Showing {(pageNumber * 20) + 1} - {(pageNumber + 1) * 20 < filteredFailureRecords.length ? (pageNumber + 1) * 20 : filteredFailureRecords.length} of {filteredFailureRecords.length}</p>
                                </div>
                                <div className={classes.sectionRightContainer}>
                                    <ul>
                                        <li>
                                            <OutlinedInput fullWidth type='text'
                                                value={searchText}
                                                onChange={e => setSearchText(e.target.value)}
                                                placeholder='Search'
                                                id='cvb-viewStructuredDataStatus-searchRecordsInput'
                                                className='signup-inputs-large' name='password'
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton aria-label="toggle password visibility" size="large">
                                                            <img alt='visibility On' src={SearchIcon} className={classes.exploreSearchIcon} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </li>
                                        <li>
                                            <button className={`medium-size-button solid-button ${classes.exportFailureButton}`}
                                                id='cvb-viewStructuredDataStatus-exportFailureButton'
                                                onClick={exportRecords} >{affectedRecords?.successfulEntries ? 'Export Reasons' : 'Export Failure Reasons'}</button>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={classes.selectProjectTableContainer}>
                                <TableContainer
                                    component={Paper}
                                    elevation={0}
                                    id='cvb-viewStructuredDataStatus-failureReasonsTableContainer'
                                    className={classes.tableContainer}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead id='cvb-viewStructuredDataStatus-failureReasonsTableHeader'>
                                            <TableRow>
                                                <TableCell width="35%" align="left" id='cvb-viewStructuredDataStatus-checkboxIdentifier'>
                                                    {/* <input checked={isAllSelected} type="checkbox" onChange={markAllChecked} /> */}
                                                    <span className={classes.checkboxIdentifiers}>
                                                        <Checkbox
                                                            checked={isAllSelected}
                                                            color="primary"
                                                            onChange={markAllChecked}
                                                            id='uploadCheck'

                                                        />
                                                    </span>
                                                    Identifier
                                                </TableCell>
                                                <TableCell width="10%" align="center" id='cvb-viewStructuredDataStatus-uploadStatusHeading'>
                                                    Upload Status
                                                </TableCell>
                                                <TableCell width="50%" align="left" id='cvb-viewStructuredDataStatus-failureReasonHeading'>
                                                    Failure Reason
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                recordsPerPage?.map((item, index) => {
                                                    const errorMessage = item?.errors?.[0].message || item?.errorMessage
                                                    return <TableRow key={v4()} >
                                                        <TableCell align="left" className={classes.selectProjectTableCell}>
                                                            <span className={classes.checkboxIdentifiers}>
                                                                <Checkbox
                                                                    checked={!item.isUnchecked}
                                                                    color="primary"
                                                                    onChange={() => toggleCheck(item)}
                                                                    id='cvb-viewStructuredDataStatus-failureReasonCellCheckbox'
                                                                />
                                                            </span>
                                                            {item?.id || ''}</TableCell>
                                                        <TableCell align="right" className={classes.selectProjectTableCell}>
                                                            <p id='cvb-viewStructuredDataStatus-failureReasonRowStatus' className={item.isSuccess ? classes.successStatus : classes.failureStatus}>
                                                                {item.isSuccess ? "PASSED" : "FAILED"}
                                                            </p>
                                                        </TableCell>
                                                        <TableCell id='cvb-viewStructuredDataStatus-failureReasonRowErrorMessage' align="left" className={classes.selectProjectTableCell}>{errorMessage || ''}</TableCell>
                                                    </TableRow>
                                                })
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                        <div className={classes.tablePagination} id='cvb-viewStructuredDataStatus-paginationContainer'>
                            <TablePagination
                                maxCount={maxCount}
                                page={pageNumber}
                                setPage={setTablePage}
                            />
                        </div>
                    </Grid>
                </Grid>
            }
        </div>
    );
}

export default ViewStructuredDataStatus;
