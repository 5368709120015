import React, { useEffect, useState } from 'react'
import Classes from './index.module.css';
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import map from 'lodash/map'
import get from 'lodash/get'
import ClosePng from '../../../assets/images/close.png'
import isEmpty from 'lodash/isEmpty'
import ErrorText from '../error-text';
import { validateEmailAddress } from '../../../utils/input-validations'
import { DCA_FORM_COMPLETED_TEXT } from '../../../constants/strings';
const Zone3AuthorizedUser = props => {
    const {
        zone3Users,
        projectDetailsDesignatedZone,
        dcaStage,
        currentDCAFormStage,
        updateZone3UsersFirstName,
        updateZone3UsersMiddleName,
        updateZone3UsersLastName,
        updateZone3UsersAffiliation,
        updateZone3UsersInstitutionalEmail,
        addZone3Users,
        removeZone3User,
        setDCAStage
    } = props;
    const [ addUserClick, setAddUsersClick ] = useState(false);
    const disableTextInput =  (currentDCAFormStage === DCA_FORM_COMPLETED_TEXT);
    useEffect(() => {
        document.getElementById('cvb-dataContributorAgreement-submitButton').addEventListener('click', () => {
            setAddUsersClick(true);
        })
    }, [])

    const onFirstNameChange = (event, id) => {
        updateZone3UsersFirstName(event.target.value, id);
    }
    const onMiddleNameChange = (event, id) => {
        updateZone3UsersMiddleName(event.target.value, id);
    }
    const onLastNameChange = (event, id) => {
        updateZone3UsersLastName(event.target.value, id);
    }
    const onAffiliationChange = (event, id) => {
        updateZone3UsersAffiliation(event.target.value, id);
    }
    const onInstitutionalEmailChange = (event, id) => {
        updateZone3UsersInstitutionalEmail(event.target.value, id);
    }
    const removeUser = (id) => {
        if(!disableTextInput){
        removeZone3User(id);
        }
    }
    const validUsersData = () => {
        let isFormValid = false;
        if (!isEmpty(zone3Users)) {
            map(zone3Users, (usersData) => {
                for (let key in usersData) {
                    const data = usersData[key];
                    const isEmptyField = (data === null && key !== 'middleName') ? false : (key !== 'middleName' ? !isEmpty(data) : true);
                    const isEmailValid = key === 'institutionalEmail' ? validateEmailAddress(usersData[key]) : true;
                    if (isEmptyField && isEmailValid) {
                        isFormValid = true;
                    } else {
                        isFormValid = false;
                        break;
                    }
                }
            })
        } else {
            isFormValid = true;
        }
        return isFormValid;
    }

    const onAddUserClick = () => {
        if (!disableTextInput) {
            setAddUsersClick(true);
            const isValidUserData = validUsersData();
            if (isValidUserData) {
                addZone3Users({
                    "firstName": '',
                    "middleName": '',
                    "lastName": '',
                    "affiliation": '',
                    "institutionalEmail": ''
                });
                setAddUsersClick(false);
            }
        }
    }
    const onSkipClick = () => {
        setDCAStage(dcaStage+1);
    }
    const sectionTitle = projectDetailsDesignatedZone !== 'zone3' ? 'Zone 3 Authorized Users (Optional)' : 'Zone 3 Authorized Users';
    
    return (
        <div className={Classes.container} id='cvb-dca-stage3ZoneAuthorizationContainer'>
            <p className={Classes.formTitle}>{sectionTitle}</p>
            {projectDetailsDesignatedZone !== 'zone3' && <button id='cvb-dca-stage3ZoneAuthorizationSkipButton' onClick={onSkipClick} className={`small-size-button transparent-button ${Classes.skipButton}`}>Skip</button>}
            {map(zone3Users, (users, id) => {
                return (
                    <div key={`usersDiv${id}`}>
                        {!disableTextInput && id > 0 && <div>
                            <img id='cvb-dca-stage3ZoneAuthorizationRemoveUserButton' src={ClosePng} alt='delete' className={Classes.removeFields} onClick={() => removeUser(id)} />
                        </div>
                        }
                        <Grid container className={Classes.inputContainers} spacing={3}>
                            <Grid item xs={4} className={Classes.fieldCont}>
                                <label htmlFor='firstName' id='cvb-dca-stage3ZoneAuthorizationFirstNameLabel'>First Name<sup> *</sup></label>
                                <OutlinedInput
                                    type="text"
                                    placeholder="Enter first name"
                                    value={get(users, 'firstName', '')}
                                    onChange={(e)=> onFirstNameChange(e, id)}
                                    fullWidth
                                    id={`firstName${id}`}
                                    key={`firstName${id}`}
                                    disabled={disableTextInput}
                                />
                                {
                                    (addUserClick) && (users['firstName'] === null || isEmpty(`${get(users, 'firstName', '')}`)) &&
                                    <ErrorText errorMessage="Enter name" />
                                }
                            </Grid>
                            <Grid item xs={4} className={Classes.fieldCont}>
                                <label htmlFor='firstName' id='cvb-dca-stage3ZoneAuthorizationMiddleNameLabel'>Middle Name</label>
                                <OutlinedInput
                                    type="text"
                                    placeholder="Enter middle name"
                                    value={get(users, 'middleName', '')}
                                    onChange={e => onMiddleNameChange(e, id)}
                                    fullWidth
                                    id={`middleName${id}`}
                                    key={`middleName${id}`}
                                    disabled={disableTextInput}
                                />
                            </Grid>
                            <Grid item xs={4} className={Classes.fieldCont}>
                                <label htmlFor='firstName' id='cvb-dca-stage3ZoneAuthorizationLastNameLabel'>Last Name<sup> *</sup></label>
                                <OutlinedInput
                                    type="text"
                                    placeholder="Enter last name"
                                    value={get(users, 'lastName', '')}
                                    onChange={e => onLastNameChange(e, id)}
                                    fullWidth
                                    id={`lastName${id}`}
                                    key={`lastName${id}`}
                                    disabled={disableTextInput}
                                />
                                {
                                    (addUserClick) &&(users['lastName'] === null || isEmpty(`${get(users, 'lastName', '')}`)) &&
                                    <ErrorText errorMessage="Enter last name" />
                                }
                            </Grid>
                        </Grid>
                        <Grid container className={Classes.inputContainers} spacing={3}>
                            <Grid item xs={4} className={Classes.fieldCont}>
                                <label htmlFor='affiliation' id='cvb-dca-stage3ZoneAuthorizationInstitutionNameLabel'>Institution<sup> *</sup></label>
                                <OutlinedInput
                                    type="text"
                                    placeholder="Enter institute"
                                    value={get(users, 'affiliation', '')}
                                    onChange={e => onAffiliationChange(e, id)}
                                    fullWidth
                                    id={`affiliation${id}`}
                                    key={`affiliation${id}`}
                                    disabled={disableTextInput}
                                />
                                {
                                    (addUserClick) && (users['affiliation'] === null ||  isEmpty(`${get(users, 'affiliation', '')}`)) &&
                                    <ErrorText errorMessage="Enter institution " />
                                }
                            </Grid>
                            <Grid item xs={4} className={Classes.fieldCont}>
                                <label htmlFor='institutionalEmail' id='cvb-dca-stage3ZoneAuthorizationIntitutionalEmailLabel'>Institutional Email<sup> *</sup></label>
                                <OutlinedInput
                                    type="text"
                                    placeholder="Enter email"
                                    value={get(users, 'institutionalEmail', '')}
                                    onChange={e => onInstitutionalEmailChange(e, id)}
                                    fullWidth
                                    id={`institutionalEmail${id}`}
                                    key={`institutionalEmail${id}`}
                                    disabled={disableTextInput}
                                />
                                {
                                    (addUserClick) && (users['institutionalEmail'] === null || !validateEmailAddress(`${get(users, 'institutionalEmail', '')}`)) &&
                                    <ErrorText errorMessage="Enter valid email" />
                                }
                            </Grid>
                        </Grid>
                        <div className={Classes.dottedLine}></div>
                    </div>
                )
            })}
           {!disableTextInput && <p id='cvb-dca-stage3ZoneAuthorizationAddMoreLink' className={Classes.addMoreFields}  onClick={onAddUserClick}>+ Add More</p>}
        </div>
    )
}
Zone3AuthorizedUser.defaultProps = {
    zone3Users : [],
    projectDetailsDesignatedZone: '',
    dcaStage: 1,
    isFormSubmitted: false,
    currentDCAFormStage: '',
    updateZone3UsersFirstName : () => { },
    updateZone3UsersMiddleName : () => {},
    updateZone3UsersLastName : () => {},
    updateZone3UsersAffiliation : () => {},
    updateZone3UsersInstitutionalEmail : () => {},
    addZone3Users : () => {},
    removeZone3User : () => {},
    setDCAStage: () => { }
};

export default Zone3AuthorizedUser;