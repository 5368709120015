import { capitalize, get, isDate, isEmpty, lowerCase, map } from 'lodash';
import React, { useState } from 'react'
import * as moment from 'moment'
import classes from './index.module.css'
import Grid from '@mui/material/Grid'
import CompletedStepPNG from '../../../assets/images/completedStep.png'
import MuiToolTip from '@mui/material/Tooltip'
import DefaultProfile from '../../../assets/images/user/default-profile-image.png'
import { v4 } from 'uuid'
import DCAFormStepper from '../../controls/dca-form-stepper';
import { DIF_FORM_STEPS, INSTITUTE_APPROVAL_PENDING_TEXT } from '../../../constants/strings';
import ViewBlueIcon from '../../../assets/icons/view.svg'
import { navigate } from '@reach/router';
import { downloadDIFForm } from '../../../api/dca-form-api';
import DownloadBlueIcon from '../../../assets/icons/download.svg'
import { getSubmittedDUP, getUserDocumentURLForDownload } from '../../../api/profile-api';
import { getCertificates, getCertificateUrl } from '../../../api/certificate-api';
import { approveWorkspaceAccessRequest, approveZone2AccessRequest, approveZone3AccessRequest, getDownloadURLForServiceAgreement, getPresignedURLForServiceAgreement } from '../../../api/admin/admin-dashboard';
import Modal from '../../commons/modal'
import ViewDataForm from '../../controls/view-data-forms'
import ViewInstitutionCertificateForm from '../../controls/view-institution-certificate-form'
import { sendGAEvents } from '../../../api/generic-api';
import ErrorText from '../../controls/error-text';
import { uploadFileToS3 } from '../../../utils/aws-sdk-helper';
import DropZone from 'react-dropzone';
import DocumentIcon from '../../../assets/images/Doc.M.png';
import CommonDatePicker from '../../commons/common-date-picker';
import { getServiceAgreementDetails } from '../../../api/workspace-api/workspace-api';
import OutlinedInput from '@mui/material/OutlinedInput';
import { validateEmailAddress } from '../../../utils/input-validations';
import RequestAdvancedComputeDetailsView from '../../commons/request-advanced-compute-details-view';

const PendingApprovalsStepper = (props) => {
    const { setDifDataSharingAssociatedEmail = () => { },
     updateSnackBar = ()=>{},
     getDatasetAccessRequests = () => { },
     getZone3AccessRequests = () => { },
     getWorkspaceAccessRequest = () => { }
    } = props;
    const { setDifStage = () => { } } = props;
    const [viewDataForm, setViewDataForm] = useState(false);
    const [dataPolicyData, setDataPolicyData] = useState({});
    const [viewInstitutionCertificateForm, setViewInstitutionCertificateForm] = useState(false);
    const [institutionCertificateData, setInstitutionCertificateData] = useState({});
    const [openServiceAgreementModal, setOpenServiceAgreementModal] = useState(false);
    const [openZone2ApproveModal, setOpenZone2ApproveModal] = useState(false);
    const [currentZone2RequestData, setCurrentZone2RequestData] = useState({});
    const [openZone3ApproveModal, setOpenZone3ApproveModal] = useState(false);
    const [currentZone3RequestData, setCurrentZone3RequestData] = useState({});
    const [openWorkspaceApproveModal, setOpenWorkspaceApproveModal] = useState(false);
    const [currentWorkspaceAccessRequestData, setCurrentWorkspaceAccessRequestData] = useState({});
    const [expiryDateError, setExpiryDateError] = useState(false);
    const [zone2RequestExpiryDate, setZone2RequestExpiryDate] = useState('');
    const [zone2Type, setZone2Type] = useState('');
    const [isFormUpdating, setIsFormUpdating] = useState(false);
    const [WorkspaceRequestExpiryDate, setWorkspaceRequestExpiryDate] = useState('');
    const [isCalenderOpen, setIsCalenderOpen] = useState(false);
    const [zone3RequestExpiryDate, setZone3RequestExpiryDate] = useState('');
    const [serviceAgreementUploaded, setServiceAgreementUploaded] = useState([]);
    const [serviceAgreementDocumentDetails, setServiceAgreementDocumentDetails] = useState({});
    const [serviceAgreementUploadPercentage, setServiceAgreementUploadPercentage] = useState(0);
    const [openCalender, setOpenCalender] = useState(false);
	const [workspaceDetails, setWorkspaceDetails] = useState('');
    const [aoEmail,setAOEmail] = useState('');
    const [costCap, setCostCapValue] = useState('');
    const [showAOEmailValidationError, setShowAOEmailValidationError] = useState(false);
    const [showCostCapValidationError, setShowCostCapValidationError] = useState(false);
    const [workspaceType, setWorkspaceType] = useState('');
    const [showWorkspaceTypeValidationError, setShowWorkspaceTypeValidationError] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const toggleDataAccessModal = () => {
        setViewDataForm(false);
    }

    const onDIFEditButtonClick = (requestData, index) => {
        const requestID = get(requestData, 'request_id', '')
        const currentStage = get(requestData, 'stage', '');
        const requesterEmail = get(requestData, 'submitter_email', '');
        const requesterSub = get(requestData, 'user_sub', '');
        if (!isEmpty(currentStage) || currentStage !== null) {
            setDifStage(index);
        } else {
            setDifStage(1);
        }
        setDifDataSharingAssociatedEmail(requesterEmail);
        navigate(`/dashboard/data-inventory-form/${requestID}/${requesterSub}`, { state: { dashboard: true, requesterSub:requesterSub, requesterEmail: requesterEmail } });
    }

    const onDIFDownload = async (userSub, dif) => {
        let requests = await downloadDIFForm(userSub, dif);
        if (!isEmpty(requests) && requests?.document_url) {
            window.location.href = requests?.document_url;
        }
    }
    const onDocumentDownload = async (usersSub, type) => {
        try {
            const res = await getUserDocumentURLForDownload(usersSub, type);
            window.location.href = res?.document_url;
        } catch (error) {
            updateSnackBar('Error in downloading the document', 'error')
        }
    }

    const getDateTimeObject = (response) => {
        let dayStr = '';
        let timeStr = '';
        let requestSubmittedDate = get(response, 'requestedDate', '');
        if (!requestSubmittedDate) {
            requestSubmittedDate = get(response, 'request_submitted_time', '')
        }
        const reqDateMomentObj = moment(requestSubmittedDate);
        if (moment.isMoment(reqDateMomentObj)) {
            dayStr = reqDateMomentObj.format('MM/DD/YYYY');
            timeStr = reqDateMomentObj.format('hh:mm A');
        }
        response.dayStr = dayStr;
        response.timeStr = timeStr;
        return response;
    }

    const getPolicyData = async (requestData) => {
        try {
            setViewDataForm(true);
            const userEmail = get(requestData, 'user_sub', '');
            let response = await getSubmittedDUP(userEmail);
            response = getDateTimeObject(response);
            setDataPolicyData(response);
        }
        catch (error) {
            console.log(error)
        }
    }
    const getInstitutionCertificateData = async (requestData) => {
        try {
            setViewInstitutionCertificateForm(true);
            const userEmail = get(requestData, 'user_sub', '');
            let response = await getSubmittedDUP(userEmail);
            response = getDateTimeObject(response);
            setInstitutionCertificateData(response);
        }
        catch (error) {
            console.log(error)
        }
    }
    let count = 0;
    const downloadURL = (url) => {
        let hiddenIFrameID = 'hiddenDownloader' + count++;
        let iframe = document.createElement('iframe');
        iframe.id = hiddenIFrameID;
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        iframe.src = url;
    }
    const onDownloadCertificates = async (requestData) => {
        try {
            const userSub = requestData?.user_sub;
            const res = await getCertificates(userSub);
            if (isEmpty(res?.certificate_list)) {
                updateSnackBar('No certificates to download', 'error');
            } else {
                map(res?.certificate_list, async (certificate) => {
                    const certificateName = certificate?.certificate_name;
                    const certUrlRes = await getCertificateUrl(certificateName, requestData?.user_sub);
                    downloadURL(certUrlRes?.url);
                })
            }
        } catch (error) {
            updateSnackBar('Error in download certificates', 'error');
        }
    }
 const onDownloadServiceAgreement = async (requestData) => {
        try {
            const userSub = get(requestData, 'user_sub', '');
            const requestId = get(requestData, 'request_id', '');
            const fileName = get(requestData, 'service_agreement_file_name', '');
            const res = await getDownloadURLForServiceAgreement(userSub, requestId, fileName);
            downloadURL(res);

        } catch (error) {
            updateSnackBar('Error in downloading Service Agreement', 'error');
        }
    }
    const onViewCertificateClick = (requestData) => {
        const userSub = get(requestData, 'user_sub', '');
        navigate(`admin/user-profile/${userSub}#certificates`, { state: { adminDashboard: true } })
    }

    const onOpenServiceAgreementModalClick = async (requestData) => {
        try {
            let workspaceResponse = await getServiceAgreementDetails(requestData?.user_sub, requestData?.request_id);
			setWorkspaceDetails(workspaceResponse);
			setOpenServiceAgreementModal(true);
        } catch (error) {
            console.log(error)
        }
    }
    const onApproveWorkspaceRequestModalConfirm = async () => {
        try {
            let isValidExpiryDate = false;
            let isValidAOEmail = false;
            let isValidCostCap = false;
            let isValidWorkspaceTypeCap = false;

            if (!isDate(WorkspaceRequestExpiryDate)) {
                setExpiryDateError(true);
                isValidExpiryDate = false;
            } else {
                isValidExpiryDate = true;
                setExpiryDateError(false);
            }
            if(!validateEmailAddress(aoEmail)){
                setShowAOEmailValidationError(true);
                isValidAOEmail = false;
            }else{
                isValidAOEmail = true;
                setShowAOEmailValidationError(false)
            }
            if(isEmpty(`${costCap}`) || costCap <= 0){
                setShowCostCapValidationError(true);
                isValidCostCap = false;
            }else{
                isValidCostCap = true;
                setShowCostCapValidationError(false);
            }
            if(isEmpty(workspaceType)){
                isValidWorkspaceTypeCap = false;
                setShowWorkspaceTypeValidationError(true);
            }else{
                isValidWorkspaceTypeCap = true;
                setShowWorkspaceTypeValidationError(false);
            }
            if(!(isValidExpiryDate && isValidAOEmail && isValidCostCap && isValidWorkspaceTypeCap)){
                return
            }
            setIsFormUpdating(true);
            if (!isEmpty(serviceAgreementDocumentDetails)) {
                const file = serviceAgreementDocumentDetails;
                const userSub = get(currentWorkspaceAccessRequestData, 'user_sub', '');
                const requestId = get(currentWorkspaceAccessRequestData, 'request_id', '');
                const preSignedData = await getPresignedURLForServiceAgreement(userSub, requestId);
                let config = {
                    onUploadProgress: function (progressEvent) {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setServiceAgreementUploadPercentage(percentCompleted);
                    }
                };
                const res = await uploadFileToS3(file[0], preSignedData, config);
                if (res == 204 || res == 202 || res == 200) {
                    setServiceAgreementUploadPercentage(0);
                    updateSnackBar('Document uploaded successfully.', 'success');
                    setServiceAgreementDocumentDetails({});
                    setServiceAgreementUploaded(true);

                } else {
                    updateSnackBar('Document uploaded failed.', 'error');
                    setServiceAgreementDocumentDetails({});
                    setServiceAgreementUploaded(false);
                }
            }
            sendGAEvents('approve_workspace_request', 'workspace_request_approval', 'workspace request approve');
            setExpiryDateError(false);
            const expiryDate = moment(WorkspaceRequestExpiryDate).format('YYYY-MM-DD');
            const body = {
                'user_sub': get(currentWorkspaceAccessRequestData, 'user_sub', ''),
                'request_id': get(currentWorkspaceAccessRequestData, 'request_id', ''),
                'expiry_date': expiryDate,
                'cost_cap':`${costCap}`,
                "ao_email": aoEmail,
                "workspace_type": workspaceType 
            };
            const res = await approveWorkspaceAccessRequest(body);
            if (res) {
                setOpenWorkspaceApproveModal(false);
                updateSnackBar(res?.message, 'success');
                setWorkspaceRequestExpiryDate('');
                setZone2RequestExpiryDate('');
                setAOEmail('');
                setCostCapValue('');
                setWorkspaceType('');
                await getWorkspaceAccessRequest();
            } else {
                setOpenWorkspaceApproveModal(false);
                updateSnackBar('Error in approving workspace request', 'error');
            }
        } catch (error) {
            setOpenWorkspaceApproveModal(false);
            updateSnackBar('Error in approving workspace request', 'error');
            setAOEmail('');
            setCostCapValue('');
            setWorkspaceType('');
        }finally{
            setIsFormUpdating(false);
        }
    }

    const onWorkspaceRequestApprove = (requestData) => {
        setOpenWorkspaceApproveModal(true);
        setCurrentWorkspaceAccessRequestData(requestData);
        setWorkspaceType(requestData?.workspace_type || '')
    }
    const onZone3RequestApprove = (requestData) => {
        setOpenZone3ApproveModal(true);
        setCurrentZone3RequestData(requestData);
    }
    const closeApproveWorkspaceRequestModal = () => {
        setOpenWorkspaceApproveModal(false);
        setExpiryDateError(false);
        setWorkspaceRequestExpiryDate('');
        setServiceAgreementUploaded(false);
        setAOEmail('');
        setCostCapValue('');
        setWorkspaceType('');
        setShowAOEmailValidationError(false);
        setShowCostCapValidationError(false);
        setShowWorkspaceTypeValidationError(false);
    }

    const stepsTitleClass = window.navigator.appVersion.indexOf('Win') !== -1
    ? window.devicePixelRatio > 1.25 ? `${classes.highDPI} ${classes.formStepTitle}`
        : classes.formStepTitle
    : classes.formStepTitle;


    const getCurrentStepComponent = (requestData) => {
        switch(props.tabValue){
            case 'dca': return <DCAFormStepper hideDownload={!props.userDetails?.resourceActions?.dashboard?.download_data_contribution_agreement} requestData={requestData} dashboard/>
            case 'dif': return  map(DIF_FORM_STEPS, (steps, index) => {
                const requestID = get(requestData, 'request_id', '');
                const userSub = get(requestData, 'user_sub', '');
                return <div className={classes.formStep}>
                    <div className={classes.formStepCircle}>
                        <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                    </div>
                    <MuiToolTip id={`cvb-adminDashboard-difRequests-stepTitleTooltip-${steps}`} title={steps}>
                        <div id={`cvb-adminDashboard-difRequests-step-${steps}`} className={stepsTitleClass}>{steps}</div>
                    </MuiToolTip>
                    <div className={classes.formStepOptional}>
                        <p className={classes.submitText}>Submitted</p>
                    </div>
                    <div className={classes.viewButtonWrapper}>
                        <div className={classes.viewDownloadIcon}>
                            <img id={`cvb-adminDashboard-difRequests-viewDif-${steps}`} alt='view' onClick={() => onDIFEditButtonClick(requestData, index + 1)} src={ViewBlueIcon} />
                            {
                                props.userDetails?.resourceActions?.dashboard?.download_data_inventory_form &&
                                <img id={`cvb-adminDashboard-difRequests-downloadDif-${steps}`} alt='download' onClick={() => onDIFDownload(userSub, requestID)} src={DownloadBlueIcon} />
                            }
                        </div>
                    </div>
                    <div className={classes.formStepBarLeft}></div>
                    <div className={classes.formStepBarRight}></div>
                </div>
            })
            case 'zone2': {
                const userSub = get(requestData, 'user_sub', '');
                return <>
                <div className={classes.formStep}>
                    <div className={classes.formStepCircle}>
                        <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                    </div>
                    <div id='cvb-adminDashboard-dup-heading' className={classes.formStepTitle}>Data Use Agreement</div>
                    <div className={classes.formStepOptional}>
                        <p id='cvb-adminDashboard-dup-submittedText' className={classes.submitText}>Submitted</p>
                    </div>
                    <div className={classes.viewButtonWrapper}>
                        <div className={classes.viewDownloadIcon}>
                            <img id='cvb-adminDashboard-viewDup-button' alt='view' onClick={() => getPolicyData(requestData)} src={ViewBlueIcon} />
                            <img id='cvb-adminDashboard-downloadDup-button' alt='download' onClick={() => onDocumentDownload(userSub, 'data_use_policy')} src={DownloadBlueIcon} />
                        </div>
                    </div>
                    <React.Fragment>
                        <div className={classes.formStepBarLeft}></div>
                        <div className={classes.formStepBarRight}></div>
                    </React.Fragment>

                </div>
                <div className={classes.formStep}>
                    <div className={classes.formStepCircle}>
                        <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                    </div>
                    <div id='cvb-adminDashboard-instCert-heading' className={classes.formStepTitle}>Institutional Certificates</div>
                    <div className={classes.formStepOptional}>
                        <p id='cvb-adminDashboard-instCert-submittedText' className={classes.submitText}>Submitted</p>
                    </div>
                    <div className={classes.viewButtonWrapper}>
                        <div className={classes.viewDownloadIcon}>
                            <img id='cvb-adminDashboard-viewInstCert-button' alt='view' onClick={() => getInstitutionCertificateData(requestData)} src={ViewBlueIcon} />
                            <img id='cvb-adminDashboard-downloadInstCert-button' alt='download' onClick={() => onDocumentDownload(userSub, 'data_use_policy')} src={DownloadBlueIcon} />
                        </div>
                    </div>
                    <div className={classes.formStepBarLeft}></div>
                    <div className={classes.formStepBarRight}></div>
                </div>
                <div className={classes.formStep}>
                    <div className={classes.formStepCircle}>
                        <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                    </div>
                    <div id='cvb-adminDashboard-certificates-heading' className={classes.formStepTitle}>Certificates</div>
                    <div className={classes.formStepOptional}>
                        <p id='cvb-adminDashboard-certificatesSubmitted-text' className={classes.submitText}>Submitted</p>
                    </div>
                    <div className={classes.viewButtonWrapper}>
                        <div className={classes.viewDownloadIcon}>
                            <img id='cvb-adminDashboard-viewCertificatesButton' alt='view' onClick={() => onViewCertificateClick(requestData)} src={ViewBlueIcon} />
                            <img id='cvb-adminDashboard-downloadCertificates-button' alt='download' onClick={() => onDownloadCertificates(requestData)} src={DownloadBlueIcon} />
                        </div>
                    </div>
                    <div className={classes.formStepBarLeft}></div>
                    <div className={classes.formStepBarRight}></div>
                </div>
            </>}
            case 'zone3': {
            const userSub = get(requestData, 'user_sub', '');    
            return <>
            <div className={classes.formStep}>
                    <div className={classes.formStepCircle}>
                        <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                    </div>
                    <div id='cvb-adminDashboard-dup-heading' className={classes.formStepTitle}>Data Use Agreement</div>
                    <div className={classes.formStepOptional}>
                        <p id='cvb-adminDashboard-dup-submittedText' className={classes.submitText}>Submitted</p>
                    </div>
                    <div className={classes.viewButtonWrapper}>
                        <div className={classes.viewDownloadIcon}>
                            <img id='cvb-adminDashboard-viewDup-button' alt='view' onClick={() => getPolicyData(requestData)} src={ViewBlueIcon} />
                            <img id='cvb-adminDashboard-downloadDup-button' alt='download' onClick={() => onDocumentDownload(userSub, 'data_use_policy')} src={DownloadBlueIcon} />
                        </div>
                    </div>
                    <React.Fragment>
                        <div className={classes.formStepBarLeft}></div>
                        <div className={classes.formStepBarRight}></div>
                    </React.Fragment>

                </div>
                <div className={classes.formStep}>
                    <div className={classes.formStepCircle}>
                        <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                    </div>
                    <div id='cvb-adminDashboard-instCert-heading' className={classes.formStepTitle}>Institutional Certificates</div>
                    <div className={classes.formStepOptional}>
                        <p id='cvb-adminDashboard-instCert-submittedText' className={classes.submitText}>Submitted</p>
                    </div>
                    <div className={classes.viewButtonWrapper}>
                        <div className={classes.viewDownloadIcon}>
                            <img id='cvb-adminDashboard-viewInstCert-button' alt='view' onClick={() => getInstitutionCertificateData(requestData)} src={ViewBlueIcon} />
                            <img id='cvb-adminDashboard-downloadInstCert-button' alt='download' onClick={() => onDocumentDownload(userSub, 'data_use_policy')} src={DownloadBlueIcon} />
                        </div>
                    </div>
                    <div className={classes.formStepBarLeft}></div>
                    <div className={classes.formStepBarRight}></div>
                </div>
                <div className={classes.formStep}>
                    <div className={classes.formStepCircle}>
                        <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                    </div>
                    <div id='cvb-adminDashboard-certificates-heading' className={classes.formStepTitle}>Certificates</div>
                    <div className={classes.formStepOptional}>
                        <p id='cvb-adminDashboard-certificatesSubmitted-text' className={classes.submitText}>Submitted</p>
                    </div>
                    <div className={classes.viewButtonWrapper}>
                        <div className={classes.viewDownloadIcon}>
                            <img id='cvb-adminDashboard-viewCertificatesButton' alt='view' onClick={() => onViewCertificateClick(requestData)} src={ViewBlueIcon} />
                            <img id='cvb-adminDashboard-downloadCertificates-button' alt='download' onClick={() => onDownloadCertificates(requestData)} src={DownloadBlueIcon} />
                        </div>
                    </div>
                    <div className={classes.formStepBarLeft}></div>
                    <div className={classes.formStepBarRight}></div>
                </div>
            </>}
            case 'workspace': return <>
            <div className={classes.formStep}>
                    <div className={classes.formStepCircle}>
                        <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                    </div>
                    <div id='cvb-adminDashboard-serviceAgreement-heading' className={classes.formStepTitle}>Proposal</div>
                    <div className={classes.formStepOptional}>
                        <p id='cvb-adminDashboard-serviceAgreement-submittedText' className={classes.submitText}>Submitted</p>
                    </div>
                    <div className={classes.viewButtonWrapper}>
                        <div className={classes.viewDownloadIconSA}>
                            <img id='cvb-adminDashboard-viewServiceAgreementModal-button' alt='view' onClick={() => onOpenServiceAgreementModalClick(requestData)} src={ViewBlueIcon} />
                        </div>
                    </div>
                    <div className={classes.formStepBarLeft}></div>
                    <div className={classes.formStepBarRight}></div>
                </div>
                {
                    requestData?.service_agreement_file_name ?
                        <div className={classes.formStep}>
                            <div className={classes.formStepCircle}>
                                <img className={classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
                            </div>
                            <div id='cvb-adminDashboard-certificates-heading' className={classes.formStepTitle}>Service Agreement</div>
                            <div className={classes.formStepOptional}>
                                <p id='cvb-adminDashboard-certificatesSubmitted-text' className={classes.submitText}>Submitted</p>
                            </div>
                            <div className={classes.viewButtonWrapper}>
                                <div className={classes.viewDownloadIconSA}>
                                    <img id='cvb-adminDashboard-downloadCertificates-button' alt='download' onClick={() => onDownloadServiceAgreement(requestData)} src={DownloadBlueIcon} />
                                </div>
                            </div>
                            <div className={classes.formStepBarLeft}></div>
                            <div className={classes.formStepBarRight}></div>
                        </div> : null}
            </>
            default: return <p></p>;
        }
    }
    const closeApproveZone2RequestModal = () => {
        setOpenZone2ApproveModal(false);
        setExpiryDateError(false);
        setZone2RequestExpiryDate('');
    }
    const onZone2RequestApprove = (requestData) => {
        setOpenZone2ApproveModal(true);
        setCurrentZone2RequestData(requestData);
    }
    
    const getCurrentStepperHeaderComponent = (requestData) =>{
        
        switch(props.tabValue){
            case 'dca': {
                const requestStatus = get(requestData, 'requestStatus', '');
                return <p id='cvb-adminDashboard-dcaRequests-dcaRequestStatus' className={classes.pendingApproval}>
                {requestStatus}
            </p>}
            case 'dif': {
                const requestStatus = get(requestData, 'status', '');
                return <p id='cvb-adminDashboard-difRequests-requestStatus' className={classes.pendingApproval}>
            {requestStatus}
        </p>}
            case 'zone2':{
                const requestStatus = get(requestData, 'request_status', '');
                const requestStatusLabel = requestStatus.replaceAll("_", " ");
             return <> 
            <li>
                <p id='cvb-adminDashboard-zone2Requests-requestStatus' className={classes.pendingApproval}>
                    {requestStatusLabel}
                </p>
            </li>
                {(lowerCase(requestStatus) !== 'approved') && (requestStatus !== "PENDING_FOR_CERTIFICATION_APPROVAL") && props.userDetails?.resourceActions?.dashboard?.approve_zone2_access_requests && 
                    <li>
                        <button id='cvb-adminDashboard-zone2Requests-approveButton' onClick={() => onZone2RequestApprove(requestData)} className={classes.approveRequestButton}>
                            Approve
                        </button>
                    </li>
                }
            </>
            }
            case 'zone3' :{ 
                const requestStatus = get(requestData, 'request_status', '');
                const requestStatusLabel = requestStatus.replaceAll("_", " ");
                return <> 
                <li>
                    <p id='cvb-adminDashboard-zone3Requests-requestStatus' className={classes.pendingApproval}>
                        {requestStatusLabel}
                    </p>
                </li>
                {(lowerCase(requestStatus) !== 'approved') && (requestStatus !== "PENDING_FOR_CERTIFICATION_APPROVAL") && props.userDetails?.resourceActions?.dashboard?.approve_zone3_access_requests && <li>
                    <button id='cvb-adminDashboard-zone3Requests-approveButton' onClick={() => onZone3RequestApprove(requestData)} className={classes.approveRequestButton}>
                        Approve
                    </button>
                </li>}
            </>}
            case 'workspace':{
                const requestStatus = get(requestData, 'request_status', '');
                return <>                
                <li>
                    {!(lowerCase(requestStatus) === 'completed' || lowerCase(requestStatus) === 'approved')
                        ? <p id='cvb-adminDashboard-wsAccessRequest-pendingRequestStatus' className={classes.pendingApproval}>
                            {requestStatus}
                        </p>
                        : <p id='cvb-adminDashboard-wsAccessRequest-authRequestStatus' className={classes.authorizedAccessText}>
                            {requestStatus}
                        </p>
                    }
                </li>
                <li>
                    {!(lowerCase(requestStatus) === 'completed' || lowerCase(requestStatus) === 'approved' || lowerCase(requestStatus) === lowerCase(INSTITUTE_APPROVAL_PENDING_TEXT)) && props.userDetails?.resourceActions?.dashboard?.approve_advance_compute_requests
                        ? <button id='cvb-adminDashboard-wsAccessRequest-approveButton' onClick={() => onWorkspaceRequestApprove(requestData)} className={classes.approveRequestButton}>
                            Approve
                                                                        </button> : ''
                    }
                </li>
            </>}

        }
    }

    const getCurrentRequestTitleAndTime = (requestData) => {
        switch(props.tabValue){
            case 'dca': {
                let dayStr = '';
                let timeStr = '';
                const currentStage = get(requestData, 'stage', '');
                if (currentStage === 'Completed') {
                    const reqCompletedDate = get(requestData, 'requestCompletionDate', '');
                    const reqDateMomentObj = moment(reqCompletedDate);
                    if (moment.isMoment(reqDateMomentObj)) {
                        dayStr = reqDateMomentObj.format('MM/DD/YYYY');
                        timeStr = reqDateMomentObj.format('hh:mm A');
                    }
                }
                else {
                    const lastUpdateDate = get(requestData, 'lastUpdatedDate', '');
                    const reqDateMomentObj = moment(lastUpdateDate);
                    if (moment.isMoment(reqDateMomentObj)) {
                        dayStr = reqDateMomentObj.format('MM/DD/YYYY');
                        timeStr = reqDateMomentObj.format('hh:mm A');
                    }
                }
                return <>
                    <h4 id='cvb-adminDashboard-dcaRequests-dcaHeading'>Data Contribution Agreement</h4>
                    <p id='cvb-adminDashboard-dcaRequests-dcaSubmittedTime'>Submitted on {dayStr} at {timeStr}</p>
                </>
            }
            case 'dif': {
                let dayStr = '';
                let timeStr = '';
                const requestSubmittedDate = get(requestData, 'updated_timestamp', '');
                const reqDateMomentObj = moment(requestSubmittedDate);
                if (moment.isMoment(reqDateMomentObj)) {
                    dayStr = reqDateMomentObj.format('MM/DD/YYYY');
                    timeStr = reqDateMomentObj.format('hh:mm A');
                }
                return <>
                    <h4 id='cvb-adminDashboard-difRequests-difHeading'>Data Inventory Form</h4>
                    <p id='cvb-adminDashboard-difRequests-submittedTime'>Submitted on {dayStr} at {timeStr}</p>
                </>
            }
            case 'zone2': {
                let dayStr = '';
                let timeStr = '';
                const requestSubmittedDate = get(requestData, 'request_submitted_time', '');
                const reqDateMomentObj = moment(requestSubmittedDate);

                if (moment.isMoment(reqDateMomentObj)) {
                    dayStr = reqDateMomentObj.format('MM/DD/YYYY');
                    timeStr = reqDateMomentObj.format('hh:mm A');
                }
                return <> 
                    <h4 id='cvb-adminDashboard-zone2Requests-reqZone2Access-heading'>Requested Zone 2 Access</h4>
                    <p id='cvb-adminDashboard-zone2Requests-submittedTime'>Submitted on {dayStr} at {timeStr}</p>
                </>
            }
            case 'zone3' : {
                let dayStr = '';
                let timeStr = '';
                const requestSubmittedDate = get(requestData, 'request_submitted_time', '');
                const reqDateMomentObj = moment(requestSubmittedDate);
                const projectId = get(requestData, 'project_id', '');
                if (moment.isMoment(reqDateMomentObj)) {
                    dayStr = reqDateMomentObj.format('MM/DD/YYYY');
                    timeStr = reqDateMomentObj.format('hh:mm A');
                }
                return <> 
                    <h4 id='cvb-adminDashboard-zone2Requests-reqZone3Access-heading'>Requested Zone 3 Access <span style={{ textTransform: 'none' }}>for {projectId}</span></h4>
                    <p id='cvb-adminDashboard-zone2Requests-submittedTime'>Submitted on {dayStr} at {timeStr}</p>
                </>
            }
            case 'workspace': {
                let dayStr = '';
                let timeStr = '';
                const requestSubmittedDate = get(requestData, 'request_submitted_time', '');
                const reqDateMomentObj = moment.utc(requestSubmittedDate);
                if (moment.isMoment(reqDateMomentObj)) {
                    dayStr = reqDateMomentObj?.local().format('MM/DD/YYYY');
                    timeStr = reqDateMomentObj?.local().format('hh:mm A');
                }
                return <>
                    <h4 id='cvb-adminDashboard-wsAccessRequest-reqAdvComputeHeader'>Requested Advanced Compute</h4>
                    <p id='cvb-adminDashboard-wsAccessRequest-submittedTime'>Submitted on {dayStr} at {timeStr}</p>
                </>
            }
        }
    }
    const onApproveZone2RequestModalConfirm = async () => {
        try {
            sendGAEvents('approve_zone2_request', 'zone2_request_approval', 'zone 2 request approve');
            setIsFormSubmitted(true);
            if(isEmpty(zone2Type)){
                if (!isDate(zone2RequestExpiryDate)) {
                    setExpiryDateError(true);
                }
                return;
            }
            if (isDate(zone2RequestExpiryDate)) {
                setExpiryDateError();
                const expiryDate = moment(zone2RequestExpiryDate).format('YYYY-MM-DD');
                const body = {
                    'request_id': get(currentZone2RequestData, 'request_id'),
                    'user_sub': get(currentZone2RequestData, 'user_sub', ''),
                    'zone2_type': zone2Type,
                    //'request_submitted_time': get(currentZone2RequestData, 'request_submitted_time', ''),
                    'expiry_date': expiryDate
                };
                setIsFormUpdating(true);
                const res = await approveZone2AccessRequest(body);
                if (res) {
                    updateSnackBar(res?.message, 'success');
                    setWorkspaceRequestExpiryDate('');
                    setZone2RequestExpiryDate('');
                    setOpenZone2ApproveModal(false);
                    await getDatasetAccessRequests();
                } else {
                    updateSnackBar('Error in approving Zone 2 request', 'error');
                    setOpenZone2ApproveModal(false);
                }
            } else {
                setExpiryDateError(true);
            }
        }
        catch (error) {
            updateSnackBar('Error in approving Zone 2 request', 'error');
            setOpenZone2ApproveModal(false);
        }finally{
            setIsFormUpdating(false);
        }
    }
    const onZone3RequestExpiryChange = value => {
        setZone3RequestExpiryDate(value);
        setExpiryDateError(false);
    }
    const onApproveZone3RequestModalConfirm = async () => {
        try {
            if (isDate(zone3RequestExpiryDate)) {
                sendGAEvents('approve_zone3_request', 'zone3_request_approval', 'zone 3 request approve');
                setExpiryDateError();
                const expiryDate = moment(zone3RequestExpiryDate).format('YYYY-MM-DD');
                const body = {
                    'user_sub': get(currentZone3RequestData, 'user_sub', ''),
                    'zone3_request_id': get(currentZone3RequestData, 'request_id', ''),
                    'project_id': get(currentZone3RequestData, 'project_id', ''),
                    'expiry_date': expiryDate
                };
                setIsFormUpdating(true);
                const res = await approveZone3AccessRequest(body);
                if (res) {
                    updateSnackBar(res?.message, 'success');
                    setWorkspaceRequestExpiryDate('');
                    setOpenZone3ApproveModal(false);
                    setZone3RequestExpiryDate('');
                    await getZone3AccessRequests();
                } else {
                    updateSnackBar('Error in approving Zone 3 request', 'error');
                    setOpenZone3ApproveModal(false);
                }
            } else {
                setExpiryDateError(true);
            }
        }
        catch (error) {
            updateSnackBar('Error in approving Zone 3 request', 'error');
            setOpenZone3ApproveModal(false);
        }finally{
            setIsFormUpdating(false);
        }
    }
    const toggleServiceAgreementModal = () => {
        setOpenServiceAgreementModal(false);
    }
    const onZone2RequestExpiryChange = value => {
        setZone2RequestExpiryDate(value);
        setExpiryDateError(false);
    }
    const closeApproveZone3RequestModal = () => {
        setOpenZone3ApproveModal(false);
        setExpiryDateError(false);
        setZone3RequestExpiryDate('');
    }
    const onWorkspaceRequestExpiryChange = value => {
        setWorkspaceRequestExpiryDate(value);
        setExpiryDateError(false);
        setOpenCalender(false);
    }
    const onServiceAgreementDocumentDrop = async (file) => {
        setServiceAgreementDocumentDetails(file);
    }
    const onAOEmailChange = (e) =>{
        setAOEmail(e.target.value);
        setShowAOEmailValidationError(false);
    }
    const onCostCapChange = (e) =>{
        setShowCostCapValidationError(false);
        setCostCapValue(e.target.value);
    }
    const onWorkspaceTypeChange = (e) =>{
        setWorkspaceType(e.target.value);
        setShowWorkspaceTypeValidationError(false);
    }
    const APPROVE_ZONE2_REQUEST_MODAL_PROPS = {
        modalTitle: 'Are you sure you want to approve this Zone 2 request?',
        positiveButtonText: 'Approve',
        negativeButtonText: "Cancel",
        positiveButtonAction: onApproveZone2RequestModalConfirm,
        negativeButtonAction: closeApproveZone2RequestModal,
        popupButtonDisable: isFormUpdating
    }
    const { requestData = { }} = props;
    const userName = (capitalize(requestData?.["first_name"]) + " " + capitalize(requestData?.["last_name"]));
    let userDetailsArray = [];
    if (!isEmpty(requestData?.designation)) { userDetailsArray.push(capitalize(requestData?.designation)) };
    if (!isEmpty(requestData?.organization)) { userDetailsArray.push(capitalize(requestData?.organization)) };
    const userInfo = userDetailsArray.join(', ');
    const profilePic = !isEmpty(requestData.avatar_image_url) ? requestData.avatar_image_url : DefaultProfile;

    const DATA_ACCESS_MODAL = {
        modalTitle: "Data Use Agreement",
        modalContent: "",
    }
    const SERVICE_AGREEMENT_MODAL = {
        modalTitle: "Proposal",
        modalContent: "",

    }
    const APPROVE_ZONE3_REQUEST_MODAL_PROPS = {
        modalTitle: 'Are you sure you want to approve this Zone 3 request?',
        positiveButtonText: 'Approve',
        negativeButtonText: "Cancel",
        positiveButtonAction: onApproveZone3RequestModalConfirm,
        negativeButtonAction: closeApproveZone3RequestModal,
        popupButtonDisable: isFormUpdating
    }
    const APPROVE_WORKSPACE_REQUEST_MODAL_PROPS = {
        modalTitle: 'Are you sure you want to approve this workspace request?',
        positiveButtonText: 'Approve',
        negativeButtonText: "Cancel",
        positiveButtonAction: onApproveWorkspaceRequestModalConfirm,
        negativeButtonAction: closeApproveWorkspaceRequestModal,
        popupButtonDisable: isFormUpdating
    }

    const onZone2TypeSelection = (type) => {
		setZone2Type(type)
	}

    const calenderOpenCloseClass = isCalenderOpen ? classes.calenderOpenClass : classes.calenderCloseClass;

    const timelineBlockStyles =  props.tabValue !== 'dif' ?  `${classes.timeLineBlockUserTimelines}` : `${classes.timeLineBlockUserTimelines} ${classes.difStepper}`;
    const formStepperStyles =  props.tabValue !== 'dif' ? `${classes.formStepperHorizontal}` : `${classes.formStepperHorizontal} ${classes.difStepper}`
    return (
        <React.Fragment>
            <Modal
                open={openWorkspaceApproveModal}
                handleClose={closeApproveWorkspaceRequestModal}
                dialogProps={APPROVE_WORKSPACE_REQUEST_MODAL_PROPS}
            >
                 <p className={classes.aoDefinitionText}>Authorizing Official: This means an executive, officer or other individual who is authorized to sign contracts on behalf of your organization or company. You can find out who that is by contacting the office of your legal counsel or chief executive. </p>
                <div className={`${classes.datePickerWrapper} ${calenderOpenCloseClass}`}>
                    <label id='cvb-adminDashboard-approveWSModal-expiryDateTitle'>Expiry Date<sup> *</sup></label>
                    <Grid item xs={6}>
                        <CommonDatePicker 
                         onCalenderDateSelected={onWorkspaceRequestExpiryChange}
                         minDate={moment().add(1, 'd').toDate()}
                         date={WorkspaceRequestExpiryDate}
                         showValidationError={expiryDateError}
                         errorMessage='Please select expiry date'
                         id='cvb-adminDashboard-approveWSModal-inputDate'
                         isFromModal
                         canClearInput
                        />
                    </Grid>
                </div>

                <Grid item lg={12} md={12} sx={12}>
                    <div className={classes.serviceAgreementDownloadContainerModal}>
                        <label id='cvb-adminDashboard-serviceAgreement-selectServiceAgreement'>Upload Service Agreement</label>
                        <div className={classes.dropZoneButtonContainer}>
                            <DropZone
                                onDrop={(acceptedFiles) => onServiceAgreementDocumentDrop(acceptedFiles)}
                                multiple={false}
                                id='cvb-adminDashboard-serviceAgreement-fileDrop'
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input id='cvb-adminDashboard-serviceAgreement-fileDropInput' {...getInputProps({ accept: 'application/pdf' })} />
                                        <div className={classes.uploadImageDiv}>
                                            <img src={DocumentIcon} alt='document' />
                                            <p className={classes.chooseFileText}>
                                                Drag and drop or
                                                                    <span>
                                                    <button id='cvb-adminDashboard-serviceAgreement-selectFileButton' className={`${classes.chooseFileButton}`}>
                                                        Select files
                                                                        </button>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </DropZone>
                        </div>
                        <progress id='cvb-adminDashboard-serviceAgreement-uploadProgress' className={classes.progressBar} value={serviceAgreementUploadPercentage} max={100} />
                        <p id='cvb-adminDashboard-serviceAgreement-fileName' className={classes.fileNameAndSize}>{get(serviceAgreementDocumentDetails, '[0].name', '')}
                            <span id='cvb-adminDashboard-serviceAgreement-fileSize'>{!isEmpty(serviceAgreementDocumentDetails) ? `${get(serviceAgreementDocumentDetails, '[0].size', '')}'kb'` : ''} </span>
                        </p>
                    </div>
                </Grid>
                <Grid item lg={6} md={6} xs={12} className={classes.addMarginTop}>
                    <label htmlFor='firstName' id="cvb-adminDashboard-aoEmailInputLabel">Authorizing Official Email<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        value={aoEmail}
                        fullWidth
                        id='cvb-adminDashboard-aoEmailInput'
                        name='aoEmail'
                        placeholder='Enter AO email'
                        onChange={onAOEmailChange}
                    />
                    {(showAOEmailValidationError) && <ErrorText errorMessage={'Please enter valid AO email'} />}
                </Grid>
                <Grid item lg={6} md={6} xs={12} className={classes.addMarginTop}>
                    <label htmlFor='firstName' id="cvb-adminDashboard-costCapInputLabel">Cost Cap<sup> *</sup></label>
                    <OutlinedInput
                        type="number"
                        value={costCap}
                        fullWidth
                        id='cvb-adminDashboard-costCapInput'
                        name='costCap'
                        placeholder='Enter Cost Cap value'
                        onChange={onCostCapChange}
                        inputProps={{min: 1}}
                    />
                    {showCostCapValidationError && <ErrorText errorMessage={'Enter valid cost cap value'} />}
                </Grid>
                <Grid item lg={6} md={6} xs={12} className={classes.addMarginTop}>
                    <label id="cvb-adminDashboard-workspaceTypeLabel">Workspace Type<sup> *</sup></label>
                    <Grid container>
                        <Grid item xs={6} className={classes.radioCheckContainer}>
                            <label className={classes.radioContainer} id="cvb-adminDashboard-ec2Label">Linux</label>
                            <input id='workspaceType' type="radio" name="workspaceType"
                                onChange={onWorkspaceTypeChange}
                                value='EC2'
                                checked={workspaceType === 'EC2' ? true : false} />
                            <span className={classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={6} className={classes.radioCheckContainer}>
                            <label className={classes.radioContainer} id="cvb-adminDashboard-windowsLabel">Windows</label>
                            <input id='workspaceType' type="radio" name="workspaceType"
                                onChange={onWorkspaceTypeChange}
                                value='WINDOWS'
                                checked={workspaceType === 'WINDOWS' ? true : false} />
                            <span className={classes.checkmark}></span>
                        </Grid>
                        {showWorkspaceTypeValidationError && <ErrorText errorMessage={'Please select one of the type'} />}
                    </Grid>
                </Grid>
            </Modal>
             <Modal
                open={openZone3ApproveModal}
                handleClose={closeApproveZone3RequestModal}
                dialogProps={APPROVE_ZONE3_REQUEST_MODAL_PROPS}
            >
                <div className={`${classes.datePickerWrapper} ${calenderOpenCloseClass}`}>
                    <label id='cvb-adminDashboard-approveZone2Modal-expiryDateLabel'>Expiry Date : </label>
                    <Grid item xs={6}>
                    <CommonDatePicker 
                         onCalenderDateSelected={onZone3RequestExpiryChange}
                         minDate={moment().add(1, 'd').toDate()}
                         date={zone3RequestExpiryDate}
                         showValidationError={expiryDateError}
                         errorMessage='Please select expiry date'
                         id='cvb-adminDashboard-approveZone3Modal-inputDate'
                         isFromModal
                         canClearInput
                        />
                    </Grid>
                </div>
            </Modal>
            <Modal open={viewDataForm} handleClose={toggleDataAccessModal} dialogProps={DATA_ACCESS_MODAL} disableTitleDivider disableBottomHorizontalDivider >
                <div className={[classes.contentStartText, classes.showHeader].join(' ')}>
                    <p>
                        <span className={classes.submitDetails} ><p id='cvb-adminDashboard-viewDUP-submittedTime' className={classes.submitText}>Submitted</p> on {dataPolicyData?.dayStr} at {dataPolicyData?.timeStr}</span>
                        <p id='cvb-adminDashboard-viewDUP-researcherName' className={classes.submitName}>By {dataPolicyData?.researcherName}</p>
                    </p>

                </div>
                <ViewDataForm
                    data={dataPolicyData}
                />
            </Modal>
            <Modal open={viewInstitutionCertificateForm}
                handleClose={() => setViewInstitutionCertificateForm(false)}
                dialogProps={{ modalTitle: "Institution Certificate" }} disableTitleDivider disableBottomHorizontalDivider >
                <div className={[classes.contentStartText, classes.showHeader].join(' ')}>
                    <p>
                        <span className={classes.submitDetails} ><p id='cvb-adminDashboard-viewInstCert-submittedTime' className={classes.submitText}>Submitted</p> on
                        {institutionCertificateData?.dayStr} at {institutionCertificateData?.timeStr}</span>
                        <p id='cvb-adminDashboard-viewInstCert-researcherName' className={classes.submitName}>By {institutionCertificateData?.researcherName}</p>
                    </p>

                </div>
                <ViewInstitutionCertificateForm {...institutionCertificateData} />
            </Modal>
            <RequestAdvancedComputeDetailsView
                open={openServiceAgreementModal}
                handleClose={toggleServiceAgreementModal}
                dialogProps={SERVICE_AGREEMENT_MODAL}
                workspaceDetails={workspaceDetails}
            >
            </RequestAdvancedComputeDetailsView>
            
            <Modal
                open={openZone2ApproveModal}
                handleClose={closeApproveZone2RequestModal}
                dialogProps={APPROVE_ZONE2_REQUEST_MODAL_PROPS}
            >
                <div className={`${classes.datePickerWrapper} ${calenderOpenCloseClass}`}>
                    <label id='cvb-adminDashboard-approveZone2Modal-expiryDateLabel'>Expiry Date : </label>
                    <Grid item xs={6}>
                    <CommonDatePicker 
                         onCalenderDateSelected={onZone2RequestExpiryChange}
                         minDate={moment().add(1, 'd').toDate()}
                         date={zone2RequestExpiryDate}
                         showValidationError={expiryDateError}
                         errorMessage='Please select expiry date'
                         id='cvb-adminDashboard-approveZone2Modal-inputDate'
                         isFromModal
                         canClearInput
                        />
                    </Grid>
                </div>

                <Grid item xs={10} className={classes.fieldCont}>
                    <label id="cvb-adminDashboard-workspaceInstanceLabel">Zone 2 Type</label>
                    <Grid container>
                        <Grid item xs={5} className={classes.radioCheckContainer}>
                            <label className={classes.radioContainer} id="cvb-adminDashboard-academiaLabel">Qualified Researcher (Academia)</label>
                            <input id='academia' type="radio" name="isDataCurated"
                                onChange={() => onZone2TypeSelection('zone2-academia')}  />
                            <span className={classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={5} className={classes.radioCheckContainer}>
                            <label className={classes.radioContainer} id="cvb-adminDashboard-industryLabel">Qualified Researcher (Industry)</label>
                            <input id='industry' type="radio" name="isDataCurated"
                                onChange={() => onZone2TypeSelection('zone2-industry')} />
                            <span className={classes.checkmark}></span>
                        </Grid>
                    </Grid>
                    {
                        isFormSubmitted && isEmpty(zone2Type) &&
                        <ErrorText errorMessage="Select a Preference of Zone 2 Type" />
                    }
                </Grid>




            </Modal>
            <Grid container key={v4()} className={classes.requestContainer}>
                <Grid item xs={3} >
                    <div className={classes.timeLineBlockUser}>
                        <div className={classes.timeLineBlockUserPicture}>
                            <img id='cvb-adminDashboard-wsAccessRequest-profilePic' src={profilePic} alt="profile pic" />
                        </div>
                        <div className={classes.timeLineBlockUserContent}>
                            <MuiToolTip id='cvb-adminDashboard-wsAccessRequest-userNameTooltip' title={userName}>
                                <h4 id='cvb-adminDashboard-wsAccessRequest-userName'>{userName}</h4>
                            </MuiToolTip>
                            <MuiToolTip id='cvb-adminDashboard-wsAccessRequest-userDetailsTooltip' title={userInfo}>
                                <p id='cvb-adminDashboard-wsAccessRequest-userDetails'>{userInfo}</p>
                            </MuiToolTip>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={9}>
                    <div className={classes.timeLineBlockUserTimeline}>
                        <div className={classes.timeLineBlockUserTimelineHeader}>
                            <div className={classes.timeLineBlockUserTimelineLeft}>
                                {getCurrentRequestTitleAndTime(requestData)}
                            </div>
                            <div className={classes.timeLineBlockUserTimelineRight}>
                                <ul>
                                   {getCurrentStepperHeaderComponent(requestData)}
                                </ul>
                            </div>
                        </div>
                        <div className={`${timelineBlockStyles}`}>
                            <div className={`${formStepperStyles}`}>
                                {getCurrentStepComponent(requestData)}
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default PendingApprovalsStepper;