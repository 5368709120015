import React from 'react'
import classes from './index.module.css';
import Info from '@mui/icons-material/Info'
import Tooltip from '@mui/material/Tooltip'
import withStyles from '@mui/styles/withStyles';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#FCF8C8",
        color: "#222222",
        boxShadow: "-1px 1px 106px -61px rgba(74,73,74,1);",
        fontSize: 14,
        fontWeight: "medium",
        marginRight: '3px',
        fontFamily: "Raleway",
        borderRadius: 0,
        padding: 15,
        textAlign: "left"
    },
    arrow: {
        color: "#FCF8C8",
    },
    customWidth: {
        maxWidth: 200,
    },
}))(Tooltip);

const KeywordsDetailCard = (props) => {
    const { value } = props;

    return (
        <>
            <div className={classes.cardContainer} style={{backgroundColor: props.backgroundColor, borderTop: `4px solid ${props.topBorderColor}`}}>
                {props?.showInfoIcon &&
                    <div className={classes.infoContainer}>
                        <LightTooltip placement="right" className={classes.userNameTitle} arrow
                            title={<span>{props?.infoTooltipTitle}</span>}>
                            <Info color='primary' />
                        </LightTooltip>
                    </div>
                }
                <p className={classes.cardTitle}>
                   {props.cardIcon ? <span className={classes.cardIcon}>
                        {props.dollarSign ? 
                        <span className={classes.dollarSignText}>$</span>
                        : <img src={props.cardIcon} alt='icon' />
                         
                        }
                    </span>: null}
                    {props.title}</p>
                <p className={`${classes.cardValue} ${props.cardValueSmall ? classes.cardValueSmall : ''}`}>{value}</p>
            </div>
        </>
    )
}

export default KeywordsDetailCard;