import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import classes from './index.module.css'
import { get, isEmpty, map } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { deleteManualUploadedDCA, getDownloadUrlForDCAManualUploads, getManualDCAUploadPresignedData } from '../../../api/dca-form-api';
import { uploadFileToS3 } from '../../../utils/aws-sdk-helper';
import Modal from '../../commons/modal'
import DropZone from 'react-dropzone';
import DocumentIcon from '../../../assets/images/Doc.M.png';
import TablePagination from '../../commons/table-pagination/TablePagination'


const UploadManualDCA = React.forwardRef((props, ref) => {
    const {manualUploadedDca= [], currentUserDetails = {}, updateSnackBar = ()=>{},
    getManuallyUploadedDCA = () =>{}, setCurrentTab
} = props;
    const [openDCADocumentModal, setOpenDCADocumentModal] = useState(false);
    const [dataDictDocumentDetails, setDataDictDocumentDetails] = useState({});
    const [dataDictUploadPercentage, setDataDictUploadPercentage] = useState(0);
    const [isFormUpdating, setIsFormUpdating] = useState(false);
    const [deleteDCAModal, setDeleteDCAModal] = useState(false);
    const [currentManualDCAToDelete, setCurrentManualDCAToDelete] = useState({});
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const maxCount = manualUploadedDca?.length ? Math.ceil(manualUploadedDca.length / 5) : 0;

    const setUserPage = number => {
        setPage(number);
    }

    const onDataDictDocumentDrop = async (file) => {
        setDataDictDocumentDetails(file);
    }
    const onDCADocumentUploadConfirm = async () => {
        try {
            if (!isEmpty(dataDictDocumentDetails)) {
                const file = dataDictDocumentDetails;
                const preSignedData = await getManualDCAUploadPresignedData(file[0].name);
                let config = {
                    onUploadProgress: function (progressEvent) {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setDataDictUploadPercentage(percentCompleted);
                    }
                };
                setIsFormUpdating(true);
                const res = await uploadFileToS3(file[0], preSignedData, config);
                if (res == 204 || res == 202 || res == 200) {
                    setOpenDCADocumentModal(false);
                    setDataDictUploadPercentage(0);
                    updateSnackBar('Document uploaded successfully.', 'success');
                    setDataDictDocumentDetails({});
                    getManuallyUploadedDCA();

                } else {
                    setOpenDCADocumentModal(false);
                    updateSnackBar('Document uploaded failed.', 'error');
                    setDataDictDocumentDetails({});
                }
            }
        } catch (error) {
            console.log(error);
            console.log('error in uploading', error);
            setDataDictUploadPercentage(0);
            updateSnackBar('Document uploaded failed.', 'error');
            setOpenDCADocumentModal(false);
        }finally{
            setIsFormUpdating(false);
        }
    }
    const onDownloadDCAClick = async (docName) => {
        try {
            const res = await getDownloadUrlForDCAManualUploads(docName);
            window.open(res, '_blank');
        } catch (error) {
            updateSnackBar('Error in download document', 'error');
        }
    }
    const onDeleteManualUploadedDCA = (row) => {
        setDeleteDCAModal(true);
        setCurrentManualDCAToDelete(row);
    }
    const closeDCADocumentModal = () => {
        setOpenDCADocumentModal(false);
        setDataDictDocumentDetails({});
    }
    const onDeleteDCAConfirm = async () => {
        try {
            setDeleteDCAModal(false);
            const res = await deleteManualUploadedDCA(currentManualDCAToDelete);
            if (res) {
                updateSnackBar('DCA document deleted successfully', 'success');
                getManuallyUploadedDCA();
            }
        } catch (error) {
            setDeleteDCAModal(false);
            updateSnackBar('Error in deleting DCA document', 'error');
        }
    }
    const closeDeleteDCAModal = () => {
        setDeleteDCAModal(false);
    }

    const UPLOAD_DCA_DOCUMENT = {
        modalTitle: 'Upload DCA document',
        positiveButtonText: 'Upload',
        negativeButtonText: "Cancel",
        positiveButtonAction: onDCADocumentUploadConfirm,
        negativeButtonAction: closeDCADocumentModal,
        popupButtonDisable: isFormUpdating
    }

    const DELETE_UPLOADED_DCA_MODAL_PROPS = {
        modalTitle: 'Are you sure you want to delete this DCA document?',
        positiveButtonText: 'Delete',
        negativeButtonText: "Cancel",
        positiveButtonAction: onDeleteDCAConfirm,
        negativeButtonAction: closeDeleteDCAModal
    }

    return (
        <div ref={ref} style={{padding: '25px',
            background: '#fff',
            boxShadow: '0px 0px 15px #e7eaf2',
            border: '1px solid #E7EAF2', margin: '20px 0'}}>
            <Modal
                open={deleteDCAModal}
                handleClose={closeDeleteDCAModal}
                dialogProps={DELETE_UPLOADED_DCA_MODAL_PROPS}
            >
            </Modal>
             <Modal
                open={openDCADocumentModal}
                handleClose={closeDCADocumentModal}
                dialogProps={UPLOAD_DCA_DOCUMENT}
            >
                <Grid item lg={12} md={12} sx={12}>
                    <label id='cvb-adminDashboard-manualDCAUpload-selectDCADoc' className={classes.dcaTitles}>Select DCA document</label>
                    <div className={classes.dropZoneButtonContainer}>
                        <DropZone
                            onDrop={(acceptedFiles) => onDataDictDocumentDrop(acceptedFiles)}
                            multiple={false}
                            id='cvb-adminDashboard-manualDCAUpload-fileDrop'
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input id='cvb-adminDashboard-manualDCAUpload-fileDropInput' {...getInputProps({ accept: 'application/pdf' })} />
                                    <div className={classes.uploadImageDiv}>
                                        <img src={DocumentIcon} alt='document' />
                                        <p className={classes.chooseFileText}>
                                            Drag and drop or
                                                                <span>
                                                <button id='cvb-adminDashboard-manualDCAUpload-selectFileButton' className={`${classes.chooseFileButton}`}>
                                                    Select files
                                                                    </button>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            )}
                        </DropZone>
                    </div>
                    <progress id='cvb-adminDashboard-manualDCAUpload-uploadProgress' className={classes.progressBar} value={dataDictUploadPercentage} max={100} />
                    <p id='cvb-adminDashboard-manualDCAUpload-fileName' className={classes.fileNameAndSize}>{get(dataDictDocumentDetails, '[0].name', '')}
                        <span id='cvb-adminDashboard-manualDCAUpload-fileSize'>{!isEmpty(dataDictDocumentDetails) ? `${get(dataDictDocumentDetails, '[0].size', '')}'kb'` : ''} </span>
                    </p>
                </Grid>
            </Modal>

            <div className={classes.uploadDcaButtonWrapper} style={{gap: 20}}>
                {(props.currentTab === null) && <label id='cvb-adminDashboard-manualDCAUpload-uploadedDocHeading' className={classes.dcaTitles} style={{ marginRight: 'auto', alignSelf: 'flex-end'}}>Uploaded DCA documents</label>}
                {(props.userDetails?.resourceActions?.dashboard?.upload_manual_dca) && (
                    <>
                        {props.currentTab === null && <button id='cvb-adminDashboard-manualDCAUpload-viewButton' onClick={() => {setCurrentTab('upload-dca'); props.setDashboardTitle('Upload DCA documents')}} className={`transparent-button`} style={{padding: '8px 20px'}}>View All Documents</button>}
                        <button id='cvb-adminDashboard-manualDCAUpload-uploadButton' onClick={() => setOpenDCADocumentModal(true)} className={`solid-button ${classes.uploadButton}`}>Upload DCA Document</button>
                    </>
                )}
            </div>

            {
                props.userDetails?.resourceActions?.dashboard?.list_manual_dca ?
                    <div className={classes.graphsBlock} >

                        <div className={classes.manualDCATable}>

                            <Grid item xs={12}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.paddingLeft} id='cvb-adminDashboard-manualDCAUpload-uploadedDocTable-fileName'>File Name</TableCell>
                                            {/* cell for the date of upload */}
                                            <TableCell  width='325px'>Download</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {map(manualUploadedDca, (row) => {
                                            const splitIndex = row.indexOf('/');
                                            const fileName = row.substring(splitIndex + 1);
                                            const userSub = row.substring(0, splitIndex);
                                            const isDocUploadedUser = userSub === currentUserDetails?.attributes?.sub;
                                            return <TableRow key={row} className={classes.row}>
                                                <TableCell id={`cvb-adminDashboard-manualDCAUpload-uploadedDoc-${fileName}`} component="th" scope="row" className={`${classes.leftBorder} ${classes.paddingLeft}`}>
                                                    {fileName}
                                                </TableCell>
                                                {/* cell for the date of upload */}
                                                {/* <TableCell/> */}
                                                <TableCell component="th" scope="row" align="left" width='325px' className={classes.rightBorder}>
                                                    {
                                                        props.userDetails?.resourceActions?.dashboard?.download_manual_dca ?
                                                            <button id={`cvb-adminDashboard-manualDCAUpload-uploadedDoc-downloadButton-${fileName}`} onClick={() => onDownloadDCAClick(row)} className={classes.approveRequestButton}>
                                                                Download
                                                                    </button> : null}
                                                    {isDocUploadedUser && props.userDetails?.resourceActions?.dashboard?.delete_manual_dca &&
                                                        <button id={`cvb-adminDashboard-manualDCAUpload-uploadedDoc-deleteButton-${fileName}`} onClick={() => onDeleteManualUploadedDCA(row)} className={`${classes.approveRequestButton} ${classes.delete}`}>
                                                            Delete
                                                                </button>}
                                                </TableCell>
                                            </TableRow>
                                        }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                                    </TableBody>
                                </Table>
                                {
                                    maxCount > 0 &&
                                    <div className={classes.tablePagination}>
                                        <TablePagination
                                            maxCount={maxCount}
                                            page={page}
                                            setPage={setUserPage}
                                        />

                                    </div>
                                }
                            </Grid>
                        </div>
                    </div> : null}
        </div>
    )
})

UploadManualDCA.displayName = 'UploadManualDCA'

export default UploadManualDCA;
