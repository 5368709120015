import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import MuiPaper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import Classes from './index.module.css'
import FileIcon from '../../../assets/images/fileIcon.png'
import ImageVerticalIcon from '../../../assets/icons/MoreOptionsVertical.S-dark.svg';
import Checkbox from '@mui/material/Checkbox'
import Popover from '@mui/material/Popover'
import Modal from '../../commons/modal'
import get from 'lodash/get'
import { getFileType } from '../../../utils/stringFormatter';
import AddUsersToShare from '../add-users-to-share';
import { shareNotebook, getSharedUserForNotebook, unshareNotebook, copyNotebookToOwnedWorkspace } from '../../../api/notebook-api';
import LinearProgress from '@mui/material/LinearProgress';
import ImgFolderIcon from '../../../assets/icons/workspace-folder.svg';
import DropDown from '../../commons/dropdown';
import ErrorText from '../error-text';
import CustomLoader from '../../commons/custom-loader';
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty';
import CartIcon from "../../../assets/images/workspaceCartIcon.svg"

const Paper = withStyles(theme => ({
    root: {
        background: '#FFFFFF',
        border: '1px solid #DEDEDE',
        borderRadius: 2,
        opacity: 1,
        height: 260,
        boxShadow: '0px 0px 0px',
        position: 'relative',
        padding: '8px 16px 0px 16px'
    }
}))(MuiPaper);

const WorkspaceFileCard = props => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openShareNotebookCopyModal, setOpenShareNotebookCopyModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [copyToWorkspace, setCopyToWorkspace] = useState(false);
    const [ workspaceValues, setWorkspaceValues ] = useState([]);
    const [ selectedWorkspace, setSelectedWorkspace ] = useState('');
    const { fileData = {} } = props;
    const fileDataType = getFileType(get(fileData, 'Key', ''));
    const fileId = get(props, 'fileData.Key', '');
    const { isChecked = false } = props;
    const { onDeleteFile = () => {} } = props;
    const { addFileToCart = () => {} } = props;
    const [openSampleNotebookShareModal, setOpenSampleNotebookShareModal ] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const { workspaceId = '' } = props;
    const [ sharedUserList, setSharedUserList ] = useState([]);
    const [ shareErrorMessage, setShareErrorMessage ] = useState('');
    const { folderDirectory = '' } = props;

    const onMoreOptionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClosePopover = () => {
        setAnchorEl(null);
    }
    const onShareSelectedFile = () => {
        try{
            setOpenSampleNotebookShareModal(true);
            getSharedUsersList();
        }catch(e){
            console.log('share error file', e);
        }
    }
    const closeShareSampleNotebookModal = () => {
        try{
            setOpenSampleNotebookShareModal(false);
        }catch(e){
            console.log('share error file', e);
        }
    }
    const onShareSampleNotebookConfirm = () => {
        try{
            alert('share');
            setOpenSampleNotebookShareModal(false);
        }catch(e){
            console.log('share error file', e);
        }
    }
    const onOpenDeleteModal = () => {
        setOpenDeleteModal(true)
    }
    const closeDeleteModal = () =>{
        setOpenDeleteModal(false)
    }
    const onDeleteSelectedFile = () => {
        try{
            onDeleteFile(fileId)
            setOpenDeleteModal(false)
        }catch(e){
            console.log('share error file', e);
        }
    }
    const onAddFileToCart = () => {
        addFileToCart(fileId);
    }
    const onCheckboxClick = (id, checked) => {
        props.onFileSelected(id, checked);
    }

    const getSharedUsersList = async () => {
        try{
            const res = await getSharedUserForNotebook(`${folderDirectory}${fileId}`);
            setSharedUserList(res);
        }catch(error){
            console.log('error in getting shared user list', error);
        }
    }
    const onAddUserClick = async (sharedWith) => {
        try {
            const id = fileId;
            const fileKey = [`${folderDirectory}${id}`];
            const data = {...sharedWith, keys: fileKey, workspaceAccountNumber: workspaceId};
            const res = await shareNotebook(data);
            if (res == 200 || res == 202) {
                getSharedUsersList();
                setShareErrorMessage('');
            }
        } catch (error) {
            setShareErrorMessage('Error in sharing file with user');
            console.log('error in adding users', error);
        }
    }
    const unshareNotebookForSelectedUser = async (email) => {
        try {
            const data = {
                "sharedWith": email,
                "key": `${folderDirectory}${fileId}`
            };
            const res = await unshareNotebook(data);
            if (res == 200 || res == 204 || res == 202) {
                getSharedUsersList();
            }
        } catch (error) {
            console.log('error ins start or stop');
        }
    }
    const openPopover = Boolean(anchorEl);

    const SHARE_SAMPLE_NOTEBOOK_MODAL_PROPS = {
        modalTitle: "Share Notebook",
        positiveButtonAction: onShareSampleNotebookConfirm,
        negativeButtonAction: closeShareSampleNotebookModal
    }
    const DELETE_MODAL_PROPS = {
        modalTitle: "Are you sure you want to delete this notebook",
        positiveButtonText: "Delete Notebook",
        negativeButtonText: "Cancel",
        positiveButtonAction: onDeleteSelectedFile,
        negativeButtonAction: closeDeleteModal
    }
    const fileDetails = {
        'type': fileDataType,
        'id': fileId,
        'size': get(fileData, 'Size', '')
    }
    const onWorkspaceOptionChange = async (value) => {
        setCopyToWorkspace(false);
        setSelectedWorkspace(value);
    }
    
    useEffect(() => {
        const filteredWorkspaces = props.allWorkspaces.filter((workspace) => workspace['pi_owner']);
        const workspacesIds = map(filteredWorkspaces, (workspace) => workspace['workspace_id']);
        setWorkspaceValues(workspacesIds);
        if(workspacesIds?.length === 1){
            setSelectedWorkspace(workspacesIds[0]);
        }
    },[props.allWorkspaces])
    const onShareNotebookCopyConfirm = async () => {
        try {
            if(selectedWorkspace){
                const body = {
                    "own_workspace_id":selectedWorkspace,
                    "shared_workspace_id":workspaceId,
                    "object_s3_key":`${folderDirectory}${fileId}`
                }
                const data = await copyNotebookToOwnedWorkspace(body);
                closeShareNotebookCopyModal();
                props.updateSnackBar(`${fileId} successfully copied to Workspace`, "Success");
            }else{
                setCopyToWorkspace(true);
            }
            
        } catch (error) {
            console.log(error);
            props.updateSnackBar(`Error in copying the notebook`, "Error");
        } 
    }
    const closeShareNotebookCopyModal = () => {
        setOpenShareNotebookCopyModal(false);
    }
    const onCopyToOwnedWorkspace = () => {
        setOpenShareNotebookCopyModal(true)
    }
    const SHARE_NOTEBOOK_COPY_MODAL_PROPS = {
        modalTitle: 'Copy Notebook to my Workspace',
        positiveButtonText: 'Copy to Workspace',
        negativeButtonText: "Cancel",
        positiveButtonAction: onShareNotebookCopyConfirm,
        negativeButtonAction: closeShareNotebookCopyModal,
        popupButtonDisable: loading
    }
    return (
        <Grid item xs={12} lg={2} md={6} className={Classes.positionRelative}>
        {props.index === 0 ?

        props.progressBar !== false?
        <div className={props.uploadClass !==false ? `${Classes.fileLoaderUploadFolder}`: `${Classes.fileLoader}`}>
            <div className={Classes.blur}>
                <div className={Classes.filenameContainer}>
                    <img alt='file' src={FileIcon} className={Classes.fileIconStyle}/>
                    <p id='cvb-workspaceFileCard-fileKey' className={Classes.filenameText}>{get(fileData, 'Key', '')}</p>
                </div>
                </div>
                    <div className={Classes.progressBarAlign}>
                        <LinearProgress id='cvb-workspaceFileCard-progress' value={props.progress} />
                    </div>
                    <div className={Classes.blur}>
                <div className={Classes.fileDetailsContainer}>
                    <div className={Classes.fileInfoDiv}>
                        <p id='cvb-workspaceFileCard-type-label' className={Classes.fileInfoTitle}>Type</p>
                        <p className={Classes.fileInfoDesc}></p>
                    </div>
                    <div className={Classes.fileInfoDiv}>
                        <p id='cvb-workspaceFileCard-size-label' className={Classes.fileInfoTitle}>Size</p>
                        <p className={Classes.fileInfoDesc}></p>
                    </div>
                </div>
                </div>
            </div>
            :null
        :null}
             <Modal
                open={openSampleNotebookShareModal}
                handleClose={closeShareSampleNotebookModal}
                dialogProps={SHARE_SAMPLE_NOTEBOOK_MODAL_PROPS}
                disableBottomHorizontalDivider
            >
                <AddUsersToShare
                    workspaceDetails={fileDetails}
                    onAddUserClick={onAddUserClick}
                    unshareForSelectedUser={unshareNotebookForSelectedUser}
                    id={fileId}
                    sharedUsersList={sharedUserList}
                    usersForShare={props.usersForShare}
                    errorMessage={shareErrorMessage}
                />
            </Modal>
            <Modal
                open={openDeleteModal}
                handleClose={closeDeleteModal}
                dialogProps={DELETE_MODAL_PROPS}
                disableTitleDivider
                >
            </Modal>

            <Modal
                open={openShareNotebookCopyModal}
                handleClose={closeShareNotebookCopyModal}
                dialogProps={SHARE_NOTEBOOK_COPY_MODAL_PROPS}
            >
                <div>
                    { workspaceValues?.length > 1 ?
                        <div className={Classes.selectWorkspaceContainer}>
                            <Grid item xs={6}>
                            <DropDown
                                values={workspaceValues}
                                handleFilterStateChange={onWorkspaceOptionChange}
                                selectedFilter={selectedWorkspace}
                                placeholder={'Select workspace'}
                            />
                            {
                                copyToWorkspace && isEmpty(selectedWorkspace) ?
                                <div className={Classes.errorTextDiv}>
                                <ErrorText errorMessage='Please select workspace'/>
                                </div> : null
                            }
                            {
                                loading ? 
                                <CustomLoader styleProp={{ marginTop: '25px' }} /> : null
                            }
                            </Grid>
                        </div>   
                        :
                        <p> You are copying Notebook to <span className={Classes.workspaceName}><b>{workspaceValues[0]}</b></span> Workspace. 
                        </p>
                    }
                </div>
            </Modal>
            <Paper>

                <div id='cvb-workspaceFileCard-container' className={Classes.fileCardContentContainer} onClick={() => props.folderDirectoryApi(get(fileData, 'Prefix', ''))}>
                    <div className={Classes.checkboxContainer}>
                    {get(fileData, 'folderActive', '') !== true ? <Checkbox
                      checked={isChecked}
                      color="primary"
                      id='cvb-workspaceFileCard-checkbox'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      onChange={(e) => onCheckboxClick(fileId, e.target.checked)}
                    />
                    : ""}
                    </div>
                    <div className={Classes.dropdownPopupContainer}>
                    {get(fileData, 'folderActive', '') !== true ? <img id='cvb-workspaceFileCard-moreOptionsButton' onClick={(e) => onMoreOptionsClick(e)} src={ImageVerticalIcon} alt='more options'/>
                    :""}
                        <Popover
                            id={'WorkspaceFile-popover'}
                            open={openPopover}
                            anchorEl={anchorEl}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                horizontal: 'right',
                            }}
                        >
                            <ul className={Classes.dropdownListContainer}>
                                {
                                    !folderDirectory?.includes("upload/") && !props?.piOwner && workspaceValues?.length ?
                                    <li id='cvb-workspaceFileCard-copyToOwnedWorkspace' onClick={onCopyToOwnedWorkspace} className={Classes.linkIcons}>
                                        Copy to my workspace
                                    </li> : null
                                }
                                {
                                    !folderDirectory?.includes("upload/") ?
                                    <li id='cvb-workspaceFileCard-shareButton' onClick={onShareSelectedFile} className={Classes.linkIcons}>
                                        Share
                                    </li> : null
                                }
                               {folderDirectory?.includes("upload/")? <li id='cvb-workspaceFileCard-deleteButton' onClick={onOpenDeleteModal} className={Classes.linkIcons}>
                                    Delete
                                </li> : null}
                            </ul>
                        </Popover>
                    </div>
                    <div className={Classes.filenameContainer}>
                        {get(fileData, 'folderActive', '') === true ? <img alt='file' src={ImgFolderIcon} className={Classes.fileIconStyle}/>
                        : <img alt='file' src={FileIcon} className={Classes.fileIconStyle}/> }
                        {get(fileData, 'Key', '') !== ""? <p id='cvb-workspaceFileCard-filename' className={Classes.filenameText}>{get(fileData, 'Key', '')}</p>
                        : <p id='cvb-workspaceFileCard-fileNamePrefix' className={Classes.filenameText}>{get(fileData, 'Prefix', '')}</p>}
                    </div>

                    <div className={Classes.fileDetailsContainer}>
                        <div className={Classes.fileInfoDiv}>
                        {get(fileData, 'folderActive', '') !== true ? <div><p id='cvb-workspaceFileCard-type-label' className={Classes.fileInfoTitle}>Type</p>
                            <p id='cvb-workspaceFileCard-type' className={Classes.fileInfoDesc}>{fileDataType}</p></div>
                            : ""
                        }
                        </div>
                        <div className={Classes.fileInfoDiv}>
                        {get(fileData, 'folderActive', '') !== true ? <div><p id='cvb-workspaceFileCard-size-label' className={Classes.fileInfoTitle}>Size</p><p id='cvb-workspaceFileCard-size' className={Classes.fileInfoDesc}>{get(fileData, 'Size', '')}</p></div>
                            :""
                            }
                        </div>
                    </div>
                </div>

                {
                    !folderDirectory?.includes("upload/") && !get(fileData, 'folderActive', '') ?
                    <div className={Classes.downloadTextContainer}>
                        <button
                            id='cvb-workspaceFileCard-addToCart'
                            onClick={onAddFileToCart}
                            className={`medium-size-button ${Classes.addToCartButton}`} >
                            <img src={CartIcon} /> Add to cart
                        </button>
                    </div> : null
                }
            </Paper>
        </Grid>
    )
}

export default WorkspaceFileCard;