import { 
    SET_DIF_STUDY_NAME,
    SET_DIF_STUDY_OBJECTIVE,
    SET_DIF_STUDY_DESCRIPTION,
    SET_DIF_STUDY_DESIGN,
    SET_DIF_STUDY_LOGO_FILE,
    SET_DIF_STUDY_NUMBER_OF_GRANTS,
    SET_DIF_STUDY_DURATION_OF_MONTHS,
    SET_DIF_STUDY_LOCATION_OF_STUDY,
    SET_DIF_STUDY_PUBLICATIONS,
    SET_DIF_STUDY_PUBLICATION_NAME,
    SET_DIF_STUDY_PUBLICATION_URL,
    REMOVE_DIF_STUDY_PUBLICATIONS,
    SET_DIF_STUDY_AGENCY_NAME,
    SET_DIF_STUDY_AGENCY_NUMBER,
    SET_DIF_STUDY_DATA,
    RESET_DIF_STUDY_DATA,
    SET_DIF_STUDY_LOGO_S3_KEY,
    SET_DIF_STUDY_OPT_IN
} from '../action-types'

export const setDifStudyName = (value) => {
    return {
        type: SET_DIF_STUDY_NAME,
        payload: value
    }
}
export const setDifStudyObjective = (value) => {
    return {
        type: SET_DIF_STUDY_OBJECTIVE,
        payload: value
    }
}

export const setDifStudyDescription = (value) => {
    return {
        type: SET_DIF_STUDY_DESCRIPTION,
        payload: value
    }
}
export const setDifStudyDesign = (value) => {
    return {
        type: SET_DIF_STUDY_DESIGN,
        payload: value
    }
}
export const setDifStudyLogoFile = (value) => {
    return {
        type: SET_DIF_STUDY_LOGO_FILE,
        payload: value
    }
}
export const setDifStudyNumberOfGrants = (value) => {
    return {
        type: SET_DIF_STUDY_NUMBER_OF_GRANTS,
        payload: value
    }
}
export const setDifStudyDurationOfMonths = (value) => {
    return {
        type: SET_DIF_STUDY_DURATION_OF_MONTHS,
        payload: value
    }
}
export const setDifStudyLocationOfStudy = (value) => {
    return {
        type: SET_DIF_STUDY_LOCATION_OF_STUDY,
        payload: value
    }
}
export const setDifStudyPublications = (value) => {
    return {
        type: SET_DIF_STUDY_PUBLICATIONS,
        payload: value
    }
}
export const setDifStudyLogoFileKey = (value) => {
    return {
        type: SET_DIF_STUDY_LOGO_S3_KEY,
        payload: value
    }
}
export const setDifStudyPublicationName = (value, id) => {
    return {
        type: SET_DIF_STUDY_PUBLICATION_NAME,
        payload: {
            value,
            id
        }
    }
}
export const setDifStudyPublicationURL = (value, id) => {
    return {
        type: SET_DIF_STUDY_PUBLICATION_URL,
        payload: {
            value,
            id
        }
    }
}
export const removeDifStudyPublications = (value) => {
    return {
        type: REMOVE_DIF_STUDY_PUBLICATIONS,
        payload: value
    }
}
export const setDifStudyAgencyName = (value, id) => {
    return {
        type: SET_DIF_STUDY_AGENCY_NAME,
        payload: {
            value,
            id
        }
    }
}
export const setDifStudyAgencyNumber = (value, id) => {
    return {
        type: SET_DIF_STUDY_AGENCY_NUMBER,
        payload: {
            value,
            id
        }
    }
}
export const setStudyData = (value) => {
    return {
        type: SET_DIF_STUDY_DATA,
        payload: value
    }
}
export const resetDifStudyData = () => {
    return {
        type: RESET_DIF_STUDY_DATA,
    }
}
export const setDifStudyOptIn = (value) => {
    return {
        type: SET_DIF_STUDY_OPT_IN,
        payload: value
    }
}
