import React, { useEffect, useState } from 'react'
import Classes from './index.module.css';
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import Checkbox from '@mui/material/Checkbox'
import get from 'lodash/get'
import map from 'lodash/map'
import ClosePng from '../../../assets/images/close.png'
import isEmpty from 'lodash/isEmpty'
import ErrorText from '../error-text';
import { validateEmailAddress } from '../../../utils/input-validations' 
import { DCA_FORM_COMPLETED_TEXT } from '../../../constants/strings';
const AuthorizedDataSubmitters = props => {
    const {
        authorizedSubmitters,
        updateAuthorizeSubmitterFirstName,
        updateAuthorizeSubmitterMiddleName,
        updateAuthorizeSubmitterLastName,
        updateAuthorizeSubmitterAffiliation,
        updateAuthorizeSubmitterInstitutionalEmail,
        addAuthorizeSubmitter,
        currentDCAFormStage,

        removeAuthorizeSubmitter,
        updateAuthorizeSubmitterCanUpload,
        updateAuthorizeSubmitterCanModify,
        updateAuthorizeSubmitterCanDelete,
    } = props;
    const [ addUserClick, setAddUsersClick ] = useState(false);
    const disableTextInput =  (currentDCAFormStage === DCA_FORM_COMPLETED_TEXT);
    
    useEffect(() => {
        let element = document.getElementById('cvb-dataContributorAgreement-submitButton');
        if(element){
            document.getElementById('cvb-dataContributorAgreement-submitButton').addEventListener('click', () => {
                setAddUsersClick(true);
            });
        }
    }, [])
    const onFirstNameChange = (event, id) => {
        updateAuthorizeSubmitterFirstName(event.target.value, id);
    }
    const onMiddleNameChange = (event, id) => {
        updateAuthorizeSubmitterMiddleName(event.target.value, id);
    }
    const onLastNameChange = (event, id) => {
        updateAuthorizeSubmitterLastName(event.target.value, id);
    }
    const onAffiliationChange = (event, id) => {
        updateAuthorizeSubmitterAffiliation(event.target.value, id);
    }
    const onInstitutionalEmailChange = (event, id) => {
        updateAuthorizeSubmitterInstitutionalEmail(event.target.value, id);
    }
    const onCanUploadChange = (event, id) => {
        updateAuthorizeSubmitterCanUpload(event.target.checked, id);
    }
    const onCanModifyChange = (event, id) => {
        updateAuthorizeSubmitterCanModify(event.target.checked, id);
    }
    const onCanDeleteChange = (event, id) => {
        updateAuthorizeSubmitterCanDelete(event.target.checked, id);
    }
    const removeUser = (id) => {
        if (!disableTextInput) {
            removeAuthorizeSubmitter(id);
        }
        if(id === (authorizedSubmitters.length-1) ){
            setAddUsersClick(false);
        }
    }
    const validUsersData = () => {
        let isFormValid = false;
        if (!isEmpty(authorizedSubmitters)) {
            map(authorizedSubmitters, (usersData) => {
                for (let key in usersData) {
                    const data = usersData[key];
                    const isEmailValid = key === 'institutionalEmail' ? validateEmailAddress(data) : true;
                    const isEmptyField = (key === 'canUpload' || key === 'canModify' || key === 'canDelete') ? true : ((data === null && key !== 'middleName') ? false : (key !== 'middleName' ? !isEmpty(data) : true));
                    if (isEmptyField && isEmailValid) {
                        isFormValid = true;
                    } else {
                        isFormValid = false;
                        return false;
                    }
                }
            })
        }
        else {
            isFormValid = true
        };
        return isFormValid;
    }
    const onAddUserClick = () => {
       if(!disableTextInput){ 
        setAddUsersClick(true);
        const isValidUserDetails = validUsersData();
        if (isValidUserDetails) {
            addAuthorizeSubmitter({
                "firstName": '',
                "middleName": '',
                "lastName": '',
                "affiliation": '',
                "institutionalEmail": '',
                "canUpload": null,
                "canModify": null,
                "canDelete": null
            });
            setAddUsersClick(false);
        }}
    }
    return (
        <div className={Classes.container} id='cvb-dca-stage3AuthorizationDataSubmitterContainer'>
            <p className={Classes.formTitle} id='cvb-dca-stage3AuthorizationDataSubmitterHeading'>Authorized Data Submitters</p>
            <p className={Classes.subTitleDesc} id='cvb-dca-stage3AuthorizationDataSubmitterDescription'>Contributor authorizes the individual(s) listed below to, as applicable,
            upload, modify and/or remove Contributed Data on behalf of Contributor through the applicable authorized
            mechanism made available by the BC manager, Contributor may update this list of Authorized Data Submitters at
            any time upon written notice to BC manager in the manner specified (or otherwise expressly authorized) by the BC
            manager.
             </p>
            <div className={Classes.dottedLine}></div>
            {map(authorizedSubmitters, (users, id) => {
                return <div key={`userContainer${id}`}>
                    {!disableTextInput && id > 0 && <div>
                        <img id='cvb-dca-stage3AuthorizationDataSubmitterRemoveLink' src={ClosePng} alt='delete' className={Classes.removeFields} onClick={() => removeUser(id)} />
                    </div>
                    }
                    <Grid container className={Classes.inputContainers} spacing={3}>
                        <Grid item xs={4} className={Classes.fieldCont}>
                            <label htmlFor='firstName' id='cvb-dca-stage3AuthorizationDataSubmitterFirstNameLabel'>First Name<sup> *</sup></label>
                            <OutlinedInput
                                type="text"
                                placeholder="Enter first name"
                                value={get(users, 'firstName', '')}
                                onChange={e => onFirstNameChange(e, id)}
                                fullWidth
                                id={`firstName${id}`}
                                disabled={disableTextInput}
                            />
                            {
                                (addUserClick) && (users['firstName'] === null || isEmpty(`${get(users, 'firstName', '')}`)) &&
                                <ErrorText errorMessage="Enter first name" />
                            }
                        </Grid>
                        <Grid item xs={4} className={Classes.fieldCont}>
                            <label htmlFor='firstName' id='cvb-dca-stage3AuthorizationDataSubmitterMiddleNameLabel'>Middle Name</label>
                            <OutlinedInput
                                type="text"
                                placeholder="Enter middle name"
                                value={get(users, 'middleName', '')}
                                onChange={e => onMiddleNameChange(e, id)}
                                fullWidth
                                id={`middleName${id}`}
                                disabled={disableTextInput}
                            />
                        </Grid>
                        <Grid item xs={4} className={Classes.fieldCont}>
                            <label htmlFor='firstName' id='cvb-dca-stage3AuthorizationDataSubmitterLastNameLabel'>Last Name<sup> *</sup></label>
                            <OutlinedInput
                                type="text"
                                placeholder="Enter last name"
                                value={get(users, 'lastName', '')}
                                onChange={e => onLastNameChange(e, id)}
                                fullWidth
                                id={`lastName${id}`}
                                disabled={disableTextInput}
                            />
                            {
                                (addUserClick) && (users['lastName'] === null ||isEmpty(`${get(users, 'lastName', '')}`)) &&
                                <ErrorText errorMessage="Enter lastName" />
                            }
                        </Grid>
                    </Grid>
                    <Grid container className={Classes.inputContainers} spacing={3}>
                        <Grid item xs={4} className={Classes.fieldCont}>
                            <label htmlFor='firstName' id='cvb-dca-stage3AuthorizationDataSubmitterInstituteNameLabel'>Institution<sup> *</sup></label>
                            <OutlinedInput
                                type="text"
                                placeholder="Enter institution"
                                value={get(users, 'affiliation', '')}
                                onChange={e => onAffiliationChange(e, id)}
                                fullWidth
                                id={`affiliation${id}`}
                                disabled={disableTextInput}
                            />
                            {
                                (addUserClick) && (users['affiliation'] === null || isEmpty(`${get(users, 'affiliation', '')}`)) &&
                                <ErrorText errorMessage="Enter Institution" />
                            }
                        </Grid>
                        <Grid item xs={4} className={Classes.fieldCont}>
                            <label htmlFor='firstName' id='cvb-dca-stage3AuthorizationDataSubmitterInstituteEmailLabel'>Institutional Email<sup> *</sup></label>
                            <OutlinedInput
                                type="text"
                                placeholder="Enter email"
                                value={get(users, 'institutionalEmail', '')}
                                onChange={e => onInstitutionalEmailChange(e, id)}
                                fullWidth
                                id={`email${id}`}
                                disabled={disableTextInput}
                            />
                            {
                                (addUserClick) && (users['lastName'] === null ||!validateEmailAddress(`${get(users, 'institutionalEmail', '')}`)) &&
                                <ErrorText errorMessage="Enter valid email" />
                            }
                        </Grid>
                    </Grid>
                    <label htmlFor='firstName' id='cvb-dca-stage3AuthorizationDataSubmitterAuthorizationLabel'>Authorization</label>
                    <Grid container className={Classes.inputContainers} spacing={3}>
                        <Grid item xs={3} className={Classes.checkBoxContainer}>
                            <Checkbox
                                checked={get(users, 'canUpload', null)}
                                color="primary"
                                onChange={(e) => onCanUploadChange(e, id)}
                                id='uploadCheck'
                                disabled={disableTextInput}
                            />
                            <label htmlFor="uploadCheck" id='cvb-dca-stage3AuthorizationDataSubmitterUploadLabel'>Upload</label>
                        </Grid>
                        <Grid item xs={3} className={Classes.checkBoxContainer}>
                            <Checkbox
                                checked={get(users, 'canModify', null)}
                                color="primary"
                                onChange={(e) => onCanModifyChange(e, id)}
                                id='modifyCheck'
                                disabled={disableTextInput}
                            />
                            <label htmlFor="modifyCheck" id='cvb-dca-stage3AuthorizationDataSubmitterModifyLabel'>Modify</label>
                        </Grid>
                        <Grid item xs={3} className={Classes.checkBoxContainer}>
                            <Checkbox
                                checked={get(users, 'canDelete', null)}
                                color="primary"
                                onChange={(e) => onCanDeleteChange(e, id)}
                                id='removeCheck'
                                disabled={disableTextInput}
                            />
                            <label htmlFor="removeCheck" id='cvb-dca-stage3AuthorizationDataSubmitterRemoveLabel'>Remove</label>
                        </Grid>
                    </Grid>
                    <div className={Classes.dottedLine}></div>
                </div>
            })}
            {!disableTextInput && <p id='cvb-dca-stage3AuthorizationDataSubmitterAddMoreLink' className={Classes.addMoreFields} onClick={onAddUserClick}>+ Add More</p>}
        </div>
    )
}

AuthorizedDataSubmitters.defaultProps = {
    authorizedSubmitters: [],
    isFormSubmitted: false,
    currentDCAFormStage: '',
    updateAuthorizeSubmitterFirstName: () => { },
    updateAuthorizeSubmitterMiddleName: () => { },
    updateAuthorizeSubmitterLastName: () => { },
    updateAuthorizeSubmitterAffiliation: () => { },
    updateAuthorizeSubmitterInstitutionalEmail: () => { },
    addAuthorizeSubmitter: () => { },
    removeAuthorizeSubmitter: () => { },
    updateAuthorizeSubmitterCanUpload: () => { },
    updateAuthorizeSubmitterCanModify: () => { },
    updateAuthorizeSubmitterCanDelete: () => { },

};

export default AuthorizedDataSubmitters;
