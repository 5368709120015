import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { navigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import DropDown from '../../commons/dropdown';
import ExploreAppliedFilters from '../../controls/explore-applied-filters';
import ExploreDataFilters from '../../controls/explore-data-filters';
import classes from './index.module.css';

function StructuredData(props) {
    const [filterCollapseState, setFilterCollapseState] = useState([])
    const [showMoreItems, setShowMoreItems] = useState([])
    const [exploreFilters, updateExploreDataFilters] = useState({})
    const [pageNumber, setPageNumber] = useState(0)

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    // Closes all the opened filter categories
    const resetFilterCollapseState = (numberOfFilters = Object.keys(exploreFilters).length) => {
        // setFilterCollapseState(fill(Array(numberOfFilters), false));
        // setShowMoreItems(fill(Array(numberOfFilters), false))
    }

    useEffect(() => {
        updateExploreDataFilters(props.filters)
    }, [props.filters])

    useEffect(() => {
        getData();
    }, [])

    const getData = async (updatedFilters) => {
        try {
            props.getStudyGalleryData(updatedFilters || exploreFilters, pageNumber, props.totalCount)
        }
        catch (error) {
            console.log(error)
        }
    }

    // Clears applied filter per particular category
    const clearAppliedFiltersPerCategory = category => {
        let newExploreFilters = { ...exploreFilters }
        let histograms = newExploreFilters[category].histogram;
        for (let innerItem of histograms) {
            innerItem.isSelected = false;
        }
        newExploreFilters[category].histogram = histograms;
        updateExploreDataFilters(newExploreFilters);
        setPageNumber(0)
        getData();
    }

    // Removes all the applied filters and
    // close the opened filter categories.
    const resetAllFilters = () => {
        let newExploreFilters = { ...exploreFilters }
        const categories = Object.keys(newExploreFilters);
        for (let item of categories) {
            let histograms = newExploreFilters[item].histogram;
            for (let innerItem of histograms) {
                innerItem.isSelected = false;
            }
            newExploreFilters[item].histogram = histograms;
        }
        updateExploreDataFilters(newExploreFilters);
        setPageNumber(0)
        getData(newExploreFilters)
        resetFilterCollapseState();
    }

    // Toggle open and close of a filter category
    const handleFilterCollapseStateChange = (index, value) => {
        const newState = [...filterCollapseState];
        newState[index] = value;
        setFilterCollapseState(newState)
    }

    // Toggle show more per filter
    const handleFilterShowMoreChange = (index, value) => {
        const newState = [...showMoreItems];
        newState[index] = value;
        setShowMoreItems(newState)
    }


    //toggles all items in a filter
    const togglesAllItemsInFilter = (category, value) => {
        let newExploreFilters = { ...exploreFilters }
        let histograms = newExploreFilters[category].histogram;
        for (let innerItem of histograms) {
            innerItem.isSelected = value;
        }
        newExploreFilters[category].histogram = histograms;
        updateExploreDataFilters(newExploreFilters);
    }

    // API call to get filters data
    useEffect(() => {
        //props.getStudyGalleryFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // Handles the filters items state change ( Checked | Unchecked )
    // e: event to get the checked value
    // filterCatCode: Category code among the filter category
    // ItemCode: code of the item which is checked/ Unchecked
    const onChangeFilterItemState = (e, filterCatCode, itemCode) => {
        // const histograms = exploreFilters[filterCatCode].histogram;
        // const indexFound = findIndex(histograms, item => item.key === itemCode);
        // histograms[indexFound].isSelected = e === true ? true : e?.target?.checked || false;
        // const updateExploreFilters = exploreFilters;
        // updateExploreFilters[filterCatCode].histogram = histograms;
        // updateExploreDataFilters({ ...updateExploreFilters })
        // setPageNumber(0)
        // getData(updateExploreFilters);
    }

    const sortOptions = ["Name", "Designation", "Organization"]

    return (
        <div className={classes.innerContainer}>
            <Grid container className={classes.searchBackground}>
                <Grid item xs={12} lg={12} md={12}>
                    <div className={classes.sectionHeaderContainer}>
                        <div className={classes.gridTitle}><h2>My Uploads- Structured Data</h2></div>
                    </div>
                </Grid>


            </Grid>

            <div className={classes.uploadStatsContainer}>
                <div className={classes.uploadStatsLeftContainer}>
                    <Grid container>
                        <Grid item xs={8} lg={8} md={8}>
                            <div className={classes.uploadStatsContainer}>
                                <Grid container spacing={3} >
                                    <Grid item xs={4} lg={4} md={4} className={classes.uploadStatsItem}>
                                        <div className={classes.totalRecords}>
                                            <p>Total Records</p>
                                            <h3>300</h3>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.uploadStatsRightContainer}>
                    <button onClick={() => navigate("/my-uploads/structured-data/upload-data")} 
                    className={`medium-size-button solid-button ${classes.uploadDataButton}`}>
                        Upload Structured Data</button>
                </div>
            </div>

            <div className={classes.filterContentContainer}>
                <Grid item lg={3}>
                    <ExploreDataFilters
                        filterCollapseState={filterCollapseState}
                        resetAllFilters={resetAllFilters}
                        exploreFilters={exploreFilters}
                        onChangeFilterItemState={onChangeFilterItemState}
                        handleFilterCollapseStateChange={handleFilterCollapseStateChange}
                        resetFilterCollapseState={resetFilterCollapseState}
                        handleFilterShowMoreChange={handleFilterShowMoreChange}
                        showMoreItems={showMoreItems}
                        togglesAllItemsInFilter={togglesAllItemsInFilter} />
                </Grid>
                <Grid item lg={9} className={classes.contentBlock}>
                    <ExploreAppliedFilters
                        exploreFilters={exploreFilters}
                        onChangeFilterItemState={onChangeFilterItemState}
                        resetAllFilters={resetAllFilters}
                        clearAppliedFiltersPerCategory={clearAppliedFiltersPerCategory} />
                    <div>

                        <div className={classes.uploadGridContainer}>
                            <Grid item lg={12}>
                                <div className={classes.tableContainerOuterBox}>
                                    <div className={classes.tableContainerInnerBox}>
                                        <Grid item lg={7} >
                                            <div className={classes.sortUserOptions}>
                                                <div className={classes.sortByTitle}>Sort By</div>
                                                <div className={classes.searchSortDropdown}>
                                                    <DropDown
                                                        className={classes.searchSortDropdown}
                                                        values={sortOptions}
                                                        placeholder={'Select'}
                                                    />
                                                </div>
                                                <p className={classes.resultsCount}>
                                                    Showing 1 of 200
                                                </p>
                                            </div>
                                        </Grid>
                                        <TableContainer
                                            component={Paper} 
                                            elevation={0}
                                            className={classes.tableContainer}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell width="10%" align="left">File Name</TableCell>
                                                        <TableCell width="15%" align="left">Project Name</TableCell>
                                                        <TableCell width="10%" align="left">File Size</TableCell>
                                                        <TableCell width="15%" align="left">Updated On</TableCell>
                                                        <TableCell width="10%" align="left">Total</TableCell>
                                                        <TableCell width="10%" align="left">Successful</TableCell>
                                                        <TableCell width="10%" align="left">Failed</TableCell>
                                                        <TableCell width="20%" align="left"></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow >
                                                        <TableCell align="left">MDFJ-12</TableCell>
                                                        <TableCell align="right">20</TableCell>
                                                        <TableCell align="right">20012</TableCell>
                                                        <TableCell align="right">222</TableCell>
                                                        <TableCell align="right">222</TableCell>
                                                        <TableCell align="right">222</TableCell>
                                                        <TableCell align="right">222</TableCell>
                                                        <TableCell align="center">
                                                            <button className={classes.projectUploadSolidButtonTransparent}
                                                                onClick={() => navigate("/my-uploads/structured-data/view-status")}>
                                                                Select Project</button>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow >
                                                        <TableCell align="left">MDFJ-12</TableCell>
                                                        <TableCell align="right">20</TableCell>
                                                        <TableCell align="right">20012</TableCell>
                                                        <TableCell align="right">222</TableCell>
                                                        <TableCell align="right">222</TableCell>
                                                        <TableCell align="right">222</TableCell>
                                                        <TableCell align="right">222</TableCell>
                                                        <TableCell align="center">
                                                            <button className={classes.projectUploadSolidButtonTransparent}
                                                                onClick={() => navigate("/my-uploads/structured-data/view-status")}>
                                                                Select Project</button>
                                                        </TableCell>
                                                    </TableRow>

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </div>
        </div >
    )
}

export default StructuredData;
