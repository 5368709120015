import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import { CohortInfoStudies } from '.';
import CohortStatistics from './tabs/CohortStatistics';
import CohortAttribute from './tabs/CohortCriteria';
import {
  getStudiesForCohort,
  getStudyParticipantsForCohort,
} from '../../../service/CohortsService';
import ExportWorkspaceModal from '../../commons/export-workspace-modal/ExportWorkspaceModal';
import { sendGAEvents } from '../../../api/generic-api';
import { exportCohortToWorkspace } from '../../../api/saved-search-api';
import { isEmpty, map, get } from 'lodash';
import { deleteCohort } from '../../../api/analysis-api';
import {
  getWorkspaceDetails
} from '../../../api/workspace-api/workspace-api';
import {getAWSConfigObject} from "../../../utils/aws-sdk-helper";
import {getObjectIdsApi} from "../../../api/graphql/exploreData";

import AWS from 'aws-sdk';

const [TAB_INFO, TAB_STATS, TAB_CRITERIA] = [0, 1, 2];
const useStyles = makeStyles((theme) => ({
  tabs: {
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
  },
  header: {
    borderBottom: '1px solid #D0D5DD',
    backgroundColor: 'white',
  },
  footer: {
    backgroundColor: 'white',
    boxShadow:
        '0px -4px 8px rgba(16, 24, 40, 0.1), 0px -2px 4px rgba(16, 24, 40, 0.06)',
    '& .MuiButton-root': {
      flex: 1,
      padding: '10px 18px',
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`cohortInfo-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const CohortInfo = ({
                             cohortInfo,
                             cohortStudies,
                             closeCohortInfo,
                             allWorkspaces,
                             updateCohortsRefresh,
                             updateSelectedCohorts,
                             ...props
                           }) => {
  const styles = useStyles();
  const [selectedTab, setSelectedTab] = React.useState(TAB_INFO);
  const [loading, setLoading] = React.useState(false);
  const [exportWorkspaceModal, setExportWorkspaceModal] = React.useState(false);
  const [removeCohortModal, setRemoveCohortModal] = React.useState(false);
  const [studies, setStudies] = React.useState([]);
  const [participants, setParticipants] = React.useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = React.useState(
      () => allWorkspaces[0]
  );
  const [manifestFileName, setManifestFileName] = React.useState('');
  const [failedWorkspaceValidation, setFailedWorkspaceValidation] = React.useState(false);
  const [defaultSelectedWorkspace, setDefaultSelectedWorkspace] = React.useState('')

  React.useEffect(() => {
    getStudiesForCohort(cohortInfo).then(setStudies);
  }, [cohortInfo]);

  React.useEffect(() => {
    getStudyParticipantsForCohort(cohortInfo).then(setParticipants);
  }, [cohortInfo]);


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const closeExportWorkspaceModal = () => {
    setDefaultSelectedWorkspace('')
    setExportWorkspaceModal(false);
    setFailedWorkspaceValidation(false);
    setManifestFileName('');
  };

  const closeRemoveCohortModal = () => {
    setRemoveCohortModal(false);
  };

  const getCohortName = () => {
    if (cohortInfo?.cohort_name?.length > 20) {
      return cohortInfo?.cohort_name.toString().substring(0, 20) + '...';
    }
    return cohortInfo?.cohort_name || 'Cohort';
  };

  const isUserCohort = (cohort) => {
    return cohort.cohort_type !== 'project';
  };

  const openRemoveCohortModal = () => {
    const cohortName = getCohortName();
    sendGAEvents(
        'remove_cohort',
        'remove_cohort',
        'selected remove cohort option',
        { cohort_name: cohortName }
    );
    setRemoveCohortModal(true);
  };

  const openModal = () => {
    const cohortName = getCohortName();
    sendGAEvents(
        'export_cohort',
        'export_cohort',
        'selected export cohort option',
        { cohort_name: cohortName }
    );
    setExportWorkspaceModal(true);
  };

  const updateSelectedWorkspace= (workspaceId) => {
    setSelectedWorkspace(workspaceId);
  };

  const updateManifestFileName = (manifestFileName) => {
    setManifestFileName(manifestFileName);
  }

  const onExportCohortToWorkspace = async () => {

    if(isEmpty(selectedWorkspace) || isEmpty(manifestFileName)) {
      setExportWorkspaceModal(true);
      setFailedWorkspaceValidation(true)
      return;
    } else {
      setFailedWorkspaceValidation(false);
    }



    try {
      setLoading(true);
      const cohortId = get(cohortInfo, 'uuid', '');
      if (selectedWorkspace && await exportManifest(manifestFileName, selectedWorkspace)) {
        const body = {
          cohort_id: cohortId,
          workspace_id: selectedWorkspace,
        };
        const res = await exportCohortToWorkspace(body);
        props.updateSnackBar(
            `Successfully Exported Cohort to Workspace`,
            'Success'
        );
        closeExportWorkspaceModal();
        setLoading(false);
      } else {
        setLoading(false);
        setExportWorkspaceModal(true);
      }
    } catch (error) {
      props.updateSnackBar(`Failed to Export Cohort to Workspace`, 'Error');
      closeExportWorkspaceModal();
      setLoading(false);
    }
  };

  const exportManifest = async (manifestFileName, selectedWorkspace) => {
    if (manifestFileName && !isEmpty(selectedWorkspace)) {
      let queryFilter = cohortInfo?.cohort_query
      let manifestFiles = []
      const objectIdsResponse = await getObjectIdsApi(queryFilter);
      if(objectIdsResponse?.case?.object_id?.histogram?.length){
        map(objectIdsResponse?.case?.object_id?.histogram, (data)=>{
          if((data?.key || '').toLowerCase() !== "no data"){
            manifestFiles.push({
              "object_id": data?.key
            })
          }
        })
      }

      if (manifestFiles?.length) {
        return uploadCohortManifest(manifestFileName, JSON.stringify(manifestFiles))
      } else {
        props.updateSnackBar(`No files exist for selected cohort`, "Error");
      }
    }
    return false;
  }

  const uploadCohortManifest = async (manifestFileName, manifestFile) => {
    try {
      const workspaceDetails = await getWorkspaceDetails(selectedWorkspace);
      const currentBucketId = get(workspaceDetails, 'scratch_bucket_name', '');

      if (!isEmpty(currentBucketId)) {
        let fileContent = manifestFile;
        let folderDirectory = 'upload/'
        let fileFolderPath = folderDirectory + `${manifestFileName ? manifestFileName : 'manifest'}.json`;
        let res = await getAWSConfigObject(selectedWorkspace);
        if (res) {
          res.get(function (err, response) {
            if (!err) {
              let s3 = new AWS.S3({
                credentials: res,
                region: 'us-east-1',
              })
              const params = {
                Bucket: currentBucketId,
                Key: fileFolderPath,
                Body: fileContent,
                Delimiter: '/',
                ACL: 'bucket-owner-full-control'
              };

              s3.upload(params, function (err, data) {
                props.updateSnackBar(`Successfully uploaded cohort manifest file`, "Success");
              });
            } else {
              props.updateSnackBar("Unable to upload cohort manifest file", "Error");
              return false;
            }
          });
        }
      } else {
        props.updateSnackBar("Unable to upload cohort manifest file", "Error");
        return false;
      }
    } catch (error) {
      return false;
    }
    return true;
  }

  const onRemoveCohort = async () => {
    try {
      const cohortName = getCohortName();
      const cohortId = get(cohortInfo, 'uuid', '');
      sendGAEvents(
          'remove_cohort',
          'remove_cohort',
          'selected remove cohort option',
          { cohort_name: cohortName }
      );
      setLoading(true);
      await deleteCohort(cohortId);
      closeCohortInfo();
      closeRemoveCohortModal();
      updateSelectedCohorts({ [cohortId]: null });
      props.updateSnackBar('Successfully deleted', 'Success');


      updateCohortsRefresh();

    } catch (error) {
      props.updateSnackBar('Unable to delete the cohort', 'Error');
    }
  };

  const EXPORT_WORKSPACE_MODAL_PROPS = {
    modalTitle: 'Export To Workspace',
    positiveButtonText: 'Export To Workspace',
    negativeButtonText: 'Cancel',
    positiveButtonAction: onExportCohortToWorkspace,
    negativeButtonAction: closeExportWorkspaceModal,
    popupButtonDisable: loading,
  };

  return (
      <>
        <Box
            position="relative"
            height="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            overflow="hidden"
            minWidth="40vw"
            maxWidth="600px"
        >
          <Box
              p={2}
              position="sticky"
              top={0}
              zIndex={1}
              className={styles.header}
          >
            <Box position="relative" mb={4}>
              <Typography variant="h2">{cohortInfo.cohort_name}</Typography>
              {studies.length ? <Typography variant="subtitle1">
                From {studies.map((s) => s.studyName).join(', ')}
              </Typography>: null}
              <IconButton
                  onClick={closeCohortInfo}
                  sx={{ position: 'absolute', right: 0, top: 0 }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>

            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                variant="buttons"
                className={styles.tabs}
                centered
                indicatorColor="transparent"
            >
              <Tab label="Project details" value={TAB_INFO} />
              <Tab label="Cohort Statistics" value={TAB_STATS} />
              <Tab label="Cohort Criteria" value={TAB_CRITERIA} />
            </Tabs>
          </Box>

          <Box flex={1} overflow="auto">
            <Box p={2}>
              <TabPanel value={selectedTab} index={TAB_INFO}>
                {(studies && participants) && <CohortInfoStudies studies={studies} participants={participants} />}
              </TabPanel>
              <TabPanel value={selectedTab} index={TAB_STATS}>
                <CohortStatistics cohort={cohortInfo} />
              </TabPanel>
              <TabPanel value={selectedTab} index={TAB_CRITERIA}>
                <CohortAttribute cohort={cohortInfo} />
              </TabPanel>
            </Box>
          </Box>
          <Box
              p={2}
              className={styles.footer}
              justifyContent="center"
              display="flex"
              width="100%"
              bottom={0}
              zIndex={1}
          >
            {isUserCohort(cohortInfo) && (
                <>
                  <Button
                      variant="outlined"
                      color="error"
                      onClick={openRemoveCohortModal}
                  >
                    Remove Cohort
                  </Button>
                  <Button variant="contained" onClick={openModal}>
                    Export to Workspace
                  </Button>
                </>
            )}
          </Box>
        </Box>
        <ExportWorkspaceModal
            open={exportWorkspaceModal}
            handleClose={closeExportWorkspaceModal}
            loading={loading}
            modalProps={EXPORT_WORKSPACE_MODAL_PROPS}
            allWorkspaces={allWorkspaces}
            updateSelectedWorkspace={updateSelectedWorkspace}
            updateManifestFileName={updateManifestFileName}
            failedWorkspaceValidation={failedWorkspaceValidation}
            defaultSelectedWorkspace={defaultSelectedWorkspace}
            setDefaultSelectedWorkspace={setDefaultSelectedWorkspace}
        />
        <Dialog
            open={removeCohortModal}
            onClose={closeRemoveCohortModal}
            maxWidth="xs"
        >
          <DialogTitle>Remove cohort?</DialogTitle>
          <DialogContent>
            Are you sure you want to remove this cohort? This action cannot be
            undone.
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={closeRemoveCohortModal}>
              Cancel
            </Button>
            <Button
                variant="contained"
                color="error"
                onClick={onRemoveCohort}
            >
              Remove Cohort
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
};

export default CohortInfo;
