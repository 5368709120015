import React from 'react'
import Grid from '@mui/material/Grid'
import Classes from './index.module.css'
import Modal from '../../commons/modal'
import { useState, useEffect } from 'react'
import DropDown from '../../commons/dropdown';
import { Auth } from 'aws-amplify';
import { getAllWorkspaces } from '../../../api/workspace-api/workspace-api'
import map from 'lodash/map'
import AWS from 'aws-sdk';
import { getAWSConfigObject } from '../../../utils/aws-sdk-helper'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty';
import {
     getWorkspaceDetails
} from '../../../api/workspace-api/workspace-api';
import CustomLoader from '../../commons/custom-loader';
import ErrorText from '../error-text';
import { ReactComponent as InfoWhiteIcon } from '../../../assets/icons/info-white.svg';
import Tooltip from '@mui/material/Tooltip'
import withStyles from '@mui/styles/withStyles';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#FCF8C8",
        color: "#222222",
        boxShadow: "-1px 1px 106px -61px rgba(74,73,74,1);",
        fontSize: 14,
        fontWeight: "medium",
        marginRight: '3px',
        fontFamily: "Raleway",
        borderRadius: 0,
        padding: 15,
        textAlign: "left",
        '&:hover': {
            cursor: 'pointer',
          },
    },
    arrow: {
        color: "#FCF8C8",
    },
    customWidth: {
        maxWidth: 200,
    },
}))(Tooltip);

const APIKeys = props => {
    const [openShareNotebookCopyModal, setOpenShareNotebookCopyModal] = useState(false);
    const [ workspaceValues, setWorkspaceValues ] = useState([]);
    const [ selectedWorkspace, setSelectedWorkspace ] = useState('');
    const [selectedKeyOption, setSelectedKeyOption] = useState('');
    const [workspaceDetails, setWorkspaceDetails] = useState('');
    const [loading, setLoading] = useState(false);
    const [copyToWorkspace, setCopyToWorkspace] = useState(false);
    const [apiKeysValues, setAPIKeysValues] = useState([]);
    const { setAllWorkspaces = () => { }} = props;
   
    const closeShareNotebookCopyModal = () => {
        setOpenShareNotebookCopyModal(false);
    }
    const loadAllWorkspaces = async () => {
        let apiValues = [];
        let res = [];
        try {
            if(props?.userDetails){
                if(props?.allWorkspaces?.length){
                    res = props?.allWorkspaces;
                }else{
                    res = await getAllWorkspaces();
                }
                if(res?.length){
                    const filteredWorkspaces = res.filter((workspace) => workspace['pi_owner']);
                    setAllWorkspaces(res);
                    const workspacesIds = map(filteredWorkspaces, (workspace) => workspace['workspace_id']);
                    if(workspacesIds?.length && props.userDetails?.resourceActions?.my_stuff?.copy_api_keys_to_workspace){
                        setWorkspaceValues(workspacesIds);
                        apiValues.push('Copy to Workspace');
                        if(workspacesIds?.length === 1){
                            onWorkspaceOptionChange(workspacesIds[0]);
                        }
                    }
                }
            }
        } catch (error) {
            setWorkspaceValues([]);
        } finally {
            setAPIKeysValues(apiValues);
        }
    }

    useEffect(() => {
        loadAllWorkspaces();
    },[props?.userDetails, props?.allWorkspaces]);


    const onShareNotebookCopyConfirm = async () => {
        try {
            if(selectedWorkspace){
                const userTokens = await Auth.currentSession();
                const objectData = {
                    "api_key": userTokens?.refreshToken?.token
                };
                onImageDrop(JSON.stringify(objectData));
            }else{
                setCopyToWorkspace(true);
            }
            
        } catch (error) {
            console.log(error)
        } 
    }

    const onImageDrop = async (file) => {
        const currentBucketId = get(workspaceDetails, 'scratch_bucket_name', '');
        try {
            if(!isEmpty(currentBucketId)){
            setLoading(true);
            let fileContent = file;
            let folderDirectory = 'upload/'
            let fileFolderPath = folderDirectory + 'credentials.json';
            let res = await getAWSConfigObject(selectedWorkspace);
            if (res) {
                res.get(function (err) {
                    if (!err) {
                        let s3 = new AWS.S3({
                            credentials: res,
                            region: 'us-east-1',
                        })
                        const params = {
                            Bucket: currentBucketId,
                            Key: fileFolderPath,
                            Body: fileContent,
                            Delimiter:'/',
                            ACL: 'bucket-owner-full-control'
                        };

                        s3.upload(params, function () {
                            setLoading(false);
                            props.updateSnackBar(`Successfully File Uploaded credentials.json`, "Success");
                            closeShareNotebookCopyModal();
                        });
                    } else {
                        setLoading(false);
                        props.updateSnackBar("Unable to upload file", "Error");
                    }
                });
            }
        } else{
            setLoading(false);
            props.updateSnackBar("Unable to upload file", "Error");
        }
        } catch (error) {
            setLoading(false);
        }
    }

    const SHARE_NOTEBOOK_COPY_MODAL_PROPS = {
        modalTitle: 'Copy API Keys to Workspace',
        positiveButtonText: 'Copy to Workspace',
        negativeButtonText: "Cancel",
        positiveButtonAction: onShareNotebookCopyConfirm,
        negativeButtonAction: closeShareNotebookCopyModal,
        popupButtonDisable: loading
    }
    const onTextChange = async (value) => {
        if(value==="Download"){
            const userTokens = await Auth.currentSession();
            const objectData = {
                "api_key": userTokens?.refreshToken?.token
            };
            exportToJson(objectData);
        }else {
            setOpenShareNotebookCopyModal(true);
        }
        
    }
    const onWorkspaceOptionChange = async (value) => {
        try{
            setCopyToWorkspace(false);
            setLoading(true);
            const res = await getWorkspaceDetails(value);
            //set workspace details
            setWorkspaceDetails(res);
        }catch(error){
            setWorkspaceDetails('');
            props.updateSnackBar("Unable to fetch Workspace Details", "Error");
            console.log(error);
        }finally{
            setLoading(false);
        }
        setSelectedWorkspace(value);
        
    }
    const exportToJson = (objectData) => {
        let filename = "credentials.json";
        let contentType = "application/json;charset=utf-8;";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          let blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(objectData)))], { type: contentType });
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          let a = document.createElement('a');
          a.download = filename;
          a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(objectData));
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }

    

    return (
        <>
        
            <Modal
                open={openShareNotebookCopyModal}
                handleClose={closeShareNotebookCopyModal}
                dialogProps={SHARE_NOTEBOOK_COPY_MODAL_PROPS}
            >
                <div>
                    { workspaceValues?.length > 1 ?
                        <div className={Classes.selectWorkspaceContainer}>
                            <Grid item xs={6}>
                            <DropDown
                                values={workspaceValues}
                                handleFilterStateChange={onWorkspaceOptionChange}
                                selectedFilter={selectedWorkspace}
                                placeholder={'Select workspace'}
                            />
                            {
                                copyToWorkspace && isEmpty(selectedWorkspace) ?
                                <div className={Classes.errorTextDiv}>
                                <ErrorText errorMessage='Please select workspace'/>
                                </div> : null
                            }
                            {
                                loading ? 
                                <CustomLoader styleProp={{ marginTop: '25px' }} /> : null
                            }
                            </Grid>
                        </div>   
                        :
                        <p> You are copying API Keys to <span className={Classes.workspaceName}><b>{workspaceValues[0]}</b></span> Workspace. 
                        </p>
                    }
                    <p className={workspaceValues?.length > 1 ? Classes.workspaceText : ''} >If you share your Workspace, everyone using it performs actions under your API credentials. As the owner of the<br/> BRAINCommons Workspace you are responsible for your team's compliance with the relevant Workspace Services<br/> Agreement. You must ensure that all users accessing your shared Workspace are aware of the terms governing its<br/> use and that they comply with it.</p>
                </div>
            </Modal>
        { 
            props.userDetails?.resourceActions?.my_stuff?.download_api_keys || props.userDetails?.resourceActions?.my_stuff?.copy_api_keys_to_workspace ?
                <div className={Classes.myStuffDopdown}>
                        <LightTooltip placement="bottom" arrow title='To be able to query data from your Workspace you will need to copy your API key from here into your Workspace.'>
                            <InfoWhiteIcon />
                        </LightTooltip>
                    <DropDown
                        values={apiKeysValues}
                        handleFilterStateChange={onTextChange}
                        placeholder={'API Keys'}
                        selectedFilter={selectedKeyOption}
                        customClass={Classes.apiKeysField}
                    />
                </div> 
            : null 
        }
    </>

    )
}
export default APIKeys;