import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import MuiTableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomClasses from './index.module.css'
import { v4 } from 'uuid'
import Paper from '@mui/material/Paper'
import get from 'lodash/get'
import Checkbox from '@mui/material/Checkbox'
import { getFileType } from '../../../utils/stringFormatter';
import WorkspaceFileListRow from '../workspace-file-list-row/WorkspaceFileListRow';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import classes from './index.module.css';
import LinearProgress from '@mui/material/LinearProgress';

const TableHead = withStyles(theme => ({
    root: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
}))(MuiTableHead);

const useStyles = makeStyles(theme => ({
    trTable: {
        width: '100%',
    },
    tdTable:{
        width: '66%',
    }
}))

const WorkspaceFileListView = props => {
    const [allFilesChecked, setAllFilesChecked] = useState(false);
    const { fileData = [] } = props;
    const { filesSelected = {} } = props;
    const { onFileSelected = () => { } } = props;
    const { onSelectAllClick = () => { } } = props;
    const { onDownloadClick = () => { } } = props;
    const { onDeleteFile = () => { } } = props;
    const { workspaceId = '' } = props;
    const { folderDirectory = ''} = props;
    const styledClasses = useStyles();

    const onHeaderCheckboxClick = (checked) => {
        setAllFilesChecked(checked);
        onSelectAllClick(checked);
    }
    const onSelectTableRow = (id, checked) => {
        onFileSelected(id, checked);
    }

    return (
        <TableContainer
            component={Paper}
            elevation={0}
            className={CustomClasses.tableContainer}>
            <Table className={(CustomClasses.table, CustomClasses.positionRelative)} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={allFilesChecked}
                                color="primary"
                                onClick={(e) => onHeaderCheckboxClick(e.target.checked)}
                                id='cvb-workspaceFileListView-selectAll-checkbox'
                            />
                        </TableCell>
                        {
                            ['File Name', 'Type', 'Size'].map(item => {
                                return <TableCell id={`cvb-workspaceFileListView-tableCell-${item}`} key={v4()}>{item}</TableCell>
                            })
                        }
                        <TableCell padding='none' className={CustomClasses.noBorderTableHead}>

                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.progressBar !== false ?
                        <div>
                             <div className={CustomClasses.maskListView}>
                                 <div className={classes.progressBarAlign}>
                                    <LinearProgress id='cvb-workspaceFileListView-progress' value={props.progress} />
                                </div>
                            </div>
                            <TableRow id='cvb-workspaceFileListView-dummyRow' key={v4()} className={props.uploadClass !==false ? `${CustomClasses.fileLoaderUploadProgressbar}`: `${CustomClasses.backgroundColor}`}>
                                <TableCell padding="checkbox" className={CustomClasses.maskFilter}>
                                    <Checkbox
                                    checked="checked"
                                    color="primary" />
                                </TableCell>
                                <TableCell className={styledClasses.tdTable}>
                                    progressBar.png
                                </TableCell>
                                <TableCell>
                                    png
                                </TableCell>
                                <TableCell>
                                    1005929
                                </TableCell>
                                <TableCell className={CustomClasses.downloadButtonMask}>
                                    <button className={`small-size-button ${CustomClasses.downloadButton}`}>Download</button>
                                </TableCell>
                            </TableRow>
                            </div>
                         : null}
                    {fileData.map((item, i) => {
                            const isChecked = get(filesSelected, item['Key'], false);
                            const fileId = get(item, 'Key', '');
                            return <TableRow id={`cvb-workspaceFileListView-fileId-${fileId}`} key={v4()} onClick={() => props.folderDirectoryApi(get(item, 'Prefix', ''))}>

                                {get(item, 'folderActive', '') !== true ?
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={isChecked}
                                        color="primary"
                                        onClick={(e) => onSelectTableRow(fileId, e.target.checked)}
                                        id={`cvb-workspaceFileListView-selectFile-${fileId}`}
                                    />
                                    </TableCell>
                                    :<TableCell></TableCell>}

                                {
                                    ['Key', 'Type', 'Size'].map(tableHeader => {
                                        return <TableCell id={`cvb-workspaceFileListView-fileType-${fileId}`} className={CustomClasses.folderButton} key={v4()} align="left">
                                            {tableHeader === 'Key' ? getFileType(get(item, 'Prefix', '')) : "" }

                                            {tableHeader !== 'Type'
                                                ? get(item, tableHeader, '')
                                                : getFileType(get(item, 'Key', ''))
                                            }

                                        </TableCell>
                                    })
                                }
                                {get(item, 'folderActive', '') !== true ?
                                    <WorkspaceFileListRow
                                        onDownloadClick={onDownloadClick}
                                        onDeleteFile={onDeleteFile}
                                        fileId={fileId}
                                        workspaceId={workspaceId}
                                        fileData={item}
                                        index={i}
                                        progressBar={props.progressBar}
                                        progress={props.progress}
                                        usersForShare={props.usersForShare}
                                        folderDirectory={folderDirectory}
                                    />
                                :<TableCell> </TableCell>}
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default WorkspaceFileListView;