import React, { useState, useEffect } from 'react'
import classes from './index.module.css'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table';
import strokesBG from '../../../assets/images/strokes-bg.png'
import fill from 'lodash/fill'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import find from 'lodash/find'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import tick from '../../../assets/icons/tick.svg'
import cancel from '../../../assets/icons/cancel.svg'
import ExploreAppliedFilters from '../../controls/explore-applied-filters'
import ExploreDataFilters from '../../controls/explore-data-filters'
import DropDown from '../../commons/dropdown';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip'
import { navigate } from '@reach/router';
import CautionIcon from '../../../assets/images/caution.svg';
import useScrollToTop from '../../commons/scroll-top';
import TablePagination from '../../commons/table-pagination'
import map from 'lodash/map';
import ImageVerticalIcon from '../../../assets/icons/MoreOptionsVertical.S-dark.svg';
import MuiPopover from '@mui/material/Popover';
import withStyles from '@mui/styles/withStyles';
import { getUserDeactivationState, deactivateUserAccount, activateUserAccount, updateUserRole, removeUserRoleRequest } from '../../../api/profile-api';
import Modal from '../../commons/modal'
import CommonDatePicker from '../../commons/common-date-picker';
import moment from 'moment'
import ErrorText from '../../controls/error-text';
import { isDate } from 'lodash';

const Popover = withStyles(theme => ({
    root: {
        overflowY: 'scroll',
    },
    paper: {
        minWidth: 192,
        right: 'auto !important',
        marginTop: '32px !important',
    }
}))(MuiPopover);

export default function Users(props) {

    const [filterCollapseState, setFilterCollapseState] = useState([])
    const [data, setData] = useState([])
    const [showSearch, setSearch] = useState('show')
    const [searchText, setSearchText] = useState('');
    const [showMoreItems, setShowMoreItems] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [filterValue, setFilterValue] = useState('')
    const [userAdminFilters, updateUserAdminDataFilters] = useState([])
    const [usersByZoneAccess, setUsersByZoneAccess] = useState({
        zone1_access: [],
        zone2_access: [],
        zone3_access: [],
        workspace_access: []
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const openPopover = Boolean(anchorEl);
    const [selectedUser, setSelectedUser] = useState({});
    const [canUserDeactivate, setCanUserDeactivate] = useState(false);
    const [deactivateModal, setDeactivateModal] = useState(false);
    const [activateModal, setActivateModal] = useState(false);
    const [eligibilityResponse, setEligibilityResponse] = useState({});
    const [deactivationInProgress, setDeactivationInProgress] = useState(false);
    const [activationInProgress, setActivationInProgress] = useState(false);
    const [editUserRoleModal, setEditUserRoleModal] = useState(false);
    const [editUserRoleInProgress, setEditUserRoleInProgress] = useState(false);
    const [userRoleValues, setUserRoleValues] = useState(["Member Project Administrator", "Master"]);
    const [selectedUserRole, setSelectedUserRole] = useState('');
    const [selectedRemoveUserRole, setSelectedRemoveUserRole] = useState('');
    const [removeUserRoleModal, setRemoveUserRoleModal] = useState(false);
    const [removeUserRoleInProgress, setRemoveUserRoleInProgress] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [editRoleExpiryDate, setEditRoleExpiryDate] = useState('');

    const onEditRoleExpiryChange = value => {
        setEditRoleExpiryDate(value);
    }

    const handleDownArrowClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedUser(row)
    }
    
    const isFormValid = () => {
        if(!isDate(editRoleExpiryDate)){
            return false
        } if (!selectedUserRole){
            return false
        }
        return true
    }

    const isRemoveUserRoleFormValid = () => {
        if (!selectedRemoveUserRole){
            return false
        }
        return true
    }

    const handleClosePopover = () => {
        setAnchorEl(null);
    }

    const onEditUserRoleChange = (value) => {
        setSelectedUserRole(value);
    }

    const onRemoveUserRoleChange = (value) => {
        setSelectedRemoveUserRole(value);
    }


    const initiateAccountDeactivation = async () => {
        try {
            const response = await getUserDeactivationState(selectedUser?.username);             
            setCanUserDeactivate(response?.user_deactivation_eligibility);
            setEligibilityResponse(response);
            setDeactivateModal(true);
        } catch (error) {
            console.log('error fetching deactivation status')
        }
    }

    const initiateAccountActivation = async () => {
        setActivateModal(true);
    }


    useEffect(() => {
        if (props.searchData?.users?.length && props?.location?.state?.search) {
            setSearch("hide")
            setSearchText(props?.location?.state?.searchString)
        } else {
            setData([])
            props.searchData.users = []
        }
    }, []);

    useEffect(() => {
        let zoneUsers = { ...usersByZoneAccess };
        if (props.searchData.users?.length || false) {
            const zone1Users = filter(props.searchData.users, (user) => user.zone1_access);
            const zone2Users = filter(props.searchData.users, (user) => user.zone2_access);
            const zone3Users = filter(props.searchData.users, (user) => user.zone3_access);
            const workspaceUsers = filter(props.searchData.users, (user) => user.workspace_access);
            zoneUsers['zone1_access'] = zone1Users;
            zoneUsers['zone2_access'] = zone2Users;
            zoneUsers['zone3_access'] = zone3Users;
            zoneUsers['workspace_access'] = workspaceUsers;
            setUsersByZoneAccess(zoneUsers);
        }
    }, [props.searchData.users])

    useScrollToTop();

    const resetFilterCollapseState = (numberOfFilters = Object.keys(userAdminFilters).length) => {
        setFilterCollapseState(fill(Array(numberOfFilters), false));
        setShowMoreItems(fill(Array(numberOfFilters), false))
        setPage(0);
    }

    const resetAllFilters = () => {
        let newAdminUserDataFilters = { ...userAdminFilters }
        const categories = Object.keys(newAdminUserDataFilters);
        for (let item of categories) {
            let histograms = newAdminUserDataFilters[item].histogram;
            for (let innerItem of histograms) {
                innerItem.isSelected = false;
            }
            newAdminUserDataFilters[item].histogram = histograms;
            getData(newAdminUserDataFilters[item].histogram);
        }
        updateUserAdminDataFilters(newAdminUserDataFilters);
        resetFilterCollapseState();
        setPage(0);
    }

    const sortOptions = ["User Roles", "User Name", "Organisation Name"]
    const onFilterValueChange = (value) => {
        let filterValue = ''
        switch (value) {
            case "User Roles":
                filterValue = "user_role"
                break;
            case "User Name":
                filterValue = "name"
                break;
            case "Organisation Name":
                filterValue = "organisation"
                break;
        }
        setFilterValue(value)
        let options = data
        options.sort((a, b) => (a[filterValue.toLowerCase()]?.toLowerCase() > b[filterValue.toLowerCase()]?.toLowerCase()) ? 1 : -1)
        setData(options)
    }


    const getData = histograms => {
        let filterSelected = false;
        let filterData = props?.searchData?.users;
        const appliedUserRoles = []
        const appliedZoneKeys = []
        let filters = userAdminFilters['Have Access To'].histogram
        filters = filters.concat(userAdminFilters['User Role'].histogram);
        for (let items of filters) {
            if (items.isSelected) {
                filterSelected = true;

                switch (items.id) {
                    case "zone2":
                        appliedZoneKeys.push("zone2_access")
                        break;
                    case "zone3":
                        appliedZoneKeys.push("zone3_access")
                        break;
                    case "workspace":
                        appliedZoneKeys.push("workspace_access")
                        break;
                }

                switch (items.id) {
                    case "generalUser":
                        appliedUserRoles.push("General User")
                        break;
                    case "advancedComputeUser":
                        appliedUserRoles.push("Advanced Compute User")
                        break;
                    case "qualifiedResearcher":
                        appliedUserRoles.push("Qualified Researcher")
                        break;
                    case "qualifiedResearcherAcademia":
                        appliedUserRoles.push("Qualified Researcher(Academia)")
                        break;
                    case "qualifiedResearcherIndustry":
                        appliedUserRoles.push("Qualified Researcher(Industry)")
                        break;
                    case "designatedUser":
                        appliedUserRoles.push("Designated User")
                        break;
                    case "bcManager":
                        appliedUserRoles.push("BC Manager")
                        break;
                    case "authorizingOfficial":
                        appliedUserRoles.push("Authorizing Official")
                        break;
                    case "projectAdministrator":
                        appliedUserRoles.push("Project Administrator")
                        break;
                    case "dataSubmitter":
                        appliedUserRoles.push("Data Submitter")
                        break;
                }

            }

        }

        if (appliedUserRoles.length) {
            let appliedFilters = []

            filterData.map(userData => {
                let userFilter = true
                let roles = userData['user_role']?.split(',')?.map(item => item.trim());
                appliedUserRoles.map(user_role => {
                    if (!roles?.includes(user_role)) {
                        userFilter = false;
                    }
                })
                if (userFilter) {
                    appliedFilters.push(userData);
                }
            })
            filterData = appliedFilters;
        }

        if (appliedZoneKeys.length) {
            let appliedFilters = []

            filterData.map(userData => {
                let userFilter = true
                appliedZoneKeys.map(zone_key => {
                    if (!userData[zone_key]) {
                        userFilter = false;
                    }
                })
                if (userFilter) {
                    appliedFilters.push(userData);
                }
            })

            filterData = appliedFilters;

        }
        if (filterSelected) {
            filterData = uniq(filterData, "username")
            setData(filterData)
        } else {
            setData(props.searchData.users)
        }

    }

    const updateUserRolesCount = (zoneSelectFilter) => {
        let roleCount = {
            generalUser: 0,
            qualifiedResearcher: 0,
            qualifiedResearcherAcademia: 0,
            qualifiedResearcherIndustry: 0,
            designatedUser: 0,
            bcManager: 0,
            authorizingOfficial: 0,
            projectAdministrator: 0,
            dataSubmitter: 0
        };
        let users = props.searchData.users;
        map(zoneSelectFilter, (filterData) => {
            if (filterData?.isSelected || false) {
                users = filter(users, (user) => user[filterData['access']]);
            }
        });
        users = uniq(users, 'username');

        map(users, (user) => {
            let uniqueUserRoles = uniqBy(user.user_roles, 'role_label');
            map(uniqueUserRoles, userRole => {
                switch (userRole.role_label) {
                    case "General User":
                        roleCount.generalUser++
                        break
                    case "Qualified Researcher":
                        roleCount.qualifiedResearcher++
                        break
                    case "Qualified Researcher(Academia)":
                        roleCount.qualifiedResearcherAcademia++
                        break
                    case "Qualified Researcher(Industry)":
                        roleCount.qualifiedResearcherIndustry++
                        break
                    case "Designated User":
                        roleCount.designatedUser++
                        break
                    case "BC Manager":
                        roleCount.bcManager++
                        break
                    case "Authorizing Official":
                        roleCount.authorizingOfficial++
                        break
                    case "Project Administrator":
                        roleCount.projectAdministrator++
                        break
                    case "Data Submitter":
                        roleCount.dataSubmitter++
                        break
                }
            })

        });

        return roleCount;

    }
    const onChangeFilterItemState = (e, filterCatCode, itemCode) => {
        setPage(0)
        const histograms = userAdminFilters[filterCatCode].histogram;
        const indexFound = findIndex(histograms, item => item.key === itemCode);
        histograms[indexFound].isSelected = e === true ? true : e?.target?.checked || false;
        const updateUserFilters = userAdminFilters;
        updateUserFilters[filterCatCode].histogram = histograms;
        let userRoleCount = updateUserRolesCount(updateUserFilters['Have Access To'].histogram);
        for (let key in userRoleCount) {
            const index = findIndex(updateUserFilters['User Role'].histogram, (userRole) => userRole?.id === key);
            if (index > -1) {
                updateUserFilters['User Role'].histogram[index] = {
                    ...updateUserFilters['User Role'].histogram[index],
                    count: userRoleCount[key]
                }
            }
        }

        updateUserAdminDataFilters({ ...updateUserFilters })
        getData(histograms)
    }

    const handleFilterCollapseStateChange = (index, value) => {
        const newState = [...filterCollapseState];
        newState[index] = value;
        setFilterCollapseState(newState)
    }

    // Toggle show more per filter
    const handleFilterShowMoreChange = (index, value) => {
        const newState = [...showMoreItems];
        newState[index] = value;
        setShowMoreItems(newState)
    }

    //toggles all items in a filter
    const togglesAllItemsInFilter = (category, value) => {
        let newAdminUserDataFilters = { ...userAdminFilters }
        let histograms = newAdminUserDataFilters[category].histogram;
        for (let innerItem of histograms) {
            innerItem.isSelected = value;
        }
        newAdminUserDataFilters[category].histogram = histograms;
        updateUserAdminDataFilters(newAdminUserDataFilters);
        getData(newAdminUserDataFilters[category].histogram);
    }

    // Clears applied filter per particular category
    const clearAppliedFiltersPerCategory = category => {
        let newAdminUserDataFilters = { ...userAdminFilters }
        let histograms = newAdminUserDataFilters[category].histogram;
        for (let innerItem of histograms) {
            innerItem.isSelected = false;
        }
        newAdminUserDataFilters[category].histogram = histograms;
        updateUserAdminDataFilters(newAdminUserDataFilters);
        getData(newAdminUserDataFilters[category].histogram);
        setPage(0);
    }

    const onSearchChange = (text) => {
        setSearchText(text);
    }

    const onSearchClick = () => {
        if (searchText) {
            window.scrollTo(0, 0);
            props.getUserSearchData(searchText)
            setSearch("hide");
            setPage(0);
            setFilterValue('');
        }
    }

    let userData = {
        zone1: 0,
        zone2: 0,
        zone3: 0,
        workspace: 0,
        generalUser: 0,
        advancedComputeUser: 0,
        qualifiedResearcher: 0,
        qualifiedResearcherAcademia: 0,
        qualifiedResearcherIndustry: 0,
        designatedUser: 0,
        bcManager: 0,
        authorizingOfficial: 0,
        projectAdministrator: 0,
        dataSubmitter: 0
    }

    useEffect(() => {
        if (props.searchData.users) {
            setData(props.searchData.users)
            for (let userInfo of props.searchData.users) {
                let userRoles = [];
                userData.zone1++;
                if (userInfo.zone2_access) {
                    userData.zone2++;
                }
                if (userInfo.zone3_access) {
                    userData.zone3++
                }
                if (userInfo.workspace_access) {
                    userData.workspace++
                }
                if (userInfo?.user_roles?.length) {
                    let uniqueUserRoles = uniqBy(userInfo.user_roles, 'role_label');
                    for (let userRole of uniqueUserRoles) {
                        userRoles.push(userRole.role_label);
                        switch (userRole.role_label) {
                            case "General User":
                                userData.generalUser++
                                break
                            case "Qualified Researcher":
                                userData.qualifiedResearcher++
                                break
                            case "Qualified Researcher(Academia)":
                                userData.qualifiedResearcherAcademia++
                                break
                            case "Qualified Researcher(Industry)":
                                userData.qualifiedResearcherIndustry++
                                break
                            case "Designated User":
                                userData.designatedUser++
                                break
                            case "BC Manager":
                                userData.bcManager++
                                break
                            case "Authorizing Official":
                                userData.authorizingOfficial++
                                break
                            case "Project Administrator":
                                userData.projectAdministrator++
                                break
                            case "Data Submitter":
                                userData.dataSubmitter++
                                break
                        }
                    }
                }
                if (userRoles?.length) {
                    userInfo.user_role = userRoles.join(', ');
                }

            }
            const filters = {
                "Have Access To": {
                    "histogram": [
                        {
                            "key": "Zone 1",
                            "id": "zone1",
                            "count": userData.zone1,
                            "access": "zone1_access"

                        },
                        {
                            "key": "Zone 2",
                            "id": "zone2",
                            "count": userData.zone2,
                            "access": "zone2_access"

                        },
                        {
                            "key": "Zone 3",
                            "id": "zone3",
                            "count": userData.zone3,
                            "access": "zone3_access"

                        },
                        {
                            "key": "Workspace",
                            "id": "workspace",
                            "count": userData.workspace,
                            "access": "workspace_access"

                        }
                    ]
                },
                "User Role": {
                    "histogram": [
                        {
                            "key": "General User",
                            "id": "generalUser",
                            "count": userData.generalUser
                        },
                        {
                            "key": "Qualified Researcher",
                            "id": "qualifiedResearcher",
                            "count": userData.qualifiedResearcher
                        },
                        {
                            "key": "Qualified Researcher(Academia)",
                            "id": "qualifiedResearcherAcademia",
                            "count": userData.qualifiedResearcherAcademia
                        },
                        {
                            "key": "Qualified Researcher(Industry)",
                            "id": "qualifiedResearcherIndustry",
                            "count": userData.qualifiedResearcherIndustry
                        },
                        {
                            "key": "Designated User",
                            "id": "designatedUser",
                            "count": userData.designatedUser
                        },
                        {
                            "key": "Data Submitter",
                            "id": "dataSubmitter",
                            "count": userData.dataSubmitter
                        },
                        {
                            "key": "Project Administrator",
                            "id": "projectAdministrator",
                            "count": userData.projectAdministrator
                        },
                        {
                            "key": "Authorizing Official",
                            "id": "authorizingOfficial",
                            "count": userData.authorizingOfficial
                        },
                        {
                            "key": "BC Manager",
                            "id": "bcManager",
                            "count": userData.bcManager
                        }
                    ]
                }
            }
            updateUserAdminDataFilters(filters)
        }


    }, [props.searchData.users])

    const goProfile = (id, tabId) => {
        navigate(`/admin/user-profile/${id}/${tabId}`, { state: { searchString: searchText } });
    }

    const goViewTransaction = () => {
        const { username } = selectedUser;
        navigate(`/my-account?username=${username}`);
    }

    const goGenerateInvoice = () => {
        const { username, first_name, last_name } = selectedUser;
        navigate(`/invoice/generate-invoice?username=${username}&name=${first_name}_${last_name}`);
    }

    const editUserRole = async () => {
        setIsFormSubmitted(true);
        if(isFormValid()){
            try{
                setEditUserRoleInProgress(true);
                const body  = {
                    "user_sub": selectedUser?.username,
                    "roles": [
                        {
                            "role_name": selectedUserRole,
                            "expiry_date": moment(editRoleExpiryDate).format('YYYY-MM-DD')
                        }
                    ]
                }
                const response = await updateUserRole(body);
                let message = '';
                if(response?.message_for_ui?.length){
                    message = response.message_for_ui
                }
                props.updateSnackBar(message, 'Success')
                closeEditUserRoleModal();
            }catch (error){
                let errorMessage = "User role not updated";
                if (error?.response?.data?.message) {
                    errorMessage = error.response.data.message
                }
                console.log(error)
                props.updateSnackBar(errorMessage, 'Error')
            }
        }
    }

    const removeUserRole = async () => {
        setIsFormSubmitted(true);
        if(isRemoveUserRoleFormValid()){
            try{
                setRemoveUserRoleInProgress(true);
                const body  = {
                    "user_sub": selectedUser?.username,
                    "roles": selectedRemoveUserRole
                }
                const response = await removeUserRoleRequest(body);
                await props.getUserSearchData(searchText);
                let message = 'User role successfully removed';
                if(response?.message_for_ui?.length){
                    message = response.message_for_ui
                }
                props.updateSnackBar(message, 'Success')
                closeRemoveUserRoleModal();
            }catch (error){
                let errorMessage = "User role not deleted";
                if (error?.response?.data?.message) {
                    errorMessage = error.response.data.message
                }
                console.log(error)
                props.updateSnackBar(errorMessage, 'Error')
            }finally{
                setRemoveUserRoleInProgress(false);
            }
        }
    }


    const maxCount = data?.length ? Math.ceil(data.length / 5) : 0;

    const setTablePage = number => {
        setPage(number)
    }
    const closeDeactivateModal = () => {
        setDeactivateModal(false);
    }

    const closeActivateModal = () => {
        setActivateModal(false);
    }

    const closeRemoveUserRoleModal = () => {
        setRemoveUserRoleModal(false)
    }
    const closeEditUserRoleModal = () => {
        setEditUserRoleModal(false);
        setEditUserRoleInProgress(false);
        setIsFormSubmitted(false);
        setEditRoleExpiryDate('');
        setSelectedUserRole('');
        handleClosePopover()
    }

    const deactivateAccount = async (deleteWorkspace, response) => {
        try {
            setDeactivationInProgress(true);
            const deactivation_details = {
                "deactivate_workspace": deleteWorkspace ? true : false,
                "workspace_ids": deleteWorkspace ? response?.workspaces : []
            }
            const userResponse = await deactivateUserAccount(deactivation_details, selectedUser?.username);
            if (userResponse?.user_deactivation) {
                await props.getUserSearchData(searchText);
                props.updateSnackBar(userResponse?.message ? userResponse.message : "Account deactivated successfully.", "Success")
            } else {
                props.updateSnackBar(userResponse?.message ? userResponse.message : 'Something went wrong, please try again.', "Error");
                return
            }

        } catch (error) {
            let errorMessage = "Something went wrong, Please try again.";
            if (error?.response?.data?.message) {
                errorMessage = error.response.data.message
            }
            console.log(error);
            props.updateSnackBar(errorMessage, "Error")
        } finally {
            closeDeactivateModal();
            handleClosePopover();
            setDeactivationInProgress(false);
        }
    }

    const activateAccount = async () => {
        try {
            setActivationInProgress(true);
            const userResponse = await activateUserAccount(selectedUser?.username);
            if (userResponse?.user_activation === "successful") {
                await props.getUserSearchData(searchText);
                props.updateSnackBar(userResponse?.message ? userResponse.message : "Account activated successfully.", "Success")
            } else {
                props.updateSnackBar(userResponse?.message ? userResponse.message : 'Something went wrong, please try again.', "Error");
                return
            }

        } catch (error) {
            let errorMessage = "Something went wrong, Please try again.";
            if (error?.response?.data?.message) {
                errorMessage = error.response.data.message
            }
            console.log(error);
            props.updateSnackBar(errorMessage, "Error")
        } finally {
            closeActivateModal();
            handleClosePopover();
            setActivationInProgress(false);
        }
    }

    const onDeactivateConfirm = (workspace) => {
        let deleteWorkspace = false;
        if (eligibilityResponse?.reason === 'WORKSPACE_EXISTS') {
            if (workspace === "WORKSPACE_EXISTS") {
                navigate(`/explore/tools/workspace/ownership-change/user/${selectedUser?.username}`)
                return
            } else {
                deleteWorkspace = true;
            }
        }
        deactivateAccount(deleteWorkspace, eligibilityResponse);
    }

    const DEACTIVATE_MODAL_PROPS = {
        modalTitle: eligibilityResponse?.reason === 'WORKSPACE_EXISTS' ? 'Transfer Workspace ownership to deactivate account' : 'Deactivate account?',
        positiveButtonText: eligibilityResponse?.reason === 'WORKSPACE_EXISTS' ? 'Transfer Ownership Of Workspace' : eligibilityResponse?.reason === 'LAST_PA_USER' ? null : 'Deactivate Account',
        negativeButtonText: eligibilityResponse?.reason === 'LAST_PA_USER' ? null : "Cancel",
        positiveButtonAction: eligibilityResponse?.reason === 'WORKSPACE_EXISTS' ? () => { onDeactivateConfirm('WORKSPACE_EXISTS') } : onDeactivateConfirm,
        negativeButtonAction: closeDeactivateModal,
        imageSrc: CautionIcon,
        additionalButtonText: eligibilityResponse?.reason === 'WORKSPACE_EXISTS' ? "Delete Workspace & Deactivate Account" : null,
        additionalButtonAction: eligibilityResponse?.reason === 'WORKSPACE_EXISTS' ? onDeactivateConfirm : null,
        popupButtonDisable: deactivationInProgress
    }

    const ACTIVATE_MODAL_PROPS = {
        modalTitle: 'Activate account?',
        positiveButtonText: 'Activate Account',
        negativeButtonText: "Cancel",
        positiveButtonAction: activateAccount,
        negativeButtonAction: closeActivateModal,
        imageSrc: CautionIcon,
        popupButtonDisable: activationInProgress
    }

    const EDIT_USER_ROLE_MODAL_PROPS = {
        modalTitle: 'Assign new role',
        positiveButtonText: 'Save User Role',
        negativeButtonText: "Cancel",
        positiveButtonAction: editUserRole,
        negativeButtonAction: closeEditUserRoleModal,
        popupButtonDisable: editUserRoleInProgress
    }

    const REMOVE_USER_ROLE_MODAL_PROPS = {
        modalTitle: 'Remove User role',
        positiveButtonText: 'Remove User Role',
        negativeButtonText: "Cancel",
        positiveButtonAction: removeUserRole,
        negativeButtonAction: closeRemoveUserRoleModal,
        popupButtonDisable: removeUserRoleInProgress
    }
    return (
        <div className={classes.mainContainer}>

            <Modal
                open={deactivateModal}
                handleClose={closeDeactivateModal}
                dialogProps={DEACTIVATE_MODAL_PROPS}
                enableImage={true}
                disableBottomHorizontalDivider={true}
            >
                <div className={classes.deactivateBody}>
                    {
                        (() => {
                            if (eligibilityResponse?.reason === 'LAST_PA_USER')
                                return <p>This account can't be deactivated as there are no additional Project Administrators for this organization. Please assign another individual to the role of Project Administrator for this organization and then try again to deactivate this account.</p>
                            if (eligibilityResponse?.reason === 'WORKSPACE_EXISTS')
                                return <div>Workspace owned by this user will be deleted on account deactivation. If you would like the Workspace to be<br /> maintained, you must pass it's ownership to someone else.
                                </div>
                            if (canUserDeactivate)
                                return <p>Are you sure you want to deactivate this account?
                                </p>
                            else
                                return <p></p>
                        })()
                    }
                </div>
            </Modal>

            <Modal
                open={activateModal}
                handleClose={closeActivateModal}
                dialogProps={ACTIVATE_MODAL_PROPS}
                enableImage={true}
            >
                <div className={classes.deactivateBody}>
                    <p>Are you sure you want to activate this account?</p>
                </div>
            </Modal>

            <Modal
                open={editUserRoleModal}
                handleClose={closeEditUserRoleModal}
                dialogProps={EDIT_USER_ROLE_MODAL_PROPS}
            >
                <div className={`${classes.datePickerWrapper}`}>
                    <label id='cvb-users-editUserRole-expiryDateTitle'>User Role<sup> *</sup></label>
                    <Grid item xs={6} style={{marginBottom: '20px'}}>
                        <DropDown
                            values={userRoleValues}
                            handleFilterStateChange={onEditUserRoleChange}
                            selectedFilter={selectedUserRole}
                            placeholder={'Select user role'}
                        />
                        { isFormSubmitted && (!selectedUserRole) && <ErrorText errorMessage={'Please select a user role'} />}
                    </Grid>
                    

                    <label id='cvb-users-editUserRole-expiryDateTitle'>Expiry Date<sup> *</sup></label>
                    <Grid item xs={6}>
                        <CommonDatePicker 
                         onCalenderDateSelected={onEditRoleExpiryChange}
                         minDate={moment().add(1, 'd').toDate()}
                         date={editRoleExpiryDate}
                         id='cvb-users-editUserRole-inputDate'
                         isFromModal
                         canClearInput
                        />
                    </Grid>
                    { isFormSubmitted && (!isDate(editRoleExpiryDate)) && <ErrorText errorMessage={'Please select expiry date'} />}
                </div>
            </Modal>

            <Modal
                open={removeUserRoleModal}
                handleClose={closeRemoveUserRoleModal}
                dialogProps={REMOVE_USER_ROLE_MODAL_PROPS}
            >
                <div style={{minHeight:'200px'}}>
                    <label id='cvb-users-removeUserRole-title'>User Role<sup> *</sup></label>
                    <Grid item xs={6} style={{marginBottom: '20px'}}>
                        <DropDown
                            values={[find(selectedUser?.user_roles,{'role_name': 'Member Project Administrator'}) ? ['Member Project Administrator'] : null, find(selectedUser?.user_roles,{'role_name': 'Master'}) ? ['Master'] : null].filter(Boolean)}
                            handleFilterStateChange={onRemoveUserRoleChange}
                            selectedFilter={selectedRemoveUserRole}
                            placeholder={'Select user role'}
                        />
                        { isFormSubmitted && (!selectedRemoveUserRole) && <ErrorText errorMessage={'Please select a user role'} />}
                    </Grid>  
                </div>
            </Modal>

            {
                showSearch === "hide"

                    ?
                    <div>
                        <div className={classes.innerContainerSearch}>
                            <Grid item xs={8} lg={5} md={6} className={classes.backroundImageContainer}>
                                <img className={classes.strokesImageInner} src={strokesBG} alt='strokes background' />
                            </Grid>
                            <div className={[classes.searchSection, classes.searchSectionInner].join(' ')}>
                                <Grid item xs={6} lg={6}>
                                    <div id='cvb-users-searchUser-heading' className={classes.searchTitle}>Search User</div>
                                    <OutlinedInput fullWidth type='text'
                                        value={searchText} placeholder='Enter name, email or organization name to search'
                                        name='search'
                                        id='cvb-users-search-input'
                                        className={classes.searchInputs}
                                        onChange={(e) => onSearchChange(e.target.value)}
                                        onKeyPress={e => {
                                            if (e.key === "Enter") {
                                                onSearchClick();
                                            }
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <button
                                                    id='cvb-users-searchButton'
                                                    onClick={onSearchClick}
                                                    className={`solid-button ${classes.searchButton}`}>Search</button>
                                            </InputAdornment>
                                        }
                                    />
                                </Grid>
                            </div>
                        </div>
                        <div className={classes.filterContentContainer}>
                            <Grid item lg={3} className={classes.filtersSection}>
                                <ExploreDataFilters
                                    filterCollapseState={filterCollapseState}
                                    resetAllFilters={resetAllFilters}
                                    exploreFilters={userAdminFilters}
                                    onChangeFilterItemState={onChangeFilterItemState}
                                    handleFilterCollapseStateChange={handleFilterCollapseStateChange}
                                    resetFilterCollapseState={resetFilterCollapseState}
                                    handleFilterShowMoreChange={handleFilterShowMoreChange}
                                    showMoreItems={showMoreItems}
                                    togglesAllItemsInFilter={togglesAllItemsInFilter} />
                            </Grid>

                            <Grid item lg={9} className={classes.contentBlock}>
                                <ExploreAppliedFilters
                                    exploreFilters={userAdminFilters}
                                    onChangeFilterItemState={onChangeFilterItemState}
                                    resetAllFilters={resetAllFilters}
                                    clearAppliedFiltersPerCategory={clearAppliedFiltersPerCategory} />

                                <Grid item lg={12}>
                                    <div className={classes.tableContainerOuterBox}>
                                        <div className={classes.tableContainerInnerBox}>
                                            <Grid item lg={7} >
                                                <div className={classes.sortUserOptions}>
                                                    <div id='cvb-users-sortBy-label' className={classes.sortByTitle}>Sort By:</div>
                                                    <div className={classes.searchSortDropdown}>
                                                        <DropDown
                                                            className={classes.searchSortDropdown}
                                                            values={sortOptions}
                                                            handleFilterStateChange={onFilterValueChange}
                                                            selectedFilter={filterValue}
                                                            placeholder={'Select'}
                                                        />
                                                    </div>
                                                    <p id='cvb-users-showingCount' className={classes.resultsCount}>
                                                        Showing {(data?.length) ? ((page * 5) + 1) : 0} - {(page * 5) + 5 <= data?.length ? (page * 5) + 5 : data?.length} of {data?.length}
                                                    </p>
                                                </div>
                                            </Grid>
                                            <TableContainer

                                                elevation={0}
                                                className={classes.tableContainer}>
                                                <Table className={classes.table}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell id='cvb-users-table-nameHeader' className={classes.searchColumn} width="10%" align="left">Name</TableCell>
                                                            <TableCell id='cvb-users-table-designationHeader' className={classes.searchColumn} width="10%" align="left">Designation</TableCell>
                                                            <TableCell id='cvb-users-table-orgNameHeader' className={classes.searchColumn} width="10%" align="left">Organization Name</TableCell>
                                                            <TableCell id='cvb-users-table-emailHeader' className={classes.searchColumn} width="15%" align="left">Email</TableCell>
                                                            <TableCell id='cvb-users-table-userRoleHeader' className={classes.searchColumn} width="10%" align="left">User Roles</TableCell>
                                                            <TableCell id='cvb-users-table-access2Header' className={[classes.searchColumn, classes.searchColumnIcon].join(' ')} align="left">Access To Zone 2</TableCell>
                                                            <TableCell id='cvb-users-table-access3Header' className={[classes.searchColumn, classes.searchColumnIcon].join(' ')} align="left">Access To Zone 3</TableCell>
                                                            <TableCell id='cvb-users-table-accessWorkspaceHeader' className={[classes.searchColumn, classes.searchColumnIcon].join(' ')} align="left">Access To Workspace</TableCell>
                                                            <TableCell id='cvb-users-table-statusHeader' className={`${classes.searchColumn} ${classes.searchColumnExpanded}`} width="40%" align="left">Status</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data.map((row) => (
                                                            <TableRow id={`cvb-users-table-row-${row.username}`} key={row.username}>
                                                                <TableCell className={[classes.organizationCell, classes.userTableCell].join(" ")} component="th" scope="row">
                                                                    <Tooltip placement="bottom-start" className={classes.userNameTitle} title={row?.name || ''}>
                                                                        <span id={`cvb-users-table-userName-${row.username}`} className={classes.userNameTitle}>{row?.name}</span>
                                                                    </Tooltip>
                                                                </TableCell>

                                                                <TableCell className={[classes.organizationCell, classes.userTableCell].join(" ")} component="th" scope="row">
                                                                    <Tooltip placement="bottom-start" title={row?.designation || ''}>
                                                                        <span id={`cvb-users-table-designation-${row.designation}`}>{row?.designation || ''}</span>
                                                                    </Tooltip>
                                                                </TableCell>

                                                                <TableCell className={[classes.organizationCell, classes.userTableCell].join(" ")} component="th" scope="row">
                                                                    <Tooltip placement="bottom-start" title={row?.organisation || ''}>
                                                                        <span id={`cvb-users-table-organisation-${row.organisation}`}>{row?.organisation || ''}</span>
                                                                    </Tooltip>
                                                                </TableCell>

                                                                <TableCell className={[classes.emailCell, classes.userTableCell].join(" ")} component="th" scope="row">
                                                                    <Tooltip placement="bottom-start" title={row?.email || ''}>
                                                                        <span id={`cvb-users-table-email-${row.email}`}>{row?.email || ''}</span>
                                                                    </Tooltip>
                                                                </TableCell>

                                                                <TableCell className={[classes.userRoleCell, classes.userTableCell].join(" ")} component="th" scope="row">
                                                                    <Tooltip placement="bottom-start" className={classes.userRoleTooltip} title={row?.user_role || ''}>
                                                                        <span id={`cvb-users-table-role-${row.user_role}`}>{row?.user_role || ''}</span>
                                                                    </Tooltip>
                                                                </TableCell>

                                                                <TableCell className={classes.userTableCell} component="th" scope="row">
                                                                    {<img src={row.zone2_access ? tick : cancel} />}
                                                                </TableCell>

                                                                <TableCell className={classes.userTableCell} component="th" scope="row">
                                                                    {<img src={row.zone3_access ? tick : cancel} />}
                                                                </TableCell>

                                                                <TableCell className={classes.userTableCell} component="th" scope="row">
                                                                    {<img src={row.workspace_access ? tick : cancel} />}
                                                                </TableCell>

                                                                <TableCell className={classes.userTableCell} component="th" scope="row">
                                                                    <div>
                                                                        {
                                                                            row.status?.toLowerCase() === "enabled" ?
                                                                                <span id={`cvb-users-table-status-${row.status}`} className={[classes.statusBox, classes.enabled].join(' ')}>
                                                                                    {row.status}
                                                                                </span> :

                                                                                row.status?.toLowerCase() === "disabled" ?

                                                                                    <span id={`cvb-users-table-status-${row.status}`} className={[classes.statusBox, classes.disabled].join(' ')}>
                                                                                        {row.status}
                                                                                    </span>

                                                                                    : null

                                                                        }
                                                                        <button id={`cvb-users-table-profile-button`} onClick={() => goProfile(row.username, "")} className={classes.statusButton}>View Profile</button>
                                                                        <img
                                                                            src={ImageVerticalIcon}
                                                                            alt='verticalIcon'
                                                                            className={classes.rightIconImage}
                                                                            onClick={(e) => handleDownArrowClick(e, row)}
                                                                        />

                                                                        <Popover
                                                                            id={`cvb-users-popover-${row?.id}`}
                                                                            open={openPopover}
                                                                            elevation={3}
                                                                            anchorEl={anchorEl}
                                                                            onClose={handleClosePopover}
                                                                            anchorOrigin={{
                                                                                horizontal: 'right',
                                                                            }}
                                                                            transformOrigin={{
                                                                                horizontal: 'right',
                                                                            }}
                                                                        >
                                                                            <ul className={classes.dropdownListContainer}>
                                                                                <li id='cvb-users-viewPermissions' onClick={() => { goProfile(selectedUser.username, "permissions") }}>View Permissions</li>
                                                                                {
                                                                                    selectedUser.status?.toLowerCase() === "enabled" && selectedUser.username !== props.userDetails?.username && 
                                                                                    
                                                                                    props.userDetails?.resourceActions?.users?.disable_users ?

                                                                                        <li id='cvb-users-deactivateAccount' onClick={() => { initiateAccountDeactivation() }}>Disable Account</li> :

                                                                                        selectedUser.status?.toLowerCase() === "disabled" && selectedUser.username !== props.userDetails?.username
                                                                                        && props.userDetails?.resourceActions?.users?.disable_users ? <li id='cvb-users-deactivateAccount' onClick={() => { initiateAccountActivation() }}>Enable Account</li> : null

                                                                                }
                                
                                                                                {
                                                                                    props.userDetails?.resourceActions?.invoice?.generate_invoice &&
                                                                                    <li id='cvb-users-generateInvoice' onClick={() => { goGenerateInvoice() }}>Generate Invoice</li>
                                                                                }
                                                                                {
                                                                                    (find(selectedUser?.user_roles,{'role_name': 'Master'}) || find(selectedUser?.user_roles,{'role_name': 'Member Project Administrator'})) &&
                                                                                    <li id='cvb-users-generateInvoice' onClick={() => { setRemoveUserRoleModal(true) }}>Remove User Role</li>
                                                                                }
                                                                                {
                                                                                    props.userDetails?.resourceActions?.invoice?.generate_invoice &&
                                                                                    <li id='cvb-users-generateInvoice' onClick={() => { goViewTransaction() }}>View Transactions</li>
                                                                                }
                                                                                {
                                                                                     props.userDetails?.resourceActions?.users?.disable_users && <li id='cvb-users-generateInvoice' onClick={() => { setEditUserRoleModal(true) }}>Edit User Role</li>
                                                                                }
                                                                            </ul>
                                                                        </Popover>
                                                                    </div>
                                                                </TableCell>

                                                            </TableRow>
                                                        )).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            {
                                                maxCount > 0 &&
                                                <div className={classes.tablePagination}>
                                                    <TablePagination
                                                        maxCount={maxCount}
                                                        page={page}
                                                        setPage={setTablePage}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>



                        </div>
                    </div>

                    :
                    <div className={classes.innerContainer}>
                        <Grid container className={classes.searchBackground}>
                            <Grid item xs={12} lg={12} md={12}>
                                <div className={classes.sectionHeaderContainer}>
                                    <div className={classes.gridTitle}><h2 id={`cvb-users-users-heading`}>Users</h2></div>
                                </div>
                            </Grid>
                            <Grid item xs={8} lg={5} md={6} className={classes.backroundImageContainer}>
                                <img className={classes.strokesImage} src={strokesBG} alt='strokes background' />
                            </Grid>
                            <div className={classes.searchSection}>
                                <Grid item xs={6} lg={6}>
                                    <div className={classes.searchTitle}>Search User</div>
                                    <OutlinedInput fullWidth type='text'
                                        value={searchText} placeholder='Enter name, email or organization name to search'
                                        name='search'
                                        className={classes.searchInputs}
                                        onChange={(e) => onSearchChange(e.target.value)}
                                        onKeyPress={e => {
                                            if (e.key === "Enter") {
                                                onSearchClick();
                                            }
                                        }}
                                        id={`cvb-users-userSearchInput`}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <button
                                                    id={`cvb-users-userSearch-button`}
                                                    onClick={onSearchClick}
                                                    className={`solid-button ${classes.searchButton}`}>Search</button>
                                            </InputAdornment>
                                        }
                                    />
                                </Grid>
                            </div>
                        </Grid>
                    </div>
            }
        </div>
    )
}
