import React from 'react'
import classes from './index.module.css'
import { Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Line, ComposedChart } from 'recharts';
import MultiSelectWithSearch from '../../controls/multi-select-with-search'
import { findIndex, map, round } from 'lodash';
import { WORKSPACE_COST_SUMMARY_LEGEND_KEYS } from '../../../constants/strings';
import MyCohortFolderIcon from '../../../assets/images/MyCohortFolderIcon.png'
import CustomLoader from '../../commons/custom-loader';

const WorkspaceUsageSummaryGraphContainers = (props) => {
  const { customizeOptions = [], tooltipKeys = [], xAxisDataKey='' } = props;

  var tooltip
const CustomTooltip = ({ active, payload }) => {
  const getFormattedLegends = (data) =>{
    let labelToShow='';
    const labelIndex = findIndex(WORKSPACE_COST_SUMMARY_LEGEND_KEYS, (key) => data.includes(key)); 
    labelIndex > -1 ?  labelToShow = WORKSPACE_COST_SUMMARY_LEGEND_KEYS[labelIndex] :  labelToShow = '';
    return labelToShow;
}

    if (!active || !tooltip)    return null
    for (const bar of payload)
        if (bar.dataKey === tooltip){
          return <div className={classes.tooltipContainer}>
            <div>
              <p className={classes.title}>{bar.payload?.[props.tooltipTitleKey] || ''}
              </p>
              <p className={classes.total}>
                <span className={classes.text}>Total Usage</span>
                <span className={classes.value}>
                  ${round(bar.payload['total_cost'], 2).toFixed(2) }
                </span>
              </p>
              {
                map(Object.keys(bar.payload), (key) => {
                  const isTooltipKeyPresent = findIndex(tooltipKeys, (tipKey)=> tipKey === key) > -1;
                  return isTooltipKeyPresent
                    ? <p className={classes.subDetails}>
                      <span className={classes.text}>{getFormattedLegends(key)}</span>
                      <span className={classes.value}>${round(bar.payload[key], 2).toFixed(2)}</span>
                    </p>
                    : null
                })
              }
            </div>
      </div>
        }
           
    return null
}

const labelInterval = Math.floor((props?.data || []).length/7);
  return (
    <div className={classes.mainContainer}>
      <div className={classes.graphContainerBox}>
      <p className={classes.graphTitle}>{props?.title || ''}</p>
      <div className={classes.customizeOptionContainer}>
        {
          <p className={classes.graphSubTitle}>{props?.subDetails || ''}</p>
        }
        {(props?.canCustomize || false) &&
          <p className={classes.customizeText}>
            <MultiSelectWithSearch
              title='Customize'
              data={customizeOptions}
              toggleCustomSelectedItem={props.handleCustomizationChange}
              onSearchChange={props.handleSearchChange}
            />
          </p>
        }
      </div>
        <div className={classes.innerGraphContainer}>
            <>
            {<div id='workspace-cost-summary-graph-container' className={classes.graphContainer}>
              <div></div>
              {props.yAxisLabel ? <p className={classes.yAxisLabel}>{props.yAxisLabel}</p> : null}
              {(props.data?.length) && !props?.isLoading ? 
              <ResponsiveContainer width={'100%'} height={400}>
                <ComposedChart
                  width={'100%'}
                  height={'100%'}
                  data={props?.data || []}
                  maxBarSize={50}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 50,
                    bottom: 20,
                  }}
                 
                >
                    <XAxis 
                      dy={20}
                      minTickGap={-200} axisLine={true} 
                      interval={labelInterval} 
                      tickFormatter={props.xAxisLabelTickFormatter} 
                      dataKey={xAxisDataKey} />
                  <YAxis axisLine={false} />
                  <Tooltip cursor={false} content={<CustomTooltip data={props.data}/>}  />
                  {map(props.dataKeys, (dataKey)=>{
                    return  <Bar dataKey={dataKey.key} onMouseOver={ () => tooltip=dataKey.key} stackId="stack" fill={dataKey.color} />
                  })}
                  { props.withReferenceLine && 
                    <Line legendType='none' dataKey="total_cost" dot={false} fill="#72B7E5" strokeWidth={3} stroke="#72B7E5" />
                  }
                   <Legend formatter={props.getFormattedLegends} wrapperStyle={{position: 'relative', marginTop: '25px', padding: '0px 20px'}}/>
                </ComposedChart>
              </ResponsiveContainer>
               : props?.isLoading ? <CustomLoader componentLoader styleProp={{ height: 500 }} /> :
               
               
               !props?.isLoading ? <div className={classes.noDataContainer}>
               <div className={classes.noDataIcon}>
                   <img alt='my cohort folder' className={classes.myCohortFolderIcon} src={MyCohortFolderIcon} />
               </div>
               <div className={classes.noDataContent}>
                   <h3>No data found</h3>
                   <p></p>
               </div>
           </div>  : null
              }
            </div>
            }
          </>
        </div>
      </div>
      </div>
    )
  }

  export default WorkspaceUsageSummaryGraphContainers;