import React from 'react'
import Grid from '@mui/material/Grid'
import Classes from './index.module.css'
const WorkspaceRightContainer = (props) => {
    const { isOwnedWorkspacePresent = false } = props;
    return ( 
        <div className={Classes.WorkspaceRightContainer}>
            <Grid container >
                <Grid item xs={12}>
                    <Grid container spacing={2} direction='column' >
                        { isOwnedWorkspacePresent ?  <div className={Classes.sharedTextContainer}>
                            <p id='cvb-workspaceRightContainer-shareNotebook-label' className={Classes.sharedText}>API Keys</p>
                            <p className={Classes.hereText}>To be able to query data from your Workspace you will need to use your API key, which you can export from the API keys button above.</p>
                        </div> : null}
                        <div className={Classes.sharedTextContainer}>
                            <p id='cvb-workspaceRightContainer-shareNotebook-label' className={Classes.sharedText}>Documentation</p>
                            <p className={Classes.hereText}>You can access the Workspace Reference manual <a rel="noreferrer" className={Classes.hereText} href="https://help.braincommons.org/category/104-advanced-compute-workspace" target="_blank">here</a> to learn more about how to use Workspaces.</p>
                        </div>
                        
                    </Grid>
                </Grid>
                
            </Grid>
        </div>
    )
}

export default WorkspaceRightContainer;


