/**
 *  External Imports
 */
import Grid from "@mui/material/Grid";

import {navigate} from "@reach/router";

import axios from "axios";

import {arrayOf, bool, func, number, shape, string} from "prop-types";

import React, {useEffect} from "react";

import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip'


/**
 *  Internal Imports
 */
import {communityAPI} from "../../../api/community-api";
import { sendGAEvents } from "../../../api/generic-api";

import iconDownArrow from "../../../assets/icons/down-arrow.svg";
import iconUpArrow from "../../../assets/icons/up-arrow.svg";

import {COMMUNITY_LEFT_MENU_TIMER_MS} from "../../../constants/timers";

import styles from "./index.module.css";

/**
 *  Component
 *
 *  @param props
 *
 *  @return {JSX.Element}
 */

 export const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#FCF8C8",
        color: "#222222",
        boxShadow: "-1px 1px 106px -61px rgba(74,73,74,1);",
        fontSize: 14,
        fontWeight: "medium",
        marginRight: '3px',
        fontFamily: "Raleway",
        borderRadius: 0,
        padding: 15,
        textAlign: "left"
    },
    arrow: {
        color: "#FCF8C8"
    },
    customWidth: {
        maxWidth: 200,
    },
}))(Tooltip);

const CommunityLeftMenu = (props) => {
	// Variables
	const {leftMenuItems, activeItemKey} = props;
	const {onChangeActiveItem, onChangeItemCounters, onClickItem, onToggleItem} = props;
	const initDataCancelTokenSrc = axios.CancelToken.source();
	
	// Init Functions
	const initData = async () => {
		// Variables
		let counters;
		
		// Update counters
		counters = await communityAPI.getCommunityCounters(initDataCancelTokenSrc.token);
		
		if (counters?.isCanceled) {
			return;
		}
		
		onChangeItemCounters(counters);
	};
	
	// Event Functions
	const onItemClick = (uniqKey) => () => { 
		switch(uniqKey){
			case 'Recommended': {
				sendGAEvents('recommended_connections', 'community', 'Selected recommended connections');
				break;
			}
			case 'Connections Group': {
				sendGAEvents('connections', 'community', 'Selected connections');
				break;
			}
			case "Incoming Requests": {
				sendGAEvents('incoming_request', 'community', 'Selected incoming requests');
				break;
			}
			case "Pending Requests": {
				sendGAEvents('pending_request', 'community', 'Selected pending requests');
				break;
			}
			case "Current Connections": {
				sendGAEvents('current_connection', 'community', 'Selected current connections');
				break;
			}
			case "Messages":{
				sendGAEvents('messages', 'community', 'Selected messages');
				break;
			}
			default: break;
		}
		onClickItem(
		uniqKey,
		({navigateRoute}) => navigate(navigateRoute),
	);}
	
	const onItemToggle = (uniqKey) => () => onToggleItem(uniqKey);
	
	// Life Cycles
	useEffect(() => {
		const initDataIntervalTimerID = window.setInterval(
			() => initData(),
			COMMUNITY_LEFT_MENU_TIMER_MS,
		);
		
		return () => {
			window.clearInterval(initDataIntervalTimerID);
			
			initDataCancelTokenSrc.cancel();
		};
	}, []);

	useEffect(() => {
		if (activeItemKey) {
			onChangeActiveItem(activeItemKey.menuActiveKeyValue);
		}
	}, [activeItemKey]);
	
	// Return
	return (
        <div className={styles.communityLeftMenu}>
			
			<Grid container
			
			      className={styles.title}>
				
				Connections
			</Grid>
			
			<Grid container
			
			      direction="column"
			      justifyContent="space-between"
			      alignItems="flex-start"
			
			      className={styles.menuContainer}>
				
				{
					leftMenuItems.map(({uniqKey, icon, iconAlt, text, navigateRoute, tooltip, counter, isGroup, isActive, isOpenSubMenu, children}, index) => (
						<div data-testid="menuItem" className={styles.menuItemArea}
						
						     key={index}>
							
							<div data-testid="menuItemActiveArea" className={isActive ? `${styles.menuItem} ${styles.menuItemActive}` : styles.menuItem}
							
							     key={uniqKey}>
								
								<div className={styles.menuItemLeftArea}
								
								     onClick={onItemClick(uniqKey)}>
									
									<div className={styles.menuItemIcon}>
										
										<img data-testid="menuItemIcon" src={icon}
										     alt={iconAlt}
										/>
									</div>
									
									<div data-testid="menuItemText" className={styles.menuItemText}>
										{text}
									</div>
								</div>
								
								<div className={styles.menuItemRightArea}>
									
									{
										!!tooltip.length &&
										<div className={styles.menuItemTooltipArea}>
											<LightTooltip placement="right" className={styles.menuItemTooltipIcon} arrow title={tooltip}>
												<img className={styles.menuItemTooltipIcon}  alt="Icon Tooltip" />
											</LightTooltip>
										</div>


									}
									
									{
										counter > 0 &&
										<div className={styles.menuItemCounter} data-testid="menuItemCounter">
											{counter}
										</div>
									}
									
									{
										children.length > 0 &&
										<img data-testid="menuItemToggle" className={styles.menuItemToggleIcon}
										
										     src={isOpenSubMenu ? iconUpArrow : iconDownArrow}
										     alt="Icon Toggle"
										
										     onClick={onItemToggle(uniqKey)}
										/>
									}
								</div>
							</div>
							
							{
								isOpenSubMenu &&
								children.length > 0 &&
								<div className={styles.menuSubItemsArea} data-testid="subMenu" >
									
									{
										children.map(({uniqKey, text, tooltip, counter, isActive}) => (
											<div data-testid="subMenuItem"
												className={
													isActive ?
														`${styles.menuItem} ${styles.menuSubItem} ${styles.menuItemActive}` :
														`${styles.menuItem} ${styles.menuSubItem}`
												}
												
												key={uniqKey}>
												
												<div className={styles.menuItemLeftArea}
												
												     onClick={onItemClick(uniqKey)}>
													
													<div data-testid="subMenuText" className={styles.menuSubItemText}>
														{text}
													</div>
												</div>
												
												<div className={styles.menuItemRightArea}>
													
													{
														!!tooltip.length &&
														<div className={styles.menuItemTooltipArea}>
															<LightTooltip placement="right" className={styles.menuItemTooltipIcon} arrow title={tooltip}>
																<img className={styles.menuItemTooltipIcon}  alt="Icon Tooltip" />
															</LightTooltip>
														</div>
													}
													
													{
														counter > 0 &&
														<div className={styles.menuItemCounter}>
															{counter}
														</div>
													}
												</div>
											</div>
										))
									}
								</div>
							}
						</div>
					))
				}
			</Grid>
		</div>
    );
};

/**
 *  Properties
 */
CommunityLeftMenu.propTypes = {
	leftMenuItems: arrayOf(
		shape(
			{
				uniqKey: string.isRequired,
				icon: string.isRequired,
				iconAlt: string.isRequired,
				text: string.isRequired,
				navigateRoute: string.isRequired,
				tooltip: string.isRequired,
				counterKey: string.isRequired,
				counter: number.isRequired,
				isGroup: bool.isRequired,
				isActive: bool.isRequired,
				isOpenSubMenu: bool.isRequired,
				children: arrayOf(
					shape(
						{
							uniqKey: string.isRequired,
							text: string.isRequired,
							navigateRoute: string.isRequired,
							tooltip: string.isRequired,
							counterKey: string.isRequired,
							counter: number.isRequired,
							isActive: bool.isRequired,
						}
					)
				).isRequired,
			}
		).isRequired,
	).isRequired,
	activeItemKey: shape({menuActiveKeyValue: string.isRequired}),
	
	onChangeActiveItem: func.isRequired,
	onChangeItemCounters: func.isRequired,
	onClickItem: func.isRequired,
	onToggleItem: func.isRequired,
};

/**
 *  Exports
 */
export default CommunityLeftMenu;
