import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Classes from './index.module.css'
import map from 'lodash/map'
import MuiPopover from '@mui/material/Popover';
import withStyles from '@mui/styles/withStyles';
import get from 'lodash/get'
import Modal from '../../commons/modal'
import AddUsersToShare from '../add-users-to-share';
import ImageWorkspace from '../../../assets/icons/024-book-1.svg';
import ImageVerticalIcon from '../../../assets/icons/MoreOptionsVertical.S-dark.svg';
import { getPresignedUrlForNotebook, copyNotebookToWorkspace } from '../../../api/notebook-api'
import DropDown from '../../commons/dropdown';
import { isEmpty } from 'lodash';
import ErrorText from '../error-text';

const Popover = withStyles(theme => ({
    root: {
        overflowY: 'scroll',
    },
    paper: {
        minWidth: 209,
        marginTop: '32px !important'

    }
}))(MuiPopover);

const NotebookInfoCards = props => {

    const [anchorEl, setAnchorEl] = useState(null);
    const popoverElements = get(props, 'popoverOptions', []);
    const [openSampleNotebookShareModal, setOpenSampleNotebookShareModal] = useState(false);
    const [openShareNotebookDownloadModal, setOpenShareNotebookDownloadModal] = useState(false);
    const [openShareNotebookCopyModal, setOpenShareNotebookCopyModal] = useState(false);
    const [openSampleNotebookDownloadModal, setOpenSampleNotebookDownloadModal] = useState(false);
    const notebookKeyName = get(props, 'eventData.sharedNotebookKeyName', '');
    const [ workspaceValues, setWorkspaceValues ] = useState([]);
    const [ selectedWorkspace, setSelectedWorkspace ] = useState('');
    const [ shareConfirm, setShareConfirm ] = useState(false);
    const [ workspaceIdToShare, setWorkspaceIdToShare ] = useState(''); 
    const { allWorkspaces = [] } = props;
    const { updateSnackBar = ()=> {} } = props;
    useEffect(() => {
        const workspacesIds = map(allWorkspaces, (workspace) => workspace['workspace_id']);
        setWorkspaceValues(workspacesIds);
        if(workspacesIds?.length){
            if(!popoverElements?.includes('Copy To Workspace') || false){
                popoverElements.push('Copy To Workspace')
            }
        }
    }, [allWorkspaces])

    const handleDownArrowClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClosePopover = () => {
        setAnchorEl(null);
    }
    const onSharedNotebookDownloadClick = async () => {
        setOpenShareNotebookDownloadModal(true);
    }
    const onShareNotebookCopyClick = () => {
        setSelectedWorkspace('');
        setShareConfirm(false);
        setOpenShareNotebookCopyModal(true);
    }
    const onSampleNotebookDownloadClick = () => {
        setOpenSampleNotebookDownloadModal(true)
    }
    const onSampleNotebookShareClick = () => {
        setOpenSampleNotebookShareModal(true)
    }
    const sharedNotebookOptionsSelect = (ele) => {
        switch (ele) {
            case 'Download': onSharedNotebookDownloadClick();
                break;
            case 'Copy To Workspace': onShareNotebookCopyClick();
                break;
            default: break;
        }
    }
    const sampleNotebookOptionsSelect = (ele) => {
        switch (ele) {
            case 'Download': onSampleNotebookDownloadClick();
                break;
            case 'Share': onSampleNotebookShareClick();
                break;
            default: break;
        }
    }
    const onPopoverOptionSelect = (ele) => {
        setAnchorEl(null);
        const type = get(props, 'type', '');
        switch (type) {
            case 'sharedNotebook': sharedNotebookOptionsSelect(ele);
                break;
            case 'sampleNotebook': sampleNotebookOptionsSelect(ele);
                break;
            default: break;
        }
    }
    const closeShareSampleNotebookModal = () => {
        setOpenSampleNotebookShareModal(false);
    }
    const closeShareNotebookDownloadModal = () => {
        setOpenShareNotebookDownloadModal(false);
    }
    const closeShareNotebookCopyModal = () => {
        setOpenShareNotebookCopyModal(false);
    }
    const closeSampleNotebookDownloadModal = () => {
        setOpenSampleNotebookDownloadModal(false);
    }
    const onShareSampleNotebookConfirm = () => {
        try {
            //api call
            setOpenSampleNotebookShareModal(false);
        } catch (error) {
            console.log('unable to share notebook')
        }
    }
    const onAddUserClick = () => {
        try {
            //api call to add user
        } catch (error) {
            console.log('error in adding user');
        }
    }
    const onShareNotebookDownloadConfirm = async () => {
        try {
            //api call to download file
            const res = await getPresignedUrlForNotebook(notebookKeyName);
            const url = get(res, 'presignedUrl', '');
            if(url){
                window.location.href = url;
            }
            setOpenShareNotebookDownloadModal(false);
        } catch (error) {
            console.log('error in adding user');
        }
    }
    const onShareNotebookCopyConfirm = async () => {
        try {
            //api call to copy notebook to workspace
            setShareConfirm(true);
            let workspaceIdToSelect = '';
            if (workspaceValues.length > 1) {
                workspaceIdToSelect = selectedWorkspace;
            } else {
                workspaceIdToSelect = workspaceValues[0];
            }
            setSelectedWorkspace(workspaceIdToSelect);
            if (!isEmpty(workspaceIdToSelect)) {
                setOpenShareNotebookCopyModal(false);
                const body = {
                    "workspaceAccountNumber": workspaceIdToSelect,
                    "sharedNotebookS3Key": notebookKeyName
                };
                const res = await copyNotebookToWorkspace(body);
                if(res){
                    updateSnackBar('Notebook is copied successfully', 'success');
                    setShareConfirm(false);
                    setSelectedWorkspace('');
                }
            }
        } catch (error) {
            console.log('error in adding user', error);
            updateSnackBar('Error in copying notebook', 'error');
            setShareConfirm(false);
            setSelectedWorkspace('');
        }
    }
    const onSampleNotebookDownloadConfirm = () => {
        try {
            //api call to add user
            setOpenSampleNotebookDownloadModal(false);
        } catch (error) {
            console.log('error in adding user');
        }
    }
    const onWorkspaceOptionChange = (value) => {
        setSelectedWorkspace(value);
        setShareConfirm(false);
    }

    const openPopover = Boolean(anchorEl);
    const workspaceDetails = {};

    const SHARE_SAMPLE_NOTEBOOK_MODAL_PROPS = {
        modalTitle: "Share Notebook",
        positiveButtonAction: onShareSampleNotebookConfirm,
        negativeButtonAction: closeShareSampleNotebookModal
    }
    const SHARE_NOTEBOOK_DOWNLOAD_MODAL_PROPS = {
        modalTitle: 'Are you sure you want to download this notebook?',
        positiveButtonText: 'Download',
        negativeButtonText: "Cancel",
        positiveButtonAction: onShareNotebookDownloadConfirm,
        negativeButtonAction: closeShareNotebookDownloadModal
    }
    const SHARE_NOTEBOOK_COPY_MODAL_PROPS = {
        modalTitle: 'Are you sure you want to copy this notebook to workspace?',
        positiveButtonText: 'Copy to workspace',
        negativeButtonText: "Cancel",
        positiveButtonAction: onShareNotebookCopyConfirm,
        negativeButtonAction: closeShareNotebookCopyModal
    }
    const SAMPLE_NOTEBOOK_DOWNLOAD_MODAL_PROPS = {
        modalTitle: 'Are you sure you want to download this notebook ?',
        positiveButtonText: 'Download',
        negativeButtonText: "Cancel",
        positiveButtonAction: onSampleNotebookDownloadConfirm,
        negativeButtonAction: closeSampleNotebookDownloadModal
    }

    return (
        <React.Fragment>
            <Modal
                open={openSampleNotebookShareModal}
                handleClose={closeShareSampleNotebookModal}
                dialogProps={SHARE_SAMPLE_NOTEBOOK_MODAL_PROPS}
                disableBottomHorizontalDivider
            >
                <AddUsersToShare workspaceDetails={workspaceDetails} onAddUserClick={onAddUserClick} usersForShare={props.usersForShare}/>
            </Modal>
            <Modal
                open={openShareNotebookDownloadModal}
                handleClose={closeShareNotebookDownloadModal}
                dialogProps={SHARE_NOTEBOOK_DOWNLOAD_MODAL_PROPS}
                disableTitleDivider={true}
            >
            </Modal>
            <Modal
                open={openShareNotebookCopyModal}
                handleClose={closeShareNotebookCopyModal}
                dialogProps={SHARE_NOTEBOOK_COPY_MODAL_PROPS}
            >
                <div>
                    { workspaceValues.length > 1 ?
                    <div className={Classes.selectWorkspaceContainer}>
                        <label id='cvb-notebookCardModal-shareNotebook-label'>Please select workspace you want to share notebook with</label>
                        <Grid item xs={6}>
                        <DropDown
                            values={workspaceValues}
                            handleFilterStateChange={onWorkspaceOptionChange}
                            selectedFilter={selectedWorkspace}
                            placeholder={'Select workspace'}
                        />
                        {
                            (shareConfirm && isEmpty(workspaceIdToShare)) &&
                            <div className={Classes.errorTextDiv}>
                            <ErrorText errorMessage='Please select workspace'/>
                            </div>
                        }
                        </Grid>
                     </div>   
                    :
                    <p id='cvb-notebookCardModal-sharingWith-workspaceName'> You are sharing a notebook with <span className={Classes.workspaceName}><b>{workspaceValues[0]}</b></span> workspace. Are 
                    you sure you want to continue. 
                    </p>
                    }
                </div>
            </Modal>
            <Modal
                open={openSampleNotebookDownloadModal}
                handleClose={closeSampleNotebookDownloadModal}
                dialogProps={SAMPLE_NOTEBOOK_DOWNLOAD_MODAL_PROPS}
                disableTitleDivider={true}
            >
            </Modal>

            <Grid item xs={12} lg={4}>
                <div className={Classes.cardContainer}>
                   { popoverElements.length ?
                   <React.Fragment>
                   <div className={Classes.icon} onClick={(e) => handleDownArrowClick(e)}>
                        <img
                            src={ImageVerticalIcon}
                            className={Classes.MoreOptionsVerticalIcon}
                            alt='Vertical MenuIcon'
                        />
                    </div>
                    <Popover
                        id={'notebook-popover'}
                        open={openPopover}
                        anchorEl={anchorEl}
                        onClose={handleClosePopover}
                    >
                        <ul className={Classes.dropdownListContainer}>
                            {
                                map(popoverElements, (ele) => {
                                    return <li id={`cvb-notebookCardModal-downloadNotebook-${ele}`} key={`${ele}`} onClick={() => onPopoverOptionSelect(ele)}>{ele}</li>
                                })
                            }
                        </ul>
                    </Popover>
                    </React.Fragment> : null
                    }
                    <div container className={Classes.cardContentContainer}>
                        <div className={Classes.workspaceImageContainer}>
                            <img src={ImageWorkspace} className={Classes.WorkspaceImage} alt='Workspace' />
                        </div>
                        <div className={Classes.cardContent}>
                            <p id={`cvb-notebookCardModal-sourceS3Key`} className={Classes.curiousText}>{get(props, 'eventData.sourceS3KeyName', '')}</p>
                        </div>
                    </div>
                </div>
            </Grid>
        </React.Fragment>
    )
}

export default NotebookInfoCards;