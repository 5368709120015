import React, { useState, useEffect, useCallback, useMemo } from 'react'
import Classes from './index.module.css'
import ImgRemoveCohort from '../../../assets/icons/delete_cohort.svg'
import ImgModifyData from '../../../assets/icons/modify_cohort.svg'
import SectionHeader from '../../commons/section-header'
import { navigate } from '@reach/router'
import { MY_STUFF_ANALYSE_DATA_BACK_BUTTON_TEXT } from '../../../constants/strings'
import { deleteCohort } from '../../../api/analysis-api'
import { formFilterData } from '../../../utils/explore-data-plotly-helper';
import AnalyzeData from "../analyze-data"

const MyStuffAnalysis = props => {

  const [analysisState, setAnalysisState] = useState([]);


  useEffect(() => {
    if (!props.analysisWithCohortId?.cohort_id) {
      props.getAnalysisWithCohortId(props.id)
    }
  }, []);

  useEffect(() => {
    if (props.analysisWithCohortId) {
      const analysisSavedData = props.analysisWithCohortId?.analysis || []
      if (analysisSavedData.length > 0) {
        let records = []
        for (let item of analysisSavedData) {
          const axisObj = item?.axis ? JSON.parse(item?.axis) : {}
          const recordItem = {
            analysis_name: item?.analysis_name,
            id: item?.analysis_id,
            xAxis: axisObj?.xAxis,
            yAxis: axisObj?.yAxis,
            graphType: item?.graph_type
          }
          records.push(recordItem)
        }
        setAnalysisState(records);
      }
      else {
        setAnalysisState([{ id: 1, graphType: "Dot" }]);
      }
      // TODO
    }
  }, [props.analysisWithCohortId]);

  const getProcessedFilterData = useCallback(() => {
    const formattedFormFilterData = formFilterData(null, props.excludeFromExploration);
    return formattedFormFilterData;
  }, [props.exploreFilters, props.excludeFromExploration])


  const getProcessedFilterValues = () => {
    try {
      const parseQuery = JSON.parse(props.analysisWithCohortId?.query);
      if (props.excludeFromExploration?.length > 0) {
        parseQuery.excludeFromExploration = props.excludeFromExploration;
      }
      return parseQuery;
    }
    catch (error) { return null }
  }

  const hasDataChanged = () => {
    let records = []
    const analysisSavedData = props.analysisWithCohortId?.analysis || [];
    let isChanged = false;
    if (analysisSavedData.length > 0) {
      for (let item of analysisSavedData) {
        const axisObj = item?.axis ? JSON.parse(item?.axis) : {}
        const recordItem = {
          analysis_name: item?.analysis_name,
          id: item?.analysis_id,
          xAxis: axisObj?.xAxis,
          yAxis: axisObj?.yAxis,
          graphType: item?.graph_type
        }
        records.push(recordItem)
      }
      if (records.length === analysisState.length) {
        for (let i = 0; i < records.length; i++) {
          if (records[i].xAxis !== analysisState[i].xAxis ||
            records[i].yAxis !== analysisState[i].yAxis ||
            records[i].id !== analysisState[i].id ||
            records[i].graphType !== analysisState[i].graphType ||
            records[i].analysis_name !== analysisState[i].analysis_name
          ) {
            isChanged = true;
          }
        }
      }
      else {
        isChanged = true;
      }
      return isChanged;
    } else {
      if (analysisState.length == 1 && !analysisState[0].xAxis && !analysisState[0].yAxis) {
        return false;
      }
    }

    return true;
  }

  const handleModifyData = async (isPermalink) => {
    try {
      if (!hasDataChanged() && !isPermalink === true) {
        props.updateSnackBar("Please update the data to modify it.", "Error")
        return;
      }
      let body = {}
      const analysisData = analysisState.map((item, index) => {
        let obj = {}
        obj["axis"] = JSON.stringify({
          "xAxis": item?.xAxis,
          "yAxis": item?.yAxis
        });
        obj["analysis_name"] = item?.analysis_name || ((props.analysisWithCohortId?.length || 1) + index);
        if (item?.id) {
          obj["id"] = item.id;
        }
        return obj;
      })
      body['analysis'] = analysisData;
      body["query"] = props.analysisWithCohortId?.query;
      body["cohort_id"] = props?.analysisWithCohortId?.cohort_id;
      body["cohort_name"] = props?.analysisWithCohortId?.cohort_name;
      body["cohort_type"] = "cohort";
      if (isPermalink === true) {
        delete body["cohort_id"];
        delete body["cohort_name"];
        delete body['analysis']
        body["cohort_type"] = 'permalink';
        const parseQuery = JSON.parse(body['query']);
        parseQuery.analysis = analysisData;
        body['query'] = JSON.stringify(parseQuery)
      }
      let response = null;
      let successMessage = "Successfully modified the analysis"
      if (isPermalink === true) {
        response = await props.saveCohortToDBApi(body)
        successMessage = "Successfully created permalink"
      } else {
        await props.saveCohortWithoutID(body);
      }
      props.updateSnackBar(successMessage, "Success");
      return response;
    }
    catch (error) {
      console.log(error);
      if (isPermalink === true) {
        throw error;
      }
      props.updateSnackBar("Unable to modified the analysis", "Error")
    }
  }

  const handleDeleteCohort = async () => {
    try {
      await deleteCohort(props.id)
      props.updateSnackBar("Successfully deleted", "Success")
      navigate("/my-stuff")
    }
    catch (error) {
      console.log(error)
      props.updateSnackBar("Unable to delete the cohort", "Error")
    }
  }

  const sectionHeaderProps = {
    title: "Analysis",
    defaultActionButtons: [
      {
        buttonText: "Remove Cohort",
        buttonImageSource: ImgRemoveCohort,
        handleOnClick: handleDeleteCohort
      },
      {
        buttonText: "Modify Data",
        buttonImageSource: ImgModifyData,
        handleOnClick: handleModifyData
      },
    ]
  }
  const onBackButtonClick = () => {
    navigate('/my-stuff');
  }

  useEffect(() => {
    const data = getProcessedFilterData()
    const parsedFilter = getProcessedFilterValues();
    let overallFilter = parsedFilter;
    if (overallFilter?.filter?.AND && data?.filter?.AND)
      overallFilter.filter.AND = [...overallFilter.filter.AND, ...data.filter.AND];
    // // TODO
  }, [props.excludeFromExploration, props.exploreFilters]);

  const preloadedAnalysis = useMemo(() => {
    return props.analysisWithCohortId?.analysis?.map((item) => {
      return {
        ...JSON.parse(item.axis || "{}"),
        id: item.id
      }
    }) || null;
  }, [props.analysisWithCohortId])



  return (
    <React.Fragment>
      <SectionHeader
        open={props.open}
        sectionHeaderProps={sectionHeaderProps}
        backButtonText={MY_STUFF_ANALYSE_DATA_BACK_BUTTON_TEXT}
        onBackButtonClick={onBackButtonClick}
      />
      <div className={Classes.container}>
        <p id='cvb-myStuffAnalysis-cohortName' className={Classes.cohortNameText}>{props.analysisWithCohortId?.cohort_name || "Cohort"}</p>
        <AnalyzeData preloadedAnalysis={preloadedAnalysis} appliedFilterFields={JSON.parse(props.analysisWithCohortId?.query || "[]")} updateAnalyzeState={setAnalysisState} />
      </div>
    </React.Fragment>
  )
}

export default MyStuffAnalysis