import React from 'react'
import styles from "./index.module.css";
import defaultIconUser from "../../../assets/images/user/default-profile-image.png";

const UserAvatar = (props) => {
    
    const { userIcon, smallIcon, midIcon } = props;
    const currentClass = smallIcon ? styles.userPhotoSmall : midIcon ? styles.userPhotoMid : styles.userPhoto

    const iconLoadedHandler =() => {
        if(props.setIconLoading){
            props.setIconLoading(false)
        }
    }

    return (
        <img className={currentClass}
            data-testid="userAvatar"
		    src={userIcon ? userIcon : defaultIconUser}
            alt="Icon User"
            onLoad={() => iconLoadedHandler()}
        />
    )
}

export default UserAvatar;
