import React, { useEffect, useState, useMemo } from 'react'
import Classes from './index.module.css';
import SectionHeader from '../../commons/section-header';
import isEmpty from 'lodash/isEmpty'
import Authorisation from '../../controls/authorisation-certificates';
import useScrollToTop from '../../commons/scroll-top';
import SavedStudyProjectDownloads from '../../controls/saved-study-project-downloads'
import WorkspaceFileDownloadOrders from '../../controls/workspace-file-download-orders'
const MyStuff = props => {
    
    const LIMIT_PER_PAGE = 5;
    useScrollToTop();

    useEffect(() => {
        props.resetMyStuffStore();
        setPageNumber(1);
    }, []);

    const [pageNumber, setPageNumber] = useState(-1);
    const [showCohortsLoader, setShowCohortsLoader] = useState(false)

    useEffect(() => {
        getCohortsData();
    }, [pageNumber])

    const getCohortsData = async () => {
        try {
            if (pageNumber > 0) {
                if (props.userCohorts?.length < (pageNumber) * LIMIT_PER_PAGE) {
                    setShowCohortsLoader(true)
                    if (props.userCohorts.length > 0) {
                        if (!isEmpty(props.lastEvaluatedKey)) {
                            await props.getUserCohorts(LIMIT_PER_PAGE, props.lastEvaluatedKey);
                        }
                    }
                    else {
                        await props.getUserCohorts(LIMIT_PER_PAGE, props.lastEvaluatedKey);
                    }
                    setShowCohortsLoader(false)
                }
            }
        }
        catch (error) {
            setShowCohortsLoader(false);
        }
    }

    const sectionHeaderProps = useMemo(() => {
        return {
            title: "My Stuff",
        }
    }, [])

    return (
        <>
            <SectionHeader
                open={props.open}
                sectionHeaderProps={sectionHeaderProps} />
            <div className={Classes.container}>

                <Authorisation userDetails={props.userDetails}></Authorisation>
                
                {
                    <SavedStudyProjectDownloads userDetails={props.userDetails}></SavedStudyProjectDownloads>
                  
                }
                {
                    <WorkspaceFileDownloadOrders />
                }

            </div>
        </>
    )
}

export default MyStuff;