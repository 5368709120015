import React, { useState, useEffect } from 'react'
import classes from './index.module.css'
import OutlinedInput from '@mui/material/OutlinedInput';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { v4 } from 'uuid';
import DropDown from '../../commons/dropdown';
import TablePagination from '../../commons/table-pagination';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '../../../assets/icons/search-icon.svg';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import { TRANSACTION_TYPE_STATUS } from '../../../constants/strings'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import { isDate } from 'lodash';
import InfoGreyIcon from '../../../assets/icons/InfoIcon.svg';
import Tooltip from '@mui/material/Tooltip'
import withStyles from '@mui/styles/withStyles';
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    startOfYear,
    endOfYear,
    addYears,
    startOfQuarter
} from '../../../utils/date-formatter';
import CalenderIcon from '../../../assets/icons/calender-icon.svg';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { filter, isEmpty, map } from 'lodash'
const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
    startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
    startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
    startOfLastFullYearDay: startOfDay(addDays(new Date(), -365)),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    startOfQuarter: startOfQuarter(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOflastYear: startOfYear(addYears(new Date(), -1)),
    endOflastYear: endOfYear(addYears(new Date(), -1))
};

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#FCF8C8",
        color: "#222222",
        boxShadow: "-1px 1px 106px -61px rgba(74,73,74,1);",
        fontSize: 14,
        fontWeight: "medium",
        marginRight: '3px',
        fontFamily: "Raleway",
        borderRadius: 0,
        padding: 15,
        textAlign: "left"
    },
    arrow: {
        color: "#FCF8C8",
    },
    customWidth: {
        maxWidth: 200,
    },
}))(Tooltip);

const sideBarOptions = () => {
    const customDateObjects = [
        {
            label: "Today",
            range: () => ({
                startDate: defineds.startOfToday,
                endDate: defineds.endOfToday
            })
        },
        {
            label: "Yesterday",
            range: () => ({
                startDate: defineds.startOfYesterday,
                endDate: defineds.endOfYesterday
            })
        },
        {
            label: "Last Week",
            range: () => ({
                startDate: defineds.startOfLastWeek,
                endDate: defineds.endOfLastWeek
            })
        },
        {
            label: "Last Month",
            range: () => ({
                startDate: defineds.startOfLastMonth,
                endDate: defineds.endOfLastMonth
            })
        },
        {
            label: "Month to date",
            range: () => ({
                startDate: defineds.startOfMonth,
                endDate: defineds.endOfToday
            })
        },
        {
            label: "Quarter to date ",
            range: () => ({
                startDate: defineds.startOfQuarter,
                endDate: defineds.endOfToday
            })
        },
        {
            label: "Year to date",
            range: () => ({
                startDate: defineds.startOfYear,
                endDate: defineds.endOfToday
            })
        }
    ];

    return customDateObjects;
};

const ROWS_PER_PAGE = 10;

const TransactionTable = (props) => {
    const { filterTransactionDataOnDate = ()=>{}} = props;
    const [selectionDateRage, setSelectedDateRange] = useState({
        startDate: startOfMonth(new Date()),
        endDate: endOfDay(new Date()),
        key: 'selection'
    });
    const [openCalender, setOpenCalender] = useState(false);
    const [selectedCalenderInput, setSelectedCalenderInput] = useState('');
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [accountTableData, setAccountTableData] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [recentAppliedDateRange, setRecentAppliedDateRange] = useState({
        startDate: startOfMonth(new Date()),
        endDate: endOfDay(new Date()),
        key: 'selection'
    })
    const sideBar = sideBarOptions();

    const staticRanges = [
        ...createStaticRanges(sideBar)
    ];
    useEffect(() => {
        onResetDateRangeSelection()
     }, [props?.queryUserName])
    useEffect(()=>{
        const transactionData = filterTransactionDataOnDate(startOfMonth(new Date()), endOfDay(new Date()))
        setTransactions(transactionData)
    }, [])

    useEffect(() => {

        const calenderInputLabel = document.querySelector('.rdrStaticRangeSelected')?.firstChild?.textContent || `${moment(selectionDateRage?.startDate).format('MM-DD-YYYY')} - ${moment(selectionDateRage?.endDate).format('MM-DD-YYYY')}`;
        setSelectedCalenderInput(calenderInputLabel);

    }, [selectionDateRage])

    useEffect(() => {
       

        let tableData = [];
        tableData = filter(transactions, (data) => ((data?.transaction_id || "") + (data?.transaction_type_id || '')).toLowerCase().includes(searchText.toLowerCase()))
        setAccountTableData(tableData);
    }, [searchText, transactions])

    useEffect(() => {
      
        if(!isEmpty(selectedFilter)){
                if(selectedFilter === 'Show All'){  
                    setAccountTableData(transactions);
                }else{
                    let tableData = [];
                    setPage(0);
                    tableData = filter(transactions, (data)=> data?.transaction_type === selectedFilter);
                    setAccountTableData(tableData);
                }
        }else{
            setAccountTableData(transactions);
        }
      
    }, [selectedFilter])


    const onSearchTextChange = text => {
        setSearchText(text);
    }
    const setTablePage = (number) => {
        setPage(number);
    }
    const onResetDateRangeSelection = () => {
        let filteredData = [];
        setSelectedDateRange({
            startDate: startOfMonth(new Date()),
            endDate: endOfDay(new Date()),
            key: 'selection'
        });
        filteredData = filterTransactionDataOnDate(startOfMonth(new Date()), endOfDay(new Date()))
        setTransactions(filteredData)
        setAccountTableData(filteredData)
        setRecentAppliedDateRange({
            startDate: startOfMonth(new Date()),
            endDate: endOfDay(new Date()),
            key: 'selection'
        });
    }
    const onCalenderCancelButtonClick = () => {
        setOpenCalender(false);
        setSelectedDateRange(recentAppliedDateRange)
    }

    //makes API call
    const onApplyDateRangeButtonClick = async () => {
        if(isDate(selectionDateRage.startDate) && isDate(selectionDateRage.endDate)){
         
            let filteredData = [];
           
            filteredData = filterTransactionDataOnDate(moment(selectionDateRage.startDate), moment(selectionDateRage.endDate).endOf('day'));
            setAccountTableData(filteredData);
            setTransactions(filteredData)
            setOpenCalender(false);
            setRecentAppliedDateRange(selectionDateRage);
        }
    }
    const onCalenderSelect = (data) => {
        setSelectedDateRange(data.selection);
    }

    const maxCount = accountTableData?.length ? Math.ceil(accountTableData.length / ROWS_PER_PAGE) : 0;

    return (
        <div className={classes.contentContainer}>
            <div className={classes.headerTitle}>
                <h3 id='cvb-invoice-recentUploadHeading'>Transaction History</h3>
            </div>
            <div>
                <div className={classes.sectionTableHeader}>
                    <div className={classes.sectionLeftContainer}>
                        <ul>
                            <li className={classes.sorTextSearchID}>
                                <OutlinedInput fullWidth type='text'
                                    value={searchText}
                                    autoComplete={"off"}
                                    onChange={e => { onSearchTextChange(e.target.value) }}
                                    placeholder='Invoice Id Or Job Id'
                                    className='signup-inputs-large'
                                    id='cvb-invoice-searchByProjectField'
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="Search records" size="large">
                                                <img alt='visibility On' src={SearchIcon} className={classes.exploreSearchIcon} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </li>
                            <li className={classes.sortText}>
                                <label id='cvb-invoice-sortByLabel'>Transaction Type: </label>
                            </li>
                            <li className={classes.sortFilter} id='cvb-invoice-sortByDropdown'>
                                <DropDown
                                    selectedFilter={selectedFilter}
                                    values={TRANSACTION_TYPE_STATUS}
                                    handleFilterStateChange={(val) => setSelectedFilter(val)}
                                    placeholder={'Status'}
                                />
                            </li>
                            <li className={`${classes.sortFilterDate} ${classes.toRight} `}>
                                <div onClick={() => setOpenCalender(!openCalender)} className={classes.calenderContainer}>
                                    <span className={classes.text}>{selectedCalenderInput}</span>
                                    <span className={classes.searchIcon}>
                                        <img src={CalenderIcon} alt='search icon' />
                                    </span>
                                </div>
                                {openCalender ?
                                    <ClickAwayListener onClickAway={() => setOpenCalender(false)}>
                                        <div className={classes.datePickerContainer}>
                                            <DateRangePicker
                                                onChange={onCalenderSelect}
                                                staticRanges={staticRanges}
                                                inputRanges={[]}
                                                months={2}
                                                direction="horizontal"
                                                ranges={[selectionDateRage]}
                                            />
                                            <div className={classes.applyResetButtonContainer}>
                                                <button onClick={onResetDateRangeSelection} className={`medium-size-button no-outlined-button ${classes.reset}`}>Reset to default</button>
                                                <button onClick={onCalenderCancelButtonClick} className={`medium-size-button no-outlined-button ${classes.cancel}`}>Cancel</button>
                                                <button onClick={onApplyDateRangeButtonClick} className={`small-size-button solid-button ${classes.apply}`}>Apply</button>
                                            </div>
                                        </div>
                                    </ClickAwayListener> : null
                                }
                            </li>
                        </ul>
                    </div>

                </div>
                <div className={classes.selectProjectTableContainer} id='cvb-invoice-invoiceTableContainer'>
                    <TableContainer
                        component={Paper}
                        elevation={0}

                        className={classes.tableContainer}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell id='cvb-invoice-tableCellSubmitter' width="15%" align="left">Transaction Amount</TableCell>
                                    <TableCell id='cvb-invoice-tableCellProject' width="15%" align="center">Balance</TableCell>
                                    <TableCell id='cvb-invoice-tableCellUpdatedOn' width="20%" align="left">Type Of Transaction</TableCell>
                                    <TableCell id='cvb-invoice-tableCellFileSize' width="15%" align="left">Invoice Id</TableCell>
                                    <TableCell id='cvb-invoice-tableCellStatus' width="20%" align="left">Job Id</TableCell>
                                    <TableCell id='cvb-invoice-tableCellStatus' width="15%" align="left">Date</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { !isEmpty(accountTableData) ?
                                    map(accountTableData, (item, index) => {
                                        const transactionText = Number(item?.transaction_amount || "0") > 0 ? `+${item?.transaction_amount}` : `${item?.transaction_amount}`;
                                        const transactionTextClass = Number(item?.transaction_amount || "0") > 0 ? classes.positiveAmount : classes.negativeAmount;

                                        return <TableRow key={v4()} >

                                            <TableCell id={`cvb-invoice-tableCellSubmitterID-${index}`} className={classes.submitterId} align="right">
                                                <span className={`${classes.transactionTextStyle} ${transactionTextClass}`}>{transactionText}</span>
                                            </TableCell>
                                            <TableCell id={`cvb-invoice-tableCellProjectID-${index}`} align="center" className={`${classes.dateTimeField} ${classes.projectID}`}>{item?.balance ? `$${item?.balance}` : ''}</TableCell>
                                            <TableCell id={`cvb-invoice-tableCellFileSize-${index}`} align="left">{item?.transaction_type || ""}
                                                {item?.transaction_type === 'ADJUSTMENT' ? <LightTooltip placement="top" className={classes.userNameTitle} arrow title={item?.notes || ''}>
                                                    <img className={classes.infoIcon} src={InfoGreyIcon} alt="info icon" />
                                                </LightTooltip> : null}
                                            </TableCell>
                                            <TableCell id={`cvb-invoice-tableCellFileSize-${index}`} align="left">
                                                {item?.transaction_type !== 'DATA DOWNLOAD'  ? item?.transaction_type_id : '-'}

                                            </TableCell>
                                            <TableCell id={`cvb-invoice-tableCellSubmitterID-${index}`} className={classes.submitterId} align="left">
                                                {item?.transaction_type === 'DATA DOWNLOAD' ? item?.transaction_type_id : "-"}
                                            </TableCell>
                                            <TableCell id={`cvb-invoice-tableCellSubmitterID-${index}`} className={classes.submitterId} align="left">
                                                {moment.utc(item?.date)?.local().format('MM-DD-YYYY')+ ' at '+moment.utc(item?.date)?.local().format('hh:mm:ss A')  || "-"}
                                            </TableCell>

                                        </TableRow>
                                    }).slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
                                    : <TableRow>
                                        <TableCell>No Data Available</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                {maxCount > 0 && <div className={classes.tablePagination} id='cvb-invoice-tablePagination'>
                    <TablePagination
                        maxCount={maxCount}
                        page={page}
                        setPage={setTablePage}
                    />
                </div>}
            </div>
        </div>
    );
}

export default TransactionTable