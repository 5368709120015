import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import classes from './index.module.css';
import OutlinedInput from '@mui/material/OutlinedInput'
import map from 'lodash/map'
import cloneDeep from 'lodash/cloneDeep'
import ClosePng from '../../../assets/images/close.png'
import MuiPopover from '@mui/material/Popover';
import withStyles from '@mui/styles/withStyles';
import LockActive from '../../../assets/icons/lock-active.svg';
import LockInactive from '../../../assets/icons/lock-inactive.svg';
import DownArrow from '../../../assets/icons/down-arrow.svg';

const Popover = withStyles(theme => ({
    root: {
        overflowY: 'scroll',
    },
    paper: {
        minWidth: 192,
        right: 'auto !important',
        marginTop: '32px !important',
    }
}))(MuiPopover);

const MoreAboutYou = props => {
    const { moreAboutYou = {}, handleValueChange = () => { } } = props
    const { selectedUserPersona = "" } = props;
    const { affiliation = "" } = moreAboutYou;
    const [anchorElProjectDescPrivacy, setAnchorElProjectDescPrivacy] = useState(null);
   
    const onPublicationNameChange = (id, value) => {
        const moreAboutYouClone = cloneDeep(moreAboutYou);
        moreAboutYouClone['publications'][id] = {
            ...moreAboutYouClone['publications'][id],
            publicationName: value
        }
        handleValueChange(2, `publications`, moreAboutYouClone['publications'])
    }
    const onPublicationURLChange = (id, value) => {
        const moreAboutYouClone = cloneDeep(moreAboutYou);
        moreAboutYouClone['publications'][id] = {
            ...moreAboutYouClone['publications'][id],
            publicationURL: value
        }
        handleValueChange(2, `publications`, moreAboutYouClone['publications'])
    }
    
   
    useEffect(() => {
        if(moreAboutYou?.publications?.length === 0) {
            onAddMorePublicationClick()
        }
    }, [moreAboutYou])
    
    const onAddMorePublicationClick = () => {
        const moreAboutYouClone = cloneDeep(moreAboutYou);
        let publications = moreAboutYouClone['publications'];
        publications.push({ publicationName: '', publicationURL: '' });
        handleValueChange(2, `publications`, publications);
    }
    const removePublications = index => {
        const moreAboutYouClone = cloneDeep(moreAboutYou);
        let publications = moreAboutYouClone['publications'];
        publications.splice(index, 1);
        handleValueChange(2, `publications`, publications);
    }
    const onOpenProjectDescPrivacyPopover = (event) => {
        setAnchorElProjectDescPrivacy(event.currentTarget);
    }
    const projectDescPrivacyPopoverClose = (event) => {
        setAnchorElProjectDescPrivacy(null);
    }
    const onProjectPrivacyDropdownSelect = (value) => {
        setAnchorElProjectDescPrivacy(null);
        handleValueChange(2, `projectDescriptionPrivacy`, value);
    }
    const openProjectDescPrivacyPopover = Boolean(anchorElProjectDescPrivacy);

    return (
        <div className={classes.moreAboutYouRoot} id="cvb-tellUsMore-mainContainer">
            <p className={classes.tellUsMoreText} id="cvb-tellUsMore-title">Tell us more about your publications and R&D Projects</p>
            <Grid container spacing={3} direction='column'>
                {`${selectedUserPersona}`.toLowerCase() !== 'industrialexpert' &&
                    <Grid item lg={8} xs={12} md={8}>
                    <label>Key Publications</label>

                    {map(moreAboutYou?.publications, (field, idx) => {
                        return <Grid container spacing={3} direction='row' className={classes.fieldCont}>
                            <Grid item xs={6}>
                                <OutlinedInput
                                    type="text"
                                    id={`cvb-tellUsMore-publicationName-${idx}`}
                                    value={field?.publicationName}
                                    placeholder="Publication Name"
                                    onChange={e => onPublicationNameChange(idx, e.target.value)}
                                    key={`publicationName${idx}`}
                                    name={`publicationName${idx}`}
                                    fullWidth
                                    classes={{ input: 'onboardingInputs' }}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.fieldCont}>
                                <OutlinedInput
                                    type="text"
                                    id={`cvb-tellUsMore-publicationUrl-${idx}`}
                                    value={field?.publicationURL}
                                    placeholder="Publication URL"
                                    onChange={e => onPublicationURLChange(idx, e.target.value)}
                                    key={`publicationUrl${idx}`}
                                    name={`publicationUrl${idx}`}
                                    fullWidth
                                    classes={{ input: 'onboardingInputs' }}
                                />
                            </Grid>
                            {idx > 0 && <div className={classes.closeButtonContainer}>
                                <img id={`cvb-tellUsMore-publicationRemove-${idx}`} src={ClosePng} alt='delete' className={classes.removeFields} onClick={() => removePublications(idx)} />
                            </div>
                            }
                        </Grid>
                    })}
                    <span id="cvb-tellUsMore-publication-addMore" className={classes.addMorePublicationFields} onClick={onAddMorePublicationClick}>+ Add More</span>

                </Grid>
                }
                { `${selectedUserPersona}`.toLowerCase() !== 'industrialexpert' &&
                <Grid item lg={8} xs={12} md={12}>
                    <div id="cvb-tellUsMore-projectDescriptionPrivacyContainer" className={classes.contactDetailsPrivacyWrapper}>
                        <span className={classes.contactPrivacyText}>{moreAboutYou?.projectDescriptionPrivacy}</span>
                        {
                            moreAboutYou?.projectDescriptionPrivacy === 'Private' ?
                                <img className={classes.viewText} src={LockActive} onClick={(e) => onOpenProjectDescPrivacyPopover(e)} /> :
                                <img className={classes.viewText} src={LockInactive} onClick={(e) => onOpenProjectDescPrivacyPopover(e)} />

                        }
                         <img src={DownArrow} className={classes.downArrow} alt='down-arrow' onClick={(e) => onOpenProjectDescPrivacyPopover(e)}/>
                        <Popover
                            id='cvb-tellUsMore-projectDescriptionPrivacy'
                            open={openProjectDescPrivacyPopover}
                            anchorEl={anchorElProjectDescPrivacy}
                            onClose={projectDescPrivacyPopoverClose}
                            anchorOrigin={{
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                horizontal: 'right',
                            }}
                        >
                            <ul className={classes.dropdownListContainer}>
                                {map(['Private', 'Viewable by all'], (value, i) => {
                                     const isActive = moreAboutYou?.projectDescriptionPrivacy === value;
                                     const activeClass = isActive ? classes.activeElementClass : '';
                                    return <li className={activeClass} onClick={() => onProjectPrivacyDropdownSelect(value)}>{value}</li>
                                })}
                            </ul>
                        </Popover>
                    </div>
                    <label>Short Description Of Drug R&D Project</label>
                    <textarea
                        type="text"
                        value={moreAboutYou?.rndProjectDescription}
                        placeholder="Enter project description"
                        onChange={e => handleValueChange(2, 'rndProjectDescription', e.target.value)}
                        key={`rndProjectDescription`}
                        name={`rndProjectDescription`}
                        className={classes.texAreaStyle}
                        rows={3}
                        id="cvb-tellUsMore-rndProjectDescription-textArea"
                    />
                </Grid>
                }
                

            </Grid>
        </div>
    )
}

export default MoreAboutYou;
