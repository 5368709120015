import React, { useState, useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
import ViewStructuredDataStatus from '../view-structured-data-status'
import classes from './index.module.css'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '../../../assets/icons/search-icon.svg';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox'
import { getAllFilesToMap } from '../../../api/unstructured-data'
import { useMemo } from 'react'
import moment from 'moment'
import { v4 } from 'uuid'
import { getListOfProjects, getCoreMetadataCollection } from '../../../api/graphql/uploadData'
import { getDataModelSchema } from '../../../api/data-model-api'
import { submitMapData, removeFile } from '../../../api/upload-data-api'
import DropDown from '../../commons/dropdown'
import orderBy from 'lodash/orderBy'
import BackButtonIcon from '../../../assets/icons/left_arrow.png'
import CloseIcon from '../../../assets/icons/close-small.svg'
import DocumentIcon from '../../../assets/icons/document_status.svg'
import EnabledTickIcon from '../../../assets/icons/success.svg'
import OutlineSuccess from '../../../assets/icons/outline_success.svg'
import Modal from '../../commons/modal'
import useScrollToTop from '../../commons/scroll-top';
import Tooltip from '@mui/material/Tooltip'
import TablePagination from '../../commons/table-pagination'

const SHOW_ALL_OPTIONS = ["All", "Ready", "Generating"]
const SORT_BY = ['File Name', 'Upload Date', 'File Size', 'Upload Status']

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#FCF8C8",
        color: "#222222",
        boxShadow: "-1px 1px 106px -61px rgba(74,73,74,1);",
        fontSize: 14,
        fontWeight: "medium",
        marginRight: '3px',
        fontFamily: "Raleway",
        borderRadius: 0,
        padding: 15,
        textAlign: "left"
    },
    arrow: {
        color: "#FCF8C8"
    },
    customWidth: {
        maxWidth: 200,
    },
}))(Tooltip);

function UnstructuredData(props) {

    const [dictionary, setDictionary] = useState(null)
    // unmapped files field
    const [isMapSubmit, setIsMapSubmit] = useState(false)
    const [records, setRecords] = useState([])
    const [searchText, setSearchText] = useState('')
    const [selectedShowAllValue, setSelectedShowAllValue] = useState(SHOW_ALL_OPTIONS[0])
    const [selectedSortByValue, setSelectedSortByValue] = useState(SORT_BY[0])
    const [pageNumber, setPageNumber] = useState(0)
    const [openRemoveAllDialog, setOpenRemoveAllDialog] = useState(false)

    // Map submit fields
    const [projects, setProjects] = useState([])
    const [nodes, setNodes] = useState([])
    const [coreMetadataOptions, setCoreMetaDataOptions] = useState([])
    const [selectedProject, setSelectedProject] = useState('')
    const [selectedNode, setSelectedNode] = useState('')
    const [selectedCoreMetadataCollection, setSelectedCoreMetadataCollection] = useState('')
    const [dynamicFields, setDynamicFields] = useState([])

    // Submission results
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [submissionResults, setSubmissionResults] = useState([])
    const [successfulEntries, setSuccessfulEntries] = useState(0)
    const [openRemoveDialog, setOpenRemoveDialog] = useState(false)
    const [selectedRemoveItem, setSelectedRemoveItem] = useState(null);


    const closeRemoveDialog = () => {
        setOpenRemoveDialog(false);
        setSelectedRemoveItem(null);
    }

    const resetPage = () => {
        try {
            loadFiles();
            getProjects();
            getNodes();
        }
        catch (error) {
            console.log(error)
        }
    }

    useScrollToTop();

    const loadFiles = async () => {
        try {
            const files = await getAllFilesToMap();
            const records = files?.records || []
            setRecords(records)
        }
        catch (error) {
            console.log(error)
            setRecords([])
        }
    }

    const sortData = modifiedRecords => {
        switch (selectedSortByValue) {
            case SORT_BY[0]: return orderBy(modifiedRecords, ['file_name'], ['asc'])
            case SORT_BY[2]: return orderBy(modifiedRecords, ['size'], ['asc'])
            case SORT_BY[1]: return modifiedRecords.sort((a, b) => {
                return new Date(a["updated_date"]) - new Date(b["updated_date"])
            })
            case SORT_BY[3]: return orderBy(modifiedRecords, ['isReady'], ['asc'])
        }
    }

    const filterDataOnShow = records => {
        switch (selectedShowAllValue) {
            case SHOW_ALL_OPTIONS[0]: return records;
            case SHOW_ALL_OPTIONS[1]: return records.filter(item => item.isReady)
            case SHOW_ALL_OPTIONS[2]: return records.filter(item => !item.isReady)
        }
    }

    const allRecords = useMemo(() => {
        let modifiedRecords = records?.map(item => {
            if (item?.hashes) {
                if (Object.keys(item.hashes).length > 0) {
                    return { ...item, isReady: true, size: item.size ? item.size : 0 }
                }
            }
            return { ...item, size: item.size ? item.size : 0 }
        }) || []
        if (searchText) {
            modifiedRecords = modifiedRecords?.filter(item => item.file_name?.toLowerCase()?.includes(searchText.toLowerCase()))
        }
        modifiedRecords = filterDataOnShow(modifiedRecords)
        modifiedRecords = sortData(modifiedRecords)
        return modifiedRecords;
    }, [records, searchText, selectedSortByValue, selectedShowAllValue])

    const isAllRecordsSelected = useMemo(() => {
        return allRecords.filter(item => item.isSelected).length === allRecords.filter(item => item.isReady).length
    }, [allRecords])


    const selectAllRecords = () => {
        let cloneRecords = allRecords.map(item => {
            if (item.isReady) {
                return { ...item, isSelected: !isAllRecordsSelected }
            }
            return item
        })
        setRecords(cloneRecords)
    }

    const updateFileSelection = (selectedItem, value) => {
        const cloneRecords = [...records]
        const matchedItem = cloneRecords.find(item => selectedItem.baseid === item.baseid && selectedItem.did === item.did);
        matchedItem.isSelected = value === undefined ? !matchedItem.isSelected : value
        setRecords(cloneRecords)
    }

    const mapAllSelectedFiles = () => {
        setIsMapSubmit(true)
    }

    const removeAllSelectedFiles = async () => {
        let removedFilesCount = 0;
        let filesPromises = []
        for (let file of selectedFiles) {
            filesPromises.push(new Promise((resolve, reject) => {
                removeFile(file.did, file.rev).then((res) => {
                    setOpenRemoveDialog(false)
                    removedFilesCount++;
                    resolve();
                }).catch(error => {
                    reject()
                })
            }))
        }
        await Promise.all(filesPromises);
        if (removedFilesCount === selectedFiles.length) {
            props.updateSnackBar("Files successfully removed", "Success");
        } else {
            props.updateSnackBar(`${removedFilesCount} out of ${selectedFiles.length} Files successfully removed`, `Success`);
        }
        setOpenRemoveAllDialog(false)
        resetPage();
    }

    const mapFile = selectedItem => {
        let cloneRecords = allRecords.map(item => {
            if (item.isReady && item.did === selectedItem.did && item.baseid === selectedItem.baseid) {
                return { ...item, isSelected: true }
            }
            return { ...item, isSelected: false }
        });
        setRecords([...cloneRecords])
        mapAllSelectedFiles()
    }

    const handleRemoveFile = async () => {
        try {
            const item = selectedRemoveItem;
            const result = await removeFile(item.did, item.rev);
            setOpenRemoveDialog(false)
            resetPage();
            props.updateSnackBar("File successfully removed", "Success");
        }
        catch (error) {
            console.log(error)
            props.updateSnackBar("Unable to remove the file", "Error");
        }
    }

    const selectedFiles = useMemo(() => {
        return allRecords.filter(item => item.isSelected && item.isReady)
    }, [allRecords])

    const removeFileSelection = (selectedItem) => {
        updateFileSelection(selectedItem, false)
        if (selectedFiles.length < 2) {
            setIsMapSubmit(false);
            clearMapping()
        }
    }

    const getProjects = async () => {
        try {
            const projects = await getListOfProjects();
            const formattedProjects = projects?.map(item => item.project_id) || []
            setProjects(formattedProjects)
        }
        catch (error) {
            console.log(error)
        }
    }

    const getNodes = async () => {
        try {
            const result = await getDataModelSchema();
            setDictionary(result)
            let keys = Object.keys(result || {});
            keys = keys.filter(item => !item.startsWith("_"));
            let dataFileKeys = keys.filter(item => result[item].category === "data_file")
            setNodes(dataFileKeys)
        }
        catch (error) {
            console.log(error)
        }
    }

    const maxCount = allRecords.length ? Math.ceil(allRecords.length / 20) : 1


    const bytesToSize = (bytes) => {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    const allFilesSize = useMemo(() => {
        let totalSize = 0;
        for (let record of allRecords) {
            totalSize += +record.size || 0
        }
        return bytesToSize(totalSize)
    }, [allRecords])


    const recordsPerPage = useMemo(() => {
        if (allRecords?.length) {
            let showingRecords = []
            for (let i = (pageNumber * 20); i < (pageNumber + 1) * 20; i++) {
                if (allRecords[i])
                    showingRecords.push(allRecords[i])
            }
            return showingRecords;
        }
        return []
    }, [allRecords, pageNumber])

    useEffect(() => {
        loadFiles();
        getProjects();
        getNodes();
    }, [])

    const clearMapping = () => {
        setSelectedNode('')
        setSelectedCoreMetadataCollection('')
        setSelectedProject('')
        setIsMapSubmit(false)
        setIsSubmitted(false)
        setSubmissionResults([])
        setSuccessfulEntries(0);
        loadFiles();
        getProjects();
        getNodes();
    }

    const dialogRemoveProps = {
        modalTitle: 'Are you sure you want to remove this?',
        positiveButtonText: 'Remove',
        negativeButtonText: "Cancel",
        positiveButtonAction: handleRemoveFile,
        negativeButtonAction: closeRemoveDialog
    }
    const closeRemoveAllDialog = () => {
        setOpenRemoveAllDialog(false)
    }


    const setTablePage = number => {
        setPageNumber(number);
    }
    const REMOVE_ALL_DIALOG_PROPS = {
        modalTitle: `Are you sure you want to remove all the (${selectedFiles.length}) selected files?`,
        positiveButtonText: 'Remove',
        negativeButtonText: "Cancel",
        positiveButtonAction: removeAllSelectedFiles,
        negativeButtonAction: closeRemoveAllDialog
    }


    const handleMappingSubmit = () => {
        const chunks = [];
        let json = [];
        let fields = dictionary?.[selectedNode]?.required || []
        selectedFiles.forEach((file) => {

            const getParentNodes = (links, parents) => {
                links.forEach((link) => {
                    if (link.subgroup) {
                        parents = (getParentNodes(link.subgroup, parents));
                    } else {
                        parents[link.target_type] = link;
                    }
                });
                return parents;
            }
            const obj = {
                type: selectedNode,
                file_name: file.file_name,
                object_id: file.did,
                project_id: selectedProject,
                file_size: file.size,
                md5sum: file.hashes ? file.hashes.md5 : null,
                submitter_id: `${selectedProject}_${file.file_name}_${file?.did?.slice(-4) || ""}`,
                core_metadata_collections: selectedCoreMetadataCollection
            };

            for (let rf of requiredFields) {
                obj[rf.fieldName] = dynamicFields[rf.fieldName]
            }

            const parentTypeOfSelectionNode = getParentNodes(dictionary?.[selectedNode]?.links || [], {}) || {}

            obj[parentTypeOfSelectionNode['core_metadata_collection'].name] = {
                submitter_id: selectedCoreMetadataCollection,
            };

            json.push(obj);
            // chink size is 10
            if (json.length === 10) {
                chunks.push(json);
                json = [];
            }
        });
        if (json.length > 0) {
            chunks.push(json);
        }
        const programProject = selectedProject.split(/-(.+)/);
        const promises = [];
        let submissionRequest = []
        chunks.forEach((chunk) => {
            const promise = submitMapData(programProject[0], programProject[1], chunk)
                .then((res) => {
                    for (let i = 0; i < chunk.length; i++) {
                        if (res?.entities?.[i]?.errors?.length == 0) {
                            submissionRequest.push({
                                id: chunk[i].file_name,
                                isSuccess: true,
                                errorMessage: ""
                            })
                        }
                        else {
                            let errorMessages = res?.entities?.[i]?.errors?.map(item => item.message || '') || []
                            let messageString = ''
                            for (let message of errorMessages) {
                                messageString += message
                            }
                            submissionRequest.push({
                                id: chunk[i].file_name,
                                isSuccess: false,
                                errorMessage: messageString || "Something went wrong"
                            })
                        }
                    }
                }).catch(error => {
                    for (let i = 0; i < chunk.length; i++) {
                        if (error?.response?.data?.entities?.[i]?.errors?.length == 0) {
                            submissionRequest.push({
                                id: chunk[i].file_name,
                                isSuccess: true,
                                errorMessage: ""
                            })
                        }
                        else {
                            let errorMessages = error?.response?.data?.entities?.[i]?.errors?.map(item => item.message || '') || []
                            console.log(errorMessages)
                            let messageString = ''
                            for (let message of errorMessages) {
                                messageString += message
                            }
                            submissionRequest.push({
                                id: chunk[i].file_name,
                                isSuccess: false,
                                errorMessage: messageString || "Something went wrong"
                            })
                        }

                    }
                });
            promises.push(promise);
        });
        Promise.all(promises).then(() => {
            setSubmissionResults(submissionRequest);
            setIsSubmitted(true);
            let successEntries = submissionRequest.filter(item => item.isSuccess).length;
            setSuccessfulEntries(successEntries)
        });
    }

    const isMappingSubmitDisabled = () => {
        let isDisabled = false;
        if (selectedProject && selectedNode && selectedCoreMetadataCollection) {
            for (let x of requiredFields) {
                if (!dynamicFields[x.fieldName]) {
                    return true;
                }
            }
        } else {
            isDisabled = true;
        }
        return isDisabled;
    }

    const getCoreDataOptions = async val => {
        try {
            const response = await getCoreMetadataCollection(val)
            const submitterIds = response.map(item => item.submitter_id)
            setCoreMetaDataOptions(submitterIds)
        }
        catch (error) {
            setCoreMetaDataOptions([])
        }
    }

    const requiredFields = useMemo(() => {

        if (selectedProject && selectedNode) {
            let fields = dictionary?.[selectedNode]?.required || [];
            fields = fields.filter(x => x !== "md5sum" && x !== "file_size" && x !== "file_name" && x !== "type" && x !== "submitter_id");
            let properties = dictionary?.[selectedNode]?.properties || {}
            let finalFields = []
            for (let item of fields) {
                finalFields.push({
                    fieldName: item,
                    enum: properties?.[item]?.enum || null
                })
            }
            return finalFields;
        }
        return []

    }, [selectedProject, selectedNode])

    const renderRequiredFields = () => {
        return <Grid container spacing={3}>
            {
                requiredFields?.map((item, index) => {
                    return <Grid item xs={5} lg={5} md={5} key={`dynamic-field-${index}`}>
                        <div className={classes.blockSelect}>
                            <p id={`cvb-unStructuredData-dynamicFields-${item?.fieldName}-${index}`}>{item?.fieldName} <sup> *</sup></p>
                            {
                                item?.enum ?
                                    <DropDown
                                        selectedFilter={dynamicFields?.[item.fieldName] || ""}
                                        values={item.enum}
                                        id={`cvb-unStructuredData-dropDown-${index}`}
                                        handleFilterStateChange={(val) => {
                                            let cloneDynamicFields = { ...dynamicFields };
                                            cloneDynamicFields[item.fieldName] = val;
                                            setDynamicFields(cloneDynamicFields)
                                        }}
                                        placeholder={'Select'} /> :
                                    <OutlinedInput
                                        fullWidth
                                        id={`cvb-unStructuredData-dropDown-${index}`}
                                        value={dynamicFields?.[item.fieldName] || ''}
                                        onChange={e => {
                                            let cloneDynamicFields = { ...dynamicFields };
                                            cloneDynamicFields[item.fieldName] = e.target.value;
                                            setDynamicFields(cloneDynamicFields)
                                        }}
                                        placeholder='Enter the value'
                                        autoComplete='off'
                                    />
                            }

                        </div>
                    </Grid>
                })
            }
        </Grid>
    }

    return (
        <div>
            {
                !isMapSubmit && !isSubmitted &&
                <div className={classes.innerContainer} id='cvb-unStructuredData-mainContainer'>
                    <Grid container className={classes.searchBackground}>
                        <Grid item xs={12} lg={12} md={12}>
                            <div className={classes.sectionHeaderContainer}>
                                <div className={classes.gridTitle}><h2 id='cvb-unStructuredData-headingText'>My Uploads- Unstructured Data</h2></div>
                            </div>
                        </Grid>
                    </Grid>
                    <div className={classes.uploadStatsContainer}>
                        <div className={classes.uploadStatsLeftContainer}>
                            <Grid container>
                                <Grid item xs={10} lg={10} md={10}>
                                    <div className={classes.uploadStatsContainer}>
                                        <Grid container spacing={3} >
                                            <Grid item xs={4} lg={4} md={4} className={classes.uploadStatsItem}>
                                                <div className={classes.totalRecords}>
                                                    <p id='cvb-unStructuredData-totalUnmappedHeadingText'><img src={DocumentIcon} alt="total records" /> Total Unmapped Files</p>
                                                    <h3 id='cvb-unStructuredData-totalUnmappedCount'>{allRecords.length}</h3>
                                                </div>
                                            </Grid>
                                            <Grid item xs={4} lg={4} md={4} className={classes.uploadStatsItem}>
                                                <div className={classes.totalRecords}>
                                                    <p id='cvb-unStructuredData-sizeUnmappedHeadingText'><img src={DocumentIcon} alt="total records" /> Size of Unmapped Files </p>
                                                    <h3 id='cvb-unStructuredData-sizeUnmappedCount'>{allFilesSize}</h3>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <Grid container >
                        <Grid item xs={8} lg={8} md={8} className={classes.uploadTableContainer}>
                            <div className={classes.uploadTableContainerInner}>
                                <div className={classes.sectionTableHeader}>
                                    <div className={classes.sectionLeftContainer}>
                                        <h4 id='cvb-unStructuredData-tableHeadingText'>Data</h4>
                                    </div>
                                    <div className={classes.sectionRightContainer}>
                                        <ul>
                                            <li>
                                                <button className={`medium-size-button transparent-button  ${classes.exportRemoveButton}`}
                                                    disabled={selectedFiles.length === 0}
                                                    id='cvb-unStructuredData-removeSelectedFilesButton'
                                                    onClick={() => setOpenRemoveAllDialog(true)}>
                                                    Remove selected files
                                                </button>
                                            </li>
                                            <li>
                                                <button className={`medium-size-button solid-button ${classes.exportFailureButton}`}
                                                    disabled={selectedFiles.length === 0}
                                                    id='cvb-unStructuredData-mapSelectedFilesButton'
                                                    onClick={mapAllSelectedFiles}>
                                                    Map selected Files
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={classes.sectionTableHeader}>
                                    <div className={classes.sectionLeftContainer}>
                                        <ul>
                                            <li>
                                                <label id='cvb-unStructuredData-sortShowLabel'>Show: </label>
                                            </li>
                                            <li id='cvb-unStructuredData-sortShowDropdown' className={classes.sortFilter}>
                                                <DropDown
                                                    selectedFilter={selectedShowAllValue}
                                                    values={SHOW_ALL_OPTIONS}
                                                    customMoreFilterWrapper={classes.customMoreFilterWrapper}
                                                    handleFilterStateChange={(val) => { setSelectedShowAllValue(val) }}
                                                    placeholder={'Select'} />
                                            </li>
                                            <li>
                                                <label id='cvb-unStructuredData-sortByLabel'>Sort By: </label>
                                            </li>
                                            <li className={classes.sortFilter} id='cvb-unStructuredData-sortByDropdown'>
                                                <DropDown
                                                    customMoreFilterWrapper={classes.customMoreFilterWrapper}
                                                    selectedFilter={selectedSortByValue}
                                                    values={SORT_BY}
                                                    handleFilterStateChange={(val) => { setSelectedSortByValue(val) }}
                                                    placeholder={'Select'} /></li>
                                            <li id='cvb-unStructuredData-showCountText'>
                                                <p className={classes.recordsStats}>Showing <span>{recordsPerPage.length ? ((pageNumber * 20) + 1) : 0}</span> -  <span>{(pageNumber + 1) * 20 > allRecords.length ? allRecords.length : (pageNumber + 1) * 20}</span>
                                                    <span> of </span> <span>{allRecords.length}</span></p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className={classes.sectionRightContainer}>
                                        <ul className={classes.filterSection}>
                                            <li id='cvb-unStructuredData-searchSection'>
                                                <OutlinedInput fullWidth type='text'
                                                    value={searchText}
                                                    autoComplete={"off"}
                                                    onChange={e => setSearchText(e.target.value)}
                                                    placeholder='Search'
                                                    className='signup-inputs-large'
                                                    id='cvb-unStructuredData-searchField'
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton aria-label="Search records" size="large">
                                                                <img alt='visibility On' src={SearchIcon} className={classes.exploreSearchIcon} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={classes.selectProjectTableContainer} id='cvb-unStructuredData-tableContainer'>
                                    <TableContainer
                                        component={Paper}
                                        elevation={0}
                                        className={classes.tableContainer}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell width="20%" align="left" id='cvb-unStructuredData-tableHeaderFileName'>
                                                        <span className={classes.checkboxIdentifiers}>
                                                            <Checkbox
                                                                checked={isAllRecordsSelected}
                                                                color="primary"
                                                                onChange={selectAllRecords}
                                                                id='uploadCheck'
                                                            />
                                                        </span>
                                                    File Name
                                                </TableCell>
                                                    <TableCell id='cvb-unStructuredData-tableHeaderUploadedOn' width="30%" align="left">Uploaded On</TableCell>
                                                    <TableCell id='cvb-unStructuredData-tableHeaderFileSize' width="10%" align="left">File Size</TableCell>
                                                    <TableCell id='cvb-unStructuredData-tableHeaderStatus' width="20%" align="left">Status</TableCell>
                                                    <TableCell width="10%" align="left"></TableCell>
                                                    <TableCell width="10%" align="left"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    recordsPerPage?.map(item => {
                                                        let updatedTime = "-"
                                                        if (item.updated_date) {
                                                            var stillUtc = moment.utc(item.updated_date).toDate();
                                                            var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
                                                            updatedTime = `${moment(local).format('MM/DD/YYYY')} at 
                                                            ${moment(local).format("hh:mm A")}`
                                                        }
                                                        return <TableRow key={v4()}>
                                                            <TableCell align="left" id='cvb-unStructuredData-fileNameCheckBox'>
                                                                <span className={classes.checkboxIdentifiers}>
                                                                    <Checkbox
                                                                        checked={item.isSelected}
                                                                        color="primary"
                                                                        onChange={() => updateFileSelection(item)}
                                                                        id='uploadCheck'
                                                                        disabled={!item?.isReady}
                                                                    />
                                                                </span>
                                                                <LightTooltip placement="bottom" className={classes.userNameTitle} arrow title={item?.file_name || ''}>
                                                                    <span className={classes.fileNameTitle}>{item?.file_name || ''}</span>
                                                                </LightTooltip>
                                                            </TableCell>
                                                            <TableCell id='cvb-unStructuredData-updateTimeCell' align="left">{updatedTime}</TableCell>
                                                            <TableCell id='cvb-unStructuredData-fileSizeCell' align="left">{bytesToSize(item?.size || 0)}</TableCell>
                                                            <TableCell id='cvb-unStructuredData-statusCell' align="left" className={classes.statusFile}>
                                                                <img alt='status icon' src={item?.isReady ? EnabledTickIcon : OutlineSuccess} className={classes.enabledTickIconImage} />
                                                                {item?.isReady ? 'Ready to be mapped' : 'Generating'}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {
                                                                    item?.isReady &&
                                                                    <button id='cvb-unStructuredData-mapButton' onClick={() => { mapFile(item) }} className={`small-size-button transparent-button  ${classes.tableActionButton}`}>
                                                                        Map
                                                                    </button>
                                                                }
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <button id='cvb-unStructuredData-removeButton' className={`small-size-button transparent-button  ${classes.tableActionButton}`}
                                                                    onClick={() => { setSelectedRemoveItem(item); setOpenRemoveDialog(true) }}>
                                                                    Remove
                                                                </button>
                                                            </TableCell>
                                                        </TableRow>
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                            <div className={classes.tablePagination} id='cvb-unStructuredData-tablePaginationSection'>
                                <TablePagination
                                    maxCount={maxCount}
                                    page={pageNumber}
                                    setPage={setTablePage}
                                />
                            </div>
                        </Grid>
                    </Grid>

                </div>
            }
            {
                isMapSubmit && !isSubmitted &&
                <div className={classes.innerContainer}>
                    <Grid container >
                        <Grid item xs={10} lg={10} md={10} className={classes.uploadTableContainer}>
                            <div className={classes.mapFilesHeader}>
                                <div className={classes.backButtonContainer} onClick={() => { setIsMapSubmit(false) }}>
                                    <p><img src={BackButtonIcon} alt="black button" /> Back</p>
                                </div>
                                <h3 id='cvb-unStructuredData-mapFilesHeading' className={classes.headerSubTitle}>Map Files</h3>
                                <h1 id='cvb-unStructuredData-assignHeadingText' className={classes.headerTitle}>Assign Project and Node Type</h1>
                            </div>
                            <p id='cvb-unStructuredData-selectFilesHeadingText' className={classes.mapTitle}>Selected files ({selectedFiles.length})</p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={10} lg={10} md={10}>
                            <Grid container spacing={2} className={classes.uploadBlockTiles}>
                                {
                                    selectedFiles.map(item => {
                                        return <Grid key={v4()} item xs={2} lg={2} md={2}>
                                            <div key={v4()} className={classes.selectedFilesContainer} id='cvb-unStructuredData-mapFilesSectionContainer'>
                                                <LightTooltip placement="bottom" className={classes.userNameTitle} arrow title={item.file_name}>
                                                    <p id='cvb-unStructuredData-mapFilesSectionFileName' className={classes.selectedFileName}>{item.file_name}</p>
                                                </LightTooltip>
                                                <div id='cvb-unStructuredData-mapFilesRemoveFilesButton' className={classes.closeIcon} onClick={() => removeFileSelection(item)}>
                                                    <img src={CloseIcon} alt="close Icon" />
                                                </div>
                                                <p id='cvb-unStructuredData-mapFilesRemoveFilesSize' className={classes.selectedFileSize}>{item.size}KB</p>
                                            </div>
                                        </Grid>
                                    })
                                }
                            </Grid>
                            <Grid container spacing={3} className={classes.dropDownsContainer}>
                                <Grid item xs={5} lg={5} md={5}>
                                    <div className={classes.blockSelect} id='cvb-unStructuredData-projectsDropDown'>
                                        <p>Projects <sup> *</sup></p>
                                        <DropDown
                                            selectedFilter={selectedProject}
                                            values={projects}
                                            handleFilterStateChange={(val) => {
                                                setSelectedProject(val);
                                                getCoreDataOptions(val);
                                                setDynamicFields({})
                                                setSelectedCoreMetadataCollection("")
                                            }}
                                            placeholder={'Enter Project Name To Select'} />
                                    </div>
                                </Grid>
                                <Grid item xs={5} lg={5} md={5}>
                                    <div className={classes.blockSelect} id='cvb-unStructuredData-nodeDropDown'>
                                        <p>Node <sup> *</sup></p>
                                        <DropDown
                                            selectedFilter={selectedNode}
                                            values={nodes}
                                            handleFilterStateChange={(val) => {
                                                setSelectedNode(val);
                                                setDynamicFields({})
                                                setSelectedCoreMetadataCollection("")

                                            }}
                                            placeholder={'Enter Project Name To Select'} />
                                    </div>
                                </Grid>
                            </Grid>
                            {
                                renderRequiredFields()
                            }
                            {
                                requiredFields?.length > 0 &&
                                <Grid container spacing={3}>
                                    <Grid item xs={5} lg={5} md={5}>
                                        <div className={classes.blockSelect} id='cvb-unStructuredData-coreMetaDataDropdown'>
                                            <p> Core MetaData Collections <sup> *</sup></p>
                                            <DropDown
                                                selectedFilter={selectedCoreMetadataCollection}
                                                values={coreMetadataOptions}
                                                handleFilterStateChange={(val) => { setSelectedCoreMetadataCollection(val) }}
                                                placeholder={'Enter Parent Node To Select'} />
                                        </div>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <div className={classes.buttonContainer}>
                        <div className={classes.buttonWrapperClass}>
                            <button id='cvb-unStructuredData-cancelButtonMapping' className={`no-outlined-button small-size-button ${classes.cancelButton}`} onClick={clearMapping}>Cancel</button>
                            <button id='cvb-unStructuredData-submitButtonMapping' type="submit" className={`medium-size-button solid-button ${classes.saveDraftButton}`}
                                onClick={handleMappingSubmit}
                                disabled={isMappingSubmitDisabled()}
                            >
                                Submit
                        </button>
                        </div>
                    </div>
                </div>
            }
            {
                isSubmitted &&
                <ViewStructuredDataStatus
                    affectedRecords={{
                        successfulEntries: successfulEntries,
                        failedEntries: submissionResults.length - successfulEntries,
                        totalEntries: submissionResults.length
                    }}
                    failureRecords={submissionResults}
                    tableHeader="Files"
                    isUnStructuredData={true}
                    clearMapping={clearMapping}
                />
            }

            <Modal
                open={openRemoveDialog}
                handleClose={closeRemoveDialog}
                dialogProps={dialogRemoveProps}
                disableTitleDivider={true}
            >
            </Modal>
            <Modal
                open={openRemoveAllDialog}
                handleClose={closeRemoveAllDialog}
                dialogProps={REMOVE_ALL_DIALOG_PROPS}
                disableTitleDivider={true}
            >
            </Modal>
        </div >
    );
}

export default UnstructuredData;
