import React, { useEffect } from "react";
import { Router, Redirect } from "@reach/router";
import ExploreCohorts from "../explore-cohorts";
import ExploreCreateCohort from "../explore-cohorts/create-cohorts/";
import Workspace from "../workspace";
import Tools from "../tools";
import useScrollToTop from "../../commons/scroll-top";
import SearchResults from "../search-results";
import { sendGAEvents } from "../../../api/generic-api";
import ProjectGallery from "../project-gallery";

const Explore = (props) => {
    useEffect(() => {
        sendGAEvents("page_view", "", "", { page_title: "explore_landing" });
    }, []);

    useScrollToTop();

    return (
        <div>
            <Router id="router" primary={false}>
                <ProjectGallery path='/' props={props} />
                <ExploreCohorts path="/cohorts" open={props.open} />
                <Tools path="/tools/*" open={props.open} />
                <Workspace path="/workspace/*" open={props.open} />
                <SearchResults path="/search-results/:id" open={props.open} />
                <ExploreCreateCohort path="/cohorts/new" open={props.open} />
                {props.userDetails?.resourceActions?.explore_data?.explore_data && (
                <Redirect
                    from="/explore/data/graphical-data"
                    to="/explore/cohorts"
                    state={{open: true}}        
                    default
                    noThrow
                />
                )}
            </Router>
        </div>
    );
};

export default Explore;
