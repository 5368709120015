import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import {v4} from 'uuid'
import { ADMIN_DASHBOARD_TABS } from '../../../../constants/admin-dashboard-tabs'
import styles from './index.module.css'

const DEFAULT_HEADER_HEIGHT = 187;

const AdminDashboardTabs = (props) => {
    const {tabRefs, setScrollPos} = props
    const tabs = Object.values(ADMIN_DASHBOARD_TABS)
    const openedTab = useSelector(state => state.adminDashboard.openedTab)
    const [activeTab, setActiveTab] = useState(tabs[0]);

    const scrollTo = ele => {
      const refTopValue = ele?.getBoundingClientRect().top + 5;
        if (refTopValue) {
            window.scrollTo({
                top: refTopValue - DEFAULT_HEADER_HEIGHT + window.scrollY,
                behavior: "smooth",
            });
        }
      };

    const getDimensions = ele => {
        const { height } = ele.getBoundingClientRect();
        const offsetTop = ele.offsetTop;
        const offsetBottom = offsetTop + height;
      
        return {
          height,
          offsetTop,
          offsetBottom,
        };
      };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + DEFAULT_HEADER_HEIGHT;
            setScrollPos(scrollPosition)
            const selected = tabRefs.find(({ section, ref }) => {
                if (ref?.current) {
                  const { offsetBottom, offsetTop } = getDimensions(ref?.current);
                  return scrollPosition > offsetTop && scrollPosition < offsetBottom;
                }
              });

              if (selected && selected.section !== activeTab) {
                setActiveTab(selected.section);
              } else if (!selected && activeTab) {
                setActiveTab(undefined);
              }
              if(window.innerHeight + window.scrollY >= document.body.scrollHeight){
                setActiveTab(tabRefs[tabRefs.length - 1].section)
              }
        };

        handleScroll();
        window.addEventListener("scroll", handleScroll);

        return () => {
        window.removeEventListener("scroll", handleScroll);
        };
      }, [activeTab]);

    useEffect(() => {
        if(openedTab !== null) {
            const tab = tabRefs.filter(tab => tab?.section === openedTab?.title)[0]
            scrollTo(tab?.ref?.current)
        }
    }, [openedTab])

    return (
        <ul className={styles.exampleInfoNav}>
            {tabRefs.map(tab => {
                if(tab.ref !== null){
                    return <li key={v4()} className={
                        tab.section === activeTab ? `${styles.exampleInfoNavItem} ${styles.exampleInfoNavItemActive}`
                        : styles.exampleInfoNavItem
                        }>
                            <button
                            type="button"
                            onClick={() => scrollTo(tab.ref.current)}
                            >
                            {tab.section}
                            </button>
                        </li>
                } else {
                    return
                }
            })}
        </ul>
    )
}

export default AdminDashboardTabs
