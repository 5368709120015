import React, { useState, useEffect } from 'react'
import Classes from './index.module.css'
import get from 'lodash/get'
import Grid from '@mui/material/Grid'
import MuiPopover from '@mui/material/Popover';
import withStyles from '@mui/styles/withStyles';
import Modal from '../../commons/modal'
import AddUsersToShare from '../add-users-to-share';
import InputAdornment from '@mui/material/InputAdornment';
import {
  getWorkspaceStatus, getSharedUsersForWorkspace, getWorkspaceLaunchUrl,
  stopWorkspace, startWorkspace, shareWorkspaceWithUsers, quitWorkspace, unShareWorkspaceWithUsers,
  getActiveUsersForWorkspace,
  getWorkspaceUsernamePassword, updateWorkspaceName
} from '../../../api/workspace-api/workspace-api';
import isEmpty from 'lodash/isEmpty';
import WorkspaceLogo from '../../../assets/icons/workspacelogo.svg';
import Tooltip from '@mui/material/Tooltip'
import { navigate } from '@reach/router'
import CustomLoader from '../../commons/custom-loader';
import { fetchWorkSpaceOverallStatus } from '../../../redux/actions/globalActions'
import map from 'lodash/map'
import CautionIcon from '../../../assets/images/caution.svg';
import moment from 'moment'
import DefaultProfileImage from '../../../assets/images/user/default-profile-image.png'
import { getProfilePicture } from '../../../api/profile-api'
import {
  WORKSPACE_STATUS_STARTED,
  WORKSPACE_STATUS_STARTING,
  WORKSPACE_STATUS_STOPPING,
  WORKSPACE_STATUS_STOPPED,
  WORKSPACE_STATUS_LOCKED
} from '../../../constants/strings';
import { v4 } from 'uuid'
import { sendGAEvents } from '../../../api/generic-api';
import LinkIcon from '../../../assets/images/link-icon.svg'
import DotIconDark from "../../../assets/icons/MoreOptionsVertical.S-dark.svg"
import CartIcon from "../../../assets/images/workspaceCartIcon.svg"
import OutlinedInput from '@mui/material/OutlinedInput';
import { generateInvoiceDetails } from '../../../api/invoice-api'
import EditIcon from '@mui/icons-material/Edit'
const Popover = withStyles(theme => ({
  root: {
    overflowY: 'scroll',
  },
  paper: {
    minWidth: 300,
    right: 'auto !important',
    marginTop: '32px !important',
  }
}))(MuiPopover);

const WorkspaceCard = props => {
  const [anchorElUserData, setAnchorElUserData] = useState(null);
  const [anchorElWorkspaceCred, setAnchorElWorkspaceCred] = useState(null);
  const [anchorElChangeWorkspaceCard, setAnchorElChangeWorkspaceCard] = useState(null);
  const [openShareWorkspaceModal, setOpenShareWorkspaceModal] = useState(false);
  const [openStartStopWorkspaceModal, setOpenStartStopWorkspaceModal] = useState(false);
  const [openQuitWorkspaceModal, setOpenQuitWorkspaceModal] = useState(false);
  const [openLaunchWorkspaceModal, setOpenLaunchWorkspaceModal] = useState(false);
  const [launchWorkspaceButtonText, setLaunchWorkspaceButtonText] = useState('');
  const [launchWorkspaceModalHeader, setLaunchWorkspaceModalHeader] = useState('');
  const [launchWorkspaceModalSubHeader, setLaunchWorkspaceModalSubHeader] = useState('');
  const [workspaceStatus, setWorkspaceStatus] = useState('');
  const [sharedUsersList, setSharedUsersList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadStatus, setLoadStatus] = useState(false);
  const [popupButtonDisable, setPopupButtonDisable] = useState(false);
  const [shareErrorMessage, setShareErrorMessage] = useState('');
  const { selectedCard = '' } = props;
  const { updateSnackBar = () => { } } = props;

  const { onViewFileDirectory = () => { } } = props;
  const { onClickViewFileDirectory = () => { } } = props;
  const id = get(props, 'eventData.workspace_id', '');
  const workspaceDetails = get(props, 'workspaceDetails', '');
  const [activeUsers, setActiveUsers] = useState(null);
  const [activeUsersProfilePictures, setActiveUsersProfilePictures] = useState({});
  const [workspaceAccessCredentials, setWorkspaceAccessCredentials] = useState({});
  const [openWorkspaceUsernameToolTip, setOpenWorkspaceUsernameToolTip] = useState(false);
  const [openWorkspacePasswordToolTip, setOpenWorkspacePasswordToolTip] = useState(false);
  const [openLaunchWorkspaceCautionModal, setOpenLaunchWorkspaceCautionModal] = useState(false);
  const [isLoadingUsersList, setIsLoadingUsersList] = useState(false);
  const [filesCount, setFilesCount] = useState(0);
  const [topupAmount, setTopupAmount] = useState(0);
  const [openTopupModal, setOpenToupModal] = useState(false);
  const { getCurrentWorkspaceDetails = () => { } } = props;
  const [openEditWorkspaceNameModal, setOpenEditWorkspaceNameModal] = useState(false)
  const [newWorkspaceName, setNewWorkspaceName] = useState(get(props, 'workspaceDetails.workspace_name', ''))
  useEffect(() => {
    setTopupAmount(workspaceDetails?.credit_amount);
  }, [id])
  const getWorkspaceAccessCredentials = async (id) => {
    try {
      const res = await getWorkspaceUsernamePassword(id);
      setWorkspaceAccessCredentials(res);
    } catch (error) {
      console.log('error in getting workspace access request');
    }
  }

  useEffect(() => {
    if (props.allWorkspacesCarts?.workspace_carts && (id in props.allWorkspacesCarts?.workspace_carts)) {
      for (let cart of props.allWorkspacesCarts?.workspace_carts[id]) {
        if (cart?.status?.toLowerCase() === "open") {
          setFilesCount(cart.files.length)
        }
      }
    }

  }, [props.allWorkspacesCarts])

  useEffect(() => {
    if (!isEmpty(id)) {
      getWorkspaceAccessCredentials(id);
    }
  }, [id]);

  const onOpenWorkspaceCredPopover = (event) => {
    setAnchorElWorkspaceCred(event.currentTarget);
  }
  const getCurrentWorkspaceStatus = async () => {
    try {
      const id = get(props, 'eventData.workspace_id', '');
      if (!isEmpty(id)) {
        const res = await getWorkspaceStatus(id);
        const status = get(res, 'status', '');
        setWorkspaceStatus(status);
        setIsLoading(false);
      }
    } catch (error) {
      console.log('error in getting status');
      setWorkspaceStatus('');
      setIsLoading(false);
    }
  }

  const onOpenPopover = (event) => {
    setAnchorElUserData(event.currentTarget);
  }

  const handleCloseWorkspaceCredPopover = () => {
    setAnchorElWorkspaceCred(null);
  }
  const getCurrentWorkspaceSharedUser = async () => {
    try {
      const id = get(props, 'eventData.workspace_id', '');
      if (!isEmpty(id)) {
        const res = await getSharedUsersForWorkspace(id);
        setSharedUsersList(res);
      }
    } catch (error) {
      setSharedUsersList([]);
      console.log('error in getting shared users');
    }
  }


  useEffect(() => {
    //api  calls
    getCurrentWorkspaceStatus();
    getCurrentWorkspaceSharedUser();
  }, [])

  const sharedUserClose = () => {
    setAnchorElUserData(null);
  }

  const openViewPopover = Boolean(anchorElUserData);
  const openWorkspaceCredPopover = Boolean(anchorElWorkspaceCred);
  const openWorkspaceChangePopover = Boolean(anchorElChangeWorkspaceCard)

  const workspaceStatusBackground = workspaceStatus === WORKSPACE_STATUS_STARTED || workspaceStatus === WORKSPACE_STATUS_STARTING || workspaceStatus === WORKSPACE_STATUS_STOPPING
    ? Classes.activeWorkspaceBackground
    : Classes.stoppedWorkspaceBackground;

  let starOrStopText = workspaceStatus === WORKSPACE_STATUS_STARTED || workspaceStatus === WORKSPACE_STATUS_STOPPING
    ? 'Stop Workspace'
    : 'Start Workspace';

  let workSpaceButtonDisable = workspaceStatus === WORKSPACE_STATUS_STARTED || workspaceStatus === WORKSPACE_STATUS_STOPPED
    ? false
    : true;

  let workSpaceLaunchButtonDisable = workspaceStatus === WORKSPACE_STATUS_STARTING || workspaceStatus === WORKSPACE_STATUS_STOPPED || workspaceStatus === WORKSPACE_STATUS_STOPPING
    || workspaceStatus === WORKSPACE_STATUS_LOCKED
    ? true
    : false;

  const errorState = () => {
    starOrStopText = 'Start Workspace';
    return workSpaceButtonDisable = false;
  }
let workSpaceStatusStopped;
  useEffect(() => {
    try {
      let interval;
      interval = setInterval(() => {
        intervalApiCall();
      }, 10000);
      return () => {
        window.clearInterval(interval);
      };
    } catch (error) {
      console.log('error in getting launch URL', error);
      errorState();
    }
  }, [])

  useEffect(() => {
    fetchWorkSpaceOverallStatus()
  }, [workspaceStatus])

  const intervalApiCall = (async () => {
    try {
      if (workspaceStatus !== WORKSPACE_STATUS_STOPPED) {
        const res = await getWorkspaceStatus(id);
        const status = get(res, 'status', '');
        setWorkspaceStatus(status);
        workSpaceStatusStopped = status
      }
    } catch (error) {
      console.log('error in getting workspace status')
    }
  })

  const onShareWorkspaceSelect = () => {
    setOpenShareWorkspaceModal(true);
  }

  const onViewWorkSpaceTransactions = () => {
    navigate(`/explore/tools/workspace-cost/${id}`)
  }

  const closeLaunchWorkspaceCautionModal = () => {
    setOpenLaunchWorkspaceCautionModal(false);
  }
  const updateLaunchModalProps = () => {
    setOpenLaunchWorkspaceCautionModal(false)
    setPopupButtonDisable(false);
    setLaunchWorkspaceButtonText('Launch Workspace');
    setLaunchWorkspaceModalHeader(`${workspaceDetails?.type || ''}`.toLowerCase() === 'windows' ? '' : 'Are you sure you want to launch this workspace ?');
    setLaunchWorkspaceModalSubHeader('');
    setOpenLaunchWorkspaceModal(true);
  }

  const onLaunchWorkspaceCautionConfirm = () => {
    updateLaunchModalProps();
  }

  const onLaunchWorkspaceClick = async () => {
    if (workspaceStatus === WORKSPACE_STATUS_STARTED) {
      setPopupButtonDisable(false);
      setIsLoadingUsersList(true);
      const activeUsersList = await updateActiveUsersList();
      setIsLoadingUsersList(false);
      if ((activeUsersList?.length || false)) {
        setOpenLaunchWorkspaceCautionModal(true);
      } else {
        setOpenLaunchWorkspaceCautionModal(false);
        updateLaunchModalProps();
      }
    } else {
      setOpenLaunchWorkspaceModal(true);
      setLaunchWorkspaceButtonText('Start & Launch Workspace');
      setLaunchWorkspaceModalHeader('Start Workspace To Launch');
      setLaunchWorkspaceModalSubHeader(`The workspace you are trying to launch is currently stopped. In order to launch the workspace,
           you need to first start the workspace. Please confirm if you want to proceed.`);
    }
  }
  const onShareWorkspaceModal = () => {

  }
  const closeShareWorkspaceModal = () => {
    setOpenShareWorkspaceModal(false);
  }
  const onAddUserClick = async (userData) => {
    try {
      const id = get(props, 'eventData.workspace_id', '');
      const res = await shareWorkspaceWithUsers(id, userData);
      if (res == '200') {
        getCurrentWorkspaceSharedUser();
      }
      setShareErrorMessage('');
    } catch (error) {
      setShareErrorMessage(error?.message);
      console.log('error in adding users', error?.message);
    }
  }
  const updateActiveUsersList = async () => {
    const id = get(props, 'eventData.workspace_id', '');
    let workspaceActiveUsers = [];
    try {
      workspaceActiveUsers = await getActiveUsersForWorkspace(id);
    } catch (error) {
      console.log('Error in fetching active users', error);
      setIsLoading(false);
      setIsLoadingUsersList(false);
    }
    let usersProfilePicture = {};
    if (workspaceActiveUsers?.length) {
      map(workspaceActiveUsers, async (user) => {
        user.profilePicture = DefaultProfileImage;
        if (user?.last_activity) {
          user.last_activity_formatted = moment(user.last_activity).calendar();
          if (user.last_activity_formatted.includes('Last')) {
            user.last_activity_formatted = moment(user.last_activity).format("Do MMM, h:mm a");
          }
        }
        const userSub = get(user, 'sub', '');
        const profilePictureURL = (await getProfilePicture(user?.sub)) || "";
        if (!isEmpty(userSub)) {
          usersProfilePicture = {
            ...usersProfilePicture,
            [userSub]: profilePictureURL
          }
        }
        setActiveUsersProfilePictures(usersProfilePicture);
      })

    }
    setActiveUsers(workspaceActiveUsers);
    return workspaceActiveUsers;
  }
  const onStartStopWorkspaceClicked = () => {
    setOpenStartStopWorkspaceModal(true);
    setAnchorElChangeWorkspaceCard(false)
    if (startStopWorkspaceModalContent === 'Stop Workspace') {
      updateActiveUsersList();
    }
  }
  const stopThisWorkspace = async () => {
    try {
      const id = get(props, 'eventData.workspace_id', '');
      const res = await stopWorkspace(id);
      if (res == '200') {
        getCurrentWorkspaceStatus();
      }
      setOpenStartStopWorkspaceModal(false);
    } catch (error) {
      console.log('error is while stopping', error)
    }
  }
  const startThisWorkspace = async () => {
    try {
      sendGAEvents('start_workspace', 'start_workspace', 'start workspace', 'start workspace');
      const id = get(props, 'eventData.workspace_id', '');
      const res = await startWorkspace(id);
      if (res == '200') {
        getCurrentWorkspaceStatus();
      }
      updateSnackBar('This should only take a few minutes.', 'success');
    } catch (error) {
      updateSnackBar('Error in starting the workspace', 'error');
      console.log('error is while stopping', error)
    }
  }

  const onStartStopWorkspaceConfirm = async () => {
    try {
      if (workspaceStatus === WORKSPACE_STATUS_STOPPED) {
        startThisWorkspace();
      }
      else {
        stopThisWorkspace();

      }
      setOpenStartStopWorkspaceModal(false);
    } catch (error) {
      console.log('error ins start or stop');
    }
  }
  const closeStartStopWorkspaceModal = () => {
    setOpenStartStopWorkspaceModal(false);
  }
  const onQuitWorkspaceConfirm = async () => {
    try {
      //api call
      setOpenQuitWorkspaceModal(false)
      const res = await quitWorkspace(id);
    } catch (error) {
      console.log('error ins start or stop');
    }
  }
  const onLaunchWorkspaceConfirm = async () => {
    try {
      sendGAEvents('launch_workspace', 'launch_workspace', 'launch workspace');
      const isActive = workspaceStatus === WORKSPACE_STATUS_STARTED;
      let response;
      const id = get(props, 'eventData.workspace_id', '');
      if (!isEmpty(id)) {
        setPopupButtonDisable(true);
        setLoadStatus(true)
        response = await getWorkspaceLaunchUrl(id);
      }
      const url = get(response, 'streaming_url', '');
      if (!isEmpty(url) && isActive) {
        setLoadStatus(true)
        window.open(url, "_blank");
      } else {
        startThisWorkspace();
      }
      setLoadStatus(false)
      if (`${workspaceDetails?.type || ''}`.toLowerCase() != 'windows') {
        setOpenLaunchWorkspaceModal(false)
      }
    } catch (error) {
      updateSnackBar('Error in launch workspace.', 'error');
      console.log('error ins start or stop', error);
      setLoadStatus(false)
      setOpenLaunchWorkspaceModal(false)
    }
  }
  const unshareWorkspaceForSelectedUser = async (email) => {
    try {
      const id = get(props, 'eventData.workspace_id', '');
      const data = { "sharedWith": email };
      const res = await unShareWorkspaceWithUsers(id, data);
      if (res) {
        getCurrentWorkspaceSharedUser();
      }
    } catch (error) {
      console.log('error ins start or stop', error);
    }
  }

  const closeQuitWorkspaceModal = () => {
    setOpenQuitWorkspaceModal(false)
  }
  const closeLaunchWorkspaceModal = () => {
    setOpenLaunchWorkspaceModal(false);

  }

  useEffect(() => {
    if (openStartStopWorkspaceModal) {
      setActiveUsers(null);
    }
  }, [openStartStopWorkspaceModal])

  const onViewFileDirectoryClick = (id) => {
    try {
      onViewFileDirectory(id);
      onClickViewFileDirectory();
      const currentBucketId = get(workspaceDetails, 'scratch_bucket_name', '');
      localStorage.setItem('currentBucketId', currentBucketId);
      navigate(`/explore/tools/workspace/${id}`, { state: { bucketId: currentBucketId, status: workspaceStatus, piOwner: workspaceDetails?.pi_owner } });
    } catch (error) {
      console.log('error in view file', error)
    }
  }

  const navigateToOwnershipChange = () => navigate(`/explore/tools/workspace/ownership-change/${id}`)

  const onUserNameCopyClick = () => {
    setOpenWorkspaceUsernameToolTip(true);
    navigator.clipboard.writeText(workspaceAccessCredentials?.username_for_workspace || '');
    setTimeout(() => {
      setOpenWorkspaceUsernameToolTip(false);
    }, 3000);
  }
  const onPasswordCopyClick = () => {
    setOpenWorkspacePasswordToolTip(true);
    navigator.clipboard.writeText(workspaceAccessCredentials?.password_for_workspace || '');
    setTimeout(() => {
      setOpenWorkspacePasswordToolTip(false);
    }, 3000);
  }
  const onRequestTopUpConfirm = async () => {
    try {
      if (topupAmount > 0) {
        const body = {
          "amount": topupAmount,
          "entity_id": id,
          "invoice_type": "CREDIT",
          "entity_type": 'WORKSPACE_ACCOUNT',
          "comments": '',
          "billing_information": {
            "name": workspaceDetails?.billing_information?.name,
            "email": workspaceDetails?.billing_information?.email,
            "zip_code": workspaceDetails?.billing_information?.address?.zip_code,
            "street_address": workspaceDetails?.billing_information?.address?.street,
            "country": workspaceDetails?.billing_information?.address?.country,
            "state": workspaceDetails?.billing_information?.address?.state,
            "city": workspaceDetails?.billing_information?.address?.city,
          }
        }
        const res = await generateInvoiceDetails(body);
        if (res) {
          updateSnackBar('Top-up request is successful', 'success');
          setOpenToupModal(false);
          getCurrentWorkspaceDetails(id);
          setTopupAmount(workspaceDetails?.credit_amount);
          setAnchorElChangeWorkspaceCard(null);
        } else {
          updateSnackBar('Top-up request failed ', 'error')
        }
      }
    } catch (e) {
      console.log(e);
      updateSnackBar('Top-up request failed', 'error')
    }
  }
  const onRequestTopUpClick = () => {
    setAnchorElChangeWorkspaceCard(null);
    setOpenToupModal(true);
  }
  const closeTopupModal = () => {
    setOpenToupModal(false);
    setAnchorElChangeWorkspaceCard(null);
    setTopupAmount(workspaceDetails?.credit_amount)
  }
  const onEditIconClick = () => {
    setOpenEditWorkspaceNameModal(true);
  }
  const onWorkspaceNameChangeConfirm = async () => {
    try {
      if (!isEmpty(newWorkspaceName)) {
        setPopupButtonDisable(true)
        const body = {
          "workspace_name": newWorkspaceName
        }
        const res = await updateWorkspaceName(id, body)
        if (res) {
          setOpenEditWorkspaceNameModal(false);
          setPopupButtonDisable(false)
          setNewWorkspaceName('');
          updateSnackBar(res?.message, 'success');
          getCurrentWorkspaceDetails(id)
        }
      }
    } catch (e) {
      setPopupButtonDisable(false);
      updateSnackBar(e?.message || '', 'error');
      console.error('error in name change', e)
    }

  }
  const WORKSPACE_NAME_CHANGE_MODAL_PROPS = {
    modalTitle: "Edit Workspace Name",
    positiveButtonAction: onWorkspaceNameChangeConfirm,
    negativeButtonAction: () => setOpenEditWorkspaceNameModal(false),
    positiveButtonText: 'Save',
    negativeButtonText: "Cancel",
    popupButtonDisable: popupButtonDisable
  }

  const SHARE_WORKSPACE_MODAL_PROPS = {
    modalTitle: "Share Workspace",
    positiveButtonAction: onShareWorkspaceModal,
    negativeButtonAction: closeShareWorkspaceModal
  }
  const startStopWorkspaceModalContent = workspaceStatus === WORKSPACE_STATUS_STARTED ?
    'Stop Workspace' :
    'Are you sure you want to start this workspace?';
  const startStopWorkspaceButtonText = workspaceStatus === WORKSPACE_STATUS_STARTED ? 'Stop Workspace' : 'Start Workspace';

  const STOP_START_WORKSPACE_MODAL_PROPS = {
    modalTitle: startStopWorkspaceModalContent,
    positiveButtonText: startStopWorkspaceButtonText,
    negativeButtonText: "Cancel",
    imageSrc: CautionIcon,
    positiveButtonAction: onStartStopWorkspaceConfirm,
    negativeButtonAction: closeStartStopWorkspaceModal,
    popupButtonDisable: startStopWorkspaceModalContent === 'Stop Workspace' && !activeUsers ? true : false
  }
  const QUIT_WORKSPACE_MODAL_PROPS = {
    modalTitle: 'Are you sure you want to quit this workspace?',
    positiveButtonText: 'Quit Workspace',
    negativeButtonText: "Cancel",
    positiveButtonAction: onQuitWorkspaceConfirm,
    negativeButtonAction: closeQuitWorkspaceModal
  }
  const LAUNCH_WORKSPACE_MODAL_PROPS = {
    modalTitle: launchWorkspaceModalHeader,
    positiveButtonText: launchWorkspaceButtonText,
    negativeButtonText: "Cancel",
    popupButtonDisable: popupButtonDisable,
    loadStatus: loadStatus,
    positiveButtonAction: onLaunchWorkspaceConfirm,
    negativeButtonAction: closeLaunchWorkspaceModal
  }
  const LAUNCH_WORKSPACE_CAUTION_MODAL_PROPS = {
    modalTitle: 'Launch Workspace',
    positiveButtonText: 'Launch Workspace',
    negativeButtonText: "Cancel",
    popupButtonDisable: popupButtonDisable,
    loadStatus: isLoadingUsersList,
    positiveButtonAction: onLaunchWorkspaceCautionConfirm,
    negativeButtonAction: closeLaunchWorkspaceCautionModal,
    imageSrc: CautionIcon
  }
  const TOP_UP_REQUEST_MODAL = {
    modalTitle: 'BRAINCommons advance compute top-up request',
    positiveButtonText: 'Send Request',
    negativeButtonText: "Cancel",
    positiveButtonAction: onRequestTopUpConfirm,
    negativeButtonAction: closeTopupModal,
  }

  const borderClass = selectedCard === id ? Classes.activeCardClass : '';
  const isOwnedWorkspace = props?.eventData?.pi_owner || false;
  return (
    <React.Fragment>
      <Modal
        open={openEditWorkspaceNameModal}
        handleClose={() => setOpenEditWorkspaceNameModal(false)}
        dialogProps={WORKSPACE_NAME_CHANGE_MODAL_PROPS}
      >
        <label>New Workspace Name</label>
        <OutlinedInput
          value={newWorkspaceName}
          fullWidth
          id='cvb-adminDashboard-newWorkspaceName'
          name='newWorkspaceName'
          placeholder='Enter workspace name'
          onChange={(e) => setNewWorkspaceName(e.target.value)}
        />
      </Modal>
      <Modal
        open={openTopupModal}
        handleClose={closeTopupModal}
        dialogProps={TOP_UP_REQUEST_MODAL}
      >
        <p>
          I confirm that I would like to add
          <span className={Classes.topupAmountContainer}>
            <OutlinedInput
              type="number"
              value={topupAmount}
              fullWidth
              id='cvb-adminDashboard-costCapInput'
              name='costCap'
              placeholder='Enter amount'
              onChange={(e) => setTopupAmount(e.target.value)}
              inputProps={{ min: 1 }}
              startAdornment={(
                <InputAdornment position="start">
                  $
                </InputAdornment>
              )}
            />
          </span>
          to my workspace account {`${id}`}
        </p>
        <p>and I authorize BRAINCommons to invoice my organization for this amount. </p>
      </Modal>
      <Modal
        open={openShareWorkspaceModal}
        handleClose={closeShareWorkspaceModal}
        dialogProps={SHARE_WORKSPACE_MODAL_PROPS}
        disableBottomHorizontalDivider
      >
        <div className={Classes.infoContainer}>
          <div><img src={CautionIcon} /></div>
          <div className={Classes.text}>As an advanced compute user in the BRAINCommons with access to a workspace, you are responsible for all workspace activities. This workspace can only be shared with other BRAINCommons users with similar privileges and data use rights.</div>
        </div>
        <AddUsersToShare
          workspaceDetails={workspaceDetails}
          sharedUsersList={sharedUsersList}
          isWorkspaceShare
          id={get(props, 'eventData.workspace_id', '')}
          onAddUserClick={onAddUserClick}
          unshareForSelectedUser={unshareWorkspaceForSelectedUser}
          usersForShare={props.usersForShare}
          errorMessage={shareErrorMessage}
        />
      </Modal>
      <Modal
        open={openStartStopWorkspaceModal}
        handleClose={closeStartStopWorkspaceModal}
        dialogProps={STOP_START_WORKSPACE_MODAL_PROPS}
        enableImage={startStopWorkspaceModalContent === 'Stop Workspace' ? true : false}
        disableTitleDivider={startStopWorkspaceModalContent === 'Stop Workspace' ? false : true}
        smallerDialog={true}
      >
        {
          startStopWorkspaceModalContent === 'Stop Workspace' ?
            <div>
              {
                activeUsers?.length ?
                  <div>
                    <div className={Classes.activeUsersTitle}>Active Users</div>
                    <div className={Classes.activeUsersBox}>
                      {
                        map(activeUsers, (user, index) => {
                          const userSub = get(user, 'sub', '');
                          const profilePic = !isEmpty(activeUsersProfilePictures[userSub]) ? activeUsersProfilePictures[userSub] : DefaultProfileImage;
                          return (<div key={index} className={Classes.activeUsersSingleContainer}>
                            <div className={Classes.profileImageBox}>
                              <img className={Classes.userProfileImageBox} src={profilePic} />
                            </div>
                            <Tooltip title={`${user?.first_name} ${user?.last_name}`}>
                              <div className={Classes.activeUsersName}>{`${user?.first_name} ${user?.last_name}`}</div>
                            </Tooltip>
                            <div className={Classes.activeUsersLastActivity}>Last Activity: {user?.last_activity_formatted}</div>
                          </div>
                          )
                        })
                      }
                    </div>
                    <div className={Classes.clearContainer}></div>
                    <div className={Classes.activeUsersText}>
                      <p>If you want to check with these users before you stop your workspace, click "Cancel".</p>
                      <p>If you still want to proceed, click "Stop Workspace".</p>
                    </div>
                  </div>
                  : activeUsers?.length !== 0 ? <div className={Classes.loaderContainer}> <CustomLoader /> </div> :
                    <p className={Classes.noActiveUsersText}>Are you sure you want to stop this workspace?</p>
              }
            </div> : null
        }

      </Modal>
      <Modal
        open={openQuitWorkspaceModal}
        handleClose={closeQuitWorkspaceModal}
        dialogProps={QUIT_WORKSPACE_MODAL_PROPS}
        disableTitleDivider={true}
      >
      </Modal>
      <Modal
        open={openLaunchWorkspaceModal}
        handleClose={closeLaunchWorkspaceModal}
        dialogProps={LAUNCH_WORKSPACE_MODAL_PROPS}
        disableTitleDivider={true}
      >
        {launchWorkspaceModalSubHeader && <p>{launchWorkspaceModalSubHeader}</p>}
        <div className={`${Classes.workspaceAccessContainer} ${Classes.workspaceAccessContainerModal}`}>
          <p className={Classes.workspaceWindowsTitle}>Use the following credentials to login to your workspace</p>
          <ul>
            <li id='cvb-workspaceCards-share-button' className={Classes.linkIcons}>
              <span className={Classes.col1}>Username</span>
              <span className={Classes.col2}>{workspaceAccessCredentials?.username_for_workspace || ''}</span>
              <Tooltip title={openWorkspaceUsernameToolTip ? 'Username Copied' : 'Copy Username'}>
                <img src={LinkIcon} onClick={() => onUserNameCopyClick()} />
              </Tooltip>
            </li>
            <li id='cvb-workspaceCards-share-button' className={Classes.linkIcons}>
              <span className={Classes.col1}>Password</span>
              <span className={Classes.col2}>{workspaceAccessCredentials?.password_for_workspace || ''}</span>
              <Tooltip title={openWorkspacePasswordToolTip ? 'Password Copied' : 'Copy Password'}>
                <img src={LinkIcon} onClick={() => onPasswordCopyClick()} />
              </Tooltip>
            </li>
          </ul>
        </div>
      </Modal>
      <Modal
        open={openLaunchWorkspaceCautionModal}
        handleClose={closeLaunchWorkspaceCautionModal}
        dialogProps={LAUNCH_WORKSPACE_CAUTION_MODAL_PROPS}
        enableImage={true}
        disableTitleDivider={false}
        smallerDialog={true}
      >
        {
          activeUsers?.length &&
          <div>
            <div className={Classes.activeUsersTitle}>Active Users</div>
            <div className={Classes.activeUsersBox}>
              {
                map(activeUsers, (user, index) => {
                  const userSub = get(user, 'sub', '');
                  const profilePic = !isEmpty(activeUsersProfilePictures[userSub]) ? activeUsersProfilePictures[userSub] : DefaultProfileImage;
                  return (<div key={index} className={Classes.activeUsersSingleContainer}>
                    <div className={Classes.profileImageBox}>
                      <img className={Classes.userProfileImageBox} src={profilePic} />
                    </div>
                    <Tooltip title={`${user?.first_name} ${user?.last_name}`}>
                      <div className={Classes.activeUsersName}>{`${user?.first_name} ${user?.last_name}`}</div>
                    </Tooltip>
                    <div className={Classes.activeUsersLastActivity}>Last Activity: {user?.last_activity_formatted}</div>
                  </div>
                  )
                })
              }
            </div>
            <div className={Classes.clearContainer}></div>
            {
              `${workspaceDetails?.type || ''}`.toLowerCase() === 'windows' ?

                <div className={Classes.activeUsersText}>
                  <p>Launching the Workspace will cause this user to disconnect from their session and, potentially, lose any unsaved work.</p>
                  <p>You are strongly advised NOT to continue, unless you have already obtained the other user’s permission.</p>
                  <p>If you still want to proceed, click “Launch Workspace”.</p>
                </div> : <div className={Classes.activeUsersText}>
                  <p>You are connecting to a shared, mirrored session. You will be able to see what all other users see and you as well as everyone else will be able to jointly control the WorkSpace.</p>
                  <p>If you still want to proceed, click “Launch Workspace”.</p>
                </div>
            }

          </div>}
      </Modal>
      {!isLoading ? <Grid item lg={4} md={6} xs={12} className={Classes.cardContainerGrigItem}>
        <div className={Classes.cardContainerWrapper}>
          <div className={`${Classes.cardContainer} ${borderClass}`}>
            <div className={Classes.WorkspaceLogoContainer}>
              <img id='cvb-workspaceCards-logo' src={WorkspaceLogo} alt='Workspace logo' />
              <p id='cvb-workspaceCards-workspaceId' className={Classes.WorkspaceText}>
                {!isEmpty(get(props, 'workspaceDetails.workspace_name', '')) ? props.workspaceDetails.workspace_name : props.workspaceDetails.workspace_id}
                {
                  isOwnedWorkspace &&
                  <span className={Classes.editIconWrapper} onClick={onEditIconClick}>
                    <EditIcon fontSize='small' />
                  </span>
                }
              </p>
            </div>
            <Grid container xs={12} className={Classes.idSizeContainer}>
              <Grid item xs={6}>
                <div className={Classes.cardInfoContainer}>
                  <div className={Classes.VerticalLine}>
                  </div>
                  <p id='cvb-workspaceCards-id-label' className={Classes.titleText}>ID:</p>
                  <Tooltip id='cvb-workspaceCards-workspaceId-toolTip' title={get(props, 'eventData.workspace_id', '')} arrow>
                    <p id='cvb-workspaceCards-workspaceId' className={Classes.subTitleText} >
                      {get(props, 'eventData.workspace_id', '')}
                    </p>
                  </Tooltip>

                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={Classes.cardInfoContainer}>
                  <div className={Classes.VerticalLine}>
                  </div>
                  <p id='cvb-workspaceCards-sharedWith-label' className={Classes.titleText}>Shared with:</p>
                  <p id='cvb-workspaceCards-sharedWithUsersCount' className={`${Classes.subTitleText} ${Classes.sharedUsersText}`} >{sharedUsersList.length} Users
                    <span id='cvb-workspaceCards-view' className={Classes.viewText} onClick={(e) => onOpenPopover(e)} >View</span>
                    <Popover
                      id={'userEmail'}
                      open={openViewPopover}
                      anchorEl={anchorElUserData}
                      onClose={sharedUserClose}
                      anchorOrigin={{
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        horizontal: 'left',
                      }}
                    >
                      <ul className={Classes.dropdownListContainer}>
                        {!isEmpty(sharedUsersList) ? sharedUsersList.map((value, i) => {
                          return <li key={v4()}>{value.email_id}</li>
                        }) : null}
                      </ul>
                    </Popover>
                  </p>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={Classes.cardInfoContainer}>
                  <div className={Classes.VerticalLine}>
                  </div>
                  <p id='cvb-workspaceCards-type-label' className={Classes.titleText}>Type:</p>
                  <p id='cvb-workspaceCards-type-text' className={Classes.subTitleText} >{get(workspaceDetails, 'type', '') === "EC2" ? "LINUX" : get(workspaceDetails, 'type', '')}</p>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={Classes.cardInfoContainer}>
                  <p id='cvb-workspaceCards-type-label' className={Classes.titleText}>Status:</p>
                  <p id='cvb-workspaceCards-type-text' className={`${Classes.workspaceStatus} ${workspaceStatusBackground}`} >{workspaceStatus}</p>
                </div>
              </Grid>
            </Grid>
            <div className={Classes.horizontalLine}></div>
            <Grid item xs={12} className={Classes.launchWorkspaceContainer}>
              <img src={CartIcon} alt="Workspace Cart" />
              <sup className={`${Classes.workspaceFilesCount}`}>{filesCount}</sup>
              <div className={Classes.shareIconsWrapper}>
                <button id='cvb-workspaceCards-launchWorkspace-button' onClick={onLaunchWorkspaceClick} disabled={(workSpaceLaunchButtonDisable) ? "disabled" : ""} className={`${Classes.launchText} ${Classes.addPaddingRight} ${Classes.addBorderRight}`}>
                  Launch Workspace
                </button>
                <button className={`${Classes.launchText} ${Classes.addPaddingRight} ${Classes.addBorderRight}`} onClick={e => setAnchorElChangeWorkspaceCard(e.currentTarget)}>
                  <img src={DotIconDark} alt="more icon" />
                </button>
                <Popover
                  id={'WorkspaceCard-workspaceCred-popover'}
                  open={openWorkspaceChangePopover}
                  anchorEl={anchorElChangeWorkspaceCard}
                  onClose={() => setAnchorElChangeWorkspaceCard(false)}
                  anchorOrigin={{
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    horizontal: 'left',
                  }}
                >
                  <div className={Classes.workspaceAccessContainer}>
                    <ul className={Classes.workSpaceOptionsPopover}>
                      <li>
                        <div disabled={workspaceStatus === WORKSPACE_STATUS_LOCKED ? 'disabled' : ''} className={Classes.addPaddingMargin} >
                          <p onClick={(e) => onOpenWorkspaceCredPopover(e)}>View Credentials</p>
                          <Popover
                            id={'WorkspaceCard-workspaceCred-popover'}
                            open={openWorkspaceCredPopover}
                            anchorEl={anchorElWorkspaceCred}
                            onClose={handleCloseWorkspaceCredPopover}
                            anchorOrigin={{
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              horizontal: 'left',
                            }}
                          >
                            <div className={Classes.workspaceAccessContainer}>
                              <p>Use the following credentials to login to your Windows workspace</p>
                              <ul>
                                <li id='cvb-workspaceCards-share-button' className={Classes.linkIcons}>
                                  <span className={Classes.col1}>Username</span>
                                  <span className={Classes.col2}>{workspaceAccessCredentials?.username_for_workspace || ''}</span>
                                  <Tooltip title={openWorkspaceUsernameToolTip ? 'Username Copied' : 'Copy Username'}>
                                    <img src={LinkIcon} onClick={() => onUserNameCopyClick()} />
                                  </Tooltip>
                                </li>
                                <li id='cvb-workspaceCards-share-button' className={Classes.linkIcons}>
                                  <span className={Classes.col1}>Password</span>
                                  <span className={Classes.col2}>{workspaceAccessCredentials?.password_for_workspace || ''}</span>
                                  <Tooltip title={openWorkspacePasswordToolTip ? 'Password Copied' : 'Copy Password'}>
                                    <img src={LinkIcon} onClick={() => onPasswordCopyClick()} />
                                  </Tooltip>
                                </li>
                              </ul>
                            </div>
                          </Popover>
                        </div>
                      </li>
                      {isOwnedWorkspace && <li><div className={Classes.addPaddingMargin} >
                        <p id='cvb-workspaceCards-downArrow-button' onClick={onViewWorkSpaceTransactions}>View Billing & Transaction History</p>
                      </div>
                      </li>
                      }
                      <li>
                        {
                          starOrStopText === 'Start Workspace' ?

                            <div onClick={onStartStopWorkspaceClicked} disabled={(workSpaceButtonDisable) ? "disabled" : ""} className={Classes.addPaddingMargin}>
                              <p id='cvb-workspaceCards-workspaceCred-button' className={`${workSpaceButtonDisable ? Classes.disableImage : ""} ${Classes.keyIconStyle}`}>Start Workspace</p>
                            </div> : <div disabled={(workSpaceButtonDisable) ? "disabled" : ""} onClick={onStartStopWorkspaceClicked} className={Classes.addPaddingMargin}>
                              <p id='cvb-workspaceCards-workspaceCred-button' className={`${workSpaceButtonDisable ? Classes.disableImage : ""} ${Classes.keyIconStyle}`}>Stop Workspace</p>
                            </div>}
                      </li>
                      {
                        workspaceDetails ?
                          <li>
                            <div disabled={workspaceStatus === WORKSPACE_STATUS_LOCKED ? 'disabled' : ''} id='cvb-workspaceCards-viewFileDirectory-button' onClick={() => onViewFileDirectoryClick(id)} className={`${Classes.launchText} ${Classes.addPaddingMargin}`}>
                              <p id='cvb-workspaceCards-workspaceCred-button'>View File Directory</p>
                            </div>

                          </li> : null
                      }

                      {isOwnedWorkspace && <li><div className={Classes.addPaddingMargin} disabled={workspaceStatus === WORKSPACE_STATUS_LOCKED ? 'disabled' : ''} >
                        <p id='cvb-workspaceCards-downArrow-button' onClick={onShareWorkspaceSelect}>Share Workspace</p>
                      </div>
                      </li>
                      }
                      {isOwnedWorkspace && <li><div className={Classes.addPaddingMargin} >
                        <p id='cvb-workspaceCards-owner-button' onClick={navigateToOwnershipChange}>Change Ownership</p>
                      </div>
                      </li>
                      }
                      {isOwnedWorkspace && <li><div className={Classes.addPaddingMargin} >
                        <p id='cvb-workspaceCards-downArrow-button' onClick={onRequestTopUpClick}>Request Top-up</p>
                      </div>
                      </li>
                      }


                    </ul>
                  </div>
                </Popover>
              </div>
            </Grid>
          </div>

          <div className={`${Classes.workspaceStatusContainer} ${workspaceStatusBackground}`}>
            <p className={Classes.thisWorkspaceText}>This workspace is
              <span id='cvb-workspaceCards-workspaceStatus' className={Classes.workspaceStatus}>{workspaceStatus}</span>
            </p>
          </div>
        </div>
      </Grid>
        : <CustomLoader />
      }
    </React.Fragment>
  )
}

export default WorkspaceCard;