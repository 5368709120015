import React, { useState, useEffect } from 'react'
import BCLogo from '../../../assets/images/BRAINCommons-Platform.svg'
import SuccessTickDisabled from '../../../assets/images/successtick.png'
import SuccessTick from '../../../assets/images/successtickenabled.png'
import { navigate } from '@reach/router'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import Classes from './index.module.css'
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import PreviewOn from '../../../assets/images/PreviewOn.png';
import PreviewOff from '../../../assets/images/PreviewOff.png';
import QuoteImage from '../../../assets/images/quotes.svg'
import ShadowImage from '../../../assets/images/shadow.png'
import Errortext from '../../controls/error-text'
import { SIGNUP_ERROR_TEXTS, SIGNUP_PAGE_FIELDS } from '../../../constants/strings'
import {
    upperCaseCheckForPassword, specialCharCheckForPassword, numberCharCheckForPassword,
    lowerCharCheckForPassword, minLengthCheckForPassword
} from '../../../utils/input-validations'
import { VALID_EMAIL_REGEX } from '../../../constants/regex'
import { Auth } from 'aws-amplify';
import { CAROUSEL_DATA_SIGNUP } from '../../../constants/strings'
import Infotext from '../../controls/info-text'
import AliceCarousel from 'react-alice-carousel'
import map from 'lodash/map'
import { v4 } from 'uuid'
import get from 'lodash/get'
import { ORGANIZATION_LIST } from '../../../constants/strings'
import DropDown from '../../commons/dropdown';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import classes from './index.module.css'
import OutlookLoginIcon from '../../../assets/icons/OutlookIcon.svg'
import IncommonsLoginIcon from '../../../assets/icons/IncommonsIcon.svg'
import Tooltip from '@mui/material/Tooltip'
import GmailLoginIcon from '../../../assets/icons/gmailLoginIcon.svg'
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import InfoIconModal from '../../../assets/icons/InfoIconModal.svg'
import Modal from '../../commons/modal'
import { getCookie, setCookie, sendGAEvents, updateGAEventsPageTitleOnPageChange, signUpStatus } from '../../../api/generic-api'
import { getOrganizationList } from '../../../api/profile-api'
import find from 'lodash/find'
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#FCF8C8",
        color: "#222222",
        boxShadow: "-1px 1px 106px -61px rgba(74,73,74,1);",
        fontSize: 14,
        fontWeight: "medium",
        marginRight: '3px',
        fontFamily: "Raleway",
        borderRadius: 0,
        padding: 15,
        textAlign: "left"
    },
    arrow: {
        color: "#FCF8C8"
    },
    customWidth: {
        maxWidth: 200,
    },
}))(Tooltip);

const Register = props => {
    const { firstname, lastname, password, email, orgName, confirmPass } = props;

    const [passStrengthCheck, setPassStrengthCheck] = useState({});
    const [disableNextButton, setDisableNextButton] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const [authError, setAuthError] = useState("")
    const [loginLoader, setLoginLoader] = useState(false)
    const [otherOrganizationName, setOtherOrganizationName] = useState("")
    const [ openSocialMediaPopup, setOpenSocialMediaPopup ] = useState(false);
    const [ loginType, setLoginType] = useState('');
    const [signUpAllowed, setSignUpAllowed] = useState(props?.location?.state?.signUpAllowed || '');
    const [allOrganization, setAllOrganization] = useState([]);
    const [organizationsList, setOrganizationsList] = useState([]);

    useEffect(() => {
        checkForPassStrength();
    }, [password])

    useEffect(() => {
        updateGAEventsPageTitleOnPageChange('register');
    }, [])

    useEffect(() => {
        if(isEmpty(signUpAllowed)){
            fetchSignUpStatus();
        }
    },[signUpAllowed])

    const fetchSignUpStatus = async () => {
        try {
            const details = await signUpStatus();
            if(!details?.sign_up_allowed){
                navigate('login')
            }
        }catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getOrganizations();
    }, []);

    const getOrganizations = async () => {
        try {
            const res = await getOrganizationList();
            const orgResList = res?.organizations || [];
            setOrganizationsList(orgResList);
            if(orgResList.length > 0){
                let orgList = map(orgResList, (org)=> org?.name);
                orgList = orgList.sort();
                setAllOrganization(orgList);
            }else{
                setAllOrganization([]);
            }
            
        } catch (error) {
            setAllOrganization([]);
            console.log('error in getting organization list')
        }
    }
    
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };



    const checkForPassStrength = () => {
        let passStrengths = cloneDeep(passStrengthCheck);
        let isUpperCaseCharPresent = false;
        let isSpecialCharPresent = false;
        let isNumberPresent = false;
        let isLowerCaseCharPresent = false;
        let minEightCharPresent = false;
        if (!isEmpty(password['text'])) {
            isUpperCaseCharPresent = upperCaseCheckForPassword(password['text']);
            isSpecialCharPresent = specialCharCheckForPassword(password['text']);
            isNumberPresent = numberCharCheckForPassword(password['text']);
            isLowerCaseCharPresent = lowerCharCheckForPassword(password['text']);
            minEightCharPresent = minLengthCheckForPassword(password['text']);
        }
        passStrengths = {
            upperCaseChar: isUpperCaseCharPresent,
            specialChar: isSpecialCharPresent,
            numberChar: isNumberPresent,
            lowerCaseChar: isLowerCaseCharPresent,
            minChar: minEightCharPresent
        }

        const disNextButton = isUpperCaseCharPresent && isSpecialCharPresent && isNumberPresent
            && isLowerCaseCharPresent && minEightCharPresent;
        setDisableNextButton(disNextButton);
        setPassStrengthCheck(passStrengths);
        return !disNextButton;
    }

    const onFirstNameChange = (e) => {
        props.onFirstNameChange({ ...firstname, text: e.target.value, isSubmit: false })
    }
    const onLastNameChange = (e) => {
        props.onLastNameChange({ ...lastname, text: e.target.value, isSubmit: false })
    }
    const onPasswordChange = (e) => {
        setAuthError("")
        props.onPasswordChange({ ...password, text: e.target.value, isSubmit: false })
    }
    const onEmailChange = (e) => {
        setAuthError("")
        props.onEmailChange({ ...email, text: e.target.value, isSubmit: false })
    }
    const onOrgNameChange = (value) => {
        props.onOrgNameChange(value);
    }
    const onConfirmPassChange = (e) => {
        setAuthError("")
        props.onConfirmPassChange({ ...confirmPass, text: e.target.value, isSubmit: false });
    }
    const validateFields = (field, isValidPass) => {
        const { FIRSTNAME, LASTNAME, EMAIL, ORGNAME, CONFPASS, PASSWORD } = SIGNUP_PAGE_FIELDS;
        switch (field) {
            case FIRSTNAME: {
                isEmpty(firstname['text']) ?
                    props.onFirstNameChange({ ...firstname, isSubmit: true, isValid: false })
                    : props.onFirstNameChange({ ...firstname, isSubmit: true, isValid: true });
                return !isEmpty(firstname['text']);
            }
            case LASTNAME: {
                isEmpty(lastname['text']) ?
                    props.onLastNameChange({ ...lastname, isSubmit: true, isValid: false })
                    : props.onLastNameChange({ ...lastname, isSubmit: true, isValid: true });
                return !isEmpty(lastname['text']);
            }
            case EMAIL: {
                let re = VALID_EMAIL_REGEX;
                !re.test(email['text']) ?
                    props.onEmailChange({ ...email, isSubmit: true, isValid: false })
                    : props.onEmailChange({ ...email, isSubmit: true, isValid: true });
                return re.test(email['text']);
            }
            case PASSWORD: {
                isEmpty(password['text']) || !isValidPass ? (
                    props.onPasswordChange({ ...password, isSubmit: true, isValid: false }))
                    : props.onPasswordChange({ ...password, isSubmit: true, isValid: true });
                return !isEmpty(password['text']);
            }
            case CONFPASS: {
                !(password['text'] === confirmPass['text']) || isEmpty(confirmPass['text']) ?
                    props.onConfirmPassChange({ ...confirmPass, isSubmit: true, isValid: false })
                    : props.onConfirmPassChange({ ...confirmPass, isSubmit: true, isValid: true });
                return password['text'] === confirmPass['text'];
            }
            default: break;
        }
    }
    const onNextButtonPress = async () => {
        try {
            props.onLoginButtonClick();
            if(!props.showCookieBanner){
                setLoginLoader(true); 
                const { FIRSTNAME, LASTNAME, EMAIL, ORGNAME, CONFPASS, PASSWORD } = SIGNUP_PAGE_FIELDS;
                const isValidFirstName = await validateFields(FIRSTNAME);
                const isValidLastName = await validateFields(LASTNAME);
                const isValidEmail = await validateFields(EMAIL);
                const isValidConfirmPass = await validateFields(CONFPASS);
                let isValidPass = false;
                for (let key in passStrengthCheck) {
                    if (passStrengthCheck[key]) {
                        isValidPass = true;
                    } else {
                        isValidPass = false;
                        break;
                    }
                }
                const isValidPassword = await validateFields(PASSWORD, isValidPass);

                if (orgName === "Other" && !otherOrganizationName) {
                    setLoginLoader(false);
                    return;
                }
                const isAllFieldsValid = isValidFirstName && isValidLastName && isValidEmail
                    && isValidConfirmPass && isValidPassword && isValidPass && orgName;

                if (isAllFieldsValid) {
                    await handleCognitoSignUp();
                }
                setLoginLoader(false);
            }
        } catch (error) {
            console.log('error in validation');
            setLoginLoader(false);
        }
    }

    const handleCognitoSignUp = async () => {
        try {
            sendGAEvents('signup', 'account_creation', 'sign up to braincommons portal', {method: 'cognito', page_title: 'register'});
            const orgId = find(organizationsList,(org) => org.name === orgName)?.id || '';
            const otherOrganizationValue = `${orgName}`.toLowerCase() == "other" ? otherOrganizationName : '' ;
            await Auth.signUp({
                username: email['text'],
                password: password['text'],
                attributes: {
                    'given_name': firstname['text'],
                    'family_name': lastname['text'],
                    email: email['text'],
                     'custom:organization_id':`${orgId}`,
                     'custom:other_organization': otherOrganizationValue
                }
            });
            navigate("/confirm-signup?email=" + email['text'], { state: { userEmailId: email['text'] } })
        }
        catch (error) {
            setAuthError(error.message)
        }
    }

    const handleGoogleFederatedSignIn = async () => {
        try {
            props.onLoginButtonClick();
            if(!props.showCookieBanner){
            sendGAEvents('signup', 'account_creation', 'sign up to braincommons portal', {method: 'google', page_title: 'register'});
            const response = await Auth.federatedSignIn({ provider: 'Google' });
            }
        }
        catch (error) {
            let errorMessage = (error?.message || error?.error_description)|| "Something went wrong, Please try again.";
            if(`${errorMessage}`.includes('PreSignUp failed with error')){
                errorMessage = errorMessage.split('PreSignUp failed with error')[1];
            }
            setAuthError(errorMessage)
        }
    }

    const CarouselContent = props => {
        return (
            <React.Fragment>
                <Grid item xs={12} className={Classes.carouselInfoContainer}>
                    <img src={QuoteImage} alt='quote image' className={Classes.quoteImage} />
                    <p id='cvb-register-authorQuote' className={Classes.authorQuoteText}>{get(props, 'authorQuoteText', '')}</p>
                    <div className={Classes.authorContainer}>
                        <p id='cvb-register-authorName' className={Classes.authorName}>{get(props, 'authorName', '')} <span>{get(props, 'authorQualification', '')}</span></p>
                        <p id='cvb-register-authorDesignation' className={Classes.authorDesc}>{get(props, 'authorDesig', '')} </p>
                        <p id='cvb-register-authorDesc' className={Classes.authorDesc}>{get(props, 'authorDesc', '')} </p>
                    </div>
                </Grid>
            </React.Fragment>
        )
    }
    const handleOffice365FederatedSignIn = async () => {
        try {
            props.onLoginButtonClick();
            if(!props.showCookieBanner){
                sendGAEvents('signup', 'account_creation', 'sign up to braincommons portal', {method: 'office365', page_title: 'register'});
                const response = await Auth.federatedSignIn({ customProvider: 'MicrosoftAzure' });
            }
        }
        catch (error) {
            setAuthError(error?.message || "Something went wrong, Please try again.")
        }
    }

    const handleInCommonsSignIn = async () => {
        try {
            props.onLoginButtonClick();
            if(!props.showCookieBanner){
                sendGAEvents('signup', 'account_creation', 'sign up to braincommons portal', {method: 'incommons', page_title: 'register'});
                const response = await Auth.federatedSignIn({ customProvider: 'InCommons' });
            }
        }
        catch (error) {
            setAuthError(error?.message || "Something went wrong, Please try again.")
        }
    }
    const renderLeftContainerCarousel = () => {

        const settings = {
            dots: true,
            autoplaySpeed: 6000,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            infinite: true,
            variableWidth: false,
            arrows: false
        };

        return (
            <Grid container direction='row' spacing={0} className='carousel-container'>
                <Grid item xs={12} className={Classes.carouselContainer}>
                    <Slider {...settings}>
                        {map(CAROUSEL_DATA_SIGNUP, (eventData) => {
                            return <CarouselContent key={v4()} authorQuoteText={eventData['authorQuoteText']}
                                authorName={eventData['authorName']}
                                authorQualification={eventData['authorQualification']}
                                authorDesig={eventData['authorDesig']}
                                authorDesc={eventData['authorDesc']}
                            />
                        })}
                    </Slider>
                </Grid>
            </Grid>
        )
    }
    const checkIfSocialCookieSet = () =>{
        let isSocialSignin = getCookie("cvb_social_signin");
        return isSocialSignin != "";
        //return true;
    }
    const onSocialLoginConfirm = (type) => {
        setOpenSocialMediaPopup(false);
        const loginTypeValue = checkIfSocialCookieSet() ? type : loginType;
        setCookie("cvb_social_signin", true, 365);
        switch(loginTypeValue){
            case "gmail": {handleGoogleFederatedSignIn(); break};
            case 'office365': handleOffice365FederatedSignIn(); break;
            case 'incommons': handleInCommonsSignIn(); break;
            default : break;
        }
    }
    const onSocialMediaButtonClick = (type) =>{
        props.onLoginButtonClick();
        if(!props.showCookieBanner){
            setLoginType(type);
            if (checkIfSocialCookieSet()) {
                onSocialLoginConfirm(type);
            } else {
                setOpenSocialMediaPopup(true);
            }
        }
    }
    const signDataUsageModalPayload = {
        modalTitle: "",
        modalContent: '',
        negativeButtonText: "Cancel",
        positiveButtonText: "Confirm Sign-in",
        negativeButtonAction: () => { setOpenSocialMediaPopup(false)},
        positiveButtonAction: () => {onSocialLoginConfirm() }
    }
    return (
        <Grid container component='main' className={Classes.signupMainContainer}>
              {
                <Modal className={classes.signInPolicyModal} open={openSocialMediaPopup} handleClose={() => setOpenSocialMediaPopup(false)} disableTitleDivider dialogProps={signDataUsageModalPayload} >
                <div className={classes.signInPolicy}>
                    <Grid container>
                            <img className={classes.warningImage} src={InfoIconModal} alt="Info Icon" />
                        <Grid item xs={12} className={classes.signInPolicyContent}>
                            <h3 className={classes.smCookieTitle}>Social Media Cookie</h3>
                            <p>
                                No social media cookies are used on the BRAINCommons Platform. As a registered user, you’ll have a
                                customizable profile page where you can include your social media links to other sites such as LinkedIn, Google Scholar
                                and Twitter. The BRAINCommons Platform is not responsible for the processing of any personal data you may wish to
                                share with these sites. We strongly recommend that you carefully read the privacy notice and terms and conditions before
                                activating these social media links on your profile page.
                            </p>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
            }
            <Grid item xs={6} className={Classes.signupLeftContentContainer}>
                {renderLeftContainerCarousel()}
            </Grid>
            <Grid item xs={6} className={Classes.rightContainer} >
                <Grid container direction='column' alignItems='center' className={Classes.signupRightFormContainer} >
                    <div className={Classes.bcLogoContainer}>
                        <img id='cvb-register-bcLogo' alt='braincommons logo' className={Classes.bcLogo} src={BCLogo} />
                        <img alt='shadow' className={Classes.shadowImage} src={ShadowImage} />
                    </div>
                    <Grid container item xs={8} direction='row' alignItems='center' justifyContent='center'
                        className={Classes.inputsContainerDiv}>
                        <p id='cvb-register-createAnAccountText' className={Classes.createAccountTitleText}>Create an account to get started</p>
                        {
                            authError &&
                            <Infotext errorMessage={authError} type="Error" />
                        }
                        <Grid container spacing={4} justifyContent='space-between' className={Classes.signupFormInputsRow}>
                            <Grid item xs={6}>
                                <label htmlFor="firstname"> First Name<sup> *</sup></label>
                                <OutlinedInput
                                    error={(firstname['isSubmit'] && !firstname['isValid'])}
                                    fullWidth value={firstname['text']}
                                    onChange={onFirstNameChange}
                                    placeholder='Enter first name'
                                    name='firstname' 
                                    id='cvb-register-firstNameInput'
                                    />
                                {(firstname['isSubmit'] && !firstname['isValid']) &&
                                    <Errortext errorMessage={SIGNUP_ERROR_TEXTS.FIRSTNAME} />}
                            </Grid>
                            <Grid item xs={6}>
                                <label htmlFor="firstname"> Last Name <sup> *</sup></label>
                                <OutlinedInput
                                    error={(lastname['isSubmit'] && !lastname['isValid'])}
                                    fullWidth
                                    value={lastname['text']}
                                    onChange={onLastNameChange}
                                    placeholder='Enter last name'
                                    name='lastname' 
                                    id='cvb-register-lastNameInput'
                                    />
                                {(lastname['isSubmit'] && !lastname['isValid']) &&
                                    <Errortext errorMessage={SIGNUP_ERROR_TEXTS.LASTNAME} />}
                            </Grid>
                        </Grid>
                        <Grid container spacing={4} justifyContent='space-between' className={Classes.signupFormInputsRow}>
                            <Grid item xs={6}>
                                <label htmlFor="email"> Email <sup> *</sup></label>
                                <OutlinedInput
                                    error={(email['isSubmit'] && !email['isValid'])}
                                    fullWidth type='email'
                                    value={email['text']}
                                    onChange={onEmailChange}
                                    placeholder='Enter email'
                                    name='email' 
                                    id='cvb-register-emailInput'
                                    />
                                {(email['isSubmit'] && !email['isValid']) &&
                                    <Errortext errorMessage={SIGNUP_ERROR_TEXTS.EMAIL} />}
                            </Grid>
                            <Grid item xs={6}>
                                <label htmlFor="orgName"> Organization Name<sup> *</sup></label>
                                <DropDown
                                    values={allOrganization}
                                    handleFilterStateChange={onOrgNameChange}
                                    selectedFilter={orgName}
                                    placeholder={'Select your organization'} 
                                    id='cvb-register-orgNameInput'
                                    />
                                {(email['isSubmit'] && !orgName) &&
                                    <Errortext errorMessage={SIGNUP_ERROR_TEXTS.ORGNAME} />}
                            </Grid>
                        </Grid>
                        {
                            orgName === "Other" &&
                            <Grid container spacing={4} justifyContent='space-between' className={Classes.signupFormInputsRow}>
                                <Grid item xs={6}>
                                    &nbsp;
                                </Grid>
                                <Grid item xs={6}>
                                    <label htmlFor="orgName"> Organization Name<sup> *</sup></label>
                                    <OutlinedInput
                                        fullWidth
                                        value={otherOrganizationName}
                                        onChange={e => setOtherOrganizationName(e.target.value)}
                                        placeholder='Enter other organization name'
                                        className='signup-inputs-large'
                                        id='cvb-register-otherOrgName'
                                    />
                                    {(password['isSubmit'] && orgName === "Other" && !otherOrganizationName &&
                                        <Errortext errorMessage={"Enter a valid organization name"} />)}
                                </Grid>
                            </Grid>
                        }
                        <Grid container spacing={4} justifyContent='space-between' className={Classes.signupFormInputsRow}>
                            <Grid item xs={12}>
                                <label htmlFor="password"> Password<sup> *</sup></label>
                                <OutlinedInput
                                    fullWidth
                                    type={showPassword ? 'text' : 'password'}
                                    value={password['text']}
                                    onChange={onPasswordChange}
                                    placeholder='Enter password'
                                    className='signup-inputs-large'
                                    name='password'
                                    id='cvb-register-passwordInput'
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                size="large">
                                                {showPassword ? <img id='cvb-register-passwordVisibilityOn' alt='visibility On' src={PreviewOn} /> :
                                                    <img id='cvb-register-passwordVisibilityOff' alt='visibility Off' src={PreviewOff} />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {(password['isSubmit'] && !password['isValid'] &&
                                    <Errortext errorMessage={SIGNUP_ERROR_TEXTS.ENTER_PASS} />)}
                            </Grid>
                        </Grid>
                        {!disableNextButton && <Grid container spacing={1} item xs={12} className={Classes.passChecksDiv}>
                            <Grid item xs={5} className={Classes.passStrengthCheck}>
                                {passStrengthCheck['upperCaseChar'] ? <img id='cvb-register-upperCaseCheck-success' src={SuccessTick} alt='success mark' /> :
                                    <img id='cvb-register-upperCaseCheck-fail' src={SuccessTickDisabled} alt='failure mark' />}
                                <p id='cvb-register-upperCaseCheck-text' className={Classes.passStrenghtOptText}>One upper case character</p>
                            </Grid>
                            <Grid item xs={4} className={Classes.passStrengthCheck}>
                                {passStrengthCheck['specialChar'] ? <img id='cvb-register-oneSpecialCharCheck-success' src={SuccessTick} alt='success mark' /> :
                                    <img id='cvb-register-oneSpecialCharCheck-fail' src={SuccessTickDisabled} alt='failure mark' />}
                                <p id='cvb-register-oneSpecialCharCheck-text' className={Classes.passStrenghtOptText}>One special character</p>
                            </Grid>
                            <Grid item xs={3} className={Classes.passStrengthCheck}>
                                {passStrengthCheck['numberChar'] ? <img id='cvb-register-oneNumberCheck-success' src={SuccessTick} alt='success mark' /> :
                                    <img id='cvb-register-oneNumberCheck-fail' src={SuccessTickDisabled} alt='failure mark' />}
                                <p id='cvb-register-oneNumberCheck-text' className={Classes.passStrenghtOptText}>One number</p>
                            </Grid>
                            <Grid item xs={5} className={Classes.passStrengthCheck}>
                                {passStrengthCheck['lowerCaseChar'] ? <img id='cvb-register-oneLowerCharCheck-success' src={SuccessTick} alt='success mark' /> :
                                    <img id='cvb-register-oneLowerCharCheck-fail' src={SuccessTickDisabled} alt='failure mark' />}
                                <p id='cvb-register-oneLowerCharCheck-text' className={Classes.passStrenghtOptText}>One lower case character</p>
                            </Grid>
                            <Grid item xs={5} className={Classes.passStrengthCheck}>
                                {passStrengthCheck['minChar'] ? <img id='cvb-register-min8Char-success' src={SuccessTick} alt='success mark' /> :
                                    <img id='cvb-register-min8Char-success' src={SuccessTickDisabled} alt='failure mark' />}
                                <p id='cvb-register-min8Char-text' className={Classes.passStrenghtOptText}>Minimum 8 characters</p>
                            </Grid>
                        </Grid>
                        }
                        <Grid container spacing={4} justifyContent='space-between' className={Classes.signupFormInputsRow}>
                            <Grid item xs={12}>
                                <label id='cvb-register-confirmPass-label' htmlFor="passwordRetype">Confirm Password<sup> *</sup></label>
                                <OutlinedInput
                                    error={(confirmPass['isSubmit'] && !confirmPass['isValid'])}
                                    fullWidth
                                    type={showConfirmPass ? 'text' : 'password'}
                                    value={confirmPass['text']}
                                    onChange={onConfirmPassChange}
                                    placeholder='Re-type password'
                                    className='signup-inputs-large'
                                    name='passwordRetype'
                                    id='cvb-register-confirmPass-input'
                                />
                                {(confirmPass['isSubmit'] && !confirmPass['isValid']) &&
                                    <Errortext errorMessage={SIGNUP_ERROR_TEXTS.PASS} />}
                            </Grid>
                        </Grid>
                        {disableNextButton && !isEmpty(password['text']) && (confirmPass['text'] === password['text']) ?
                            <Grid item xs={12} className={Classes.passSuccessContainer}>
                                <Grid direction='row' alignItems='center' container
                                    className={Classes.passStrengthCheckSuccessCont}>
                                    <img id='cvb-register-passStrenghtCheck-success' src={SuccessTick} alt='success mark' />
                                    <p id='cvb-register-passStrenghtCheck-text' className={Classes.passStrengthCheckSuccess}>
                                        Your password is secure and you are ready to proceed.</p>
                                </Grid>
                            </Grid> : ''
                        }
                        <button
                            id='cvb-register-nextButton'
                            onClick={onNextButtonPress}
                            disabled={loginLoader}
                            className={`solid-button ${Classes.nextButton}`}>Submit</button>
                        <Grid item xs={12}>
                            <Grid container alignItems='center' direction='column' >
                                <Grid item xs={12}>
                                    <p className={Classes.alreadyHaveAccountText}>Already have an account?
                                    <span id='cvb-register-signInButton' className={Classes.signInText} onClick={() => navigate('/login')}>Sign in</span></p>
                                </Grid>

                                <Grid item className={Classes.signInWithContainer}>
                                    <span className={Classes.horLine}></span>
                                    <p className={Classes.signInWithText}>Or Sign in with</p>
                                    <span className={Classes.horLine}></span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div className={Classes.signinMethodsContainer}>
                            <LightTooltip placement="right" className={Classes.userNameTitle} arrow title="Your personal email account">
                                <button className={`contained-button ${Classes.gmailButton}`}
                                    id='cvb-register-gmailLogin-button'
                                    onClick={()=>onSocialMediaButtonClick('gmail')}><img src={GmailLoginIcon} alt="Gmail Icon" /> Gmail</button>
                            </LightTooltip>
                        </div>
                        <Grid item xs={12} className={Classes.signInWithContainerResearcher}>
                            <span className={Classes.horLineResearcher}></span>
                            <p className={Classes.signInWithTextResearcher}>Qualified Researchers must sign in with either</p>
                            <span className={Classes.horLineResearcher}></span>
                        </Grid>
                        <div className={Classes.signinMethodsContainer}>
                            {/* <LightTooltip  placement="bottom" className={Classes.userNameTitle} arrow title="Your professional email account associated with your industry.">
                                
                            </LightTooltip> */}
                                <button disabled={true} className={`contained-button ${Classes.officeButton}`}
                                    id='cvb-register-outlookLogin-button'
                                    onClick={()=>onSocialMediaButtonClick('office365')}><img src={OutlookLoginIcon} alt="office Icon" /> Office 365</button>

                            <LightTooltip placement="bottom" className={Classes.userNameTitle} arrow title="Your professional email account associated with your academic institution.">
                                <button className={`contained-button ${Classes.incommonsButton}`}
                                    id='cvb-register-inCommonsLogin-button'
                                    onClick={()=>onSocialMediaButtonClick('incommons')}><img src={IncommonsLoginIcon} alt="Incommons Icon" /> In Commons</button>
                            </LightTooltip>

                        </div>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    );
}

export default Register
