import React from 'react';
import Modal from '../../commons/modal'
import Classes from './index.module.css';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'
import moment from 'moment';
import OutlinedInput from '@mui/material/OutlinedInput'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Checkbox from '@mui/material/Checkbox';

const OwnershipTransferRequestDocumentModel = ({
  showOwnershipTransferRequestDocument,
  setShowOwnershipTransferRequestDocument
}) => {
  const owner = showOwnershipTransferRequestDocument?.workspace_owner || {}
  const intitatedBy = showOwnershipTransferRequestDocument?.initiated_by || {}

  return <Modal
    open={!!showOwnershipTransferRequestDocument}
    handleClose={() => setShowOwnershipTransferRequestDocument(null)}
    dialogProps={{
      modalTitle: 'Workspace Ownership Transfer'
    }}
    disableTitleDivider={true}
    disableBottomHorizontalDivider
  >

    <div className={Classes.agreementContainer} id='cvb-dataContributorAgreement-stageOneFormContainer'>
      <div className={Classes.titleHeaderContainer}>
        <h1>Request for Workspace Ownership Change</h1>
      </div>
      <div className={Classes.topFormSection}>
        <div className={Classes.formField}>
          <label className={Classes.signatoryLabel}>Requestor Name</label>
          <OutlinedInput
            value={intitatedBy?.first_name  + ' ' + intitatedBy?.last_name || ''}
            disabled
            fullWidth
            id='cvb-dataContributorAgreement-contributorSigningName'
          />
        </div>
        <div className={Classes.formField}>
          <label className={Classes.signatoryLabel}>Requestor Email</label>
          <OutlinedInput
            value={intitatedBy?.email || ''}
            disabled
            fullWidth
            id='cvb-dataContributorAgreement-contributorSigningName'
          />
        </div>
        <div className={Classes.formDescription}>
          {
              intitatedBy?.username !== owner?.username ?
              <><p>a) As a BC Manager - Master I wish to transfer ownership of the Workspace(s) listed below. I understand that if I transfer ownership of the Workspace to a User with a different level of access all users whom the WorkSpace is currently shared with will lose access to it and the new owner will decide who they would like to share it with. </p>
              <p>b) The new owner will ensure that personal or otherwise confidential files that must not be stored on the Workspace are deleted from the Workspace as soon as possible after the Workspace has been transferred to them. BRAINCommons cannot be held responsible for any accidental disclosure of confidential information that results from the new owner’s omission to delete them.</p>
              <p> I would like the Workspace's ownership to be transferred to:</p></> : <> <p>a) I no longer wish to be the owner of this Workspace. I understand that if I transfer ownership of the Workspace to a User with a different level of access all users whom the Workspace is currently shared with will lose access to it and the new owner will decide who they would like to share it with. </p>
              <p>b) I confirm that all personal or otherwise confidential files that must not be shared with the new owner have been deleted from the Workspace and that BRAINCommons cannot be held responsible for any accidental disclosure of confidential information that results from my omission to delete them.</p>
              <p> I would like the ownerships of the following Workspaces to be transferred:</p></>
          }
        </div>
        <Grid container>
          <Grid item xs={7} md={7} lg={7}>
            <div className={Classes.workspaceAllocationTable}>
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell id='cvb-myCohorts-tableHeaderSearchName' className={Classes.typeColumn} width="25%">Workspace</TableCell>
                      <TableCell id='cvb-myCohorts-tableHeaderSavedOn' className={Classes.searchColumn} width="35%" align="left">Workspace owner</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableCell id='cvb-myCohorts-tableHeaderSearchName' className={Classes.typeColumn} >{showOwnershipTransferRequestDocument?.workspace_id}</TableCell>
                    <TableCell id='cvb-myCohorts-tableHeaderSavedOn' className={Classes.searchColumn} align="left">
                      <OutlinedInput
                        value={showOwnershipTransferRequestDocument?.workspace_recipient?.email || ''}
                        disabled
                        fullWidth
                        id='cvb-dataContributorAgreement-contributorSigningName'
                      />
                    </TableCell>

                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={Classes.agreementFormMainContainer} id='cvb-dataContributorAgreement-stageOneFormAgreement'>
        <div className={Classes.signFormInputContainer} id='cvb-dataContributorAgreement-stageOneAgreementContainer'>
          <div className={Classes.signatureContainer}>
            <p id='cvb-dataContributorAgreement-stageOneSignAgreementTitle'>
              <label>Sign Agreement<sup> *</sup></label>
            </p>
            <p id='cvb-dataContributorAgreement-stageOneSignAgreementDescription'>Sign your name on the trackpad and press any key when finished</p>
            <div id='signature-pad' className={Classes.signaturePad}>
              <p id='cvb-dataContributorAgreement-stageOneSignAgreementClickText' className={Classes.clickHeretoBegin}>Click here to begin</p>
              <img src={showOwnershipTransferRequestDocument?.signature} alt="Signature" />

            </div>
          </div>
        </div>
        <Grid item>
              <div className={Classes.agreeContainer}>
                <span className={Classes.agreeCheckbox}>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    disabled={true} checked={true} />
                </span>
                <span className={Classes.agreeContent}>
                   I confirm that by signing this Agreement I am legally bound by its terms and conditions
                </span>
              </div>
            </Grid>
        <div className={Classes.signFormInputContainer}>
          <label className={Classes.signatoryLabel}>Name :</label>
          <OutlinedInput
            value={intitatedBy?.first_name  + ' ' + intitatedBy?.last_name || ''}
            disabled
            fullWidth
            id='cvb-dataContributorAgreement-contributorSigningName'
          />
        </div>
        <div className={Classes.signFormInputContainer}>
          <label className={Classes.signatoryLabel}>Date :</label>
          <OutlinedInput
            value={moment(showOwnershipTransferRequestDocument?.submitted_at).format('MM/DD/YYYY') 
          }
            disabled
            fullWidth
            id='cvb-dataContributorAgreement-contributorSigningTitle'
          />
        </div>
      </div>

    </div>

  </Modal>
}
export default OwnershipTransferRequestDocumentModel;