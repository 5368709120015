import {env} from '../../../env'
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MuiOutlinedInput from '@mui/material/OutlinedInput';
import withStyles from '@mui/styles/withStyles';
import { navigate } from '@reach/router';
import { Auth } from 'aws-amplify';
import filter from 'lodash/filter';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar-edit';
import { connect } from 'react-redux';
import { getProfilePicture, getUserPermissions, getUserProfile, updateUserProfile, uploadProfilePicture, getOrganizationList, getDesignationList } from '../../../api/profile-api';
import githubImg from '../../../assets/icons/github-color.svg';
import googleImg from '../../../assets/icons/google-color.svg';
import id from '../../../assets/icons/id-color.svg';
import internet from '../../../assets/icons/internet-color.svg';
import linkedIn from '../../../assets/icons/linkedin_ob.svg';
import twitterImg from '../../../assets/icons/twitter_ob.svg';
import DefaultProfileImage from '../../../assets/images/user/default-profile-image.png';
import { COUNTRY_LIST, EDUCATION_OPTIONS, GITHUB_LINK, GOOGLE_SCHOLAR_LINK, LINKEDIN_LINK, ORCID_LINK, SECTORS_LIST, TWITTER_LINK, USER_ONBOARD_FORM_ERROR_TEXT, USER_ONBOARD_FORM_REQ_FIELDS } from '../../../constants/strings';
import { setEditContactNumber, setTabsValueInEditProfile, setEditStreetAddress, setEditZipCode } from '../../../redux/actions/editProfileActions';
import { updateProfilePicture, updatePullCount, updateSnackBar } from '../../../redux/actions/globalActions';
import { updateUserDetails } from '../../../redux/actions/userActions';
import { changeStepperCount, clearOnboardForm, setKeywordList, setOnboardingCity, setOnboardingCountry, 
    setOnboardingDegree, setOnboardingFirstName, setOnboardingInstitutionName, setOnboardingLastName, 
    setOnboardingStateName, setOnboardingTitle, setOnboardSector, setOnDepartmentChange, setProfileImageOfUser, 
    setResearcherGoals, setTagsInputText, setUsersBio, setUsersDesignation, setUsersGithubProfile, 
    setUsersGoogleProfile, setUsersLinkedinProfile, setUsersOrchidProfile, setUsersOrganizationName, 
    setUsersOtherDesignation, setUsersPersonalProfile, setUsersTwitterProfile, setOnboardingEducation, setScientificBackground, 
    setOtherHighestEducation
} from '../../../redux/actions/userOnboardActions';
import { validatePhoneNumber, validateURL } from '../../../utils/input-validations';
import { getResourceActions } from '../../../utils/resourceActionsFormatter';
import CustomLoader from '../../commons/custom-loader';
import DropDown from '../../commons/dropdown';
import Modal from '../../commons/modal';
import ErrorText from '../../controls/error-text';
import Classes from './index.module.css';
import map from 'lodash/map'
import find from 'lodash/find'
import { lowerCase } from 'lodash';
const OutlinedInput = withStyles(theme => ({
    root: {
        backgroundColor: '#FFFFFF'
    }
}))(MuiOutlinedInput);


const UserOnboard = props => {
    const { firstName, lastName, title, department, institutionName, city,
        contactNumber,
        country, stateName, sector, degree, researchersGoals, streetAddress, zipCode,
        designation, otherDesignation, organization, bio, profileImage, tagsInputText,
        linkedin, google, github, orchid, personal, twitter, highestEducation, 
        zone2Request, scientificBackground, otherHighestEducation, otherOrganizationName, setOtherOrganizationName } = props;
    const [showPreview, setShowPreview] = useState(false)
    const [openEditModel, setopenEditModel] = useState(false);
    const [launchWorkspaceButtonText, setLaunchWorkspaceButtonText] = useState('');
    const [launchWorkspaceModalHeader, setLaunchWorkspaceModalHeader] = useState('');
    const [userProfile, setUserProfile] = useState(null)
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isInCommonsUser, setIsInCommonsUser] = useState(false)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [email, setEmail] = useState("")
    const [allOrganization, setAllOrganization] = useState([]);
    const [organizationsList, setOrganizationsList] = useState([]);
    const [allDesignation, setAllDesignation] = useState([]);
    const [designationList, setDesignationList] = useState([]);
    const [isFormUpdating, setIsFormUpdating] = useState(false);

    useEffect(() => {
        getOrganizations();
    }, [])

    useEffect(() => {
        getDesignations();
    }, [])

    const getDesignations = async () => {
        try {
            const res = await getDesignationList();
            const desResList = res?.designations || [];
            setDesignationList(desResList);
            if(desResList.length > 0){
                let desList = map(desResList, (des)=> des?.name);
                desList = desList.sort();
                setAllDesignation(desList);
            }else{
                setAllDesignation([]);
            }
            
        } catch (error) {
            setAllDesignation([]);
            console.log('error in getting organization list')
        }
    }

    const getOrganizations = async () => {
        try {
            const res = await getOrganizationList();
            const orgResList = res?.organizations || [];
            setOrganizationsList(orgResList);
            if(orgResList.length > 0){
                let orgList = map(orgResList, (org)=> org?.name);
                orgList = orgList.sort();
                setAllOrganization(orgList);
            }else{
                setAllOrganization([]);
            }
            
        } catch (error) {
            setAllOrganization([]);
            console.log('error in getting organization list')
        }
    }

    const clearStore = () => {
        props.setUsersOrganizationName({ ...organization, text: "" })
    }


    useEffect(() => {
        getData();

        return () => {
            clearStore()
        }
    }, [allOrganization, allDesignation])

    const getData = async () => {
        try {
            setIsLoadingData(true);

            const user = await Auth.currentSession();
            const identities = user?.idToken?.payload?.identities || null;
            if (identities?.length > 0 && (identities?.[0]?.providerName == "InCommons")) {
                setIsInCommonsUser(true)
            }

            let userDetails = (await Auth.currentUserInfo()) || {};
            const userInfo = await getUserProfile(userDetails?.attributes?.sub);
            const userPermissions = await getUserPermissions();
            let resourceActions = getResourceActions(userPermissions);
            userInfo.user_details['permissions'] = userPermissions;
            userInfo.user_details['resourceActions'] = resourceActions;
            if(userInfo?.user_details){
                await props.updateUserDetails(userInfo.user_details);
            }
            userDetails = userInfo?.user_details || {};
            setUserProfile(userDetails)
            setEmail(userDetails?.email || '')
            props.setOnboardingFirstName({ ...firstName, text: userDetails?.first_name || '', isSubmit: false })
            props.setOnboardingLastName({ ...lastName, text: userDetails?.last_name || '', isSubmit: false })

            const institutionalDetails = userInfo?.user_details?.institution_details || {};


            props.setOnboardingTitle({ ...title, text: institutionalDetails?.title || "", isSubmit: false })
            props.setOnDepartmentChange({ ...department, text: institutionalDetails?.department || "", isSubmit: false })
            props.setOnboardingInstitutionName({ ...institutionName, text: institutionalDetails?.institution_name || "", isSubmit: false })
            props.setResearcherGoals({ ...researchersGoals, text: institutionalDetails?.researcherGoals || "", isSubmit: false })
            props.setOnboardingCity({ ...city, text: institutionalDetails?.city || "", isSubmit: false })
            props.setOnboardingCountry({ ...country, text: institutionalDetails?.country || "", isSubmit: false })
            props.setOnboardingStateName({ ...stateName, text: institutionalDetails?.state || "", isSubmit: false })
            props.setOnboardSector({ ...sector, text: institutionalDetails?.sector || "", isSubmit: false })
            props.setOnboardingDegree({ ...degree, text: institutionalDetails?.degree || "", isSubmit: false })
            props.setEditStreetAddress(institutionalDetails?.streetAddress)
            props.setEditZipCode(institutionalDetails?.zipCode)
            if(allOrganization.length > 0){
                if (allOrganization.find(item => item === userDetails?.organization) || !userDetails?.organization) {
                    props.setUsersOrganizationName({ ...organization, text: userDetails?.organization || '', isSubmit: false })
                    setOtherOrganizationName("")
                } else {
                    props.setUsersOrganizationName({ ...organization, text: "Other", isSubmit: false })
                    setOtherOrganizationName(userDetails?.organization || "")
                }
            }
            if(EDUCATION_OPTIONS.length > 0){
                if (EDUCATION_OPTIONS.find(item => item === userDetails?.["custom_attributes"]?.["highestEducation"]) || !(userDetails?.["custom_attributes"]?.["highestEducation"])) {
                    props.setOnboardingEducation({...highestEducation, text: userDetails?.["custom_attributes"]?.["highestEducation"] || '', isSubmit: false});
                    props.setOtherHighestEducation({text: '', isValid: false, isSubmit: false, invalidText: "" })
                } else {
                    props.setOnboardingEducation({ ...highestEducation, text: "Other", isSubmit: false });
                    props.setOtherHighestEducation({text: userDetails?.["custom_attributes"]?.["highestEducation"] || '', isValid: false, isSubmit: false, invalidText: "" })
                }
            }
            if(allDesignation.length > 0){
                props.setUsersDesignation(includes(allDesignation, userDetails?.["designation"]) ?
                    userDetails?.["designation"] : isEmpty(userDetails?.['designation']) ? '' : "Other" || '')
                props.setUsersOtherDesignation(includes(allDesignation, userDetails?.["designation"]) ?
                    "" : userDetails?.["designation"] || '')
            }
            props.setUsersBio(userDetails?.["professional_bio"] || '')
            props.setUsersGithubProfile(userDetails?.["custom_attributes"]?.["github"] || '');
            props.setUsersLinkedinProfile(userDetails?.["custom_attributes"]?.["linkedin"] || '');
            props.setUsersGoogleProfile(userDetails?.["custom_attributes"]?.["google"] || '');
            props.setUsersOrchidProfile(userDetails?.["custom_attributes"]?.["orchid"] || '');
            props.setUsersPersonalProfile(userDetails?.["custom_attributes"]?.["personal"] || '');
            props.setUsersTwitterProfile(userDetails?.["custom_attributes"]?.["twitter"] || '');
            setIsLoadingData(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (!props.editProfile) {
            props.clearOnboardForm();
        }
    }, [props.editProfile])

    useEffect(() => {
        let count = filter([firstName['text'], lastName['text'], organization['text']], ele => !isEmpty(ele)).length;
        props.changeStepperCount(count);
    }, [firstName, lastName]);

    const onInputBlur = (field) => {
        let count = filter([firstName['text'], lastName['text'], organization['text']], ele => !isEmpty(ele)).length;
        props.changeStepperCount(count);
    }
    const onFirstNameChange = (e) => {
        props.setOnboardingFirstName({ ...firstName, text: e.target.value, isSubmit: false })
    }
    const onLastNameChange = (e) => {
        props.setOnboardingLastName({ ...lastName, text: e.target.value, isSubmit: false });
    }
    const onDesignationTextChange = value => {
        setIsFormSubmitted(false);
        props.setUsersDesignation(value)
    }

    const onCountryChange = value => {
        props.setOnboardingCountry({ ...country, text: value, isSubmit: false })
    }

    const onStreetAddressChange = (e) => {
        props.setEditStreetAddress(e.target.value);
    }

    const onZipCodeChange = (e) => {
        props.setEditZipCode(e.target.value);
    }

    const onOtherDesignationTextChange = e => {
        setIsFormSubmitted(false);
        props.setUsersOtherDesignation(e.target.value)
    }
    const onOrganizationTextChange = (value) => {
        props.setUsersOrganizationName({ ...organization, text: value, isSubmit: false })
    }
    const onBioTextChange = e => {
        props.setUsersBio(e.target.value)
    }
    const onGithubTextChange = e => {
        props.setUsersGithubProfile(e.target.value)
    }
    const onOrchidTextChange = e => {
        props.setUsersOrchidProfile(e.target.value)
    }
    const onLinkedinTextChange = e => {
        props.setUsersLinkedinProfile(e.target.value)
    }
    const onTwitterTextChange = e => {
        props.setUsersTwitterProfile(e.target.value)
    }
    const onGoogleTextChange = e => {
        props.setUsersGoogleProfile(e.target.value)
    }
    const onPersonalTextChange = e => {
        props.setUsersPersonalProfile(e.target.value)
    }

    const onDepartmentChange = e => {
        props.setOnDepartmentChange({ ...department, text: e.target.value, isSubmit: false })
    }

    const onInstitutionName = e => {
        props.setOnboardingInstitutionName({ ...institutionName, text: e.target.value, isSubmit: false })
    }

    const onChangeSector = value => {
        props.setOnboardSector({ ...sector, text: value, isSubmit: false })
    }

    const onChangeCity = e => {
        props.setOnboardingCity({ ...city, text: e.target.value, isSubmit: false })
    }

    const onChangeStateName = e => {
        props.setOnboardingStateName({ ...stateName, text: e.target.value, isSubmit: false })
    }

    const onDegreeChange = value => {
        props.setOnboardingDegree({ ...degree, text: value, isSubmit: false })
    }
    const onEducationChange = value => {
        props.setOnboardingEducation({ ...highestEducation, text: value, isSubmit: false })
    }
    const onResearcherGoalsChange = event => {
        props.setResearcherGoals({ ...researchersGoals, text: event.target.value, isSubmit: false })
    }
    const onScientificBackgroundChange = event =>{
        props.setScientificBackground({ ...scientificBackground, text: event.target.value, isSubmit: false })
    }

    const openEditModal = () => {
        setLaunchWorkspaceButtonText('Enter');
        setLaunchWorkspaceModalHeader('Upload a Photo');
        setopenEditModel(true);
    }

    const closeShareWorkspaceModal = () => {
        setopenEditModel(false);
        setShowPreview(false);
    }
    const onLaunchWorkspaceConfirm = () => {
        setopenEditModel(false);
        setShowPreview(showPreview)
    }
    const LAUNCH_WORKSPACE_MODAL_PROPS = {
        modalTitle: launchWorkspaceModalHeader,
        positiveButtonText: launchWorkspaceButtonText,
        negativeButtonText: "Cancel",
        positiveButtonAction: onLaunchWorkspaceConfirm,
        negativeButtonAction: closeShareWorkspaceModal
    }

    const validateFields = (field) => {
        const { FIRST_NAME, LAST_NAME, ORG_NAME, EDUCATION_FIELD } = USER_ONBOARD_FORM_REQ_FIELDS;
        switch (field) {
            case FIRST_NAME: {
                isEmpty(firstName['text']) ? props.setOnboardingFirstName({ ...firstName, isSubmit: true, isValid: false }) :
                    props.setOnboardingFirstName({ ...firstName, isSubmit: true, isValid: true });
                return !isEmpty(firstName['text']);
            }
            case LAST_NAME: {
                isEmpty(lastName['text']) ? props.setOnboardingLastName({ ...lastName, isSubmit: true, isValid: false }) :
                    props.setOnboardingLastName({ ...lastName, isSubmit: true, isValid: true });
                return !isEmpty(lastName['text']);
            }
            case ORG_NAME: {
                isEmpty(organization['text']) ? props.setUsersOrganizationName({ ...organization, isSubmit: true, isValid: false }) :
                    props.setUsersOrganizationName({ ...organization, isSubmit: true, isValid: true });
                if (organization['text'] === "Other") {
                    return !!otherOrganizationName
                }
                return !isEmpty(organization['text']);
            }
            case EDUCATION_FIELD: {
                isEmpty(highestEducation['text']) ? props.setOnboardingEducation({ ...highestEducation, isSubmit: true, isValid: false }) :
                    props.setOnboardingEducation({ ...highestEducation, isSubmit: true, isValid: true });
                if (lowerCase(highestEducation['text']) === "other") {
                    return !!otherHighestEducation
                }
                return !isEmpty(highestEducation['text']);
            }
            default: return false;
        }
    }

    const validateInCommonDetails = () => {

        const isTitleValid = !isEmpty(title['text']);
        props.setOnboardingTitle({ ...title, isSubmit: true, isValid: isTitleValid })

        const isDepartmentValid = !isEmpty(department['text']);
        props.setOnDepartmentChange({ ...department, isSubmit: true, isValid: isDepartmentValid })

        const isInstitutionNameValid = !isEmpty(institutionName['text']);
        props.setOnboardingInstitutionName({ ...institutionName, isSubmit: true, isValid: isInstitutionNameValid })

        const isCityValid = !isEmpty(city['text']);
        props.setOnboardingCity({ ...city, isSubmit: true, isValid: isCityValid })

        const isCountryValid = !isEmpty(country['text']);
        props.setOnboardingCountry({ ...country, isSubmit: true, isValid: isCountryValid });

        const isStateNameValid = !isEmpty(stateName['text']);
        props.setOnboardingStateName({ ...stateName, isSubmit: true, isValid: isStateNameValid })

        const isSectorValid = !isEmpty(sector['text']);
        props.setOnboardSector({ ...sector, isSubmit: true, isValid: isSectorValid })

        const isDegreeValid = !isEmpty(degree['text']);
        props.setOnboardingDegree({ ...degree, isSubmit: true, isValid: isDegreeValid })

        const isResearcherGoalsValid = !isEmpty(researchersGoals['text'])
        props.setResearcherGoals({ ...researchersGoals, isSubmit: true, isValid: isResearcherGoalsValid })

        return isTitleValid && isDepartmentValid && isInstitutionNameValid && isCityValid &&
            isCountryValid && isStateNameValid && isSectorValid && isDegreeValid && isResearcherGoalsValid;

    }

    const onSubmitClick = async () => {
        try {
            setIsFormSubmitted(true);
            const { FIRST_NAME, LAST_NAME, ORG_NAME, EDUCATION_FIELD } = USER_ONBOARD_FORM_REQ_FIELDS;

            const isValidFirstName = validateFields(FIRST_NAME);
            const isValidLastName = validateFields(LAST_NAME);
            let isValidOrgName = validateFields(ORG_NAME);
            let isEducationValid = validateFields(EDUCATION_FIELD);

            if((orchid && !validateURL(orchid)) || (google && !validateURL(google)) || (twitter && !validateURL(twitter)) || (linkedin && !validateURL(linkedin)) || (github && !validateURL(github)) || (personal && !validateURL(personal))) {
                return
            }
            if(!validatePhoneNumber(contactNumber) && props?.editProfile){
                return
            } 
            let userTitle = `${designation}`.toLowerCase() === 'other' ? otherDesignation : designation;
            const isValidTitle = isInCommonsUser ? true : !isEmpty(userTitle);
            const checkTagsInputText = isEmpty(tagsInputText);
            let isValidIsCommonsDetails = true;
            if (isInCommonsUser) {
                isValidIsCommonsDetails = validateInCommonDetails();
                isValidOrgName = true;
            }
            if (isValidFirstName && isValidLastName && isValidOrgName && checkTagsInputText && isValidIsCommonsDetails && isValidTitle && isEducationValid) {
                let user = await Auth.currentAuthenticatedUser();
                if (!isInCommonsUser) {
                    let cognitoResult = await Auth.updateUserAttributes(user, {
                        'custom:organization': organization?.text
                    });
                }
                let previousCustomAttributes = userProfile?.custom_attributes || {};
                const orgId = find(organizationsList,(org) => org.name === organization['text'])?.id || '';
                const desId = find(designationList,(desig) => desig.name === designation)?.id || '';
                
                let otherOrgCustomAttribute = {};
                let otherDesCustomAttribute = {};
                let prevAttributeUpdated = {}
                const isOrganizationOther = `${organization['text']}`.toLowerCase() == "other";
                const isDesignationOther = `${designation}`.toLowerCase() == "other";
                if(isOrganizationOther){
                    otherOrgCustomAttribute = {"other_organization": otherOrganizationName};
                }else{
                    delete previousCustomAttributes['other_organization'];
                }
                if(isDesignationOther){
                    otherDesCustomAttribute = {"other_designation": otherDesignation};
                }else{
                    delete previousCustomAttributes['other_designation']
                }
                let highestEduToPass = lowerCase(highestEducation.text) === 'other' ? (otherHighestEducation?.text || '') : highestEducation.text;
                const payloadBody = {
                    'last_name': lastName.text,
                    'first_name': firstName.text,
                    'organization_id': orgId, 
                    'designation_id': desId,
                    'professional_bio': bio,
                    "phone_number": contactNumber,
                    'custom_attributes': {
                        ...previousCustomAttributes,
                        ...otherOrgCustomAttribute,
                        ...otherDesCustomAttribute,
                        'google': google,
                        'linkedin': linkedin,
                        'orchid': orchid,
                        'personal': personal,
                        'github': github,
                        'twitter': twitter,
                        "onboarding_check": "true",
                        "highestEducation": highestEduToPass,
                        'scientificBackground': scientificBackground?.text || ""
                    },

                }
                if (isInCommonsUser || props.editProfile) {
                    payloadBody.institution_details = {
                        "department": department?.text || '',
                        "institution_name": institutionName?.text || '',
                        "sector": sector?.text || "",
                        "country": country?.text || "",
                        "streetAddress": streetAddress,
                        "zipCode": zipCode,
                        "city": city?.text || "",
                        "state": stateName?.text || "",
                        "researcherGoals": researchersGoals?.text || ''
                    }
                }
                setIsFormUpdating(true);
                let result = await updateUserProfile(user?.attributes?.sub, payloadBody);
                const latestProfileDetails = await getUserProfile(user?.attributes?.sub);
                const userPermissions = await getUserPermissions();
                let resourceActions = getResourceActions(userPermissions);
                latestProfileDetails.user_details['permissions'] = userPermissions;
                latestProfileDetails.user_details['resourceActions'] = resourceActions;
                props.updateUserDetails(latestProfileDetails?.user_details || {})
                const profileImageSource = profileImage?.[0] || profileImage
                if (profileImageSource) {
                    const response = await uploadProfilePicture(profileImageSource)
                }
                try {
                    const response = await getProfilePicture();
                    props.updateProfilePicture(response);
                }
                catch (error) {
                    console.log(error)
                }
                props.updatePullCount();
                props.setTagsInputText('');
                props.updateSnackBar("Updated Profile details", "Success");
                if (!props.editProfile && env.REACT_APP_COMMUNITY_HIDDEN !== 'true') {
                    navigate('/tell-us-more/community-welcome');
                } else if (env.REACT_APP_COMMUNITY_HIDDEN === 'true'){
                    navigate('/explore')
                }

            } else {
                props.updateSnackBar("Enter all mandatory fields", "Error")
            }
        }
        catch (error) {
            console.log(error)
            props.updateSnackBar("Unable to update Profile details", "Error");
        }finally{
            setIsFormUpdating(false);
        }
    }

    const handleCancelButton = async () => {
        try {
            let user = await Auth.currentAuthenticatedUser();
            const previousCustomAttributes = userProfile?.custom_attributes || {};

            let result = await updateUserProfile(user?.attributes?.sub, {
                "custom_attributes": {
                    ...previousCustomAttributes,
                    "onboarding_check": "true"
                }
            });
            const latestProfileDetails = await getUserProfile(user?.attributes?.sub);
            const userPermissions = await getUserPermissions();
            let resourceActions = getResourceActions(userPermissions);
            latestProfileDetails.user_details['permissions'] = userPermissions;
            latestProfileDetails.user_details['resourceActions'] = resourceActions;
            props.updateUserDetails(latestProfileDetails?.user_details || {})
            navigate("/my-profile")

        }
        catch (error) {
            console.log(error)
        }
    }


    const onClose = () => {
        setShowPreview(null);
    }

    const onCrop = async (preview) => {
        setShowPreview(preview)
        let filePath = dataURLtoFile(preview, 'image.png');
        props.setProfileImageOfUser(filePath);
    }

    const onContactNumberChange = (e) => {
        props.setEditContactNumber(e.target.value);
    }


    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });

    }

    const onSocialMediaLinkClick = (Link) => {
        window.open(Link, "_blank")
    }

    const isOrganizationOther = () => {
            if ((organization['text'] === "Other") && allOrganization.length) {
                return true;
            }
            return false;
    }
    const isHighestEducationOther = () => {
        if ((lowerCase(highestEducation['text']) === "other") && EDUCATION_OPTIONS.length) {
            return true;
        }
        return false;
    }
    const getSelectedHighestEducationValue = () => {
        if (!highestEducation?.text || false) {
            return null
        }
        if (typeof (highestEducation) === "string") {
            props.setUsersOrganizationName({ text: highestEducation })
            return
        }
        if (trim(highestEducation?.text).toLowerCase() === "other") {
            return "Other"
        }

        return EDUCATION_OPTIONS.find(item => item === highestEducation?.['text']) ? highestEducation['text'] : "Other"
    }

    const getSelectedOrganizationValue = () => {
        if (!organization['text']) {
            return null
        }
        if (typeof (organization) === "string") {
            props.setUsersOrganizationName({ text: organization })
            return
        }
        if (trim(organization['text']).toLowerCase() === "other") {
            return "Other"
        }

        return allOrganization.find(item => item === organization?.['text']) ? organization['text'] : "Other"
    }

    const onHighestOtherEducationChange = (e) => {
        props.setOtherHighestEducation({ ...otherHighestEducation, text: e.target.value, isSubmit: false });
    }

    const onboardContainerClass = !props.editProfile ? Classes.addPadding : '';
    const profileImageContainerSize = !props.editProfile ? 4 : 3;
    const heightAndWidthOfProfilePic = !props.editProfile ? Classes.largeProfileContainer : Classes.smallProfilePicContainer
    const allInputContainersStyle = !props.editProfile ? Classes.allInputContainers : `${Classes.allInputContainers} ${Classes.addMarginToInputCont}`
    const defaultImageSource = props.proPic || DefaultProfileImage;
    const inputContainerLength = window.innerWidth < 1440 ? !props.editProfile ? 7 : 9 : 6;

    return (
        <div className={Classes.mainContainer}>
            <Grid container className={`${Classes.userOnboardContainer} ${onboardContainerClass}`}>
                <Grid item xs={12}>
                    <Grid container direction='row' >
                        {(props.editProfile || zone2Request) ? null : <Grid item xs={1} >
                            <div className={Classes.stepperContainer}>
                            </div>
                        </Grid>}
                        {
                            isLoadingData ?
                                <div id='cvb-userOnboarding-loader'><CustomLoader componentLoader styleProp={{ height: 500, width: "70vw" }} /></div> :
                                <React.Fragment>
                                     <Grid item xs={profileImageContainerSize} className={Classes.userProfileImageContainer}>
                                        {!zone2Request ?
                                            <React.Fragment>
                                                {showPreview != false ? <div
                                                    className={`${Classes.profilePicContainerDiv} ${heightAndWidthOfProfilePic}`}
                                                    id='cvb-userOnboarding-profilePic'
                                                    style={{ backgroundImage: `url('${get(profileImage, '[0].preview', showPreview)}')` }}>
                                                </div> :
                                                    <div
                                                        id='cvb-userOnboarding-defaultSourceImage'
                                                        className={`${Classes.profilePicContainerDiv} ${heightAndWidthOfProfilePic}`}
                                                        style={{ backgroundImage: `url('${get(profileImage, '[0].preview', defaultImageSource)}')` }}>
                                                    </div>}
                                                <div className='upload-image-div'>
                                                    <button id='cvb-userOnboarding-editPhotoButton' onClick={openEditModal} className={`transparent-button ${Classes.choosePictureButton}`}>Choose A Photo</button>
                                                </div>
                                            </React.Fragment>
                                            : <p className={Classes.tellUsText}>Personal Details</p>
                                        }
                                    </Grid>
                                    <Grid xs={inputContainerLength} md={inputContainerLength} lg={inputContainerLength} item className={allInputContainersStyle}>
                                        {(props.editProfile || zone2Request) ? null : <p className={Classes.tellUsText}>Tell us about yourself</p>}
                                        <Grid container spacing={4} justifyContent='space-between'>
                                            <Grid item xs={6}>
                                                <label id='cvb-userOnboarding-firstNameLabel' htmlFor="firstName"> First Name<sup> *</sup></label>
                                                <OutlinedInput
                                                    onBlur={() => onInputBlur('firstName')}
                                                    error={(firstName['isSubmit'] && !firstName['isValid'])} fullWidth
                                                    value={firstName['text']} onChange={onFirstNameChange}
                                                    placeholder='Enter first name' name='firstName' 
                                                    id='cvb-userOnboarding-firstNameInput'
                                                    />
                                                {(firstName['isSubmit'] && !firstName['isValid']) &&
                                                    <ErrorText errorMessage={USER_ONBOARD_FORM_ERROR_TEXT.FIRST_NAME} />}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <label id='cvb-userOnboarding-lastNameLabel' htmlFor="lastName"> Last Name<sup> *</sup></label>
                                                <OutlinedInput onBlur={() => onInputBlur('lastName')}
                                                    error={(lastName['isSubmit'] && !lastName['isValid'])} fullWidth
                                                    value={lastName['text']} onChange={onLastNameChange}
                                                    placeholder='Enter last name' name='lastName' 
                                                    id='cvb-userOnboarding-lastNameInput'
                                                    />
                                                {(lastName['isSubmit'] && !lastName['isValid']) &&
                                                    <ErrorText errorMessage={USER_ONBOARD_FORM_ERROR_TEXT.LAST_NAME} />}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={4} justifyContent='space-between'>
                                            <Grid item xs={12}>
                                                <label id='cvb-userOnboarding-emailLabel' htmlFor="title">Email</label>
                                                <OutlinedInput
                                                    fullWidth
                                                    value={email}
                                                    disabled
                                                    id='cvb-userOnboarding-emailInput-disabled'
                                                />
                                            </Grid>
                                        </Grid>
                                        {
                                            isInCommonsUser && !props?.editProfile ?
                                                <>
                                                    <Grid container spacing={4} justifyContent='space-between'>
                                                        <Grid item xs={12}>
                                                            <label id='cvb-userOnboarding-emailLabel' htmlFor="title">Email</label>
                                                            <OutlinedInput
                                                                fullWidth
                                                                value={email}
                                                                disabled 
                                                                id='cvb-userOnboarding-emailInput-disabled'
                                                                />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={5} justifyContent='space-between'>
                                                        <Grid item xs={12} className={Classes.institutionalDetailsTitle}>
                                                            <h3 id='cvb-userOnboarding-institutionalDetailsHeading'>Institutional Details</h3>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={4} justifyContent='space-between'>
                                                        <Grid item xs={6}>
                                                            <label id='cvb-userOnboarding-departmentLabel' htmlFor="department">Department</label>
                                                            <OutlinedInput onBlur={() => onInputBlur('department')}
                                                                error={(department['isSubmit'] && !department['isValid'])} fullWidth
                                                                value={department['text']} onChange={onDepartmentChange}
                                                                placeholder='Enter your department name' name='department' 
                                                                id='cvb-userOnboarding-departmentInput'
                                                                />
                                                            {(department['isSubmit'] && !department['isValid']) &&
                                                                <ErrorText errorMessage={"Please enter the department"} />}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={4} justifyContent='space-between'>
                                                        <Grid item xs={6}>
                                                            <label id='cvb-userOnboarding-sectorLabel' htmlFor="sector">Sector</label>
                                                            <DropDown
                                                                values={SECTORS_LIST}
                                                                handleFilterStateChange={onChangeSector}
                                                                selectedFilter={sector?.text}
                                                                placeholder={'Select your sector'}
                                                                customClass={Classes.designationField}
                                                                id='cvb-userOnboarding-sectorInput'
                                                            />
                                                            {(sector['isSubmit'] && !sector['isValid']) &&
                                                                <ErrorText errorMessage={"Please select a sector"} />}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={4} justifyContent='space-between'>
                                                        <Grid item xs={12}>
                                                            <label id='cvb-userOnboarding-institutionNameLabel' htmlFor="institutionName">Institution Name</label>
                                                            <OutlinedInput onBlur={() => onInputBlur('institutionName')}
                                                                error={(institutionName['isSubmit'] && !institutionName['isValid'])} fullWidth
                                                                value={institutionName['text']} onChange={onInstitutionName}
                                                                placeholder='Enter your institution name' name='institutionName' 
                                                                id='cvb-userOnboarding-institutionNameInput'
                                                                />
                                                            {(institutionName['isSubmit'] && !institutionName['isValid']) &&
                                                                <ErrorText errorMessage={"Please enter institution name"} />}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={4} justifyContent='space-between'>
                                                        <Grid item xs={6}>
                                                            <label id='cvb-userOnboarding-countryLabel' htmlFor="Country">Country</label>
                                                            <DropDown
                                                                values={COUNTRY_LIST}
                                                                searchable
                                                                handleFilterStateChange={onCountryChange}
                                                                selectedFilter={country?.text}
                                                                placeholder={'Select your country'}
                                                                customClass={Classes.designationField}
                                                                isClearable
                                                                id='cvb-userOnboarding-countryInput'
                                                            />
                                                            {(country['isSubmit'] && !country['isValid']) &&
                                                                <ErrorText errorMessage={"Please select a country"} />}
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <label id='cvb-userOnboarding-cityLabel' htmlFor="city">City</label>
                                                            <OutlinedInput onBlur={() => onInputBlur('city')}
                                                                error={(city['isSubmit'] && !city['isValid'])} fullWidth
                                                                value={city['text']} onChange={onChangeCity}
                                                                placeholder='Enter city' name='city' 
                                                                id='cvb-userOnboarding-cityInput'
                                                                />
                                                            {(city['isSubmit'] && !city['isValid']) &&
                                                                <ErrorText errorMessage={"Please enter city"} />}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={4} justifyContent='space-between'>
                                                        <Grid item xs={6}>
                                                            <label id='cvb-userOnboarding-stateLabel' htmlFor="sector">State</label>
                                                            <OutlinedInput onBlur={() => onInputBlur('stateName')}
                                                                error={(stateName['isSubmit'] && !stateName['isValid'])} fullWidth
                                                                value={stateName['text']} onChange={onChangeStateName}
                                                                placeholder='Enter state' name='stateName' 
                                                                id='cvb-userOnboarding-stateInput'
                                                                />
                                                            {(stateName['isSubmit'] && !stateName['isValid']) &&
                                                                <ErrorText errorMessage={"Please enter state"} />}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={4} justifyContent='space-between' className={Classes.signUpFormInputsRow}>
                                                        <Grid item xs={12} className={Classes.textareaContainer}>
                                                            <label id='cvb-userOnboarding-researcherGoalLabel' htmlFor="goals">Researcher goals</label>
                                                            <textarea rows={4} fullWidth
                                                                value={researchersGoals?.text}
                                                                onChange={onResearcherGoalsChange}
                                                                placeholder='Please explain the intent goal of your research and provide project summary or abstract .' name='bio'
                                                                style={{ color: '#586477' }}
                                                                id='cvb-userOnboarding-researcherGoalInput'
                                                            />
                                                            {(researchersGoals['isSubmit'] && !researchersGoals['isValid']) &&
                                                                <ErrorText errorMessage={"Please enter a researchers goals"} />}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={5} justifyContent='space-between'>
                                                        <Grid item xs={12} className={Classes.institutionalDetailsTitle}>
                                                        </Grid>
                                                    </Grid>
                                                </> : props?.editProfile ?

                                                <React.Fragment>
                                                    <Grid container spacing={4} className={Classes.educationFieldContainer} justifyContent='space-between'>
                                                        <Grid item xs={6} className={Classes.educationInputFieldContainer}>
                                                            <label id='cvb-userOnboarding-educationLabel'  htmlFor="education" className={Classes.educationLabel}> Highest Level Of Education Completed<sup> *</sup></label>
                                                            <DropDown
                                                                values={EDUCATION_OPTIONS}
                                                                handleFilterStateChange={onEducationChange}
                                                                selectedFilter={getSelectedHighestEducationValue()}
                                                                placeholder={'Select education completed'}
                                                                customClass={Classes.organizationField}
                                                                id='cvb-userOnboarding-educationInput'
                                                            />
                                                            {(highestEducation['isSubmit'] && !highestEducation['isValid']) &&
                                                                <ErrorText errorMessage={USER_ONBOARD_FORM_ERROR_TEXT.EDUCATION_FIELD} />}
                                                        </Grid>
                                                        {
                                                            isHighestEducationOther() &&
                                                            <Grid item xs={6} className={Classes.otherEducationFieldContainer}>
                                                                <label id='cvb-userOnboarding-educationLabel' htmlFor="education" className={Classes.otherEducationLabel}> Other Degree Or Technical/Occupational Certificate<sup> *</sup></label>
                                                                <OutlinedInput
                                                                    onBlur={() => onInputBlur('firstName')}
                                                                    error={((isEmpty(otherHighestEducation?.text)) && (otherHighestEducation?.isSubmit || false)  && (!otherHighestEducation?.isValid || false))} fullWidth
                                                                    value={otherHighestEducation?.text} onChange={onHighestOtherEducationChange}
                                                                    placeholder='Enter another degree or certificate' name='otherHighestEducationName' 
                                                                    id='cvb-userOnboarding-highestEducationLabel'
                                                                    />
                                                                {((isEmpty(otherHighestEducation?.text)) && (otherHighestEducation?.isSubmit || false)  && (!otherHighestEducation?.isValid || false)) &&
                                                                    <ErrorText errorMessage={"Please provide a degree or certificate"} />}
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </React.Fragment> :
                                                <React.Fragment>
                                                    <Grid container spacing={4} justifyContent='space-between'>
                                                        <Grid item xs={6}>
                                                            <label id='cvb-userOnboarding-titleLabel' htmlFor="designation"> Role In My Organization<sup> *</sup></label>
                                                            <DropDown
                                                                values={allDesignation}
                                                                handleFilterStateChange={onDesignationTextChange}
                                                                selectedFilter={designation}
                                                                placeholder={'Select your role'}
                                                                customClass={Classes.designationField}
                                                                id='cvb-userOnboarding-titleInput'
                                                            />
                                                             {( isEmpty(designation) && isFormSubmitted) &&
                                                                <ErrorText errorMessage={"Please select role"} />}
                                                        </Grid>
                                                        {
                                                            `${designation}`.toLowerCase() === "other" ?
                                                                <Grid item xs={6}>
                                                                    <label id='cvb-userOnboarding-designationLabel' htmlFor="designation">Other Role<sup> *</sup></label>
                                                                    <OutlinedInput error={(isEmpty(otherDesignation) && isFormSubmitted)} fullWidth placeholder='Enter your role' name='organization'
                                                                        value={otherDesignation} onChange={onOtherDesignationTextChange} 
                                                                        id='cvb-userOnboarding-designationInput'
                                                                        />
                                                                    {(isEmpty(otherDesignation) && isFormSubmitted) &&
                                                                        <ErrorText errorMessage={"Please enter role"} />}

                                                                </Grid> : ''}

                                                    </Grid>
                                                    <Grid container spacing={4} justifyContent='space-between'>
                                                        <Grid item xs={6}>
                                                            <label id='cvb-userOnboarding-organizationLabel'  htmlFor="organization" > Organization<sup> *</sup></label>
                                                            <DropDown
                                                                values={allOrganization}
                                                                handleFilterStateChange={onOrganizationTextChange}
                                                                selectedFilter={getSelectedOrganizationValue()}
                                                                placeholder={'Select your organization'}
                                                                customClass={Classes.organizationField}
                                                                id='cvb-userOnboarding-organizationInput'
                                                            />
                                                            {(organization['isSubmit'] && !organization['isValid']) &&
                                                                <ErrorText errorMessage={USER_ONBOARD_FORM_ERROR_TEXT.ORG_NAME} />}
                                                        </Grid>
                                                        {
                                                            isOrganizationOther() &&
                                                            <Grid item xs={6}>
                                                                <label id='cvb-userOnboarding-organizationLabel' htmlFor="organization" > Organization Name<sup> *</sup></label>
                                                                <OutlinedInput
                                                                    onBlur={() => onInputBlur('firstName')}
                                                                    error={(firstName['isSubmit'] && organization['text'] === "Other" && !otherOrganizationName)} fullWidth
                                                                    value={otherOrganizationName} onChange={e => setOtherOrganizationName(e.target.value)}
                                                                    placeholder='Enter your organization name' name='otherOrganizationName' 
                                                                    id='cvb-userOnboarding-otherOrganizationLabel'
                                                                    />
                                                                {(firstName['isSubmit'] && organization['text'] === "Other" && !otherOrganizationName) &&
                                                                    <ErrorText errorMessage={"Please enter a valid organization name"} />}
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                    <Grid container spacing={4} className={Classes.educationFieldContainer} justifyContent='space-between'>
                                                        <Grid item xs={6} className={Classes.educationInputFieldContainer}>
                                                            <label id='cvb-userOnboarding-educationLabel'  htmlFor="education" className={Classes.educationLabel}> Highest Level Of Education Completed<sup> *</sup></label>
                                                            <DropDown
                                                                values={EDUCATION_OPTIONS}
                                                                handleFilterStateChange={onEducationChange}
                                                                selectedFilter={getSelectedHighestEducationValue()}
                                                                placeholder={'Select education completed'}
                                                                customClass={Classes.organizationField}
                                                                id='cvb-userOnboarding-educationInput'
                                                            />
                                                            {(highestEducation['isSubmit'] && !highestEducation['isValid']) &&
                                                                <ErrorText errorMessage={USER_ONBOARD_FORM_ERROR_TEXT.EDUCATION_FIELD} />}
                                                        </Grid>
                                                        {
                                                            isHighestEducationOther() &&
                                                            <Grid item xs={6} className={Classes.otherEducationFieldContainer}>
                                                                <label id='cvb-userOnboarding-educationLabel' htmlFor="education" className={Classes.otherEducationLabel}> Other Degree Or Technical/Occupational Certificate<sup> *</sup></label>
                                                                <OutlinedInput
                                                                    onBlur={() => onInputBlur('firstName')}
                                                                    error={(highestEducation['isSubmit'] && lowerCase(highestEducation['text']) === "other" && !otherHighestEducation?.text)} fullWidth
                                                                    value={otherHighestEducation?.text} onChange={onHighestOtherEducationChange}
                                                                    placeholder='Enter another degree or certificate' name='otherHighestEducationName' 
                                                                    id='cvb-userOnboarding-highestEducationLabel'
                                                                    />
                                                                {((highestEducation?.isSubmit || false) && lowerCase(highestEducation['text']) === "other" && !otherHighestEducation?.text) &&
                                                                    <ErrorText errorMessage={"Please provide a degree or certificate"} />}
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </React.Fragment>
                                        }
                                       {!zone2Request && <Grid container spacing={4} justifyContent='space-between' className={Classes.signUpFormInputsRow}>
                                            <Grid item xs={12} className={Classes.textareaContainer}>
                                                <label id='cvb-userOnboarding-backInterestLabel' htmlFor="bio">My Background and Interests (optional)</label>
                                                <textarea rows={4} fullWidth value={bio}
                                                    onChange={onBioTextChange}
                                                    placeholder='Explain more about yourself, your role, experience, etc.' name='bio'
                                                    style={{ color: '#586477' }}
                                                    id='cvb-userOnboarding-backInterestInput'
                                                />
                                            </Grid>
                                        </Grid>}
                                        {!zone2Request && 
                                        <Grid container spacing={4} justifyContent='space-between' className={Classes.mediaIconsContainer}>
                                            <Grid item xs={6} className={Classes.mediaField}>
                                                <div className={Classes.flexItem}>
                                                    <img src={id} alt='ID image' className={Classes.mediaImage} />
                                                </div>
                                                <div className={Classes.flexItem}>
                                                    <OutlinedInput fullWidth placeholder='Enter ORCID URL'
                                                        onChange={onOrchidTextChange} value={orchid}
                                                        id='cvb-userOnboarding-orchidURLInput'
                                                        endAdornment={
                                                            <InputAdornment position="end" >
                                                                <p className={Classes.adornmentText} id='cvb-userOnboarding-orchidURLButton' onClick={() => onSocialMediaLinkClick(ORCID_LINK)}>?</p>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </div>
                                                <div className={Classes.flexItem}>
                                                    {(orchid && !validateURL(orchid)) &&
                                                        <div id='cvb-userOnboarding-invalidPhoneErrorMessage' className={Classes.errorMessage}>
                                                        <ErrorText errorMessage='Please enter a valid URL.' /></div>
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid item xs={6} className={Classes.mediaField}>
                                                <div className={Classes.flexItem}>
                                                    <img src={linkedIn} alt='ID image' className={Classes.mediaImage} />
                                                </div>

                                                <div className={Classes.flexItem}>
                                                    <OutlinedInput fullWidth placeholder='Enter LinkedIn URL'
                                                        onChange={onLinkedinTextChange} value={linkedin}
                                                        id='cvb-userOnboarding-linkedInURLInput'
                                                        endAdornment={
                                                            <InputAdornment position="end" >
                                                                <p className={Classes.adornmentText} id='cvb-userOnboarding-linkedinURLButton' onClick={() => onSocialMediaLinkClick(LINKEDIN_LINK)}>?</p>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </div>

                                                <div className={Classes.flexItem}>
                                                    {(linkedin && !validateURL(linkedin)) &&
                                                        <div id='cvb-userOnboarding-invalidPhoneErrorMessage' className={Classes.errorMessage}>
                                                        <ErrorText errorMessage='Please enter a valid URL.' /></div>
                                                    }
                                                </div>
                                            </Grid>
                                        </Grid>
                                        }
                                        {!zone2Request &&
                                        <Grid container spacing={4} justifyContent='space-between'>
                                            <Grid item xs={6} className={Classes.mediaField}>

                                                <div className={Classes.flexItem}>
                                                    <img src={googleImg} alt='ID image' className={Classes.mediaImage} />
                                                </div>

                                                <div className={Classes.flexItem}>
                                                    <OutlinedInput fullWidth placeholder='Enter Google Scholar URL'
                                                        onChange={onGoogleTextChange} value={google}
                                                        id='cvb-userOnboarding-scholarURLInput'
                                                        endAdornment={
                                                            <InputAdornment position="end" >
                                                                <p className={Classes.adornmentText} id='cvb-userOnboarding-scholarURLButton' onClick={() => onSocialMediaLinkClick(GOOGLE_SCHOLAR_LINK)}>?</p>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </div>

                                                <div className={Classes.flexItem}>
                                                    {(google && !validateURL(google)) &&
                                                        <div id='cvb-userOnboarding-invalidPhoneErrorMessage' className={Classes.errorMessage}>
                                                        <ErrorText errorMessage='Please enter a valid URL.' /></div>
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid item xs={6} className={Classes.mediaField}>
                                                
                                                <div className={Classes.flexItem}>
                                                    <img src={twitterImg} alt='ID image' className={Classes.mediaImage} />
                                                </div>

                                                <div className={Classes.flexItem}>
                                                    <OutlinedInput fullWidth placeholder='Enter Twitter URL'
                                                        onChange={onTwitterTextChange} value={twitter}
                                                        id='cvb-userOnboarding-twitterURLInput'
                                                        endAdornment={
                                                            <InputAdornment position="end" >
                                                                <p className={Classes.adornmentText} id='cvb-userOnboarding-twitterURLButton' onClick={() => onSocialMediaLinkClick(TWITTER_LINK)}>?</p>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </div>

                                                <div className={Classes.flexItem}>
                                                    {(twitter && !validateURL(twitter)) &&
                                                        <div id='cvb-userOnboarding-invalidPhoneErrorMessage' className={Classes.errorMessage}>
                                                        <ErrorText errorMessage='Please enter a valid URL.' /></div>
                                                    }
                                                </div>
                                            </Grid>
                                        </Grid>
                                        }  
                                        {!zone2Request && 
                                        <Grid container spacing={4} justifyContent='space-between'>
                                            <Grid item xs={6} className={Classes.mediaField}>

                                                <div className={Classes.flexItem}>
                                                    <img src={githubImg} alt='ID image' className={Classes.mediaImage} />
                                                </div>

                                                <div className={Classes.flexItem}>
                                                    <OutlinedInput fullWidth placeholder='Enter GitHub URL'
                                                        onChange={onGithubTextChange} value={github}
                                                        id='cvb-userOnboarding-githubURLInput'
                                                        endAdornment={
                                                            <InputAdornment position="end" >
                                                                <p className={Classes.adornmentText} id='cvb-userOnboarding-githubURLButton' onClick={() => onSocialMediaLinkClick(GITHUB_LINK)}>?</p>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </div>

                                                <div className={Classes.flexItem}>
                                                    {(github && !validateURL(github)) &&
                                                        <div id='cvb-userOnboarding-invalidPhoneErrorMessage' className={Classes.errorMessage}>
                                                        <ErrorText errorMessage='Please enter a valid URL.' /></div>
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid item xs={6} className={Classes.mediaField}>

                                                <div className={Classes.flexItem}>
                                                    <img src={internet} alt='ID image' className={Classes.mediaImage} />
                                                </div>

                                                <div className={Classes.flexItem}>
                                                    <OutlinedInput fullWidth placeholder='Enter Personal Website'
                                                        onChange={onPersonalTextChange} value={personal}
                                                        id='cvb-userOnboarding-personalURLInput'
                                                    />
                                                </div>

                                                <div className={Classes.flexItem}>
                                                    {(personal && !validateURL(personal)) &&
                                                        <div id='cvb-userOnboarding-invalidPhoneErrorMessage' className={Classes.errorMessage}>
                                                        <ErrorText errorMessage='Please enter a valid URL.' /></div>
                                                    }
                                                </div>
                                            </Grid>
                                        </Grid>
                                        }


                                        {
                                            (props?.editProfile) &&

                                             <React.Fragment>
                                                <React.Fragment>
                                               {!zone2Request && <Grid container spacing={4} justifyContent='space-between' style={{marginTop:'20px'}}>
                                                    <Grid item xs={6}>
                                                        <label id='cvb-userOnboarding-emailLabel' htmlFor="title">Email</label>
                                                        <OutlinedInput
                                                            fullWidth
                                                            value={email}
                                                            disabled 
                                                            id='cvb-userOnboarding-emailInput-disabled'
                                                            />
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <label id='cvb-userOnboarding-contactNumberLabel' htmlFor='contactNumber'>Contact Number<sup> *</sup></label>
                                                        <OutlinedInput
                                                            fullWidth
                                                            value={contactNumber}
                                                            error={(isFormSubmitted && !validatePhoneNumber(contactNumber))}
                                                            onChange={onContactNumberChange}
                                                            placeholder='Enter contact number'
                                                            name='contactNumber' 
                                                            id='cvb-userOnboarding-contactNumberInput'
                                                            />
                                                        <p className={Classes.hintText}>Country code is optional</p>
                                                        {(isFormSubmitted && !validatePhoneNumber(contactNumber)) &&
                                                            <div id='cvb-userOnboarding-invalidPhoneErrorMessage' className={Classes.errorMessage}><ErrorText errorMessage='Please enter valid phone number.' /></div>
                                                        }
                                                    </Grid>
                                                </Grid>}

                                                <Grid container spacing={4} justifyContent='space-between'>
                                                    <Grid item xs={6}>
                                                        <label id='cvb-userOnboarding-titleLabel' htmlFor="designation"> Role In My Organization<sup> *</sup></label>
                                                        <DropDown
                                                            values={allDesignation}
                                                            handleFilterStateChange={onDesignationTextChange}
                                                            selectedFilter={designation}
                                                            placeholder={'Select your role'}
                                                            customClass={Classes.designationField}
                                                            id='cvb-userOnboarding-titleInput'
                                                        />
                                                            {( isEmpty(designation) && isFormSubmitted) &&
                                                            <ErrorText errorMessage={"Please select role"} />}
                                                    </Grid>
                                                    {
                                                        `${designation}`.toLowerCase() === "other" ?
                                                            <Grid item xs={6}>
                                                                <label id='cvb-userOnboarding-designationLabel' htmlFor="designation">Other Role<sup> *</sup></label>
                                                                <OutlinedInput error={(isEmpty(otherDesignation) && isFormSubmitted)} fullWidth placeholder='Enter your role' name='organization'
                                                                    value={otherDesignation} onChange={onOtherDesignationTextChange} 
                                                                    id='cvb-userOnboarding-designationInput'
                                                                    />
                                                                {(isEmpty(otherDesignation) && isFormSubmitted) &&
                                                                    <ErrorText errorMessage={"Please enter role"} />}

                                                            </Grid> : ''}

                                                </Grid>

                                               {!zone2Request && <Grid container spacing={4} justifyContent='space-between'>
                                                <Grid item xs={6}>
                                                        <label id='cvb-userOnboarding-sectorLabel' htmlFor="sector">Sector</label>
                                                        <DropDown
                                                            values={SECTORS_LIST}
                                                            handleFilterStateChange={onChangeSector}
                                                            selectedFilter={sector?.text}
                                                            placeholder={'Select your sector'}
                                                            customClass={Classes.designationField}
                                                            id='cvb-userOnboarding-sectorInput'
                                                        />
                                                        {(sector['isSubmit'] && !sector['isValid']) &&
                                                            <ErrorText errorMessage={"Please select a sector"} />}
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                            <label id='cvb-userOnboarding-departmentLabel' htmlFor="department">Department</label>
                                                            <OutlinedInput onBlur={() => onInputBlur('department')}
                                                                error={(department['isSubmit'] && !department['isValid'])} fullWidth
                                                                value={department['text']} onChange={onDepartmentChange}
                                                                placeholder='Enter your department name' name='department' 
                                                                id='cvb-userOnboarding-departmentInput'
                                                                />
                                                            {(department['isSubmit'] && !department['isValid']) &&
                                                                <ErrorText errorMessage={"Please enter the department"} />}
                                                    </Grid>
                                                    
                                                    
                                                </Grid>}
                                                <Grid container spacing={4} justifyContent='space-between'>
                                                    <Grid item xs={6}>
                                                        <label id='cvb-userOnboarding-organizationLabel'  htmlFor="organization" > Organization<sup> *</sup></label>
                                                        <DropDown
                                                            values={allOrganization}
                                                            handleFilterStateChange={onOrganizationTextChange}
                                                            selectedFilter={getSelectedOrganizationValue()}
                                                            placeholder={'Select your organization'}
                                                            customClass={Classes.organizationField}
                                                            id='cvb-userOnboarding-organizationInput'
                                                        />
                                                        {(organization['isSubmit'] && !organization['isValid']) &&
                                                            <ErrorText errorMessage={USER_ONBOARD_FORM_ERROR_TEXT.ORG_NAME} />}
                                                    </Grid>
                                                </Grid>
                                                

                                                {
                                                    isOrganizationOther()&&
                                                    <Grid container spacing={4} justifyContent='space-between'>
                                                        <Grid item xs={6}>
                                                            <label id='cvb-userOnboarding-organizationLabel' htmlFor="organization" > Organization Name<sup> *</sup></label>
                                                            <OutlinedInput
                                                                onBlur={() => onInputBlur('firstName')}
                                                                error={(firstName['isSubmit'] && organization['text'] === "Other" && !otherOrganizationName)} fullWidth
                                                                value={otherOrganizationName} onChange={(e) => setOtherOrganizationName(e.target.value)}
                                                                placeholder='Enter your organization name' name='otherOrganizationName' 
                                                                id='cvb-userOnboarding-otherOrganizationLabel'
                                                                />
                                                            {(firstName['isSubmit'] && organization['text'] === "Other" && !otherOrganizationName) &&
                                                                <ErrorText errorMessage={"Please enter a valid organization name"} />}
                                                        </Grid>
                                                    </Grid>
                                                }

                                                </React.Fragment>

                                                {
                                                    isOrganizationOther() &&
                                                        <>
                                                        <Grid container spacing={4} justifyContent='space-between'>
                                                            <Grid item xs={6}>
                                                                <label id='cvb-userOnboarding-countryLabel' htmlFor="Country">Country</label>
                                                                <DropDown
                                                                    values={COUNTRY_LIST}
                                                                    searchable
                                                                    handleFilterStateChange={onCountryChange}
                                                                    selectedFilter={country?.text}
                                                                    placeholder={'Select your country'}
                                                                    customClass={Classes.designationField}
                                                                    isClearable
                                                                    id='cvb-userOnboarding-countryInput'
                                                                />
                                                                {(country['isSubmit'] && !country['isValid']) && 
                                                                    <ErrorText errorMessage={"Please select a country"} />}
                                                            </Grid>
                                                            
                                                        </Grid>
                                                        <Grid container spacing={4} justifyContent='space-between'>
                                                            <Grid item xs={12}>
                                                                <label id='cvb-editContactDetails-streetAddressLabel' htmlFor='streetAddress'>Street Address</label>
                                                                <OutlinedInput
                                                                    fullWidth
                                                                    value={streetAddress}
                                                                    onChange={onStreetAddressChange}
                                                                    placeholder='Enter street address'
                                                                    name='streetAddress' 
                                                                    id='cvb-editContactDetails-streetAddressInput'
                                                                    />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={4} justifyContent='space-between'>
                                                            <Grid item xs={6}>
                                                                <label id='cvb-userOnboarding-cityLabel' htmlFor="city">City</label>
                                                                <OutlinedInput onBlur={() => onInputBlur('city')}
                                                                    error={(city['isSubmit'] && !city['isValid'])} fullWidth
                                                                    value={city['text']} onChange={onChangeCity}
                                                                    placeholder='Enter city' name='city' 
                                                                    id='cvb-userOnboarding-cityInput'
                                                                    />
                                                                {(city['isSubmit'] && !city['isValid']) &&
                                                                    <ErrorText errorMessage={"Please enter city"} />}
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <label id='cvb-userOnboarding-stateLabel' htmlFor="sector">State</label>
                                                                <OutlinedInput onBlur={() => onInputBlur('stateName')}
                                                                    error={(stateName['isSubmit'] && !stateName['isValid'])} fullWidth
                                                                    value={stateName['text']} onChange={onChangeStateName}
                                                                    placeholder='Enter state' name='stateName' 
                                                                    id='cvb-userOnboarding-stateInput'
                                                                    />
                                                                {(stateName['isSubmit'] && !stateName['isValid']) &&
                                                                    <ErrorText errorMessage={"Please enter state"} />}
                                                            </Grid>
                                                        </Grid> 
                                                        
                                                        <Grid container spacing={4} justifyContent='space-between'>
                                                            <Grid item xs={6}>
                                                                <label id='cvb-editContactDetails-zipCodeLabel' htmlFor='zipCode'>Zip Code</label>
                                                                <OutlinedInput
                                                                    fullWidth
                                                                    value={zipCode}
                                                                    onChange={onZipCodeChange}
                                                                    placeholder='Enter zip code'
                                                                    name='zipCode' 
                                                                    id='cvb-editContactDetails-zipCodeInput'
                                                                    />
                                                            </Grid>
                                                        </Grid>
                                                        </>
                                                }
                                                    
                                               {!zone2Request && <Grid container spacing={4} justifyContent='space-between' className={Classes.signUpFormInputsRow}>
                                                    <Grid item xs={12} className={Classes.textareaContainer}>
                                                        <label id='cvb-userOnboarding-researcherGoalLabel' htmlFor="goals">Researcher goals</label>
                                                        <textarea rows={4} fullWidth
                                                            value={researchersGoals?.text}
                                                            onChange={onResearcherGoalsChange}
                                                            placeholder='Please explain the intent goal of your research and provide project summary or abstract .' name='bio'
                                                            style={{ color: '#586477' }}
                                                            id='cvb-userOnboarding-researcherGoalInput'
                                                        />
                                                        {(researchersGoals['isSubmit'] && !researchersGoals['isValid']) &&
                                                            <ErrorText errorMessage={"Please enter a researchers goals"} />}
                                                    </Grid>
                                                </Grid>}
                                                {zone2Request && <Grid container spacing={4} justifyContent='space-between' className={Classes.signUpFormInputsRow}>
                                                    <Grid item xs={12} className={Classes.textareaContainer}>
                                                        <label id='cvb-userOnboarding-scientificBackgroundLabel' htmlFor="goals">Scientific Background & Area Of Research<sup> *</sup></label>
                                                        <textarea rows={4} fullWidth
                                                            value={scientificBackground?.text}
                                                            onChange={onScientificBackgroundChange}
                                                            placeholder='' name='bio'
                                                            style={{ color: '#586477' }}
                                                            id='cvb-userOnboarding-scientificBackgroundInput'
                                                        />
                                                        {((scientificBackground?.['isSubmit'] || false) && !scientificBackground['isValid']) &&
                                                            <ErrorText errorMessage={"Please enter a scientific background"} />}
                                                    </Grid>
                                                </Grid>}
                                                {zone2Request && <Grid container spacing={4} justifyContent='space-between' className={Classes.signUpFormInputsRow}>
                                                    <Grid item xs={12} className={Classes.textareaContainer}>
                                                        <label id='cvb-userOnboarding-researcherGoalLabel' htmlFor="goals">Researcher goals<sup> *</sup></label>
                                                        <textarea rows={4} fullWidth
                                                            value={researchersGoals?.text}
                                                            onChange={onResearcherGoalsChange}
                                                            placeholder='Please explain the intent goal of your research and provide project summary or abstract .' name='bio'
                                                            style={{ color: '#586477' }}
                                                            id='cvb-userOnboarding-researcherGoalInput'
                                                        />
                                                        {(researchersGoals['isSubmit'] && !researchersGoals['isValid']) &&
                                                            <ErrorText errorMessage={"Please enter a researchers goals"} />}
                                                    </Grid>
                                                </Grid>}

                                            </React.Fragment>

                                        }




                                        {(!props.editProfile || zone2Request) ?
                                            null
                                            :
                                            <Grid container direction='column'>
                                                <Grid item xs={6} className={Classes.updateButtonContainer}>
                                                    <button id='cvb-userOnboarding-cancelButton' onClick={handleCancelButton} className={`no-outlined-button small-size-button ${Classes.cancelButton}`}>
                                                        Cancel
                            </button>
                                                    <button onClick={onSubmitClick} id='cvb-userOnboarding-updateProfileButton'
                                                        disabled={isFormUpdating}
                                                        className={`medium-size-button solid-button ${Classes.updateButton}`}>
                                                        Update Profile
                                        </button>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </React.Fragment>
                        }
                        <Modal open={openEditModel} dialogProps={LAUNCH_WORKSPACE_MODAL_PROPS}>
                            <Avatar
                                width={390}
                                height={295}
                                onCrop={onCrop}
                                onClose={onClose}
                                src={get(profileImage, '[0].preview', showPreview)}
                            />
                        </Modal>
                    </Grid>
                </Grid>
            </Grid>
            {
                (props.editProfile || zone2Request) ? null :
                    <div className={Classes.communityFooter}>
                        <div className={Classes.buttonWrapperClass}>
                            <button disabled={isFormUpdating} id='cvb-userOnboarding-nextButton' onClick={props.onSubmitEdit ? () => { props.onSubmitEdit({ otherOrganizationName: otherOrganizationName}) } : onSubmitClick} type="submit" className={`medium-size-button solid-button ${Classes.nextButton}`} >
                                Next
                    </button>
                        </div>
                    </div>
            }
        </div>
    );
}


const mapStateToProps = state => {
    return {
        firstName: state.userOnboard.firstName,
        lastName: state.userOnboard.lastName,

        title: state.userOnboard.title,
        department: state.userOnboard.department,
        institutionName: state.userOnboard.institutionName,
        city: state.userOnboard.city,
        country: state.userOnboard.country,
        stateName: state.userOnboard.stateName,
        sector: state.userOnboard.sector,
        degree: state.userOnboard.degree,
        contactNumber: state.editProfile.contactNumber,
        streetAddress: state.editProfile.streetAddress,
        zipCode: state.editProfile.zipCode,

        designation: state.userOnboard.designation,
        otherDesignation: state.userOnboard.otherDesignation,
        organization: state.userOnboard.organization,
        bio: state.userOnboard.bio,
        profileImage: state.userOnboard.profileImage,
        tagsInputText: state.userOnboard.tagsInputText,
        keywordList: state.userOnboard.keywordList,
        google: state.userOnboard.google,
        linkedin: state.userOnboard.linkedin,
        github: state.userOnboard.github,
        twitter: state.userOnboard.twitter,
        orchid: state.userOnboard.orchid,
        personal: state.userOnboard.personal,
        proPic: state.global.profilePictureUrl,
        researchersGoals: state.userOnboard.researchersGoals,
        highestEducation: state.userOnboard.highestEducation,
        scientificBackground: state.userOnboard.scientificBackground,
        otherOrganizationName: state.userOnboard.otherOrganizationName,
        otherHighestEducation: state.userOnboard.otherHighestEducation
    }
}

UserOnboard.propTypes = {
    editProfile: PropTypes.bool,
    zone2Request: PropTypes.bool
};

UserOnboard.defaultProps = {
    editProfile: false,
    zone2Request: false
};

const mapDispatchToProps = dispatch => {
    return {
        updateSnackBar: async (message, type) => dispatch(updateSnackBar(message, type)),
        changeStepperCount: async (value) => await dispatch(changeStepperCount(value)),
        setOnboardingFirstName: (value) => dispatch(setOnboardingFirstName(value)),
        setOnboardingTitle: (value) => dispatch(setOnboardingTitle(value)),
        setOnDepartmentChange: (value) => dispatch(setOnDepartmentChange(value)),
        setOnboardingInstitutionName: (value) => dispatch(setOnboardingInstitutionName(value)),
        setOnboardingCity: (value) => dispatch(setOnboardingCity(value)),
        setOnboardingCountry: (value) => dispatch(setOnboardingCountry(value)),
        setOnboardingStateName: (value) => dispatch(setOnboardingStateName(value)),
        setOnboardSector: (value) => dispatch(setOnboardSector(value)),
        setOnboardingDegree: (value) => dispatch(setOnboardingDegree(value)),
        setOnboardingLastName: (value) => dispatch(setOnboardingLastName(value)),
        setUsersDesignation: (value) => dispatch(setUsersDesignation(value)),
        setEditStreetAddress: (value) => dispatch(setEditStreetAddress(value)),
        setEditZipCode: (value) => dispatch(setEditZipCode(value)),
        setUsersOtherDesignation: (value) => dispatch(setUsersOtherDesignation(value)),
        setUsersOrganizationName: (value) => { dispatch(setUsersOrganizationName(value)) },
        setUsersBio: (value) => dispatch(setUsersBio(value)),
        setUsersLinkedinProfile: (value) => dispatch(setUsersLinkedinProfile(value)),
        setUsersTwitterProfile: (value) => dispatch(setUsersTwitterProfile(value)),
        setUsersGithubProfile: (value) => dispatch(setUsersGithubProfile(value)),
        setUsersOrchidProfile: (value) => dispatch(setUsersOrchidProfile(value)),
        setUsersGoogleProfile: (value) => dispatch(setUsersGoogleProfile(value)),
        setUsersPersonalProfile: (value) => dispatch(setUsersPersonalProfile(value)),
        setProfileImageOfUser: (value) => dispatch(setProfileImageOfUser(value)),
        setTagsInputText: (value) => dispatch(setTagsInputText(value)),
        setKeywordList: (value) => dispatch(setKeywordList(value)),
        setTabsValueInEditProfile: (value) => dispatch(setTabsValueInEditProfile(value)),
        clearOnboardForm: (value) => dispatch(clearOnboardForm(value)),
        updateProfilePicture: (value) => dispatch(updateProfilePicture(value)),
        updatePullCount: (value) => dispatch(updatePullCount(value)),
        updateUserDetails: payload => dispatch(updateUserDetails(payload)),
        setEditContactNumber: payload => dispatch(setEditContactNumber(payload)),
        setResearcherGoals: payload => dispatch(setResearcherGoals(payload)),
        setOnboardingEducation: (value) => dispatch(setOnboardingEducation(value)),
        setScientificBackground: payload => dispatch(setScientificBackground(payload)),
        setOtherHighestEducation: payload => dispatch(setOtherHighestEducation(payload)),
        setOtherOrganizationName: payload => dispatch({
            type: "SET_OTHER_ORG_NAME",
            payload: payload
        }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserOnboard);