import { get, isDate, isEmpty, lowerCase, map } from 'lodash';
import React, { useState } from 'react'
import * as moment from 'moment'
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getServiceAgreementDetails } from '../../../../api/workspace-api/workspace-api';
import {getAllCertificatesURLForDownload, getSubmittedDUP, getUserDocumentURLForDownload} from '../../../../api/profile-api';
import { navigate } from '@reach/router';
import { downloadDIFForm } from '../../../../api/dca-form-api';
import { approveWorkspaceAccessRequest, rejectWorkspaceAccessRequest, updateAdvanceComputeAsPaid,  approveZone2AccessRequest, approveZone3AccessRequest, getDataAccessCommitteeFileDownloadUrl } from '../../../../api/admin/admin-dashboard';
import { DIF_FORM_STEPS, INSTITUTE_APPROVAL_PENDING_TEXT } from '../../../../constants/strings';
import Zone2ActionsPopOver from './Zone2ActionsPopOver'
import { sendGAEvents } from '../../../../api/generic-api';
import CommonDatePicker from '../../../commons/common-date-picker';
import { Grid, Table, TableBody } from '@mui/material'
import Modal from '../../../commons/modal'
import classes from './index.module.css'
import DCAFormStepper from '../../dca-form-stepper/DCAFormStepper';
import ErrorText from '../../error-text';
import ViewInstitutionCertificateForm from '../../../controls/view-institution-certificate-form'
import ViewDataForm from '../../../controls/view-data-forms'
import RequestAdvancedComputeDetailsView from '../../../commons/request-advanced-compute-details-view';
import ImageVerticalIcon from '../../../../assets/icons/MoreOptionsVertical.S-dark.svg';
import MuiPopover from '@mui/material/Popover';
import withStyles from '@mui/styles/withStyles';

const Popover = withStyles(theme => ({
  root: {
    overflowY: 'scroll',
  },
  paper: {
    minWidth: 192,
    right: 'auto !important',
    marginTop: '32px !important',
  }
}))(MuiPopover);

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    }
  },
  'innerCell': {
    border: 'none'
  }
});

const PendingApprovalRow = props => {
  const { organizationsList= [], currentData, tabValue, setDifDataSharingAssociatedEmail = () => { }, updateSnackBar = () => { }, getDatasetAccessRequests = () => { }, getZone3AccessRequests = () => { }, getWorkspaceAccessRequest = () => { }, setDifStage = () => { } } = props;
  const [open, setOpen] = useState(false);
  const [viewDataForm, setViewDataForm] = useState(false);
  const [dataPolicyData, setDataPolicyData] = useState({});
  const [viewInstitutionCertificateForm, setViewInstitutionCertificateForm] = useState(false);
  const [institutionCertificateData, setInstitutionCertificateData] = useState({});
  const [openServiceAgreementModal, setOpenServiceAgreementModal] = useState(false);
  const [openZone2ApproveModal, setOpenZone2ApproveModal] = useState(false);
  const [currentZone2RequestData, setCurrentZone2RequestData] = useState({});
  const [openZone3ApproveModal, setOpenZone3ApproveModal] = useState(false);
  const [currentZone3RequestData, setCurrentZone3RequestData] = useState({});
  const [openWorkspaceApproveModal, setOpenWorkspaceApproveModal] = useState(false);
  const [currentWorkspaceAccessRequestData, setCurrentWorkspaceAccessRequestData] = useState({});
  const [expiryDateError, setExpiryDateError] = useState(false);
  const [zone2RequestExpiryDate, setZone2RequestExpiryDate] = useState('');
  const [isFormUpdating, setIsFormUpdating] = useState(false);
  const [WorkspaceRequestExpiryDate, setWorkspaceRequestExpiryDate] = useState('');
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);
  const [zone3RequestExpiryDate, setZone3RequestExpiryDate] = useState('');
  const [workspaceDetails, setWorkspaceDetails] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [zone2Type, setZone2Type] = useState('');
  const [openWorkspaceRejectModal, setOpenWorkspaceRejectModal] = useState(false);
  const [workspaceRequestAnchorEl, setWorkspaceRequestAnchorEl] = useState(null);
  const [paidModal, setPaidModal] = useState(false)

  const calenderOpenCloseClass = isCalenderOpen ? classes.calenderOpenClass : classes.calenderCloseClass;

  const DATA_ACCESS_MODAL = {
    modalTitle: "Data Use Agreement",
    modalContent: "",
  }
  const SERVICE_AGREEMENT_MODAL = {
    modalTitle: "Proposal",
    modalContent: "",

  }


  const toggleDataAccessModal = () => {
    setViewDataForm(false);
  }

  const onWorkspaceRequestApprove = (requestData) => {
    setOpenWorkspaceApproveModal(true);
    setCurrentWorkspaceAccessRequestData(requestData);
  }
  const onRejectWorkspaceRequest = (requestData) => {
    setOpenWorkspaceRejectModal(true);
    setCurrentWorkspaceAccessRequestData(requestData);
  }
  const closeWorkspaceRejectModal= () => {
    setOpenWorkspaceRejectModal(false);
    setCurrentWorkspaceAccessRequestData({});
  }

  const onZone2RequestApprove = (requestData) => {
    setOpenZone2ApproveModal(true);
    setCurrentZone2RequestData(requestData);
  }

  const onZone3RequestApprove = (requestData) => {
    setOpenZone3ApproveModal(true);
    setCurrentZone3RequestData(requestData);
  }

  const onOpenServiceAgreementModalClick = async (requestData) => {
    try {
      let workspaceResponse = await getServiceAgreementDetails(requestData?.user_sub, requestData?.request_id);
      setWorkspaceDetails(workspaceResponse);
      setOpenServiceAgreementModal(true);
    } catch (error) {
      console.log(error)
    }
  }


  const getCurrentRequestTime = (requestData, dacSubmittedDate = false) => {
    let dayStr = '';
    let timeStr = '';
    switch (props.tabValue) {
      case 'dca': {
        const currentStage = get(requestData, 'stage', '');
        if (currentStage === 'Completed') {
          const reqCompletedDate = get(requestData, 'requestCompletionDate', '');
          const reqDateMomentObj = moment(reqCompletedDate);
          if (moment.isMoment(reqDateMomentObj)) {
            dayStr = reqDateMomentObj.format('MM/DD/YYYY');
            timeStr = reqDateMomentObj.format('hh:mm A');
          }
        }
        else {
          const lastUpdateDate = get(requestData, 'lastUpdatedDate', '');
          const reqDateMomentObj = moment(lastUpdateDate);
          if (moment.isMoment(reqDateMomentObj)) {
            dayStr = reqDateMomentObj.format('MM/DD/YYYY');
            timeStr = reqDateMomentObj.format('hh:mm A');
          }
        }
        return <span id='cvb-adminDashboard-dcaRequests-dcaSubmittedTime'>{dayStr} at {timeStr}</span>
      }
      case 'dif': {
        const requestSubmittedDate = get(requestData, 'updated_timestamp', '');
        const reqDateMomentObj = moment(requestSubmittedDate);
        if (moment.isMoment(reqDateMomentObj)) {
          dayStr = reqDateMomentObj.format('MM/DD/YYYY');
          timeStr = reqDateMomentObj.format('hh:mm A');
        }
        return <span id='cvb-adminDashboard-difRequests-submittedTime'>{dayStr} at {timeStr}</span>
      }
      case 'zone2': {
        let requestSubmittedDate = '';
        if(dacSubmittedDate){
          requestSubmittedDate = get(requestData, 'approved_at', '');
        }else{
          requestSubmittedDate = get(requestData, 'request_submitted_time', '');
        }
        if(!isEmpty(requestSubmittedDate)){
          const reqDateMomentObj = moment(requestSubmittedDate);

          if (moment.isMoment(reqDateMomentObj)) {
            dayStr = reqDateMomentObj.format('MM/DD/YYYY');
            timeStr = reqDateMomentObj.format('hh:mm A');
          }
          return <span id='cvb-adminDashboard-zone2Requests-submittedTime'>{dayStr} at {timeStr}</span>
        }else{
          return '-'
        }
      }
      case 'zone3': {
        const requestSubmittedDate = get(requestData, 'request_submitted_time', '');
        const reqDateMomentObj = moment(requestSubmittedDate);
        if (moment.isMoment(reqDateMomentObj)) {
          dayStr = reqDateMomentObj.format('MM/DD/YYYY');
          timeStr = reqDateMomentObj.format('hh:mm A');
        }
        return <span id='cvb-adminDashboard-zone2Requests-submittedTime'>{dayStr} at {timeStr}</span>
      }
      case 'workspace': {
        const requestSubmittedDate = get(requestData, 'request_submitted_time', '');
        const reqDateMomentObj = moment.utc(requestSubmittedDate);
        if (moment.isMoment(reqDateMomentObj)) {
          dayStr = reqDateMomentObj?.local().format('MM/DD/YYYY');
          timeStr = reqDateMomentObj?.local().format('hh:mm A');
        }
        return <span id='cvb-adminDashboard-wsAccessRequest-submittedTime'>{dayStr} at {timeStr}</span>
      }
    }
  }

  const getCurrentRequestTitle = (requestData) => {
    switch (props.tabValue) {
      case 'dca': return <h6 id='cvb-adminDashboard-dcaRequests-dcaHeading'>Data Contribution Agreement</h6>
      case 'dif': return <h6 id='cvb-adminDashboard-difRequests-difHeading'>Data Inventory Form</h6>
      case 'zone2': return <h6 id='cvb-adminDashboard-zone2Requests-reqZone2Access-heading'>Requested Zone 2 Access</h6>
      case 'zone3': {
        const projectId = get(requestData, 'project_id', '');
        return <h6 id='cvb-adminDashboard-zone2Requests-reqZone3Access-heading'>Requested Zone 3 Access <span style={{ textTransform: 'none' }}>for {projectId}</span></h6>
      }
      case 'workspace': return <h6 id='cvb-adminDashboard-wsAccessRequest-reqAdvComputeHeader'>Requested Advanced Compute</h6>
    }
  }

  const getCurrentRequestStatus = requestData => {
    switch (props.tabValue) {
      case 'dca': {
        const requestStatus = get(requestData, 'requestStatus', '');
        return <p id='cvb-adminDashboard-dcaRequests-dcaRequestStatus' className={classes.pendingApproval}>
          {requestStatus}
        </p>
      }
      case 'dif': {
        const requestStatus = get(requestData, 'status', '');
        return <p id='cvb-adminDashboard-difRequests-requestStatus' className={classes.pendingApproval}>
          {requestStatus}
        </p>
      }
      case 'zone2': {
        const requestStatus = get(requestData, 'request_status', '');
        const requestStatusLabel = requestStatus.replaceAll("_", " ");
        return <>
          <div>
            <p id='cvb-adminDashboard-zone2Requests-requestStatus' className={classes.pendingApproval}>
              {requestStatusLabel}
            </p>
          </div>
        </>
      }
      case 'zone3': {
        const requestStatus = get(requestData, 'request_status', '');
        const requestStatusLabel = requestStatus.replaceAll("_", " ");
        return <>
          <div>
            <p id='cvb-adminDashboard-zone3Requests-requestStatus' className={classes.pendingApproval}>
              {requestStatusLabel}
            </p>
          </div>
        </>
      }
      case 'workspace': {
        const requestStatus = get(requestData, 'request_status', '');
        const requestStatusLabel = requestStatus.replaceAll("_", " ");
        return <>
          <div>
            {!(lowerCase(requestStatus) === 'completed' || lowerCase(requestStatus) === 'approved')
              ? <p id='cvb-adminDashboard-wsAccessRequest-pendingRequestStatus' className={classes.pendingApproval}>
                {requestStatusLabel}
              </p>
              : <p id='cvb-adminDashboard-wsAccessRequest-authRequestStatus' className={classes.authorizedAccessText}>
                {requestStatusLabel}
              </p>
            }
          </div>
        </>
      }

    }
  }

  const getPolicyData = async (requestData) => {
    try {
      setViewDataForm(true);
      const userEmail = get(requestData, 'user_sub', '');
      let response = await getSubmittedDUP(userEmail);
      response = getDateTimeObject(response);
      setDataPolicyData(response);
    }
    catch (error) {
      console.log(error)
    }
  }

  const getDateTimeObject = (response) => {
    let dayStr = '';
    let timeStr = '';
    let requestSubmittedDate = get(response, 'requestedDate', '');
    if (!requestSubmittedDate) {
      requestSubmittedDate = get(response, 'request_submitted_time', '')
    }
    const reqDateMomentObj = moment(requestSubmittedDate);
    if (moment.isMoment(reqDateMomentObj)) {
      dayStr = reqDateMomentObj.format('MM/DD/YYYY');
      timeStr = reqDateMomentObj.format('hh:mm A');
    }
    response.dayStr = dayStr;
    response.timeStr = timeStr;
    return response;
  }

  const onDocumentDownload = async (usersSub, type) => {
    try {
      const res = await getUserDocumentURLForDownload(usersSub, type);
      window.location.href = res?.document_url;
    } catch (error) {
      updateSnackBar('Error in downloading the document', 'error')
    }
  }

  const onViewCertificateClick = (requestData) => {
    const userSub = get(requestData, 'user_sub', '');
    navigate(`admin/user-profile/${userSub}#certificates`, { state: { adminDashboard: true } })
  }

  const onDownloadAllCertificates = async (usersSub) => {
    try {
      const res = await getAllCertificatesURLForDownload(usersSub);
      window.location.href = res?.url;
    } catch (error) {
      updateSnackBar('Error in downloading the document', 'error')
    }
  }

  const getInstitutionCertificateData = async (requestData) => {
    try {
      setViewInstitutionCertificateForm(true);
      const userEmail = get(requestData, 'user_sub', '');
      let response = await getSubmittedDUP(userEmail);
      response = getDateTimeObject(response);
      setInstitutionCertificateData(response);
    }
    catch (error) {
      console.log(error)
    }
  }

  const onDIFEditButtonClick = (requestData, index) => {
    const requestID = get(requestData, 'request_id', '')
    const currentStage = get(requestData, 'stage', '');
    const requesterEmail = get(requestData, 'submitter_email', '');
    const requesterSub = get(requestData, 'user_sub', '');
    if (!isEmpty(currentStage) || currentStage !== null) {
      setDifStage(index);
    } else {
      setDifStage(1);
    }
    setDifDataSharingAssociatedEmail(requesterEmail);
    navigate(`/dashboard/data-inventory-form/${requestID}/${requesterSub}`, { state: { dashboard: true, requesterSub: requesterSub, requesterEmail: requesterEmail } });
  }

  const onDownloadDataAccessCommitteeForm = async (userSub) => {
    try{
      const res = await getDataAccessCommitteeFileDownloadUrl(userSub);
      if(res){
        if (res?.url) {
          window.location.href = res?.url;
        }
      }
    }catch(e){
      console.log(e)
    }
  }

  const onDIFDownload = async (userSub, dif) => {
    let requests = await downloadDIFForm(userSub, dif);
    if (!isEmpty(requests) && requests?.document_url) {
      window.location.href = requests?.document_url;
    }
  }
  const closeApproveWorkspaceRequestModal = () => {
    setOpenWorkspaceApproveModal(false);
    setExpiryDateError(false);
    setWorkspaceRequestExpiryDate('');
  }

  const onApproveZone3RequestModalConfirm = async () => {
    try {
      if (isDate(zone3RequestExpiryDate)) {
        sendGAEvents('approve_zone3_request', 'zone3_request_approval', 'zone 3 request approve');
        setExpiryDateError();
        const expiryDate = moment(zone3RequestExpiryDate).format('YYYY-MM-DD');
        const body = {
          'user_sub': get(currentZone3RequestData, 'user_sub', ''),
          'zone3_request_id': get(currentZone3RequestData, 'request_id', ''),
          'project_id': get(currentZone3RequestData, 'project_id', ''),
          'expiry_date': expiryDate
        };
        setIsFormUpdating(true);
        const res = await approveZone3AccessRequest(body);
        if (res) {
          updateSnackBar(res?.message, 'success');
          setWorkspaceRequestExpiryDate('');
          setOpenZone3ApproveModal(false);
          setZone3RequestExpiryDate('');
          await getZone3AccessRequests();
        } else {
          updateSnackBar('Error in approving Zone 3 request', 'error');
          setOpenZone3ApproveModal(false);
        }
      } else {
        setExpiryDateError(true);
      }
    }
    catch (error) {
      updateSnackBar('Error in approving Zone 3 request', 'error');
      setOpenZone3ApproveModal(false);
    } finally {
      setIsFormUpdating(false);
    }
  }

  const getCurrentStep = (requestData) => {
    const userSub = requestData?.user_sub;
    const requestStatus = get(requestData, 'request_status', '');
    switch (tabValue) {
      case 'dca':
        return <DCAFormStepper hideDownload={!props.userDetails?.resourceActions?.dashboard?.download_data_contribution_agreement} requestData={requestData} dashboard />
      case 'workspace':
        return <TableRow style={{ padding: 0 }} className={classes.innerRow}>
          <TableCell width="5%" />
          <TableCell width="20%" />
          <TableCell width="25%"><span className={classes.formStepTitle}>Proposal</span></TableCell>
          <TableCell width="15%">
            {getCurrentRequestTime(currentData)}
          </TableCell>
          <TableCell width="10%" align="center"><span className={classes.submitText}>Submitted</span></TableCell>
          <TableCell width="25%" align="center">
            <button style={{ marginRight: '10px' }} onClick={() => onOpenServiceAgreementModalClick(requestData)} className={classes.approveRequestButton}>
              View
            </button>
          </TableCell>
        </TableRow>
      case 'zone2': return <>
          <TableRow style={{ padding: 0 }} className={classes.innerRowNotLast}>
            <TableCell width="5%" />
            <TableCell width="20%" />
            <TableCell width="25%"><span className={classes.formStepTitle}>Data Use Agreement</span></TableCell>
            <TableCell width="15%">
              {getCurrentRequestTime(currentData)}
            </TableCell>
            <TableCell width="10%" align="center"><span className={classes.submitText}>Submitted</span></TableCell>
            <TableCell width="25%" align="center">
              <button style={{ marginRight: '10px' }} onClick={() => getPolicyData(requestData)} className={classes.approveRequestButton}>
                View
              </button>
              <button onClick={() => onDocumentDownload(userSub, 'data_use_policy')} className={classes.approveRequestButton}>
                Download
              </button>
            </TableCell>
          </TableRow>
          <TableRow style={{ padding: 0 }} className={classes.innerRowNotLast}>
            <TableCell width="5%" />
            <TableCell width="20%" />
            <TableCell width="25%"><span className={classes.formStepTitle}>Institutional Certificates</span></TableCell>
            <TableCell width="15%">
              {getCurrentRequestTime(currentData)}
            </TableCell>
            <TableCell width="10%" align="center"><span className={classes.submitText}>Submitted</span></TableCell>
            <TableCell width="25%" align="center">
              <button style={{ marginRight: '10px' }} onClick={() => getInstitutionCertificateData(requestData)} className={classes.approveRequestButton}>
                View
              </button>
              <button onClick={() => onDocumentDownload(userSub, 'data_use_policy')} className={classes.approveRequestButton}>
                Download
              </button>
            </TableCell>
          </TableRow>
          <TableRow style={{ padding: 0 }} className={classes.innerRowNotLast}>
            <TableCell width="5%" />
            <TableCell width="20%" />
            <TableCell width="25%"><span className={classes.formStepTitle}>Training Certificate</span></TableCell>
            <TableCell width="15%">
              {getCurrentRequestTime(currentData)}
            </TableCell>
            <TableCell width="10%" align="center"><span className={classes.submitText}>Submitted</span></TableCell>
            <TableCell width="25%" align="center">
              <button style={{ marginRight: '10px' }} onClick={() => onViewCertificateClick(requestData)} className={classes.approveRequestButton}>
                View
              </button>
              <button onClick={() => onDownloadAllCertificates(userSub)} className={classes.approveRequestButton}>
                Download
              </button>
            </TableCell>
          </TableRow>
          { (requestStatus === 'APPROVED' || requestStatus=== 'REJECTED') && <TableRow style={{ padding: 0 }} className={classes.innerRow}>
            <TableCell width="5%" />
            <TableCell width="20%" />
            <TableCell width="25%"><span className={classes.formStepTitle}>Data Access Committee Form</span></TableCell>
            <TableCell width="15%">
            {getCurrentRequestTime(currentData, true)}
            </TableCell>
            <TableCell width="10%" align="center"><span className={classes.submitText}>Submitted</span></TableCell>
            <TableCell width="25%" align="center">
              <button style={{ marginRight: '0px' }} onClick={() => onDownloadDataAccessCommitteeForm(userSub)} className={classes.approveRequestButton}>
              Download
              </button>
              <button style={{ marginRight: '10px', visibility: 'hidden' }} onClick={() => getInstitutionCertificateData(requestData)} className={classes.approveRequestButton}>
                View
              </button>
            </TableCell>
          </TableRow>}
        </>
      case 'zone3':
        return <>
          <TableRow style={{ padding: 0 }} className={classes.innerRowNotLast}>
            <TableCell width="5%" />
            <TableCell width="20%" />
            <TableCell width="25%"><span className={classes.formStepTitle}>Data Use Agreement</span></TableCell>
            <TableCell width="15%">
              {getCurrentRequestTime(currentData)}
            </TableCell>
            <TableCell width="10%" align="center"><span className={classes.submitText}>Submitted</span></TableCell>
            <TableCell width="25%" align="center">
              <button style={{ marginRight: '10px' }} onClick={() => getPolicyData(requestData)} className={classes.approveRequestButton}>
                View
              </button>
              <button onClick={() => onDocumentDownload(userSub, 'data_use_policy')} className={classes.approveRequestButton}>
                Download
              </button>
            </TableCell>
          </TableRow>
          <TableRow style={{ padding: 0 }} className={classes.innerRowNotLast}>
            <TableCell width="5%" />
            <TableCell width="20%" />
            <TableCell width="25%"><span className={classes.formStepTitle}>Institutional Certificates</span></TableCell>
            <TableCell width="15%">
              {getCurrentRequestTime(currentData)}
            </TableCell>
            <TableCell width="10%" align="center"><span className={classes.submitText}>Submitted</span></TableCell>
            <TableCell width="25%" align="center">
              <button style={{ marginRight: '10px' }} onClick={() => getInstitutionCertificateData(requestData)} className={classes.approveRequestButton}>
                View
              </button>
              <button onClick={() => onDocumentDownload(userSub, 'data_use_policy')} className={classes.approveRequestButton}>
                Download
              </button>
            </TableCell>
          </TableRow>
          <TableRow style={{ padding: 0 }} className={classes.innerRow}>
            <TableCell width="5%" />
            <TableCell width="20%" />
            <TableCell width="25%"><span className={classes.formStepTitle}>Certificates</span></TableCell>
            <TableCell width="15%">
              {getCurrentRequestTime(currentData)}
            </TableCell>
            <TableCell width="10%" align="center"><span className={classes.submitText}>Submitted</span></TableCell>
            <TableCell width="25%" align="center">
              <button style={{ marginRight: '10px' }} onClick={() => onViewCertificateClick(requestData)} className={classes.approveRequestButton}>
                View
              </button>
              <button onClick={() => onDocumentDownload(userSub, 'data_use_policy')} className={classes.approveRequestButton}>
                Download
              </button>
            </TableCell>
          </TableRow>
        </>
      case 'dif': return map(DIF_FORM_STEPS, (steps, index) => {
        const requestID = get(requestData, 'request_id', '');
        const userSub = get(requestData, 'user_sub', '');

        return <TableRow style={{ padding: 0 }} className={DIF_FORM_STEPS.length > index + 1 ? classes.innerRowNotLast : classes.innerRow}>
          <TableCell width="32px" />
          <TableCell width="20%" />
          <TableCell width="25%"><span className={classes.formStepTitle}>{steps}</span></TableCell>
          <TableCell width="15%">
            {getCurrentRequestTime(currentData)}
          </TableCell>
          <TableCell width="10%" align="center"><span className={classes.submitText}>Submitted</span></TableCell>
          <TableCell width="25%" align="center">
            <button style={{ marginRight: '10px' }} onClick={() => onDIFEditButtonClick(requestData, index + 1)} className={classes.approveRequestButton}>
              View
            </button>
            {props.userDetails?.resourceActions?.dashboard?.download_data_inventory_form &&
              <button onClick={() => onDIFDownload(userSub, requestID)} className={classes.approveRequestButton}>
                Download
              </button>}
          </TableCell>
        </TableRow>
      })
    }
  }
  const closeApproveZone3RequestModal = () => {
    setOpenZone3ApproveModal(false);
    setExpiryDateError(false);
    setZone3RequestExpiryDate('');
  }

  const onApproveWorkspaceRequestModalConfirm = async () => {
    try {
      setWorkspaceRequestAnchorEl(null);
      let isValidExpiryDate = false;
      if (!isDate(WorkspaceRequestExpiryDate)) {
        setExpiryDateError(true);
        isValidExpiryDate = false;
      } else {
        isValidExpiryDate = true;
        setExpiryDateError(false);
      }
       if (!(isValidExpiryDate)) {
        return
      }
      setIsFormUpdating(true);
      sendGAEvents('approve_workspace_request', 'workspace_request_approval', 'workspace request approve');
      setExpiryDateError(false);
      const expiryDate = moment(WorkspaceRequestExpiryDate).format('YYYY-MM-DD');
      const body = {
        'user_sub': get(currentWorkspaceAccessRequestData, 'user_sub', ''),
        'request_id': get(currentWorkspaceAccessRequestData, 'request_id', ''),
        'expiry_date': expiryDate,
      };
      const res = await approveWorkspaceAccessRequest(body);
      if (res) {
        setOpenWorkspaceApproveModal(false);
        updateSnackBar(res?.message, 'success');
        setWorkspaceRequestExpiryDate('');
        setZone2RequestExpiryDate('');
        await getWorkspaceAccessRequest();
      } else {
        setOpenWorkspaceApproveModal(false);
        updateSnackBar('Error in approving workspace request', 'error');
      }
    } catch (error) {
      setOpenWorkspaceApproveModal(false);
      updateSnackBar('Error in approving workspace request', 'error');
    } finally {
      setIsFormUpdating(false);
    }
  }
  const onRejectWorkspaceRequestConfirm = async ()=>{
    //API CALL
    try{
      setWorkspaceRequestAnchorEl(null);
      setIsFormUpdating(true);
      const body = {
        'user_sub': get(currentWorkspaceAccessRequestData, 'user_sub', ''),
        'request_id': get(currentWorkspaceAccessRequestData, 'request_id', ''),
      };
      const res = await rejectWorkspaceAccessRequest(body);
      if(res){
        setCurrentWorkspaceAccessRequestData({});
        setOpenWorkspaceRejectModal(false)
        updateSnackBar(res?.message || 'Workspace request rejected successfully.' , 'success');
        await getWorkspaceAccessRequest();
      }else {
        setOpenWorkspaceRejectModal(false);
        updateSnackBar('Error in rejecting workspace request', 'error');
      }
   
    }catch(e){
      setOpenWorkspaceRejectModal(false);
      updateSnackBar('Error in rejecting workspace request', 'error');
      console.log(e);
    }finally{
      setIsFormUpdating(false);
    }
  }

  const onPaidButtonClick = (requestData) =>{
    setPaidModal(true);
    setCurrentWorkspaceAccessRequestData(requestData)
  }
  const closePaidModal = ()=>{
    setPaidModal(false);
    setCurrentWorkspaceAccessRequestData({})
  }
  const onPaidModalConfirm = async () => {
    //API CALL
    try {
      setIsFormUpdating(true);
      setWorkspaceRequestAnchorEl(null);
      const user_sub = get(currentWorkspaceAccessRequestData, 'user_sub', '');
      const request_id = get(currentWorkspaceAccessRequestData, 'request_id', '');
      const res = await updateAdvanceComputeAsPaid(user_sub, request_id);
      if (res) {
        updateSnackBar(res?.message || 'Updated advance compute request to Paid successfully.', 'success');
        setCurrentWorkspaceAccessRequestData({});
        setPaidModal(false)
        await getWorkspaceAccessRequest();
      }else {
        setPaidModal(false);
        updateSnackBar('Error in updating advance compute request to paid', 'error');
      }
    } catch (e) {
      console.log(e);
        setPaidModal(false);
        updateSnackBar('Error in updating advance compute request to paid', 'error');
    }finally{
      setIsFormUpdating(false);
    }
  }
  
  const onWorkspaceRequestExpiryChange = value => {
    setWorkspaceRequestExpiryDate(value);
    setExpiryDateError(false);
  }

  const onZone3RequestExpiryChange = value => {
    setZone3RequestExpiryDate(value);
    setExpiryDateError(false);
  }

  const closeApproveZone2RequestModal = () => {
    setOpenZone2ApproveModal(false);
    setExpiryDateError(false);
    setZone2RequestExpiryDate('');
  }

  const toggleServiceAgreementModal = () => {
    setOpenServiceAgreementModal(false);
  }

  const onApproveZone2RequestModalConfirm = async () => {
    try {
      sendGAEvents('approve_zone2_request', 'zone2_request_approval', 'zone 2 request approve');
      setIsFormSubmitted(true);
      if (isEmpty(zone2Type)) {
        if (!isDate(zone2RequestExpiryDate)) {
          setExpiryDateError(true);
        }
        return;
      }
      if (isDate(zone2RequestExpiryDate)) {
        setExpiryDateError();
        const expiryDate = moment(zone2RequestExpiryDate).format('YYYY-MM-DD');
        const body = {
          'request_id': get(currentZone2RequestData, 'request_id'),
          'user_sub': get(currentZone2RequestData, 'user_sub', ''),
          'zone2_type': zone2Type,
          'expiry_date': expiryDate
        };
        setIsFormUpdating(true);
        const res = await approveZone2AccessRequest(body);
        if (res) {
          updateSnackBar(res?.message, 'success');
          setWorkspaceRequestExpiryDate('');
          setZone2RequestExpiryDate('');
          setOpenZone2ApproveModal(false);
          await getDatasetAccessRequests();
        } else {
          updateSnackBar('Error in approving Zone 2 request', 'error');
          setOpenZone2ApproveModal(false);
        }
      } else {
        setExpiryDateError(true);
      }
    }
    catch (error) {
      updateSnackBar('Error in approving Zone 2 request', 'error');
      setOpenZone2ApproveModal(false);
    } finally {
      setIsFormUpdating(false);
    }
  }

  const onZone2TypeSelection = (type) => {
    setZone2Type(type)
  }

  const onZone2RequestExpiryChange = value => {
    setZone2RequestExpiryDate(value);
    setExpiryDateError(false);
  }
  const openWorkspacePopover = !!workspaceRequestAnchorEl;

  const getApproveBtn = requestData => {
    switch (props.tabValue) {
      case 'zone2': {
        const requestStatus = get(requestData, 'request_status', '');
        const requestStatusLabel = requestStatus.replaceAll("_", " ");
        return <>
          {(lowerCase(requestStatus) !== 'approved') && (requestStatus !== "PENDING_FOR_CERTIFICATION_APPROVAL") && props.userDetails?.resourceActions?.dashboard?.approve_zone2_access_requests &&
            <div>
              <button id='cvb-adminDashboard-zone2Requests-approveButton' onClick={() => onZone2RequestApprove(requestData)} className={classes.approveRequestButton}>
                Approve
              </button>
            </div>
          }
        </>
      }
      case 'zone3': {
        const requestStatus = get(requestData, 'request_status', '');
        return <>
          {(lowerCase(requestStatus) !== 'approved') && (requestStatus !== "PENDING_FOR_CERTIFICATION_APPROVAL") && props.userDetails?.resourceActions?.dashboard?.approve_zone3_access_requests && <div>
            <button id='cvb-adminDashboard-zone3Requests-approveButton' onClick={() => onZone3RequestApprove(requestData)} className={classes.approveRequestButton}>
              Approve
            </button>
          </div>}
        </>
      }
      case 'workspace': {
        const requestStatus = get(requestData, 'request_status', '');
        
        return <>
          <div>
            {!(lowerCase(requestStatus) === 'completed' || lowerCase(requestStatus) === 'approved' || lowerCase(requestStatus) === 'invoice paid' || lowerCase(requestStatus) === lowerCase(INSTITUTE_APPROVAL_PENDING_TEXT)) && props.userDetails?.resourceActions?.dashboard?.approve_advance_compute_requests
              ? 
              (lowerCase(requestStatus) !== 'rejected' && lowerCase(requestStatus) !== 'invoice paid') ?
                <div style={{position: 'relative'}}>
                <img
                  src={ImageVerticalIcon}
                  alt='verticalIcon'
                  className={classes.wsRequestRightIconImage}
                  onClick={(event) => setWorkspaceRequestAnchorEl(event.currentTarget)} />
                <Popover
                  open={openWorkspacePopover}
                  elevation={3}
                  anchorEl={workspaceRequestAnchorEl}
                  onClose={()=> setWorkspaceRequestAnchorEl(null)}
                  anchorOrigin={{
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    horizontal: 'right',
                  }}
                >
                  <ul className={classes.dropdownListContainer}>
                    {lowerCase(requestStatus) === 'submitted' && 
                    <>
                    <li onClick={() => onWorkspaceRequestApprove(requestData)}>
                      Approve
                    </li>
                    <li onClick={() => onRejectWorkspaceRequest(requestData)}>
                      Reject
                    </li>
                    </>
                    }
                    { lowerCase(requestStatus) === 'pending payment' && 
                       <li onClick={() => onPaidButtonClick(requestData)}>
                       Paid
                     </li>

                    }
                  </ul>

                </Popover>
              </div> : ''
              : ''
            }
          </div>
        </>
      }
    }
  }

  const APPROVE_ZONE3_REQUEST_MODAL_PROPS = {
    modalTitle: 'Are you sure you want to approve this Zone 3 request?',
    positiveButtonText: 'Approve',
    negativeButtonText: "Cancel",
    positiveButtonAction: onApproveZone3RequestModalConfirm,
    negativeButtonAction: closeApproveZone3RequestModal,
    popupButtonDisable: isFormUpdating
  }
  const APPROVE_WORKSPACE_REQUEST_MODAL_PROPS = {
    modalTitle: 'Are you sure you want to approve this advanced compute request?',
    positiveButtonText: 'Approve',
    negativeButtonText: "Cancel",
    positiveButtonAction: onApproveWorkspaceRequestModalConfirm,
    negativeButtonAction: closeApproveWorkspaceRequestModal,
    popupButtonDisable: isFormUpdating
  }

  const APPROVE_ZONE2_REQUEST_MODAL_PROPS = {
    modalTitle: 'Are you sure you want to approve this Zone 2 request?',
    positiveButtonText: 'Approve',
    negativeButtonText: "Cancel",
    positiveButtonAction: onApproveZone2RequestModalConfirm,
    negativeButtonAction: closeApproveZone2RequestModal,
    popupButtonDisable: isFormUpdating
  }
  const REJECT_WORKSPACE_REQUEST_MODAL_PROPS = {
    modalTitle: 'Are you sure you want to reject this advanced compute request?',
    positiveButtonText: 'Reject',
    negativeButtonText: "Cancel",
    positiveButtonAction: onRejectWorkspaceRequestConfirm,
    negativeButtonAction: closeWorkspaceRejectModal,
    popupButtonDisable: isFormUpdating
  }
  const PAID_WORKSPACE_REQUEST_MODAL_PROPS = {
    modalTitle: `Update status of advance compute request to 'Paid'?`,
    positiveButtonText: 'Update Status',
    negativeButtonText: "Cancel",
    positiveButtonAction: onPaidModalConfirm,
    negativeButtonAction: closePaidModal,
    popupButtonDisable: isFormUpdating
  }

  return (
    <>
      <TableRow className={classes.root} >
        <TableCell width="5%" align="center" className={classes.leftBorder}>
          <div className={classes.icon}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </div>
        </TableCell >
        <TableCell width="20%" className={classes.bottomBorder}>
          {currentData?.first_name} {currentData?.last_name}
        </TableCell>
        <TableCell width="25%" align="left" className={classes.bottomBorder}>{getCurrentRequestTitle(currentData)}</TableCell>
        <TableCell width="15%" align="left" className={classes.bottomBorder}>{getCurrentRequestTime(currentData)}</TableCell>
        <TableCell width="10%" align="left" className={classes.bottomBorder}>{getCurrentRequestStatus(currentData)}</TableCell>
        {
          props.tabValue !== 'zone2' &&
          <TableCell width="25%" className={classes.rightBorder} align="center">{getApproveBtn(currentData)}</TableCell>
        }
        {
          (props.tabValue === 'zone2' & props.userDetails?.resourceActions?.dashboard?.approve_zone2_access_requests) ?
          <>
            <TableCell width="25%" align="right" className={classes.rightBorder}>
              {
                props.currentData?.request_status?.toLowerCase() !== "approved" && 
                props.currentData?.request_status?.toLowerCase() !== "rejected" &&
                <Zone2ActionsPopOver
                  currentData={currentData}
                  updateSnackBar={props.updateSnackBar}
                />
              }

            </TableCell>
          </> 
          : (props.tabValue === 'zone2' & !props.userDetails?.resourceActions?.dashboard?.approve_zone2_access_requests
          ) ? <TableCell width="25%" align="right" className={classes.rightBorder}/> : null
        }
      </TableRow>
      <TableRow className={classes.innerRowWrap}>
        <TableCell style={{ padding: 0, border: 'none' }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table>
                <TableBody>
                  {getCurrentStep(currentData)}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={paidModal}
        handleClose={closePaidModal}
        dialogProps={PAID_WORKSPACE_REQUEST_MODAL_PROPS}
      >
       <p className={classes.paidModalContent}>Requestor will be informed about the next steps.</p>
      </Modal>
      <Modal
        open={openWorkspaceRejectModal}
        handleClose={closeWorkspaceRejectModal}
        dialogProps={REJECT_WORKSPACE_REQUEST_MODAL_PROPS}
      >
      </Modal>

      <Modal
        open={openWorkspaceApproveModal}
        handleClose={closeApproveWorkspaceRequestModal}
        dialogProps={APPROVE_WORKSPACE_REQUEST_MODAL_PROPS}
      >
        <p className={classes.aoDefinitionText}>Authorizing Official: This means an executive, officer or other individual who is authorized to sign contracts on behalf of your organization or company. You can find out who that is by contacting the office of your legal counsel or chief executive. </p>
        <div className={`${classes.datePickerWrapper} ${calenderOpenCloseClass}`}>
          <label id='cvb-adminDashboard-approveWSModal-expiryDateTitle'>Expiry Date<sup> *</sup></label>
          <Grid item xs={6}>
            <CommonDatePicker
              onCalenderDateSelected={onWorkspaceRequestExpiryChange}
              minDate={moment().add(1, 'd').toDate()}
              date={WorkspaceRequestExpiryDate}
              showValidationError={expiryDateError}
              errorMessage='Please select expiry date'
              id='cvb-adminDashboard-approveWSModal-inputDate'
              isFromModal
              canClearInput
            />
          </Grid>
        </div>
      </Modal>

      <Modal
        open={openZone3ApproveModal}
        handleClose={closeApproveZone3RequestModal}
        dialogProps={APPROVE_ZONE3_REQUEST_MODAL_PROPS}
      >
        <div className={`${classes.datePickerWrapper} ${calenderOpenCloseClass}`}>
          <label id='cvb-adminDashboard-approveZone2Modal-expiryDateLabel'>Expiry Date : </label>
          <Grid item xs={6}>
            <CommonDatePicker
              onCalenderDateSelected={onZone3RequestExpiryChange}
              minDate={moment().add(1, 'd').toDate()}
              date={zone3RequestExpiryDate}
              showValidationError={expiryDateError}
              errorMessage='Please select expiry date'
              id='cvb-adminDashboard-approveZone3Modal-inputDate'
              isFromModal
              canClearInput
            />
          </Grid>
        </div>
      </Modal>
      <Modal open={viewDataForm} handleClose={toggleDataAccessModal} dialogProps={DATA_ACCESS_MODAL} disableTitleDivider disableBottomHorizontalDivider >
        <div className={[classes.contentStartText, classes.showHeader].join(' ')}>
          <p>
            <span className={classes.submitDetails} ><p id='cvb-adminDashboard-viewDUP-submittedTime' className={classes.submitText}>Submitted</p> on {dataPolicyData?.dayStr} at {dataPolicyData?.timeStr}</span>
            <p id='cvb-adminDashboard-viewDUP-researcherName' className={classes.submitName}>By {dataPolicyData?.researcherName}</p>
          </p>

        </div>
        <ViewDataForm data={dataPolicyData} />
      </Modal>
      <Modal open={viewInstitutionCertificateForm}
        handleClose={() => setViewInstitutionCertificateForm(false)}
        dialogProps={{ modalTitle: "Institution Certificate" }} disableTitleDivider disableBottomHorizontalDivider >
        <div className={[classes.contentStartText, classes.showHeader].join(' ')}>
          <p>
            <span className={classes.submitDetails} ><p id='cvb-adminDashboard-viewInstCert-submittedTime' className={classes.submitText}>Submitted</p> on
              {institutionCertificateData?.dayStr} at {institutionCertificateData?.timeStr}</span>
            <p id='cvb-adminDashboard-viewInstCert-researcherName' className={classes.submitName}>By {institutionCertificateData?.researcherName}</p>
          </p>

        </div>
        <ViewInstitutionCertificateForm {...institutionCertificateData} />

      </Modal>

      <Modal
        open={openZone2ApproveModal}
        handleClose={closeApproveZone2RequestModal}
        dialogProps={APPROVE_ZONE2_REQUEST_MODAL_PROPS}
      >
        <div className={`${classes.datePickerWrapper} ${calenderOpenCloseClass}`}>
          <label id='cvb-adminDashboard-approveZone2Modal-expiryDateLabel'>Expiry Date : </label>
          <Grid item xs={6}>
            <CommonDatePicker
              onCalenderDateSelected={onZone2RequestExpiryChange}
              minDate={moment().add(1, 'd').toDate()}
              date={zone2RequestExpiryDate}
              showValidationError={expiryDateError}
              errorMessage='Please select expiry date'
              id='cvb-adminDashboard-approveZone2Modal-inputDate'
              isFromModal
              canClearInput
            />
          </Grid>
        </div>

        <Grid item xs={10} className={classes.fieldCont}>
          <label id="cvb-adminDashboard-workspaceInstanceLabel">Zone 2 Type</label>
          <Grid container>
            <Grid item xs={5} className={classes.radioCheckContainer}>
              <label className={classes.radioContainer} id="cvb-adminDashboard-academiaLabel">Qualified Researcher (Academia)</label>
              <input id='academia' type="radio" name="isDataCurated"
                onChange={() => onZone2TypeSelection('zone2-academia')} />
              <span className={classes.checkmark}></span>
            </Grid>
            <Grid item xs={5} className={classes.radioCheckContainer}>
              <label className={classes.radioContainer} id="cvb-adminDashboard-industryLabel">Qualified Researcher (Industry)</label>
              <input id='industry' type="radio" name="isDataCurated"
                onChange={() => onZone2TypeSelection('zone2-industry')} />
              <span className={classes.checkmark}></span>
            </Grid>
          </Grid>
          {
            isFormSubmitted && isEmpty(zone2Type) &&
            <ErrorText errorMessage="Select a Preference of Zone 2 Type" />
          }
        </Grid>
      </Modal>
      <RequestAdvancedComputeDetailsView
        open={openServiceAgreementModal}
        handleClose={toggleServiceAgreementModal}
        dialogProps={SERVICE_AGREEMENT_MODAL}
        workspaceDetails={workspaceDetails}
        organizationsList={organizationsList}
      >
      </RequestAdvancedComputeDetailsView>
    </>
  );
};

export default PendingApprovalRow;
