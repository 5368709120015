import React, { useState, useEffect, useMemo } from 'react'
import Classes from './index.module.css'
import AnalysisDataVisualization from '../../controls/analysis-data-visualization'
import OutlinedInput from '@mui/material/OutlinedInput'
import Modal from '../../commons/modal'
import InfoGreyIcon from '../../../assets/images/InfoGreyIcon.png'
import ErrorText from '../../controls/error-text'
import AnalysisTabs from '../../controls/analysis-tabs'
import { formFilterData } from '../../../utils/explore-data-plotly-helper'
import { sendGAEvents } from '../../../api/generic-api'
import { gql } from 'apollo-boost';
import { getAnalyseChartData } from '../../../api/graphql/exploreData'
import { v4 } from 'uuid'
import {GET_ANALYSIS_DATA_VALUES } from '../../../../src/api/graphql-query-helpers/graphql'
let initialStateID = v4();

const AnalyzeData = props => {

  const [openSaveCohortPopUp, setOpenSaveCohortPopUp] = useState(false);
  const [cohortName, setCohortName] = useState('');
  const [emptyCohortNameError, setEmptyCohortNameError] = useState(false);

  const [analysisState, setAnalysisState] = useState([{ id: initialStateID }]);
  const [currentActive, setCurrentActive] = useState(initialStateID);
  const [isFormUpdating, setIsFormUpdating] = useState(false);

  const [queriedResult, setQueriedResult] = useState({});

  const [lastFetchedCount, setLastFetchedCount] = useState(0)

  const closeSaveCohortModal = () => {
    setOpenSaveCohortPopUp(false);
    setCohortName('')
  }

  useEffect(() => {
    if (props.preloadedAnalysis?.length > 0) {
      setAnalysisState(props.preloadedAnalysis);
      setCurrentActive(props.preloadedAnalysis?.[0]?.id)
    }
  }, [props.preloadedAnalysis])

  const getCurrentActiveState = () => {
    return analysisState?.find(({ id }) => id === currentActive)
  }

  useEffect(() => {
    if (props.updateAnalyzeState) {
      props.updateAnalyzeState(analysisState)
    }
  }, [analysisState, props.updateAnalyzeState])

  const onSaveCohortModal = async (isPermalink) => {
    try {
      sendGAEvents('save_cohort', 'save_cohort', 'selected save cohort');
      if (!cohortName) {
        return setEmptyCohortNameError("Please enter the cohort name")
      }
      if (cohortName && cohortName.indexOf("/") > -1) {
        return setEmptyCohortNameError("Please do not enter '/' in Cohort Name")
      }
      let body = {
        "query": "Query for  new cohort creation44",
        "cohort_name": cohortName
      }
      setIsFormUpdating(true);
      const filterData = formFilterData(props.exploreDataFilters, props.excludeFromExploration, props.appliedFilters);
      const filterDataString = JSON.stringify(filterData)
      body["query"] = filterDataString;
      const analysisBody = analysisState.map((item, index) => {
        return {
          "axis": JSON.stringify({
            "xAxis": item?.xAxis,
            "yAxis": item?.yAxis
          }),
          "analysis_name": `Analysis ${index + 1}`
        }
      })
      if (isPermalink === true) {
        filterData.analysis = analysisBody;
        body['query'] = JSON.stringify(filterData);
      } else {
        body.analysis = analysisBody;
      }
      body['cohort_type'] = isPermalink === true ? 'permalink' : 'cohort'
      let response = null;
      if (isPermalink === true) {
        response = await props.saveCohortToDBApi(body)
      } else {
        response = await props.saveCohortWithoutID(body);
      }
      let snackBarMessage = 'Successfully added the cohort to My Stuff'
      if (isPermalink === true) {
        snackBarMessage = "Successfully created permalink"
      }
      props.updateSnackBar(snackBarMessage, "Success");
      setOpenSaveCohortPopUp(false);
      setCohortName('')
      if (isPermalink === true) {
        return response;
      }
    } catch (error) {
      if (isPermalink === true) {
        throw error
      }
      setEmptyCohortNameError(error?.response?.data?.error || 'Something went wrong. Please try later.')
    } finally {
      setIsFormUpdating(false);
    }
  }

  const onCohortNameChange = e => {
    setCohortName(e.target.value);
    setEmptyCohortNameError('');
  }

  const SAVE_COHORT_MODAL = {
    modalTitle: "Save Cohort",
    modalContent: "",
    positiveButtonText: "Save Cohort",
    negativeButtonText: "Cancel",
    positiveButtonAction: onSaveCohortModal,
    negativeButtonAction: closeSaveCohortModal,
    popupButtonDisable: isFormUpdating
  }

  const getData = async (id, xAxis, yAxis) => {
    try {
      const appliedKeys = {}
      for (let item of (props.appliedFilterFields || [])) {
        const finalFilter = item.find(x => x.isFinalSelection)?.value;
        const keys = Object.keys(finalFilter || {})
        for (let key of keys) {
          if (Array.isArray(finalFilter[key])) {
            appliedKeys[key] = finalFilter[key]
          } else {
            const innerKeys = Object.keys(finalFilter[key] || {})
            for (let innerKey of innerKeys) {
              if (appliedKeys[key]) {
                appliedKeys[key][innerKey] = true;
              } else {
                appliedKeys[key] = {
                  [innerKey]: true
                }
              }
            }
          }
        }
      }
      let query = ""
      if (xAxis === "height" || xAxis === "weight") {
        query = gql`query ($filter: JSON){
          _aggregation{
            case (nestedAggFields: { termsFields: ["${yAxis}"] }, filter: $filter){
              ${xAxis}{
                histogram(rangeStep: 10){
                  key
                  count
                  termsFields{
                    field
                    terms{
                      key
                      count
                    }
                  }
                }
              }
            }
          }
        }
        `;
      } else {
        query = gql`query ($filter: JSON){
          _aggregation{
            case (nestedAggFields: { termsFields: ["${yAxis}"] }, filter: $filter){
              ${xAxis}{
                histogram{
                  key
                  count
                  termsFields{
                    field
                    terms{
                      key
                      count
                    }
                  }
                }
              }
            }
          }
        }
        `;
      }
      const response = await getAnalyseChartData(query, appliedKeys);
      return response?._aggregation?.case

    }
    catch (error) { console.log(error) }
  }

  const fetchOnChange = async (id, xAxisValue, yAxisValue) => {
    const resp = await getData(currentActive, xAxisValue, yAxisValue);
    setQueriedResult({
      [id]: {
        case: resp,
        xAxis: xAxisValue,
        yAxis: yAxisValue
      }
    })
  }

  useEffect(() => {
    const xAxisValue = getCurrentActiveState()?.xAxis;
    const yAxisValue = getCurrentActiveState()?.yAxis;
    const selectedQueryResult = queriedResult[currentActive] || {};
    if ((selectedQueryResult["xAxis"] !== xAxisValue ||
      selectedQueryResult["yAxis"] !== yAxisValue)) {
      if (xAxisValue && yAxisValue) {
        fetchOnChange(currentActive, xAxisValue, yAxisValue)
      }
    }
  }, [analysisState, queriedResult, currentActive]);

  const fetchAllGraphsData = async () => {
    const allPromises = [];
    let finalData = {}
    for (let item of analysisState) {
      if (item.id && item.xAxis && item.yAxis) {
        allPromises.push(getData(item.id, item.xAxis, item.yAxis).then((resp) => {
          finalData[item.id] = {
            case: resp,
            xAxis: item.xAxis,
            yAxis: item.yAxis
          }
        }))
      }
    }
    await Promise.all(allPromises);
    setLastFetchedCount(props.refreshAnalyzeDataCount)
    setQueriedResult(finalData)
  }

  useEffect(() => {
    if (props.refreshAnalyzeDataCount > 0 && lastFetchedCount < props.refreshAnalyzeDataCount) {
      fetchAllGraphsData()
    }
  }, [props.refreshAnalyzeDataCount, props.appliedFilterFields])

  const addNewAnalysisData = () => {
    const newAnalysis = {
      id: v4(),
    }
    setCurrentActive(newAnalysis.id)
    setAnalysisState([...analysisState, newAnalysis])
  }

  const updateField = (field, value, item) => {
    const analysisStateClone = [...analysisState];
    for (let i of analysisState) {
      if (i.id == item.id) {
        i[field] = value;
        break;
      }
    }
    setAnalysisState(analysisStateClone)
  }

  const closeAnalysisTab = (e, id) => {
    e.stopPropagation();
    const analysisStateClone = analysisState.filter(item => item?.id !== id);
    if (analysisState.length > 0) {
      setCurrentActive(analysisStateClone[analysisStateClone?.length - 1]?.id)
    }
    setAnalysisState(analysisStateClone);
  }

  const getXAxisValues = useMemo(() => {
    return GET_ANALYSIS_DATA_VALUES
  }, [props.exploreData])

  const getYAxisValues = useMemo(() => {
    return getXAxisValues.filter(item => getCurrentActiveState()?.xAxis !== item?.value && item?.value !== "height" && item?.value !== "weight");
  }, [getXAxisValues, analysisState])


  const xAxisValues = useMemo(() => {
    const values = getXAxisValues?.filter(item => getCurrentActiveState()?.yAxis !== item?.value);
    return values
  }, [getXAxisValues, currentActive, getCurrentActiveState()?.yAxis]);

  return (
    <div>
      <Modal
        open={openSaveCohortPopUp}
        handleClose={closeSaveCohortModal}
        dialogProps={SAVE_COHORT_MODAL}
        disableTitleDivider
      >
        <div className={Classes.saveCohortContentContainer}>
          <div className={Classes.cohortInputContainer}>
            <label htmlFor='cohortName'>Cohort Name</label>
            <OutlinedInput
              value={cohortName}
              onChange={onCohortNameChange}
              placeholder='Enter cohort name'
            />
            {emptyCohortNameError && <p> <ErrorText errorMessage={emptyCohortNameError} /></p>}
          </div>
          <p className={Classes.cohortInfoText}>
            <span>
              <img alt='info icon' className={Classes.infoIcon} src={InfoGreyIcon} />
            </span>
            You can access this cohort later from 'My Stuff' page.
          </p>
        </div>
      </Modal>
      <div className={Classes.tabsContentContainer}>
        <div className={Classes.contentMainContainer}>
          <AnalysisTabs
            analysisState={analysisState}
            addNewAnalysisData={addNewAnalysisData}
            currentState={getCurrentActiveState() || {}}
            currentActive={currentActive}
            setCurrentActive={setCurrentActive}
            closeAnalysisTab={closeAnalysisTab} />
          <AnalysisDataVisualization
            currentActive={currentActive}
            analysisState={analysisState}
            updateField={updateField}
            xAxisValues={xAxisValues}
            yAxisValues={getYAxisValues}
            currentState={getCurrentActiveState() || {}}
            graphData={queriedResult[currentActive]?.case || {}}
          />
        </div>
      </div>
    </div>
  )
}

export default AnalyzeData