import React, { useState, useEffect, useCallback } from 'react'
import Classes from './index.module.css'
import BackButtonArrow from '../../../assets/icons/left_arrow.png'
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import DropDown from '../../commons/dropdown'
import { COUNTRY_LIST } from '../../../constants/strings'
import ErrorText from '../../controls/error-text';
import { getUserSearchAdminApi } from '../../../api/admin/user'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import debounce from 'lodash/debounce'
import { generateInvoiceDetails } from '../../../api/invoice-api';
import { navigate } from '@reach/router'
import { validateEmailAddress } from '../../../utils/input-validations'
import { useLocation } from "@reach/router"
import InputAdornment from '@mui/material/InputAdornment';

const GenerateInvoice = ({ updateSnackBar }) => {
  const [amount, setAmount] = useState('');
  const [type, setType] = useState('USER_ACCOUNT');
  const [user, setUser] = useState('');
  const [workspaceId, setWorkspaceId] = useState('');
  const [otherInformation, setOtherInformation] = useState('');
  const [billingName, setBillingName] = useState('');
  const [billingEmail, setBillingEmail] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [billingCountry, setBillingCountry] = useState('');
  const [billingState, setBillingState] = useState('');
  const [billingCity, setBillingCity] = useState('');
  const [billingZipCode, setBillingZipCode] = useState('');
  const [errors, setErrors] = useState({})
  const [usersList, setUsersList] = useState([]);
  const { search } = useLocation();

  const getQueryParam = (param) => {
    try {
      const urlParams = new URLSearchParams(search);
      return urlParams?.get(param)
    }
    catch (error) {
      return undefined;
    }
  }

  const queryUserName = getQueryParam('username');
  const queryFieldName = (getQueryParam("name")?.split("_") || [' '])

  useEffect(() => {
    if (Object.keys(errors || {})?.length > 0) {
      validateForm()
    }
  }, [amount, type, user, workspaceId,
    billingName, billingAddress, billingEmail, billingState,
    billingCity, billingCountry, billingZipCode])

  const validateForm = () => {
    let errors = {};
    if (!amount) {
      errors['amount'] = 'Please enter a valid amount'
    }
    if (!billingName) {
      errors['billingName'] = 'Please enter name'
    }
    if (!validateEmailAddress(billingEmail)) {
      errors['billingEmail'] = 'Please enter a valid email'
    }
    if (!billingAddress) {
      errors['billingAddress'] = 'Please enter street address'
    }
    if (!billingState) {
      errors['billingState'] = 'Please enter state '
    }
    if (!billingCity) {
      errors['billingCity'] = 'Please enter city '
    }
    if (!billingCountry) {
      errors['billingCountry'] = 'Please enter country '
    }
    if (!billingZipCode) {
      errors['billingZipCode'] = 'Please enter zipcode '
    }
    if (type === 'USER_ACCOUNT' && (queryUserName ? false : !user?.username)) {
      errors['user'] = 'Please select a valid email'
    }
    if (type === 'WORKSPACE_ACCOUNT' && !workspaceId) {
      errors['workspaceId'] = 'Please select a valid workspace Id'
    };

    setErrors(errors)
    return Object.keys(errors)?.length === 0
  }

  const handleRequest = async () => {
    const isFormValid = validateForm();
    if (!isFormValid) {
      return;
    }
    try {
      const body = {
        "amount": amount,
        "entity_id": type === 'USER_ACCOUNT' ? (queryUserName || user?.username) : workspaceId,
        "invoice_type": "CREDIT",
        "entity_type": type === 'USER_ACCOUNT' ? 'USER_ACCOUNT' : 'WORKSPACE_ACCOUNT',
        "comments": otherInformation,
        "billing_information": {
          "name": billingName,
          "email": billingEmail,
          "zip_code": billingZipCode,
          "street_address": billingAddress,
          "country": billingCountry,
          "state": billingState,
          "city": billingCity,
        }
      }
      const response = await generateInvoiceDetails(body);
      updateSnackBar(response?.message || "Invoice generated successfully", 'success')
      navigate('/invoice')
    }
    catch (error) {
      console.log(error, error?.response)
      updateSnackBar(error?.response?.data?.message || "Something went wrong, Please try again!", 'error')
    }
  }

  const getUsersList = async inputName => {
    try {
      const response = await getUserSearchAdminApi(inputName);
      setUsersList(response?.users || [])
    }
    catch (error) {
      setUsersList([])
      console.log(error)
    }
  }

  const onCancelAndButtonClick = () => {
    navigate('/invoice');
  }


  const handleUserInputChange = useCallback(debounce((value) => {
    setUser(value);
    getUsersList(value)
  }, 100), []);

  return (
    <div className={Classes.editProfileMainContainer}>
      <div className={Classes.editProfileChildContainer}>
        <Grid container direction='column'>
          <p id='cvb-requestAdvanceCompute-backButton'
            className={Classes.backButton}>
            <span><img alt='back button arrow' src={BackButtonArrow} /></span>
            <p id='cvb-requestAdvanceCompute-backButtonText'
              onClick={onCancelAndButtonClick}
              className={Classes.backButtonText}>Back</p>
          </p>
          <h3 id='cvb-requestAdvanceCompute-heading' className={Classes.editProfileText}>Generate Invoice</h3>
        </Grid>
        <Grid spacing={3} xs={10} container direction='row' className={Classes.advancedComputeContainer}>
          <Grid item xs={5} className={Classes.fieldCont}>
            <label htmlFor='amount' id="cvb-advancedCompute-projectTitleLabel">Amount<sup> *</sup></label>
            <OutlinedInput
              type="number"
              value={amount}
              fullWidth
              inputProps={{ maxLength: 256 }}
              onChange={e => setAmount(e.target.value)}
              id='cvb-advancedCompute-projectTitle'
              name='amount'
              placeholder='Enter amount'
              startAdornment={(
                <InputAdornment position="start">
                    $
                </InputAdornment>
            )}
            />
            {errors['amount'] && <ErrorText errorMessage={errors['amount']} />}
          </Grid>
          <Grid item xs={10} className={Classes.fieldCont}>
            <label id="cvb-advancedCompute-workspaceInstanceLabel">Type<sup> *</sup></label>
            <Grid container>
              <Grid item xs={4} className={Classes.radioCheckContainer}>
                <label className={Classes.radioContainer} id="cvb-advancedCompute-ec2Label">Account Credit</label>
                <input id='isDataCurated1' type="radio" name="isDataCurated"
                  checked={type === "USER_ACCOUNT"}
                  onChange={() => setType('USER_ACCOUNT')}
                />
                <span className={Classes.checkmark}></span>
              </Grid>

              <Grid item xs={4} className={Classes.radioCheckContainer}>
                <label className={Classes.radioContainer} id="cvb-advancedCompute-windowsLabel">Workspace Credit</label>
                <input id='isDataCurated2' type="radio" name="isDataCurated"
                  checked={type === "WORKSPACE_ACCOUNT"}
                  disabled={!!queryUserName}
                  onChange={() => setType('WORKSPACE_ACCOUNT')}
                />
                <span className={Classes.checkmark}></span>
              </Grid>
            </Grid>
            {errors['type'] && <ErrorText errorMessage={errors['type']} />}
          </Grid>
          {
            queryUserName &&
            <Grid item xs={5} className={Classes.fieldCont}>
              <label htmlFor='workspace' id="cvb-DIFFormStep1-countryLabel">User Details<sup> *</sup></label>
              <OutlinedInput
                type="text"
                value={queryFieldName.join(" ")}
                disabled={!!queryUserName}
                fullWidth
                id='cvb-advancedCompute-organization'
                name='workspace'
                placeholder='Enter workspace id'
              />
            </Grid>
          }
          {
            (type === 'USER_ACCOUNT' && !queryUserName) ?
              <Grid item xs={5} className={Classes.fieldCont}>
                <label htmlFor='user' id="cvb-DIFFormStep1-countryLabel">User Details<sup> *</sup></label>
                <Autocomplete
                  id="combo-box-demo"
                  selected={typeof user === 'object'}
                  options={usersList}
                  disabled={!!queryUserName}
                  onChange={(e, newValue) => { setUser(newValue) }}
                  getOptionLabel={({ email }) => email}
                  style={{ width: 400 }}
                  renderInput={(params) => <TextField {...params}
                    variant="outlined"
                    label=""
                    margin="dense"
                    placeholder={`Start typing user's email`}
                    onChange={e => handleUserInputChange(e.target.value)} />}
                />
                {errors['user'] && <ErrorText errorMessage={errors['user']} />}
              </Grid>
              :
              !queryUserName &&
              <Grid item xs={5} className={Classes.fieldCont}>
                <label htmlFor='workspace' id="cvb-DIFFormStep1-countryLabel">Workspace ID<sup> *</sup></label>
                <OutlinedInput
                  type="text"
                  value={workspaceId}
                  onChange={e => setWorkspaceId(e.target.value)}
                  fullWidth
                  id='cvb-advancedCompute-organization'
                  name='workspace'
                  placeholder='Enter workspace id'
                />
                {errors['workspaceId'] && <ErrorText errorMessage={errors['workspaceId']} />}
              </Grid>
          }
          <Grid item xs={10} className={Classes.fieldCont}>
            <label htmlFor='organization' id="cvb-advancedCompute-organizationLabel">Other Information</label>
            <OutlinedInput
              type="text"
              value={otherInformation}
              onChange={e => setOtherInformation(e.target.value)}
              fullWidth
              inputProps={{
                minLength:0, maxLength: 100
              }}
              id='cvb-advancedCompute-organization'
              name='organization'
              placeholder='User cost center information or any other information'
            />
          </Grid>
          <Grid item xs={10}>
            <p className={`${Classes.formTitle}`} id="cvb-advancedCompute-workspaceUserDetails">Billing Information</p>
          </Grid>

          <Grid item xs={5} className={Classes.fieldCont}>
            <label htmlFor='firstName' id="cvb-advancedCompute-firstNameLabel"> Name<sup> *</sup></label>
            <OutlinedInput
              type="text"
              value={billingName}
              onChange={e => setBillingName(e.target.value)}
              fullWidth
              id='cvb-advancedCompute-firstName'
              name='firstName'
              placeholder='Enter Name'

            />
            {errors['billingName'] && <ErrorText errorMessage={errors['billingName']} />}
          </Grid>

          <Grid item xs={5} className={Classes.fieldCont}>
            <label htmlFor='firstName' id="cvb-advancedCompute-lastNameLabel"> Email<sup> *</sup></label>
            <OutlinedInput
              type="text"
              value={billingEmail}
              onChange={e => setBillingEmail(e.target.value)}
              fullWidth
              id='cvb-advancedCompute-lastName'
              name='lastName'
              placeholder='Enter email'
            />
            {errors['billingEmail'] && <ErrorText errorMessage={errors['billingEmail']} />}
          </Grid>
          <Grid item xs={5} className={Classes.fieldCont}>
            <label htmlFor='country' id="cvb-DIFFormStep1-countryLabel">Country<sup> *</sup></label>
            <DropDown
              id="cvb-DIFFormStep1-instituteCountry"
              name='instituteCountry'
              values={COUNTRY_LIST}
              handleFilterStateChange={value => setBillingCountry(value)}
              selectedFilter={billingCountry}
              placeholder={'Select Country'}
              searchable
              isClearable
            />
            {errors['billingCountry'] && <ErrorText errorMessage={errors['billingCountry']} />}
          </Grid>
          <Grid item xs={10} className={Classes.fieldCont}>
            <label htmlFor='firstName' id="cvb-advancedCompute-lastNameLabel"> Street address<sup> *</sup></label>
            <OutlinedInput
              type="text"
              value={billingAddress}
              onChange={e => setBillingAddress(e.target.value)}
              fullWidth
              id='cvb-advancedCompute-lastName'
              name='lastName'
            />
            {errors['billingAddress'] && <ErrorText errorMessage={errors['billingAddress']} />}
          </Grid>
          <Grid item xs={5} className={Classes.fieldCont}>
            <label htmlFor='organization' id="cvb-advancedCompute-organizationLabel">City<sup> *</sup></label>
            <OutlinedInput
              type="text"
              value={billingCity}
              onChange={e => setBillingCity(e.target.value)}
              fullWidth
              id='cvb-advancedCompute-organization'
              name='organization'
              placeholder='Enter city'
            />
            {errors['billingCity'] && <ErrorText errorMessage={errors['billingCity']} />}
          </Grid>
          <Grid item xs={5} className={Classes.fieldCont}>
            <label htmlFor='organization' id="cvb-advancedCompute-organizationLabel">State<sup> *</sup></label>
            <OutlinedInput
              type="text"
              value={billingState}
              onChange={e => setBillingState(e.target.value)}
              fullWidth
              id='cvb-advancedCompute-organization'
              name='organization'
              placeholder='Enter state'

            />
            {errors['billingState'] && <ErrorText errorMessage={errors['billingState']} />}
          </Grid>
          <Grid item xs={5} className={Classes.fieldCont}>
            <label htmlFor='organization' id="cvb-advancedCompute-organizationLabel">Zip Code<sup> *</sup></label>
            <OutlinedInput
              type="text"
              value={billingZipCode}
              fullWidth
              onChange={e => setBillingZipCode(e.target.value)}
              id='cvb-advancedCompute-organization'
              name='organization'
              placeholder='Enter zipcode'
            />
            {errors['billingZipCode'] && <ErrorText errorMessage={errors['billingZipCode']} />}
          </Grid>
        </Grid>
      </div>
      <div className={Classes.agreementFormButtons}>
        <button className={`no-outlined-button`} onClick={onCancelAndButtonClick}>Cancel</button>
        <button onClick={handleRequest} className={`medium-size-button solid-button ${Classes.requestAccessButton}`}>
          Request
        </button>
      </div>
    </div >
  )
}

export default GenerateInvoice
