import React, { useEffect, useState } from 'react';
import {env} from '../../../env'
import Grid from '@mui/material/Grid';
import Classes from './index.module.css'
import SignatureCanvas from 'react-signature-canvas'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { DCA_FORM_COMPLETED_TEXT } from '../../../constants/strings';
import ErrorText from '../../controls/error-text'
import Checkbox from '@mui/material/Checkbox';

const Acknowledgment = props => {
    const { acknowledgementRef = null,
        currentDCAFormStage = "",
        setAcknowledgmentSignature = () => { },
        acknowledgmentSignature = '',
        isFormSubmitted = false,
        isDashboard = false,
        contributorSigningName = '',
        contributorSigningTitle = '',
        userDetails = {},
        setIsAgreeSelected = ()=>{ },
        isAgreeSelected = false
    } = props;
    const [ canvasClicked, setCanvasClicked] = useState(false);
    const disableTextInput =  (currentDCAFormStage === DCA_FORM_COMPLETED_TEXT);
    const isSignatureValid = () => !acknowledgementRef?.current?.isEmpty();
    const createAckSign = async (data) => await acknowledgementRef?.current?.fromDataURL(data);
    const clearAckSign = async () => await acknowledgementRef.current.clear();
    useEffect(() => {
        if(!isEmpty(acknowledgmentSignature)){
            createAckSign(acknowledgmentSignature);
        }else{
            clearAckSign();
            setAcknowledgmentSignature(null);
        }
    }, []);
    const onSignatureEnd = async () => {
        const ackSign = await acknowledgementRef?.current?.toDataURL();
        setAcknowledgmentSignature(ackSign);
    }
    const clearSignature = async () => {
        if (!disableTextInput) {
            await clearAckSign();
            setAcknowledgmentSignature(null);
        }
    }

    const onCanvasClick = () => {
        setCanvasClicked(true);
    }
    const userName = !isDashboard ? (capitalize(userDetails?.["first_name"]) + " " + capitalize(userDetails?.["last_name"])) : contributorSigningName;
    const designation = !isDashboard ? get(userDetails ,'designation', '') : contributorSigningTitle ;
    const dateToday = moment(new Date()).format('MM/DD/YYYY');
    const agreementText = env.REACT_APP_AGREEMENT_TEXT

    return (
        <div className={Classes.container} id='cvb-dca-stage3AcknowledgementContainer'>
            <p className={Classes.formTitle}>Acknowledgment</p>
            <Grid container className={Classes.signaturePadContainer} id='cvb-dca-stage3AcknowledgementSignPad'>
                <Grid item xs={5}>
                    <p className={Classes.formTitle} id='cvb-dca-stage3AcknowledgementAgreedText'>Agreed and Acknowledged by: </p>
                    <p className={Classes.signPolicyText} id='cvb-dca-stage3AcknowledgementSignText'>
                        <label>Sign Agreement<sup> *</sup></label>
                    </p>
                    <p className={Classes.signPolicySubText} id='cvb-dca-stage3AcknowledgementSignSubText'>Sign your name on the trackpad and press any key when finished</p>
                    <div className={Classes.signHereBox} onClick={onCanvasClick} >
                    { !isSignatureValid() && <p className={Classes.clickHeretoBegin} id='cvb-dca-stage3AcknowledgementSignPadClickLink'>Click here to begin</p>}
                        <SignatureCanvas penColor='black'
                            ref={acknowledgementRef}
                            canvasProps={{ width: 400, height: 150, className: 'sigCanvas' }} 
                            onEnd={onSignatureEnd}
                            />
                    <p onClick={clearSignature} className={Classes.clearText} id='cvb-dca-stage3AcknowledgementSignPadClearLink'>Clear</p>
                       
                    </div>
                    {
                        isFormSubmitted && !isSignatureValid() &&
                        <ErrorText errorMessage="Signature mandatory" />
                    }
                    <Grid item>
                        <div className={Classes.agreeContainer}>
                            <span className={Classes.agreeCheckbox}>
                                <Checkbox
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    value={isAgreeSelected}
                                    checked={isAgreeSelected}
                                    onChange={(e) => setIsAgreeSelected(e.target.checked)}
                                />
                            </span>
                            <span className={Classes.agreeContent}>
                            {agreementText ? agreementText : "I confirm that by signing this Agreement I am legally bound by its terms and conditions" }
                                </span>
                        </div>
                        {
                            isFormSubmitted && !isAgreeSelected &&
                            <ErrorText errorMessage="Please confirm agreement" />
                        }
                    </Grid>
                    <div className={Classes.userDetails}>
                        <p id='cvb-dca-stage3AcknowledgementUserName'>Name: <span>{userName}</span></p>
                        <p id='cvb-dca-stage3AcknowledgementTitle'>Title: <span>{designation}</span></p>
                        <p id='cvb-dca-stage3AcknowledgementDate'>Date: <span>{dateToday}</span></p>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Acknowledgment;
