import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import classes from './index.module.css'
import ImgDataSets from '../../../assets/images/datasets.svg'
import ImgUsers from '../../../assets/images/users.svg'
import { ZONE_1_KEY, ZONE_2_KEY, ZONE_3_KEY } from '../../../constants/strings'
import { getDatasetForAllZones } from '../../../api/graphql/admin-dashboard'
import { map, get, find } from 'lodash'
import DashboardUserCard from '../../commons/dashboard-user-card'

const DistributionsCardContainer = (props) => {
    const { dashboardDataTitle = '', usersData =[], selectedUser= null, selectedZone= null } = props;
    const [distributionCards, setDistributionCards] = useState([]);
    const [zone1DatasetCount, setZone1DatasetCount] = useState(null);
    const [zone2DatasetCount, setZone2DatasetCount] = useState(null);
    const [zone3DatasetCount, setZone3DatasetCount] = useState(null);
    const [allZonesData, setAllZonesData] = useState(null);

    const loadZonesData = async () => {
        try {
            const res = await getDatasetForAllZones();
            let dataForBarChart = [];
            const histogram = res?.data?._aggregation?.study?.zone?.histogram;
            const zone1Details = find(histogram, (data) => get(data, 'key', 0) === ZONE_1_KEY);
            const zone2Details = find(histogram, (data) => get(data, 'key', 0) === ZONE_2_KEY);
            const zone3Details = find(histogram, (data) => get(data, 'key', 0) === ZONE_3_KEY);
            const zone1DatasetCount = zone1Details?.termsFields?.[0]?.terms?.length || 0;
            const zone2DatasetCount = zone2Details?.termsFields?.[0]?.terms?.length || 0;
            const zone3DatasetCount = zone3Details?.termsFields?.[0]?.terms?.length || 0;

            dataForBarChart = [
                {
                    name: 'Zone 1', count: zone1DatasetCount, color: "#58C9DD"
                },
                {
                    name: 'Zone 2', count: zone2DatasetCount, color: "#58C9DD"
                }, {
                    name: 'Zone 3', count: zone3DatasetCount, color: "#58C9DD"
                }
            ]

            setZone1DatasetCount(zone1DatasetCount);
            setZone2DatasetCount(zone2DatasetCount);
            setZone3DatasetCount(zone3DatasetCount);
            setAllZonesData(histogram);
        } catch (error) {
            setZone1DatasetCount(0);
            console.log('error in getting zones data', error)
        }
    }

    useEffect(() => {
        loadZonesData()
    }, [])
    const onZoneSelect = (id) => {
        let currentSelectedZoneData = {};
        props.onDatasetZoneSelected(id);
        switch (id) {
            case 1: currentSelectedZoneData = find(allZonesData, (data) => get(data, 'key', 0) === ZONE_1_KEY);
                break;
            case 2: currentSelectedZoneData = find(allZonesData, (data) => get(data, 'key', 0) === ZONE_2_KEY);
                break;
            case 3: currentSelectedZoneData = find(allZonesData, (data) => get(data, 'key', 0) === ZONE_3_KEY);
                break;
            default: break;
        }
        props.onUserZoneSelected(null);
        props.onZoneSelect(id);
    }

    const onViewUserClick = zone => {
        props.onDatasetZoneSelected(null);
        switch (zone) {
            case 1: {
                props.userDetails?.resourceActions?.dashboard?.view_zone1_user_details &&
                    props.onUserZoneSelected(zone);
                props.onViewUserClick(zone);
                break;
            }
            case 2: {
                props.userDetails?.resourceActions?.dashboard?.view_zone2_user_details &&
                    props.onUserZoneSelected(zone);
                props.onViewUserClick(zone);
                break;
            }
            case 3: {
                props.userDetails?.resourceActions?.dashboard?.view_zone3_user_details &&
                    props.onUserZoneSelected(zone);
                props.onViewUserClick(zone);
                break;
            }
        }
    }

    useEffect(() => {
        let distCards = [];
        distCards = [
            {
                isVisible: props.userDetails?.resourceActions?.dashboard?.view_zone1_datasets || false,
                type: 'dataset', 
                selectedUsersOrZone: selectedZone,
                icon: ImgDataSets,
                title: 'Projects',
                count: zone1DatasetCount,
                zone: 1,
                onViewClick: () =>  onZoneSelect(1),
            },
            {
                isVisible: props.userDetails?.resourceActions?.dashboard?.view_zone1_users || false,
                type: 'user', 
                selectedUsersOrZone: selectedUser,
                icon: ImgUsers,
                title: 'Users',
                count: usersData?.zone1?.length,
                zone: 1,
                onViewClick: () => onViewUserClick(1),
                canViewUserDetails: props.userDetails?.resourceActions?.dashboard?.view_zone1_user_details
            },
            {
                isVisible: props.userDetails?.resourceActions?.dashboard?.view_zone2_datasets || false, 
                type: 'dataset', 
                selectedUsersOrZone: selectedZone,
                icon: ImgDataSets,
                title: 'Projects',
                count: zone2DatasetCount,
                zone: 2,
                onViewClick: () =>  onZoneSelect(2)
            },
            {
                isVisible: props.userDetails?.resourceActions?.dashboard?.view_zone2_users || false, 
                type: 'user', 
                selectedUsersOrZone: selectedUser,
                icon: ImgUsers,
                title: 'Users',
                count: usersData?.zone2?.length,
                zone: 2,
                onViewClick: () =>  onViewUserClick(2),
                canViewUserDetails: props.userDetails?.resourceActions?.dashboard?.view_zone2_user_details
            },
            {
                isVisible: props.userDetails?.resourceActions?.dashboard?.view_zone3_datasets || false, 
                type: 'dataset', 
                selectedUsersOrZone: selectedZone,
                icon: ImgDataSets,
                title: 'Projects',
                count: zone3DatasetCount,
                zone: 3,
                onViewClick: () =>  onZoneSelect(3)
            },
            {
                isVisible: props.userDetails?.resourceActions?.dashboard?.view_zone3_users || false, 
                type: 'user', 
                selectedUsersOrZone: selectedUser,
                icon: ImgUsers,
                title: 'Users',
                count: usersData?.zone3?.length,
                zone: 3,
                onViewClick: () =>  onViewUserClick(3),
                canViewUserDetails: props.userDetails?.resourceActions?.dashboard?.view_zone3_user_details
            },
        ];

        setDistributionCards(distCards);

    }, [props.userDetails, selectedZone, usersData, selectedUser, allZonesData  ])

    return (
        <React.Fragment>
            <Grid container spacing={3} className={classes.zoneTiles}>
                {
                    props.userDetails?.resourceActions?.dashboard?.view_zone1_datasets || props.userDetails?.resourceActions?.dashboard?.view_zone1_users ||
                        props.userDetails?.resourceActions?.dashboard?.view_zone2_datasets || props.userDetails?.resourceActions?.dashboard?.view_zone2_users ||
                        props.userDetails?.resourceActions?.dashboard?.view_zone3_datasets || props.userDetails?.resourceActions?.dashboard?.view_zone3_users
                        ?
                                <h2 id='cvb-adminDashboard-dashboardDataTitleText' className={classes.dashboardSubHeading}>{dashboardDataTitle}</h2>
                       : null
                }
                <Grid container spacing={3} className={classes.zoneTiles}>
                    {
                        props.userDetails?.resourceActions?.dashboard?.view_zone1_datasets || props.userDetails?.resourceActions?.dashboard?.view_zone1_users ?
                            <Grid item xs>
                                <h3 id='cvb-adminDashboard-zone1Heading' className={classes.zoneTilesHeading}>Zone 1</h3>
                                <Grid container spacing={2}>
                                    {map(distributionCards.slice(0, 2), (data, index) => {
                                        return <DashboardUserCard
                                            key={index}
                                            {...data}
                                        />
                                    })}
                                </Grid>
                            </Grid> : null

                    }
                    {
                        props.userDetails?.resourceActions?.dashboard?.view_zone2_datasets || props.userDetails?.resourceActions?.dashboard?.view_zone2_users ?
                            <Grid item xs>
                                <h3 id='cvb-adminDashboard-zone1Heading' className={classes.zoneTilesHeadingZone2}>Zone 2</h3>
                                <Grid container spacing={2}>
                                    {map(distributionCards.slice(2, 4), (data, index) => {
                                        return <DashboardUserCard
                                            key={index}
                                            {...data}
                                        />
                                    })}
                                </Grid>
                            </Grid> : null

                    }
                    {
                        props.userDetails?.resourceActions?.dashboard?.view_zone3_datasets || props.userDetails?.resourceActions?.dashboard?.view_zone3_users ?
                            <Grid item xs>
                                <h3 id='cvb-adminDashboard-zone1Heading' className={classes.zoneTilesHeadingZone3}>Zone 3</h3>
                                <Grid container spacing={2}>
                                    {map(distributionCards.slice(4, 6), (data, index) => {
                                        return <DashboardUserCard
                                            key={index}
                                            {...data}
                                        />
                                    })}
                                </Grid>
                            </Grid> : null

                    }
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default DistributionsCardContainer;