import React, { useEffect, useMemo, useState} from "react";

import Box from "@mui/material/Box";
import SectionHeader from "../../../commons/section-header";
import {
  Breadcrumbs,
  Button,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import BREADCRUMB_ICON from "../../../../assets/images/chevron-right.svg";
import ADD from "../../../../assets/images/add.svg";
import ADD_WHITE from "../../../../assets/images/add_white.svg";
import FILTER from "../../../../assets/images/filter_list.svg";
import CHECK from "../../../../assets/images/check.svg";
import { navigate } from "@reach/router";
import { vars } from "../../../../assets/variables";
import { v4 } from "uuid";
import CriteriaBlock from "./CriteriaBlock";
import GroupCriteriaBlock from "./GroupCriteriaBlock";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ChortDialog from "./ChortDialog";
import {
  defaultCriterion,
  defaultGroupCriteria,
  criteriaToQuery,
  hasEmpty,
  queryToCriteria,
  hasValidCriteria,
  isQueryEmpty
} from "./utils";
import {addCohort, getCohortAggregates} from "../../../../service/CohortsService";
import {getAccessibleProjects} from "../../../../api/upload-data-api";

const {
  primaryColor,
  primaryTextColor,
  selectPlaceholderColor,
  outlinedInputBorderColor,
  lightGrey,
  subHeaderColor,
  white,
} = vars;

const useStyles = makeStyles(() => ({
  pageSubHeader: {
    background: lightGrey,
    padding: "0 1.25rem 0 2.5rem",
    boxShadow: `0 0.0625rem 0 ${outlinedInputBorderColor}`,
    height: "3.5625rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& p": {
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: "1.0625rem",
      display: "flex",
      alignItems: "center",
      color: subHeaderColor,

      "& small": {
        marginLeft: "0.5rem",
        color: primaryTextColor,
        fontWeight: 500,
      },
    },
  },

  mainContainer: {
    padding: "2.5rem 2.875rem",
  },

  newCohort: {
    paddingBottom: "2.75rem",
    position: "relative",

    "& p": {
      display: "flex",
      alignItems: "center",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: "1.0625rem",
      color: primaryTextColor,

      "& img": {
        marginRight: "1rem",
      },
    },

    "&:before": {
      content: '""',
      height: "calc(100% - 2.3125rem)",
      width: "0.0625rem",
      background: outlinedInputBorderColor,
      position: "absolute",
      left: "0.71875rem",
      top: "1.75rem",
    },
  },

  block: {
    display: "flex",
    alignItems: "center",
    marginTop: "1.5rem",
  },

  bullet: {
    width: "1.5rem",
    height: "1.5rem",
    background: white,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    marginRight: "0.5rem",
    position: "relative",
    border: `0.0625rem solid ${outlinedInputBorderColor}`,
    justifyContent: "center",
  },

  dot: {
    width: "0.25rem",
    borderRadius: "50%",
    background: primaryTextColor,
    height: "0.25rem",
  },

  wrap: {
    width: "5rem",
    marginRight: "0.5rem",

    "& p": {
      justifyContent: "center",
    },
  },

  wrapSecondary: {
    filter: "drop-shadow(0 0.0625rem 0.125rem rgba(231, 234, 242, 0.6))",

    "& .MuiIconButton-root": {
      borderRadius: 0,
      height: "2.75rem",
      backgroundColor: white,
      padding: "0.875rem 0.75rem !important",
      border: `0.0625rem solid ${outlinedInputBorderColor}`,
      color: primaryTextColor,

      "& + .MuiIconButton-root": {
        borderTopRightRadius: "0.375rem",
        borderBottomRightRadius: "0.375rem",
      },

      "& svg": {
        width: "1rem",
        height: "1rem",
      },
    },

    "& .MuiButton-text": {
      borderRadius: "0.375rem 0 0 0.375rem",
      height: "2.8125rem",
      backgroundColor: white,
      padding: "0.875rem 0.75rem !important",
      border: `0.0625rem solid ${outlinedInputBorderColor}`,
      width: "9.75rem",
      color: primaryTextColor,
      justifyContent: "space-between",
      margin: 0,
      fontWeight: "500",
      fontSize: "0.875rem",

      "& em": {
        fontStyle: "normal",
        color: selectPlaceholderColor,
      },

      "& svg": {
        color: primaryTextColor,
      },

      "&:hover": {
        borderColor: primaryColor,
      },

      "&[aria-describedby]": {
        borderColor: primaryColor,
        boxShadow: "0 0 0 0.0625rem rgba(77, 128, 198, 0.3)",
      },
    },

    "& .MuiFormControl-root": {
      width: "9.75rem",

      "& .MuiOutlinedInput-root": {
        borderRadius: 0,
      },
    },
  },

  label: {
    display: "block",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#344054",
    marginBottom: "6px",
  },
}));


function getAllCriteriaGroups(criteria) {
  return criteria.filter((crit) => crit && crit.id && crit.items)
  .flatMap((crit) => [crit, ...getAllCriteriaGroups(crit.items)]).filter(crit => crit.items)
}

function getCriteriaGroupMap(criteria) {
  return getAllCriteriaGroups(criteria)
    .reduce((acc, item) => ({...acc, [item.id]: item})
    , {});
}

function ExploreCreateCohort(props) {
  const classes = useStyles();
  const defaultQueries = props?.location?.state?.formDefaultData
  const defaultCriteria = defaultQueries ? queryToCriteria(defaultQueries): null
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);
  const [criteria, setCriteria] = useState(defaultCriteria
    ?  defaultCriteria.items
    : [defaultCriterion()]);
  const [cohortName, setCohortName] = useState("");
  const [booleanOperator, setBooleanOperator] = useState(defaultCriteria ? defaultCriteria.operator : "OR");
  const [subjectsCount, setSubjectsCount] = useState(null);
  const disableOnSave = useMemo(() => hasEmpty(criteria), [criteria]);

  const sectionHeaderProps = {
    breadcrumbs: (
      <Breadcrumbs
        separator={<img src={BREADCRUMB_ICON} alt="" />}
        aria-label="breadcrumb"
      >
        <Link underline="hover" href="#" onClick={() => navigate("/explore/cohorts")}>
          Cohort Explorer
        </Link>
        <Typography>Create New Cohort</Typography>
      </Breadcrumbs>
    ),
    type: "newCohortButtons",
    defaultActionButtons: [
      {
        buttonText: "Close",
        handleOnClick: () => setOpenDiscardDialog(true),
        variant: "outlined",
      },
      {
        buttonText: "Save Cohort",
        handleOnClick: () => setOpenSaveDialog(true),
        variant: "contained",
        imageSrc: CHECK,
        disabled: disableOnSave
      },
    ],
  };

  useEffect(() => {
    updateSubjectsCount()
  }, [criteria, booleanOperator]);

  const clearAll = () => {
    setCriteria([{ ...defaultCriterion() }])
  }

  const addCriteria = () => {
    setCriteria((criteria) => [
      ...criteria,
      { ...defaultCriterion(), id: v4() },
    ]);
  };

  const addGroupCriteria = () => {
    setCriteria((criteria) => [...criteria, { ...defaultGroupCriteria() }]);
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1); // 👉️ 0
    result.splice(endIndex, 0, removed);

    return result;
  };

  // Used for drag and drop
  const groupMap = useMemo(
    () => getCriteriaGroupMap(criteria),
    [criteria]
  );

  const updateCriterionDefinition = (id, newDefinition) => {
    const criteriaCopy = [...criteria];
    const criteriaIdx = criteriaCopy.findIndex((c) => c.id === id);
    criteriaCopy[criteriaIdx] = { id, ...newDefinition };
    setCriteria(criteriaCopy);
  };

  /**
   * result {
   * destination {draggableId, index}
   * }
   */
  function onDragEnd(result) {
    const { source, destination } = result;
    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    if (result.type === "droppableItem") {
      const items = reorder(criteria, source.index, result.destination.index);
      setCriteria([...items]);
    } else if (result.type === "droppableSubItem") {
      const sourceParentId = result.source.droppableId;
      const destParentId = result.destination.droppableId;

      const sourceSubItems = groupMap[sourceParentId.split("_")[1]]?.items;
      const destSubItems = groupMap[destParentId.split("_")[1]]?.items;

      let newCriteria = [...criteria];
      if (sourceParentId === destParentId) {
        const subItems = reorder(
          sourceSubItems,
          source.index,
          result.destination.index
        );

        newCriteria = newCriteria.map((item) => {
          if (item.id === sourceParentId.split("_")[1]) {
            item.items = subItems;
          }
          return item;
        });

        setCriteria([...newCriteria]);
      } else {
        let newSourceSubItems = [...sourceSubItems];
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

        let newDestSubItems = [...destSubItems];
        newDestSubItems.splice(destIndex, 0, draggedItem);
        newCriteria = newCriteria.map((item) => {
          if (item.id === sourceParentId.split("_")[1]) {
            item.items = newSourceSubItems;
          } else if (item.id === destParentId.split("_")[1]) {
            item.items = newDestSubItems;
          }
          return item;
        });
      }
      setCriteria([...newCriteria]);
    }

    // move
    result.source.index, result.destination.index;
  }

  function deleteCriterion(criterion, group = null) {
    if (!group) {
      setCriteria(criteria.filter((c) => c.id !== criterion.id));
    } else {
      const newCriteria = [...criteria];
      const criteriaGroupIdx = newCriteria.findIndex((c) => c.id === group);
      newCriteria[criteriaGroupIdx].items = newCriteria[
        criteriaGroupIdx
      ].items.filter((c) => c.id !== criterion.id);
      setCriteria(newCriteria);
    }
  }

  function onCohortSave() {

      addCohort(cohortName, criteriaToQuery(criteria, booleanOperator)).then((result) => {
          if(result.error) {
            props.updateSnackBar(result.error, 'error');
          } else {
            setOpenSaveDialog(false);
            navigate("/explore/cohorts?tab=my");
          }
      }).catch(error => {
        props.updateSnackBar("Something went wrong. Please try later.", 'error');
      });
  }



  /**
   * Calculates the number of cohort subjects. By default, the subjects number includes all the subjects from all
   * the user accessible subjects.
   * @returns {Promise<void>}
   */
  async function updateSubjectsCount(){

    let query = criteriaToQuery(criteria, booleanOperator);

    const isQueryValid = hasValidCriteria(query);
    // Check if we are in the initial state when all projects must be shown
    if(isQueryEmpty(query)) {
      const allAccessibleProjects = await getAccessibleProjects();
      query = criteriaToQuery( allAccessibleProjects.map(p => {
        return {"IN": {"project_id": [p.project_id]}}
      }), booleanOperator);
    // Check if a valid criteria has been selected by the user.
    } else if(!isQueryValid) {
      return;
    }

    //Perform the query and update the subjects prop.
    const cohort = {cohort_query: query}
    const fields = [{enabled: true, queryVariable: 'project_id'}];
    const result = await getCohortAggregates(cohort, fields);
    setSubjectsCount(result.subjects.histogram[0].max)
  }

  function deleteGroup(criterion) {
    setCriteria(criteria.filter((c) => c.id !== criterion.id));
  }
  return (
    <>
      <SectionHeader
        open={props.open}
        sectionHeaderProps={sectionHeaderProps}
      />

      <Box className={classes.pageSubHeader}>
        <Typography>
          My cohort<Typography component="small">{subjectsCount && `(${subjectsCount} subjects)`}</Typography>
        </Typography>

        <Button variant="outlined" size="small" color="secondary" onClick={clearAll}>
          Clear all
        </Button>
      </Box>

      <Box className={classes.mainContainer}>
        <Box className={classes.newCohort}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Typography>
              <img src={FILTER} alt="" />
              In the new Cohort, include all subjects that match{" "}
              {booleanOperator === "and" ? "all" : "any of"} the following
              criteria:
            </Typography>
            <Droppable droppableId="criteria" type={`droppableItem`}>
              {(provided) => (
                <Stack {...provided.droppableProps} ref={provided.innerRef}>
                  {criteria &&
                    criteria.length > 0 &&
                    criteria.map((criteriaItem, index) => (
                      <Draggable
                        key={criteriaItem.id}
                        draggableId={criteriaItem.id}
                        index={index}
                        type={"droppable"}
                      >
                        {(provided, snapshot) => {
                          return criteriaItem.items ? (
                            <GroupCriteriaBlock
                              key={criteriaItem.id}
                              index={index}
                              innerRef={provided.innerRef}
                              criteria={criteriaItem}
                              snapshot={snapshot}
                              provided={provided}
                              reorder={reorder}
                              updateGroup={(group) =>
                                updateCriterionDefinition(
                                  criteriaItem.id,
                                  group
                                )
                              }
                              onDeleteGroup={deleteGroup}
                              onDeleteItem={deleteCriterion}
                              parentOperator={booleanOperator}
                              setParentOperator={setBooleanOperator}
                            />
                          ) : (
                            <CriteriaBlock
                              index={index}
                              key={criteriaItem.id}
                              innerRef={provided.innerRef}
                              criteria={criteriaItem}
                              snapshot={snapshot}
                              provided={provided}
                              parentOperator={booleanOperator}
                              setParentOperator={setBooleanOperator}
                              onDelete={deleteCriterion}
                              updateCriterion={updateCriterionDefinition}
                            />
                          );
                        }}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </Box>

        <Box display="flex" alignItems="center">
          <Button variant="contained" color="primary" onClick={addCriteria}>
            <img src={ADD_WHITE} alt="" />
            Add Criterion
          </Button>
          <Button variant="outlined" color="primary" onClick={addGroupCriteria}>
            <img src={ADD} alt="" />
            Add Criteria Group
          </Button>
        </Box>
      </Box>

      <ChortDialog
        open={openDiscardDialog}
        handleClose={() => setOpenDiscardDialog(false)}
        title="Close and discard changes?"
        description="Are you sure you want to close this cohort creation? This will discard all your unsaved attributes."
        actions={
          <>
            <Button
              onClick={() => setOpenDiscardDialog(false)}
              variant="outlined"
            >
              Back
            </Button>
            <Button
              onClick={() => {
                setOpenDiscardDialog(false);
                navigate(".");
              }}
              color="error"
              variant="contained"
            >
              Close and Discard
            </Button>
          </>
        }
      />

      <ChortDialog
        open={openSaveDialog}
        handleClose={() => setOpenSaveDialog(false)}
        title="Save new cohort"
        description="Please enter a name for this cohort."
        actions={
          <>
            <Button onClick={() => setOpenSaveDialog(false)} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={onCohortSave}
              color="primary"
              disabled={cohortName.length < 3}
              className="disable-shadow"
              variant="contained"
            >
              Save
            </Button>
          </>
        }
      >
        <Box mt={3}>
          <Typography component="label" className={classes.label}>
            Cohort name
          </Typography>
          <TextField
            placeholder="e.g. Male, CO2<99"
            value={cohortName}
            onChange={(e) => setCohortName(e.target.value)}
            required
            helperText="Tip: use a descriptive name that will help you quickly understand what attributes you used in this cohort."
          />
        </Box>
      </ChortDialog>
    </>
  );
}

export default ExploreCreateCohort;



