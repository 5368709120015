import React, { useState, useEffect, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classes from "./index.module.css"
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DownArrow from '../../../assets/icons/W-DropDown.XS.svg'
import UpArrow from '../../../assets/icons/up-arrow.svg'
import SearchIcon from '../../../assets/icons/search-icon.svg';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { v4 } from 'uuid'
import Checkbox from '@mui/material/Checkbox';


const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
    }
}));


function DropDown(props) {

    const { searchable = false, customClass = "", values = [],
        handleFilterStateChange, selectedValues = [], disable, filterCategory, placeholder = 'Select', hideApplyFooter } = props

    const materialClasses = useStyles();
    const [open, setOpen] = React.useState(false);
    const [searchText, setSearchText] = useState("");
    const [currentState, setCurrentState] = useState([])

    const handleClick = () => {
        setOpen(prev => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
        handleCancelChanges()
    };

    const renderIcon = () => {
        if (open) {
            return <img className={classes.dropdownIcon} src={UpArrow} alt="Up Arrow" />
        } else {
            return <img className={classes.dropdownIcon} src={DownArrow} alt="downarrow" />
        }

    }

    const handleKeyDown = (event, type, item, filterCategory) => {
        if ((event.key === 'Tab' || event.key === 'Enter') && !open) {
            handleClick();
            event.preventDefault();
        }
        if (type === 'menuItem' && event.key === 'Enter') {
            handleFilterStateChange(item, filterCategory);
            handleClick();
        }
    }

    const getCurrentSelectedValues = () => {
        const finalValues = values?.map(item => {
            const isSelected = selectedValues?.find(x => x === item);
            return {
                key: item,
                isSelected: isSelected
            }
        }) || [];
        setCurrentState(finalValues)
    }

    useEffect(() => {
        getCurrentSelectedValues();
    }, [selectedValues, values])

    const handleApplyChanges = () => {
        const selectedItems = currentState.map(item => {
            if (item.isSelected) {
                return item.key
            };
        }).filter(x => x)
        handleFilterStateChange(selectedItems, filterCategory);
        setOpen(false);
    }

    const handleCancelChanges = () => {
        getCurrentSelectedValues();
        setOpen(false)
    }

    const handleItemChange = item => {
        const alteredState = currentState.map(x => {
            if (x.key === item.key) {
                x.isSelected = !x.isSelected
            }
            return x
        })
        setCurrentState(alteredState);
        if(hideApplyFooter){
            const selectedItems = currentState.map(item => {
                if (item.isSelected) {
                    return item.key
                };
            }).filter(x => x)
            handleFilterStateChange(selectedItems, filterCategory);
        }
    }

    const getDropdownPlaceHolder = () => {
        if (selectedValues?.length) {
            if (selectedValues.length > 2) {
                return <span>{selectedValues[0]}, {selectedValues[1]}...<span className={classes.moreFilterText}>{selectedValues.length - 2}More...</span></span>
            } else {
                return selectedValues.reduce((a, b) => a + ", " + b)
            }
        }
        return placeholder;
    }

    const getCurrentDropDownValues = useMemo(() => {
        if (searchText) {
            return currentState.filter(item => item?.key?.toLowerCase().includes(searchText.toLowerCase()))
        }
        return currentState;
    }, [currentState, searchText])

    const selectedItemsInfo = useMemo(() => {
        const totalItemsCount = currentState?.length || 0;
        let selectedItemsCount = 0;
        if (totalItemsCount) {
            selectedItemsCount = currentState.filter(item => item.isSelected)?.length || 0
        }
        return {
            totalItemsCount,
            selectedItemsCount
        }
    }, [currentState, searchText])

    const isAnyItemSelected = useMemo(() => {
        return selectedItemsInfo?.selectedItemsCount > 0
    }, [selectedItemsInfo])

    const isPartialSelected = useMemo(() => {
        if (selectedItemsInfo?.totalItemsCount == 0 || selectedItemsInfo?.selectedItemsCount == 0) {
            return false;
        }
        return selectedItemsInfo.totalItemsCount - selectedItemsInfo.selectedItemsCount != 0
    }, [selectedItemsInfo])

    const handleSelectAll = () => {
        const latestValue = !(isAnyItemSelected && !isPartialSelected)

        const finalValues = values?.map(item => {
            return {
                key: item,
                isSelected: latestValue
            }
        }) || [];
        setCurrentState(finalValues)
        if(hideApplyFooter){
            const selectedItems = finalValues.map(item => {
                if (item.isSelected) {
                    return item.key
                };
            }).filter(x => x)
            handleFilterStateChange(selectedItems, filterCategory);
        }
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={`${materialClasses.wrapper} ${classes.outline}`} tabIndex={0} onKeyDown={handleKeyDown}>
                <div className={`${classes.dropdownTitle} ${customClass}`} onClick={handleClick}  >
                    <span className={placeholder == getDropdownPlaceHolder() && classes.placeHolderDropDownText}>{getDropdownPlaceHolder()}<span>{renderIcon()}</span></span>
                </div>
                {open && !disable ? (
                    <div className={classes.moreFiltersWrapper}>
                        {
                            searchable &&
                            <div className={classes.searchDropdown}>
                                <TextField fullWidth type='text'
                                    value={searchText}
                                    placeholder='Search'
                                    onChange={e => setSearchText(e.target.value)}
                                    className='signup-inputs-large' name='password'
                                    disabled={disable}
                                    autoComplete="off"
                                    id='cvb-multiSelect-searchInput'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton aria-label="toggle password visibility" size="large">
                                                    <img alt='visibility On' className={classes.searchDropdownIcon} src={SearchIcon} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        }
                        <ul className={classes.moreFiltersPopover}>
                            {
                                getCurrentDropDownValues?.length > 0 &&
                                !searchText &&
                                <li className={classes.dropdownOptionItem} tabIndex={0}
                                    id={`menu-item-main-0`}
                                    key={v4()}
                                    
                                >
                                    <Checkbox
                                        checked={isAnyItemSelected}
                                        indeterminate={isPartialSelected}
                                        color="primary"
                                        onChange={handleSelectAll}
                                        id='cvb-multiSelect-checkbox'
                                    /> <b>{selectedItemsInfo?.selectedItemsCount || 0} / {selectedItemsInfo?.totalItemsCount || 0}</b>
                                </li>
                            }
                            {
                                getCurrentDropDownValues?.map((item, index) => {
                                    return <li className={classes.dropdownOptionItem} tabIndex={0}
                                        id={`menu-item-${index}`}
                                        key={v4()}
                                    >
                                        <Checkbox
                                            checked={item?.isSelected}
                                            color="primary"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            onChange={(e) => { handleItemChange(item) }}
                                        />
                                        {item?.key}
                                    </li>
                                })
                            }
                            {
                                !hideApplyFooter ?
                                <div className={classes.buttonContainer}>
                                <button
                                    id='cvb-multiSelect-cancelButton'
                                    className={`no-outlined-button solid-button ${classes.copyUrlButton}`}
                                    onClick={handleCancelChanges}>Cancel</button>
                                <button
                                    id='cvb-multiSelect-applyButton'
                                    className={`no-outlined-button solid-button ${classes.copyUrlButton}`}
                                    onClick={handleApplyChanges}>Apply</button>
                                </div> : null
                            }
                            
                        </ul>

                    </div>
                ) : null}
            </div>
        </ClickAwayListener>
    );
}

export default DropDown;
