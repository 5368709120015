import React, { useState, useEffect } from 'react'
import {env} from '../../../env'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import Classes from './index.module.css'
import EditProfile from './EditProfile';
import { navigate, Match } from '@reach/router';
import makeStyles from '@mui/styles/makeStyles';
import BackButtonArrow from '../../../assets/icons/left_arrow.png'
import withStyles from '@mui/styles/withStyles';
import { useSelector, useDispatch } from 'react-redux'
import { updateInitialOnboardState } from '../../../redux/actions/userOnboardActions'
import { editProfileInitialState } from '../../../redux/actions/editProfileActions'
import { Auth } from 'aws-amplify'
import EditTrainingAndCertificates from '../../controls/edit-training-and-certificate'
import { uploadProfilePicture, getUserProfile, updateUserProfile, getUserPermissions } from '../../../api/profile-api'
import { getResourceActions } from '../../../utils/resourceActionsFormatter';
import TellUsMore from '../tell-us-more';
const useStyles = makeStyles(theme => ({
    paper: {
        marginLeft: 34,
        boxShadow: 'none',
        marginTop: 6,
        marginBottom: 12,
        borderBottom: '2px solid #DEDEDE',
        marginRight: 50
    },
}))

const Tab = withStyles(theme => ({
    root: {
        minWidth: 50,
        padding: '6px 4px',
        marginRight: 30
    }
}))(MuiTab);

const EditProfileHome = props => {
    const styledClasses = useStyles();
    const { tabValue = 0 } = props;
    const reduxDispatcher = useDispatch();
    const editProfileProps = useSelector(state => state.editProfile)
    const userOnboardProps = useSelector(state => state.userOnboard)

    useEffect(() => {
        getInitialData();
    }, [])

    useEffect(() => {
        let tabVal = 0;
        switch (props.id) {
            case 'edit-about-me': tabVal = 0; break;
            case 'edit-contact-details': tabVal = 2; break;
            case 'edit-training-and-certificates': tabVal = 1; break;
            default: tabVal = 0; break;
        }
        props.setTabsValueInEditProfile(tabVal);
    }, [props.id, tabValue])

    const getInitialData = async () => {
        try {
            let userDetails = await Auth.currentUserInfo();
            const userInfo = await getUserProfile(userDetails?.attributes?.sub)
            userDetails = userInfo?.user_details;
            reduxDispatcher(updateInitialOnboardState(userDetails || {}))
            reduxDispatcher(editProfileInitialState(userDetails || {}))
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleChange = (event, newValue) => {
        if (newValue === 2) {
            navigate('/edit-profile/edit-contact-details')
        }
        if (newValue === 0) {
            navigate('/edit-profile/edit-about-me')
        }
        if (newValue === 1) {
            navigate('/edit-profile/edit-training-and-certificates')
        }
        props.setTabsValueInEditProfile(newValue);
    };

    const formSubmitBody = (customValues) => {
        let body = {};
        if (userOnboardProps.firstName?.text) {
            body['first_name'] = userOnboardProps.firstName.text
        }
        if (userOnboardProps.lastName?.text) {
            body['last_name'] = userOnboardProps.lastName.text
        }
        if (userOnboardProps.designation) {
            body['designation'] = userOnboardProps.designation
        }
        if (userOnboardProps.organization?.text) {
            body['organization'] = userOnboardProps.organization.text
        }
        if (customValues?.otherOrganizationName) {
            body['organization'] = customValues.otherOrganizationName
        }
        if (userOnboardProps.bio) {
            body['professional_bio'] = userOnboardProps.bio
        }
        if (userOnboardProps.tagList?.length > 0) {
            body['tags'] = userOnboardProps.tagList
        }
        body['phone_number'] = editProfileProps.contactNumber

        body['institution_details'] = {}
        body['institution_details']['institution_name'] = editProfileProps.institutionName

        body['institution_details']['address'] = editProfileProps.streetAddress
        body['institution_details']['city'] = editProfileProps.city
        body['institution_details']['state'] = editProfileProps.state
        body['institution_details']['zip_code'] = editProfileProps.zipCode
        body['institution_details']['country'] = editProfileProps.country

        body['institution_details']['title'] = userOnboardProps?.title?.text || ""
        body['institution_details']['department'] = userOnboardProps?.department?.text || ""
        body['institution_details']['sector'] = userOnboardProps?.sector?.text || ""
        body['institution_details']['degree'] = userOnboardProps?.degree?.text || ""
        return body;
    }

    const onSubmitEdit = async (customValues) => {
        try {
            const profileImageSource = userOnboardProps?.profileImage?.[0] || userOnboardProps.profileImage
            const body = formSubmitBody(customValues);
            let user = await Auth.currentAuthenticatedUser();
            if (user) {
                let profileUpdate = await updateUserProfile(user?.attributes?.sub, body);
                props.updateSnackBar('Profile updated successfully', 'success');
                const latestProfileDetails = await getUserProfile(user?.attributes?.sub);
                const userPermissions = await getUserPermissions();
                let resourceActions = getResourceActions(userPermissions);
                latestProfileDetails.user_details['permissions'] = userPermissions;
                latestProfileDetails.user_details['resourceActions'] = resourceActions;
                props.updateUserDetails(latestProfileDetails?.user_details || {})
                props.updatePullCount();
            }
        }
        catch (error) {
            console.log(error);
            props.updateSnackBar('Error while updating profile', 'error');
        }
    }

    return (
        <div className={Classes.editProfileMainContainer}>
            <div className={Classes.editProfileChildContainer}>
                <Grid container direction='column'>
                    <p id='cvb-editProfileHome-backButton' onClick={() => navigate('/my-profile')} className={Classes.backButton}>
                        <span><img alt='back button arrow' src={BackButtonArrow} /></span>
                        <p id='cvb-editProfileHome-backButtonText' className={Classes.backButtonText}>Back</p>
                    </p>
                    <p className={Classes.editProfileText}>Edit Profile</p>
                </Grid>
                <Paper square className={styledClasses.paper}>
                    <Tabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        classes={styledClasses.tabRoot}
                    >
                        <Tab id='aboutMe' label="About Me" />
                        <Tab id='trainingAndCert' label="Training certificate" />
                        {env.REACT_APP_COMMUNITY_HIDDEN !== 'true' && <Tab id='communityDetails' label="Community Details" />}
                    </Tabs>
                </Paper>

                <Match path="/edit-profile/edit-about-me">
                    {props =>
                        props.match ? (<EditProfile onSubmitEdit={onSubmitEdit} contactNumber={editProfileProps.contactNumber}/>) : ''
                    }
                </Match>
                <Match path="/edit-profile/edit-training-and-certificates">
                    {props =>
                        props.match ? (<EditTrainingAndCertificates onSubmitEdit={onSubmitEdit} />) : ''
                    }
                </Match>
                {env.REACT_APP_COMMUNITY_HIDDEN !== 'true' &&  <Match path="/edit-profile/tell-us-more/:id">
                    {props =>
                        props.match ? (<TellUsMore editProfile id={props.match.id} location={props.location} />) : ''
                    }
                </Match>}

            </div>
        </div>
    )
}

export default EditProfileHome