import React from 'react';
import withStyles from '@mui/styles/withStyles';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import ImgClose from '../../../assets/images/close.png'
import CustomClass from "./index.module.css"
import MuiDivider from '@mui/material/Divider';
import CustomLoader from '../../commons/custom-loader';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        maxHeight: '80vh',
        zIndex: 9999999,
        display: 'flex',
        alignItems: 'center'
    },
    closeButton: {
        position: 'absolute',
        right: 40,
        color: theme.palette.grey[500],
    },
});

const Divider = withStyles(theme => ({
    root: {
        position: 'absolute',
        width: '100%',
        bottom: 96,
        left: 0
    },
}))(MuiDivider);

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <p className={CustomClass.modalTitleStyle}>{children}</p>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    size="large">
                    <img className={CustomClass.closeIcon} src={ImgClose} alt="close" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const LargeDialog = withStyles(theme => {
    return {
        container: {
            backgroundColor: 'rgba(0, 0, 0, 0.38)',
            backdropFilter: 'blur(2px)'
        },
        paper: {
            borderRadius: 10,
            padding: '0',
            maxWidth: '70%',
            minWidth: 600
        }
    }
})(MuiDialog);

const SmallDialog = withStyles(theme => {
    return {
        container: {
            backgroundColor: 'rgba(0, 0, 0, 0.38)',
            backdropFilter: 'blur(5px)'
        },
        paper: {
            borderRadius: 10,
            padding: '14px 14px 0px 14px',
            maxWidth: 450,
            minWidth: 450
        }
    }
})(MuiDialog);

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: '28px 16px 28px 16px'
    },
}))(MuiDialogActions);

export default function CustomModal(props) {
    const { handleClose, open, dialogProps, disableTitleDivider = false,
        bothPositiveButtons = false, containerClass = "",
        disableBottomHorizontalDivider = false, enableImage = false, smallerDialog, buttonStyleProps = {} } = props;
    const { modalTitle, modalTitleClass, modalContent, positiveButtonText, negativeButtonText, positiveButtonAction,
        negativeButtonAction, additionalButtonText, additionalButtonAction, imageSrc, popupButtonDisable, loadStatus, positiveButtonClass } = dialogProps || {};
    const Dialog = smallerDialog ? SmallDialog : LargeDialog;
    return (
        <div >
            {loadStatus === true ? <div id='cvb-modal-progressBar' className={CustomClass.progressBar} ><CustomLoader /></div> :null}
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className={containerClass}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {!enableImage ?
                        <span id='cvb-modal-modalTitle' className={modalTitleClass}>{modalTitle}</span> :
                        <span id='cvb-modal-modalTitle'><img id='cvb-modal-warning-icon' src={imageSrc} alt='Caution Image' className={CustomClass.image} /> {modalTitle} </span>}
                </DialogTitle>
                <DialogContent id='cvb-modal-modalContent' dividers={!disableTitleDivider}>
                    {modalContent}
                    {props.children}
                </DialogContent>
                {!disableBottomHorizontalDivider && <Divider />}

                {!disableBottomHorizontalDivider && <Divider />}
                <DialogActions>
                    {
                        negativeButtonText &&
                        <button onClick={negativeButtonAction}
                            style={buttonStyleProps}
                            id='cvb-modal-negativeActionButton'
                            className={bothPositiveButtons ?
                                `medium-size-button solid-button ${CustomClass.requestAccessButton}, ${CustomClass.positiveActionButton}` :
                                CustomClass.negativeActionButton} >
                            {negativeButtonText}
                        </button>
                    }
                    {
                        additionalButtonText &&
                        <button onClick={additionalButtonAction}
                            style={buttonStyleProps}
                            id='cvb-modal-additionalActionButton'
                            className={bothPositiveButtons ?
                                `medium-size-button solid-button ${CustomClass.requestAccessButton}, ${CustomClass.positiveActionButton}` :
                                CustomClass.negativeActionButton} >
                            {additionalButtonText}
                        </button>
                    }
                    {
                        positiveButtonText &&
                        <button onClick={positiveButtonAction} disabled = {(popupButtonDisable)? "disabled" : ""}
                            style={buttonStyleProps}
                            id='cvb-modal-positiveActionButton'
                            className={`medium-size-button solid-button ${CustomClass.requestAccessButton}, ${CustomClass.positiveActionButton} ${positiveButtonClass}`}>
                            {positiveButtonText}
                        </button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}
