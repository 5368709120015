import React, { useEffect, useState, useMemo, useRef } from 'react';
import {env} from '../../../env'
import Classes from './index.module.css';
import SignatureCanvas from 'react-signature-canvas';
import ErrorText from '../error-text';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'
import OutlinedInput from '@mui/material/OutlinedInput'
import isEmpty from 'lodash/isEmpty'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import { navigate } from '@reach/router'
import {
  getUsersListForOwnershipChange,
  getAllWorkspaces, submitWorkspaceTransfer,
  getAllWorkspacesForUser
} from '../../../api/workspace-api/workspace-api'
import { Auth } from 'aws-amplify'
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SectionHeader from '../../commons/section-header'
import makeStyles from '@mui/styles/makeStyles';
import Checkbox from '@mui/material/Checkbox';

const WorkspaceOwnerShipChange = ({ id, userSub, updateSnackBar, userDetails }) => {

  const sigRef = useRef();

  const [userAttributes, setUserAttributes] = useState(null);
  const [usersList, setUsersList] = useState({})
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedWorkspaceOwnershipEmails, setSelectedWorkspaceOwnershipEmails] = useState({})
  const [signature, setSignature] = useState(null)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isAgreeSelected, setIsAgreeSelected] = useState(false)

  const sectionHeaderProps = {
    title: "Workspace Ownership Change",

  }
  const agreementText = env.REACT_APP_AGREEMENT_TEXT
  const useStyles = makeStyles((theme) => ({
    formControl: {
      maxWidth: 300,
      minWidth: 250,
      "& .MuiInputLabel-formControl": {

      },
      "& .MuiSelect-selectMenu": {
        border: "1px solid rgba(0, 0, 0, 0.42)!important",
        borderRadius: "5px"
      },
      "& .MuiInput-underline:before": {
        borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
        transition: "none"
      },
      "& .MuiSelect-select": {
        padding: "10px 10px"
      }
    },
    dropdownStyle: {
      color: "#000!important",

      "& .MuiListItem-button": {
        color: "#586477!important",
        height: "0px",
        padding: "20px 10px",
      },
    },


  }));
  const classes = useStyles();
  const fetchUsersListAndWorkspaces = async () => {
    try {
      const userValues = await Auth.currentUserInfo();
      let workspacesResponse = []
      setUserAttributes(userValues?.attributes)
      const sub = userSub ? userSub : userValues?.attributes?.sub;
      const response = await getUsersListForOwnershipChange(sub);
      setUsersList(response)
      if (userSub) {
        let workspacesResponseOfUser = await getAllWorkspacesForUser(userSub)
        if (workspacesResponseOfUser?.length) {
          for (let workspace of workspacesResponseOfUser) {
            workspacesResponse.push({
              workspace_id: workspace,
              pi_owner: true
            })
          }
        }
      } else {
        workspacesResponse = await getAllWorkspaces()
      }
      setWorkspaces(workspacesResponse)
    }
    catch (error) {
      console.log(error)
    }
  }
  const userSelectionForTransferValidation = ()=>{
    let validForm = true;
    if(Object.keys(selectedWorkspaceOwnershipEmails).length){
      for(let i = 0; i< listableWorkspaces.length; i++){
        if(selectedWorkspaceOwnershipEmails[listableWorkspaces[i]?.workspace_id]?.length){
          validForm = true;
          break;
        }else{
          validForm = false
        }
      }
    }else{
      validForm = false;
    }
    return validForm
  }
  const isFormValid = () => {
    let validForm = true;
    validForm = userSelectionForTransferValidation();

    if (!signature) {
      validForm = false
    }
    if (!isAgreeSelected) {
      validForm = false
    }
    return validForm;
  }

  const handleSubmitTransfer = async () => {
    setIsLoading(true)
    try {
      const body = []
      for (let workspace of workspaces) {
        if (selectedWorkspaceOwnershipEmails[workspace?.workspace_id]) {
          body.push({
            "workspace_owner": userSub ? userSub : userAttributes?.sub,
            "workspace_id": workspace?.workspace_id,
            "recipient": selectedWorkspaceOwnershipEmails[workspace?.workspace_id],
            "signature": signature
          })
        }
      }
      await submitWorkspaceTransfer(body)
      if(userSub){
        navigate('/users')
      }else{
        navigate('/my-stuff')
      }
      updateSnackBar('Workspace ownership is submitted successfully', 'success')
    }
    catch (error) {
      let errorMessage = "Something went wrong, Please try again later.";
      updateSnackBar(error?.response?.data?.error || errorMessage, 'error')
    }
    setIsLoading(false);
  }

  const onSubmitButtonClick = async () => {
    setIsFormSubmitted(true);
    if (isFormValid()) {
      handleSubmitTransfer();
    }
  }

  useEffect(() => {
    fetchUsersListAndWorkspaces()
  }, []);
  const onCancelButtonClick = () => {
    navigate('/explore/tools/workspace')
  }

  const onSignatureEnd = async () => {
    const sign = await sigRef?.current?.toDataURL();
    setSignature(sign);
  }

  const clearSignature = async () => {
    await sigRef.current.clear();
    setSignature(null);
  }

  const listableWorkspaces = useMemo(() => {
    return workspaces.filter(({ pi_owner, workspace_id }) => {
      return pi_owner && (id ? workspace_id == id : true)
    })
  }, [id, workspaces])

  return (
    <>
      <Grid container >
        <SectionHeader
          sectionHeaderProps={sectionHeaderProps}
        />

      </Grid>
      <div className={Classes.agreementContainer} id='cvb-dataContributorAgreement-stageOneFormContainer'>
        <div className={Classes.titleHeaderContainer}>
          <h1>Request for Workspace Ownership Change</h1>
        </div>
        <div className={Classes.topFormSection}>
          <div className={Classes.formField}>
            <label className={Classes.signatoryLabel}>Requestor Name</label>
            <OutlinedInput
              value={(userDetails?.first_name || '') + " " + (userDetails?.last_name || '')}
              disabled
              fullWidth
              id='cvb-dataContributorAgreement-contributorSigningName'
            />
          </div>
          <div className={Classes.formField}>
            <label className={Classes.signatoryLabel}>Requestor Email</label>
            <OutlinedInput
              value={userAttributes?.email || ''}
              disabled
              fullWidth
              id='cvb-dataContributorAgreement-contributorSigningName'
            />
          </div>
          <div className={Classes.formDescription}>
            {
              userSub ?
              <><p>a) As a BC Manager - Master I wish to transfer ownership of the Workspace(s) listed below. I understand that if I transfer ownership of the Workspace to a User with a different level of access all users whom the WorkSpace is currently shared with will lose access to it and the new owner will decide who they would like to share it with. </p>
              <p>b) The new owner will ensure that personal or otherwise confidential files that must not be stored on the Workspace are deleted from the Workspace as soon as possible after the Workspace has been transferred to them. BRAINCommons cannot be held responsible for any accidental disclosure of confidential information that results from the new owner’s omission to delete them.</p>
              <p> I would like the Workspace's ownership to be transferred to:</p></> : <> <p>a) I no longer wish to be the owner of this Workspace. I understand that if I transfer ownership of the Workspace to a User with a different level of access all users whom the Workspace is currently shared with will lose access to it and the new owner will decide who they would like to share it with. </p>
              <p>b) I confirm that all personal or otherwise confidential files that must not be shared with the new owner have been deleted from the Workspace and that BRAINCommons cannot be held responsible for any accidental disclosure of confidential information that results from my omission to delete them.</p>
              <p> I would like the ownerships of the following Workspaces to be transferred:</p></>
            }
          </div>
          <Grid container>
            <Grid item xs={7} md={7} lg={7}>
              <div className={Classes.workspaceAllocationTable}>
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell id='cvb-myCohorts-tableHeaderSearchName' className={Classes.typeColumn} width="25%">Workspace</TableCell>
                        <TableCell id='cvb-myCohorts-tableHeaderSavedOn' className={Classes.searchColumn} width="35%" align="left">Workspace owner</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        listableWorkspaces?.map(({ workspace_id }) => {
                          return <TableRow key={workspace_id}>
                            <TableCell id='cvb-myCohorts-tablCellCohortDate' component="th" scope="row">
                              {workspace_id}
                            </TableCell>
                            <TableCell id='cvb-myCohorts-tablCellCohortDate' component="th" scope="row">
                              <FormControl className={classes.formControl} sx={{ m: 1, minWidth: 600 }}>
                                <Select
                                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                                  defaultValue="none"
                                  onChange={e => {
                                    const previousValue = { ...selectedWorkspaceOwnershipEmails }
                                    previousValue[workspace_id] = e.target.value;
                                    setSelectedWorkspaceOwnershipEmails(previousValue)
                                  }} id="grouped-select" label="Grouping">
                                  <ListSubheader>Same Access</ListSubheader>
                                  {
                                    usersList?.["same_access"]?.length > 0 &&
                                    usersList?.["same_access"].map((email) => {
                                      return <MenuItem key={email} value={email}>{email}</MenuItem>
                                    })
                                  }
                                  <ListSubheader>Other users</ListSubheader>
                                  {
                                    usersList?.["other_users"]?.length > 0 &&
                                    usersList?.["other_users"].map((email) => {
                                      return <MenuItem key={email} value={email}>{email}</MenuItem>
                                    })
                                  }
                                </Select>
                              </FormControl>
                              {
                                isFormSubmitted && !(userSelectionForTransferValidation()) &&
                                <ErrorText errorMessage="Please select the workspace owner" />
                              }
                            </TableCell>
                          </TableRow>
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={Classes.agreementFormMainContainer} id='cvb-dataContributorAgreement-stageOneFormAgreement'>
          <div className={Classes.signFormInputContainer} id='cvb-dataContributorAgreement-stageOneAgreementContainer'>
            <div className={Classes.signatureContainer}>
              <p id='cvb-dataContributorAgreement-stageOneSignAgreementTitle'>
                <label>Sign Agreement<sup> *</sup></label>
              </p>
              <p id='cvb-dataContributorAgreement-stageOneSignAgreementDescription'>Sign your name on the trackpad and press any key when finished</p>
              <div id='signature-pad' className={Classes.signaturePad}>
                <p id='cvb-dataContributorAgreement-stageOneSignAgreementClickText' className={Classes.clickHeretoBegin}>Click here to begin</p>
                <SignatureCanvas penColor='black'
                  ref={sigRef}
                  canvasProps={{ width: 400, height: 150, className: 'sigCanvas' }}
                  onEnd={onSignatureEnd}
                />
                {
                  isFormSubmitted && isEmpty(signature) &&
                  <ErrorText errorMessage="Please sign the form" />
                }
              </div>
              <button id='cvb-dataContributorAgreement-stageOneSignAgreementClearLink' className={`no-outlined-button ${Classes.clearButton}`} onClick={clearSignature}>
                Clear
              </button>
            </div>
            <Grid item>
              <div className={Classes.agreeContainer}>
                <span className={Classes.agreeCheckbox}>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    value={isAgreeSelected}
                    onChange={(e) => setIsAgreeSelected(e.target.checked)}
                  />
                </span>
                <span className={Classes.agreeContent}>
                  {agreementText ? agreementText : "I confirm that by signing this Agreement I am legally bound by its terms and conditions" }
                </span>
              </div>
              {
                isFormSubmitted && !isAgreeSelected &&
                <ErrorText errorMessage="Please confirm agreement" />
              }
            </Grid>
          </div>
          <div className={Classes.signFormInputContainer}>
            <label className={Classes.signatoryLabel}>Name :</label>
            <OutlinedInput
              value={(userDetails?.first_name || '') + " " + (userDetails?.last_name || '')}
              disabled
              fullWidth
              id='cvb-dataContributorAgreement-contributorSigningName'
            />
          </div>
          <div className={Classes.signFormInputContainer}>
            <label className={Classes.signatoryLabel}>Date :</label>
            <OutlinedInput
              value={new Date().toLocaleDateString()}
              disabled
              fullWidth
              id='cvb-dataContributorAgreement-contributorSigningTitle'
            />
          </div>
        </div>

      </div>
      <div className={Classes.buttonContainer} id='cvb-dataContributorAgreement-buttonsContainer'>
        <div>
          <button id='cvb-dataContributorAgreement-cancelButton' className={`transparent-button medium-size-button ${Classes.cancelButton}`} onClick={() => onCancelButtonClick()}>Cancel</button>
          <button className={`solid-button medium-size-button ${Classes.saveDraftButton} `}
            onClick={() => onSubmitButtonClick()} disabled={isLoading}>
            Confirm Ownership Transfer
          </button>
        </div>
      </div>
    </>
  )
}

export default WorkspaceOwnerShipChange;