import React from 'react';
import Grid from '@mui/material/Grid'
import Classes from './index.module.css'
import ToolsCard from '../../controls/tools-card';
import { navigate } from '@reach/router';
import ScrollToTop from '../../commons/scroll-top';
import map from 'lodash/map'
import ToolsIcon from '../../../assets/icons/workspace_tool_icon.svg'
import ComingSoonIcon from '../../../assets/icons/comingsoon_brain.svg'
import SectionHeader from '../../commons/section-header';
import { sendGAEvents } from '../../../api/generic-api';
import { PAGES_DEFINITIONS } from '../../../constants/strings';

const CARD_DATA = [
    {
        cardName: 'WORKSPACE',
        icon: ToolsIcon,
        onClick: () => {
            sendGAEvents('workspace_selection', 'workspace_selection', 'selected workspace option');
            navigate('/explore/tools/workspace')
        }
    },
    {
        cardName: 'ENIGMA',
        icon: ComingSoonIcon,
        onClick: () => {}
    },
    {
        cardName: 'ANTs',
        icon: ComingSoonIcon,
        onClick: () => {}
    }
]

const sectionHeaderProps = {
    title: "Explore Tools",
    pageDefinition: PAGES_DEFINITIONS['tools']
}
const Tools = props => {
    return (
        <React.Fragment>
            <SectionHeader
                open={props.open}
                sectionHeaderProps={sectionHeaderProps}
            />
            <div className={Classes.container}>
                <Grid container spacing={3}>
                    {map(CARD_DATA, (cardData, i) => <Grid item xs={2}>
                        <ToolsCard key={i} cardData={cardData} />
                    </Grid>
                    )}
                </Grid>
                <ScrollToTop />
            </div>
        </React.Fragment>
    )
}

export default Tools;