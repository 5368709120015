import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import {env} from '../../../env'
import { COMMUNITY_URLS } from "../../../constants/urls";
import SideNavBar, { drawerWidth, drawerWidthClosed } from '../../controls/side-nav-bar'
import { Router, Redirect } from '@reach/router';
import AuthorisedRoute from '../../../root/AuthorisedRoute'
import TopNavBar from '../../controls/top-nav-bar'
import CssBaseline from '@mui/material/CssBaseline';
import Classes from './index.module.css'
import MyProfile from '../my-profile';
import ChangePassword from '../change-password';
import Explore from '../explore'
import Footer from '../../commons/footer'
import EditProfileHome from '../edit-profile';
import AnalysisHome from '../analysis';
import Workspace from '../workspace';
import WorkspaceFileDirectory from '../../controls/workspace-file-directory'
import WorkspaceOwnerShipChange from '../../controls/workspace-ownership-change'
import MyStuff from '../my-stuff';
import MyStuffAnalysis from '../my-stuff-analysis';
import PrivacyPolicy from '../../commons/privacy-policy'
import TermsOfUse from '../../commons/terms-of-use'
import Modal from '../../commons/modal'
import { Auth } from 'aws-amplify';
import { navigate } from '@reach/router'
import ZoneRequestAccess from '../zone-request-access'
import RequestAdvanceCompute from '../request-advance-compute';
import DataContributeAgreement from '../data-contributor-agreement'
import AdminDashboard from '../admin-dashboard'
import Deactivate from '../deactivate'
import Users from '../users'
import Community from "../community";
import CommunityConstellation from "../community-constellation";
import CommunityConnections from "../community-connections";
import CommunityRecommended from "../community-recommended";
import CommunityConnectionsAccepted from "../community-connections-accepted";
import CommunityConnectionsIncoming from "../community-connections-incoming";
import CommunityConnectionsPending from "../community-connections-pending";
import CommunityMessages from "../community-messages";
import CommunitySearchResults from "../community-search-results";
import AdminUserProfile from '../admin-profile'
import DataInventoryForm from '../data-inventory-form'
import DataModel from '../data-model'
import MyUploads from '../my-uploads'
import DataInventoryFormStatus from '../data-inventory-form-status'
import { acceptPrivacyPolicy, acceptTnc } from '../../../api/profile-api'
import TellUsMore from '../tell-us-more'
import SessionTimer from './SessionTimer'
import AllNotificationsView from '../all-notifications';
import ChangeNotification from '../change-notification';
import CommunityConnectionRequests from "../community-connection-requests";
import WorkspaceDetails from '../../controls/workspace-details/WorkspaceDetails'
import WhiteList from '../white-list';
import Invoice from '../invoice'
import Pricing from '../pricing';
import Cart from '../cart';
import MyAccount from '../my-account';
import WorkspaceFileDownloadRequestDetails from '../workspace-file-download-request';
import ProjectGallery from '../project-gallery';

const styles = {
    root: {
        display: 'flex',
        flex: 1,
        
        overflow: 'hidden',
    },
    toolbar: theme => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    }),
    content: {
        flexGrow: 1,
        padding: 0,
        width: '100%',
        minHeight: "100vh"
    },
};

const AuthenticatedLanding = props => {
    const [open, setOpen] = useState(true)
    const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState(false);
    const [openTermsOfUseModal, setOpenTermsOfUseModal] = useState(false);
    const [declineModal, setDeclineModal] = useState(false)
    const [modalIndex, setModalIndex] = useState(null)
    const [shouldShowModal, setShowShouldModal] = useState(undefined)
    const [isAdmin, setIsAdmin] = useState(undefined)
    const [isSubmitter, setIsSubmitter] = useState(false)
    const [currentUserDetails, setCurrentUserDetails] = useState({});
    const [termsOfUseButtonDisable, setTermsOfUseButtonDisable] = useState(true);
    const [privacyPolicyButtonDisable, setPrivacyPolicyButtonDisable] = useState(true);
    const [sessionTimerON, setSessionTimerON] = useState(true);
    const [profileClick, doProfileClick] = useState(0)

    useEffect(() => {
        checkFlag();
    }, [props.userDetails?.custom_attributes])

    const getCurrentUserDetails = async () => {
        try {
            const userValues = await Auth.currentUserInfo();
            setCurrentUserDetails(userValues);
        } catch (err) {
            console.log('error in getting user details')
        }
    }


    const getUserRole = async () => {
        try {
            const user = await Auth.currentSession();
            const cognitoGroups = user?.accessToken?.payload?.["cognito:groups"] || [];
            const bcAdmin = cognitoGroups.find(item => item === "bc-admin")
            const bcSubmitter = cognitoGroups.find(item => item === "data-submitter")
            const partnerAdmin = cognitoGroups.find(item => item === "partner-admin")
            const projectAdmin = cognitoGroups.find(item => item === "project-administrator")
            setIsAdmin(!!bcAdmin || !!bcSubmitter || !!partnerAdmin || !!projectAdmin)
            setIsSubmitter(!!bcSubmitter)

        } catch (error) {
            setIsAdmin(false)
            setIsSubmitter(false)

        }
    }
    
    useEffect(() => {
        getCurrentUserDetails()
        getUserRole();
    }, [])

    const checkFlag = async (byPassCheck) => {
        try {
            if ((props.userDetails?.custom_attributes?.privacy_policy &&
                props.userDetails?.custom_attributes?.tnc) || byPassCheck) {
                const userInfo = props.userDetails
                if (!userInfo?.custom_attributes?.["onboarding_check"]) {
                    navigate("/onboarding")
                    setShowShouldModal(false);
                }
                else {
                    setShowShouldModal(false);
                }
            }
            else {
                setShowShouldModal(true)
                setOpenTermsOfUseModal(true);
                setModalIndex(1);
            }
        }
        catch (error) {
            console.log(error)
        }
    }


    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const toggleDrawer = () => {
        setOpen(open => !open)
    }

    useEffect(() => {
        window.Beacon('init', '01f433bc-e8e1-4ee4-afce-83a3cc20fac5')
    }, [])

    const toggleTermOfUseModal = async value => {
        try {
            setTermsOfUseButtonDisable(true);
            let user = await Auth.currentAuthenticatedUser();
            await acceptTnc(user?.attributes?.sub)
            setOpenTermsOfUseModal(false);
            setOpenPrivacyPolicyModal(true);
            setModalIndex(2);
        } catch (error) {
            setTermsOfUseButtonDisable(false);
            console.log(error.message)
        }
    }

    const togglePrivacyPolicyModal = async value => {
        try {
            setPrivacyPolicyButtonDisable(true);
            let user = await Auth.currentAuthenticatedUser();
            await acceptPrivacyPolicy(user?.attributes?.sub)
            setOpenPrivacyPolicyModal(false);
            setOpenPrivacyPolicyModal(false);
            setModalIndex(-1);
            checkFlag(true)
        } catch (error) {
            setPrivacyPolicyButtonDisable(false);
            console.log(error.message)
        }
    }

    const handleDeclineOnTermsOrPrivacy = () => {
        toggleDeclineModal(true);

    }

    const toggleDeclineModal = value => {
        setDeclineModal(value)
    }

    const handleNegativeDeclineModal = () => {
        logout();
        setDeclineModal(false);
    }

    const toggleCloseDeclineModal = () => {
        setDeclineModal(false);
    }

    const logout = async () => {
        try {
            await Auth.signOut().catch(e => { })
            const cohortsColumns = localStorage.getItem('myCohortsColumns');
            const projectsColumns = localStorage.getItem('availableProjectsColumns');
            localStorage.clear();
            localStorage.setItem('myCohortsColumns', cohortsColumns);
            localStorage.setItem('availableProjectsColumns', projectsColumns);
            sessionStorage.clear();
            window.location.href = "/"
        } catch (error) {
            console.log(error)
        }
    }

    const onPositiveDeclineBtnAction = () => {
        if (modalIndex === 1) {
            setOpenTermsOfUseModal(true);
        }
        else if (modalIndex === 2) {
            setOpenPrivacyPolicyModal(true)
        }
        toggleDeclineModal(false)
    }

    const PRIVACY_POLICY_MODAL = {
        modalTitle: "Privacy Notice",
        modalContent: "",
        positiveButtonText: "I Accept",
        negativeButtonText: "Decline",
        positiveButtonAction: togglePrivacyPolicyModal,
        negativeButtonAction: handleDeclineOnTermsOrPrivacy,
        popupButtonDisable: privacyPolicyButtonDisable
    }

    const TERM_OF_USE_MODAL = {
        modalTitle: "Terms Of Use",
        modalContent: "",
        positiveButtonText: "I Accept",
        negativeButtonText: "Decline",
        positiveButtonAction: toggleTermOfUseModal,
        negativeButtonAction: handleDeclineOnTermsOrPrivacy,
        popupButtonDisable: termsOfUseButtonDisable
    }

    const DECLINE_MODAL = {
        modalTitle: "Are you sure you want to decline?",
        positiveButtonText: "Continue Reading",
        negativeButtonText: "Abort Signup",
        positiveButtonAction: onPositiveDeclineBtnAction,
        negativeButtonAction: handleNegativeDeclineModal
    }

    if (shouldShowModal === undefined) {
        return <div>Loading..</div>
    }


    const profileClickHandler = ( ) => {
        doProfileClick(prev => prev + 1)
    }


    const forceUpdate = () => {
        setSessionTimerON(false)
        setTimeout(() => {
            setSessionTimerON(true)
        }, 100)
    }
    return (
        <Box>
            {
                sessionTimerON &&
                <SessionTimer forceUpdate={forceUpdate} logout={logout} />
            }
            <Box className={Classes.landingRoot}>
                {
                    shouldShowModal === false &&
                    <React.Fragment>
                        <TopNavBar
                            open={open}
                            handleDrawerOpen={handleDrawerOpen}
                            profileClickHandler={profileClickHandler}
                            />
                        <SideNavBar
                            isAdmin={isAdmin}
                            isSubmitter={isSubmitter}
                            open={open}
                            toggleDrawer={toggleDrawer} 
                            profileClick={profileClick}
                            />
                        <CssBaseline />
                        <Box sx={{ ...styles.root, width: `calc(100% -${open ? drawerWidth: drawerWidthClosed}px` }}>
                            <Box component="main" sx={styles.content}>
                                <Box sx={styles.toolbar} />
                                <Box className={Classes.childRoutesContainer}>
                                    {

                                        <Router id="router" primary={false}>
                                            <AuthorisedRoute component={AdminDashboard} open={open} path="/dashboard" exact currentUserDetails={currentUserDetails} isUserAuthorised={props.userDetails?.resourceActions?.dashboard || false} />
                                            <AuthorisedRoute component={WorkspaceDetails} path="/dashboard/workspace/:id" dashboard currentUserDetails={currentUserDetails} isUserAuthorised={props.userDetails?.resourceActions?.dashboard?.view_workspace_cost || false} />
                                            <AuthorisedRoute component={WorkspaceDetails} path="/explore/tools/workspace-cost/:id" workspace currentUserDetails={currentUserDetails} isUserAuthorised={props.userDetails?.resourceActions?.explore_tools?.explore_tools || false} />
                                            <AuthorisedRoute path="/users" component={Users} isUserAuthorised={props.userDetails?.resourceActions?.users || false} />
                                            <AuthorisedRoute path="/users/invited-users" component={WhiteList} isUserAuthorised={props.userDetails?.resourceActions?.users?.view_invited_users || false} />
                                            <AuthorisedRoute path="/pricing" component={Pricing} isUserAuthorised={props.userDetails?.resourceActions?.pricing || false} />
                                            <AuthorisedRoute path="/pricing/*" component={Pricing} isUserAuthorised={props.userDetails?.resourceActions?.pricing || false} />
                                            <AuthorisedRoute path="/cart" component={Cart} isUserAuthorised={props.userDetails?.resourceActions?.my_profile || false} />
                                            <AuthorisedRoute path="/cart/*" component={Cart} isUserAuthorised={props.userDetails?.resourceActions?.my_profile || false} />
                                            <AuthorisedRoute component={MyProfile} path="/my-profile" open={open} isUserAuthorised={props.userDetails?.resourceActions?.my_profile || false} />
                                            <AuthorisedRoute component={EditProfileHome} path="/edit-profile/*id" isUserAuthorised={props.userDetails?.resourceActions?.my_profile || false} />
                                            <AuthorisedRoute component={ChangePassword} path="/change-password" isUserAuthorised={true} />
                                            <AuthorisedRoute component={ChangeNotification} path="/change-notification" isUserAuthorised={true} />
                                            <AuthorisedRoute component={AdminUserProfile} path="/admin/user-profile/:id/*" isUserAuthorised={props.userDetails?.resourceActions?.user_profile || false} />
                                            <AuthorisedRoute component={Explore} path="/" exact open={open} isUserAuthorised={true} />
                                            <AuthorisedRoute component={MyProfile} path="/my-profile/*" open={open} isUserAuthorised={props.userDetails?.resourceActions?.my_profile || false} />
                                            <AuthorisedRoute component={ZoneRequestAccess} path="/my-profile/request-access" isUserAuthorised={props.userDetails?.resourceActions?.my_profile || false} />
                                            <AuthorisedRoute component={RequestAdvanceCompute} path="/my-profile/request-advance-compute" isUserAuthorised={props.userDetails?.resourceActions?.my_profile || false} />
                                            <AuthorisedRoute component={RequestAdvanceCompute} path="/my-profile/request-advance-compute/:id" isUserAuthorised={props.userDetails?.resourceActions?.my_profile || false} />
                                            <AuthorisedRoute component={Explore} path="/explore/*" exact open={open} isUserAuthorised={props.userDetails?.resourceActions?.explore?.explore || false} />
                                            <AuthorisedRoute component={AnalysisHome} path="/explore/analysis/*" open={open} isUserAuthorised={props.userDetails?.resourceActions?.explore?.explore || false} />
                                            <AuthorisedRoute component={MyStuff} path="/my-stuff/*" open={open} isUserAuthorised={props.userDetails?.resourceActions?.my_stuff || false} />
                                            <AuthorisedRoute component={MyStuffAnalysis} path="/my-stuff-analysis/:id" open={open} isUserAuthorised={props.userDetails?.resourceActions?.my_stuff?.list_cohorts || false} />
                                            <AuthorisedRoute component={Workspace} path="/explore/tools/workspace/*" exact open={open} isUserAuthorised={props.userDetails?.resourceActions?.explore_tools?.explore_tools || false} />
                                            <AuthorisedRoute component={WorkspaceFileDirectory} path="/explore/tools/workspace/:id" open={open} isUserAuthorised={props.userDetails?.resourceActions?.explore_tools?.explore_tools || false} />
                                            <AuthorisedRoute component={WorkspaceOwnerShipChange} path="/explore/tools/workspace/ownership-change/:id" open={open} isUserAuthorised={props.userDetails?.resourceActions?.explore_tools?.explore_tools || false} />
                                            <AuthorisedRoute component={WorkspaceOwnerShipChange} path="/explore/tools/workspace/ownership-change/user/:userSub" open={open} isUserAuthorised={props.userDetails?.resourceActions?.explore_tools?.explore_tools || false} />
                                            <AuthorisedRoute component={WorkspaceOwnerShipChange} path="/explore/tools/workspace/ownership-change" open={open} isUserAuthorised={props.userDetails?.resourceActions?.explore_tools?.explore_tools || false} />
                                            <AuthorisedRoute component={DataInventoryForm} path='/my-stuff/data-inventory-form/:id/:requesterSub' isUserAuthorised={props.userDetails?.resourceActions?.my_stuff?.submit_dif || props.userDetails?.resourceActions?.dashboard?.view_data_inventory_form || false} />
                                            <AuthorisedRoute component={DataInventoryForm} path='/dashboard/data-inventory-form/:id/:requesterSub' isUserAuthorised={props.userDetails?.resourceActions?.my_stuff?.submit_dif || props.userDetails?.resourceActions?.dashboard?.view_data_inventory_form || false} />
                                            <AuthorisedRoute component={DataInventoryForm} path='/my-stuff/data-inventory-form' isUserAuthorised={props.userDetails?.resourceActions?.my_stuff?.submit_dif || false} />
                                            <AuthorisedRoute component={DataInventoryFormStatus} path='/my-stuff/form-status/*id' isUserAuthorised={props.userDetails?.resourceActions?.my_stuff?.submit_dif || false} />
                                            <AuthorisedRoute component={Deactivate} path='/my-profile/deactivate' isUserAuthorised={props.userDetails?.resourceActions?.my_profile || false} />
                                            <AuthorisedRoute component={DataContributeAgreement} path="/my-stuff/data-contribute-agreement/:id/:requesterSub" open={open} isUserAuthorised={props.userDetails?.resourceActions?.my_stuff?.submit_dca || props.userDetails?.resourceActions?.dashboard?.view_data_contribution_agreement || false} />
                                            <AuthorisedRoute component={DataContributeAgreement} path="/dashboard/data-contribute-agreement/:id/:requesterSub" open={open} isUserAuthorised={props.userDetails?.resourceActions?.my_stuff?.submit_dca || props.userDetails?.resourceActions?.dashboard?.view_data_contribution_agreement || false} />
                                            <AuthorisedRoute component={DataContributeAgreement} path="/my-stuff/data-contribute-agreement" open={open} isUserAuthorised={props.userDetails?.resourceActions?.my_stuff?.submit_dca || false} />
                                            <AuthorisedRoute component={ProjectGallery} path="/explore/project-gallery" isUserAuthorised={props.userDetails?.resourceActions?.explore?.explore || false} />
                                            <AuthorisedRoute component={DataModel} path="/explore/data-model" isUserAuthorised={props.userDetails?.resourceActions?.explore?.explore || false} />
                                            <AuthorisedRoute component={Invoice} path="/invoice" isUserAuthorised={props.userDetails?.resourceActions?.invoice || false} />
                                            <AuthorisedRoute component={Invoice} path="/invoice/*" isUserAuthorised={props.userDetails?.resourceActions?.invoice || false} />
                                            <AuthorisedRoute component={EditProfileHome} path="edit-profile/tell-us-more/community-welcome/" isUserAuthorised={env.REACT_APP_COMMUNITY_HIDDEN !== 'true'} />
                                            <AuthorisedRoute component={Community} path='/community/*' open={open} isUserAuthorised={env.REACT_APP_COMMUNITY_HIDDEN !== 'true'} />
                                            <AuthorisedRoute component={Community} path={COMMUNITY_URLS.COMMUNITY} open={open} isUserAuthorised={(env.REACT_APP_COMMUNITY_HIDDEN !== 'true' & props.userDetails?.resourceActions?.explore_community?.explore_community) || false} >
                                                <CommunityConstellation default path={COMMUNITY_URLS.CONSTELLATION} />
                                                <CommunityConnections path={COMMUNITY_URLS.COMMUNITY_CONNECTIONS}>
                                                    <CommunityRecommended path={COMMUNITY_URLS.RECOMMENDED} />
                                                    <CommunityConnectionRequests default path={COMMUNITY_URLS.REQUESTS}>
                                                        <CommunityConnectionsAccepted default path={`${COMMUNITY_URLS.REQUESTS}${COMMUNITY_URLS.ACCEPTED_REQUESTS}`} />
                                                        <CommunityConnectionsIncoming path={`${COMMUNITY_URLS.REQUESTS}${COMMUNITY_URLS.INCOMING_REQUESTS}`} />
                                                        <CommunityConnectionsPending path={`${COMMUNITY_URLS.REQUESTS}${COMMUNITY_URLS.PENDING_REQUESTS}`} />
                                                    </CommunityConnectionRequests>
                                                    <CommunityMessages path={COMMUNITY_URLS.MESSAGES} />
                                                    <CommunitySearchResults path={COMMUNITY_URLS.SEARCH_RESULTS} />
                                                </CommunityConnections>
                                            </AuthorisedRoute>
                                            <AuthorisedRoute component={MyUploads} path="my-uploads/*" isUserAuthorised={props.userDetails?.resourceActions?.data_upload_history || props.userDetails?.resourceActions?.structured_data || props.userDetails?.resourceActions?.unstructured_data || false} />
                                            <AuthorisedRoute component={TellUsMore} myProfile path="/my-profile/tell-us-more/*" isUserAuthorised={env.REACT_APP_COMMUNITY_HIDDEN !== 'true'} />
                                            <AuthorisedRoute component={TellUsMore} myProfile path="/my-profile/tell-us-more/community-welcome" isUserAuthorised={env.REACT_APP_COMMUNITY_HIDDEN !== 'true'} />
                                            <AuthorisedRoute component={TellUsMore} myProfile path="/tell-us-more/*" isUserAuthorised={env.REACT_APP_COMMUNITY_HIDDEN !== 'true'} />
                                            <AuthorisedRoute component={TellUsMore} myProfile path="/my-profile/tell-us-more/:id" isUserAuthorised={props.userDetails?.resourceActions?.my_profile || false} />
                                            <AuthorisedRoute component={AllNotificationsView} open={open} path="/view-all-notifications" isUserAuthorised={props.userDetails?.resourceActions?.my_profile || false} />
                                            <AuthorisedRoute currentUserDetails={currentUserDetails} component={MyAccount} open={open} path="/my-account" isUserAuthorised={props.userDetails?.resourceActions?.my_profile || false} />
                                            <AuthorisedRoute currentUserDetails={currentUserDetails} component={WorkspaceFileDownloadRequestDetails} open={open} path="/workspace-file-download-request/:workspaceId/:orderId" isUserAuthorised={props.userDetails?.resourceActions?.my_profile || false} />
                                            <AuthorisedRoute currentUserDetails={currentUserDetails} component={WorkspaceFileDownloadRequestDetails} open={open} path="/project-file-download-request/:projectId/:orderId" isUserAuthorised={props.userDetails?.resourceActions?.my_profile || false} />
                                            <Redirect from="/" to="/" default noThrow />
                                        </Router>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </React.Fragment>
                }
            </Box>
            <Footer />
            <PrivacyPolicy
                open={openPrivacyPolicyModal}
                handleClose={handleDeclineOnTermsOrPrivacy}
                dialogProps={PRIVACY_POLICY_MODAL}
                setPrivacyPolicyButtonDisable={setPrivacyPolicyButtonDisable}
                showAgreeCheckbox={true}
                 />
            <TermsOfUse
                open={openTermsOfUseModal}
                handleClose={handleDeclineOnTermsOrPrivacy}
                dialogProps={TERM_OF_USE_MODAL}
                setTermsOfUseButtonDisable={setTermsOfUseButtonDisable}
                showAgreeCheckbox={true}
            />
            <Modal
                open={declineModal}
                handleClose={toggleCloseDeclineModal}
                dialogProps={DECLINE_MODAL}
                disableTitleDivider={true}>
                <p className={Classes.declineModalSubHeading} >You need to accept privacy notice & terms and
                    <br />conditions in order to register in BRAINCommons.</p>
            </Modal>
        </Box>
    )
}

export default AuthenticatedLanding
