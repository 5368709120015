import React, { useState, useRef, useEffect } from 'react'
import Classes from './index.module.css'
import Grid from '@mui/material/Grid'
import BackButtonArrow from '../../../assets/icons/left_arrow.png'
import DataContributorAgreementStage from '../../controls/data-contribute-agreement-stage'
import InstitutionalCertificationStage from '../../controls/institutional-certification-stage'
import DefinitionAndContributeDataStage from '../../controls/definition-and-contributed-data-stage'
import ContributedDatasetStage from '../../controls/contributed-dataset-stage';
import isEmpty from 'lodash/isEmpty';
import { scrollToGivenTop, validateEmailAddress } from '../../../utils/input-validations'
import moment from 'moment'
import { updateDCAForm, getDCAFormFields } from '../../../api/dca-form-api'
import get from 'lodash/get'
import { navigate } from '@reach/router'
import { getDCANextFormStage, getDCACurrentFormStage, getStageNumberFromRes } from '../../../utils/stringFormatter'
import CustomLoader from '../../commons/custom-loader'
import Modal from '../../commons/modal'
import { DCA_FORM_COMPLETED_TEXT } from '../../../constants/strings'
import map from 'lodash/map'
import { getUserProfile } from '../../../api/profile-api'
import { Auth } from 'aws-amplify'
import capitalize from 'lodash/capitalize';
import useScrollToTop from '../../commons/scroll-top';
import { sendGAEvents } from '../../../api/generic-api'

const DataContributorAgreement = props => {
    const {
        dcaClientName = '',
        dcaAgreementDate = null,
        dcaContributorName = '',
        dataContributorAgreementSignature = null,
        projectDetailsProjectName = '',
        projectDetailsSynopsisText = '',
        projectDetailsDesignatedZone = '',
        projectDetailsIsDatasetDownloadable = '',
        projectDetailsTypeOfData = '',
        isResearcherAllowed = '',
        projectDetailsRestrictionOfUSeText= '',
        projectDetailsOtherTypeDataText = '',
        projectDetailsAckStatement = '',
        projectDetailsIRBApprovalNumber = '',
        projectDetailsTotalSizeOfData = '',
        projectDetailsIRBNumberFile = {},
        irbApprovalCopyS3Key = '',
        ppiFirstName= '',
        ppiLastName= '',
        ppiMiddleName= '',
        ppiCredentials= '',
        ppiInstituteName= '',
        ppiInstituteEmail= '',
        ppiInstituteStreetAddress= '',
        ppiInstituteCity= '',
        ppiInstituteState= '',
        ppiInstituteZipCode= '',
        ppiInstituteCountry= '',
        projectAdminFirstName= '',
        projectAdminLastName= '',
        projectAdminMiddleName= '',
        projectAdminCredentials= '',
        projectAdminInstituteName= '',
        projectAdminInstituteEmail= '',
        projectAdminInstituteStreetAddress= '',
        projectAdminInstituteCity= '',
        projectAdminInstituteState= '',
        projectAdminInstituteZipCode= '',
        projectAdminInstituteCountry= '',
        projectPIFirstName = '',
        projectPILastName = '',
        projectPIMiddleName = '',
        projectPICredentials = '',
        projectPIAffiliation = '',
        projectPIInstituteEmail = '',
        adminName = '',       
        id = '',
        requesterSub = '',
        zone3Users = [],
        authorizedSubmitters = [],
        currentDCAFormStage,
        acknowledgmentSignature = '',
        } = props;
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [presentUUID, setPresentUUID ] = useState(id);
    const [ submitDCAModal, setSubmitDCAModal ] = useState(false);
    const [ cancelDCAModal, setCancelDCAModal ] = useState(false);
    const [ saveAsDraftModal, setSaveAsDraftModal ] = useState(false);
    const { dcaStage = 1 } = props;
    const { setDCAStage = () => {} } = props;
    const {setProjectDetailsFields = () => {} } = props;
    const {setDCAAllFormFields = ()=> {} }= props;
    const {setProjectInstitutionalFields = ()=>{} } = props;
    const {setProjectAdministratorFields = ()=>{}} = props;
    const {setProjectPIFields = ()=>{}} = props;
    const {setZone3UsersFields = ()=>{}} = props;
    const {setAuthorizedDataSubmittersFields = ()=>{}} = props;
    const {updateSnackBar = ()=>{}} = props;
    const dcaRef = useRef(null);
    const instituteCertRef = useRef(null);
    const acknowledgementRef = useRef(null);
    const nextButtonRef = useRef(null);
    const [currentFormStage, setCurrentFormStage ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [isFormUpdating, setIsFormUpdating] = useState(false);
    const fullNameOfUser = (capitalize(userDetails?.["first_name"]) + " " + capitalize(userDetails?.["last_name"]))
    const { setCurrentDCAFormStage = () => {}} = props;
    const { setInstitutionalCertAdminName = ()=> { }} = props;
    const isDashboard = get(props, 'location.state.dashboard', false);
    const disableTextInput =  (currentDCAFormStage === DCA_FORM_COMPLETED_TEXT);
    const createDCASign = async (data) => (!isEmpty(data) && !isEmpty(dcaRef.current)) && (await dcaRef?.current?.fromDataURL(data));
    const createAckSign = async (data) => (!isEmpty(data) && !isEmpty(acknowledgementRef.current)) && (await acknowledgementRef?.current?.fromDataURL(data));
    const createInstituteSign = async (data) => (!isEmpty(data) && !isEmpty(instituteCertRef.current)) && (await instituteCertRef?.current?.fromDataURL(data));
    const clearDCASign = async () => !isEmpty(dcaRef.current) && (await dcaRef.current.clear());
    const clearAckSign = async () => !isEmpty(acknowledgementRef.current) && (await acknowledgementRef.current.clear());
    const clearInstituteSign = async () => !isEmpty(instituteCertRef.current) && (await instituteCertRef.current.clear());
    const [isAgreeSelected, setIsAgreeSelected] = useState(false)
    const [isAckAgreeSelected, setIsAckAgreeSelected] = useState(false)
   
    const loadInitialData = async () => {
        try{
            if(!isEmpty(id)){
                setIsLoading(true);
                const res = await getDCAFormFields(requesterSub, id);
                setIsLoading(false);
                const formStage = get(res, 'stage', '');
                const stageNumber = getStageNumberFromRes(formStage);
                setCurrentFormStage(stageNumber);
                const projectDetailsFields = get(res, 'projectDetails', {});
                const projectInstitutionalFields = get(res, 'projectPrimaryInstitutionalContact', {});
                const projectAdministratorFields = get(res, 'projectAdministrator', {});
                const projectPIFields = get(res, 'projectPI', {});
                const zone3UserData = get(res, 'zone3AuthorizedUsers', []);
                const authorizedDatasetSubmittersFields = get(res, 'authorizedDatasetSubmitters', []);
                setCurrentDCAFormStage(formStage);
                await setDCAAllFormFields(res);
                await setProjectDetailsFields(projectDetailsFields);
                await setProjectInstitutionalFields(projectInstitutionalFields);
                await setProjectAdministratorFields(projectAdministratorFields);
                await setProjectPIFields(projectPIFields);
                await setZone3UsersFields(zone3UserData);
                await setAuthorizedDataSubmittersFields(authorizedDatasetSubmittersFields);
                await setInstitutionalCertAdminName(get(res, 'adminName', ''));
                await createDCASign(get(res, 'dataContributorAgreementSignature', ''));
                await createAckSign(get(res, 'acknowledgementSignature', ''));
                await createInstituteSign(get(res, 'institutionalCertificationSignature', ''));
            }else{
                setCurrentDCAFormStage(1);
                clearDCASign();
                clearAckSign();
                clearInstituteSign();
            }   
            
        }catch(error){
            console.log('error in getting dca', error)
        }
    }
    const getUserData = async () => {
        try {
            const userValues = await Auth.currentUserInfo();
            const userInfo = await getUserProfile(userValues?.attributes?.sub)
            setUserDetails(userInfo?.user_details || {})
        }
        catch (error) {
            console.log('error in getting user details')
        }
    }


    useEffect(() => {
        loadInitialData();
        getUserData();
    }, [])
    const backHandle = () => {
        const stepsForStepper = projectDetailsDesignatedZone === 'zone3';
        
        if(dcaStage === 1){
            if(!isDashboard){
            navigate('/my-stuff')
            }else{
                navigate('/dashboard')
            }
        }else{
            if(!stepsForStepper && dcaStage === 8){
                setDCAStage(dcaStage - 2);
            }else{
                setDCAStage(dcaStage - 1);
            }
        }
    }
    useScrollToTop();
    const onCancelClick = () => {
        
        if(!isDashboard){
            navigate('/my-stuff')
            }else{
                navigate('/dashboard')
            }
    }
    const scrollToZone3ErrorFields = index => {
            const zone3ErrorElement = document.getElementById(`lastName${index}`);
            scrollToGivenTop(zone3ErrorElement);
    }
    const validateZone3UserForm = () => {
        let isFormValid = false;
        map(zone3Users, (usersData, index)=>{
            for(let key in usersData){
                const data = usersData[key];
                const isEmptyField = (data === null && key !== 'middleName') ? false : (key !== 'middleName' ? !isEmpty(data) : true);
                const isEmailValid = key === 'institutionalEmail' ? validateEmailAddress(usersData[key]) : true; 
                if(isEmptyField && isEmailValid){
                    isFormValid = true;
                }else{
                    isFormValid = false;
                    scrollToZone3ErrorFields(index);
                    break;
                }
            }
        })
        return isFormValid;
    }
    const scrollToUsersFields = (index) => {
        const usersErrorElement = document.getElementById(`lastName${index}`);
        scrollToGivenTop(usersErrorElement);
    }
    const validUsersData = () => {
        let isFormValid = false;
        map(authorizedSubmitters, (usersData, index)=>{
            for(let key in usersData){
                const data = usersData[key];
                const isEmailValid = key === 'institutionalEmail' ? validateEmailAddress(data) : true;
                const isEmptyField = (key === 'canUpload' || key === 'canModify' || key === 'canDelete')? true : ((data === null && key !== 'middleName') ? false : (key !== 'middleName' ? !isEmpty(data) : true));
                    if(isEmptyField && isEmailValid){
                        isFormValid = true;
                    }else{
                        isFormValid = false;
                        scrollToUsersFields(index);
                        return false;
                    }
            }
        })
        return isFormValid;
    }

    const validateForm = () => {
        switch(dcaStage){
            case 1 : return (!isEmpty(dcaClientName) && dcaAgreementDate !== null && !isEmpty(dcaContributorName) &&
                     !isEmpty(dataContributorAgreementSignature) && isAgreeSelected);
            case 2 : return true;   
            case 3 : return ( !isEmpty(projectDetailsProjectName) && !isEmpty(projectDetailsSynopsisText) && !isEmpty(projectDetailsDesignatedZone) &&
            !isEmpty(projectDetailsIsDatasetDownloadable) && !isEmpty(projectDetailsTypeOfData) && (projectDetailsTypeOfData === 'other' ? !isEmpty(projectDetailsOtherTypeDataText) : true)
            )  
            case 4: return ( !isEmpty(ppiFirstName) && !isEmpty(ppiLastName) && !isEmpty(ppiCredentials)
            && !isEmpty(ppiInstituteName) && validateEmailAddress(ppiInstituteEmail) && !isEmpty(ppiInstituteStreetAddress) && !isEmpty(ppiInstituteCity)
            && !isEmpty(ppiInstituteState) && !isEmpty(ppiInstituteZipCode) && !isEmpty(ppiInstituteCountry)

            )  
            case 5: return ( !isEmpty(projectAdminFirstName) && !isEmpty(projectAdminLastName) && !isEmpty(projectAdminCredentials)
            && !isEmpty(projectAdminInstituteName) && validateEmailAddress(projectAdminInstituteEmail) && !isEmpty(projectAdminInstituteStreetAddress) && !isEmpty(projectAdminInstituteCity)
            && !isEmpty(projectAdminInstituteState) && !isEmpty(projectAdminInstituteZipCode) && !isEmpty(projectAdminInstituteCountry)

            )   
            case 6: return ( !isEmpty(projectPIFirstName) && !isEmpty(projectPILastName) && !isEmpty(projectPICredentials)
            && !isEmpty(projectPIAffiliation) && validateEmailAddress(projectPIInstituteEmail)

            )    
            case 7: return validateZone3UserForm();
            case 8: return validUsersData();
            case 9: return (!isEmpty(acknowledgmentSignature) && isAckAgreeSelected);
            case 10: return (!isEmpty(adminName));
            default: return true;
            //default needs to change         
        }
    }
    const scrollToErrorFields = () => {
        switch (dcaStage) {
            case 1: 
                if(isEmpty(dcaClientName) || dcaAgreementDate === null || isEmpty(dcaContributorName)) {
                    const dcaNameElement = document.getElementById('dcaClientName');
                    scrollToGivenTop(dcaNameElement);
                    return;
                }
            
            case 3: {
                if(isEmpty(projectDetailsSynopsisText) || isEmpty(projectDetailsProjectName)) {
                    const projectSynopsisElement = document.getElementById('projectSynopsis');
                    scrollToGivenTop(projectSynopsisElement);
                    return;
                }
                if (isEmpty(projectDetailsDesignatedZone)) {
                    const designatedZonesElement = document.getElementById('designatedZones');
                    scrollToGivenTop(designatedZonesElement);
                    return;
                }
                if(isEmpty(projectDetailsIsDatasetDownloadable)){
                    const datasetDownloadableElement = document.getElementById('datasetDownloadable');
                    scrollToGivenTop(datasetDownloadableElement);
                    return;
                }
                if(isEmpty(projectDetailsTypeOfData)){
                    const typeOfDataElement = document.getElementById('typeOfData');
                    scrollToGivenTop(typeOfDataElement);
                    return;
                }
                if(projectDetailsTypeOfData === 'other' && isEmpty(projectDetailsOtherTypeDataText)){
                    const datasetDownloadableElement = document.getElementById('otherTypeData');
                    scrollToGivenTop(datasetDownloadableElement);
                    return;
                }
            }
            case 4 : {
                if(isEmpty(ppiFirstName) || isEmpty(ppiLastName) || isEmpty(ppiCredentials)
                || isEmpty(ppiInstituteName) || !validateEmailAddress(ppiInstituteEmail)
                ){
                    const credentialsElement = document.getElementById('credentials');
                    scrollToGivenTop(credentialsElement);
                    return;
                }
                if(isEmpty(ppiInstituteStreetAddress) || isEmpty(ppiInstituteCity) || isEmpty(ppiInstituteState))
                {
                    const instituteCityElement = document.getElementById('instituteCity');
                    scrollToGivenTop(instituteCityElement);
                    return;
                }
                if(isEmpty(ppiInstituteZipCode) || isEmpty(ppiInstituteCountry))
                {
                    const instituteZipCodeElement = document.getElementById('instituteZipCode');
                    scrollToGivenTop(instituteZipCodeElement);
                    return;
                }
            }
            case 5 : {
                if(isEmpty(projectAdminFirstName) || isEmpty(projectAdminLastName) || isEmpty(projectAdminCredentials)
                || isEmpty(projectAdminInstituteName) || !validateEmailAddress(projectAdminInstituteEmail)
                ){
                    const credentialsElement = document.getElementById('credentials');
                    scrollToGivenTop(credentialsElement);
                    return;
                }
                if(isEmpty(projectAdminInstituteStreetAddress) || isEmpty(projectAdminInstituteCity) || isEmpty(projectAdminInstituteState))
                {
                    const instituteCityElement = document.getElementById('instituteCity');
                    scrollToGivenTop(instituteCityElement);
                    return;
                }
                if(isEmpty(projectAdminInstituteZipCode) || isEmpty(projectAdminInstituteCountry))
                {
                    const instituteZipCodeElement = document.getElementById('instituteZipCode');
                    scrollToGivenTop(instituteZipCodeElement);
                    return;
                }
            }
            case 6 : {
                if( isEmpty(projectPIFirstName) || isEmpty(projectPILastName) || isEmpty(projectPICredentials) ){
                    const firstNameElement = document.getElementById('firstName');
                    scrollToGivenTop(firstNameElement);
                    return;
                }
                if( isEmpty(projectPIAffiliation) || !validateEmailAddress(projectPIInstituteEmail)){
                    const affiliationElement = document.getElementById('affiliation');
                    scrollToGivenTop(affiliationElement);
                    return;
                }
            }
            case 10: {
                if(isEmpty(adminName)){
                    const adminNameEle = document.getElementById('adminName');
                    scrollToGivenTop(adminNameEle);
                    return;
                }
            }
            default: return '';
        }
    }
    const openSubmitPopupModal = () => {
        setSubmitDCAModal(true);
    }
    const onNextButtonClick = (event) => {
        if (!disableTextInput) {
            setIsFormSubmitted(true);
            const isValidForm = validateForm();
            if (isValidForm) {
                if (dcaStage < 10) {
                    updateCompleteDCAForm('next');
                    setIsFormSubmitted(false);
                } else if (dcaStage === 10) {
                    openSubmitPopupModal();
                }
            } else {
                scrollToErrorFields();
            }
        } else {
            if (dcaStage < 10) {
                if (projectDetailsDesignatedZone !== 'zone3' && dcaStage === 6) {
                    setDCAStage(dcaStage + 2);
                } else {
                    setDCAStage(dcaStage + 1);
                }
            } else if (dcaStage === 10) {
                if (!isDashboard) {
                    navigate('/my-stuff');
                } else {
                    navigate('/dashboard')
                }
            }
        }
    }
    const onSaveDraftButtonClick = async () => {
            if (dcaStage <= 10) {
                updateCompleteDCAForm('draft');
                setIsFormSubmitted(false);
            }
    }
    const getDesignatedZoneData = () => {
        switch(projectDetailsDesignatedZone){
            case 'zone1': return {
                "designatedZoneType": projectDetailsDesignatedZone,
                "isUseByQualifiedResearchersFromIndustryAllowed": null,
                "restrictionOfUse": null
            }
            case 'zone2' : {
            const researcherAllowFlag = isResearcherAllowed === 'yes' ? true : false;
            return {
                "designatedZoneType": projectDetailsDesignatedZone,
                "isUseByQualifiedResearchersFromIndustryAllowed": researcherAllowFlag,
                "restrictionOfUse": null
            }}
            case 'zone3': return {
                "designatedZoneType": projectDetailsDesignatedZone,
                "isUseByQualifiedResearchersFromIndustryAllowed": null,
                "restrictionOfUse": projectDetailsRestrictionOfUSeText
            }
            default: return {
                "designatedZoneType": projectDetailsDesignatedZone,
                "isUseByQualifiedResearchersFromIndustryAllowed": null,
                "restrictionOfUse": null
            }
        }
    }

    const updateCompleteDCAForm = async (type)=>{
        try{
            setSubmitDCAModal(false);
            const sendUuid = isEmpty(presentUUID) ? {} : {uuid: presentUUID };
            let stageToSend = '';
            if(type == 'next'){
                if (currentFormStage < dcaStage+1) {
                    stageToSend = getDCANextFormStage(dcaStage);
                }else{
                    stageToSend = getDCACurrentFormStage(currentFormStage);
                }
            }
            else{
                if (currentFormStage < dcaStage) {
                    stageToSend = getDCACurrentFormStage(dcaStage);
                }else {
                    stageToSend = getDCACurrentFormStage(currentFormStage);
                }
            }
            const effectiveDate = moment.isDate(dcaAgreementDate) ? moment.utc(dcaAgreementDate).format(): null;
            const dataContributorDate = moment.isDate(new Date()) ? moment.utc(new Date()).format(): null;
            const dcaAgreementSign = ( !isEmpty(dcaRef?.current) && !dcaRef?.current?.isEmpty() ) ? dcaRef?.current?.toDataURL(): (!isEmpty(dataContributorAgreementSignature) ? dataContributorAgreementSignature  : null);
            const acknowledgementSign = (!isEmpty(acknowledgementRef?.current) && !acknowledgementRef?.current?.isEmpty()) ? acknowledgementRef?.current?.toDataURL(): (!isEmpty(acknowledgmentSignature) ? acknowledgmentSignature  : null);
        const isDatasetDownloadable = projectDetailsIsDatasetDownloadable === 'yes' ? true : false;    
        const designatedZoneData = getDesignatedZoneData();
        const irbFileKeyName =  (!isEmpty(projectDetailsIRBNumberFile)) ? `${presentUUID}/${projectDetailsIRBNumberFile[0]?.name}` 
        :(!isEmpty(irbApprovalCopyS3Key) ? irbApprovalCopyS3Key : '' ) ;
        
        const projectDetailsOther = projectDetailsTypeOfData === 'other' ? projectDetailsOtherTypeDataText : '';
        const body = {
            "partyName": dcaClientName,
            "partyDescription": dcaContributorName,
            ...sendUuid,
            "requesterName": fullNameOfUser,
            "requesterTitle": get(userDetails, 'designation', ''),
            "effectiveDate": effectiveDate,
            "dataContributorDate": dataContributorDate,
            "dataContributorAgreementSignature": dcaAgreementSign,
            "stage": stageToSend,
            "institutionalCertificationSignature": '',
            "adminName": adminName,
            "projectDetails":{
               "projectName":projectDetailsProjectName,
               "projectSynopsis":projectDetailsSynopsisText,
               "irbApprovalNumber": projectDetailsIRBApprovalNumber,
               "irbApprovalCopyS3Key": irbFileKeyName,
               "datasetDownloadableByUsers":isDatasetDownloadable,
               "acknowledgingStatement": projectDetailsAckStatement,
               "designatedZone":designatedZoneData,
               "typeOfData":{
                  "dataType": projectDetailsTypeOfData,
                  "dataDescription": projectDetailsOther
               },
               "estimatedTotalSizeOfData": projectDetailsTotalSizeOfData
            },
            "projectPrimaryInstitutionalContact":{
               "firstName": ppiFirstName,
               "middleName": ppiMiddleName,
               "lastName": ppiLastName,
               "credentials": ppiCredentials,
               "institutionDetails":{
                  "institutionalEmail": ppiInstituteEmail,
                  "institutionName": ppiInstituteName,
                  "institutionAddress":{
                     "streetAddress": ppiInstituteStreetAddress,
                     "city": ppiInstituteCity,
                     "state": ppiInstituteState,
                     "zipCode": ppiInstituteZipCode,
                     "country": ppiInstituteCountry
                  }
               }
            },
            "projectAdministrator":{
               "firstName": projectAdminFirstName,
               "middleName": projectAdminMiddleName,
               "lastName": projectAdminLastName,
               "credentials": projectAdminCredentials,
               "institutionDetails":{
                  "institutionalEmail": projectAdminInstituteEmail,
                  "institutionName": projectAdminInstituteName,
                  "institutionAddress":{
                     "streetAddress": projectAdminInstituteStreetAddress,
                     "city": projectAdminInstituteCity,
                     "state": projectAdminInstituteState,
                     "zipCode": projectAdminInstituteZipCode,
                     "country": projectAdminInstituteCountry
                  }
               }
            },
            "projectPI":{
               "firstName": projectPIFirstName,
               "middleName": projectPIMiddleName,
               "lastName": projectPILastName,
               "credentials": projectPICredentials,
               "affiliation": projectPIAffiliation,
               "institutionalEmail": projectPIInstituteEmail
            },
            "zone3AuthorizedUsers":zone3Users,
            "authorizedDatasetSubmitters": authorizedSubmitters,
            "acknowledgementSignature": acknowledgementSign
         }
         setIsFormUpdating(true);
        const res = await updateDCAForm(body); 
        if(res){
            loadInitialData();
            if(type == 'next'){
                if(dcaStage < 10){
                    let nextStage = '';
                    if(projectDetailsDesignatedZone !== 'zone3' && dcaStage === 6){
                        nextStage = 8;
                    }else{
                        nextStage = dcaStage + 1;
                    }
                    setDCAStage(nextStage);
                }
                if(dcaStage === 10){
                    sendGAEvents('submit_data_contribution_agreement', 'data_contribution_agreement', 'Data contribution agreement submit');
                    const userSub = get(userDetails, 'username', '');
                    navigate(`/my-stuff/form-status/${presentUUID}/${userSub}`, {state: { type: 'dca'}})
                }
            }else{
                if(type == 'draft'){
                    navigate('/my-stuff')
                }
            }

            const targetDCAElement = document.getElementById('DCAFormID');
            scrollToGivenTop(targetDCAElement);
            updateSnackBar('Form is Updated Successfully', 'success');
        }
        const tempUUID = get(res, 'uuid', '');
        if(isEmpty(presentUUID)){

            setPresentUUID(tempUUID);
        }
        }catch(error){
            console.log('error in updateDatasetGovernanceForm ');
            updateSnackBar('Error in updating form.', 'error');
        }finally{
            setIsFormUpdating(false);
        }
    }

    const closeDCAModal = () =>{
        setSubmitDCAModal(false);
    }
    const onSubmitDCAConfirm = () => {
        updateCompleteDCAForm('next');
    }
    const continueEditingClick = () =>{
        setCancelDCAModal(false);
    }
    const abortClick = () =>{
        setCancelDCAModal(false);
        onCancelClick();
    }
    const onCancelButtonClick = () => {
        if (!disableTextInput) {
            setCancelDCAModal(true)
        } else {
            if (!isDashboard) {
                navigate('/my-stuff')
            } else {
                navigate('/dashboard')
            }
        }
    }
    const onSaveAsDraftSelect = () => {
        setSaveAsDraftModal(false);
        onSaveDraftButtonClick();
    }
    const continueEditingDraft = () => {
        setSaveAsDraftModal(false);
    }
    const onSaveAsDraftButtonClick = () => {
        if(!disableTextInput){
            setSaveAsDraftModal(true);
        }
    }
    const SUBMIT_DCA_MODAL_PROPS = {
        modalTitle: 'Submit Data Contributor Agreement',
        modalContent: 'You are submitting the Data Contributor Agreement. Are you sure you want to proceed ?',
        positiveButtonText: 'Submit',
        negativeButtonText: "Cancel",
        positiveButtonAction: onSubmitDCAConfirm,
        negativeButtonAction: closeDCAModal
    }
      
    const CANCEL_DCA_MODAL_PROPS = {
        modalTitle: 'Cancel Data Contributor Agreement',
        modalContent: 'You are aborting the Data Contributor Agreement. Are you sure you want to proceed ?',
        positiveButtonText: 'Abort',
        negativeButtonText: "Continue Editing",
        positiveButtonAction: abortClick,
        negativeButtonAction: continueEditingClick
    }
      
    const SAVE_DRAFT_DCA_MODAL_PROPS = {
        modalTitle: 'Save As Draft',
        modalContent: 'You are saving the Data Contributor Agreement as draft. Are you sure you want to proceed ?',
        positiveButtonText: 'Save as draft',
        negativeButtonText: "Continue Editing",
        positiveButtonAction: onSaveAsDraftSelect,
        negativeButtonAction: continueEditingDraft
    }
    const buttonWrapperClass = !disableTextInput ? `${Classes.buttonWrapper}` : `${Classes.buttonWrapper} ${Classes.readOnlyForm}`;
  
    return (
        
        <div className={Classes.editProfileMainContainer} id='cvb-dataContributorAgreement-mainContainer'>
             <Modal
                open={submitDCAModal}
                handleClose={closeDCAModal}
                dialogProps={SUBMIT_DCA_MODAL_PROPS}
                disableBottomHorizontalDivider
            ></Modal>
            <Modal
                open={cancelDCAModal}
                handleClose={continueEditingClick}
                dialogProps={CANCEL_DCA_MODAL_PROPS}
                disableBottomHorizontalDivider
            ></Modal>
            <Modal
                open={saveAsDraftModal}
                handleClose={continueEditingDraft}
                dialogProps={SAVE_DRAFT_DCA_MODAL_PROPS}
                disableBottomHorizontalDivider
            ></Modal>
                <div className={Classes.editProfileChildContainer}>
                    <Grid container direction='column'>
                        <p id='cvb-dataContributorAgreement-backButton' onClick={backHandle} className={Classes.backButton}>
                            <span><img alt='back button arrow' src={BackButtonArrow} /></span>
                            <p className={Classes.backButtonText}>Back</p>
                        </p>
                        <h3 id='cvb-dataContributorAgreement-formTitle' className={Classes.editProfileText}>Data Contributor Agreement</h3>
                        <div className={Classes.agreementHeader}>
                            <Grid container direction='column'>
                                <div className={Classes.formStepperHorizontal}>
                                    <div className={Classes.formStep}>
                                        <div className={dcaStage == 1 ?
                                            Classes.formStepActiveCircle :
                                            (dcaStage > 1 ? Classes.firstFormCompleted
                                                : Classes.formStepCircle)}>
                                            <span></span>
                                        </div>
                                        <div id='cvb-dataContributorAgreement-stage1DCATitle' className={Classes.formStepTitle}>Data Contributor Agreement</div>
                                        <div id='cvb-dataContributorAgreement-stage1DCADescription' className={Classes.formStepOptional}>Sign Agreement in order to contribute data</div>
                                        <div className={dcaStage > 1 ?  `${Classes.formStageCompleted} ${Classes.formStepBarLeft}` : Classes.formStepBarLeft}></div>
                                        <div className={dcaStage > 1 ? `${Classes.formStageCompleted} ${Classes.formStepBarRight}` : Classes.formStepBarRight}></div>
                                    </div>
                                    <div className={Classes.formStep}>
                                        <div className={dcaStage == 2 ?
                                            Classes.formStepActiveCircle :
                                            (dcaStage > 2 ? Classes.firstFormCompleted
                                                : Classes.formStepCircle)}>
                                            <span></span>
                                        </div>
                                        <div id='cvb-dataContributorAgreement-stage2DCATitle' className={Classes.formStepTitle}>Definitions & Contributed Data Specific Terms</div>
                                        <div id='cvb-dataContributorAgreement-stage2DCADescription' className={Classes.formStepOptional}>Go through the definitions
                                    </div>
                                        <div className={dcaStage > 1 ?  `${Classes.formStageCompleted} ${Classes.formStepBarLeft}` : Classes.formStepBarLeft}></div>
                                        <div className={dcaStage > 2 ?  `${Classes.formStageCompleted} ${Classes.formStepBarRight}` : Classes.formStepBarRight}></div>
                                    </div>
                                    <div className={Classes.formStep}>
                                        <div className={(dcaStage >= 3 && dcaStage <= 9) ?
                                            Classes.formStepActiveCircle :
                                            (dcaStage > 9 ? Classes.firstFormCompleted
                                                : Classes.formStepCircle)}>
                                            <span></span>
                                        </div>
                                        <div id='cvb-dataContributorAgreement-stage3DCATitle' className={Classes.formStepTitle}>Contributed Dataset Governance Form</div>
                                        <div id='cvb-dataContributorAgreement-stage3DCADescription' className={Classes.formStepOptional}>Fill Contributed Dataset Governance Form
                                    </div>
                                        <div className={dcaStage > 2 ?  `${Classes.formStageCompleted} ${Classes.formStepBarLeft}` : Classes.formStepBarLeft}></div>
                                        <div className={dcaStage === 10 ?  `${Classes.formStageCompleted} ${Classes.formStepBarRight}` : Classes.formStepBarRight}></div>
                                    </div>
                                    <div className={Classes.formStep}>
                                        <div className={dcaStage == 10 ?
                                            Classes.formStepActiveCircle :
                                            (dcaStage > 10 ? Classes.firstFormCompleted
                                                : Classes.formStepCircle)}>
                                            <span></span>
                                        </div>
                                        <div id='cvb-dataContributorAgreement-stage4DCATitle' className={Classes.formStepTitle}>Institutional Certification</div>
                                        <div id='cvb-dataContributorAgreement-stage4DCADescription' className={Classes.formStepOptional}>Fill & submit the certificate, it needs to be approved by the Institution
                                    </div>
                                        <div className={dcaStage === 10 ?  `${Classes.formStageCompleted} ${Classes.formStepBarLeft}` : Classes.formStepBarLeft}></div>
                                        <div className={dcaStage === 10 ?  `${Classes.formStageCompleted} ${Classes.formStepBarRight}` : Classes.formStepBarRight}> </div>
                                    </div>
                                </div>
                            </Grid>
                        </div>
                    {isLoading ? 
                    <div className={Classes.loaderContainer}>
                    <CustomLoader /> 
                    </div>
                    :
                        <React.Fragment>
                            {
                                dcaStage == 1 &&
                                    <DataContributorAgreementStage  
                                    isDashboard={isDashboard} 
                                    userDetails={userDetails} 
                                    dcaRef={dcaRef} 
                                    isFormSubmitted={isFormSubmitted} 
                                    setIsAgreeSelected={setIsAgreeSelected}
                                    isAgreeSelected={isAgreeSelected}
                                    />
                            }
                            {
                                dcaStage == 2 &&
                                <DefinitionAndContributeDataStage nextButtonRef={nextButtonRef}/>
                            }
                            {
                                (dcaStage >= 3 && dcaStage <= 9) &&
                                <ContributedDatasetStage
                                    isFormSubmitted={isFormSubmitted}
                                    acknowledgementRef={acknowledgementRef}
                                    presentUUID={presentUUID}
                                    isDashboard={isDashboard}
                                    userDetails={userDetails}
                                    setIsAckAgreeSelected={setIsAckAgreeSelected}
                                    isAckAgreeSelected={isAckAgreeSelected}
                                />
                            }
                            {
                                dcaStage == 10 &&
                                <InstitutionalCertificationStage
                                    isDashboard={isDashboard}
                                    instituteCertRef={instituteCertRef}
                                    isFormSubmitted={isFormSubmitted}
                                    userDetails={userDetails}
                                />
                            }
                        </React.Fragment>
                    }
                        <div className={Classes.buttonContainer} id='cvb-dataContributorAgreement-buttonsContainer'>
                            <div className={buttonWrapperClass}>
                                <button id='cvb-dataContributorAgreement-cancelButton' className={`no-outlined-button small-size-button ${Classes.cancelButton}`} onClick={onCancelButtonClick}>Cancel</button>
                                {!disableTextInput && <button disabled={isFormUpdating} className={`transparent-button medium-size-button ${Classes.saveDraftButton} `} onClick={onSaveAsDraftButtonClick}>Save As Draft</button>}
                                <button disabled={isFormUpdating} id='cvb-dataContributorAgreement-submitButton' type="submit" className={`medium-size-button solid-button ${Classes.nextButton}`} onClick={onNextButtonClick} ref={nextButtonRef}>
                                    {!(currentDCAFormStage === DCA_FORM_COMPLETED_TEXT && dcaStage === 10) ? (dcaStage === 10 ? 'Submit': 'Next') : 'Submitted'}
                                </button>
                            </div>
                        </div>
                    </Grid>
                </div>
        </div>
    
    )
}

export default DataContributorAgreement;