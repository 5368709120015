import {
    SET_EDIT_CONTACT_NUMBER, SET_EDIT_CITY, SET_EDIT_STREET_ADDRESS, SET_EDIT_STATE,
    SET_EDIT_ZIP_CODE, SET_EDIT_COUNTRY, SET_TAB_VALUE_IN_EDIT_PROFILE, SET_EDIT_PROFILE_INITIAL_STATE, SET_EDIT_INSTITUTION_NAME
} from '../action-types'

export const setTabsValueInEditProfile = (value) => {
    return {
        type: SET_TAB_VALUE_IN_EDIT_PROFILE,
        payload: value
    }
}
export const setEditContactNumber = (value) => {
    return {
        type: SET_EDIT_CONTACT_NUMBER,
        payload: value
    }
}
export const setEditStreetAddress = (value) => {
    return {
        type: SET_EDIT_STREET_ADDRESS,
        payload: value
    }
}
export const setEditCity = (value) => {
    return {
        type: SET_EDIT_CITY,
        payload: value
    }
}
export const setEditState = (value) => {
    return {
        type: SET_EDIT_STATE,
        payload: value
    }
}
export const setEditZipCode = (value) => {
    return {
        type: SET_EDIT_ZIP_CODE,
        payload: value
    }
}
export const setEditCountry = (value) => {
    return {
        type: SET_EDIT_COUNTRY,
        payload: value
    }
}
export const setEditInstitutionName = (value) => {
    return {
        type: SET_EDIT_INSTITUTION_NAME,
        payload: value
    }
}


export const editProfileInitialState = value => {
    const initialState = {
        tabValue: 0,
        contactEmail: value?.['email'] || '',
        contactNumber: value?.['phone_number'] || '',
        city: value?.institution_details?.['city'] || '',
        streetAddress: value?.institution_details?.['address'] || '',
        state: value?.institution_details?.['state'] || '',
        zipCode: value?.institution_details?.['zip_code'] || '',
        country: value?.institution_details?.['country'] || '',
        institutionName: value?.institution_details?.['institution_name']
    }
    return {
        type: SET_EDIT_PROFILE_INITIAL_STATE,
        payload: initialState
    }
}
