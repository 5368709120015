/**
 *  External Imports
 */
import React from "react";
import { shape, bool, instanceOf } from "prop-types";

/**
 *  Internal Imports
 */


const CommunityConstellationGraphLink = (props) => {
    const { graphLabelsMask } = props;
    const { isSelectable } = props.link;


    const isLinkTargetSelected = () => {
        if (!isSelectable && !props?.link && !props?.link?.target) {
            return false;
        } else {
            const currentTarget = props?.link?.target?.metadata?.value;
            return graphLabelsMask?.has(currentTarget);
        }
    };

    const isLinkReady = () => {
        return (
            props &&
            props?.link &&
            props?.link?.source &&
            props?.link?.source?.x &&
            props?.link?.source?.y &&
            props?.link?.target &&
            props?.link?.target?.x &&
            props?.link?.target?.y
        );
    };

    return isLinkReady() ? (
        <line
            data-testid="constellationGraphLink"
            x1={props.link.source.x}
            y1={props.link.source.y}
            x2={props.link.target.x}
            y2={props.link.target.y + 1}
            strokeWidth={2}
            opacity={isSelectable && !isLinkTargetSelected() ? 0.2 : 1}
            stroke={"#637BBD"}
        />
    ) : null;
};

CommunityConstellationGraphLink.propTypes = {
    link: shape({
        isSelectable: bool,
    }),
    graphLabelsMask: instanceOf(Map).isRequired,
};

export default CommunityConstellationGraphLink;
