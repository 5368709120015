import AnalysisTabs from './AnalysisTabs'
import { connect } from 'react-redux'

const mapStateToProps = state => {
    return {
        analysisAttributes: state.analyzeData.analysisAttributes,
        selectedAnalysis: state.analyzeData.selectedAnalysis,
    }
}
const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AnalysisTabs)