import Authorisation from './Authorisation'
import { connect } from 'react-redux'
import { setDCAStage, resetDCAForm, setCurrentDCAFormStage } from '../../../redux/actions/dcaActions'
import { resetProjectDetails } from '../../../redux/actions/projectDetailsActions'
import { resetProjectInstitutionForm } from '../../../redux/actions/projectPrimaryInstitutionalActions'
import { resetPIForm } from '../../../redux/actions/projectPIActions'
import { resetProjectAdminForm } from '../../../redux/actions/projectAdminActions'
import { resetZone3UserForm } from '../../../redux/actions/zone3UsersActions'
import { resetAuthSubmitterForm } from '../../../redux/actions/authorizeSubmitterActions'
import { setDifStage } from '../../../redux/actions/dataInventoryActions'
import { resetInstitutionFormData } from '../../../redux/actions/difInstitutionActions'
import { resetPrimaryInvestigatorData } from '../../../redux/actions/difPIActions'
import { resetDataSharingData } from '../../../redux/actions/difDataSharingActions'
import { resetOtherPersonnelData } from '../../../redux/actions/difOtherPersonalActions'
import { resetTimelineData } from '../../../redux/actions/difTimelineActions'
import { resetIndividualDatasetData } from '../../../redux/actions/difIndividualDatasetActions'
import { setInstitutionalCertAdminName } from '../../../redux/actions/institutionalCertActions'
import { resetDifStudyData } from '../../../redux/actions/difStudyActions'
import { updateSnackBar } from '../../../redux/actions/globalActions'
const mapStateToProps = state => {
    return {
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDCAStage: async (value) => await dispatch(setDCAStage(value)),
        resetDCAForm: async () => await dispatch(resetDCAForm()),
        resetProjectDetails: async () => await dispatch(resetProjectDetails()),
        resetProjectInstitutionForm: async () => await dispatch(resetProjectInstitutionForm()),
        resetPIForm: async () => await dispatch(resetPIForm()),
        resetProjectAdminForm: async () => await dispatch(resetProjectAdminForm()),
        resetZone3UserForm: async () => await dispatch(resetZone3UserForm()),
        resetAuthSubmitterForm: async () => await dispatch(resetAuthSubmitterForm()),
        setDifStage: async (value) => await dispatch(setDifStage(value)),
        setCurrentDCAFormStage: async (value) => await dispatch(setCurrentDCAFormStage(value)),
        setInstitutionalCertAdminName: async (value) => await dispatch(setInstitutionalCertAdminName(value)),
        resetInstitutionFormData: () => dispatch(resetInstitutionFormData()),
        resetPrimaryInvestigatorData: () => dispatch(resetPrimaryInvestigatorData()),
        resetDataSharingData: () => dispatch(resetDataSharingData()),
        resetOtherPersonnelData: () => dispatch(resetOtherPersonnelData()),
        resetTimelineData: () => dispatch(resetTimelineData()),
        resetIndividualDatasetData: () => dispatch(resetIndividualDatasetData()),
        resetDifStudyData: () => dispatch(resetDifStudyData()),
        updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Authorisation);

