import React, { useEffect, useState } from 'react';
import {env} from '../../../env'
import Classes from './index.module.css';
import SignatureCanvas from 'react-signature-canvas';
import ErrorText from '../error-text';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import OutlinedInput from '@mui/material/OutlinedInput'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import { DCA_FORM_COMPLETED_TEXT } from '../../../constants/strings';
import CommonDatePicker from '../../commons/common-date-picker';
import Checkbox from '@mui/material/Checkbox';
const DataContributeAgreementStage = (props) => {
    const {
        isFormSubmitted,
        dcaClientName,
        dcaAgreementDate,
        dcaContributorName,
        contributorSigningName,
        contributorSigningTitle,
        dcaRef,
        dataContributorAgreementSignature,
        userDetails,
        currentDCAFormStage,
        isDashboard,
        setDCAAgreementDate,
        setDCAClientName,
        setDCAContributorName,
        setDCASignature,
        isAgreeSelected,
        setIsAgreeSelected
    } = props
    const disableTextInput =  (currentDCAFormStage === DCA_FORM_COMPLETED_TEXT);
    const isSignatureValid = () => !dcaRef?.current?.isEmpty();
    const clearDcaSign = async () => await dcaRef.current.clear();
    const createDcaSign = (data) => dcaRef?.current?.fromDataURL(data);
    useEffect(() => {
        if(!isEmpty(dataContributorAgreementSignature)){
            createDcaSign(dataContributorAgreementSignature);
        }else{
            clearDcaSign();
            setDCASignature(null);
        }
    }, [])

    const clearSignature = async () => {
        if(!disableTextInput){
        await clearDcaSign();
        setDCASignature(null);
        }
    }
    const onDCADateChange = value => {
        setDCAAgreementDate(value);
    }
    const onClientNameChange = event => {
        setDCAClientName(event.target.value);
    }
    const onContributorNameChange = event => {
        setDCAContributorName(event.target.value)
    }
    const onSignatureEnd = async () => {
        const dcaAgreementSign = await dcaRef?.current?.toDataURL();
        setDCASignature(dcaAgreementSign);
    }
    
    
    const fullNameOfUser = !isDashboard ? (capitalize(userDetails?.["first_name"]) + " " + capitalize(userDetails?.["last_name"])) : contributorSigningName;
    const usersDesignation = !isDashboard ? get(userDetails,'designation', '') : contributorSigningTitle;
    const agreementText = env.REACT_APP_AGREEMENT_TEXT

    return (
        <div className={Classes.agreementContainer} id='cvb-dataContributorAgreement-stageOneFormContainer'>
            <div className={Classes.impTitleHeaderContainer}>
                    <p id='cvb-dataContributorAgreement-stageOneDisclaimerTextHeading'>Important </p>
                    <p id='cvb-dataContributorAgreement-stageOneDisclaimerDescription' className={Classes.impSubHeader}>Please read following agreement term before getting started. Fill out data contribute agreement to get approved to 
                        access services in BRAINCommons.
                    </p>
                </div>
                <p id='cvb-dataContributorAgreement-stageOneFormHeading' className={Classes.formDCATitle}>Data Contributor Agreement</p>
            <p id='cvb-dataContributorAgreement-stageOneFormDescription'>
                This BRAINCommons Data Contributor Agreement (<b>“Agreement”</b>) is effective as of
            <span className={Classes.inputContainer} id='cvb-dataContributorAgreement-stageOneFormDateField'>
                    <CommonDatePicker
                        onCalenderDateSelected={onDCADateChange}
                        date={dcaAgreementDate}
                        minDate={moment().add(1, 'd').toDate()}
                        showValidationError={isFormSubmitted && !dcaAgreementDate}
                        errorMessage='Enter a service agreement date'
                        id='cvb-dca-agreementInputDate'
                        canClearInput
                        disabled={disableTextInput}
                    />
                </span>
            (the <b>“Effective Date”</b>) by and between Cohen Veterans Bioscience, Inc. (<b>“CVB”</b>), a Massachusetts nonprofit corporation, and
            <span className={Classes.inputContainer}>
                    <OutlinedInput
                        id='cvb-dataContributorAgreement-stageOneClientName'
                        placeholder='Enter name'
                        name='clientName'
                        type='text'
                        onChange={onClientNameChange}
                        value={dcaClientName}
                        fullWidth
                        disabled={disableTextInput}
                    />
                    {isFormSubmitted && !dcaClientName &&
                        <ErrorText errorMessage="Enter a client name" />
                    }
                </span>
                            a
                            <span className={Classes.inputContainer}>
                    <OutlinedInput
                        id='cvb-dataContributorAgreement-stageOneFormContributorName'
                        placeholder='Enter name'
                        name='clientName'
                        type='text'
                        onChange={onContributorNameChange}
                        value={dcaContributorName}
                        fullWidth
                        disabled={disableTextInput}
                    />
                    {isFormSubmitted && !dcaContributorName &&
                        <ErrorText errorMessage="Enter a contributor name" />
                    }
                </span>
                            (<b>“Contributor”</b> and collectively with CVB, the <b>“Parties”</b>).
            </p>
            <h3 className={`${Classes.formSubHeader}`}>Background</h3>
            <p>
                CVB has provided funding and resources for the development of, and coordinates governance and oversees data contribution,
                access and services in connection with, the BRAINCommons, a knowledge commons that collocates or integrates data,
                storage and computing infrastructure with services, tools, interfaces and applications for managing, analyzing, and sharing data
                to create an interoperable resource for the brain and other research communities that enables and encourages data sharing,
                collaboration and data-driven discovery (the <b>“BRAINCommons”</b>). The BRAINCommons utilizes a software (and other technology)-enabled platform for managing,
                analyzing and sharing biomedical data (the <b>“Platform”</b>), which includes a cloud-based infrastructure.
            </p>
            <p>
                Subject to the terms of this Agreement, CVB will provide use of the Platform to users (collectively, <b>“Users”</b>), consistent with Contributed Data Specific Terms on 
                <b>Exhibit B</b>. Users will be granted certain access to, and computing and analysis services regarding, BRAINCommons data provided by various data contributors (<b>“BC Data”</b>). 
                Certain controlled access and restricted access users (collectively, <b>“Authorized Users”</b>) will be granted enhanced access to BC Data based on rules regarding access to and use of 
                certain BC Data (each, a <b>“Zone”</b>) after submission of required access request information and determination and approval by the Data Access Committee (or, as applicable, via another 
                approval process implemented by the BC Manager) pursuant to Contributor’s designated Zone for certain BC Data (collectively,  <b>“Contributor-designated Zone Rights”</b> or <b>“Designated Zone”</b>).
            </p>
            <ol type='i' className={Classes.olList}>
                <b>Contributor desires to</b>:
            <li>contribute certain datasets, which consist of de-identified preclinical and/or biomedical data, including, without limitation, clinical, genomic, imaging, or biosensor data (collectively, the <b>“Contributed Data”</b>), to the Platform; and </li>
                <li> permit CVB or its designees (as applicable, the <b>“BC Manager”</b>) to grant Users certain rights, based on the applicable Contributor-designated Zone Rights, to access and use the Contributed Data via the Platform, subject to Applicable Law and the Data Use Agreement. </li>
            </ol>
            <ol type='1' className={Classes.olList}>
                <h3 className={`${Classes.formSubHeader}`}>AGREEMENT</h3>
                <p>In consideration of the foregoing and the mutual promises and covenants contained herein, the Parties agree as follows:</p>
                <li><b>Definitions.</b> Capitalized terms used in this Agreement have the meaning set forth on <b>Exhibit A</b>.</li>
                <li><b>Contributor Responsibilities</b>
                    <ul className={Classes.ulList}>
                        <li><b>2.1 Conformity of Data.</b> Subject to the terms and conditions of this Agreement, Contributor will provide the BC Manager with the Contributed Data in the format(s) required by the BC Manager for use in connection with the Platform.
                        </li>
                        <li>
                            <b>2.2.	Licenses. </b>

                            <ul>
                                <li>2.2.1  Contributor hereby grants to the BC Manager a non-exclusive, irrevocable (except as otherwise expressly set forth in Section 3.2 of this Agreement), sublicenseable, transferable (pursuant to Section 8.4 of this Agreement), world-wide, fully paid-up and royalty-free license to: (i) provide applicable Users with access through the Platform to the Contributed Data and authorize applicable Users to use, disclose and modify the Contributed Data, consistent with the terms set forth in <b>Exhibit B</b>; and (ii) internally use, disclose and modify the Contributed Data solely for the purposes of managing, providing or improving the Platform, subject to the terms and conditions hereof.  For the avoidance of doubt, the BC Manager shall have no right to use the Contributed Data in research under the foregoing subsection (ii) beyond the rights of other Users.
                                </li>
                                <li>2.2.2.	Except as authorized by Contributor in writing, the BC Manager will not knowingly or recklessly disclose or grant access to the Contributed Data to any other person for any purpose other than as set forth in this Section 2.2. CVB shall be liable (pursuant to indemnification under Section 6.3.2) for any disclosure of or access to Contributed Data in violation of this Agreement that is in the possession, custody or control of BC Manager that causes liability to Contributor.   </li>
                            </ul>
                        </li>
                        <li><b>2.3.	Contributed Data Requirements.</b>
                            <ul>
                                <li>
                                    2.3.1.	Contributor and its Representatives shall be responsible for ensuring that the Contributed Data does not include any Prohibited Data unless specifically approved in writing by the BC Manager. Contributor will, in the manner specified (or otherwise expressly authorized) by the BC Manager, provide and maintain a complete, accurate and current list of all Authorized Data Submitters.  Contributor shall ensure that all Authorized Data Submitters understand and agree that (i) they shall not provide any Prohibited Data without the BC Manager’s prior written consent; (ii) they shall not provide, modify or remove any Contributed Data except through the applicable authorized mechanism made available by the BC Manager; and (iii) their authorization is limited solely to permit the relevant Contributed Data to be uploaded and reviewed for quality control verification.  For clarity, Authorized Data Submitters are not Users and have no rights other than as described herein. Contributor shall be responsible and liable for any act or omission of any Authorized Data Submitter as if Contributor directly engaged in such act or omission.
                                </li>
                                <li>
                                    2.3.2.	Contributor will be solely responsible for obtaining all necessary consents, including any necessary Institutional Review Board (<b>“IRB”</b>) or Privacy Board consents, and otherwise complying with all Applicable Laws and other restrictions: (i) to transmit any Contributed Data to the Platform or to the BC Manager; (ii) to authorize the BC Manager to provide Users with access to and use of such Contributed Data as set forth in Section 2.2; and (iii) to authorize the BC Manager to exercise its rights and perform its obligations under this Agreement. Contributor shall ensure that it has the legal authority to contribute the Contributed Data and execute all referenced certifications and forms.
                                </li>
                                <li>
                                    2.3.3.	Contributor will retain the right to control Contributed Data provided hereunder.
                                </li>
                                <li>
                                    2.3.4.	Contributor shall submit to the BC Manager an Institutional Certification, in the form attached hereto as <b>Exhibit C</b>, with respect to the Contributed Data.
                                </li>
                            </ul>

                        </li>
                    </ul>
                </li>
                <li>
                    <b>BC Manager Responsibilities.</b>
                    <ul>
                        <li>
                            <b>3.1.	Notifications.</b>  If the BC Manager determines that a User has violated the Data Use Agreement, the BC Manager may, in its reasonable discretion, suspend such User’s access to Contributed Data and must promptly report the violation to Contributor if such violation impacts Contributor’s Contributed Data.
                    </li>
                        <li>
                            <b>3.2.	Removal of Contributed Data.</b> If Contributor requests that any Contributed Data be removed from the Platform, including where removal is required by Applicable Laws, the BC Manager will use commercially reasonable efforts to promptly remove such Contributed Data from the Platform and reasonably facilitate communications between Contributor and Users who had access to such Contributed Data. Notwithstanding any such request to remove Contributed Data from the Platform, Contributor acknowledges that once Contributed Data is uploaded to the Platform, use of such Contributed Data is subject to the applicable Contributor-designated Zone Rights, which may include permission to download and/or use the Contributed Data to conduct research without further restriction or obligation to return or cease use of such Contributed Data.  BC Manager will not be responsible for such ongoing or future use of such Contributed Data by Users.
                    </li>
                        <li>
                            <b>3.3.	User Authorization.</b>  The BC Manager will implement a commercially reasonable process aimed at authorizing and authenticating Users (in light of the applicable Contributor-designated Zone Rights requested by such User). The BC Manager will take commercially reasonable measures to reasonably ensure that each User expressly agrees to or is otherwise bound by the terms of the Data Use Agreement and any other applicable terms and conditions of use, including without limitation that Users may not (i) redistribute BC Data in an unauthorized manner; (ii) identify, attempt to identify, or contact any individual to which any Contributed Data pertains; or (iii) violate any Applicable Laws.
                    </li>
                        <li>
                            <b>3.4.	Limitations on Use.</b> The Platform and the BC Data and Contributed Data may not be used for any purpose other than research or educational purposes.
                    </li>
                        <li>
                            <b>3.5.	 Data Security.</b> The BC Manager will manage and operate the Platform using the same policies, procedures and controls that are required for a Federal Information Security Management Act of 2002 (FISMA) Moderate System, as updated from time to time. These policies, procedures and controls are currently described in NIST Special Publication 800-53, Revision 4 (available at http://dx.doi.org/10.6028/NIST.SP.800-53r4). Specific deviations from these policies, processes and controls will be documented and maintained in a BPA System Security Plan. The Platform may use or interoperate with cloud providers, such as Amazon Web Services.
                    </li>
                        <li>
                            <b>3.6.	BC Manager Discretion.</b>  The BC Manager reserves the right, and has the discretion at any time, to screen, reject, block or remove any Contributed Data from the BRAINCommons.
                    </li>
                    </ul>
                </li>
                <li>
                    <b>Contributed Data Specific Terms.</b> The Parties will comply with the terms and conditions set forth on <b>Exhibit B</b>.
            </li>
                <li>
                    <b>Proprietary Rights.</b>
                    <ul>
                        <li><b>5.1.	Intellectual Property Rights.</b> Contributor hereby grants to the BC Manager a perpetual, irrevocable, worldwide, non-exclusive, transferable, sublicenseable, fully paid-up and royalty-free license to incorporate any suggestions or other feedback, which is provided by Contributor and relates to the Platform, into the Platform and to use and modify such feedback in connection with the Platform.  Contributor and the BC Manager will in good faith discuss any requested or asserted rights in any discoveries made by researchers accessing the Contributed Data via the Platform.  Each Party reserves all rights not expressly granted to the other Party under this Agreement.</li>
                    </ul>
                </li>
                <li>
                    <b>Warranties and Remedies</b>
                    <ul>
                        <li>
                            <b>6.1.	Warranties.  </b>
                            <ul>
                                <li><b>6.1.1.	Authority.</b>  Each Party represents and warrants to the other that such Party has the right and necessary corporate authority to enter into this Agreement.  The individual executing this Agreement on behalf of Contributor represents and warrants that he or she has the legal authority to bind Contributor to this Agreement.</li>
                                <li><b>6.1.2.	Contributed Data.</b>  Contributor represents and warrants to the BC Manager that Contributor has obtained all consents and waivers necessary and is otherwise legally authorized to transmit to the Platform or to the BC Manager any Contributed Data and to grant the rights granted to the BC Manager under this Agreement.  Contributor also represents and warrants that Contributor’s contribution of Contributed Data to the Platform does not infringe any third party rights, does not violate Contributor contractual obligations to third parties, and complies with all Applicable Laws.</li>
                            </ul>
                        </li>
                        <li>
                            <b>6.2. Disclaimer of Warranties.  EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, (A) EACH PARTY HEREBY DISCLAIMS ALL WARRANTIES WITH RESPECT TO THE SUBJECT MATTER OF THIS AGREEMENT, INCLUDING BUT NOT LIMITED TO WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE; (B) THE PLATFORM IS PROVIDED “AS IS” AND WITHOUT ANY WARRANTY RELATED TO ACCURACY, COMPLETENESS, QUALITY OR THAT THE PLATFORM WILL BE UNINTERRUPTED OR ERROR FREE; AND (C) THE CONTRIBUTED DATA IS PROVIDED “AS IS” WITHOUT ANY WARRANTY RELATED TO ACCURACY, COMPLETENESS, OR QUALITY.  </b>
                        </li>
                        <li>
                            <b>6.3.	Indemnification. </b>
                            <ul>
                                <li>6.3.1.	To the maximum extent permitted by law, Contributor will defend, indemnify and hold harmless CVB and the BC Manager and their respective Affiliates and Representatives from and against any and all claims, liabilities, losses, causes of action, damages, costs and expenses (collectively, “Claims”), to such portion of Claims to the extent arising from any allegations made by any third party (including any Governmental Authority) regarding any (i) Contributed Data provided to the Platform or the BC Manager (whether provided directly by Contributor or by Authorized Data Submitter); or (ii) material breach of Sections 2.3.1, 2.3.2, or 6.1.2 of this Agreement by Contributor or its Authorized Data Submitter. </li>
                                <li>6.3.2.	To the maximum extent permitted by law, the BC Manager will defend, indemnify and hold harmless Contributor and its Affiliates and Representatives from and against any and all Claims to the extent arising from any allegations made by any third party (including any Governmental Authority) regarding any (i) gross negligence or willful misconduct by or on behalf of the BC Manager; or (ii) material breach of Sections 2.2, 3.3, or 3.5 of this Agreement by the BC Manager.</li>
                            </ul>
                        </li>
                        <li>
                            <b>6.4.	LIMITATION OF LIABILITY.  EXCEPT WITH RESPECT TO EACH PARTY’S INDEMNIFICATION OBLIGATIONS PURSUANT TO THIS AGREEMENT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, (A) NEITHER PARTY SHALL BE LIABLE HEREUNDER FOR CONSEQUENTIAL, INCIDENTAL, EXEMPLARY, OR PUNITIVE DAMAGES (INCLUDING LOST PROFITS OR SAVINGS), EVEN IF IT HAS BEEN ADVISED OF THEIR POSSIBLE EXISTENCE, AND (B) NEITHER PARTY’S TOTAL AND CUMULATIVE AGGREGATE LIABILITY FOR ANY CLAIM OR CLAIMS ARISING UNDER THIS AGREEMENT WILL EXCEED ONE THOUSAND DOLLARS (US$ 1,000.00).  </b>
                        </li>
                    </ul>
                </li>
                <li>
                    <b>Term and Termination</b>
                <ul>
                        <li><b>7.1.	Term.</b>  This Agreement will take effect on the Effective Date and continue in effect for three (3) years.  This Agreement may be renewed for successive one (1) year renewal terms by written agreement to renew signed by the Parties.</li>
                        <li> <b>7.2.	Termination by Either Party.</b>  Either Party may terminate this Agreement by written notice to the other Party, upon the occurrence of any of the following events:
                        <ul>
                                <li>7.2.1.	For any reason or no reason upon not less than ninety (90) days’ prior written notice to the other Party;</li>
                                <li>7.2.2.	The other Party becomes insolvent or subject to any proceeding under the federal bankruptcy laws or other similar laws for the protection of creditors (which proceeding, if involuntary, is not dismissed within thirty (30) days); or</li>
                                <li>7.2.3.	The other Party materially breaches any term, provision, representation or warranty of this Agreement and such breach or default is not cured within thirty (30) days of the breaching Party’s receipt of written notice thereof, as reasonably determined by the non-breaching Party.</li>
                                <li>7.2.4.	The Parties agree that the provisions of this Agreement are intended to be interpreted and implemented so as to comply with all Applicable Laws and all relevant IRB determinations. If an IRB or other comparable body objects to use of the Contributed Data, then the Parties agree to modify this Agreement so as to be in compliance or to be acceptable to such IRB. If such modification is not possible, or practical, or if the Parties are unable to agree upon the modification to be made, then either Party may immediately terminate this Agreement.</li>
                            </ul>
                        </li>
                        <li>
                            <b>7.3.	Effect of Termination.</b>
                            <ul>
                                <li>7.3.1.	The terms and conditions of the following Sections will survive any termination or expiration of this Agreement: Sections 1 (Definitions), 2.2 (Licenses), 2.3 (Contributed Data), 3.2 (Removal of Contributed Data), 4(Contributed Data Specific Terms), 5.1 (Intellectual Property Rights), 6.2 (Disclaimer of Warranties), 6.3 (Indemnification), 6.4 (Limitation of Liability), 7.3 (Effect of Termination), and 8 (Miscellaneous) and <b>Exhibit B</b>.  </li>
                                <li>7.3.2.	Upon written request of Contributor and within thirty (30) days after such request, CVB will cause Contributed Data to be removed from the Commons.  However, Contributor acknowledges and agrees that removal of Contributed Data will in no way affect the rights of Users in Results generated using Contributed Data prior to removal.</li>

                            </ul>
                        </li>

                    </ul>
                </li>
                <li>
                    <b>Miscellaneous</b>
                    <ul>
                        <li><b>8.1. Governing Law.</b>  This Agreement shall be governed by and interpreted in accordance with the internal substantive laws of the State of New York.
                        </li>
                        <li><b>8.2.	Notices.</b>  Any notice or other document or communication required or permitted under this Agreement will be deemed to have been duly given only if in writing and delivered by the following method: to the email address provided by Contributor in connection with its registration for the BRAINCommons or such other email address as Contributor may dictate according to the notice provisions hereof, and for CVB, to the following email address: <a href='notice@braincommons.org'>notice@braincommons.org</a> or such other email address as CVB may dictate according to the notice provisions hereof.  Notices shall be deemed delivered when sent provided there is reasonable indication of receipt by the Party being notified.</li>
                        <li><b>8.3.	Agreement.</b>  This Agreement (including all attachments hereto) sets forth the entire understanding of the Parties and supersedes all prior agreements and understandings regarding the subject matter hereof.  If any provision of this Agreement is found to be illegal or invalid, such provision will be modified to the extent necessary to comply with applicable law and refashioned to best approximate the original intent of the Parties, and the remaining provisions shall remain in full force and effect in accordance with their terms.  The waiver of any breach or default will not constitute a waiver of any other right or any subsequent breach or default.  No amendment of this Agreement is binding unless accepted in writing by authorized representatives of both Parties.  The relationship between the Parties under this Agreement is that of independent contractors.  This Agreement may be executed in one or more counterparts, each of which shall be deemed an original, but collectively shall constitute one and the same instrument.  In the event of a conflict between the body of this Agreement and an Exhibit, the terms in the body hereof will supersede, except to the extent that terms in an Exhibit expressly state that they prevail.</li>
                        <li><b>8.4.	Assignment.</b>  Neither Party may assign this Agreement without the written consent of the other Party; provided, however, that: (a) either Party may assign this Agreement to an affiliate; and (b) either Party may assign this Agreement to an entity that acquires all or substantially all of the assets or business of such Party.</li>
                        <li><b>8.5.	Force Majeure.</b>  Each Party will be excused from any delay or failure in performance resulting from any events or circumstances beyond such Party’s reasonable control.</li>
                        <li><b>8.6.	Publicity.</b>  Each Party reserves the right to approve, in writing and in advance, any use of its names, trademarks, logos or other proprietary indicia by the other Party.  Any approved use will be in accordance with the approving Party’s applicable trademark usage guidelines (as provided or made available to the other Party).  Notwithstanding the foregoing, (a) CVB may list Contributor as a data contributor (or a substantially similar or equivalent designation) in or in connection with the BRAINCommons in relation to the Contributed Data; and (b) unless Contributor provides CVB with express written notice to the contrary, CVB may list Contributor as a partner (or a substantially similar or equivalent designation) on CVB’s website.  Notwithstanding the foregoing, but subject to the requirements of Section 8.7 (Confidentiality), Contributor may publicly identify the availability of Contributed Data on the Platform and use any name used by BC Manager to publicly identify the Platform, including without limitation that CVB hosts the Contributed Data.</li>
                        <li><b>8.7.	Confidentiality.</b>  The Parties agree that this Agreement, including its terms and conditions, constitute the confidential information of CVB.  Contributor shall not disclose this Agreement, or any of its terms, without the prior written consent of CVB.</li>
                        <li><b>8.8.	Parties.</b>  Notwithstanding anything else in this Agreement, wherever the term “BC Manager” appears in this Agreement, Contributor shall be entitled to hold CVB liable for performance of each such term or condition as if CVB were acting as the BC Manager.</li>
                        <li><b>8.9.	Compliance with Applicable Laws.</b>  The Parties hereby agree that they will cooperate in good faith to comply with all laws applicable to the Contributed Data, including without limitation cooperating in good faith to enter into additional agreements and/or amendments to the Agreement in order to do so; provided that such additional agreement and/or amendment shall in no event reallocate each Party’s responsibilities as set forth herein.  </li>
                    </ul>
                </li>

            </ol>
            <p>Each Party has caused this Agreement to be duly executed by its respective authorized representative to take effect as of the Effective Date.</p>
            <div className={Classes.agreementFormMainContainer} id='cvb-dataContributorAgreement-stageOneFormAgreement'>
                <p id='cvb-dataContributorAgreement-stageOneContributorTitle' className={Classes.signingAuthText}> Contributor:</p>
                <div className={Classes.signFormInputContainer} id='cvb-dataContributorAgreement-stageOneAgreementContainer'>
                    <div className={Classes.signatureContainer}>
                        <p id='cvb-dataContributorAgreement-stageOneSignAgreementTitle'>
                            <label>Sign Agreement<sup> *</sup></label>
                        </p>
                        <p id='cvb-dataContributorAgreement-stageOneSignAgreementDescription'>Sign your name on the trackpad and press any key when finished</p>
                        <div id='signature-pad' className={Classes.signaturePad}>
                        { !isSignatureValid() && <p id='cvb-dataContributorAgreement-stageOneSignAgreementClickText' className={Classes.clickHeretoBegin}>Click here to begin</p>}
                            <SignatureCanvas penColor='black'
                                ref={dcaRef}
                                canvasProps={{ width: 400, height: 150, className: 'sigCanvas' }} 
                                onEnd={onSignatureEnd}
                                />
                            {
                                isFormSubmitted && !isSignatureValid() &&
                                <ErrorText errorMessage="Signature mandatory" />
                            }
                        </div>
                        <button id='cvb-dataContributorAgreement-stageOneSignAgreementClearLink' className={`no-outlined-button ${Classes.clearButton}`} onClick={clearSignature}>Clear</button>

                    </div>
                    <Grid item>
                        <div className={Classes.agreeContainer}>
                            <span className={Classes.agreeCheckbox}>
                                <Checkbox
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    value={isAgreeSelected}
                                    checked={isAgreeSelected}
                                    onChange={(e) => setIsAgreeSelected(e.target.checked)}
                                />
                            </span>
                            <span className={Classes.agreeContent}>
                            {agreementText ? agreementText : "I confirm that by signing this Agreement I am legally bound by its terms and conditions" }
                            </span>
                        </div>
                        {
                            isFormSubmitted && !isAgreeSelected &&
                            <ErrorText errorMessage="Please confirm agreement" />
                        }
                    </Grid>
                </div>
                <div className={Classes.signFormInputContainer}>
                    <label className={Classes.signatoryLabel}>Name :</label>
                    <OutlinedInput
                        value={fullNameOfUser}
                        disabled
                        fullWidth
                        id='cvb-dataContributorAgreement-contributorSigningName'
                    />
                </div>
                <div className={Classes.signFormInputContainer}>
                    <label className={Classes.signatoryLabel}>Title :</label>
                    <OutlinedInput
                        value={usersDesignation}
                        disabled
                        fullWidth
                        id='cvb-dataContributorAgreement-contributorSigningTitle'
                    />
                </div>
            </div>
        </div>
    )
}

DataContributeAgreementStage.defaultProps = {
    dcaClientName: '',
    dcaAgreementDate: null,
    dcaContributorName: '',
    dataContributorAgreementSignature: null,
    contributorSigningByText: '',
    contributorSigningName: '',
    contributorSigningTitle: '',
    contributorSigningDate: null,
    dcaReviewDate: null,
    dcaReviewApproveText: '',
    dcaReviewByText: '',
    dcaReviewerName: '',
    dcaReviewerTitle: '',
    dcaReviewerSignDate: null,
    dcaRef: null,
    requestData: '',
    isDashboard: false,
    setDCAAgreementDate: () => { },
    setDCAClientName: () => { },
    setDCAContributorName: () => { },
    setContributorSigningName: () => { },
    setContributorSigningByText: () => { },
    setContributorSigningTitle: () => { },
    setContributorSigningDate: () => { },

    setDcaReviewDate: () => { },
    setDcaReviewApproveText: () => { },
    setDcaReviewByText: () => { },
    setDcaReviewerName: () => { },
    setDcaReviewerTitle: () => { },
    setDcaReviewerSignDate: () => { },
};
export default DataContributeAgreementStage;