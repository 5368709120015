import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
  Slider,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { vars } from "../../../../assets/variables";
import AttributeCriteriaOption from "./AttributeCriteriaOption";
import CriteriaKeyOption from "./CriteriaKeyOption";
import { allSchema as ATTRIBUTE_DATA } from "../filter-schema";
import { getFiltersValues } from "../../../../service/CohortsService";
import { makeStyles, withStyles } from "@mui/styles";

import {ALL_ATTRIBUTES} from "../utils";

import { OPERATORS, SLIDER_OPERATORS, REG_OPERATORS, IN_OPERATOR, BETWEEN_OPERATOR } from "../constants";

const {
  primaryColor,
  primaryTextColor,
  selectPlaceholderColor,
  outlinedInputBorderColor,
  white,
} = vars;

const getItemStyle = (isDragging, draggableStyle, index) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  minHeight: 42,
  zIndex: 1000-index,
  // styles we need to apply on draggables
  ...draggableStyle,
});

const useStyles = makeStyles(() => ({
  block: {
    display: "flex",
    alignItems: "center",
    marginTop: "1.5rem",
    width: "max-content",
    position: 'unset'
  },

  nested: {
    marginTop: "0",
    marginBottom: "0.625rem",
  },

  sliderBox: {
    height: "2.8125rem",
    width: "9.75rem",
    padding: "0 0.75rem !important",
    display: "flex",
    alignItems: "center",
    border: `0.0625rem solid ${outlinedInputBorderColor}`,
  },

  wrapSecondary: {
    display: "flex",
    filter: "drop-shadow(0 0.0625rem 0.125rem rgba(231, 234, 242, 0.6))",

    "&  .MuiIconButton-root": {
      borderRadius: 0,
      height: "2.8125rem",
      backgroundColor: white,
      padding: "0.875rem 0.75rem !important",
      border: `0.0625rem solid ${outlinedInputBorderColor}`,
      color: primaryTextColor,

      "& + .MuiIconButton-root": {
        borderTopRightRadius: "0.375rem",
        borderBottomRightRadius: "0.375rem",
      },

      "& svg": {
        width: "1rem",
        height: "1rem",
      },
    },

    "& .MuiButton-text": {
      borderRadius: "0.375rem 0 0 0.375rem",
      height: "2.8125rem",
      backgroundColor: white,
      padding: "0.875rem 0.75rem !important",
      border: `0.0625rem solid ${outlinedInputBorderColor}`,
      minWidth: "9.75rem",

      color: primaryColor,
      justifyContent: "space-between",
      margin: 0,
      fontWeight: "500",
      fontSize: "0.875rem",

      "& span": {
        display: "block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },

      "& em": {
        fontStyle: "normal",
        color: selectPlaceholderColor,
      },

      "& svg": {
        color: primaryTextColor,
      },

      "&:hover": {
        borderColor: primaryColor,
      },

      "&[aria-describedby]": {
        borderColor: primaryColor,
        boxShadow: "0 0 0 0.0625rem rgba(77, 128, 198, 0.3)",
      },
    },

    "& .MuiOutlinedInput-input": {
      fontWeight: 500,
      fontSize: "0.875rem",
      color: primaryTextColor,
    },

    "& .MuiFormControl-root": {
      minWidth: "9.75rem",

      "& .MuiOutlinedInput-root": {
        borderRadius: 0,
        boxSizing: "border-box",
        height: "2.8125rem",
      },
    },
  },
}));

const CustomAutocomplete = withStyles({
  root: {
    minHeight: "2.8125rem",
    height: 'max-content !important',
    position: 'sticky',
    maxWidth: 300
  },
  focused: {
    "& .MuiOutlinedInput-root": {
      color: "red",
      position: 'absolute',
      zIndex: 10000
    }
  },
  inputRoot: {
    minHeight: "2.8125rem",
    height: 'max-content !important',
    position: 'absolute',
    width: 300
  },
  tag: {
    height: 24,
    maxWidth: '150px !important',
    position: "relative",
    zIndex: 0,
    borderRadius: '4px',
    backgroundColor: "rgba(77, 128, 198, 0.1)",
    fontSize: '0.875rem',
    padding:  '0rem 0.125rem',

    "& .MuiChip-label": {
      color: "rgba(77, 128, 198, 1)",
    },
    "& .MuiChip-deleteIcon": {
      color: "rgba(77, 128, 198, 1)",
      width: '1.0825rem',
      height: '1.0825rem',
      zIndex: 3
    },
    "&:after": {
      content: '""',
      right: 10,
      top: 6,
      height: 12,
      width: 12,
      position: "absolute",
      zIndex: 1
    },
  },
  endAdornment: {

    "& .makeStyles-wrapSecondary-89  .MuiIconButton-root": {
      borderWidth: '0',
    },
    "& .MuiAutocomplete-clearIndicator": {
      border: 'none', 
      background: 'none',
      top: -12,
      padding: '0.0125rem 0.0125rem !important',
    },
    "& .MuiAutocomplete-popupIndicator": {
      border: 'none', 
      background: 'none',
      top: -12,
      height: 'max-content',
      "& svg": {
        width: '1.5rem',
        height: '1.5rem', 
      },
    },
  },

})(Autocomplete);

function valuetext(value) {
  return `${value}°C`;
}

function getInitialAttribute(attributeKey) {
  return ATTRIBUTE_DATA.find(attribute => { 
    return attribute.queryVariable === attributeKey
  })
}

function getAttributeKey(attribute) {
  const obj = Object.fromEntries(Object.entries(attribute).filter(([key, _]) => key !== 'id'));
  const value = Object.values(obj)?.[0];

  const valueObj = Array.isArray(value)  ? Object.values(value[0])?.[0] : {}
  const key = Array.isArray(value) ? Object.keys(valueObj)[0]  : value !== undefined ? Object.keys(value)[0] : '';
  
  return key;
};

const getPropValue = (prop) => {
  const obj = Object.values(prop)[0];
  const value = Object.values(obj)[0]

  return value;
}

function getAttributeValue(attribute) {
  const obj = Object.fromEntries(Object.entries(attribute).filter(([key, _]) => key !== 'id'));
  const value = Object.values(obj)?.[0];
  if (Array.isArray(value) && value.length > 0) {
    return [];
  }
  const result = Object.values(value)?.[0] ?? [];
  return result;
}

function getAttributeSliderValues(attribute) {
  const obj = Object.fromEntries(Object.entries(attribute).filter(([key, _]) => key !== 'id'));
  const value = Object.values(obj)?.[0];

  if (Array.isArray(value) && value.length > 0) {
    return [getPropValue(value[0]), getPropValue(value[1])];
  } 
}

function findOperator(operator, attributeType) {
  const ops = attributeType === "slider" ? SLIDER_OPERATORS : attributeType === "filter" ? REG_OPERATORS : OPERATORS;
  return ops.find((op) => op.value === operator) || ops [0];
}

function CriteriaBlock(props) {
  const classes = useStyles();
  const {
    criteria,
    setCriteria,
    bullet = true,
    nested,
    slider,
    snapshot,
    provided,
    innerRef,
    index,
    parentOperator,
    setParentOperator,
    updateCriterion,
    onDelete,
  } = props;
  const criteriaKey = getAttributeKey(criteria);
  const filterColumnsAttributes = ALL_ATTRIBUTES.map((column) => ({ ...column, enabled: true }));
  const [attribute, setAttribute] = useState(() => getInitialAttribute(criteriaKey) ?? "");
  const [value, setValue] = useState(() => getAttributeValue(criteria) ?? []);
  const [selectOptions, setSelectOptions] = useState([]);
  const [sliderValues, setSliderValues] = useState(() => getAttributeSliderValues(criteria));
  const [valueSelector, setValueSelector] = useState({
    type: "value",
    operators: OPERATORS,
  });
  const [operator, setOperator] = useState("");

  const getOperator = (filters) => {
    return findOperator(Object.keys(criteria)[0], attribute?.type || (filters ? "filter" : "value"));
  }

  const updateSelector = (filters) => {
    setOperator(getOperator(filters));
    setSelectOptions(filters);

    if (attribute?.type === "slider") {
      setValueSelector({ type: "slider", operators: SLIDER_OPERATORS });
      if(!sliderValues){
        setSliderValues([filters[0].min, filters[0].max]);
      }
    } else if (filters && filters.length > 0) {
      setValueSelector({ type: "filter", operators: REG_OPERATORS });
    } else {
      setValueSelector({ type: "value", operators: OPERATORS });
    }
  };

  useEffect(() => {

    if (!attribute) {
      return;
    }
    getFiltersValues(filterColumnsAttributes, attribute.queryVariable)
      .then((filters) => updateSelector(filters))
      .catch((err) => console.log("err", err));
  }, [attribute]);

  useEffect(() => {
    if (!attribute || !operator) {
      return;
    }
    let criterionDefinition = null;
    if (sliderValues && attribute?.type === "slider" && criteria && operator == BETWEEN_OPERATOR) {
      criterionDefinition = {
        AND: [
          {
            gte: {
              [attribute.queryVariable]: sliderValues[0],
            },
          },
          {
            lte: {
              [attribute.queryVariable]: sliderValues[1],
            },
          },
        ],
        id: criteria.id,
      };
    } else if(value !== null) {
      criterionDefinition = {
        [operator.value]: {
          [attribute.queryVariable]: value,
        },
      };
    }
    updateCriterion(criteria.id, criterionDefinition);
  }, [operator, value, sliderValues]);

  const updateOperator = (operatorObj) => {
    setOperator(operatorObj);
    setValue([])
  };

  const updateValue = (val) => {
    if (val) {
      setValue(val);
    }
  };

  const handleSliderChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    
    setSliderValues(newValue);
    
  };

  const blockClass = nested
    ? `${classes.block} ${classes.nested}`
    : classes.block;

  return (
    <Box
      className={blockClass}
      maxWidth="100%"
      {...provided?.draggableProps}
      ref={innerRef}
      style={getItemStyle(
        snapshot?.isDragging,
        provided?.draggableProps?.style,
        index
      )}
    >
      <CriteriaKeyOption
        index={index}
        bullet={bullet}
        criteriaKey={parentOperator}
        setCriteriaKey={setParentOperator}
      />

      <Box className={classes.wrapSecondary}>
        <AttributeCriteriaOption
          attribute={attribute}
          setAttribute={(val) => {
            setAttribute(val);
            setValue([]);
          }}
        />

        <FormControl>
          <Select
            value={operator}
            onChange={(e) => updateOperator(e.target.value)}
            disabled={!attribute}
            displayEmpty
            pace
            placeholder="Operator"
          >
            {valueSelector?.operators.map((operator) => (
              <MenuItem value={operator} key={operator?.value}>{operator.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        
          {valueSelector?.type === "filter" && selectOptions.length ? (
            <Tooltip title={Array.isArray(value) ? value.join(", ") : value} placement="top">
              <CustomAutocomplete 
                multiple={operator === IN_OPERATOR}
                id='select-options'
                limitTags={1}
                value={value}
                onChange={(_, value) => {
                  updateValue(value)
                  }}
                options={(selectOptions).length > 0 ? selectOptions.map(option => option.key) : []}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Value" size="small" sx={{ width: '300px' }} />
                )}
                disableCloseOnSelect
                tagSizeSmall
              />
            </Tooltip>
          ) : operator == BETWEEN_OPERATOR ? (
            <Box className={classes.sliderBox}>
              <Tooltip title={`${sliderValues && sliderValues[0]} to ${sliderValues && sliderValues[1]}`} placement="top">
              <Slider
                getAriaLabel={() => "Minimum distance shift"}
                value={sliderValues}
                valueLabelDisplay="auto"
                onChange={handleSliderChange}
                getAriaValueText={valuetext}
                disableSwap
                min={selectOptions?.[0]?.min ?? 0}
                max={selectOptions?.[0]?.max ?? 100}
                step={1}
              />
              </Tooltip>
            </Box>
          ) : (
            <FormControl>
              <TextField
                placeholder="Enter a value..."
                value={value}
                variant="outlined"
                disabled={!attribute}
                type={attribute?.type === "slider" ? "number" : "string"}
                onChange={(e) => setValue(e.target.value ?? '')}
              />
            </FormControl>
          )}
        
        <Tooltip title="Delete item">
          <IconButton onClick={() => onDelete(criteria)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Drag and drop to change order">
          <Box
            display="flex"
            {...provided?.dragHandleProps}
            className="MuiIconButton-root"
          >
            <DragIndicatorIcon />
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default CriteriaBlock;
