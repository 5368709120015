import React, { useEffect, useState } from 'react'
import Classes from './index.module.css';
import { Auth } from 'aws-amplify';
import isEmpty from 'lodash/isEmpty'
import Grid from '@mui/material/Grid'
import { getAllDCAForm, downloadDCAForm, downloadDIFForm, deleteDCAForm } from '../../../api/dca-form-api';
import map from 'lodash/map'
import { navigate } from '@reach/router';
import * as moment from 'moment'
import get from 'lodash/get'
import { getAllDIFRequest, deleteDIFForm } from '../../../api/dif-form-api';
import { DIF_STAGE_COMPLETED_STATUS } from '../../../constants/strings';
import { getUserProfile, getUserDocumentURLForDownload, getSubmittedDUP, getDataRequestStatus } from '../../../api/profile-api'
import { getWorkspaceRequests, getAllWorkspaceOwnerShipDetails, getServiceAgreementDetails } from '../../../api/workspace-api/workspace-api'
import { getCertificates } from '../../../api/certificate-api'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import ViewDataForm from '../../controls/view-data-forms'
import TermsOfUse from '../../commons/terms-of-use/'
import PrivacyPolicy from '../../commons/privacy-policy/'
import Modal from '../../commons/modal'
import { getCertificateUrl } from '../../../api/certificate-api'
import CertificatePreview from '../../controls/certificate-preview'
import { getStageNumberFromRes } from '../../../utils/stringFormatter'
import { lowerCase, filter, sortBy } from 'lodash';
import ViewInstitutionCertificateForm from '../../controls/view-institution-certificate-form'
import { sendGAEvents } from '../../../api/generic-api';
import RequestAdvancedComputeDetailsView from '../../commons/request-advanced-compute-details-view';
import OwnershipTransferRequestDocumentModel from './OwnershipTransferRequestDocumentModel'
import OwnershipTransferActionModel from './OwnershipTransferActionModel'

const Authorisation = props => {
  const [dcaRequestData, setDCARequestData] = useState([]);
  const [difRequestData, setDifRequestData] = useState([]);
  const [selectedWorkspaceRequest, setSelectedWorkspaceRequest] = useState({});
  const [workspaceRequests, setWorkspaceRequests] = useState([]);
  const [workspaceOwnershipDetails, setWorkspaceOwnershipDetails] = useState([]);
  const [authData, setData] = useState([])
  const [certificates, setCertificates] = useState([])
  const [usersData, setUserData] = useState([]);
  const [userDCAData, setDCAData] = useState([]);
  const [userDIFData, setDIFData] = useState([]);
  const [dataAccess, setDataAccess] = useState([]);
  const [dataAccessForModal, setDataAccessForModal] = useState(null);
  const [openTermsOfUseModal, setOpenTermsOfUseModal] = useState(false);
  const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState(false);
  const [openDataAccessModal, setOpenDataAccessModal] = useState(false);
  const [openServiceAgreementModal, setOpenServiceAgreementModal] = useState(false);
  const [certificatePreviewModal, setCertificatePreviewModal] = useState(false);
  const [selectedCertificateName, setSelectedCertificateName] = useState('');
  const [deleteFormModalDetails, setDeleteFormModalDetails] = useState({});
  const [deleteFormModal, setDeleteFormModal] = useState(false);
  const [viewDUPPageNumber, setViewDUPPageNumber] = useState(0);
  const [workspaceDetails, setWorkspaceDetails] = useState({});
  const [showOwnershipTransferRequestDocument, setShowOwnershipTransferRequestDocument] = useState(null);
  const [showOwnershipTransfer, setShowOwnershipTransfer] = useState(null);

  const [zone2Request, setZone2Request] = useState([]);
  const [zone3Request, setZone3Request] = useState([]);

  const closeCertificateReviewModal = () => {
    setCertificatePreviewModal(false);
  }

  const CERTIFICATE_VIEW_MODAL_PROPS = {
    modalTitle: selectedCertificateName,
  }


  const {
    resetInstitutionFormData = () => { },
    resetPrimaryInvestigatorData = () => { },
    resetDataSharingData = () => { },
    resetOtherPersonnelData = () => { },
    resetTimelineData = () => { },
    resetIndividualDatasetData = () => { },
    resetDifStudyData = () => { }

  } = props;

  const { setDifStage = () => { } } = props;
  const { setDCAStage = () => { } } = props;

  const loadDCARequestData = async () => {
    try {
      const res = await getAllDCAForm();
      setDCARequestData(res);
    } catch (error) {
      console.log('error in loading dca request');
    }
  }

  const loadDIFRequestData = async () => {
    try {
      setDifRequestData(true);
      const res = await getAllDIFRequest();
      if (res) {
        setDifRequestData(res);
      }
    } catch (error) {
      console.log('error in loading dif request');
    }
  }

  const toggleTermOfUseModal = () => {
    setOpenTermsOfUseModal(false);
  }
  const onOpenTermsOfUseModalClick = () => {
    setOpenTermsOfUseModal(true);
  }

  const TERM_OF_USE_MODAL = {
    modalTitle: "Terms Of Use",
    modalContent: "",

  }

  const togglePrivacyPolicyModal = () => {
    setOpenPrivacyPolicyModal(false);
  }
  const onOpenPrivacyPolicyModalClick = () => {
    setOpenPrivacyPolicyModal(true);
  }

  const PRIVACY_POLICY_MODAL = {
    modalTitle: "Privacy Notice",
    modalContent: "",

  }

  const toggleDataAccessModal = () => {
    setOpenDataAccessModal(false);
  }
  const onOpenDataAccessModalClick = () => {
    setOpenDataAccessModal(true);
  }
  const onDUPModalNextPrevClick = () => {
    let viewDUPPage = 0;
    switch (viewDUPPageNumber) {
      case 0: viewDUPPage = 1; break;
      case 1: viewDUPPage = 0; break;
      default: viewDUPPage = 0; break;
    }
    setViewDUPPageNumber(viewDUPPage);
  }
  const positiveDUPModalButtonText = viewDUPPageNumber === 0 ? 'Next' : 'Previous';
  const dupViewTitle = viewDUPPageNumber === 0 ? 'Data Use Agreement' : 'Institutional Certification';

  const DATA_ACCESS_MODAL = {
    modalTitle: dupViewTitle,
    modalContent: "",
    positiveButtonText: positiveDUPModalButtonText,
    negativeButtonText: "Cancel",
    positiveButtonAction: onDUPModalNextPrevClick,
    negativeButtonAction: toggleDataAccessModal

  }
  const toggleServiceAgreementModal = () => {
    setOpenServiceAgreementModal(false);
  }
  const onOpenServiceAgreementModalClick = async (workspace) => {
    setSelectedWorkspaceRequest(workspace);
    setOpenServiceAgreementModal(true);
  }

  const SERVICE_AGREEMENT_MODAL = {
    modalTitle: "Proposal",
    modalContent: "",
  }

  const getData = async () => {
    let userData = [], privacyDate = "", privacyTime = "",
      status = "", userValues = "", userInfo = "", tosURL = "",
      privacyPolicyURL = "";
    try {
      userValues = await Auth.currentUserInfo();
      userInfo = await getUserProfile(userValues?.attributes?.sub);
      tosURL = await getUserDocumentURLForDownload(userValues?.attributes?.sub, 'terms_of_use');
      privacyPolicyURL = await getUserDocumentURLForDownload(userValues?.attributes?.sub, 'privacy_notice');
    } catch (error) {
      console.log(error);
    } finally {
      if (userInfo?.user_details?.custom_attributes?.privacy_policy_date) {
        privacyDate = moment.utc(userInfo?.user_details?.custom_attributes?.privacy_policy_date)?.local().format('MM/DD/YYYY');
        privacyTime = moment.utc(userInfo?.user_details?.custom_attributes?.privacy_policy_date)?.local().format('hh:mm A');
        status = "Submitted";
      }
      userData.push({
        "type": "Terms Of Use",
        "indexNumber": 1,
        "id": "terms",
        "url": tosURL?.document_url || '',
        "submission_status": status,
        "hideDownload": !tosURL?.document_url,
        "submission_date": privacyDate + ' at ' + privacyTime,
        "approval_status": "NA",
        "expiry_date": "-",
        "expand": false
      }, {
        "type": "Privacy Notice",
        "indexNumber": 2,
        "id": "privacy",
        "url": privacyPolicyURL?.document_url || '',
        "hideDownload": !privacyPolicyURL?.document_url,
        "submission_status": status,
        "submission_date": privacyDate + ' at ' + privacyTime,
        "approval_status": "NA",
        "expiry_date": "-",
        "expand": false
      });
      setUserData(userData);
    }

  }

  useEffect(() => {
    setData([...authData, ...certificates])
  }, [certificates]);

  useEffect(() => {
    setData([...authData, ...usersData])
  }, [usersData]);

  useEffect(() => {
    const filteredData = filter(authData, (data) => {
      if (data?.type === 'Data Contribution Agreement') {
        return false;
      }
      return true
    });
    const sortedData = sortBy([...filteredData, ...userDCAData], (data) => data?.indexNumber);
    setData(sortedData)
  }, [userDCAData]);

  useEffect(() => {
    const tempAuthData = [...certificates, ...userDCAData, ...dataAccess, ...userDIFData, ...workspaceRequests, ...usersData, ...zone2Request, ...zone3Request, ...workspaceOwnershipDetails];
    const sortedData = sortBy(tempAuthData, (data) => data?.indexNumber);
    setData(sortedData);
  }, [certificates, userDCAData, dataAccess, userDIFData, workspaceRequests, usersData, zone2Request, zone3Request, workspaceOwnershipDetails]);

  useEffect(() => {
    setData([...authData, ...dataAccess])
  }, [dataAccess]);

  useEffect(() => {
    const filteredData = filter(authData, (data) => {
      if (data?.type === 'Data Inventory Form') {
        return false;
      }
      return true
    })
    const sortedData = sortBy([...filteredData, ...userDIFData], (data) => data?.indexNumber);
    setData(sortedData)
  }, [userDIFData]);

  useEffect(() => {
    setData([...authData, ...workspaceRequests])
  }, [workspaceRequests]);

  useEffect(() => {
    let dcaData = [];
    if (dcaRequestData?.length) {
      let status = "";
      dcaRequestData.map((dca, index) => {
        const formCurrentStage = dca?.stage;
        let dataContributorAgreement = Number(dca?.stage[0]) === 1
          ? "Saved As Draft"
          : dca?.stage === 'Completed' ? 'Submitted' : (Number(dca?.stage[0]) === 1 ? 'Saved As Draft' : 'Completed');

        let definitions = Number(formCurrentStage[0]) === 2
          ? "Saved As Draft"
          : formCurrentStage === 'Completed' ? 'Submitted' :
            (Number(formCurrentStage[0]) > 2 ? 'Completed' : 'Pending');

        let datasetGovernance = Number(formCurrentStage[0]) === 3 ?
          "Saved As Draft" :
          formCurrentStage === 'Completed' ? 'Submitted' :
            (Number(formCurrentStage[0]) > 3 ? 'Completed' : 'Pending');

        let institutionalCertificates = Number(formCurrentStage[0]) === 4
          ? "Saved As Draft" :
          formCurrentStage === 'Completed' ? 'Submitted' : 'Pending';


        if (dataContributorAgreement === 'Saved As Draft' || definitions === 'Saved As Draft' || datasetGovernance === 'Saved As Draft'
          || institutionalCertificates === 'Saved As Draft') {
          status = "Saved As Draft"
        } else {
          status = "Submitted"
        }

        dcaData.push({
          "type": "Data Contribution Agreement",
          "indexNumber": 6,
          "id": "dca" + index,
          "requesterEmail": dca?.requesterEmail,
          "requesterSub": dca?.requesterSub,
          "uuid": dca?.uuid,
          "stage": dca?.stage,
          "submission_status": status,
          "submission_date": moment.utc(dca?.lastUpdatedDate)?.local().format('MM/DD/YYYY') + " at " + moment.utc(dca?.lastUpdatedDate)?.local().format('hh:mm A'),
          "approval_status": "NA",
          "expiry_date": "-",
          "expand": true,
          "hideDownload": status === "Saved As Draft" ? true : false,
          "showDeleteForm": lowerCase(dca?.requestStatus) === 'incomplete request',
          "expandedData": [{
            "type": "Data Contribution Agreement",
            "id": "dca-expanded",
            "submission_status": dataContributorAgreement,
            "submission_date": moment.utc(dca?.lastUpdatedDate)?.local().format('MM/DD/YYYY') + " at " + moment.utc(dca?.lastUpdatedDate)?.local().format('hh:mm A'),
            "approval_status": "",
            "expiry_date": ""
          }, {
            "type": "Definitions",
            "id": "definitions",
            "submission_status": definitions,
            "submission_date": moment.utc(dca?.lastUpdatedDate)?.local().format('MM/DD/YYYY') + " at " + moment.utc(dca?.lastUpdatedDate)?.local().format('hh:mm A'),
            "approval_status": "",
            "expiry_date": ""
          }, {
            "type": "Contributed Dataset Governance Form",
            "id": "dgf",
            "submission_status": datasetGovernance,
            "submission_date": moment.utc(dca?.lastUpdatedDate)?.local().format('MM/DD/YYYY') + " at " + moment.utc(dca?.lastUpdatedDate)?.local().format('hh:mm A'),
            "approval_status": "",
            "expiry_date": ""
          }, {
            "type": "Institutional Certification",
            "id": "institutional-certifications",
            "submission_status": institutionalCertificates,
            "submission_date": moment.utc(dca?.lastUpdatedDate)?.local().format('MM/DD/YYYY') + " at " + moment.utc(dca?.lastUpdatedDate)?.local().format('hh:mm A'),
            "approval_status": "",
            "expiry_date": ""
          }]
        })
      });
    }
    setDCAData(dcaData);
  }, [dcaRequestData]);

  useEffect(() => {
    let difData = [];
    if (difRequestData?.length) {
      difRequestData.map((dif, index) => {
        difData.push({
          "type": "Data Inventory Form",
          "indexNumber": 7,
          "request_id": dif?.request_id,
          "stage": dif?.stage,
          "submitter_email": dif?.submitter_email,
          "submitter_sub": dif?.user_sub,
          "id": "data-inventory-form" + index,
          "hideDownload": dif?.stage !== DIF_STAGE_COMPLETED_STATUS ? true : false,
          "submission_status": dif?.stage === DIF_STAGE_COMPLETED_STATUS ? "Submitted" : (dif?.stage >= 1 ? "Saved As Draft" : "Completed"),
          "submission_date": moment.utc(dif?.updated_timestamp)?.local().format('MM/DD/YYYY') + " at " + moment.utc(dif?.updated_timestamp)?.local().format('hh:mm A'),
          "approval_status": dif?.status,
          "expiry_date": "-",
          "expand": true,
          "showDeleteForm": dif?.status === 'draft',
          "expandedData": [{
            "type": "Institution",
            "id": "dif-institutions",
            "submission_status": dif?.stage === DIF_STAGE_COMPLETED_STATUS ? "Submitted" : (dif?.stage >= 1 ? (Number(dif?.stage) === 1 ? "Saved As Draft" : "Completed") : ''),
            "submission_date": moment.utc(dif?.updated_timestamp)?.local().format('MM/DD/YYYY') + " at " + moment.utc(dif?.updated_timestamp)?.local().format('hh:mm A'),
            "approval_status": "",
            "expiry_date": ""
          }, {
            "type": "Study",
            "id": "study",
            "submission_status": dif?.stage === DIF_STAGE_COMPLETED_STATUS ? "Submitted" : (dif?.stage >= 2 ? (Number(dif?.stage) === 2 ? "Saved As Draft" : "Completed") : ''),
            "submission_date": moment.utc(dif?.updated_timestamp)?.local().format('MM/DD/YYYY') + " at " + moment.utc(dif?.updated_timestamp)?.local().format('hh:mm A'),
            "approval_status": "",
            "expiry_date": ""
          }, {
            "type": "Primary Investigator",
            "id": "primaryInvestigator",
            "submission_status": dif?.stage === DIF_STAGE_COMPLETED_STATUS ? "Submitted" : (dif?.stage >= 3 ? (Number(dif?.stage) === 3 ? "Saved As Draft" : "Completed") : ''),
            "submission_date": moment.utc(dif?.updated_timestamp)?.local().format('MM/DD/YYYY') + " at " + moment.utc(dif?.updated_timestamp)?.local().format('hh:mm A'),
            "approval_status": "",
            "expiry_date": ""
          }, {
            "type": "Data Sharing",
            "id": "dataSharing",
            "submission_status": dif?.stage === DIF_STAGE_COMPLETED_STATUS ? "Submitted" : (dif?.stage >= 4 ? (Number(dif?.stage) === 4 ? "Saved As Draft" : "Completed") : ''),
            "submission_date": moment.utc(dif?.updated_timestamp)?.local().format('MM/DD/YYYY') + " at " + moment.utc(dif?.updated_timestamp)?.local().format('hh:mm A'),
            "approval_status": "",
            "expiry_date": ""
          }, {
            "type": "Other Personal",
            "id": "otherPersonal",
            "submission_status": dif?.stage === DIF_STAGE_COMPLETED_STATUS ? "Submitted" : (dif?.stage >= 5 ? (Number(dif?.stage) === 5 ? "Saved As Draft" : "Completed") : ''),
            "submission_date": moment.utc(dif?.updated_timestamp)?.local().format('MM/DD/YYYY') + " at " + moment.utc(dif?.updated_timestamp)?.local().format('hh:mm A'),
            "approval_status": "",
            "expiry_date": ""
          }, {
            "type": "Timeline",
            "id": "timeline",
            "submission_status": dif?.stage === DIF_STAGE_COMPLETED_STATUS ? "Submitted" : (dif?.stage >= 6 ? (Number(dif?.stage) === 6 ? "Saved As Draft" : "Completed") : ''),
            "submission_date": moment.utc(dif?.updated_timestamp)?.local().format('MM/DD/YYYY') + " at " + moment.utc(dif?.updated_timestamp)?.local().format('hh:mm A'),
            "approval_status": "",
            "expiry_date": ""
          }, {
            "type": "Individual Dataset",
            "id": "individualDataset",
            "submission_status": dif?.stage === DIF_STAGE_COMPLETED_STATUS ? "Submitted" : (dif?.stage >= 7 ? (Number(dif?.stage) === 7 ? "Saved As Draft" : "Completed") : ''),
            "submission_date": moment.utc(dif?.updated_timestamp)?.local().format('MM/DD/YYYY') + " at " + moment.utc(dif?.updated_timestamp)?.local().format('hh:mm A'),
            "approval_status": "",
            "expiry_date": ""
          }]
        });
      });
    }
    setDIFData(difData);

  }, [difRequestData])

  const getPolicyData = async () => {
    let response = '', dataUsePolicyDocumentURL = '';
    try {
      response = await getSubmittedDUP();
      dataUsePolicyDocumentURL = await getUserDocumentURLForDownload('', 'data_use_policy');
    }
    catch (error) {
      console.log(error)
    } finally {
      if (response?.isDupSubmitted) {
        let status = ""
        if (response?.requestedDate) {
          response.dayStr = moment.utc(response?.requestedDate)?.local().format('MM/DD/YYYY');
          response.timeStr = moment.utc(response?.requestedDate)?.local().format('hh:mm A');
          status = "Submitted";
        }
        const approvalStatus = `${response?.requestStatus}`.replaceAll("_", " ");
        let dataUsePolicy = [{
          "type": "Data Use Agreement",
          "indexNumber": 3,
          "id": "data-use-policy",
          "url": dataUsePolicyDocumentURL?.document_url || '',
          "hideDownload": !dataUsePolicyDocumentURL?.document_url,
          "submission_status": status,
          "submission_date": response.dayStr + ' at ' + response.timeStr,
          "approval_status": approvalStatus,
          "expiry_date": response?.expiryDate || '-',
          "expand": false
        }];
        setDataAccessForModal(response)
        setDataAccess(dataUsePolicy)
      }
    }
  }

  const getZone2Request = async () => {
    let response = [];
    let finalData = []
    try {
      response = await getDataRequestStatus('zone2');
    }
    catch (error) {
      console.log(error)
    } finally {
      if (Array.isArray(response)) {
        let status = ""
        for (let item of response) {
          if (item?.request_submitted_time) {
            item.dayStr = moment.utc(item?.request_submitted_time)?.local().format('MM/DD/YYYY');
            item.timeStr = moment.utc(item?.request_submitted_time)?.local().format('hh:mm A');
            if((item?.request_status).toLowerCase() === 'rejected' || (item?.request_status).toLowerCase() === 'approved'){
              status = "Completed";
            }else{
              status = "Submitted";
            }            
          }
          const approvalStatus = `${item?.request_status}`.replaceAll("_", " ");
          finalData.push({
            "type": "Zone 2",
            "indexNumber": 8,
            "id": "zone-2",
            "url": "",
            "hideDownload": true,
            "hideView": true,
            "submission_status": status,
            "submission_date": item.dayStr + ' at ' + item.timeStr,
            "approval_status": approvalStatus,
            "expiry_date": item?.expiry_date || '-',
            "expand": false
          })
        }
        setZone2Request(finalData)
      }
    }
  }

  const getZone3Request = async () => {
    let response = '';
    try {
      response = await getDataRequestStatus('zone3');
    }
    catch (error) {
      console.log(error)
    } finally {
      if (response?.user_zone3_access_requests?.length) {
        let zone3Request = [];
        response.user_zone3_access_requests.map((zone3, index) => {
          let status = ""
          if (zone3?.request_submitted_time) {
            zone3.dayStr = moment.utc(zone3?.request_submitted_time)?.local().format('MM/DD/YYYY');
            zone3.timeStr = moment.utc(zone3?.request_submitted_time)?.local().format('hh:mm A');
            status = "Submitted";
          }
          zone3Request.push({
            "type": `Zone 3 Request for ${zone3.project_id}`,
            "indexNumber": 9,
            "id": `zone-3 ${index}`,
            "url": "",
            "hideDownload": true,
            "hideView": true,
            "submission_status": status,
            "submission_date": zone3.dayStr + ' at ' + zone3.timeStr,
            "approval_status": zone3?.request_status,
            "expiry_date": zone3?.expiry_date || '-',
            "expand": false
          });
        });

        setZone3Request(zone3Request)
      }
    }
  }

  const getAddedCertificates = async () => {
    try {
      const res = await getCertificates();
      const certificateList = get(res, 'certificate_list', []);
      let certificates = [];
      let status = certificateList?.length === 1 ? "Submitted" : "Pending";
      let expandedData = [];
      map(certificateList, (cert)=> {
        expandedData.push({
          "type": cert?.certificate_name || '',
          "id": cert?.certificate_name || '',
          "certificate": true,
          "submission_status": "",
          "submission_date": "",
          "approval_status": "",
          "expiry_date": ""
        })
      } )
      certificates.push({
        "type": "Training Certificate",
        "indexNumber": 5,
        "id": "terms-expanded",
        "submission_status": status,
        "submission_date": "",
        "approval_status": "",
        "expiry_date": "-",
        "expand": true,
        "open": false,
        "hideView": true,
        "hideDownload": status === "Pending" ? true : false,
        "expandedData": expandedData
      });
      let certificatesCheck = [];
      certificatesCheck.push(certificates[0].expandedData);
      certificates[0].expandedData.map((certificate, index) => {
        certificateList.map((cert) => {
            certificate.showView = true;
            certificate.showDownload = true;
            certificate.submission_status = "Submitted";
            certificate.submission_date = moment.utc(cert.uploaded_on)?.local().format('MM/DD/YYYY') + " at " + moment.utc(cert.uploaded_on)?.local().format('hh:mm A')
        });
        if (!certificate.submission_status) {
          certificatesCheck.splice(index, 1);
        }
      });
      if (!certificatesCheck?.length) {
        certificates[0].expand = false;
        certificates[0].expandedData = certificatesCheck;
      }
      setCertificates(certificates);

    } catch (error) {
      console.log('error in getting certificates', error)
    }
  }
  const getDateTimeObject = (response) => {
    let dayStr = '';
    let timeStr = '';
    let requestSubmittedDate = get(response, 'requestedDate', '');
    if (!requestSubmittedDate) {
      requestSubmittedDate = get(response, 'request_submitted_time', '')
    }
    const reqDateMomentObj = moment.utc(requestSubmittedDate);
    if (moment.isMoment(reqDateMomentObj)) {
      dayStr = reqDateMomentObj?.local().format('MM/DD/YYYY');
      timeStr = reqDateMomentObj?.local().format('hh:mm A');
    }
    response.dayStr = dayStr;
    response.timeStr = timeStr;
    return response;
  }
  const getAllWorkspaceRequest = async () => {
    let requests = '';
    let workspaceResponses = {}
    try {
      requests = await getWorkspaceRequests();
      if (requests?.length) {
        for (let request of requests) {
          try {
            const userSub = get(request, 'user_sub', '');
            const requestId = get(request, 'request_id', '');
            let workspaceResponse = await getServiceAgreementDetails(userSub, requestId);
            workspaceResponses[request?.request_id] = workspaceResponse;
          }
          catch (error) {
            if (!workspaceResponses[request?.request_id]) {
              workspaceResponses[request?.request_id] = null;
            }
          }
        }
        setWorkspaceDetails(workspaceResponses);
      }

    } catch (error) {
      console.log('error in getting workspace request', error);
      setWorkspaceRequests([]);
    } finally {
      if (!isEmpty(requests)) {
        requests = requests.map(request => getDateTimeObject(request));
        let workspaceRequestsData = [];
        requests.map((request, index) => {
          const approvalStatus = get(request, 'approval_status', '');
          const approvalStatusLabel = isEmpty(approvalStatus) ? '' : approvalStatus.replaceAll("_", " ");
          const submissionStatus = get(request, 'request_status', '');
          const submissionStatusLabel = isEmpty(submissionStatus) ? '' : submissionStatus.replaceAll("_", " ");
          workspaceRequestsData.push({
            "type": "Advanced Compute Request",
            "indexNumber": 4,
            "dayStr": request?.dayStr,
            "url": '',
            "timeStr": request?.timeStr,
            "id": "service-agreement" + index,
            "submission_status": submissionStatusLabel,
            "submission_date": request?.dayStr + ' at ' + request?.timeStr,
            "approval_status": approvalStatusLabel,
            "hideDownload": true,
            "expiry_date": request?.expiry_date || '-',
            "expand": false,
            "request_id": request?.request_id
          })
        });
        workspaceRequestsData = [...workspaceRequestsData];
        setWorkspaceRequests(workspaceRequestsData);
      }
    }
  }

  const fetchAllWorkspaceOwnerShipDetails = async () => {
    try {
      const userValues = await Auth.currentUserInfo();
      const currentEmail = userValues?.attributes?.email;
      const response = await getAllWorkspaceOwnerShipDetails();
      let workspaceOwnerships = [];
      if (Array.isArray(response)) {
        for (let item of response) {
          const dayStr = moment.utc(item?.submitted_at)?.local().format('MM/DD/YYYY');
          const timeStr = moment.utc(item?.submitted_at)?.local().format('hh:mm A');
          if (item?.workspace_owner?.email === currentEmail) {
            workspaceOwnerships.push({
              ...item,
              showActions: false,
              "type": "Workspace Ownership Transfer",
              "submission_date": dayStr + ' at ' + timeStr,
              "expiry_date": item?.expiry_date || '-',
              "submission_status": item.status,
              hideDownload: true
            })
          } else if (item?.workspace_recipient?.email === currentEmail) {
            workspaceOwnerships.push({
              ...item,
              showActions: (item.status !== "ACCEPTED" && item.status !== "REJECTED" && item.status !== "COMPLETED") ? true : false,
              "submission_status": item.status,
              "submission_date": dayStr + ' at ' + timeStr,
              "expiry_date": item?.expiry_date || '-',
              "type": "Workspace Ownership Transfer",
              hideDownload: true
            })
          }
        }
      }
      setWorkspaceOwnershipDetails(workspaceOwnerships)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    loadDCARequestData();
    loadDIFRequestData();
    getData();
    getAllWorkspaceRequest();
    getPolicyData();
    getZone2Request();
    getZone3Request();
    fetchAllWorkspaceOwnerShipDetails();
  }, [])

  useEffect(() => {
    getAddedCertificates();
  }, [])

  const onFormStageClick = (row) => {
    const uuid = row?.uuid;
    let stageNumber = getStageNumberFromRes(row?.stage);
    const requesterEmail = row?.requesterEmail;
    const requesterSub = row?.requesterSub;
    if (stageNumber === 3) {
      switch (stageNumber) {
        case '3A': stageNumber = 3; break;
        case '3B': stageNumber = 4; break;
        case '3C': stageNumber = 5; break;
        case '3D': stageNumber = 6; break;
        case '3E': stageNumber = 7; break;
        case '3F': stageNumber = 8; break;
        case '3G': stageNumber = 9; break;
        default: stageNumber = 3; break;
      }
    }
    props.resetDCAForm();
    props.resetProjectDetails();
    props.resetProjectInstitutionForm();
    props.resetPIForm();
    props.resetProjectAdminForm();
    props.resetZone3UserForm();
    props.resetAuthSubmitterForm();

    setDCAStage(stageNumber);

    navigate(`/my-stuff/data-contribute-agreement/${uuid}/${requesterSub}`, { state: { formStage: stageNumber, requesterSub: requesterSub, requesterEmail: requesterEmail } });
  }

  const onOwnershipTransferViewClick = (row) => {
    setShowOwnershipTransferRequestDocument(row)
  }

  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });
  const onSubmitDCAClick = async () => {
    try {
      props.resetDCAForm();
      props.resetProjectDetails();
      props.resetProjectInstitutionForm();
      props.resetPIForm();
      props.resetProjectAdminForm();
      props.resetZone3UserForm();
      props.resetAuthSubmitterForm();
      props.setInstitutionalCertAdminName('');
      navigate('/my-stuff/data-contribute-agreement');
      props.setDCAStage(1);
    } catch (error) {
      console.log('error in reset error')
    }
  }
  const onSubmitDIFClick = () => {
    resetInstitutionFormData();
    resetPrimaryInvestigatorData();
    resetDataSharingData();
    resetOtherPersonnelData();
    resetTimelineData();
    resetIndividualDatasetData();
    resetDifStudyData();
    setDifStage(1);
    navigate('/my-stuff/data-inventory-form/')
  }
  const onDIFEditButtonClick = (requestData, index) => {
    const requesterEmail = get(requestData, 'submitter_email', '');
    const requesterSub = get(requestData, 'submitter_sub', '');
    const requestID = get(requestData, 'request_id', '')
    const currentStage = get(requestData, 'stage', '');
    if (!isEmpty(currentStage) || currentStage !== null) {
      setDifStage(index);
    } else {
      setDifStage(1);
    }
    navigate(`/my-stuff/data-inventory-form/${requestID}/${requesterSub}`, { state: { dashboard: false, requesterSub: requesterSub, requesterEmail: requesterEmail } });
  }

  const viewDocument = (row) => {
    if (row?.type === "Terms Of Use") {
      sendGAEvents('view_terms_of_use_document', 'terms_of_use', 'View terms of use document');
      onOpenTermsOfUseModalClick();
    } else if (row?.type === "Privacy Notice") {
      sendGAEvents('view_privacy_notice_document', 'privacy_notice', 'View privacy notice document');
      onOpenPrivacyPolicyModalClick();
    } else if (row?.type === "Advanced Compute Request") {
      sendGAEvents('view_service_agreement', 'service_agreement', 'View submitted service agreement');
      if(row?.submission_status?.toLowerCase() === "submitted"){
        navigate(`/my-profile/request-advance-compute/${row?.request_id}`)
      }else{
        onOpenServiceAgreementModalClick(row);
      }
    } else if (row?.type === "Data Inventory Form") {
      sendGAEvents('view_data_inventory_form', 'data_inventory_form', 'View submitted data inventory form');
      onDIFEditButtonClick(row, row?.stage)
    } else if (row?.certificate) {
      sendGAEvents('view_certificates', 'training_and_certificate', 'View uploaded certificates');
      setSelectedCertificateName(row?.type);
      setCertificatePreviewModal(true);
    } else if (row?.type === "Data Use Agreement") {
      sendGAEvents('view_data_use_agreement', 'data_use_agreement', 'View submitted data use agreement');
      onOpenDataAccessModalClick();
    } else if (row?.type === "Data Contribution Agreement") {
      sendGAEvents('view_data_contribution_agreement', 'data_contribution_agreement', 'View uploaded data contribution agreement');
      onFormStageClick(row)
    } else if (row?.type === "Workspace Ownership Transfer") {
      onOwnershipTransferViewClick(row)
    }
  }

  let count = 0;
  const downloadURL = (url) => {
    let hiddenIFrameID = 'hiddenDownloader' + count++;
    let iframe = document.createElement('iframe');
    iframe.id = hiddenIFrameID;
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    iframe.src = url;
  }

  const downloadDocument = async (row) => {
    if (row?.type === "Data Inventory Form") {
      let requests = await downloadDIFForm(row?.submitter_sub, row?.request_id);
      if (!isEmpty(requests) && requests?.document_url) {
        window.location.href = requests?.document_url;
      }
      return;
    }
    if (row?.type === "Data Contribution Agreement") {
      let requests = await downloadDCAForm(row?.requesterSub, row?.uuid);
      if (!isEmpty(requests)) {
        window.location.href = requests;
      }
    } else if (row?.type === "Training Certificate") {
      const res = await getCertificateUrl(row?.expandedData[0]?.type);
      const certUrl = get(res, 'url', '');
      downloadURL(certUrl);

    } else if (row?.certificate) {
      const res = await getCertificateUrl(row?.type);
      const certUrl = get(res, 'url', '');
      window.location.href = certUrl;
    } else if (row?.type === "Terms Of Use" || row?.type === "Privacy Notice" ||
      row?.type === "Data Use Agreement" || row?.type === "Advanced Compute Request") {
      window.location.href = row.url;
    }
  }

  const onActions = row => {
    setShowOwnershipTransfer(row)
  }

  const deleteForm = (row) => {
    const modalTitle = row?.type === 'Data Inventory Form' ? 'Are you sure you want to delete this Data Inventory Form ?' :
      'Are you sure you want to delete this Data Contribution Agreement Form ?';
    setDeleteFormModalDetails({ ...row, title: modalTitle });
    setDeleteFormModal(true);
  }
  const onDeleteFormConfirm = async () => {
    try {
      setDeleteFormModal(false);
      const isDif = deleteFormModalDetails?.type === 'Data Inventory Form';
      const isDca = deleteFormModalDetails?.type === 'Data Contribution Agreement';

      if (isDca) {
        sendGAEvents('delete_data_contribution_agreement', 'data_contribution_agreement', 'delete uploaded data contribution agreement');
        const uuid = deleteFormModalDetails?.uuid;
        const res = await deleteDCAForm(uuid);
        props.updateSnackBar('Form deleted successfully.', 'success');
        await loadDCARequestData();
      }
      if (isDif) {
        sendGAEvents('delete_data_inventory_form', 'data_inventory_form', 'delete uploaded data inventory form');
        const uuid = deleteFormModalDetails?.request_id;
        const res = await deleteDIFForm(uuid);
        props.updateSnackBar('Form deleted successfully.', 'success');
        await loadDIFRequestData();
      }
    } catch (error) {
      setDeleteFormModal(false);
      console.log(error);
      props.updateSnackBar('Error in deleting form', 'error')
    }
  }
  const DELETE_FORM_MODAL = {
    modalTitle: deleteFormModalDetails?.title,
    modalContent: "",
    positiveButtonText: 'Delete',
    negativeButtonText: "Cancel",
    positiveButtonAction: onDeleteFormConfirm,
    negativeButtonAction: () => setDeleteFormModal(false)
  }
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    return (

      <React.Fragment>
        <TableRow className={classes.root}>

          <TableCell>

            {
              row?.expand ?
                <IconButton id='cvb-myStuff-expandRowButton' aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton> : null
            }

          </TableCell>
          <TableCell className={[Classes.organizationCell, Classes.userTableCell].join(" ")} component="th" scope="row">
            <Tooltip id='cvb-myStuff-tableRow-typeTitleValue' title={row?.type || ''}>
              <span id='cvb-myStuff-tableRow-typeValue' className={Classes.typeText}>{row?.type}</span>
            </Tooltip>
          </TableCell>

          <TableCell className={[Classes.organizationCell, Classes.userTableCell].join(" ")} component="th" scope="row">
            {
              row?.submission_status?.toLowerCase() === "completed" || row?.submission_status?.toLowerCase() === "submitted"
                || row?.submission_status?.toLowerCase() === "approved" || row?.submission_status?.toLowerCase() === "invoice paid" || row?.submission_status?.toLowerCase() === "accepted" ?
                <span id='cvb-myStuff-tableRow-submissionStatusValue' className={Classes.submitText}>{row?.submission_status}</span> :

                row?.submission_status?.toLowerCase() === "saved as draft" ?

                  <span id='cvb-myStuff-tableRow-submissionStatusValue' className={Classes.draftText}>{row?.submission_status}</span>
                  :

                  <span id='cvb-myStuff-tableRow-submissionStatusValue' className={Classes.pendingText}>{row?.submission_status}</span>
            }
          </TableCell>

          <TableCell className={[Classes.organizationCell, Classes.userTableCell].join(" ")} component="th" scope="row">
            <Tooltip id='cvb-myStuff-tableRow-submissionDate-tooltip' title={row?.submission_date || ''}>
              <span id='cvb-myStuff-tableRow-submissionDate'>{row?.submission_date || ''}</span>
            </Tooltip>
          </TableCell>

          <TableCell className={[Classes.emailCell, Classes.userTableCell].join(" ")} component="th" scope="row">
            {
              (row?.approval_status?.toLowerCase() === "approved" || row?.type == "Zone 2" || row?.type == "Advanced Compute Request") ?
                row?.approval_status?.toLowerCase() === "rejected" ?  <span id='cvb-myStuff-tableRow-approvalStatus' className={Classes.pendingText}>{row?.approval_status || ''}</span>:
                <span id='cvb-myStuff-tableRow-approvalStatus' className={Classes.submitText}>{row?.approval_status || ''}</span> :
                (row?.type === "Terms Of Use" && !row?.expand) || row?.type === "Privacy Notice" || row?.type === "Workspace Ownership Transfer" ?

                  <span id='cvb-myStuff-tableRow-approvalStatus'>NA</span> :

                  (row?.type === "Training Certificate") ?

                    <span id='cvb-myStuff-tableRow-approvalStatus' className={Classes.pendingText}></span> :

                    <span id='cvb-myStuff-tableRow-approvalStatus' className={Classes.pendingText}>Pending</span>
            }
          </TableCell>

          <TableCell className={[Classes.userRoleCell, Classes.userTableCell].join(" ")} style={{ position: 'relative' }} component="th" scope="row">
            <Tooltip id='cvb-myStuff-tableRow-expiryDate-tooltip' title={row?.expiry_date || ''}>
              <span id='cvb-myStuff-tableRow-expiryDateValue' className={Classes.expiryDateText}>{row?.expiry_date || ''}</span>


            </Tooltip>
            <div className={Classes.viewDownloadButtonWrapper}>
              {
                !row?.hideView ?
                  <button id='cvb-myStuff-tableRow-viewDocument-button'
                    onClick={() => viewDocument(row)}
                    className={Classes.statusButton}>
                    {
                      row?.submission_status?.toLowerCase() === "saved as draft" || 
                      (row?.submission_status?.toLowerCase() === "submitted" && row?.type === "Advanced Compute Request") ?
                        "Edit" : "View"
                    }
                  </button> : null
              }
              {
                !row?.hideDownload ?
                  <button id='cvb-myStuff-tableRow-downloadDocument-button'
                    onClick={() => downloadDocument(row)} className={Classes.statusButton}>
                    Download
                  </button> : null
              }
              {
                row?.showActions &&
                <button id='cvb-myStuff-tableRow-downloadDocument-button'
                  onClick={() => onActions(row)} className={Classes.statusButton}>
                  Actions
                </button>
              }
              {
                row.showDeleteForm &&
                <button id='cvb-myStuff-tableRow-deleteForms-button'
                  onClick={() => deleteForm(row)} className={Classes.statusButton}>
                  Delete
                </button>
              }
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0, background: '#f9f9f9' }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>

              <Table id='cvb-myStuff-table' size="small" aria-label="purchases">
                <TableHead style={{ display: 'none' }}>
                  <TableRow>
                    <TableCell id='cvb-myStuff-tableHead-searchColumn' className={Classes.searchColumn} width="5%"></TableCell>
                    <TableCell id='cvb-myStuff-tableHead-typeColumn' className={Classes.searchColumn} width="20%">Type</TableCell>
                    <TableCell id='cvb-myStuff-tableHead-subStatusColumn' className={Classes.searchColumn} width="16%" align="left">Submission Status</TableCell>
                    <TableCell id='cvb-myStuff-tableHead-subDateColumn' className={Classes.searchColumn} width="20%" align="left">Submission Date</TableCell>
                    <TableCell id='cvb-myStuff-tableHead-approvalStatusColumn' className={Classes.searchColumn} width="15%" align="left">Approval Status</TableCell>
                    <TableCell id='cvb-myStuff-tableHead-expiryDateColumn' className={Classes.searchColumn} width="25%" align="left">Expiry Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    row?.expand ?

                      row.expandedData.map((expandedRow) => (
                        <TableRow key={expandedRow.id}>
                          <TableCell className={`${Classes.organizationCell} ${Classes.userTableCell} ${Classes.iconsColumn}`} component="th" scope="row"></TableCell>
                          <TableCell id='cvb-myStuff-tableRow-expandedRow-type' className={[Classes.typeCell, Classes.userTableCell].join(" ")} component="th" scope="row" style={{minWidth: 195}}>
                            {expandedRow?.type}
                          </TableCell>

                          <TableCell className={[Classes.organizationCell, Classes.submissionStatus].join(" ")} component="th" scope="row" style={{paddingLeft: 14}}>
                            {
                              expandedRow?.submission_status?.toLowerCase() === "submitted" || expandedRow?.submission_status?.toLowerCase() === 'completed' ?
                                <span id='cvb-myStuff-tableRow-expandedRow-submissionStatus' className={Classes.submitText}>{expandedRow?.submission_status || ''}</span> :
                                expandedRow?.submission_status?.toLowerCase() === "saved as draft" ?

                                  <span id='cvb-myStuff-tableRow-expandedRow-submissionStatus' className={Classes.draftText}>{expandedRow?.submission_status}</span>
                                  :

                                  <span id='cvb-myStuff-tableRow-expandedRow-submissionStatus' className={Classes.pendingText}>{expandedRow?.submission_status}</span>
                            }
                          </TableCell>

                          <TableCell id='cvb-myStuff-tableRow-expandedRow-submissionDate' className={`${Classes.organizationCell} ${Classes.submissionDate} ${expandedRow?.certificate ? Classes.submissionDate : ""} ${Classes.userTableCell}`} component="th" scope="row">
                            {expandedRow?.submission_date}
                          </TableCell>
                          <TableCell id='cvb-myStuff-tableRow-expandedRow-approvalStatus' className={[Classes.organizationCell, Classes.userTableCell].join(" ")} component="th" scope="row">
                            {expandedRow?.approval_status}
                          </TableCell>
                          <TableCell id='cvb-myStuff-tableRow-expandedRow-expiryDate' className={[Classes.organizationCell, Classes.userTableCell].join(" ")} component="th" scope="row">
                            {expandedRow?.expiry_date}
                            {
                              expandedRow?.showDownload ?
                                <button id='cvb-myStuff-tableRow-expandedRow-downloadDocument' onClick={() => downloadDocument(expandedRow)} className={`${Classes.statusButton} ${Classes.viewCertificateButton}`} >Download</button> : null
                            }
                            {
                              expandedRow?.showView ?
                                <button id='cvb-myStuff-tableRow-expandedRow-viewDocument' onClick={() => viewDocument(expandedRow)} className={Classes.statusButton} >View</button> : null
                            }
                          </TableCell>
                        </TableRow>
                      )) : null}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <>

      <OwnershipTransferRequestDocumentModel
        showOwnershipTransferRequestDocument={showOwnershipTransferRequestDocument}
        setShowOwnershipTransferRequestDocument={setShowOwnershipTransferRequestDocument}
      />

      <OwnershipTransferActionModel
        showOwnershipTransfer={showOwnershipTransfer}
        setShowOwnershipTransfer={setShowOwnershipTransfer}
        fetchAfterSuccessfulTransfer={fetchAllWorkspaceOwnerShipDetails}
        updateSnackBar={props.updateSnackBar}
      />

      <TermsOfUse
        open={openTermsOfUseModal}
        handleClose={toggleTermOfUseModal}
        dialogProps={TERM_OF_USE_MODAL}



      />
      <PrivacyPolicy
        open={openPrivacyPolicyModal}
        handleClose={togglePrivacyPolicyModal}
        dialogProps={PRIVACY_POLICY_MODAL}


      />
      <Modal
        open={certificatePreviewModal}
        handleClose={closeCertificateReviewModal}
        dialogProps={CERTIFICATE_VIEW_MODAL_PROPS}
        disableTitleDivider={true}
      >
        <CertificatePreview selectedCertificateName={selectedCertificateName} />
      </Modal>
      <Modal open={openDataAccessModal} handleClose={toggleDataAccessModal} dialogProps={DATA_ACCESS_MODAL} disableTitleDivider disableBottomHorizontalDivider >
        <div className={[Classes.contentStartText, Classes.showHeader].join(' ')}>
          <p>
            <span id='cvb-myStuff-dataAccess-submitterDetails' className={Classes.submitDetails} ><p id='cvb-myStuff-dataAccess-submittedOnDetails' className={Classes.submitText}>Submitted</p> on {dataAccessForModal?.dayStr} at {dataAccessForModal?.timeStr}</span>
            <p id='cvb-myStuff-dataAccess-researcherName' className={Classes.submitName}>By {dataAccessForModal?.researcherName}</p>
          </p>

        </div>
        {viewDUPPageNumber === 0 && <ViewDataForm
          data={dataAccessForModal}
        />
        }
        {
          viewDUPPageNumber === 1 && <ViewInstitutionCertificateForm  {...dataAccessForModal} />
        }

      </Modal>

      <RequestAdvancedComputeDetailsView
        open={openServiceAgreementModal}
        handleClose={toggleServiceAgreementModal}
        dialogProps={SERVICE_AGREEMENT_MODAL}
        workspaceDetails={workspaceDetails[selectedWorkspaceRequest?.request_id]}
      >
      </RequestAdvancedComputeDetailsView>

      <Modal
        open={deleteFormModal}
        handleClose={() => setDeleteFormModal(false)}
        dialogProps={DELETE_FORM_MODAL}
        disableTitleDivider
      >

      </Modal>

      <div className={Classes.container} id='cvb-myAStuffAuthorization-tableContainer'>
        <Grid item lg={11} xl={8} xs={12} md={12}  className={Classes.myUploadHeaderContainer} >
          <p id='cvb-myAStuffAuthorization-mainHeadingText' className={Classes.myStuffSectionHeaders} >My Authorizations & Certifications</p>
          <div className={Classes.myUploadButtonWrapper}>
            {
              props.userDetails?.resourceActions?.my_stuff?.submit_dif ?
                <button id='cvb-myAStuffAuthorization-DIFButton' onClick={onSubmitDIFClick} className={`solid-button ${Classes.submitDIButton}`}>Submit Data Inventory Form</button>
                : null
            }
            {
              props.userDetails?.resourceActions?.my_stuff?.submit_dca ?
                <button id='cvb-myAStuffAuthorization-DCAButton' onClick={onSubmitDCAClick} className={`solid-button ${Classes.submitDCAButton}`}>Submit Data Contribution Agreement</button>
                : null
            }
              
          </div>
        </Grid>
        <Grid container item lg={11} xl={8} xs={12} md={12} spacing={4} className={Classes.authorizationContainer} id='cvb-myAStuffAuthorization-authorizationTableContainer' >

          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell className={Classes.searchColumn} width="5%"></TableCell>
                  <TableCell id='cvb-myAStuffAuthorization-tableHeaderType' className={Classes.typeColumn} style={{ borderLeft: 'none' }} width="20%">Type</TableCell>
                  <TableCell id='cvb-myAStuffAuthorization-tableHeaderStatus' className={Classes.searchColumn} width="16%" align="left">Submission Status</TableCell>
                  <TableCell id='cvb-myAStuffAuthorization-tableHeaderDate' className={Classes.searchColumn} width="20%" align="left">Submission Date</TableCell>
                  <TableCell id='cvb-myAStuffAuthorization-tableHeaderApprovalStatus' className={Classes.searchColumn} width="15%" align="left">Approval Status</TableCell>
                  <TableCell id='cvb-myAStuffAuthorization-tableHeaderExpiryDate' className={Classes.searchColumn} style={{ minWidth: 235 }} width="25%" align="left">Expiry Date</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {authData.map((row) => (
                  <Row key={row.id} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Grid>
      </div>
    </>
  )
}

export default Authorisation;