import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Classes from './index.module.css'
import map from 'lodash/map'
import { v4 } from 'uuid'
import NotebookInfoCards from '../notebook-info-cards'
import { getAllNotebooks } from '../../../api/notebook-api'

const WorkspaceSharedFilesContainer = (props) => {
    const [notebooks, setNotebooks] = useState([]);

    const loadNotebooks = async () => {
        try{
            const res = await getAllNotebooks();
            setNotebooks(res);
        }catch(error){
            console.log('error is setting notebook',error);
        }
    }
    useEffect(() => {
       loadNotebooks();
    }, []);

    return (
        <div className={Classes.WorkspaceRightContainer}>
            <Grid container >
                <Grid item xs={12}>
                    <div className={Classes.sharedTextContainer}>
                        <p id='cvb-workspaceRightContainer-shareNotebook-label' className={Classes.sharedText}> Shared notebooks</p>
                        <p className={Classes.hereText}>Here are your shared notebooks</p>
                    </div>
                    <Grid container spacing={2} >
                        {map(notebooks, (eventData) => {
                            return <NotebookInfoCards
                                key={v4()}
                                eventData={eventData}
                                popoverOptions={[]}
                                type='sharedNotebook'
                                usersForShare={props.usersForShare}
                            />
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default WorkspaceSharedFilesContainer;


