/**
 *  External Imports
 */
import React, { useState, useEffect } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {Calendar} from 'react-date-range';
import moment from 'moment';

/**
 *  Internal Imports
 */
import classes from './index.module.css'
import { ReactComponent as CalenderIcon } from '../../../assets/icons/calender-icon.svg';
import { ReactComponent as CrossMarkIcon } from '../../../assets/icons/cross-mark.svg';
import ErrorText from '../../controls/error-text';
import { addYears } from '../../../utils/date-formatter';

/**
 *  Component
 *
 *  @return {JSX.Element}
 */

const CommonDatePicker = (props) => {

   //Accepted props and default values
 
    const {
        onCalenderDateSelected = () => { },
        date = new Date(),
        showValidationError = false,
        errorMessage='',
        maxDate = addYears(new Date(), 50),
        minDate = addYears(new Date(), -50),
        id='',
        isFromModal = false,
        canClearInput = false,
        disabled = false
    } = props;
    
    const [openCalender, setOpenCalender] = useState(false);
    const [selectedCalenderInput, setSelectedCalenderInput] = useState('Select date');

    useEffect(() => {
        let calenderInputText = '';
        if(moment.isDate(date)){
            calenderInputText = moment(date).format('MM/DD/YYYY');
        }else{
            calenderInputText = 'Select date'
        }
        setSelectedCalenderInput(calenderInputText); 
    }, [date]);

    // Functions
    const onDateSelected = (date) =>{
        onCalenderDateSelected(date);
        setOpenCalender(false);
    }
    const onClearDateInput = (e)=> {
       onCalenderDateSelected(null);
       e.stopPropagation();
    }
    const onInputBoxClick = () => {
        if (!disabled) {
            setOpenCalender(!openCalender)
        }
    }
    const calenderOpenCloseClass = isFromModal ? ( openCalender ? classes.calenderOpenClass : classes.calenderCloseClass ) : '';

    const calendarContainerStyle = disabled ? `${classes.calenderContainer} ${classes.disabled}` : `${classes.calenderContainer}`;
    
    // Return
    return (
        <div id={id} className={`${classes.datePickerWrapper} ${calenderOpenCloseClass}`}>
            <div onClick={onInputBoxClick} className={calendarContainerStyle}>
                <span className={classes.text}>{selectedCalenderInput}</span>
                <span className={classes.searchIcon}>
                    {canClearInput ? 
                        <span onClick={onClearDateInput}  className={classes.clearIcon}>
                            <CrossMarkIcon />
                        </span>
                    : null}
                    <CalenderIcon/>
                </span>
            </div>
            {openCalender ?
                <ClickAwayListener onClickAway={() => setOpenCalender(false)}>
                    <div className={classes.datePickerContainer}>
                        <Calendar
                            date={date}
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={onDateSelected}
                            id={id}
                        />
                    </div>
                </ClickAwayListener> : null}
            {showValidationError && <ErrorText errorMessage={errorMessage} />}
        </div>
    )
}

/**
 *  Exports
 */

export default CommonDatePicker;