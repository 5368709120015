import React, { useState, useEffect } from 'react'
import { Box, Modal } from '@mui/material';

import classes from './index.module.css'
import { VALID_EMAIL_REGEX } from '../../../../constants/regex';

import ErrorOutlineIcon from '../../../../assets/images/error_outline_red.png';

const WhiteListInviteModal = props => {
  const {openInvite, setOpenInvite, setUserToInvite} = props

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isNameValid, setIsNameValid] = useState(true)
  const [isLastNameValid, setIsLastNameValid] = useState(true)


  
  const handleInvite = () => {
   const re = VALID_EMAIL_REGEX;
   const testEmail = re.test(email);
   if(testEmail && firstName !== '' && lastName !== ''){
    setUserToInvite({ "users": [{
        "email_id": email,
        "first_name": firstName,
        "last_name": lastName
      }]})
   } else {
     setIsEmailValid(false)
   }

   if(firstName === ''){
    setIsNameValid(false)
   }

   if(lastName === ''){
    setIsLastNameValid(false)
   }
 }

 useEffect(() => {
   if(isEmailValid === false){
     setTimeout(() => {setIsEmailValid(true)}, 3000)
   }
   if(isNameValid === false){
     setTimeout(() => {setIsNameValid(true)}, 3000)
   }
   if(isLastNameValid === false){
     setTimeout(() => {setIsLastNameValid(true)}, 3000)
   }
 }, [isEmailValid, isNameValid, isLastNameValid])

 useEffect(() => {
  if(!openInvite){
    setFirstName('')
    setLastName('')
    setEmail('')
  }
 }, [openInvite])

    return (
        <div>
            <Modal
            open={openInvite}
            onClose={() => setOpenInvite(false)}
            >
                <Box className={classes.modal}>
                    <h2 className={classes.modalTitle}>Invite User</h2>
                    <div className={classes.iconClose} onClick={() => setOpenInvite(false)}>
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 0.700012L11.3 0L6 5.29999L0.700012 0L0 0.700012L5.29999 6L0 11.3L0.700012 12L6 6.70001L11.3 12L12 11.3L6.70001 6L12 0.700012Z" fill="#414141"/>
                      </svg>
                    </div>
                    <form className={classes.inputContainer} id='form'>
                      <div className={classes.formControl}>
                        <label className={classes.label} htmlFor="name">First Name*</label>
                        <input type="text" id="name" className={`${classes.input} ${!isNameValid && classes.invalid}`} onChange={(e) => {setFirstName(e.target.value)}}/>
                        {!isNameValid && <div className={classes.error}>
                          <img alt='error icon' src={ErrorOutlineIcon} />
                          <span className={classes.errorText}>Please enter a name</span>
                        </div>}
                      </div>
                      <div className={classes.formControl}>
                        <label className={classes.label} htmlFor="surname">Last Name*</label>
                        <input type="text" id="surname" className={`${classes.input} ${!isLastNameValid && classes.invalid}`} onChange={(e) => {setLastName(e.target.value)}}/>
                        {!isLastNameValid && <div className={classes.error}>
                          <img alt='error icon' src={ErrorOutlineIcon} />
                          <span className={classes.errorText}>Please enter a last name</span>
                        </div>}
                      </div>
                      <div className={classes.formControl}>
                        <label className={classes.label} htmlFor="email">User Email*</label>
                        <input type="email" id="email" className={`${classes.input} ${!isEmailValid && classes.invalid}`} onChange={(e) => {setEmail(e.target.value)}}/>
                        {!isEmailValid && <div className={classes.error}>
                          <img alt='error icon' src={ErrorOutlineIcon} />
                          <span className={classes.errorText}>Please enter a valid email</span>
                        </div>}
                      </div>
                    </form>
                    <div className={classes.buttonContainer}>
                      <button className={`transparent-button ${classes.cancelBtn}`} onClick={() => {setOpenInvite(false)}}>Cancel</button>
                      <button className={`solid-button ${classes.inviteBtn}`} onClick={() => {handleInvite()}}>Invite</button>
                    </div>
                </Box>
            </Modal>

      </div>
    )
}

export default WhiteListInviteModal
