import React from 'react';
import classes from './index.module.css'
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '../../../assets/icons/down-arrow.svg';
import upArrowXs from '../../../assets/icons/up-arrow.svg';
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import { v4 } from 'uuid'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { sendGAEvents } from '../../../api/generic-api';
import lockIcon from '../../../assets/icons/lockIcon.png';

export default function FilterCategory(props) {

  // Slider related actions
  const [value, setValue] = React.useState([20, 60]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: 3,
      width: 20,
      height: 20,
      cursor: 'not-allowed',
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#e4e4e4',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
  })

  const StyledCheckbox = (props) => {
    const classes = useStyles();

    return (
      <Checkbox
        className={classes.root}
        disableRipple
        color="default"
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }

  // Checkbox related actions
  const {
    item = {},
    filterCategoryCode = "",
    onChangeFilterItemState,
    filterCollapseState,
    handleFilterCollapseStateChange,
    filterIndex,
    handleFilterShowMoreChange,
    showMoreItems,
    togglesAllItemsInFilter,
    open,
    isTargetLockingEnabled,
    replacers = {}
  } = props;

  const { histogram = [] } = item;

  const hideSelectAll = () => {
    if (isTargetLockingEnabled) {
      return !histogram.find(x => x.count >= 50)
    }
    return false;
  }

  // Returns the filter items per category
  const getFilterItemValues = () => {
    if (showMoreItems[filterIndex] || histogram.length <= 5) {
      return histogram;
    }
    return histogram.slice(0, 5)
  }

  const handleSliderChange = (index, newValue) => {
    const val = [...value];
    val[index] = Number.parseInt(newValue) || 0;
    setValue(val)
  }

  const areValuesNumbers = () => {
    let areNumbers = true;
    if (histogram?.length > 0) {
      for (let item of histogram) {
        if (isNaN(item?.key)) {
          areNumbers = false;
          break;
        }
      }
    } else {
      return false;
    }

    return areNumbers;
  }

  const getMarks = category => {
    let marks = []
    for (let item of histogram) {
      marks.push({
        value: item?.key,
        label: item?.key
      })
    }
    return marks;
  }

  const areAllValuesSelected = () => {
    let allSelected = true;
    for (let item of histogram) {
      if (!item.isSelected) {
        allSelected = false;
        break;
      }
    }
    return allSelected;
  }

  const getTotalCountOfAllItems = () => {
    let totalCount = 0;
    for (let item of histogram) {
      totalCount += item.count > 0 ? item.count : 0;
    }
    return totalCount;
  }

  const alterFilterCodeToText = filterCode => {
    const textArray = filterCode.split("_");
    return textArray.join(" ");
  }

  const getDateFieldValues = () => {
    if (item?.isDateField) {
      if (item?.selected_start_date && item?.selected_end_date) {
        return [new Date(item?.selected_start_date), new Date(item?.selected_end_date)]
      }
      return null
    }
    return null
  }

  const filterItemValues = getFilterItemValues()
  const areNumberType = areValuesNumbers()
  const areAllSelected = areAllValuesSelected();
  const totalCountOfAllItems = getTotalCountOfAllItems();

  const dateFieldValues = getDateFieldValues()
  return (
    <div className={classes.leftColumnFilters}>
      <Accordion className={classes.expansionPanel}
        id='cvb-filterCategory-expansionPanel'
        expanded={filterCollapseState}
        onChange={() => { handleFilterCollapseStateChange(filterIndex, !filterCollapseState); handleFilterShowMoreChange(filterIndex, false) }}
      >
        <AccordionSummary
          expandIcon={<img src={ExpandMoreIcon} alt="icon" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.expansionPanelHeading}
        >
          <h3 className={classes.filterBlockHeading}>
            {alterFilterCodeToText(replacers[filterCategoryCode] || filterCategoryCode)}
          </h3>
        </AccordionSummary>
        <AccordionDetails>
          {
            item?.isDateField ?
              <>
                <DateRangePicker
                  minDate={new Date(item?.start_date)}
                  maxDate={new Date(item?.end_date)}
                  onChange={(e) => { onChangeFilterItemState(e, filterCategoryCode, null, true) }}
                  value={dateFieldValues}
                  id='cvb-filterCategory-dateRangePicker'
                />
              </> :
              <React.Fragment>
                {
                  !areNumberType ?
                    <div>
                      <div className={classes.clearFilterValues}
                        id='cvb-filterCategory-clearFilterButton'
                        onClick={() => { togglesAllItemsInFilter(filterCategoryCode, false) }}>Clear</div>
                      <ul className={classes.checkboxFilters}>
                        {
                          !hideSelectAll &&
                          <React.Fragment>
                            {
                              histogram?.length > 0 ?
                                <li key={v4()} className={classes.filterSelectAll}>
                                  <Checkbox
                                    checked={areAllSelected}
                                    color="primary"
                                    id='cvb-filterCategory-toggleFilterSelectionCheckbox'
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    onChange={(e) => { togglesAllItemsInFilter(filterCategoryCode, !areAllSelected) }}
                                  />
                                  <span id='cvb-filterCategory-selectAllText' className={classes.filterText}>
                                    Select All
                                  </span>
                                  {
                                    !isTargetLockingEnabled &&
                                    <span id='cvb-filterCategory-totalCountOfItems' className={classes.filtersCount}>
                                      {totalCountOfAllItems}
                                    </span>
                                  }

                                </li> :
                                <li>No items found</li>
                            }
                          </React.Fragment>
                        }
                        {
                          filterItemValues?.length > 0 &&
                          filterItemValues.map(item => {
                            const isDisabled = isTargetLockingEnabled && item.count < 50
                            return <li key={v4()} className={isDisabled ? classes.itemDisabled : ""}>
                              {
                                isDisabled ?
                                  <StyledCheckbox disabled /> :
                                  <Checkbox
                                    checked={item?.isSelected || false}
                                    color="primary"
                                    disabled={isDisabled}
                                    id={`cvb-filterCategory-filterItemValues-${item.key}`}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    onChange={(e) => {
                                      sendGAEvents('filter', 'filter_data', 'filter data',
                                        { 'filter_category': filterCategoryCode, 'filter_option': item.key }
                                      );
                                      onChangeFilterItemState(e, filterCategoryCode, item.key)
                                    }}
                                  />
                              }

                              <span id={`cvb-filterCategory-itemKey-${item.key}`} className={classes.filterText}>
                                {Array.isArray(item?.key) ? item.key.join(" - ") : item?.key}
                                {
                                  item.isAccessible === false && isTargetLockingEnabled &&
                                  <span className={`${classes.lockIcon}`}>
                                    <img src={lockIcon} alt="lock icon" />
                                  </span>
                                }
                              </span>
                              <span id={`cvb-filterCategory-itemCount-${item.key}`} className={`${classes.filtersCount}`}>
                                {isTargetLockingEnabled && isDisabled ? "< 50" : item?.count > 0 ? item.count : 0}
                              </span>
                            </li>
                          })
                        }
                      </ul>
                      {
                        histogram.length > 5 ?
                          (showMoreItems[filterIndex] ?
                            <div className={classes.moreFilterOptions}>
                              <p id='cvb-filterCategory-lessButton' onClick={() => { handleFilterShowMoreChange(filterIndex, false) }}>Less...<img src={upArrowXs} className={classes.imgAlign} alt="Down Arrow" /></p></div> :
                            <div className={classes.moreFilterOptions}>
                              <p id='cvb-filterCategory-moreButton' onClick={() => { handleFilterShowMoreChange(filterIndex, true) }}>
                                {histogram.length - 5} More...<img src={ExpandMoreIcon} className={classes.imgAlign} alt="Up Arrow" /></p></div>) : <></>
                      }
                    </div>
                    :
                    <div className={classes.sliderContainer}>
                      <div className={classes.sliderInputContainer}>
                        <input
                          id='cvb-filterCategory-slider-1'
                          value={value[0]}
                          onChange={e => handleSliderChange(0, e.target.value)} />
                        <span>-</span>
                        <input
                          id='cvb-filterCategory-slider-2'
                          value={value[1]}
                          onChange={e => handleSliderChange(1, e.target.value)} />
                      </div>


                      <Slider
                        id='cvb-filterCategory-muiSlider'
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        step={20}
                        max={80}
                        min={0}
                        marks={getMarks(filterCategoryCode)}
                      />
                    </div>
                }
              </React.Fragment>
          }
        </AccordionDetails>
      </Accordion>
    </div>
  );
}